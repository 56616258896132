import React, { Suspense } from 'react'
import Header from '../../Header'
import Footer from '../../Footer'
import { AppHeader, AppSidebar, AppSidebarFooter, AppSidebarMinimizer, AppSidebarToggler } from '@coreui/react'
import { withRouter } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'
import { SidebarMenu } from './SidebarMenu'

const AdminLayout = props => {
  const loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  )

  const toggleMenu = e => {
    e.preventDefault()
    e.target.parentElement.classList.toggle('open')
  }

  const { children, navigation, location } = props
  const showSidebar = location.pathname !== '/404'

  const removeSidebarShow = () => {
    document.body.classList.remove('sidebar-show')
  }
  
  return (
    <div className="app">
      <AppHeader fixed>
        <AppSidebarToggler className="d-lg-none" mobile />
        <Suspense fallback={loading}>
          <Header location={location}/>
        </Suspense>
      </AppHeader>

      <NotificationContainer />

      <div className="app-body">
        {showSidebar && (
          <AppSidebar fixed display="lg" onClick={removeSidebarShow}>
            <Suspense>
              <div className="scrollbar-container sidebar-nav ps-container ps--active-y">
                <SidebarMenu navigation={navigation} pathname={props && props.history.location.pathname} toggleMenu={toggleMenu} />
              </div>
            </Suspense>
            <AppSidebarFooter/>
            <AppSidebarMinimizer/>
          </AppSidebar>
        )}

        <main className="main">
          {children}
        </main>

        <div id="app-notifications"/>
      </div>
      <Footer/>
    </div>
  )
}

export default withRouter(AdminLayout)
