import React from 'react'
import ReactDOM from 'react-dom'
import Modal from './Modal'

const CustomPrompt = (data, callback) => {
  let attrs = JSON.parse(data)
  const container = document.createElement('div')

  document.body.appendChild(container)

  const closeModal = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container)
    callback(callbackState)
  }

  ReactDOM.render(
    <Modal
      isShown={true}
      hide={() => closeModal(false)}
      onCancel={() => closeModal(false)}
      textCancel={attrs.textCancel}
      onSubmit={() => closeModal(true)}
      textAction={attrs.textAction}
      title={attrs.title}
      isShowing={true}
    >
      {attrs.message}
    </Modal>,
    container,
  )
}
export default CustomPrompt