import api from '../../utils/api'
import {
  FETCH_RENEWALS_REQUEST,
  FETCH_RENEWALS_SUCCESS,
  FETCH_RENEWALS_FAILURE,
  RENEWALS_TABLE_ROW_ADD,
  RENEWALS_TABLE_ROW_REMOVE,
  DELETE_RENEWALS_REQUEST,
  DELETE_RENEWALS_SUCCESS,
  DELETE_RENEWALS_FAILURE,
  RESTORE_RENEWALS_REQUEST,
  RESTORE_RENEWALS_SUCCESS,
  RESTORE_RENEWALS_FAILURE,
  ASSIGN_RENEWALS_SUCCESS,
  ASSIGN_RENEWALS_REQUEST,
  ASSIGN_RENEWALS_FAILURE,
} from '../../constants'
import { NotificationManager } from 'react-notifications'

export const fetchRenewalsRequest = () => ({
  type: FETCH_RENEWALS_REQUEST,
})

export const fetchRenewalsSuccess = data => ({
  type: FETCH_RENEWALS_SUCCESS,
  payload: data,
})

export const fetchRenewalsFailure = err => ({
  type: FETCH_RENEWALS_FAILURE,
  payload: err,
})

/**
 * Fetch Renewals
 */
export const fetchRenewals = url => dispatch => {
  dispatch(fetchRenewalsRequest())

  return api
    .get(`/direct-sales/renewals${url ? url : ''}`)
    .then(res => {
      return dispatch(fetchRenewalsSuccess(res.data))
    })
    .catch(err => {
      return dispatch(fetchRenewalsFailure(err.response))
    })
}

export const renewalsTableRowAdd = data => ({
  type: RENEWALS_TABLE_ROW_ADD,
  payload: data,
})

export const renewalsTableRowRemove = data => ({
  type: RENEWALS_TABLE_ROW_REMOVE,
  payload: data,
})

/**
 * Delete 1 prospect or more by providing an array of user ids.
 *
 * @param {Array} ids
 */
export const deleteRenewals = ids => dispatch => {
  dispatch({
    type: DELETE_RENEWALS_REQUEST,
  })

  return api
    .patch(`/direct-sales/renewals`, {
      action: 'delete',
      ids: ids,
    })
    .then(res => {
      dispatch({
        type: DELETE_RENEWALS_SUCCESS,
        payload: ids,
      })
      if (ids.length > 1)
        NotificationManager.success('Success! You deleted multiple renewals.')
      else NotificationManager.success('Success! You deleted a renewal.')
    })
    .catch(err => {
      dispatch({
        type: DELETE_RENEWALS_FAILURE,
        payload: err.response.data,
      })

      NotificationManager.error('Oops! Something went wrong. Please try again.')
    })
}

export const restoreRenewals = ids => dispatch => {
  dispatch({
    type: RESTORE_RENEWALS_REQUEST,
  })

  return api
    .patch(`/direct-sales/renewals`, {
      action: 'restore',
      ids: ids,
    })
    .then(() => {
      dispatch({
        type: RESTORE_RENEWALS_SUCCESS,
        payload: ids,
      })
      if (ids.length > 1)
        NotificationManager.success('Woohoo! You restored multiple renewals.')
      else NotificationManager.success('Woohoo! You restored a renewal.')
    })
    .catch(err => {
      dispatch({
        type: RESTORE_RENEWALS_FAILURE,
        payload: err.response.data,
      })

      NotificationManager.error('Oops! Something went wrong. Please try again.')
    })
}

export const assignRenewal = (ids, assignee_id, prioritised) => dispatch => {
  if (!ids) return

  dispatch({
    type: ASSIGN_RENEWALS_REQUEST,
  })

  return api
    .patch(`/direct-sales/renewals`, {
      action: 'assign',
      ids,
      assignee_id,
      prioritised,
    })
    .then(res => {
      dispatch({
        type: ASSIGN_RENEWALS_SUCCESS,
        payload: res.data
      })
      if (ids.length > 1)
        NotificationManager.success('Woohoo! You assigned multiple renewals.')
      else NotificationManager.success('Woohoo! You assigned a renewal.')
    })
    .catch(() => {
      dispatch({
        type: ASSIGN_RENEWALS_FAILURE,
      })
      NotificationManager.error('Oops! Something went wrong. Please try again.')
    })
}
