import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray, Form, reduxForm, SubmissionError } from 'redux-form'
import renderField from '../../../../../utils/renderField'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { confirmAlert } from 'react-confirm-alert'
import Modal from '../../../../modals/Modal'
import { validPhoneNumber } from '../../../../../utils/misc'
import EditContactForm from './forms/EditContactForm'
import AddContactForm from './forms/AddContactForm'
import api from '../../../../../utils/api'
import { useParams } from 'react-router-dom'
import { pick, clone, findIndex } from 'lodash'
import { NotificationManager } from 'react-notifications'

const renderTableRow = ({
  fields,
  contacts,
  handleDelete,
  enableEdit,
  handleAdd,
}) => {
  let renderContacts = contacts.map((contact, idx) => {
    return (
      <tr key={idx}>
        <td>
          {contact.title} {contact.first_name} {contact.last_name}
        </td>
        <td>{contact.email}</td>
        <td>{contact.tel1}</td>
        <td>{contact.tel2}</td>
        <td>
          {contact.is_primary ? (
            <span
              className="d-block wf-icon-prioritise-selected"
              style={{ width: 20, height: 20 }}
            />
          ) : (
            <span
              className="d-block wf-icon-prioritise"
              style={{ width: 20, height: 20 }}
            />
          )}
        </td>
        <td width="105px">
          <div className="d-flex align-items-center">
            <Link
              data-tip="Edit contact"
              data-place="top"
              to={`#`}
              className="table-action-btn"
              primary="true"
              size="sm"
              onClick={() => enableEdit(contact)}
            >
              <span className="wf-icon-edit" />
            </Link>
            <Link
              data-tip="Delete contact"
              data-place="top"
              to={`#`}
              className="table-action-btn table-action-btn--delete"
              size="sm"
              onClick={() => handleDelete(contact)}
            >
              <span className="d-block wf-icon-delete" />
            </Link>
            <ReactTooltip effect="solid" />
          </div>
        </td>
      </tr>
    )
  })

  return (
    <>
      {renderContacts}
      {fields.map((field, index) => (
        <tr key={index}>
          <td>
            <Field
              name={`${field}.name`}
              type="text"
              className="form-control"
              component={renderField}
              showSuccessValidation={false}
            />
          </td>
          <td>
            <Field
              name={`${field}.email`}
              type="text"
              component={renderField}
              className="form-control"
              showSuccessValidation={false}
            />
          </td>
          <td>
            <Field
              name={`${field}.tel_office`}
              type="text"
              component={renderField}
              className="form-control"
              showSuccessValidation={false}
            />
          </td>
          <td>
            <Field
              name={`${field}.tel_mobile`}
              type="text"
              component={renderField}
              className="form-control"
              showSuccessValidation={false}
            />
          </td>
          <td width="110px">
            <Field
              name={`${field}.is_primary`}
              type="checkbox"
              label="Yes"
              id="fieldPrimary"
              component={renderField}
              showSuccessValidation={false}
            />
          </td>
          <td>
            <div className="d-flex align-items-center justify-content-between">
              <button className="btn btn-sm btn-secondary">Save</button>
              <span
                data-tip="Delete contact"
                data-place="top"
                className="fa fa-close cursor-pointer"
                onClick={() => fields.remove(index)}
              />
              <ReactTooltip effect="solid" />
            </div>
          </td>
        </tr>
      ))}
      <tr>
        <td colSpan={7}>
          <Link to={'#'} onClick={handleAdd}>
            Add contact
          </Link>
        </td>
      </tr>
    </>
  )
}

const PersonalContactsTable = ({
  handleSubmit,
  onSubmit,
  contacts,
  setContacts,
}) => {
  const [edit, setEdit] = useState(false)
  const [create, setCreate] = useState(false)
  const [editingContact, seEditingContact] = useState(null)
  const params = useParams()

  const deleteContact = async (id) => {
    const res = await api.delete(
      `/external-sales/dealers/${params.id}/contacts/${id}`
    )
    if (res.status === 200) {
      setContacts([...contacts.filter((c) => c.id !== id)])
      NotificationManager.success('Contact deleted successfully!')
    } else if (res.status === 404) {
      NotificationManager.error('The contact could not be found.')
    }
  }

  const handleEditSubmit = async (values) => {
    const data = { ...values }
    // data.title = get(values, 'title.value')
    const res = await api.patch(
      `/external-sales/dealers/${params.id}/contacts/${values.id}`,
      data
    )
    if (res.status === 200) {
      setEdit(false)

      if (values.is_primary) {
        await makeContactPrimary(contacts, values.id)
      }

      // update the contact in the contacts table
      const updatedContacts = clone(contacts)
      const i = findIndex(updatedContacts, (c) => c.id === values.id)
      updatedContacts.splice(i, 1, res.data)
      setContacts(updatedContacts)

      NotificationManager.success('Contact updated successfully!')
    } else if (res.status === 404) {
      NotificationManager.error('The contact could not be found.')
    }
  }

  async function makeContactPrimary(list, contactId) {
    const res2 = await api.patch(
      `/external-sales/dealers/${params.id}/contacts/${contactId}/set-primary`,
      []
    )
    if (res2.status === 200) {
      const updatedContacts = [
        ...list.map((c) => {
          if (c.id === res2.data.id) {
            c.is_primary = res2.data.is_primary
          } else {
            c.is_primary = false
          }
          return c
        }),
      ]
      setContacts(updatedContacts)
    }
  }

  const handleCreate = async (values) => {
    const data = pick(values, [
      'first_name',
      'last_name',
      'email',
      'tel1',
      'tel2',
      'is_primary',
    ])

    // data.title = values.title.value

    const res = await api.post(
      `/external-sales/dealers/${params.id}/contacts`,
      data
    )

    if (res.status === 201) {
      const list = [...contacts, res.data]

      // add contact to the table
      setContacts(list)

      setCreate(false)

      if (data.is_primary) {
        const contactId = res.data.id
        await makeContactPrimary(list, contactId)
      }
    } else if (res.status === 422) {
      throw new SubmissionError({
        ...res.data.errors,
        _error: res.data.message,
      })
    } else {
      NotificationManager.error(
        'Oops! Something went wrong. Please try again after refreshing the page.'
      )
      setCreate(false)
    }
  }

  /**
   * MODAL - Delete contact
   * @param contact
   */
  const deleteModal = (contact) => {
    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>
                Delete the contact{' '}
                <strong>
                  {contact.first_name} {contact.last_name}
                </strong>
                ? It'll be lost forever.
              </p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  deleteContact(contact.id)
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const enableEdit = (contact) => {
    seEditingContact({
      ...contact,
      // title: {
      //   value: contact.title,
      // },
    })
    setEdit(!edit)
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} initialvalues={{ contacts }}>
        <table className="table table-striped table-bordered pricing-rule-table mt-4 mb-5">
          <thead>
            <tr>
              <th>Contact's full name</th>
              <th>Email address</th>
              <th>Tel (office)</th>
              <th>Tel (mob)</th>
              <th>Primary</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {contacts && (
              <FieldArray
                name={'contacts'}
                component={renderTableRow}
                contacts={contacts}
                handleDelete={deleteModal}
                enableEdit={enableEdit}
                handleAdd={() => setCreate(true)}
              />
            )}
          </tbody>
        </table>
      </Form>

      <Modal
        isShowing={edit}
        title={'Edit contact'}
        style={{ width: 550 }}
        hideCloseButton
      >
        <EditContactForm
          initialValues={editingContact}
          onCancel={() => setEdit(false)}
          onSubmit={handleEditSubmit}
        />
      </Modal>

      <Modal
        isShowing={create}
        title={'Add contact'}
        style={{ width: 550 }}
        className="contact-modal"
        hideCloseButton
      >
        <AddContactForm
          onSubmit={handleCreate}
          cancel={() => setCreate(false)}
        />
      </Modal>
    </div>
  )
}

PersonalContactsTable.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setContacts: PropTypes.func.isRequired,
}

const validate = (fields) => {
  const errors = {}

  if (!fields.tel_office && !fields.tel_mobile)
    errors.tel_office = 'You must enter at least one telephone number.'

  if (fields.tel_office && !validPhoneNumber(fields.tel_office))
    errors.tel_office = "That phone number doesn't look quite right."

  if (fields.tel_mobile && !validPhoneNumber(fields.tel_mobile))
    errors.tel_mobile = "That phone number doesn't look quite right."

  return errors
}

PersonalContactsTable.propTypes = {
  contacts: PropTypes.array.isRequired,
}

export default reduxForm({
  form: 'dealer/personal-contact',
  validate,
})(PersonalContactsTable)
