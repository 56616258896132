import React, { Fragment, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import paginationFactory, {
  SizePerPageDropdownStandalone,
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import PropTypes from 'prop-types';
import SearchBox from './SearchBox';
import CustomTableActions from './CustomTableActions';

const CustomDropdown = ({ options, onSizePerPageChange, sizePerPage }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <div className="size-per-page-dropdown">
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle color="outline" caret>
          {sizePerPage}
        </DropdownToggle>
        <DropdownMenu>
          {options.map((option) => (
            <DropdownItem
              key={option.text}
              data-page={option.page}
              onClick={(e) => {
                e.preventDefault();
                onSizePerPageChange(option.page);
              }}
            >
              {option.text}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

const sizePerPageRenderer = ({
  options,
  onSizePerPageChange,
  currSizePerPage,
}) => {
  return (
    <div className="size-per-page d-flex align-items-center">
      <span className="mr-2">Show</span>
      <CustomDropdown
        options={options}
        sizePerPage={currSizePerPage}
        onSizePerPageChange={onSizePerPageChange}
      />
      <span className="ml-2 mr-2">entries</span>
    </div>
  );
};

export const Table = (props) => {
  const {
    resource,
    columns,
    exportData,
    selectRow,
    handleTableChange,
    loading,
    onSearch,
    searching,
    omnibox,
    noDataIndication,
    searchPlaceholder,
    hasTableSearch,
    tableActions,
    keyField,
    showSizePerPage,
    minSelection,
    columnToggle,
    style = {
      marginBottom: '100px',
    },
  } = props;
  const selected = selectRow ? selectRow.selected : [];

  return (
    resource && (
      <div style={style}>
        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            page: resource.current_page,
            sizePerPage: resource.per_page,
            totalSize: resource.total,
            sizePerPageRenderer,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <Fragment>
              <div className="row">
                <div className="col d-flex align-items-center">
                  {showSizePerPage && (
                    <SizePerPageDropdownStandalone {...paginationProps} />
                  )}
                  {/*We also can use the base selections provided to the table component.*/}
                  {((selected && selected.length > minSelection) || (props.selected && props.selected.length > minSelection))  && (
                    <CustomTableActions tableActions={tableActions} />
                  )}
                </div>
                {hasTableSearch && (
                  <div className="col">
                    <SearchBox
                      omnibox={omnibox}
                      searchPlaceholder={searchPlaceholder}
                      searching={searching}
                      onSearch={onSearch}
                    />
                  </div>
                )}
              </div>
              <BootstrapTable
                remote
                loading={loading}
                keyField={keyField || 'id'}
                data={resource.data}
                columns={columns}
                selectRow={selectRow}
                onTableChange={handleTableChange}
                {...paginationTableProps}
                noDataIndication={noDataIndication}
                hideSizePerPage={showSizePerPage}
                striped
                hover
                condensed
                columnToggle={columnToggle}
                rowClasses={props.rowClasses}
              />

              {resource.total > 0 && (
                <div className="row align-items-center">
                  <div className="col text-muted">
                    <small
                      className="d-inline-flex"
                      style={{ fontSize: '13px' }}
                    >
                      Showing {resource.from} to {resource.to} of{' '}
                      {resource.total} entries
                    </small>
                    {exportData && (
                      <Link
                        to="#"
                        className="no-decoration text-success ml-3"
                        style={{ fontSize: 14, fontWeight: 800 }}
                        onClick={exportData}
                      >
                        <i className="nudge-down icon-arrow-down-circle" />{' '}
                        Export to CSV
                      </Link>
                    )}
                  </div>

                  <div className="col">
                    <PaginationListStandalone
                      {...paginationProps}
                      nextPageText="Next"
                      prePageText="Previous"
                    />
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </PaginationProvider>
      </div>
    )
  );
};

Table.propTypes = {
  searching: PropTypes.bool,
  onSearch: PropTypes.func,
  // resource: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  exportData: PropTypes.func,
  selectRow: PropTypes.object,
  handleTableChange: PropTypes.func,
  loading: PropTypes.bool,
  selected: PropTypes.array,
  dispatch: PropTypes.func,
  noDataIndication: PropTypes.string,
  hasTableSearch: PropTypes.bool,
  tableActions: PropTypes.arrayOf(
    PropTypes.shape({
      tooltip: PropTypes.shape({
        text: PropTypes.string,
        place: PropTypes.string,
      }),
      callback: PropTypes.func.isRequired,
      iconClass: PropTypes.string.isRequired,
    })
  ),
  minSelection: PropTypes.number, // how many rows to select before we show table actions
  columnToggle: PropTypes.bool,
  showSizePerPage: PropTypes.bool,
};

Table.defaultProps = {
  noDataIndication: 'No matching records.',
  hasTableSearch: false,
  showSizePerPage: true,
  minSelection: 1,
  columnToggle: false,
};

export default Table;
