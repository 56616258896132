import React from 'react'
import {Field, Form, reduxForm} from "redux-form";
import { email, required } from 'redux-form-validators'
import renderField from "../../../utils/renderField";

const EditPolicyholderContactForm = ({handleSubmit, onSubmit, valid}) => {
  return <>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        type="text"
        label="Title"
        component={renderField}
        name={`title`}
        className="form-control"
      />
      <Field
        type="text"
        label="First name"
        component={renderField}
        name={`first_name`}
        className="form-control"
      />
      <Field
        type="text"
        label="Last name"
        component={renderField}
        name={`last_name`}
        className="form-control"
      />
      <Field
        type="text"
        label="Address Line 1"
        component={renderField}
        name={`address_1`}
        className="form-control"
      />
      <Field
        type="text"
        label="Address Line 2"
        component={renderField}
        name={`address_2`}
        className="form-control"
      />
      <Field
        type="text"
        label="Address Line 3"
        component={renderField}
        name={`address_3`}
        className="form-control"
      />
      <Field
        type="text"
        label="City"
        component={renderField}
        name={`city`}
        className="form-control"
      />
      <Field
        type="text"
        label="County"
        component={renderField}
        name={`county`}
        className="form-control"
      />
      <Field
        type="text"
        label="Postcode"
        component={renderField}
        name={`postcode`}
        className="form-control"
      />
      <Field
        type="email"
        label="Email"
        component={renderField}
        name={`email`}
        className="form-control"
        validate={[required({ msg: 'You must enter an email address.' }), email({ message: 'That email address doesn\'t quite look right.' })]}
        required
      />
      <Field
        type="text"
        label="Telephone (home)"
        component={renderField}
        name={`tel1`}
        className="form-control"
      />
      <Field
        type="text"
        label="Telephone (mobile)"
        component={renderField}
        name={`tel2`}
        className="form-control"
      />
      <button
        type="submit"
        className={`btn btn-secondary mb-4 mt-2`}
        disabled={!valid}
      >
        Save
      </button>
    </Form>
  </>
}



export default reduxForm({
  enableReinitialize: true,
  form: 'policyholder/edit-details'
})(EditPolicyholderContactForm)
