import React from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'
import { has } from 'lodash'

AssignModal.propTypes = {
  title   : PropTypes.string,
  subtitle: PropTypes.string,

  isShowing: PropTypes.bool.isRequired,
  toggle   : PropTypes.func.isRequired,
  onCancel : PropTypes.func,
  onSubmit : PropTypes.func,

  assignTo: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(PropTypes.object),
}

function AssignModal(props) {
  const { children, assignTo, selected } = props
  let tagline, name;

  if (selected.length > 0) {
    if (has(selected[0], 'name')) {
      name = selected[0].name
    } else {
      name = `${selected[0].first_name} ${selected[0].last_name}`
    }
    tagline = selected.length > 1 ? `${selected.length} ${assignTo}` : name

  }

  return (
    <Modal
      hide={props.toggle}
      isShowing={props.isShowing}
      title={props.title}
      subtitle={tagline}
      onCancel={props.onCancel}
      onSubmit={props.onSubmit}
    >
      {children}
    </Modal>
  )
}

export default AssignModal