import {
  FETCH_USER_GROUPS_REQUEST,
  FETCH_USER_GROUPS_SUCCESS,
  FETCH_USER_GROUPS_FAILURE,
  DELETE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP_REQUEST,
  DELETE_USER_GROUP_FAILURE,
  SEARCH_USER_GROUPS,
  FETCH_USER_GROUP_SUCCESS,
  FETCH_USER_GROUP_REQUEST,
  FETCH_USER_GROUP_FAILURE,
} from '../constants'

const initialState = {
  isPending: null,
  data: null,
  error: null,
  singleGroup: false,
  searching: false,
}

export function userGroups(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_GROUPS_REQUEST:
      return {
        ...state,
        isPending: true,
      }
    case FETCH_USER_GROUPS_SUCCESS:
      return {
        ...state,
        isPending: false,
        data: { ...action.payload },
      }
    case FETCH_USER_GROUPS_FAILURE:
      return {
        ...state,
        isPending: false,
        data: null,
        error: { ...action.payload },
      }
    case FETCH_USER_GROUP_REQUEST:
      return {
        ...state,
        isPending: true,
      }
    case FETCH_USER_GROUP_SUCCESS:
      return {
        ...state,
        isPending: false,
        singleGroup: { ...action.payload.group },
      }
    case FETCH_USER_GROUP_FAILURE:
      return {
        ...state,
        isPending: false,
        error: { ...action.payload.error },
      }
    case DELETE_USER_GROUP_REQUEST:
      return {
        ...state,
        isPending: true,
        error: null,
      }
    case DELETE_USER_GROUP_SUCCESS:
      return {
        ...state,
        isPending: false,
        data: {
          ...state.data,
          data: [
            ...state.data.data.filter(group => group.id !== action.payload.id),
          ],
        },
        error: null,
      }
    case DELETE_USER_GROUP_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload.error,
      }
    case SEARCH_USER_GROUPS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
