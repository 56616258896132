import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'

class renderDatePicker extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }).isRequired,
    inputValueFormat: PropTypes.string,
    icon: PropTypes.bool
  }

  static defaultProps = {
    inputValueFormat: null,
    icon: true
  }

  state = {
    selectedDate: null
  }

  UNSAFE_componentWillMount() {
    if (this.props.input.value) {
      this.setState({
        selectedDate: moment(
          this.props.input.value,
          this.props.inputValueFormat
        ).toDate(),
      })
    }
  }

  handleChange = (date) => {
    this.setState({
      selectedDate: date,
    })

    this.props.input.onChange(date)
  }

  render() {
    const {
      meta: { touched, error, valid, dirty },
      label,
      id,
      ...rest
    } = this.props
    let validated = ''

    if (touched && error && 'error') {
      validated = 'is-invalid'
    }

    if (touched && dirty && valid) {
      validated = 'is-valid'
    }

    return (
      <div className="b-form-group form-group">
        {label && (
          <label htmlFor={id} className="col-form-label pt-0">
            {label}
          </label>
        )}
        <div className="input-group flex-nowrap w-100">
          {this.props.icon && <div className="input-group-append">
            <span
              className="input-group-text"
              style={{ borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}
            >
              <i className="icon-calendar" />
            </span>
          </div>}
          <DatePicker
            {...rest}
            className={`${this.props.className} ${validated} w-100 wf-datepicker-input`}
            selected={this.state.selectedDate}
            onChange={this.handleChange}
            autoComplete="off"
            style={{ width: '100%' }}
          />
        </div>
        {touched && error && (
          <div className="invalid-feedback d-block">{error}</div>
        )}
      </div>
    )
  }
}

renderDatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
}

export default renderDatePicker
