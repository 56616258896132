import React from 'react'
// import PropTypes from 'prop-types'
import GetQuoteForm from './GetQuoteForm'

const GetQuote = props => {

  // const steps = props.steps


  return (
    <div>
      <GetQuoteForm />
    </div>
  )
}

GetQuote.propTypes = {
  // steps: PropTypes.arrayOf(PropTypes.object)
}

export default GetQuote