import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../../../../utils/api'
import { useParams } from 'react-router-dom'
import { history } from '../../../../../utils/history'
import PolicyholderDetails from './PolicyholderDetails'
import VehicleDetails from '../../../DealerPortal/Policies/Show/VehicleDetails';
import PolicyDetails from './PolicyDetails'
import PolicyStatusForm from './PolicyStatusForm'
import DealerDetails from './DealerDetails'
import { confirmAlert } from 'react-confirm-alert'
import { NotificationManager } from 'react-notifications'
import moment from "moment";
import AdminComments from './AdminComments';
import { hasPermission } from '../../../../../utils/auth';
import { connect } from 'react-redux';

const Policy = (props) => {
  const { loggedInPerson } = props
  const params = useParams()
  const [policy, setPolicy] = useState(null)
  const [sendingPolicyDocumentUpdateEmail, setSendingPolicyDocumentUpdateEmail] = useState(false)
  const [forceRerender, setForceRerender] = useState(0)

  const fetchPolicy = () => {
    api
      .get('/external-sales/policies/' + params.id)
      .then((res) => {
        setPolicy(res.data)
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 404) {
          history.push('/404')
        }
      })
  }

  useEffect(() => {
    fetchPolicy();
  }, [])

  const cancelPolicy = () => {
    api
      .patch('/external-sales/policies/' + params.id + '/cancel')
      .then((res) => {
        NotificationManager.success('Woohoo! You cancelled a policy.')
        let updated = { ...policy, status: res.data.status }
        setPolicy(updated)
      })
      .catch((err) => {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again after refreshing the page'
        )
      })
  }

  const onCancel = () => {
    confirmAlert({
      title: '🧐 Are you sure?',
      message: `The policy will be cancelled forever. This action cannot be undone.`,
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>{message}</p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  cancelPolicy()
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const handlePolicyStatusSubmit = (values) => {
    api
      .patch(
        '/external-sales/policies/' + params.id + '/status',
        {
          status: values.status.value,
          sub_status: values.status.value === 'Cancelled' ? values.sub_status.value : ''
        })
      .then(async (res) => {
        // add email message to timeline
        if (values.status.value === 'Cancelled') {
          if (
            values.status.value !== policy.status &&
            !values.sub_status.value
          ) {
            const message = {
              message: `Policy cancelled by ${loggedInPerson.first_name} ${loggedInPerson.last_name}`,
              message_type: 'Note',
              person_id: loggedInPerson.person_id,
            };
            await createPolicyMessage(message);
          } else if (
            ['Approved', 'Cancelled'].includes(policy.status) &&
            values.sub_status.value !== policy.sub_status
          ) {
            if (values.sub_status.value === 'Deal Unwound') {
              await sendEmail({ type: 'policy-cancellation-deal-unwound' });
              const message = {
                message: `Cancelled due to deal being unwound. Email sent on the ${moment().format('Do')} of ${moment().format('MMM')} ${moment().format('YYYY')} at ${moment().format('HH:mm')}`,
                message_type: 'Email',
                person_id: loggedInPerson.person_id,
              };
              
              await createPolicyholderMessage(message);
              await createPolicyMessage(message);
            } else if (values.sub_status.value === 'Non-payment by Dealer') {
              await sendEmail({ type: 'policy-cancellation-non-payment-by-dealer' });
              const message = {
                message: `Cancelled due to Dealer non-payment email sent by ${loggedInPerson.first_name} ${loggedInPerson.last_name} on the ${moment().format('Do')} of ${moment().format('MMM')} ${moment().format('YYYY')} at ${moment().format('HH:mm')}`,
                message_type: 'Email',
                person_id: loggedInPerson.person_id,
              };
              await createPolicyholderMessage(message);
              await createPolicyMessage(message);
            } else if (values.sub_status.value === 'Customer Request') {
              await sendEmail({ type: 'policy-cancellation-customer-request' });
              const message = {
                message: `Cancelled due to customer request. Email sent on the ${moment().format('Do')} of ${moment().format('MMM')} ${moment().format('YYYY')} at ${moment().format('HH:mm')}`,
                message_type: 'Email',
                person_id: loggedInPerson.person_id,
              };
              await createPolicyholderMessage(message);
              await createPolicyMessage(message);
            }
          }
        } else if (values.status.value === 'Approved' && values.status.value !== policy.status) {
          // send new policy email by trigger
          // await sendEmail({ type: 'new-policy' });
          // const message = {
          //   message: `New policy documents were emailed on the ${moment().format('Do')} of ${moment().format('MMM')} ${moment().format('YYYY')} at ${moment().format('HH:mm')}`,
          //   message_type: 'Email',
          //   person_id: loggedInPerson.person_id,
          // };
          // await createPolicyholderMessage(message);
          // await createPolicyMessage(message);
        }

        NotificationManager.success('Woohoo! You updated the policy.')
        history.push('/external-sales/policies')
      })
      .catch((err) => {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again after refreshing the page'
        )
      })
  }

  const transferPolicy = () => {
    history.push(`/external-sales/policies/${policy.id}/transfer`)
  }

  const isPolicyActive = () => {
    return policy && (policy.status === 'Approved') &&
      moment(policy.start_date).isSameOrBefore(moment()) &&
      moment(policy.end_date).isAfter(moment());
  }

  const isPolicyApproved = () => {
    return policy && (policy.status === 'Approved')
  }

  const isPolicyLapsed = () => {
    return policy && moment(policy.end_date).isBefore(moment());
  }

  const sendPolicyDocumentUpdateEmail = async () => {
    setSendingPolicyDocumentUpdateEmail(true);

    try {
      await api.post(`/external-sales/policies/${policy.id}/email`, { type: 'policy-document-update' });
      const message = {
        message: `The updated policy document was emailed on the ${moment().format('Do')} of ${moment().format('MMM')} ${moment().format('YYYY')} at ${moment().format('HH:mm')}`,
        message_type: 'Email',
        person_id: loggedInPerson.person_id,
      };
      await createPolicyholderMessage(message);
      await createPolicyMessage(message);
      setForceRerender(forceRerender + 1)
      NotificationManager.success('Woohoo! You sent policy update document email.')
    } catch (err) {
      NotificationManager.error(
        'Oops! Something went wrong. Please try again after refreshing the page'
      )
    }

    setSendingPolicyDocumentUpdateEmail(false);
  }

  const sendEmail = async ({ type }) => {
    try {
      await api.post(`/external-sales/policies/${policy.id}/email`, { type });
    } catch (err) {}
  }

  const sendPolicyLapsedEmail = () => {
    api.post(`/external-sales/policies/${policy.id}/send-lapsed-email`)
      .then((res) => {})
      .catch((err) => {});

    const message = {
      message: `Policy lapsed email sent by ${loggedInPerson.first_name} ${loggedInPerson.last_name} on the ${moment().format('Do')} of ${moment().format('MMM')} at ${moment().format('HH:mm')}`,
      message_type: 'Email',
      person_id: loggedInPerson.person_id,
    };

    createPolicyholderMessage(message);
  }

  const createPolicyholderMessage = async (message) => {
    try {
      await api.post(`/external-sales/policyholders/${policy.policyholder.id}/timeline/message`, message);
    } catch (err) {}
  }

  const createPolicyMessage = async (message) => {
    try {
      await api.post(`/external-sales/policies/${policy.id}/timeline/message`, message);
    } catch (err) {}
  }

  return (
    policy && (
      <div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/external-sales/policies" className="open active">
              Policies
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">View policy</span>
          </li>
        </ol>

        <div className="container">
          <header className="py-4 d-flex align-items-center justify-content-between border-bottom">
            <div>
              <div className="d-flex">
                <h3 style={{ fontSize: 36 }}>
                  Policy,{' '}
                  <span className="font-xl mt-2- fw-400 text-gray-700">
                    {policy.ref}
                  </span>
                  <span className="badge badge-primary badge-pill font-sm py-2 px-3 ml-3">
                    {policy.status === 'Cancelled' ? policy.status : 'Dealer'}
                  </span>
                </h3>
              </div>
            </div>

            <div className="d-flex" style={{ gap: 20 }}>
              {isPolicyApproved() && (
                <button
                  className="btn btn-outline-info btn-md"
                  disabled={sendingPolicyDocumentUpdateEmail}
                  onClick={sendPolicyDocumentUpdateEmail}
                >
                  Policy document update email
                </button>
              )}

              {isPolicyLapsed() && (
                <button
                  className="btn btn-outline-info btn-md"
                  onClick={sendPolicyLapsedEmail}
                >
                  Policy lapsed email
                </button>
              )}

              {hasPermission('direct_sales.policies.update') &&
                <>
                  {hasPermission('external_sales.policies.update_status') && policy.status !== 'Cancelled' && (moment(policy.end_date).isAfter(moment())) && (
                    <button
                      className="btn btn-outline-danger btn-md"
                      onClick={onCancel}
                    >
                      Cancel
                    </button>
                  )}
                  {isPolicyActive() &&
                    <button className="btn btn-outline-info btn-md" onClick={transferPolicy}>Transfer Policy</button>
                  }
                </>
              }
            </div>
          </header>

          <div className="row">
            <div className="col-8 border-right">
              <DealerDetails dealer={policy.dealer} />
              <VehicleDetails
                vehicle={policy.vehicle}
                allowEdit={hasPermission('external_sales.policies.update')}
                endpoint={`/external-sales/policies/${policy.id}/vehicle`}
                refresh={fetchPolicy}
                coverLevel={policy.cover_level}
              />
              <PolicyholderDetails policyholder={policy.policyholder} />
              <div className="pt-1">
                <hr className="mt-4" style={{ height: '20px' }} />
              </div>
            </div>
            <div className="col-4">
              <PolicyDetails policy={policy} refreshPolicy={fetchPolicy} />
            </div>
            <div className="col-4">
              <PolicyStatusForm
                onSubmit={handlePolicyStatusSubmit}
                initialValues={{
                  status: {
                    value: policy.status,
                  },
                  sub_status: {
                    value: policy.sub_status,
                  },
                }}
                statusText={policy.status}
              />
            </div>
          </div>

          <div className="row mb-4">
              <div className="col pr-5">
                <hr />
                <AdminComments key={forceRerender} />
              </div>
            </div>

        </div>
      </div>
    )
  )
}

Policy.propTypes = {}

export default connect(
  (state) => ({
    loggedInPerson: state.authentication.user,
  }),
  null
)(Policy);
