import React from 'react'
import PropTypes from 'prop-types'
import { confirmAlert } from 'react-confirm-alert';
import { hasPermission } from '../../../../utils/auth';
import { echo } from '../../../../utils/misc';
import Avatar from '../../../ui/Avatar';

const SystemUsers = ({dealer, onSuspendUser}) => {

  const showSuspendUserModel = (contact) => {
    confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>Suspend <strong>{contact.first_name + ' ' + contact.last_name}</strong>'s user account?. They will not be able to login any more.</p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  onSuspendUser(contact.user.id)
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  return (

    <div className="container">

      {dealer && dealer.contacts &&
      <div className="row">
        <div className="col-12">

          {dealer.contacts.map((contact, index) => {
            return (
            <div key={index} className={`contact row d-flex flex-start ${index < dealer.contacts.length - 1 ? 'mb-5' : ''}`}>
              <div className="col-1 pt-1">
                <Avatar size="medium" user={contact} />
              </div>
              <div className="col-7">
                <span className="label">{echo(contact.first_name) + ' ' + echo(contact.last_name)}</span>
                <p className="font-md" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{echo(contact.email)}</p>
              </div>
              <div className="col-4 pt-2" data-contact-id={contact.id}>
              {contact.user && contact.user.is_active && hasPermission('dealer_portal.suspend_user') &&
                <button
                  type="button"
                  onClick={() => showSuspendUserModel(contact)}
                  className="btn btn-warning btn-sm font-sm"
                  style={{ width: '100%' }}
                >
                  Suspend this user
                </button>
              }
              {(!contact.user || !contact.user.is_active) &&
                <p className="font-sm font-weight-bold text-lightgray" style={{ textAlign: 'center' }}>Inactive</p>
              }
              </div>
            </div>
            );
          })}

        </div>
      </div>
      }
    </div>
  )

}

SystemUsers.propTypes = {
  dealer: PropTypes.object.isRequired,
  onSuspendUser: PropTypes.func.isRequired,
}

export default SystemUsers
