import React from 'react'

export const coveredComponentsDynamicMarkup = <>
  <div className="mb-5">
    <h1><i className="wf-icon-exclusive-cover d-inline-block mr-1"></i> Exclusive</h1>
    <p>Exceptional coverage across almost all mechanical and electrical components..</p>
  </div>

  <hr/>

  <div className="my-5">
    <h2 className="my-4">What's included?</h2>
    <p className="small">You can make multiple claims, up to the current value of your vehicle (based on
      Glass’s Guide
      industry prices) whilst your vehicle is covered by us.</p>
    <p>Almost all of the original factory fitted mechanical and electrical components in your vehicle
      are covered against mechanical breakdown for the duration of our Exclusive plan.</p>
  </div>

  <h5 className="mb-3">Which parts are NOT covered?</h5>
  <p className="small mb-5">Due to an extensive list of covered parts within our Exclusive cover level
    we list the exclusions,
    unlike our Dynamic and Premier cover levels where we list the covered parts.</p>

  <div>
    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Bodywork etc</h5>
    <p className="small">Bodywork, paintwork, trims, upholstery and windows: Bodywork panels and
      bumpers, door handles,
      folding roofs & folding roof frames & mechanisms, glass & non-glass windows, hinges, interior &
      exterior trims, lifting struts, paintwork, seat frames & upholstery, sunroofs & sunroof
      mechanisms, tonneaus & other interior & exterior covers & associated mechanisms, weather seals and
      windscreens.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Computer software and firmware</h5>
    <p className="small">Upgraded or revised software and firmware, including software and firmware
      patches and updates, relating directly or indirectly to any components of your vehicle.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Hybrid and battery powered vehicle
      components</h5>
    <p className="small">
      Battery control modules, battery cooling systems, electrical inverters and converters, hybrid
      control modules, main drive batteries and traction motors.
    </p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Miscellaneous components</h5>
    <p className="small">Auxiliary drive belts, brackets & mountings, cables, casings (unless they have
      been damaged as the result of the breakdown of a part), diesel glow plugs, electrical connectors,
      engine covers, engine starting battery & all other batteries, fuel & fuel lines, fuses, heating
      elements & aerials integral components not approved by the manufacturer and fitted by them, or
      fitted by their agents, when your vehicle was new and before it was first used.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Servicing & maintenance related
      components</h5>
    <p>
      All components and fluids which require periodic replacement as part of the manufacturer’s
      recommended servicing (including but not limited to):anti-freeze, brake fluid, filters, grease,
      oils, sparking plugs and refrigerants), clutch covers, clutch friction plates, clutch release
      bearings, brake drums & brake discs & brake friction materials (brake pads & brake shoes),exhaust
      pipes & exhaust pipe gaskets, flywheels that have been damaged by worn clutch friction plates, and
      tyres.
    </p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Important notes</h5>
    <p className="small">
      Sundries such as coolants, filters, fluids, lubricants, oils and other working materials will be
      covered provided the vehicle is not within 1,000 miles of its next due service, and to include
      refrigerants, all provided their replacement is necessitated by a valid claim. If your vehicle is
      fitted with a clutch operating cable this will be covered.
    </p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Wear and tear cover</h5>
    <p className="small">All wear and tear items from Premier Cover, up to 110,000 miles and 10 years
      (whichever is sooner), with the addition of the following items up to 80,000 miles and 7 years
      (whichever is sooner). Water pump (subject to documented proof that the last due change of water
      pump has taken place as specified by the manufacturers recommended servicing schedule),
      Turbocharger.</p>

  </div>
</>

export default coveredComponentsDynamicMarkup