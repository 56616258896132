import React, { Component, Fragment } from 'react'
// import CreateCoverLevelForm from './CreateCoverLevelForm'
import { connect } from 'react-redux'
import { isValid, getFormValues } from 'redux-form'
import {
  addDirectSalesCoverLevel,
  resetForm,
} from '../../../actions/cover-levels.actions'
import { history } from '../../../utils/history';
import { Link } from 'react-router-dom'
import CreateCoverLevelForm from './CreateCoverLevelForm'

class DirectSalesCreatePage extends Component {
  cancel = e => {
    e.preventDefault()
    this.props.resetForm('direct-sales-cover-levels/create')
    history.push('/admin/direct-sales-cover-levels')
  }
  render() {
    return (
      <Fragment>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin/direct-sales-cover-levels" className="open active">
              Direct sales cover levels
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">Add a cover level</span>
          </li>
        </ol>

        <div className="container-fluid animated fadeIn">
          <div className="mw-37-5rem mx-auto">
            <CreateCoverLevelForm
              onSubmit={this.props.addDirectSalesCoverLevel}
              valid={this.props.valid}
              cancel={this.cancel}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default connect(
  state => ({
    valid: isValid('direct-sales-cover-levels/create')(state),
    values: getFormValues('direct-sales-cover-levels/create')(state),
  }),
  { addDirectSalesCoverLevel, resetForm },
)(DirectSalesCreatePage)
