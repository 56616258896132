import React from 'react'
import PropTypes from 'prop-types'

const ProductTileSnapshot = props => {
  const { title, description, unmetCriteriaMessage, select, payNow, meta, coverLevel } = props
  return (
    <div className="product-tile-snapshot px-3">
      <h5 className="text-uppercase mt-3">{title}</h5>
      <p className="plan-description">{description}</p>
      {unmetCriteriaMessage ?
       <p className="no-price">{unmetCriteriaMessage}</p>
                            :
       <div className="toggle-options">
         <span className="text-uppercase label small">From&nbsp;</span>
         {!payNow ?
          <>
            <span className="price monthly">{meta && meta.monthly}</span>
            <span className="text-uppercase label small monthly">
              &nbsp;Per month
            </span>
          </>
                  :
          <>
            <span className="price annual">{meta && meta.annual}</span>
          </>}

       </div>}
      {meta && !meta.msg && <button
        type="submit"
        onClick={() => select(coverLevel, payNow)}
        className="btn-secondary btn btn-block mb-3"
      >
        Choose plan and view quote
      </button>}
    </div>
  )
}

ProductTileSnapshot.propTypes = {
  title               : PropTypes.string.isRequired,
  description         : PropTypes.string.isRequired,
  price               : PropTypes.any,
  unmetCriteriaMessage: PropTypes.string,
  select              : PropTypes.func,
  payNow              : PropTypes.bool.isRequired,
}

export default ProductTileSnapshot