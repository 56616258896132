import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import api from '../../../../../utils/api'
import { NotificationManager } from 'react-notifications'
import TimelineHeader from '../../../../ui/timeline/TimelineHeader'
import Timeline from '../../../../ui/timeline/Timeline'
import TimelineSidebarCard from '../../../../ui/TimelineSidebarCard'
import moment from 'moment'
import { get } from 'lodash'
import Avatar from '../../../../ui/Avatar'
import ProspectCard from './ProspectCard'

const ProspectTimeline = (props) => {
  const params = useParams()
  const [prospect, setProspect] = useState(null)
  const [agents, setAgents] = useState(null)
  const [activeTab, setActiveTab] = useState(0)

  const loggedInPersonID = useSelector(
    (state) => state.authentication.user && state.authentication.user.person_id
  )

  const loadProspect = () => {
    api
    .get(`/direct-sales/prospects/${params.id}`)
    .then((res) => {
      setProspect(res.data)

      let quotes = get(res.data, 'quotes')
      let agents =
        quotes &&
        quotes
          .map((quote) => {
            if (quote.pipeline_item) {
              return quote.pipeline_item.assignee
            }
            return null
          })
          .filter(Boolean)

      setAgents(agents)
    })
    .catch((err) => {
      if (err) {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again'
        )
      }
      setProspect(null)
    })
  }

  useEffect(() => {
    loadProspect()
  }, [params.id]);
 
  return !prospect ? (
    <div>Loading</div>
  ) : (
    <>
      <ol className="breadcrumb mb-0">
        <li className="breadcrumb-item">
          <Link to={`/direct-sales/prospects`} className="open active">
            Prospects
          </Link>
        </li>
        <li className="breadcrumb-item">
          <span className="active">Prospect timeline</span>
        </li>
      </ol>
      <TimelineHeader user={prospect}/>
      <div className="bg-grey pt-5 pl-4 pr-4" style={{ paddingBottom: '60px' }}>
        <div className="timeline-page-container animated fadeIn pb-6">
          <div className="timeline-page-content">
            <div className="d-flex flex-column align-self-start border-0 flex-grow-1">
              <ProspectCard
                prospect={prospect}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                reloadProspect={loadProspect}
              />

              {loggedInPersonID && (
                <Timeline
                  personId={prospect.id}
                  for={`${prospect.first_name} ${prospect.last_name}`}
                  loggedInPersonID={loggedInPersonID}
                  apiUrl={`/direct-sales/prospects/${prospect.id}/timeline`}
                />
              )}
            </div>

            <div className="wf-timeline-sidebar">

              <div className="wf-timeline-card shadow-dark bg-white ph-claim-limits">
                <h6 className="h6 fw-600 font-sm">ASSIGNED TO AGENT</h6>
                {agents && agents.length > 0 && <div className="d-flex align-items-center">
                  <Avatar size={'medium'} user={agents[activeTab]}/>
                  <span className="ml-2">{agents[activeTab].first_name} {agents[activeTab].last_name}</span>
                </div>}
                {agents && agents.length === 0 && <div>No assigned agent.</div>}
              </div>

              {prospect.policies.length > 0 && (
                <TimelineSidebarCard
                  title="ACTIVE POLICIES"
                  data={prospect.policies}
                  renderRow={(row, index) => {
                    return (
                      <div key={index} className="mb-2">
                        <div className="font-sm font-weight-medium">
                          {moment(row.end_date).format('DD/MM/YYYY')}
                        </div>
                        <Link to="#" className="font-sm">
                          {row.ref}
                        </Link>
                      </div>
                    )
                  }}
                />
              )}
            </div>
            {/*<TimelineSidebar policyHolder={policyHolder} activePolicyTab={this.state.activePolicyTab}/>*/}
          </div>
        </div>
      </div>
    </>
  )
}

ProspectTimeline.propTypes = {
  policyHolder: PropTypes.object,
}

export default ProspectTimeline
