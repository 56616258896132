import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

export const PermissionListItem = ({
  index,
  name,
  allPermissions,
  selectAll,
  handleChange,
  permissions,
  deselect,
  expanded
}) => {
  const [open, setOpen] = React.useState(false)
  const [allPermissionsSelected, setAllPermissionsSelected] = React.useState(
    false,
  )

  React.useEffect(() => {
    if (expanded) {
      setOpen(true)
    }
  }, [])
  
  const handleToggle = e => {
    setOpen(!open)
  }
  const handleSelectAll = e => {
    e.stopPropagation()
    setAllPermissionsSelected(true)
    // e.g permissions['Profile']
    selectAll(allPermissions[name])
  }
  const handleDeselect = e => {
    e.stopPropagation()
    setAllPermissionsSelected(false)
    // e.g permissions['Profile']
    deselect(allPermissions[name])
  }
  return (
    <ul className="list-group list-group--permissions">
      <li
        className="list-group-item font-sm fw-600 list-group-item-action d-flex justify-content-between align-items-center"
        onClick={handleToggle}
      >
        {name}

        {open ? (
          <div className="d-flex flex-end align-items-center">
            {allPermissionsSelected ? (
              <div
                className="text-blue font-sm fw-400 mr-3"
                onClick={handleDeselect}
              >
                Deselect all
              </div>
            ) : (
              <div
                className="text-blue font-sm fw-400 mr-3"
                onClick={handleSelectAll}
              >
                Select all
              </div>
            )}
            <i className="wf-icon-minus" style={{ pointerEvents: 'none' }} />
          </div>
        ) : (
          <i className="wf-icon-plus" style={{ pointerEvents: 'none' }} />
        )}
      </li>
      <div className={`permissions-list ${open ? '' : 'collapse'}`}>
        {Object.entries(allPermissions[name]).map((row, index) => {
          return (
            <div
              className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
              key={index}
              style={index % 2 === 0 ? { background: '#f8f8f8' } : {}}
              onClick={() => handleChange(row[0])}
            >
              {row[1]}
              <div
                className="custom-control custom-checkbox custom-control-inline"
                onClick={e => {
                  e.preventDefault()
                  handleChange(row[0])
                }}
              >
                <input
                  className="custom-control-input"
                  id={row[0]}
                  type="checkbox"
                  checked={_.includes(permissions, row[0])}
                  onChange={() => {}}
                />
                <label className="custom-control-label" htmlFor={row[0]}>
                  Allow
                </label>
              </div>
            </div>
          )
        })}
      </div>
    </ul>
  )
}

PermissionListItem.propTypes = {
  expanded: PropTypes.bool
}

PermissionListItem.defaultProps = {
  expanded: false
}