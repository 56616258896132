import validator from 'validator'

const validate = fields => {
  let errors = {}
  if (!fields.first_name)
    errors.first_name = 'Please give this user a first name.'
  if (fields.first_name && fields.first_name.length < 2)
    errors.first_name = 'Must be 2 characters or more.'
  if (fields.first_name && fields.first_name.length > 50)
    errors.first_name = 'Must be 50 characters or less.'

  if (!fields.last_name) errors.last_name = 'Please give this user a last name.'
  if (fields.last_name && fields.last_name.length < 2)
    errors.last_name = 'Must be 2 characters or more.'
  if (fields.last_name && fields.last_name.length > 50)
    errors.last_name = 'Must be 50 characters or less.'

  if (!fields.email) errors.email = 'Please give this user an email address.'
  if (fields.email && !validator.isEmail(fields.email))
    errors.email = `That email address doesn't look quite right.`
  
  if (!fields.groups)
    errors.groups = 'Please assign this user to at least one group.'

  return errors
}

export default validate
