import React, { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import {Col, Row} from "reactstrap";

const CustomPricingHeader = ({ dealer, action }) => {

  useEffect(() => {
    // odd scroll behaviour fix.
    window.scroll(0, 0)
  }, [])

  const resetModal = () => {
    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>Reset Pricing to base price band?</p>
              <button
                className="btn btn-secondary" onClick={() => {
                onClose()
                action()
              }}
              >
                Yes, I'm sure
              </button>
              <button className="btn btn-transparent text-secondary" onClick={onClose}>
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  return (
    <Row>
      <Col sm="7">
        <h1 className="page-title">Custom Pricing</h1>
        <p>{dealer.name}</p>
      </Col>
      <Col sm="5" style={{textAlign: 'right'}}>
        <button onClick={() => resetModal()} className="btn btn-secondary" data-tip="Reset Prices" data-place="top">
          Reset Prices to Base
        </button>
      </Col>
    </Row>
  );
};

export default CustomPricingHeader;
