import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form, reduxForm } from 'redux-form'
import { fetchSingleTerritory } from '../../../actions/territories.actions'
import { connect } from 'react-redux'
import renderField from '../../../utils/renderField'

const TerritoryEditForm = props => {

  const { cancel, handleSubmit, handleDelete, onSubmit} = props

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="mb-4">Edit territory</h2>

      <div className="row">
        <div className="col-md-12">
          <Field
            type="text"
            label="Territory name"
            component={renderField}
            name="name"
            className="form-control"
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col">
          <button disabled={!props.valid} className="btn btn-secondary">
            Save changes
          </button>
          <button
            className="btn btn-outline"
            style={{color: '#70b725'}}
            onClick={cancel}
          >
            Cancel
          </button>
        </div>
        <div className="col d-flex justify-content-end btn-delete">
          <button
            type="button"
            className="btn-delete-resource"
            onClick={() => handleDelete()}
          >
            <span className="icon-trash"/>
          </button>
        </div>
      </div>
    </Form>
  )
}

const validate = values => {
  let errors = {}

  if (!values.name) errors.name = 'Please give this territory a name.'
  if (values.name && values.name.length < 2)
    errors.name = 'Must be 2 characters or more.'
  if (values.name && values.name.length > 50)
    errors.name = 'Must be 50 characters or less.'

  return errors
}

TerritoryEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
}


export default connect(
  state => ({
    initialValues: state.territories.singleTerritory,
  }),
  {fetchSingleTerritory},
)(
  reduxForm({
    form: 'territory/edit',
    enableReinitialize: true,
    validate,
  })(TerritoryEditForm),
)
