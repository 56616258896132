import React, { useState, useEffect } from 'react'
import { times } from 'lodash'
import moment from 'moment'
import { money } from '../../../../../utils/misc'

const TeamTargetTable = ({ year, budgets }) => {
  const [totalBudget, setTotalBudget] = useState(0)

  useEffect(() => {
    setTotalBudget(
      budgets.reduce((total, current) => {
        return total + parseFloat(current)
      }, 0)
    )
  }, [budgets])

  return (
    <>
      <table
        className={
          'table table-expand table-striped-inverse table-bordered table-individualTargets'
        }
      >
        <tbody>
          <tr>
            <td>&nbsp;</td>
            <td colSpan={2}>
              <label className={'label mb-2'}>Annual Total</label>
            </td>
            {times(12, (idx) => {
              return (
                <td key={idx}>
                  <label className={'label mb-2'}>
                    {moment().month(idx).format('MMM')} {year}
                  </label>
                </td>
              )
            })}
          </tr>
          <tr>
            <td>Team Budget set by Directors</td>
            <td>100% Vol</td>
            <td>{money.format(totalBudget)}</td>
            {times(12, (idx) => {
              return (
                <td key={idx}>
                  <label className={'label mb-2'}>
                    {money.format(budgets[idx])}
                  </label>
                </td>
              )
            })}
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default TeamTargetTable
