import React from 'react'
import { Field } from 'redux-form'
import renderField from '../../../../../utils/renderField'

const AdditionalInfo = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-4">
          <h6 className="text-uppercase">Additional information (optional)</h6>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 b-form-group">
          <Field
            type="text"
            label="Previous warranty provider"
            component={renderField}
            name={`existing_warranty_provider`}
            className="form-control"
          />
        </div>
        <div className="col-md-6 b-form-group">
          <Field
            type="text"
            label="Policies per month"
            component={renderField}
            name={`policies_per_month`}
            className="form-control"
          />
        </div>
        <div className="col-md-6 b-form-group">
          <Field
            type="text"
            label="Potential monthly revenue"
            component={renderField}
            fieldIcon="wf-icon-gbp"
            name={`potential_revenue`}
            className="form-control"
          />
        </div>
        <div className="col-md-6 b-form-group">
          <Field
            type="text"
            label="Number of cars in stock"
            component={renderField}
            name={`cars_in_stock`}
            className="form-control"
          />
        </div>
        <div className="col-md-6 b-form-group">
          <Field
            type="text"
            label="Lowest stock price"
            component={renderField}
            fieldIcon="wf-icon-gbp"
            name={`min_stock_price`}
            className="form-control"
          />
        </div>
        <div className="col-md-6 b-form-group">
          <Field
            type="text"
            label="Highest stock price"
            component={renderField}
            fieldIcon="wf-icon-gbp"
            name={`max_stock_price`}
            className="form-control"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 b-form-group">
          <Field
            type="textarea"
            label="Other"
            component={renderField}
            name={`comments`}
            className="form-control"
            placeholder="Reg company number, Directors name(s) & Reg address"
          />
        </div>
        <div className="col">
          <hr className="pb-1"/>
        </div>
      </div>
    </>
  )
}

export default AdditionalInfo
