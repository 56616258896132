import React, { Component, Fragment } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import api from '../../../utils/api'
import PageHeader from '../../ui/PageHeader'
import {
  fetchTerritories,
  territoriesTableRowAdd,
  territoriesTableRowRemove,
  deleteTerritory,
} from '../../../actions/territories.actions'
import { connect } from 'react-redux'
import { sortCaret } from '../../../utils/misc'
import Table from '../../ui/Table'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import ReactTooltip from 'react-tooltip'
import Avatar from "../../ui/Avatar";
import { NotificationManager } from 'react-notifications';

class TerritoriesPage extends Component {
  state = {
    filterGroups: [],
    query: '',
    page: 1,
    desc: false,
    limit: 10,
    order: '',
    account_manager: '',
    refreshingTerritories: false,
  }

  componentDidMount() {
    this.props.fetchTerritories()
    this.getAccountManagers()
  }

  handleDelete = (id, row) => {
    confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({title, onClose}) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose}/>
              <h3>{title}</h3>
              <p>
                Delete the territory <strong>{row.name}</strong>? It'll be lost
                forever.
              </p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  this.props.deleteTerritory(id)
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  export = () => {
    api
      .get(`admin/territories/excel`, {
        responseType: 'blob',
      })
      .then(response => {
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(response.data)
        link.download = 'territories.xlsx'
        link.click()
      })
  }

  getAccountManagers = query =>
    new Promise((resolve, reject) => {
      api
        .get(`admin/territories/account-managers?query=${query ? query : ''}`)
        .then(res => {
          let ams = res.data.data

          ams = ams.map(am => {
            am.name = am.first_name + ' ' + am.last_name
            am.avatar = true // Defaulting to true to show the placeholder avatar
            return am
          })

          this.setState({ filterGroups: [{name: 'account managers', filters: ams}, ...this.state.filterGroups] })
          resolve(res.data.data)
        })
    })

  handleTableChange = (type, {sortField, sortOrder, data, page, sizePerPage}) => {
    this.setState({
      page: page || 1,
      desc: (sortOrder === 'desc') ? true : false || false,
      limit: sizePerPage || 10,
      order: sortField || '',
    }, this.buildUrlAndFetchTerritories)
  }

  handleSearch = (query, filters) => {
    let account_manager = '';

    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]

      if (filter.group_name === 'account managers') account_manager = filter.name

    }

    this.setState({
      query,
      account_manager
    }, this.buildUrlAndFetchTerritories)
  }

  buildUrlAndFetchTerritories = () => {
    const {
      query,
      page,
      desc,
      limit,
      account_manager
    } = this.state

    let url = `?query=${query}&page=${page}&desc=${desc}&limit=${limit}`

    if (account_manager !== '') url = `${url}&current_account_manager=${account_manager}`

    this.props.fetchTerritories(url)
  }

  refreshTerritories = () => {
    if (!this.state.refreshingTerritories) {
      this.setState({ refreshingTerritories: true })
      api.patch(`/external-sales/dealers/refresh-territories`)
      .then(() => {
        NotificationManager.success('Woohoo! You refreshed all dealer territories.')
      })
      .catch(err => {
        if (err) {
          NotificationManager.error('Oops! Something went wrong. Please try again after refreshing.')
        }
      })
      .finally(() => {
        this.setState({ refreshingTerritories: false })
      })
    }
  }

  render() {
    const {isPending, territories} = this.props

    const options = {
      showOptions: false,
      columns: [
        {
          dataField: 'name',
          text: 'Area',
          sort: true,
          sortCaret,
          headerStyle: {
            width: '35%',
          },
        },

        {
          dataField: 'current_account_manager',
          text: 'Account Manager',
          headerStyle: {
            width: '15%',
          },
          sort: true,
          sortCaret,
          formatter: (cell, row) => {
            return (
              <div className="d-flex align-items-center">
                {row.current_account_manager_profile && row.current_account_manager_profile.user && <Avatar user={row.current_account_manager_profile.user} />}
                &nbsp;{row.current_account_manager_profile && (row.current_account_manager_profile.first_name + ' ' + row.current_account_manager_profile.last_name)}
              </div>
            )
          },
        },
        {
          dataField: 'postcodes',
          text: 'Postcodes',
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          formatter: (cell, row) => {
            let postcodes = row.postcodes

            return postcodes.map((p, i) => {
              let postcode = `${p}, `
              if (i === postcodes.length - 1) {
                postcode = `${p}`
              }

              return <span key={i}>{postcode}</span>
            })
          },
        },
        {
          dataField: 'id',
          text: 'Actions',
          headerStyle: {
            width: 100,
          },
          formatter: (cell, row) => (
            <div className="d-flex align-items-center">
              <Link
                data-tip="Edit territory"
                data-place="top"
                to={`/admin/territories/${row.id}/edit`}
                className="table-action-btn"
                primary="true"
                size="sm"
              >
                <span className="icon-note"/>
              </Link>
              <Link
                data-tip="Delete territory"
                data-place="top"
                to={`#`}
                className="table-action-btn table-action-btn--delete"
                size="sm"
                onClick={() => this.handleDelete(row.id, row)}
              >
                <span className="icon-trash"/>
              </Link>
              <ReactTooltip effect="solid"/>
            </div>
          ),
        },
      ],
      groups: [],
    }

    return (
      <Fragment>
        <br/>
        <div className="container-fluid animated fadeIn">
          <PageHeader
            title="Territories"
            description="View, edit and assign Account Managers to UK territories here."
            buttonLink={'/admin/territories/create'}
            buttonText="Add a territory"
            buttonIcon="d-block wf-icon-add"
          />

          {this.state.refreshingTerritories ? (
          <button
            className="btn btn-primary disabled btn-sm font-sm mt-1 mr-3 mb-3">
            Please wait...
          </button>
          ) : (
          <button
            onClick={this.refreshTerritories}
            className="btn btn-primary btn-sm font-sm mt-1 mr-3 mb-3">
            Refresh Prospect & Dealer Links
          </button>
          )}

          <br/>

          {territories && (
            <Table
              resource={territories}
              loading={isPending}
              showOptions={options.showOptions}
              handleTableChange={this.handleTableChange}
              hasTableSearch
              searching={false}
              onSearch={this.handleSearch}
              omnibox={{
                groups: this.state.filterGroups
              }}
              columns={options.columns}
              dispatch={this.props.dispatch}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTerritories,
      territoriesTableRowAdd,
      territoriesTableRowRemove,
      dispatch,
      deleteTerritory,
    },
    dispatch,
  )

export default connect(
  state => ({
    isPending: state.territories.isPending,
    territories: state.territories.data,
    selectedRows: state.territories.selectedRows,
  }),
  mapDispatchToProps,
)(TerritoriesPage)
