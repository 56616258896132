import React from 'react'
import PageHeader from "../../ui/PageHeader";
import {hasPermission, isSuperAdmin} from "../../../utils/auth";
import {API_URL} from "../../../utils/constants";
import useModal from "../../../hooks/useModal";
import Modal from "../../modals/Modal";
import DatePickers from "./forms/DatePickers";
import {connect} from 'react-redux';
import {getFormValues, isInvalid, reduxForm} from "redux-form";
import moment from "moment";
import DealerAndDatePickers from './forms/DealerAndDatePickers';

const ReportIndex = ({
  dateRangeFormValues,
  dateRangeFormInvalid,
  dealerAndDateRangeFormValues,
  dealerAndDateRangeFormInvalid,
}) => {
  const buttonGroupClass = 'btn btn-secondary btn-sm mb-1';
  const hasDealerReportsPermission = () => {
    return hasPermission('reports.dealer_statistics') ||
      hasPermission('reports.dealer_revenue') ||
      hasPermission('reports.dealer_claims') ||
      hasPermission('reports.dealer_profit') ||
      hasPermission('reports.dealer_recovery_revenue') ||
      hasPermission('reports.dealer_summaries') ||
      hasPermission('reports.dealer_cases') ||
      hasPermission('reports.dealer_quality') ||
      hasPermission('reports.dealer_projection')
  }

  const hasGeneralReportsPermission = () => {
    return hasPermission('reports.direct_sales_revenue') ||
      hasPermission('reports.renewal_summary') ||
      hasPermission('reports.master_sales');
  }

  const hasGeneralClaimsPermission = () => {
    return hasPermission('reports.claims_awaiting_approval') ||
      hasPermission('reports.claims_approved') ||
      hasPermission('reports.claims_approved_not_paid');
  }

  const downloadReport = (url) => {
    window.open(API_URL + `${url}?token=` + JSON.parse(localStorage.getItem('wf-token')));
  }

  const {
    isShowing: directSalesRevenueDateSelectorShowing,
    toggle: directSalesRevenueSelectorToggle
  } = useModal()

  const downloadDirectSalesRevenueSummary = () => {
    const url = new URL(`${API_URL}/downloads/csv/reports/direct-sales/revenue`)
    url.searchParams.append('token', JSON.parse(localStorage.getItem('wf-token')))
    url.searchParams.append('from', moment(dateRangeFormValues.date_from).format('YYYY-MM-DD'))
    url.searchParams.append('to', moment(dateRangeFormValues.date_to).format('YYYY-MM-DD'))
    window.open(url);
  }

  const {
    isShowing: renewalsDateSelectorShowing,
    toggle: renewalsDateSelectorToggle
  } = useModal()

  const downloadRenewalSummary = () => {
    const url = new URL(`${API_URL}/downloads/csv/reports/renewal_export`)
    url.searchParams.append('token', JSON.parse(localStorage.getItem('wf-token')))
    url.searchParams.append('from', moment(dateRangeFormValues.date_from).format('YYYY-MM-DD'))
    url.searchParams.append('to', moment(dateRangeFormValues.date_to).format('YYYY-MM-DD'))
    window.open(url);
  }

  const {
    isShowing: dealerSummariesSelectorShowing,
    toggle: dealerSummariesSelectorToggle
  } = useModal()
  const {
    isShowing: leagueTableSelectorShowing,
    toggle: leagueTableSelectorToggle
  } = useModal()

  const downloadDealerSummaries = () => {
    const url = new URL(`${API_URL}/downloads/csv/reports/dealer/summaries`)
    url.searchParams.append('token', JSON.parse(localStorage.getItem('wf-token')))
    url.searchParams.append('dealer_id', dealerAndDateRangeFormValues.dealer.id)
    url.searchParams.append('from', moment(dealerAndDateRangeFormValues.date_from).format('YYYY-MM-DD'))
    url.searchParams.append('to', moment(dealerAndDateRangeFormValues.date_to).format('YYYY-MM-DD'))
    window.open(url);
  }

  const downloadLeagueTable = () => {
    const url = new URL(`${API_URL}/downloads/csv/reports/dealer/league-table`)
    url.searchParams.append('token', JSON.parse(localStorage.getItem('wf-token')))
    url.searchParams.append('from', moment(dateRangeFormValues.date_from).format('YYYY-MM-DD'))
    url.searchParams.append('to', moment(dateRangeFormValues.date_to).format('YYYY-MM-DD'))
    window.open(url);
  }

  return <>
    <div className="container-fluid animated fadeIn bg-grey min-vh-100 dashboard-grey-extend">
      <br/>
      <div className="row">
        <div className="col-md-8">
          <PageHeader
            title="Report Dashboard"
            description={`View platform reports below.`}
          />
        </div>
      </div>
      <div className="row">
        {hasDealerReportsPermission() &&
        <div className="col-lg-4">
          <div className="card">
            <h4>Dealer Reports</h4>
            {hasPermission('reports.dealer_statistics') && <button onClick={() => downloadReport(`/downloads/csv/reports/dealer/statistics`)} className={buttonGroupClass}>Dealer Statistics</button>}
            {hasPermission('reports.dealer_revenue') && <button onClick={() => downloadReport(`/downloads/csv/reports/dealer/revenue`)} className={buttonGroupClass}>Revenue by Dealer</button>}
            {hasPermission('reports.dealer_claims') && <button onClick={() => downloadReport(`/downloads/csv/reports/dealer/claims`)} className={buttonGroupClass}>Claims by Dealer</button>}
            {hasPermission('reports.dealer_profit') && <button onClick={() => downloadReport(`/downloads/csv/reports/dealer/profit`)} className={buttonGroupClass}>Profit by Dealer</button>}
            {hasPermission('reports.dealer_recovery_revenue') && <button onClick={() => downloadReport(`/downloads/csv/reports/dealer/recovery-revenue`)} className={buttonGroupClass}>Recovery Revenue by Dealer</button>}
            {hasPermission('reports.dealer_summaries') && <button onClick={dealerSummariesSelectorToggle} className={buttonGroupClass}>Summaries by Dealer</button>}
            {hasPermission('reports.dealer_cases') && <button onClick={() => downloadReport(`/downloads/csv/reports/dealer/cases`)} className={buttonGroupClass}>Cases by Dealer</button>}
            {hasPermission('reports.dealer_quality') && <button onClick={() => downloadReport(`/downloads/csv/reports/dealer/quality`)} className={buttonGroupClass}>Dealer Quality</button>}
            {hasPermission('reports.dealer_projection') && <button onClick={() => downloadReport(`/downloads/csv/reports/dealer/projection`)} className={buttonGroupClass}>Dealer Projection</button>}
            {isSuperAdmin() && <button onClick={leagueTableSelectorToggle} className={buttonGroupClass}>League Table</button>}
          </div>
        </div>
        }
        {hasGeneralReportsPermission() &&
        <div className="col-lg-4">
          <div className="card">
            <h4>General Reports</h4>
            {hasPermission('reports.direct_sales_revenue') && <button onClick={directSalesRevenueSelectorToggle} className={buttonGroupClass}>Direct Sales Revenue</button>}
            {hasPermission('reports.renewal_summary') && <button onClick={renewalsDateSelectorToggle} className={buttonGroupClass}>Renewal Export</button>}
            {hasPermission('reports.renewal_summary') && <button onClick={() => downloadReport(`/downloads/csv/reports/renewal_summary`)} className={buttonGroupClass}>Renewal Summary</button>}
            {hasPermission('reports.master_sales') && <button onClick={() => downloadReport(`/downloads/csv/reports/master-sales`)} className={buttonGroupClass}>Master Sales</button>}
          </div>
        </div>
        }
        {hasGeneralClaimsPermission() &&
        <div className="col-lg-4">
          <div className="card">
            <h4>Claims Reports</h4>
            {hasPermission('reports.claims_awaiting_approval') && <button onClick={() => downloadReport(`/downloads/csv/reports/claims/awaiting_approval`)} className={buttonGroupClass}>Claims Awaiting Approval</button>}
            {hasPermission('reports.claims_approved') && <button onClick={() => downloadReport(`/downloads/csv/reports/claims/approved`)} className={buttonGroupClass}>Approved</button>}
            {hasPermission('reports.claims_approved_not_paid') && <button onClick={() => downloadReport(`/downloads/csv/reports/claims/approved_not_paid`)} className={buttonGroupClass}>Approved Unpaid Claims</button>}
            {hasPermission('reports.claims_approved_paid') && <button onClick={() => downloadReport(`/downloads/csv/reports/claims/approved_paid`)} className={buttonGroupClass}>Paid Claims</button>}
          </div>
        </div>
        }
      </div>
    </div>

    <Modal
      isShowing={directSalesRevenueDateSelectorShowing}
      title={'Select a date range'}
      subtitle={'Show direct sales policies sold in this range'}
      textAction={'Download Report'}
      hide={() => {directSalesRevenueSelectorToggle()}}
      onSubmit={() => {downloadDirectSalesRevenueSummary()}}
      submitDisabled={dateRangeFormInvalid}
    >
      <DatePickers handleSubmit={() => {}} />
    </Modal>

    <Modal
      isShowing={renewalsDateSelectorShowing}
      title={'Select a date range'}
      subtitle={'Show renewals from this range'}
      textAction={'Download Report'}
      hide={() => {renewalsDateSelectorToggle()}}
      onSubmit={() => {downloadRenewalSummary()}}
      submitDisabled={dateRangeFormInvalid}
    >
      <DatePickers handleSubmit={() => {}} />
    </Modal>

    <Modal
      isShowing={dealerSummariesSelectorShowing}
      title={'Dealer Summaries'}
      subtitle={'Show dealer summaries for a month'}
      textAction={'Download Report'}
      hide={() => {dealerSummariesSelectorToggle()}}
      onSubmit={() => {downloadDealerSummaries()}}
      submitDisabled={dealerAndDateRangeFormInvalid}
    >
      <DealerAndDatePickers handleSubmit={() => {}} />
    </Modal>

    <Modal
      isShowing={leagueTableSelectorShowing}
      title={'Select a date range'}
      subtitle={'Show league table from this range'}
      textAction={'Download Report'}
      hide={() => {leagueTableSelectorToggle()}}
      onSubmit={() => {downloadLeagueTable()}}
      submitDisabled={dateRangeFormInvalid}
    >
      <DatePickers handleSubmit={() => {}} />
    </Modal>

  </>
}

export default connect(
  state => ({
    dateRangeFormValues: getFormValues('report/daterange')(state),
    dateRangeFormInvalid: isInvalid('report/daterange')(state),
    dealerAndDateRangeFormValues: getFormValues('report/dealeranddaterange')(state),
    dealerAndDateRangeFormInvalid: isInvalid('report/dealeranddaterange')(state),
  }),
  null,
)(
  reduxForm({
    form: 'report-menu',
    destroyOnUnmount: false,
  })(ReportIndex),
)