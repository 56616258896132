import React, { useEffect, useRef, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import { NavLink } from 'react-router-dom'
import api from '../../../utils/api';
import NotificationsCentre from './NotificationsCentre'

const Notifications = props => {
  const [open, setOpen] = useState(false)
  const [hasNewNotifications, setHasNewNotifications] = useState(false)
  const node = useRef()

  useEffect(() => {
    api.get('/profile/notifications/unread-count')
      .then(res => {
        setHasNewNotifications(res.data.count > 0)
      })
      .catch(() => {
        NotificationManager.error('Oops! Something went wrong fetching your notifications.');
      })
  }, [])

  const handleBellClick = e => {
    e.preventDefault()
    setOpen(!open)
  }

  return (
    <div ref={node}>
      <NavLink
        to={'/admin/notifications'}
        onClick={handleBellClick}
        className={`nav-link-icon ${open ? 'active' : ''}`}
      >
        <i className="icon-bell text-black"/>
        <i className={`notification-dot ${hasNewNotifications ? '' : 'hidden'}`}/>
      </NavLink>

      {open && <NotificationsCentre setOpen={setOpen} open={open} setHasNewNotifications={setHasNewNotifications} />}
    </div>
  )
}

Notifications.propTypes = {}

export default Notifications