import React, {useEffect} from 'react'
import { sortCaret } from '../../../../../utils/misc'
import { uniqueId } from 'lodash'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import Table from '../../../../ui/Table'
import { useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import api from '../../../../../utils/api'
import {NotificationManager} from 'react-notifications'
import {hasPermission} from "../../../../../utils/auth";

const SpecialVehicles = ({dealer}) => {
  const [state, setState] = useState({
    total: 10,
    query: '',
    page: 1,
    desc: false,
    limit: 10,
    order: '',
    prices: null,
    searching: false,
    url: null,
    filterGroups: [
      {
        name: 'status',
        allowOnlyOne: true,
        filters: [
          { id: 5, name: 'Approved', value: 'Approved' },
          { id: 4, name: 'With manager', value: 'With manager' },
          { id: 6, name: 'Adjusted', value: 'Adjusted' },
        ],
      },
    ],
  })

  const [prices, setPrices] = useState([]);

  const fetchData = (url = '') => {
    api.get(`external-sales/dealers/${dealer.id}/special-vehicles${url}`)
      .then(res => {
        let data = res.data.data.map(v => ({
          id: v.id,
          make: v.make,
          model: v.models.map(model => (model.model)).join(', '),
          labour_rate: '',
          price_increase: v.percentage,
          type: v.type,
          status: v.status
        }))
        setPrices(data)
      })
      .catch(() => {
        NotificationManager.error('Oops! Something went wrong fetching the dealer special vehicles.')
      })
  }

  const oneNeedsApproving = () => {
    return !!(hasPermission('external_sales.dealers.update_special_vehicle_status') && (prices.filter(p => (p.status === 'Pending')).length));
  }

  // const prices = [
  //   {
  //     id: 1,
  //     make: 'Audi',
  //     model: 'R8',
  //     labour_rate: '£100',
  //     price_increase: '',
  //     type: 'Prestige',
  //     status: 'Approved',
  //   },
  //   {
  //     id: 2,
  //     make: 'Audi',
  //     model: 'R8',
  //     labour_rate: '£100',
  //     price_increase: '',
  //     type: 'Prestige',
  //     status: 'Approved',
  //   },
  //   {
  //     id: 3,
  //     make: 'Audi',
  //     model: 'R8',
  //     labour_rate: '£100',
  //     price_increase: '',
  //     type: 'Prestige',
  //     status: 'Approved',
  //   },
  //   {
  //     id: 4,
  //     make: 'Audi',
  //     model: 'R8',
  //     labour_rate: '£100',
  //     price_increase: '',
  //     type: 'Prestige',
  //     status: 'Approved',
  //   },
  //   {
  //     id: 5,
  //     make: 'Audi',
  //     model: 'R8',
  //     labour_rate: '£100',
  //     price_increase: '',
  //     type: 'Prestige',
  //     status: 'Approved',
  //   },
  //   {
  //     id: 6,
  //     make: 'Audi',
  //     model: 'R8',
  //     labour_rate: '£100',
  //     price_increase: '',
  //     type: 'Prestige',
  //     status: 'Approved',
  //   },
  //   {
  //     id: 7,
  //     make: 'Audi',
  //     model: 'R8',
  //     labour_rate: '£100',
  //     price_increase: '',
  //     type: 'Prestige',
  //     status: 'Approved',
  //   },
  //   {
  //     id: 8,
  //     make: 'Audi',
  //     model: 'R8',
  //     labour_rate: '£100',
  //     price_increase: '',
  //     type: 'Prestige',
  //     status: 'Approved',
  //   },
  //   {
  //     id: 9,
  //     make: 'Audi',
  //     model: 'R8',
  //     labour_rate: '£100',
  //     price_increase: '',
  //     type: 'Prestige',
  //     status: 'Approved',
  //   },
  //   {
  //     id: 10,
  //     make: 'Audi',
  //     model: 'R8',
  //     labour_rate: '£100',
  //     price_increase: '',
  //     type: 'Prestige',
  //     status: 'Approved',
  //   },
  //   {
  //     id: 11,
  //     make: 'Audi',
  //     model: 'R8',
  //     labour_rate: '£100',
  //     price_increase: '',
  //     type: 'Prestige',
  //     status: 'Approved',
  //   },
  // ]

  const handleTableChange = (
    type,
    { sortField, sortOrder, page, sizePerPage }
  ) => {
    let url = `?page=${page}&desc=${
      sortOrder === 'desc' ? true : false
    }&limit=${sizePerPage}&query=${state.query}`
    if (sortField) url = `${url}&order=${sortField}`

    setState((prev) => ({ ...prev, url }))

    fetchData(url)
  }

  //fetch on render
  useEffect(() => {
    fetchData()
  }, [])

  /**
   * Search
   * @param query
   */
  const handleSearch = (query) => {
    const { page, desc, limit } = state;
    let url = `?query=${query}&page=${page}&desc=${desc}&limit=${limit}`;
    fetchData(url)
  }

  const deleteSpecialVehicle = (id) => {
    api.delete(`/external-sales/dealers/${dealer.id}/special-vehicles/${id}`)
      .then(() => {
        NotificationManager.success('Woohoo! You deleted a dealer special vehicle.')
        fetchData();
      })
      .catch(() => {
        NotificationManager.error('Oops! Something went wrong deleting the special vehicle.')
      })
  }

  const updateSpecialVehicleStatus = (id, status) => {
    api.patch(`/external-sales/dealers/${dealer.id}/special-vehicles/${id}/status`, {status})
      .then(() => {
        NotificationManager.success('Woohoo! Vehicle Status Updated to ' + status);
        fetchData();
      })
      .catch(() => {
        NotificationManager.error('Oops! Something went wrong updating the vehicle status.');
        fetchData();
      })
  }

  const deleteModal = (vehicle) => {
    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>
                Delete the special vehicle{' '}
                <strong>
                  {vehicle.make} {vehicle.model}
                </strong>
                ? It'll be lost forever.
              </p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  deleteSpecialVehicle(vehicle.id)
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const options = {
    showOptions: false,
    columns: [
      {
        dataField: 'make',
        text: 'Make',
      },
      {
        dataField: 'model',
        text: 'Model',
      },
      {
        dataField: 'labour_rate',
        text: 'Labour rate',
        sort: true,
      },
      {
        dataField: 'percentage',
        text: 'Price increase',
        sort: true,
        sortCaret,
      },
      {
        dataField: 'type',
        text: 'Type',
        sort: true,
        sortCaret,
        formatter: (cell, row) => {
          return (
            <span
              key={row.id}
              style={{ backgroundColor: 'rgb(17, 30, 96)', marginRight: 2, height: 20}}
              className="badge badge-pill badge-secondary"
            >
              {row.type}
            </span>
          )
        },
      },

      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        sortCaret,
      },

      {
        dataField: uniqueId('actions_'),
        text: 'Actions',
        headerStyle: {
          width: oneNeedsApproving() ? 160 : 100,
        },
        formatter: (cell, row) => (
          <div className="d-flex align-items-center">
            <Link
              data-tip="Edit vehicle"
              data-place="top"
              to={`/external-sales/dealers/${dealer.id}/edit/special-vehicles/${row.id}`}
              className="table-action-btn"
              primary="true"
              size="sm"
            >
              <span className="wf-icon-edit" />
            </Link>
            <Link
              data-tip="Delete vehicle"
              data-place="top"
              to={`#`}
              className="table-action-btn table-action-btn--delete"
              size="sm"
              onClick={() => deleteModal(row)}
            >
              <span className="d-block wf-icon-delete" />
            </Link>
            {hasPermission('external_sales.dealers.update_special_vehicle_status') && (row.status === 'Pending') && <>
              <Link
                data-tip="Approve"
                data-place="top"
                to={`#`}
                onClick={() => updateSpecialVehicleStatus(row.id, 'Approved')}
                className="table-action-btn"
                primary="true"
                size="sm"
              >
                <span className="wf-icon-circle-tick" />
              </Link>
              <Link
                data-tip="Reject"
                data-place="top"
                to={`#`}
                onClick={() => updateSpecialVehicleStatus(row.id, 'Rejected')}
                className="table-action-btn"
                primary="true"
                size="sm"
              >
                <span className="wf-icon-x" />
              </Link>
            </>}
            <ReactTooltip effect="solid" />
          </div>
        ),
      },
    ],
  }

  return (
    <>
      {prices && (
        <div className="row">
          <div className="col">
            <div className="d-flex justify-content-between align-items-center mb-5">
              <h6 className="my-0 text-uppercase">Special vehicles</h6>
              <Link to={`/external-sales/dealers/${dealer.id}/edit/special-vehicles`}
                className="btn btn-sm btn-secondary font-sm"
                style={{ height: 30 }}
              >
                <i
                  className="d-block wf-icon-add float-left"
                  style={{ marginTop: '2px' }}
                />
                Add
              </Link>
            </div>
            <Table
              resource={{ total: 10, from: 1, to: 10, data: prices }}
              showOptions={options.showOptions}
              handleTableChange={handleTableChange}
              columns={options.columns}
              hasTableSearch={true}
              omnibox={{ groups: state.filterGroups }}
              searching={state.searching}
              onSearch={handleSearch}
              style={{ marginBottom: 'none' }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default SpecialVehicles
