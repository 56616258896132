import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { history } from "../../../../../utils/history";
import api from "../../../../../utils/api";
import {NotificationManager} from "react-notifications";
import { confirmAlert } from "react-confirm-alert";

const EditProspectHeader = ({ dealer }) => {

  useEffect(() => {
    // odd scroll behaviour fix.
    window.scroll(0, 0)
  }, [])

  const deleteDealer = async () => {
    const res = await api.delete(
      `/external-sales/dealers/${dealer.id}`
    )
    if (res.status === 200) {
      history.push('/external-sales/prospects')
      NotificationManager.success('Prospect dealer deleted successfully!')
    } else if (res.status === 404) {
      NotificationManager.error('The prospect dealer could not be found.')
    }
  }

  const deleteModal = () => {
    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>Delete the prospect dealer <strong>{dealer.name}</strong>? You can undo this later.</p>
              <button
                className="btn btn-secondary" onClick={() => {
                  onClose()
                  deleteDealer()
                }}
              >
                Yes, I'm sure
              </button>
              <button className="btn btn-transparent text-secondary" onClick={onClose}>
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const handleCreateAccount = () => {
    confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>
                Create a dealer account for <strong>{dealer.name}</strong>?
              </p>
              <button className="btn btn-secondary" onClick={() => {
                onClose()
                createAccount()
              }}>
                Yes, I'm sure
              </button>
              <button className="btn btn-transparent text-secondary" onClick={onClose}>
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const createAccount = () => {
    api.patch(`/external-sales/prospects/${dealer.id}/create-account`)
      .then(res => {
        NotificationManager.success('Woohoo! You created a dealer.')
        history.push(`/external-sales/dealers/${dealer.id}/edit`)
      })
      .catch(err => {
        NotificationManager.error('Oops! Could not create a dealer account for the prospect.')
      })
  }

  return (
    <div>
      <header className="py-4 d-flex align-items-center justify-content-between border-bottom">
        <h3 style={{ fontSize: 36 }}>Edit prospect</h3>
        <div>
          <button onClick={() => handleCreateAccount()} className="btn btn-success btn-md mr-3 fl-right">
            Create Account
          </button>
          <button onClick={() => deleteModal()} className="btn-gray" data-tip="Delete dealer" data-place="top">
            <i className="wf-icon-delete d-block"/>
          </button>
        </div>
      </header>
    </div>
  )
}

EditProspectHeader.propTypes = {
  dealer: PropTypes.object.isRequired
};

export default EditProspectHeader
