import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import CreateTerritoryForm from './CreateTerritoryForm'
import { history } from '../../../utils/history'
import { connect } from 'react-redux'
import { isValid } from 'redux-form'
import { createTerritory } from '../../../actions/territories.actions'
import api from '../../../utils/api'

class TerritoryCreate extends Component {
  state = {
    account_managers: [],
  }
  cancel = () => history.push('/admin/territories')

  componentDidMount() {
    this.getAccountManagers()
  }

  getAccountManagers = query =>
    new Promise((resolve, reject) => {
      api
        .get(`admin/territories/account-managers?query=${query ? query : ''}`)
        .then(res => {
          this.setState({ account_managers: res.data.data })
          resolve(res.data.data)
        })
    })

  render() {
    return (
      <Fragment>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin/territories" className="open active">
              Territories
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">Add a territory</span>
          </li>
        </ul>

        <div className="container-fluid animated fadeIn">
          <div className="mw-37-5rem mx-auto">
            <CreateTerritoryForm
              accountManagers={this.state.account_managers}
              fetch={this.getAccountManagers}
              onSubmit={this.props.createTerritory}
              cancel={this.cancel}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default connect(
  state => ({
    valid: isValid('territories/create')(state),
  }),
  { createTerritory },
)(TerritoryCreate)
