import React from 'react';
import Modal from '../../../../modals/Modal';
import { Field } from 'redux-form';
import renderField from '../../../../../utils/renderField';
import { numericality, required } from 'redux-form-validators';
import CustomSelect from '../../../../ui/CustomSelect';

const Modals = ({
  coverLevels,
  toggleModal,
  handleSpecialDurationSubmit,
  handleDurationSubmit,
  durationModal,
  specialDurationModal,
}) => {
  return (
    <>
      <Modal
        hide={toggleModal}
        isShowing={durationModal}
        title="Add duration"
        subtitle="e.g. 48 months"
        onCancel={toggleModal}
        onSubmit={handleDurationSubmit}
        textAction="Add duration"
        textCancel="Cancel"
      >
        <div className="row">
          <div className="mt-2 col">
            <Field
              name="pb_duration"
              type="text"
              label="Duration"
              className="w-50 form-control"
              component={renderField}
              validate={[
                required({
                  message: 'You must enter the duration.',
                }),
                numericality({
                  msg: 'You must enter a number greater than 0.',
                  '>': 0,
                }),
              ]}
              append={{
                direction: 'right',
                text: 'months',
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        hide={() => toggleModal('special')}
        isShowing={specialDurationModal}
        style={{ width: '435px' }}
        title="Special pricing rule"
        subtitle="e.g. Pay for 24 months, drive for 36"
        onCancel={() => toggleModal('special')}
        onSubmit={handleSpecialDurationSubmit}
        textAction="Save price rule"
        textCancel="Cancel"
      >
        <div className="mt-4 d-flex">
          <Field
            name="pb_duration"
            type="text"
            label="Pay for (duration)"
            className="w-50 form-control"
            component={renderField}
            validate={[
              numericality({
                msg: 'You must enter a number greater than 0.',
                allowBlank: false,
                '>': 0,
              }),
            ]}
            append={{
              direction: 'right',
              text: 'months',
            }}
          />
          <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
          <Field
            name="pb_drive"
            type="text"
            label="Drive for (duration)"
            className="w-50 form-control"
            component={renderField}
            validate={[
              numericality({
                msg: 'You must enter a number greater than 0.',
                allowBlank: false,
                '>': 0,
              }),
            ]}
            append={{
              direction: 'right',
              text: 'months',
            }}
          />
        </div>

        <div>
          {coverLevels && (
            <Field
              name="target_cover_level"
              component={CustomSelect}
              label="Choose cover level"
              options={coverLevels}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              validate={[
                required({
                  message: 'You must choose a cover level.',
                }),
              ]}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default Modals;
