import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import EditProspectHeader from './EditProspectHeader'
import { useParams } from 'react-router-dom'
import api from '../../../../../utils/api'
import { pick } from 'lodash'
import { NotificationManager } from 'react-notifications'
import DealerDetails from './DealerDetails'
import AdditionalInformation from '../../../../ui/AdditionalInformation'
import DealerContactDetails from '../../../../ui/DealerContactDetails'
import { SubmissionError } from 'redux-form'
import AddedBy from '../../../../ui/AddedBy'

const EditProspect = () => {
  const [dealer, setDealer] = useState(null)

  const params = useParams()

  const fetchDealer = () => {
    api
      .get(`/external-sales/prospects/${params.id}`)
      .then((res) => {
        setDealer(res.data)
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing'
          )
        }
      })
  }

  useEffect(() => {
    fetchDealer()
  }, [])

  const handleAdditionalInfoSubmit = (values) => {
    const data = pick(values, [
      'existing_warranty_provider',
      'policies_per_month',
      'potential_revenue',
      'cars_in_stock',
      'min_stock_price',
      'max_stock_price',
      'comments',
    ])
    return api
      .patch(`/external-sales/prospects/${dealer.id}/additional-info`, data)
      .then((res) => {
        setDealer(res.data)
        NotificationManager.success(
          'Woohoo! Additional information updated successfully!'
        )
      })
      .catch(_promiseCatch)
  }

  /**
   * Handle any errors on post request
   * @param err
   */
  const _promiseCatch = (err) => {
    if (err && err.response.status === 422) {
      throw new SubmissionError({
        ...err.response.data.errors,
        _error: err.response.data.message,
      })
    } else {
      NotificationManager.error(
        'Oops! Something went wrong. Please try again after refreshing'
      )
    }
  }

  return (
    dealer && (
      <>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/external-sales/prospects" className="open active">
              Prospects
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">Edit prospect</span>
          </li>
        </ol>

        <div className="mx-auto px-3" style={{ maxWidth: 980 }}>
          <EditProspectHeader dealer={dealer} />

          <div
            className="edit-dealer-container mx-auto position-relative d-flex"
            style={{ maxWidth: 980 }}
          >
            <div className="edit-dealer-column border-right flex-grow-1">
              {dealer && (
                <DealerDetails
                  isProspect={true}
                  dealer={dealer}
                  setDealer={setDealer}
                />
              )}

              <div className="row">
                <div className="edit-dealer-column col pb-4 border-bottom">
                  <DealerContactDetails dealer={dealer} setDealer={setDealer} />
                </div>
              </div>
            </div>

            <div className="edit-dealer-column pt-4 pl-4">
              <AddedBy
                user={{
                  id: 1,
                  first_name: 'John',
                  last_name: 'Doe',
                  avatar: null,
                }}
              />
            </div>
          </div>

          <AdditionalInformation
            dealer={dealer}
            formName={'prospect/additional-info'}
            onSubmit={handleAdditionalInfoSubmit}
          />
        </div>
      </>
    )
  )
}

export default EditProspect
