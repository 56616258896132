import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form, reduxForm } from 'redux-form'
import renderField from '../../../utils/renderField'
import { validPhoneNumber } from '../../../utils/misc'
import { email, required, url } from 'redux-form-validators'

const EditContactDetailsForm = ({
  handleSubmit,
  onSubmit,
  valid,
  dirty,
  toggleForm,
}) => {

  return (
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Telephone (office)"
              component={renderField}
              name={`tel_office`}
              className="form-control"
            />
          </div>
          <div className="col">
            <Field
              type="text"
              label="Telephone mobile"
              component={renderField}
              name={`tel_mobile`}
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Email address"
              component={renderField}
              name={`email`}
              validate={[required({ msg: 'You must enter an email address.' }), email({ message: 'That email address doesn\'t quite look right.' })]}
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Website"
              component={renderField}
              name={`website`}
              validate={[url({
                message: 'That URL doesn\'t quite look right.',
                allowBlank: true,
                protocolIdentifier: false
              })]}
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="col mt-3">
            <div className="form-group">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!valid || !dirty}
                onClick={() => toggleForm(false)}
              >
                Save changes
              </button>
              <button
                type="button"
                className="btn btn-outline btn-form-cancel"
                onClick={() => toggleForm(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Form>
  )
}

const validate = fields => {
  let errors = {}

  if (!fields.tel_office && !fields.tel_mobile)
    errors.tel_office = 'You must enter at least one telephone number.'

  if (fields.tel_office && !validPhoneNumber(fields.tel_office))
    errors.tel_office = 'That phone number doesn\'t look quite right.'

  if (fields.tel_mobile && !validPhoneNumber(fields.tel_mobile))
    errors.tel_mobile = 'That phone number doesn\'t look quite right.'

  return errors
}

EditContactDetailsForm.propTypes = {
  onSubmit  : PropTypes.func.isRequired,
  valid     : PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'garage-contact-edit',
  validate,
})(EditContactDetailsForm)