import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {change, Field, formValueSelector, reduxForm} from "redux-form";
import CustomSelect from "../../../../ui/CustomSelect";
import renderDatePicker from "../../../../ui/renderDatePicker";
import renderField from "../../../../../utils/renderField";
import api from "../../../../../utils/api";
import {get, sortBy, uniqBy} from "lodash";
import {money} from "../../../../../utils/misc";
import { hasPermission } from '../../../../../utils/auth';

const PolicyEditForm = ({
  onCancel,
  handleSubmit,
  policy,
  company_name,
  coverLevel,
  duration,
  claimLimit,
  excess,
  labourRate,
  sundries,
  policyPrice,
  dispatch,
  soldBy,
}) => {

  const [specialVehicle, setSpecialVehicle] = useState([]);
  const [coverLevels, setCoverLevels] = useState([]);

  const [soldByUsers, setSoldByUsers] = useState([])
  useEffect(() => {
    api.get('/dealer-portal/account-details')
      .then(res => {
        setSoldByUsers(res.data.contacts)
      })
  }, [])

  useEffect(() => {
    api.get(`/misc/special-vehicle-lookup`, {
      params: {
        make: policy.vehicle.make,
        model: policy.vehicle.model,
        target: 'Dealer',
        dealer_id: policy.dealer.id,
      }
    })
      .then(res => setSpecialVehicle(res.data))
      .catch(() => setSpecialVehicle(null))
  }, [])

  useEffect(() => {
    if (specialVehicle && specialVehicle.type === 'Prestige') {
      // force labour rate to £100
      labourRate = {id: -1, title: '100', value: 0.00}
      setLabourRateOptions([labourRate])
      dispatch(change('create-policy-policy', 'labour_rate', labourRate, true))
    }
  }, [specialVehicle])

  useEffect(() => {
    api.get(`/external-sales/policies/create/${policy.dealer.id}/plans`, {
      params: {
        legacy: false,
        type: policy.vehicle.type,
        make: policy.vehicle.make,
        model: policy.vehicle.model,
        drive_type: policy.vehicle.drive_type,
        engine_size: policy.vehicle.engine_size,
        reg_date: policy.vehicle.reg_date,
        mileage: policy.vehicle.mileage,
        sale_price: policy.vehicle.retail_price,
      }
    })
      .then(res => setCoverLevels(res.data))
      .catch(() => setCoverLevels([]))
  }, [])

  //This side effect attempts to reset the initial form value to the correct one from the api including prices, as we don't
  //have that when the initialValues for this form is set.
  useEffect(() => {
    if (coverLevel && !coverLevel.prices && coverLevels.length) {
      let setCoverLevel = coverLevels.find(cl => cl.id === coverLevel.id);
      if (setCoverLevel) {
        dispatch(change('external-sales/policy/edit', 'cover_level', setCoverLevel));
      }
    }
  }, [coverLevel, coverLevels]);

  const [durationOptions, setDurationOptions] = useState([]);
  useEffect(() => {
    if (coverLevel && coverLevel.prices) {
      setDurationOptions(sortBy(
        uniqBy(coverLevel.prices.map(p =>
            ({name: (p.special_duration || p.duration), value: parseInt(p.duration.split(' ')[0])}))
          , (item) => (item.value))
        , (item) => (item.value)));
    } else {
      setDurationOptions([]);
    }
  }, [coverLevel])

  const [claimLimitOptions, setClaimLimitOptions] = useState([]);
  useEffect(() => {
    if (!coverLevel || !coverLevel.prices || !duration) {
      return setClaimLimitOptions([]);
    }
    setClaimLimitOptions(sortBy(uniqBy(coverLevel.prices.filter((p) => (p.duration === duration.name)).map((p) => ({name: `£${p.claim_limit}`, value: parseInt(p.claim_limit)})), (i) => (i.value)), (i) => (i.value)));
  }, [coverLevel, duration]);


  const [labourRateOptions, setLabourRateOptions] = useState([]);
  const [excessOptions, setExcessOptions] = useState([]);
  const [sundryOptions, setSundryOptions] = useState([]);
  useEffect(() => {
    //Wait for cover level, otherwise excess, labour rates and sundries field will not be applied.
    if (!coverLevel) return;
    let coverLevelId = get(coverLevel, 'id');
    let params = {
      cover_level: coverLevelId,
      dealer_id: policy.dealer.id,
    };
    api.get('/misc/price-rules/dealer', {params})
      .then(response => {
        setExcessOptions(response.data.filter(pr => (pr.type === 'Excess')).sort((a, b) => (a.title - b.title)))
        if (!specialVehicle || specialVehicle.type !== 'Prestige') { // because for Prestige the labour rate is fixed at £100
          setLabourRateOptions(response.data.filter(pr => (pr.type === 'Labour Rate')).sort((a, b) => (a.title - b.title)))
        }
        setSundryOptions(response.data.filter(pr => (pr.type === 'Sundry')))
        if (!excess) {
          dispatch(change('external-sales/policy/edit', 'excess', findDefaultValue(response.data.filter(pr => (pr.type === 'Excess'))), true))
        }
        if (!labourRate) {
          dispatch(change('external-sales/policy/edit', 'labour_rate', findDefaultValue(response.data.filter(pr => (pr.type === 'Labour Rate'))), true))
        }
      });
  }, [coverLevel])

  const findDefaultValue = (set) => {
    //Find one marked as default
    if (set.filter(i => i.default).length) {
      return set.filter(i => i.default)[0]
    }
    //Else return first
    if (set.length > 0) {
      return set[0];
    }
    //Return nothing if nothing found
    return null;
  }

  const [newPrice, setNewPrice] = useState(null);
  useEffect(() => {
    if (coverLevel) {
      api.get(`/external-sales/policies/create/${policy.dealer.id}/price`, {
        params: {
          legacy: false,
          type: policy.vehicle.type,
          make: policy.vehicle.make,
          model: policy.vehicle.model,
          drive_type: policy.vehicle.drive_type,
          engine_size: policy.vehicle.engine_size,
          reg_date: policy.vehicle.reg_date,
          mileage: policy.vehicle.mileage,
          sale_price: policy.vehicle.retail_price,
          cover_level_id: coverLevel.id,
          duration: duration.name,
          claim_limit: claimLimit.value,
          excess: get(excess, 'title', null),
          labour_rate: get(labourRate, 'title', null),
          sundries: sundries.map(sundry => sundry.title),
        }
      })
        .then(res => {
          setNewPrice(res.data.price)
        })
    } else {
      setNewPrice(null);
    }
  }, [coverLevel, excess, duration, claimLimit, labourRate, sundries]);

  return <>
    <form onSubmit={handleSubmit}>
    {policy.status === 'Draft' && (
      <div className="row mt-4 policy-edit-form">
        <div className="col-12 mt-3">
          <Field
            label="Company"
            component={renderField}
            id={'company_name'}
            name={`company_name`}
            className="form-control"
          />
        </div>
        <div className="col-12">
          <Field
            label="Cover level"
            component={CustomSelect}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            name={`cover_level`}
            id={'coverLevel'}
            options={coverLevels}
          />
        </div>
        <div className="col-12">
          <Field
            type="text"
            id="policyStartDate"
            label="Start date"
            component={renderDatePicker}
            inputValueFormat="DD-MM-YYYY"
            placeholderText="DD/MM/YYYY"
            dateFormat="dd/MM/yyyy"
            fixedHeight
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            yearDropdownItemNumber={3}
            name={`start_date`}
            className="form-control"
          />
        </div>
        <div className="col-12">
          <Field
            label="Duration"
            component={CustomSelect}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.name}
            name={`duration`}
            id={'duration'}
            options={durationOptions}
          />
        </div>
        <div className="col-12">
          <Field
            label="Claim limit"
            component={CustomSelect}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.name}
            name={`claim_limit`}
            id={'claim_limit'}
            options={claimLimitOptions}
            prependIcon={<i className="wf-icon-gbp" />}
          />
        </div>
        <div className="col-12">
          <Field
            label="Labour Rate"
            component={CustomSelect}
            getOptionValue={option => option.title}
            getOptionLabel={option => option.title}
            name={`labour_rate`}
            id={'labour_rate'}
            options={labourRateOptions}
            prependIcon={<i className="wf-icon-gbp" />}
          />
        </div>
        <div className="col-12">
          <Field
            label="Excess"
            getOptionValue={option => option.title}
            getOptionLabel={option => option.title}
            component={CustomSelect}
            appendIcon={<i className="wf-icon-percent" />}
            className="prepended-select mb-0"
            name={`excess`}
            id={'excess'}
            options={excessOptions}
          />
        </div>
        <div className="col-12">
          <Field
            name="sundries"
            isMulti
            component={CustomSelect}
            label="Sundries"
            options={sundryOptions}
            getOptionValue={(option) => option.title}
            getOptionLabel={(option) => option.title}
          />
        </div>
        <div className="col d-flex justify-content-between bm-2 mb-3">
          <span className="font-xl fw-800 text-gray-800">Dealer Price<br></br>(exc. Tax)</span>
          <span className="font-xl fw-800 text-gray-800">{money.format(newPrice)}</span>
        </div>
        <div className="col-12">
          <span className="label d-block mb-2">Sold for (inc. Tax)</span>
          <Field
            type="text"
            label=""
            component={renderField}
            name={`policy_price`}
            className="form-control"
            append={{
              direction: 'left',
              text     : <i className="wf-icon-gbp"/>,
            }}
          />
        </div>
      </div>
    )}
    {hasPermission('dealer_portal.policies.set_sold_by') &&
      <div className={`row ${policy.status === 'Draft' ? '' : 'mt-4'}`}>
        <div className="col-12">
          <Field
            label="Sold by"
            component={CustomSelect}
            getOptionValue={option => option.id}
            getOptionLabel={option => `${option.first_name} ${option.last_name}`}
            name={`sold_by`}
            id={'sold_by'}
            options={soldByUsers}
          />
        </div>
      </div>
    }
    <div className="row">
        <div className="col-md-12 mt-4">
          <div className="form-group">
            <button
              className="btn btn-secondary"
              type="submit"
              disabled={!newPrice}
            >
              Save changes
            </button>
            <button
              type="button"
              className="btn btn-outline btn-form-cancel"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </>
}

const selector = formValueSelector('external-sales/policy/edit')

export default connect((state) => ({
  coverLevel: selector(state, 'cover_level'),
  claimLimit: selector(state, 'claim_limit'),
  duration: selector(state, 'duration'),

  excess: selector(state, 'excess'),
  labourRate: selector(state, 'labour_rate'),
  sundries: selector(state, 'sundries'),

  policyPrice: selector(state, 'policy_price'),
  soldBy: selector(state, 'sold_by'),
}))(reduxForm({
  form: 'external-sales/policy/edit',
  //validate - We'll need this function later.
})(PolicyEditForm))
