import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form, reduxForm } from 'redux-form'
import renderField from '../../../../../utils/renderField'
import CustomAsyncSelect from "../../../../ui/CustomAsyncSelect";
import api from "../../../../../utils/api";

const EditGarageLinkedDealers = ({
                                handleSubmit,
                                onSubmit,
                                valid,
                                dirty,
                                toggleForm,
                              }) => {

  const loadDealerOptions = async (query) => {
    let res = await api.get(`/misc/dealers?query=${query}&limit=10&active_only=true`);
    return res.data.data;
  }


  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Minimum Labour Rate"
              component={renderField}
              name={`minimum_labour_rate`}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
          <Field
            type="text"
            label="Sell Out Labour Rate"
            component={renderField}
            name={`sell_out_labour_rate`}
            className="form-control"
          />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              component={CustomAsyncSelect}
              label="Link to dealers (optional)"
              name="dealers"
              placeholder={'Find a dealer'}
              isClearable
              cacheOptions
              loadOptions={loadDealerOptions}
              defaultOptions={false}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              isMulti={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col mt-3">
            <div className="form-group">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!valid || !dirty}
                onClick={() => toggleForm(false)}
              >
                Save changes
              </button>
              <button
                type="button"
                className="btn btn-outline btn-form-cancel"
                onClick={() => toggleForm(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}

const validate = (fields) => {
  return {}
}

EditGarageLinkedDealers.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  toggleForm: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'garage/editDealers',
  validate,
})(EditGarageLinkedDealers)
