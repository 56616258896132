import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import UsersCreateForm from './UserCreateForm'
import api from '../../../utils/api'
import {
  change,
  isValid,
  formValueSelector,
} from 'redux-form'
import { connect } from 'react-redux'
import { addUser } from '../../../actions/users.actions'
import { history } from '../../../utils/history'
import { hasPermission, isSuperAdmin, hasGroup } from '../../../utils/auth';

class UserCreatePage extends Component {
  state = {
    groups: [],
  }
  componentDidMount() {
    this.loadGroups()
  }

  loadGroups = async () => {
    const response = await api.get('admin/users/groups?limit=1000')
    let groups = response.data.data.map(group => {
      return {
        color: group.color,
        value: group.id,
        name: group.name,
      }
    })

    // External Sales Managers should be able to assign users to a Dealer user group or the External Sales group
    // Account Managers (which are also part of external sales) should only should be able to assign users to a Dealer user group
    if (hasGroup('Management')) {
      this.setState({ groups: groups })
    } else if (!isSuperAdmin() && hasPermission('external_sales.dealers.view')) { // is an External Sales member of staff
      if (hasPermission('external_sales.dealers.view_all')) { // is an External Sales Manager
        this.setState({ groups: groups.filter(g => g.name.startsWith('Dealer') || g.name === 'External Sales') })
      } else { // is an Account Manager
        this.setState({ groups: groups.filter(g => g.name.startsWith('Dealer')) })
      }
    } else {
      this.setState({ groups: groups })
    }
  }

  cancel = () => history.push('/admin/users')

  render() {
    return (
      <Fragment>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin/users" className="open active">
              Users
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">Add a user</span>
          </li>
        </ol>

        <div className="container-fluid animated fadeIn">
          <div className="mw-37-5rem mx-auto">
            <UsersCreateForm
              change={this.props.change}
              onSubmit={this.props.addUser}
              cancel={this.cancel}
              groups={this.state.groups}
              dealerId={this.props.dealer_id}
              submitErrors={this.props.submitErrors || {}}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}
const selector = formValueSelector('users/create')

export default connect(
  state => ({
    valid: isValid('users/create')(state),
    dealer_id: selector(state, 'dealer_id'),
    groups: selector(state, 'groups'),
  }),
  { change, addUser },
)(UserCreatePage)
