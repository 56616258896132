import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, Field, reset, Form } from 'redux-form'
import TargetTable from './TargetTable'
import renderField from "../../../../../../utils/renderField";
import { isPositiveFloat } from "../../../../../../utils/misc";

const TeamTargetForm = ({ handleSubmit, pristine, submitting, year, yearData, dispatch, invalid, ...props }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        type="hidden"
        name="year"
        value={year}
        component={renderField}
      />
      <p><strong style={{color: '#2D2C31'}}>EXTERNAL SALES</strong></p>
      <TargetTable year={year} formKey={'external_sales'} values={props.external_sales || null} />
      <br />
      <p><strong style={{color: '#2D2C31'}}>DIRECT SALES</strong></p>
      <TargetTable year={year} formKey={'direct_sales'} values={props.direct_sales || null} />
      <div style={{marginBottom: '4rem', marginTop: '1rem'}}>
        <button type="submit" disabled={pristine || submitting || invalid} name="submit" className="btn btn-secondary">Save Budgets</button>
        <button type="reset" className="btn btn-transparent text-secondary" onClick={() => dispatch(reset('teamTargetForm'))}>Cancel</button>
      </div>
    </Form>
  )
}

const validateSection = (values) => {
  let errors = {months: []}
  if (values.months) {
    for(let i = 0; i < 12; i++) {
      if (values.months[i] === '') {
        errors.months[i] = 'You must provide a value'
        continue
      }
      if (isNaN(values.months[i]) || isNaN(parseFloat(values.months[i]))) {
        errors.months[i] = 'The budget must be a number'
      }
      if (!isPositiveFloat(values.months[i])) {
        errors.months[i] = 'The budget must be positive'
      }
    }
    return errors
  } else {
    return {}
  }
}

const validate = (values, form) => {
  let errors = {}
  if (values.direct_sales) {
    errors.direct_sales = validateSection(values.direct_sales)
  }
  if (values.external_sales) {
    errors.external_sales = validateSection(values.external_sales)
  }
  return errors
}

const selector = formValueSelector('teamTargetForm')

export default connect((state) => {
  return {
    external_sales: {
      annual: selector(state, 'external_sales.annual'),
      months: selector(state, 'external_sales.months'),
    },
    direct_sales: {
        annual: selector(state, 'direct_sales.annual'),
        months: selector(state, 'direct_sales.months')
    }
  }
})(
  reduxForm({
    form: 'teamTargetForm',
    enableReinitialize: true,
    validate,
  })(TeamTargetForm)
)
