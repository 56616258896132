import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import api from '../../../utils/api'
import { NotificationManager } from 'react-notifications';
import moment from "moment";
import {Link} from "react-router-dom";

const NotificationsCentre = props => {
  const node = React.useRef()
  const separator = <div style={{ marginLeft: '20px', width: '100%', height: 1, backgroundColor: '#EAE8E7' }}/>

  function handleClickAway(e) {
    if (!node.current.contains(e.target)) {
      props.setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAway)

    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickAway)
    }
  }, [])

  const [newNotifications, setNewNotifications] = useState([]);
  const [oldNotifications, setOldNotifications] = useState([]);

  useEffect(() => {
    if (props.open) {
      api.get('/profile/notifications')
          .then(res => {
            setNewNotifications(res.data.new);
            setOldNotifications(res.data.old);
          })
          .catch(() => {
            NotificationManager.error('Oops! Something went wrong fetching your notifications.');
          })
    }
    props.setHasNewNotifications(newNotifications.length > 0)
  }, [props.open])

  const renderNotification = (notification) => {
    return <>
      <div className={`notification-item ${!notification.read_at ? 'notification-item--unread' : ''}`}>
        {renderNotificationType(notification.type, notification.created_at, notification.data)}
      </div>
    </>
  }

  const renderNotificationType = (type, time, data) => {
    const formattedTime = moment.utc(time).local().format('DD/MM/YYYY - h:mma')
    switch(type) {
      case 'App\\Notifications\\DirectSaleMade':
        return <>
          <Link to={`/direct-sales/policies/${data.policy.id}/policy`}>
            <div className="font-xs">{formattedTime}</div>
            <div className="font-sm">New sale! <span role="img" aria-label="woohoo">🎉</span></div>
            <div className="font-xs mt-1"><i className="icon-user"/> &nbsp; {data.sold_by_user.first_name + ' ' + data.sold_by_user.last_name}</div>
          </Link>
        </>
      case 'App\\Notifications\\ExternalSaleMade':
        return <>
          <Link to={`/external-sales/policies/${data.policy.id}/policy`}>
            <div className="font-xs">{formattedTime}</div>
            <div className="font-sm">New policy! <span role="img" aria-label="woohoo">🎉</span></div>
            <div className="font-xs mt-1"><i className="icon-user"/> &nbsp; {data.dealer.name}</div>
          </Link>
        </>
      case 'App\\Notifications\\CustomPricingRequest':
        return <>
          <Link to={`/external-sales/dealers/${data.dealer.id}/edit`}>
            <div className="font-xs">{formattedTime}</div>
            <div className="font-sm">New Custom Price Request!</div>
            <div className="font-xs mt-1"><i className="icon-user"/> &nbsp; {data.requested_by_user.first_name + ' ' + data.requested_by_user.last_name}</div>
          </Link>
        </>
      case 'App\\Notifications\\NewClaim':
        return <>
          <Link to={`/claims/claims/${data.claim.id}`}>
            <div className="font-xs">{formattedTime}</div>
            <div className="font-sm">New Claim! <span role="img" aria-label="ambulance">🚑</span></div>
            <div className="font-xs mt-1"><i className="icon-user"/> &nbsp; {data.raised_by_user.first_name + ' ' + data.raised_by_user.last_name}</div>
          </Link>
        </>
      default: return <>
        <div className="font-xs">{formattedTime}</div>
        <div className="font-sm">New sale! <span role="img" aria-label="woohoo">🎉</span></div>
        <div className="font-xs mt-1"><i className="icon-user"/> &nbsp; {data.sold_by_user.first_name + ' ' + data.sold_by_user.last_name}</div>
      </>
    }
  }

  return createPortal(<div className={`notifications-centre`} ref={node}>
    <div className="notifications-group">
      <div className="notifications-group__title fw-600">
        New notifications
      </div>
      {newNotifications.map((item, k) => {
        return <Fragment key={k}>
          {renderNotification(item)}
          {k !== (newNotifications.length + oldNotifications.length) - 1 && separator}
        </Fragment>
      })}
      {(newNotifications.length === 0) && <>
        <div className="notification-item">
          No new notifications <span role="img" aria-label="cool">😎</span>
        </div>
      </>}

      <div className="notifications-group__title fw-600">
        Previous notifications
      </div>

      {oldNotifications.map((item, k) => {
        return <Fragment key={k}>
          {renderNotification(item)}
          {k !==  oldNotifications.length -1 && separator}
        </Fragment>
      })}
      {(oldNotifications.length === 0) && <>
        <div className="notification-item">
          No old notifications <span role="img" aria-label="cool">😎</span>
        </div>
      </>}
    </div>
  </div>, document.querySelector('#app-notifications'))

}

NotificationsCentre.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setHasNewNotifications: PropTypes.func.isRequired,
}

export default NotificationsCentre