import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../../../../ui/PageHeader'
import {
  Field,
  Form,
  formValueSelector,
  isPristine,
  isValid,
  reduxForm,
  change,
  untouch,
} from 'redux-form'
import CustomSelect from '../../../../../ui/CustomSelect'
import { connect } from 'react-redux'
import renderVehicleTypesField from '../../../../../ui/fields/renderVehicleTypesField'
import Modals from '../modals'
import { tableHeadings } from '../../../PriceBands/forms/create/table-headings'
import { isNumber } from '../../../../../../utils/misc'
import { uniqBy, sortBy } from 'lodash'
import { Prompt } from 'react-router-dom'
import { PrestigeVehicle } from './PrestigeVehicle'
import { SpecialistVehicle } from './SpecialistVehicle'
import { CommercialVehicle } from './CommercialVehicle'
import { BespokeVehicle } from './BespokeVehicle'
import { required } from 'redux-form-validators'
import api from '../../../../../../utils/api'
import { NotificationManager } from 'react-notifications'
import { getColumnsFromVehicle } from '../helpers'

const CreateForm = (props) => {
  const { onSubmit, handleSubmit, dispatch, vehicleMake, dealer } = props


  const [coverLevelsOptions, setCoverLevelsOptions] = useState([])
  useEffect(() => {
    if (props.dealer) {
      api.get(`external-sales/dealers/${dealer.id}/cover-levels`)
        .then(res => {
          setCoverLevelsOptions(res.data)
        })
        .catch(err => {
          setCoverLevelsOptions([])
          NotificationManager.error('Oops! Something went wrong. Please refresh the page.')
        })
    } else {
      api.get(`admin/cover-levels?target=${props.target}&limit=1000`)
        .then(res => {
          setCoverLevelsOptions(res.data.data)
        })
        .catch(err => {
          setCoverLevelsOptions([])
          NotificationManager.error('Oops! Something went wrong. Please refresh the page.')
        })
    }
  }, [props.dealer])

  const [vehicleMakes, setVehicleMakes] = useState([])
  useEffect(() => {
    api
      .get('/misc/vehicles/makes')
      .then((response) => {
        //We require an object with id and name properties even though this is saved as a string.
        setVehicleMakes(
          response.data.map((make) => ({
            id: make,
            name: make,
          }))
        )
      })
      .catch(() => {
        NotificationManager.error(
          'Oops! Something went wrong fetching the available vehicle makes'
        )
      })
  }, [])

  const [vehicleModels, setVehicleModels] = useState([])
  useEffect(() => {
    if (typeof vehicleMake === 'object') {
      api
        .get('/misc/vehicles/models', {
          params: { make: vehicleMake.name },
        })
        .then((response) => {
          if (!props.editing) {
            dispatch(change('special-vehicles/create', 'model', null))
            dispatch(untouch('special-vehicles/create', 'model'))
          }
          setVehicleModels(
            response.data.map((model) => ({
              id: model,
              name: model,
            }))
          )
        })
        .catch(() => {
          NotificationManager.error(
            'Oops! Something went wrong fetching the available vehicle models'
          )
        })
    }
  }, [vehicleMake])

  // selected price band to populate empty fields
  const { vehicle_type } = props

  const [state, setState] = useState({
    tables: null,
    specialDurationModal: false,
    durationModal: false,
    coverLevelName: null,
  })

  const toggleModal = (modal, coverLevelName) => {
    const { dispatch, change, untouch } = props

    if (modal === 'special') {
      setState((pre) => ({
        ...pre,
        specialDurationModal: !pre.specialDurationModal,
      }))

      // reset form fields
      dispatch(change('pb_duration', ''))
      dispatch(change('pb_drive', ''))
      dispatch(change('target_cover_level', ''))

      dispatch(untouch('pb_duration'))
      dispatch(untouch('pb_drive'))
      dispatch(untouch('target_cover_level'))
    } else {
      setState((pre) => ({
        ...pre,
        durationModal: !pre.durationModal,
        coverLevelName,
      }))
      dispatch(change('pb_duration', ''))
      dispatch(untouch('pb_duration'))
    }
  }

  useEffect(() => {
    if (coverLevelsOptions) {
      let tablesData =
        coverLevelsOptions &&
        coverLevelsOptions.map(({ id, name }) => {
          return {
            name,
            columns: sortBy(
              tableHeadings.concat(getColumnsFromVehicle(props.vehicle, id)),
              (column) => column.value
            ),
          }
        })
      let tables = []
      tables['Prestige'] = tablesData
      tables['Commercial'] = tablesData

      setState((pre) => ({ ...pre, tables }))
    }
  }, [coverLevelsOptions])

  useEffect(() => {
    dispatch(change('special-vehicle/create', 'coverLevels', ''))
  }, [vehicle_type])

  const handleSpecialDurationSubmit = () => {
    const { pb_duration, pb_drive, target_cover_level, vehicle_type } = props
    const { dispatch, change, untouch } = props

    if (
      !isNumber(pb_duration) ||
      !isNumber(pb_drive) ||
      typeof target_cover_level === 'undefined' ||
      pb_duration === '0'
    ) {
      return false
    }

    let title = `${pb_duration} months (drive ${pb_drive})`

    let columns = [
      ...state.tables[vehicle_type].filter(
        (t) => t.name === target_cover_level.name
      )[0].columns,
    ]

    let cols = uniqBy(
      [
        ...columns,
        {
          title,
          value: parseInt(pb_duration),
          hiddenFor: [],
          special: true,
        },
      ],
      'value'
    )

    let tables = { ...state.tables }

    tables[vehicle_type].map((t) => {
      if (t.name === target_cover_level.name) t.columns = cols
      return t
    })

    setState((prevState) => ({
      ...prevState,
      tables,
    }))
    //ensure this is added to the formValues on submit
    //m there to force object rather than array
    dispatch(
      change(
        `special_duration.${target_cover_level.name}.m${pb_duration}`,
        pb_drive
      )
    )

    // reset form fields
    dispatch(change('pb_duration', ''))
    dispatch(change('pb_drive', ''))
    dispatch(change('target_cover_level', ''))

    dispatch(untouch('pb_duration'))
    dispatch(untouch('pb_drive'))
    dispatch(untouch('target_cover_level'))
    toggleModal('special')
  }

  const handleDurationSubmit = () => {
    const { pb_duration, vehicle_type } = props
    const { coverLevelName } = state

    if (!isNumber(pb_duration) || pb_duration === '0') {
      return false
    }

    let title = `${pb_duration} months`
    // get table columns for cover level
    let columns = [
      ...state.tables[vehicle_type].filter((t) => t.name === coverLevelName)[0]
        .columns,
    ]

    // attach the new column
    let cols = uniqBy(
      [
        ...columns,
        {
          title,
          value: parseInt(pb_duration),
          hiddenFor: [],
          special: true,
        },
      ],
      'value'
    )

    // make a copy of the current tables
    let tables = { ...state.tables }

    tables[vehicle_type].map((t) => {
      if (t.name === coverLevelName) t.columns = cols
      return t
    })

    setState((prevState) => ({
      ...prevState,
      tables,
    }))

    toggleModal()
    const { dispatch, change, untouch } = props
    // reset form fields
    dispatch(change('pb_duration', ''))
    dispatch(untouch('pb_duration'))
  }

  const handleRemoveColumn = (tableName, th, index) => {
    let cols = [...state.tables[vehicle_type]]
      .map((t) => {
        return t.name === tableName ? t : null
      })
      .filter(Boolean)
      .map((t) => t.columns)[0]
      .filter((th, i) => i !== index)

    setState((prevState) => ({
      ...prevState,
      tables: {
        ...prevState.tables,
        ...prevState.tables[vehicle_type].map((table) => {
          if (table.name === tableName) {
            table.columns = cols
          }
          return table
        }),
      },
    }))
  }

  // form fields
  const { coverLevels, pristine, valid } = props

  const noCoverLevelsSelected =
    coverLevels &&
    (Object.keys(coverLevels).length === 0 ||
      coverLevels[Object.keys(coverLevels)[0]].length === 0)

  return (
    <>
      <PageHeader
        title={
          props.editing ? 'Edit a special vehicle' : 'Add a special vehicle'
        }
      />
      <br />

      <Prompt
        when={!pristine}
        message={'You have unsaved changes, are you sure you want to leave?'}
      />

      <Form onSubmit={handleSubmit(onSubmit)}>
        {coverLevels && (
          <Modals
            coverLevels={coverLevels[vehicle_type]}
            toggleModal={toggleModal}
            handleSpecialDurationSubmit={handleSpecialDurationSubmit}
            specialDurationModal={state.specialDurationModal}
            durationModal={state.durationModal}
            handleDurationSubmit={handleDurationSubmit}
          />
        )}

        <div className="row">
          <div className="col-md-3">
            <Field
              label="Vehicle make"
              component={CustomSelect}
              name="make"
              options={vehicleMakes}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              validate={[
                required({
                  message: 'You must choose a vehicle make.',
                }),
              ]}
              isDisabled={props.vehicleModel && props.vehicleModel.length > 0}
            />
          </div>
          <div className="col-md-9">
            <Field
              label="Model(s)"
              component={CustomSelect}
              isMulti
              name="model"
              className="w-100"
              options={vehicleModels}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              validate={[
                required({
                  message: 'You must choose at least one model.',
                }),
              ]}
            />
          </div>
        </div>

        <div className="label mb-2 mt-3">Which type of vehicle(s) is it?</div>

        <div className="d-flex">
          <Field
            label="Which type of vehicle(s) is it?"
            component={renderVehicleTypesField}
            className={`${vehicle_type === 'Prestige' && 'selected'} mr-3`}
            type="radio"
            value="Prestige"
            id={'prestige'}
            name="vehicle_type"
            data={{
              title: 'Prestige',
              text: 'Assign and edit price bands for prestige vehicles.',
            }}
          />

          <Field
            label="Which type of vehicle(s) is it?"
            component={renderVehicleTypesField}
            className={`${vehicle_type === 'Specialist' && 'selected'} mr-3`}
            type="radio"
            value="Specialist"
            id={'specialist'}
            name="vehicle_type"
            data={{
              title: 'Specialist',
              text: 'Prices automatically increase by a fixed percentage.',
            }}
          />

          <Field
            label="Which type of vehicle(s) is it?"
            component={renderVehicleTypesField}
            className={`${vehicle_type === 'Commercial' && 'selected'} mr-3`}
            type="radio"
            id={'commercial'}
            value="Commercial"
            name="vehicle_type"
            data={{
              title: 'Commercial',
              text: 'Assign and edit price bands for commercial vehicles.',
            }}
          />

        </div>

        {PrestigeVehicle(
          vehicle_type,
          coverLevelsOptions,
          coverLevels,
          toggleModal,
          state,
          handleRemoveColumn,
          noCoverLevelsSelected,
          valid,
          props
        )}

        {SpecialistVehicle(vehicle_type, props)}

        {CommercialVehicle(
          vehicle_type,
          coverLevelsOptions,
          coverLevels,
          toggleModal,
          state,
          handleRemoveColumn,
          props
        )}

        {BespokeVehicle(vehicle_type, props)}
      </Form>
    </>
  )
}

CreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

const selector = formValueSelector('special-vehicles/create')

export default connect(
  (state) => ({
    coverLevels: selector(state, 'coverLevels'),
    pb_duration: selector(state, 'pb_duration'),
    pb_drive: selector(state, 'pb_drive'),
    target_cover_level: selector(state, 'target_cover_level'),
    vehicle_type: selector(state, 'vehicle_type'),
    vehicleMake: selector(state, 'make'),
    vehicleModel: selector(state, 'model'),

    isValid: isValid('special-vehicles/create')(state),
    pristine: isPristine('special-vehicles/create')(state),
  }),
  { change }
)(
  reduxForm({
    form: 'special-vehicles/create',
  })(CreateForm)
)
