import axios from 'axios'
import { API_URL } from './constants'
import {NotificationManager} from 'react-notifications'
import {history} from "./history";
import _ from 'lodash'

const notFreshPaths = [
  '/auth/login',
  '/auth/refresh',
]
function isNotRefreshPath(url) {
  for (const path of notFreshPaths) {
    if (url.includes(path)) return true
  }
  return false
}

let api = axios.create({
  baseURL : API_URL,
})

api.interceptors.request.use(
  reqConfig => {

    // add the bearer token if we have one, unless we aer calling the 'send reset password' email endpoint
    // as this doesn't expect one (whether we are logged in or not)
    if (!reqConfig.url.includes('/auth/forgot')) {
      reqConfig.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('wf-token'))}`
    }

    if (reqConfig.url.includes('/auth/logout')) {
      reqConfig.headers['X-REFRESH-TOKEN'] = localStorage.getItem('refresh_token')
    }

    return reqConfig
  },
  err => () => {
    if (err.response) {
      if (err.response.status === 500) {
        NotificationManager.error('Oops! There was a server error while attempting your request. Please refresh or try again.');
      }
      if (err.response.status === 401) {
        NotificationManager.error('Oops! You are not logged in!')
        history.push('/')
      }
      if (err.response.status === 403) {
        NotificationManager.error('Oops! You do not have permissions to perform this action.')
      }
    }
    Promise.reject(err)
  },
)

// Add a response interceptor
api.interceptors.response.use(async function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger

  if (isNotRefreshPath(response.config.url)) {
    return response;
  }

  await refreshToken()

  return response;
}, function (err) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
  if (err.response) {
    // if the token expired, redirect to login page
    if (err.response.status === 401) {
      localStorage.removeItem('wf-token')
      window.location.href = '/'
    }
  }

  return Promise.reject(err);
});

const refreshToken = _.debounce(
  async function() {
    try {
      const res = await api.get('/auth/refresh');  
      const newToken = res.data.access_token;
      if (newToken) localStorage.setItem('wf-token', JSON.stringify(newToken))
    } catch (error) {
      console.warn('refresh error', error)
    }
  },
  3000,
)

api.defaults.headers.common['Content-Type'] = 'application/json'
api.defaults.headers.common['Accept'] = 'application/json'
api.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

export default api
