import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import moment from 'moment'
import api from '../../../../utils/api'
import PageHeader from '../../../ui/PageHeader'
import DatePickers from './Datepickers'
import { money, prettyNumber } from '../../../../utils/misc';

const sixDaysAgo = moment().subtract(6, 'd').format('YYYY-MM-DD')
const today = moment().format('YYYY-MM-DD')

function Dashboard() {
  const [fromDate, setFromDate] = useState(sixDaysAgo)
  const [toDate, setToDate] = useState(today)

  const [data, setData] = useState(null)

  useEffect(() => {
    api
      .get('/dashboards/claims', {
        params: {
          from_date: fromDate,
          to_date: toDate
        }
      })
      .then(res => {
        setData(res.data)
      })
      .catch(err => {
        if (err) {
          NotificationManager.error('Whoops! Something went wrong. Please try again after refreshing the page.')
        }
      })
  }, [fromDate, toDate])

  const handleFilterChange = (values) => {
    // the initial value of the datepickers is a string but it changes to a Date object when the user picks a date
    // so we need to cater for both here and enforce a YYYY-MM-DD date format

    // Note use moment to format the date as YMD to avoid any daylight savings time problems
    setFromDate(values.date_from instanceof Date ? moment(values.date_from).format('YYYY-MM-DD') : values.date_from)
    setToDate(values.date_to instanceof Date ? moment(values.date_to).format('YYYY-MM-DD') : values.date_to)
  }

  const getTotalOpen = () => {
    return Object.values(data.summary.open).reduce((partialSum, a) => partialSum + a, 0);
  }

  const getTotalRejected = () => {
    return Object.values(data.summary.rejected).reduce((partialSum, a) => partialSum + a, 0);
  }

  return (
    <div className="container-fluid animated fadeIn bg-grey min-vh-100 dashboard-grey-extend">
      <br/>
      <div className="row">
        <div className="col-md-8">
          <PageHeader
            title="Claims Dashboard"
            description={`View claims statistics below.`}
          />
        </div>
      </div>

     <div className="d-flex align-items-center justify-content-between">
       <div>
         <h3 style={{ marginTop: 64 }}>All Claims</h3>
         <p style={{ marginBottom: 32 }}>Opened, paid and rejected</p>
       </div>
       <div className={'dashboard-date-select'}>
         <DatePickers
           onSubmit={handleFilterChange}
           initialValues={{date_from: fromDate, date_to: toDate}}
         />
       </div>
     </div>
      {data &&
      <>
        <div className="dashboard-segment blue mb-5">
          <div className="header">
            <img className="icn-open-claim" alt="" />
            <h4>Opened Claims</h4>
            <p className="font-md">Of which were in the following status at the end of the selected date range:</p>
          </div>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th width="50%">TOTAL OPENED CLAIMS</th>
                <th>{prettyNumber(getTotalOpen())}</th>
              </tr>
            </thead>
            <tbody>
            {Object.keys(data.summary.open).map((k) => (
              <tr key={k}>
                <td>{k}</td>
                <td>{prettyNumber(data.summary.open[k])}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>

        <div className="dashboard-segment green mb-5">
          <div className="header">
            <img className="icn-paid-claim" alt="" />
            <h4>Paid Claims</h4>
            <p className="font-md">Of which were in the following status at the end of the selected date range:</p>
          </div>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th width="50%">TOTAL PAID CLAIMS (PAID AT {money.format(data.summary.paid.net)})</th>
                <th>{prettyNumber(data.summary.paid.qty)}</th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="dashboard-segment red mb-5">
          <div className="header">
            <img className="icn-rejected-claim" alt="" />
            <h4>Rejected Claims</h4>
            <p className="font-md">Of which were in the following status at the end of the selected date range:</p>
          </div>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th width="50%">TOTAL REJECTED CLAIMS</th>
                <th>{prettyNumber(getTotalRejected())}</th>
              </tr>
            </thead>
            <tbody>
            {Object.keys(data.summary.rejected).map((k) => (
              <tr key={k}>
                <td>{k}</td>
                <td>{prettyNumber(data.summary.rejected[k])}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </>
      }

    </div>
  )
}



export default Dashboard
