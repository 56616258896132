import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { money } from '../../../../../utils/misc'
import { confirmAlert } from 'react-confirm-alert'
import { hasPermission } from '../../../../../utils/auth'
import { CLAIM_PAYEE_TYPES } from '../../../../../constants'
import moment from 'moment'

const ClaimItems = ({
  claim,
  payee,
  garage,
  dealer,
  claimItems,
  removeItem,
  setItem,
  toggleAddClaimItemModal,
  toggleEditClaimItemModal,
  claimLimit,
  confirmAuth,
  setPayout,
}) => {
  const handleEditClick = (claimItem) => {
    setItem(claimItem)
    toggleEditClaimItemModal()
  }

  const toggleDeleteModal = (claimItem) => {
    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>
                Delete <strong>{claimItem.description}</strong>? It'll be lost
                forever.
              </p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  removeItem(claimItem.id)
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const net = claimItems.reduce((acc, c) => acc + c.net, 0)

  const hasVAT = () => {
    return ((payee === CLAIM_PAYEE_TYPES.DEALER && dealer && dealer.vat_registered) 
    || (payee === CLAIM_PAYEE_TYPES.REPAIRING_GARAGE && garage && garage.vat_registered))
  }

  const vat = hasVAT() ? claimItems.reduce((acc, c) => acc + c.vat, 0) : 0
  const vatAddedClaimLimit = claimLimit * (hasVAT() ? 1.2 : 1) 

  let gross = net + vat
  const grossIsCapped = claimLimit < gross

  let calculateExcessFrom = grossIsCapped ? claimLimit : gross;
  // Include the VAT in the capped gross if the claim is create after April 5th 2024
  const applyNewCappedCalculation = moment(claim.created_at) > moment('2024-04-05 00:00:00')
  if (applyNewCappedCalculation) {
     calculateExcessFrom = Math.min(gross, vatAddedClaimLimit);
  }

  const claimExcess = () => {
    if (claim.policy.excess_type === 'Percentage') {
      return calculateExcessFrom * (claim.policy.excess / 100)
    } else if (claim.policy.excess_type === 'Absolute') {
      // if the excess is greater than the cost of repairs
      if (claim.policy.excess > calculateExcessFrom) {
        return calculateExcessFrom
      } else {
        return claim.policy.excess
      }
    }
  }

  setPayout(calculateExcessFrom - claimExcess())

  const hasUnauthorisedItems = () => {
    return !!claim.items.filter(i => i.auth_code === null).length
  }

  return (
    <div className="col-12 p-0">
      <h6 className="mt-4 mb-2 text-uppercase">Claim items</h6>
      {claimItems &&
        claimItems.map((item, i) => {
          if (!isNaN(item.net)) {
            return (
              <div className="row" key={i}>
                <div className="col mb-2 d-flex align-items-center">
                  <div className="flex-grow-1">{item.description}</div>
                  <div className="pr-1">{item.auth_code || ' '}</div>
                  <div>
                    <span onClick={removeItem}>{money.format(item.net)}</span>
                  </div>
                  {hasPermission('claims.claims.update') ? (
                    <span
                      className="wf-icon-edit d-inline-block ml-2 cursor-pointer"
                      style={{backgroundSize:"16px"}}
                      onClick={() => handleEditClick(item)}
                    />
                  ) : (<></>)
                  }
                  {hasPermission('claims.claims.update') ? (
                    <span
                      className="wf-icon-red-cross d-inline-block ml-2 cursor-pointer"
                      onClick={() => toggleDeleteModal(item)}
                    />
                  ) : (<></>)
                  }
                </div>
              </div>
            )
          }
          return null
        })}
      {hasPermission('claims.claims.update') && (
      <Link
        to={'#'}
        className="mt-3 mr-3 d-inline-block font-sm"
        onClick={toggleAddClaimItemModal}
      >
        Add an item
      </Link>
      )}

      {hasPermission('claims.claims.update') && hasUnauthorisedItems() && (
        <button className="d-block btn btn-secondary mt-2 mb-1" onClick={confirmAuth}>Approve Pending Items</button>
      )}

      <div className="d-flex align-items-center justify-content-between mb-1 mt-4">
        <span className="label">Net</span>
        <span className="label">{money.format(net)}</span>
      </div>
      {hasVAT() &&
      <div className="d-flex align-items-center justify-content-between mb-1">
        <span className="label">VAT</span>
        <span className="label">{money.format(vat)}</span>
      </div>}
      <div className="d-flex align-items-center justify-content-between mb-1">
        <span className="label">Gross {grossIsCapped ? '(uncapped)' : ''}</span>
        <span className="label">{money.format(gross)}</span>
      </div>
      {grossIsCapped && <div className="d-flex align-items-center justify-content-between mb-1">
        <span className="label">Gross (capped)</span>
        <span className="label">{money.format(calculateExcessFrom)}</span>
      </div>}
      <div className="d-flex align-items-center justify-content-between mb-1">
        <span className="label">Excess</span>
        <span className="label">{money.format(claimExcess())}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-2 mb-4">
        <span className="label fw-800" style={{ fontSize: 20 }}>
          Payout
        </span>
        <span className="label fw-800" style={{ fontSize: 20 }}>
          {money.format(calculateExcessFrom - claimExcess())}
        </span>
      </div>
    </div>
  )
}

ClaimItems.propTypes = {
  claimItems: PropTypes.array.isRequired,
  removeItem: PropTypes.func.isRequired,
  setItem: PropTypes.func.isRequired,
  claimLimit: PropTypes.number.isRequired,
  setPayout: PropTypes.func.isRequired,
}

export default ClaimItems
