import React from 'react'
import { Field, Form, reduxForm } from 'redux-form'
import renderField from '../../../../../../utils/renderField'
import { email, required } from 'redux-form-validators'
import { validPhoneNumber } from '../../../../../../utils/misc'

const AddContactForm = ({ handleSubmit, onSubmit, cancel, valid }) => {
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col">
          <Field
            label="First name"
            name={`first_name`}
            type="text"
            className="form-control"
            component={renderField}
            validate={[required({ message: 'Please enter a first name.' })]}
          />
        </div>
        <div className="col">
          <Field
            label="Last name"
            name={`last_name`}
            type="text"
            className="form-control"
            component={renderField}
            validate={[required({ message: 'Please enter a last name.' })]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Field
            label="Email address"
            name={`email`}
            type="text"
            component={renderField}
            className="form-control"
            validate={[
              required({ message: 'Please enter an email address.' }),
              email({
                message: "That email address doesn't look quite right.",
              }),
            ]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Field
            label="Telephone (office)"
            name={`tel1`}
            type="text"
            component={renderField}
            className="form-control"
          />
        </div>
        <div className="col-6">
          <Field
            label="Telephone (mobile)"
            name={`tel2`}
            type="text"
            component={renderField}
            className="form-control"
          />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-6">
          <Field
            label="Title"
            component={CustomSelect}
            placeholder="Select"
            name={`title`}
            className={'select-z-index-fix'}
            validate={[required({ msg: 'You must select a title' })]}
            options={[
              { name: 'Mr', value: 'Mr' },
              { name: 'Mrs', value: 'Mrs' },
              { name: 'Miss', value: 'Miss' },
              { name: 'Ms', value: 'Ms' },
              { name: 'Doctor', value: 'Doctor' },
              { name: 'Reverend', value: 'Reverend' },
            ]}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
          />
        </div>
      </div> */}
      <div className="row">
        <div className="col">
          <Field
            name={`is_primary`}
            type="checkbox"
            label="Primary"
            id="fieldPrimary"
            component={renderField}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group mb-0">
            <button
              className="btn btn-secondary"
              type="submit"
              disabled={!valid}
            >
              Add contact
            </button>
            <button
              type="button"
              className="btn btn-outline btn-form-cancel"
              onClick={() => cancel(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Form>
  )
}

const validate = (fields) => {
  let errors = {}

  if (!fields.tel1 && !fields.tel2)
    errors.tel1 = 'You must enter at least one telephone number.'

  if (fields.tel1 && !validPhoneNumber(fields.tel1))
    errors.tel1 = "That phone number doesn't look quite right."

  if (fields.tel2 && !validPhoneNumber(fields.tel2))
    errors.tel2 = "That phone number doesn't look quite right."

  if (!fields.tel1 && !fields.tel2)
    errors.tel1 = 'You must enter at least one telephone number.'

  return errors
}

export default reduxForm({
  form: 'add-contact',
  validate,
  initialValues: {
    is_primary: false,
  },
})(AddContactForm)
