import React, { Fragment, useEffect, useState } from 'react'
import DealerDashboard from '../../ExternalSales/DealerTimeline/DealerDashboard';
import api from '../../../../utils/api';
import { NotificationManager } from 'react-notifications';
import PageHeader from '../../../ui/PageHeader';
import RevCounter from './RevCounter';

const Dashboard = () => {

  const [dealer, setDealer] = useState(null)
  const [dashboardData, setDashboardData] = useState(null)

  const fetchDealer = () => {
    api
      .get(`/dealer-portal/account-details`)
      .then((res) => {
        setDealer(res.data)
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing'
          )
        }
      })
  }

  useEffect(() => {
    fetchDealer()
  }, [])

  return (
    <Fragment>
      <br/>
      <div className="container-fluid animated fadeIn">
        <PageHeader
          title="Dashboard"
          // description={'These are the profit made/on track for:'}
        />
        {dealer && (
          <>
            <div className="row">
              <div className="col">
                {dashboardData && (
                  <RevCounter dealer={dealer} data={dashboardData} />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <DealerDashboard dealer={dealer} isForDealerPortal={true} setDashboardData={setDashboardData} />
              </div>
            </div>
          </>
        )}
      </div>
    </Fragment>
  )

}

export default Dashboard
