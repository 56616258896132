import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form, reduxForm } from 'redux-form'
import renderField from '../../../../../../utils/renderField'
import { required } from 'redux-form-validators'

const EditItemForm = ({ handleSubmit, onSubmit, valid, onCancel }) => {
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col mt-2">
          <Field
            type="text"
            label="Claim item name"
            component={renderField}
            name={`description`}
            className="form-control"
            showSuccessValidation={false}
            validate={[required({ msg: 'You must enter a name.' })]}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Field
            type="text"
            label="Price"
            component={renderField}
            append={{
              direction: 'left',
              text: <i className="wf-icon-gbp" />,
            }}
            name={`net`}
            className="form-control"
            showSuccessValidation={false}
            validate={[required({ msg: 'You must enter the price.' })]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col mt-3">
          <button className="btn btn-secondary" type="submit" disabled={!valid}>
            Save changes
          </button>
          <button
            type="button"
            className="btn btn-outline btn-form-cancel"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </Form>
  )
}

EditItemForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
}

const validate = (values) => {
  const errors = {}

  const pattern = new RegExp('^[0-9]*(?:\\.[0-9]*)?$')

  if (values.price && !pattern.test(values.price)) {
    errors.price = 'Price can be only a positive number'
  }

  return errors
}

export default reduxForm({
  validate,
  form: 'edit-claim-item',
})(EditItemForm)
