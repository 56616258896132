import React from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../ui/PageHeader'
import UserGroupEditForm from './UserGroupEditForm'
import { connect } from 'react-redux'
import {
  fetchUserGroup,
  deleteUserGroup,
} from '../../../actions/user-groups.actions'
import { history } from '../../../utils/history'
import _ from 'lodash'
import { change, formValueSelector, SubmissionError } from 'redux-form'
import api from '../../../utils/api'
import { NotificationManager } from 'react-notifications'
import { confirmAlert } from 'react-confirm-alert'

class UserGroupEditPage extends React.Component {
  state = {
    permissions: [],
  }

  componentDidMount() {
    const id = this.props.match.params.id

    this.props
      .fetchUserGroup(id)
      .then(res => {
        this.setState({ permissions: res.data.permissions })
      })
      .catch(err => {
        if (err && err.response.status === 404) history.go('/404')
      })
  }

  handleSubmit = group => {
    return api
      .patch(`/admin/users/groups/${group.id}`, group)
      .then(res => {
        NotificationManager.success('Woohoo! Your changes were saved.')
      })
      .catch(err => {
        if (err && err.response.status === 422) {
          throw new SubmissionError({
            ...err.response.data.errors,
            _error: err.response.data,
          })
        }

        NotificationManager.error(
          'Oops! Something went wrong. Please try again after refreshing the page.',
        )
      })
  }

  select = permission => {
    if (_.includes(this.props.perms, permission)) {
      let arr = [...this.props.perms.filter(p => p !== permission)]
      this.props.change('user-group/edit', 'permissions', arr)
    } else {
      this.props.change(
        'user-group/edit',
        'permissions',
        _.union([...this.props.perms, permission]),
      )
    }
  }

  getPermissionsArray = permissions => {
    if (!permissions) {
      throw new Error('Please provided permissions arg.')
    }

    return Object.keys(permissions)
  }

  selectAll = perms => {
    let permissions = this.getPermissionsArray(perms)

    let newData = _.union([...this.state.permissions, ...permissions])

    this.setState({ permissions: newData })

    this.props.change('user-group/edit', 'permissions', newData)
  }

  deselect = perms => {
    const permsArray = Object.keys(perms)
    let copy = _.clone(this.state.permissions)

    permsArray.forEach(p => _.remove(copy, value => p === value))
    this.setState(
      {
        permissions: copy,
      },
      () => {
        this.props.change(
          'user-group/edit',
          'permissions',
          this.state.permissions,
        )
      },
    )
  }

  handleDelete = group => {
    confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>
                Delete the user group <strong>{group.name}</strong>? It'll be
                lost forever.
              </p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  this.props.deleteUserGroup(group.id, '/admin/user-groups')
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  onCancel = () => history.push('/admin/user-groups')

  render() {
    const { isPending, initialValues } = this.props

    return (
      !isPending && (
        <>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/user-groups" className="open active">
                Groups
              </Link>
            </li>
            <li className="breadcrumb-item">
              <span className="active">Edit group</span>
            </li>
          </ol>

          <div className="container-fluid animated fadeIn">
            <div className="mw-37-5rem mx-auto mb-6">
              <PageHeader title="Edit group" />
              {this.props.group && (
                <UserGroupEditForm
                  onSubmit={this.handleSubmit}
                  onCancel={this.onCancel}
                  handleChange={this.select}
                  deselect={this.deselect}
                  initialValues={this.props.group}
                  selectAll={this.selectAll}
                  permissions={this.props.perms}
                  perms={this.props.perms}
                  change={this.props.change}
                  handleDelete={() => this.handleDelete(initialValues)}
                />
              )}
            </div>
          </div>
        </>
      )
    )
  }
}

UserGroupEditPage.propTypes = {}

const selector = formValueSelector('user-group/edit')

export default connect(
  state => ({
    isPending: state.userGroups.isPending,
    initialValues: state.userGroups.singleGroup,
    group: state.userGroups.singleGroup,
    perms: selector(state, 'permissions'),
  }),
  { fetchUserGroup, change, deleteUserGroup },
)(UserGroupEditPage)
