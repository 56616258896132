import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import { history } from '../../../../../utils/history';

const CreateClaim = ({ array: { push } }) => {
  const [step, setStep] = useState(1)
  const [steps, setSteps] = useState(formSteps)
  const [policy, setPolicy] = useState(null)
  const [lastStepVisited, setLastStepVisited] = useState(1)

  const nextStep = () => {
    setStep(step + 1)
  }

  useEffect(() => {
    setSteps(steps => [
      ...steps.map(item => {
        if (item.id === step) {
          item.current = true
        } else {
          item.current = false
        }
        return item
      }),
    ])
  }, [step])

  /**
   * Only allow the user to navigate
   * to the last step he visited.
   *
   * @param {Number} step
   */
  const handleGoBackTo = step => {
    if (lastStepVisited >= step.id) {
      setStep(step.id)
    }
  }

  const handleCancel = () => {
    history.push('/policyholder-portal/claims')
  }

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/claims/claims" className="open active">
            All claims
          </Link>
        </li>
        <li className="breadcrumb-item">
          <span className="active">Start a new claim</span>
        </li>
      </ol>

      <h2 className="text-center mt-5 mb-5">Start a new claim</h2>

      {steps && (
        <div className="px-5 bg-grey text-text-center d-flex justify-content-center py-3 form-steps">
          {steps.map((step, i) => (
            <h5
              onClick={() => handleGoBackTo(step)}
              className={`form-step fw-800 text-capitalize
        ${step.current ? 'text-primary current' : ''}
        `}
              key={i}
            >
              {step.id}. {step.title}
            </h5>
          ))}
        </div>
      )}
      <div className="container-fluid mt-5">
        {step === 1 &&
        <Step1
          setPolicy={setPolicy}
          policy={policy}
          onCancel={handleCancel}
          onSubmit={() => {
            nextStep()
            setLastStepVisited(2)
          }}
        />
        }
        {step === 2 &&
        <Step2
          policy={policy}
          onCancel={handleCancel}
          onSubmit={() => {
            nextStep()
            setLastStepVisited(3)
          }}
        />
        }
        {step === 3 &&
        <Step3
          setStep={setStep}
          policy={policy}
          onCancel={handleCancel}
        />
        }
      </div>
    </div>
  )
}


const formSteps = [
  {
    id             : 1,
    title          : 'Policy',
    current        : true,
    stepLinkIsGreen: false,
  },
  {
    id             : 2,
    title          : 'Fault',
    current        : false,
    stepLinkIsGreen: false,
  },
  {
    id             : 3,
    title          : 'Preferred Garage',
    current        : false,
    stepLinkIsGreen: false,
  },
]

CreateClaim.propTypes = {}

export default connect()(
  reduxForm({
    form: 'policyholder-portal/claims/create',
  })(CreateClaim),
)
