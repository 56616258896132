import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import navigation from '../utils/navigation'

const PrivateRoute = ({component: Component, ...rest}) => (
  <Route
    {...rest}
    render={props => {
      if (localStorage.getItem('wf-token')) {
        let page = navigation[1].routes[0].url

        if (props.location.pathname === '/renewals') {
          return <Redirect to={{pathname: page}}/>
        }

        if (props.location.pathname === '/claims') {
          return <Redirect to={{pathname: page}}/>
        }
        return <Component {...props} {...rest} />
      }

      return <Redirect
        to={{pathname: '/', state: {from: props.location}}}
      />
    }
    }
  />
)

export default PrivateRoute
