import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { change, Field, formValueSelector, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import renderField from '../../../../../utils/renderField'
import { Link } from 'react-router-dom'
import renderDatePicker from '../../../../ui/renderDatePicker';
import { required } from 'redux-form-validators';
import CustomSelect from '../../../../ui/CustomSelect';

const EditVehicleDetails = ({
  handleSubmit,
  onSubmit,
  onCancel,
  valid,
  vehicle,
  manualInfo: [useManualInfo, setUseManualInfo],
  handleVRMLookup,
  lookupData,
  dispatch,
}) => {

  useEffect(() => {
    setUseManualInfo(vehicle.manual_data);
    if (!vehicle.manual_data) {
      handleVRMLookup(vehicle.vrm);
    }

    // set the default value of the custom select field we use for Vehicle type
    dispatch(change('vehicle-edit', 'type', { value: vehicle.type } ));
  }, [])


  return (
    <div className="col-12 vehicle-details-form">
      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="mt-4 row">
          <div className="col-12">

            <div className="row">
              <div className="col-12 mb-2">
                <Field
                  type="text"
                  label="Registration"
                  component={renderField}
                  name={`vrm`}
                  className="form-control"
                  button="Lookup"
                  handleSubmit={handleVRMLookup}
                  normalize={value => value && value.replace(/\s/g, '').toLocaleUpperCase()}
                  validate={[required({message: 'Please enter a registration number.'})]}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <Link
                  to="#"
                  className="text-blue"
                  onClick={() => {
                    setUseManualInfo(!useManualInfo)
                  }}
                >
                  {useManualInfo ? 'Lookup Registration' : 'Enter Details Manually'}
                </Link>
              </div>
            </div>

            {!useManualInfo && lookupData && (
              <div className="row">
                <div className="col-12">
                  <div className="d-block">
                    <div className="mt-1 vehicle-details-lookup">
                      <table className="table table-striped table-bordered">
                        <tbody className="smaller">
                        <tr>
                          <td className="fw-600">VIN</td>
                          <td>{lookupData.VIN}</td>
                        </tr>
                        <tr>
                          <td className="fw-600">Type</td>
                          <td>{lookupData.Type}</td>
                        </tr>
                        <tr>
                          <td className="fw-600">Make</td>
                          <td>{lookupData.Make}</td>
                        </tr>
                        <tr>
                          <td className="fw-600">Model</td>
                          <td>{lookupData.Model}</td>
                        </tr>
                        <tr>
                          <td className="fw-600">Series</td>
                          <td>{lookupData.Series}</td>
                        </tr>
                        <tr>
                          <td className="fw-600">Drive Type</td>
                          <td>{lookupData.DriveType}</td>
                        </tr>
                        <tr>
                          <td className="fw-600">Engine Size (cc)</td>
                          <td>{lookupData.EngineSize}</td>
                        </tr>
                        <tr>
                          <td className="fw-600">Fuel Type</td>
                          <td>{lookupData.FuelType}</td>
                        </tr>
                        <tr>
                          <td className="fw-600">Transmission</td>
                          <td>{lookupData.Transmission}</td>
                        </tr>
                        <tr>
                          <td className="fw-600">Manufactured</td>
                          <td>{lookupData.RegDate}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!!useManualInfo && <>
            <div className="row">
              <div className="col-6">
                <Field
                  label="VIN number"
                  component={renderField}
                  name={`vin`}
                  id={'vin'}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col-6">
                <Field
                  className="select-z-index-fix2"
                  label="Type"
                  component={CustomSelect}
                  placeholder="Select"
                  name={`type`}
                  id={'type'}
                  getOptionLabel={(option) => option.value}
                  getOptionValue={(option) => option.value}
                  options={[
                    { value: 'Car' },
                    { value: 'Bike' },
                    { value: 'Van' },
                    { value: 'Motorhome' },
                    { value: 'Other' },
                  ]}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <Field
                  label="Make"
                  component={renderField}
                  name={`make`}
                  id={'make'}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col">
                <Field
                  label="Model"
                  component={renderField}
                  name={`model`}
                  id={'model'}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col">
                <Field
                  label="Series"
                  component={renderField}
                  name={`series`}
                  id={'series'}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <Field
                  label="Drive type"
                  component={renderField}
                  name={`drive_type`}
                  id={'drive_type'}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col">
                <Field
                  label="Engine size"
                  component={renderField}
                  name={`engine_size`}
                  id={'engine_size'}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col">
                <Field
                  label="Transmission"
                  component={renderField}
                  name={`transmission`}
                  id={'transmission'}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 b-form-group">
                <Field
                  type="text"
                  id="reg_date"
                  label="Manufactured"
                  component={renderDatePicker}
                  inputValueFormat="DD-MM-YYYY"
                  placeholderText="DD/MM/YYYY"
                  dateFormat="dd/MM/yyyy"
                  fixedHeight
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  yearDropdownItemNumber={3}
                  name={`reg_date`}
                  className="form-control"
                  validate={[required({message: 'Please enter a date.'})]}
                />
              </div>
            </div>
            </>}

            <div className="row">
              <div className="col-md-6 b-form-group">
                <Field
                  type="text"
                  label="Sale price"
                  component={renderField}
                  name={`retail_price`}
                  className="form-control"
                  append={{
                    direction: 'left',
                    text     : <i className="wf-icon-gbp"/>,
                  }}
                  validate={[required({message: 'Please enter a sale price.'})]}
                />
              </div>
              <div className="col-md-6 b-form-group">
                <Field
                  type="text"
                  label="Mileage"
                  component={renderField}
                  name={`mileage`}
                  className="form-control"
                  validate={[required({message: 'Please enter a mileage.'})]}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="form-group">
                  <button
                    className="btn btn-secondary"
                    type="submit"
                    disabled={!valid}
                  >
                   Save changes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline btn-form-cancel"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

EditVehicleDetails.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

const validate = values => {
}

const selector = formValueSelector('vehicle-edit')

export default connect((state) => ({
  type: selector(state, 'type'),
}))(reduxForm({
    form: 'vehicle-edit',
    destroyOnUnmount: true,
    validate,
  })(EditVehicleDetails),
)
