import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '../../../../ui/Avatar'
import { echo, formatDate, formatExcess, money } from '../../../../../utils/misc'

export const PolicyDetails = ({ policy, isQuote }) => {
  const { cover_level, created_by } = policy

  return (
    policy && <div className="px-5">
      <h6 className="my-5 text-uppercase">{isQuote ? 'Quote' : 'Policy'} details</h6>

      <div className="row">
        <div className="col">
          <span className="label">Cover level</span>
          {cover_level && <p className="font-md">{echo(cover_level.name)}, {echo(policy.duration)}</p>}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <span className="label">Start date</span>
          <p className="font-md">{formatDate(policy.start_date)}</p>
        </div>
        <div className="col">
          <span className="label">End date</span>
          <p className="font-md">{formatDate(policy.end_date)}</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <span className="label">Claim limit</span>
          <p className="font-md">{money.format(policy.claim_limit)}</p>
        </div>
        <div className="col">
          <span className="label">Labour rate</span>
          <p className="font-md">{money.format(policy.labour_rate)}</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <span className="label">Excess</span>
          {formatExcess(policy.excess_type, policy.excess)}
        </div>
      </div>

      <div className="d-flex justify-content-between mt-3 mb-5 pb-4 border-bottom">
        <span className="font-xl fw-800 text-gray-800">Price</span>
        <span className="font-xl fw-800 text-gray-800">{money.format(policy.net + policy.vat)}</span>
      </div>

      { created_by && <div>
        <h6 className="mt-3 text-uppercase">{isQuote ? 'Quote' : 'Policy'} created by</h6>
        <div className="d-flex py-3 mb-3">
          <>
            <Avatar user={created_by}/>
            <span className="ml-2">{echo(created_by.first_name)} {echo(created_by.last_name, '')}</span>
          </>
        </div>
      </div> }

      <h6 className="mt-3 text-uppercase">source</h6>
      <div className="mb-3">
        <span>{policy.purchased_from}</span>
      </div>

      {policy.extras && policy.extras.length > 0 && <>
        <h6 className="mt-4 text-uppercase">Policy Extras</h6>
        <div>
          {policy.extras.map((extra, idx) => (
            <p key={idx}>{extra.title}</p>
          ))}
        </div>
      </>}

    </div>
  )
}

PolicyDetails.defaultProps = {
  isQuote: false
}

PolicyDetails.propTypes = {
  policy: PropTypes.object.isRequired
}

export default PolicyDetails