import React, { Component } from 'react'
import './styles/App.scss'
import '@coreui/icons/css/coreui-icons.css'
import LoginPage from './components/pages/Login'
import PolicyholderLoginPage from './components/pages/Login/Policyholder'
import PrivateRoute from './components/PrivateRoute'
import { history } from './utils/history'
import Admin from './components/pages/AdminLayout'
import { setTokenClaims, activate2FA, logout } from './actions/authentication.action'
import { connect } from 'react-redux'
import { accessTokenClaims } from './utils/auth'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import TwoFactorAuthPage from './components/pages/TwoFactorAuthPage'
import ForgotPasswordPage from './components/pages/ForgotPasswordPage'
import ResetPasswordPage from './components/pages/ForgotPasswordPage/ResetPasswordPage'
import SetPasswordPage from './components/pages/SetPassword'
import PolicyholderRegisterPage from './components/pages/PolicyholderPortal/Register';

class App extends Component {
  componentDidMount() {
    let token = localStorage.getItem('wf-token')
    let tokenData = accessTokenClaims(token)
    this.props.setTokenClaims(tokenData)

    const need2FA = localStorage.getItem('need-2FA') === 'true'
    if (need2FA) {
      this.props.activate2FA()
    }

    //Set up an event listener to check a logout and log this page out too.
    window.addEventListener('storage', e => {
      if(e.key === 'wf-token' && e.oldValue && !e.newValue) {
        this.props.logout();
      }
    });
  }
  //This component will never unmount as its the root app component, therefore a componentWillUnmount isn't required.
  render() {
    const { auth } = this.props
    const isAuthenticated = auth.user && auth.user.exp * 1000 > new Date().getTime()
    const permissions = auth.user && auth.user.permissions

    return (
      <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
        {isAuthenticated && !auth.need2FA ? (
          <React.Fragment>
            <PrivateRoute path="/" component={Admin} permissions={permissions}/>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route exact path="/auth/two-factor" component={TwoFactorAuthPage} />
              <Route exact path="/auth/forgot" component={ForgotPasswordPage} />
              <Route exact path="/auth/forgot/reset-password/:token" component={ResetPasswordPage} />
              <Route exact path="/auth/set-password/:token" component={SetPasswordPage} />
              <Route exact path="/policyholder-portal" component={PolicyholderLoginPage} />
              <Route exact path="/policyholder-portal/register" component={PolicyholderRegisterPage} />
              {(!auth.isLoading && !auth.isPending) && (
                <Redirect
                  to={{
                    pathname: '/',
                    state: { from: window.location.pathname }
                  }}
                />
              )}
            </Switch>
          </React.Fragment>
        )}
      </Router>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.authentication,
})

export default connect(
  mapStateToProps,
  {setTokenClaims, activate2FA ,logout},
)(App)
