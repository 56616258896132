import React from 'react'
import PropTypes from 'prop-types'
import PlaceholderImage from '../../images/avatars/1.jpg'

function FilterOption(props) {
  return <div
    className={(props.localFilters.filter(props.callbackfn).length > 0) ? 'filter py-2 px-3 active' : 'filter py-2 px-3'}
    onClick={props.onClick}
  >
    {props.filter.color && <span className="color-blob" style={{backgroundColor: props.filter.color}}></span>}
    {props.filter.avatar && <img className="filter-avatar" src={PlaceholderImage} alt="Filter Avatar" />}
    {props.filter.icon && <i className={'filter-icon ' + props.filter.icon}></i>}
    <span>{props.filter.name}</span>
  </div>
}

FilterOption.propTypes = {
  localFilters: PropTypes.arrayOf(PropTypes.any),
  callbackfn: PropTypes.func,
  onClick: PropTypes.func,
  filter: PropTypes.any,
}

export default FilterOption
