import React from 'react'
import {FieldArray} from "redux-form";
import renderExcess from "../../../../../ui/fields/renderExcess";
import renderLabourRates from "../../../../../ui/fields/renderLabourRates";
import renderSundries from "../../../../../ui/fields/renderSundries";

const CustomPricingRules = ({coverLevels, durations}) => {

  return <>
    <table className="table table-striped table-bordered pricing-rule-table custom-pricing-rule-table mt-4 mb-5">
      <thead>
      <tr>
        <th width="160px">Excess</th>
        <th width="325px">Policy cost impact</th>
        <th>Applies to cover level(s)</th>
        <th width="100px">Default*</th>
        <th>Active**</th>
        <th>Delete</th>
      </tr>
      </thead>
      <tbody>
      <FieldArray name={'rules'} component={renderExcess} coverLevels={coverLevels} deleteRow={() => {}} />
      </tbody>
    </table>

    <table className="table table-striped table-bordered pricing-rule-table custom-pricing-rule-table">
      <thead>
      <tr>
        <th width="160px">Labour rate</th>
        <th width="325px">Policy cost impact</th>
        <th>Applies to cover level(s)</th>
        <th width="100px">Default*</th>
        <th>Active**</th>
        <th>Delete</th>
      </tr>
      </thead>
      <tbody>
      <FieldArray name={'labour_rates'} component={renderLabourRates} coverLevels={coverLevels} deleteRow={() => {}} />
      </tbody>
    </table>


    <div className="" style={{ marginTop: 65 }}>
      <table className="table table-striped table-bordered pricing-rule-table custom-pricing-rule-table" style={{ marginTop: 40 }}>
        <thead>
        <tr>
          <th width="160px">Sundry</th>
          <th width="325px">Policy cost impact</th>
          <th>Applies to cover level(s)</th>
          <th>Applies to duration(s)</th>
          <th>Active**</th>
          <th>Delete</th>
        </tr>
        </thead>
        <tbody>
        <FieldArray name={'sundries'} component={renderSundries} coverLevels={coverLevels} durations={durations} deleteRow={() => {}} />
        </tbody>
      </table>
    </div>
  </>
}


export default CustomPricingRules
