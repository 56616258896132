import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SlideToggle from '../../../../ui/SlideToggle'
import PricingForm from './forms/PricingForm'
import api from '../../../../../utils/api'
import { checked } from '../../../../../utils/misc'
import { NotificationManager } from 'react-notifications'
import {Link} from "react-router-dom";

const Pricing = ({ allowDealerPrice, basePriceBandId, handleSubmit, dealer }) => {
  const [edit, setEdit] = useState(false)
  const [priceBands, setPriceBands] = useState([])

  // fetch price bands
  useEffect(() => {
    api
      .get('/misc/price-bands?target=Dealer&limit=999')
      .then((res) => {
        setPriceBands(res.data.data)
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Failed to retrieve price bands.'
          )
        }
      })
  }, [])

  const pricesBandSelected = (id) => {
    return (
      priceBands.length > 0 && priceBands.filter((pb) => pb.id === id)[0].name
    )
  }

  const onSubmit = (values) => {
    return handleSubmit(values).then(() => {
      setEdit(false)
    })
  }

  return (
    <div className="row">
      <div className="col col-sm-12">
        <header className="pr-4 my-4 d-flex align-items-center justify-content-between">
          <h6 className="mb-0 text-uppercase">Pricing</h6>
          <button
            onClick={() => setEdit(!edit)}
            className="btn-gray"
            data-tip="Edit pricing"
            data-place="top"
          >
            <i className="wf-icon-edit d-block" />
          </button>
        </header>

        <SlideToggle isVisible={edit}>
          <PricingForm
            onSubmit={onSubmit}
            priceBands={priceBands}
            onCancel={() => setEdit(false)}
            initialValues={{
              allow_dealer_price: allowDealerPrice,
              base_price_band_id: { id: basePriceBandId, name: pricesBandSelected(basePriceBandId)},
              disable_special_vehicle_pricing: dealer.disable_special_vehicle_pricing,
              allow_staff_view_pricing: dealer.allow_staff_view_pricing,
            }}
          />
        </SlideToggle>

        <div className="row">
          {!edit && (
            <div className="col-md-4 col-sm-12">
              <span className="label">Dealer price band (to use as base)</span>
              <p className="font-md">
                {basePriceBandId ? pricesBandSelected(basePriceBandId) : '-'}
              </p>

              <Link to={`/external-sales/dealers/${dealer.id}/custom-price-band`} className="btn btn-warning btn-sm font-weight-bold my-2">
                Request custom prices
              </Link>

              <p className="font-md mt-4">
                {checked(dealer.disable_special_vehicle_pricing)}
                <span>Turn off special vehicle pricing</span>
              </p>
              <p className="font-md mt-4">
                {checked(dealer.allow_staff_view_pricing)}
                <span>Allow dealer staff to view pricing</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

Pricing.propTypes = {
  allowDealerPrice: PropTypes.bool.isRequired,
}

export default Pricing
