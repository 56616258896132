import React  from 'react'
import {get} from 'lodash'
import { Link } from 'react-router-dom'
import GarageForm from './GarageForm'
import moment from "moment";
import api from '../../../../../utils/api'
import {history} from "../../../../../utils/history";
import {NotificationManager} from 'react-notifications'

const CreateGarage = props => {

  const onSubmit = values => {
    let data = {
      name: values.name,
      postcode: values.postcode,
      address_1: values.address_1 || values.address.address_1,
      address_2: values.address_2 || values.address.address_2,
      city: values.city || values.address.city,
      county: values.city || values.address.county,

      signup_date: moment(values.signup_date).format('YYYY-MM-DD'),
      authorised_repairer: values.authorised_repairer,
      vat_registered: values.vat_registered,
      vat_number: values.vat_registered ? values.vat_number : null,
      tel_office: values.tel_office,
      tel_mobile: values.tel_mobile,
      website: values.website,
      email: values.email,
      dealer_ids: get(values, 'dealer', []).map(dealer => dealer.id),
      comments: values.comments,
      minimum_labour_rate: values.minimum_labour_rate,
      sell_out_labour_rate: values.sell_out_labour_rate,
    }
    api.post('/claims/garages', data)
      .then(res => {
        history.push('/claims/garages')
        NotificationManager.success('Woohoo! You created a garage');
      })
      .catch(err => {
        console.log(err);
        NotificationManager.error('Oops! Something went wrong creating the garage. Please refresh and try again.');
      })
  }

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/claims/garages" className="open active">
            Garages
          </Link>
        </li>
        <li className="breadcrumb-item">
          <span className="active">Add a garage</span>
        </li>
      </ol>

      <GarageForm onSubmit={onSubmit} />
    </div>
  )
}

CreateGarage.propTypes = {

}

export default CreateGarage