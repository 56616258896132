import React from 'react'
import * as PropTypes from 'prop-types'

function PrioritiseField({ onValue }) {
  const [priority, setPriority] = React.useState(false)

  return <div className="form-group">
    <label htmlFor="prioritise">Prioritise</label>
    <br/>
    <div
      className={`assign-priority wf-icon-prioritise ${priority && 'wf-icon-prioritise-selected'}`}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        onValue(!priority)
        setPriority(!priority)
      }}
    />
  </div>
}

PrioritiseField.propTypes = {
  onValue: PropTypes.func.isRequired,
}


export default PrioritiseField