import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="d-flex h-100 align-items-center justify-content-center">
      <div>four-oh-four</div>
    </div>
  )
};

export default NotFoundPage;