import React, { useEffect, useState } from 'react'
import PageHeader from '../../../ui/PageHeader'
import Table from '../../../ui/Table'
import { Link } from 'react-router-dom'
import { sortCaret } from '../../../../utils/misc'
import api from '../../../../utils/api'
import NotificationManager from 'react-notifications'
import { uniq, uniqueId } from 'lodash'

const Policyholders = (props) => {
  const [state, setState] = useState({
    total: 10,
    query: '',
    page: 1,
    desc: false,
    limit: 10,
    order: '',
    policyholders: null,
    searching: false,
    url: null,
  })

  const fetchData = (url) => {
    api
      .get(`/external-sales/policyholders${url ? url : ''}`)
      .then((res) => {
        setState((prev) => ({
          ...prev,
          policyholders: res.data,
          searching: false,
        }))
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again.'
          )
        }
      })
  }
  useEffect(() => {
    fetchData()
  }, [])

  const handleTableChange = (
    type,
    { sortField, sortOrder, page, sizePerPage }
  ) => {
    let url = `?page=${page}&desc=${
      sortOrder === 'desc' ? true : false
    }&limit=${sizePerPage}&query=${state.query}`
    if (sortField) url = `${url}&order=${sortField}`

    setState((prev) => ({ ...prev, url }))

    fetchData(url)
  }

  /**
   * Search
   * @param query
   */
  const handleSearch = (query) => {
    const { page, desc, limit } = state
    let url = `?query=${query}&page=${page}&desc=${desc}&limit=${limit}`
    fetchData(url)
  }

  const { policyholders, searching } = state

  const options = {
    showOptions: false,
    columns: [
      {
        dataField: 'name',
        text: 'Policyholder name',
        formatter: (cell, row) => (
          <Link
            key={row.id}
            to={`/external-sales/policyholders/${row.id}/timeline`}
          >
            {row.title} {row.first_name} {row.last_name}
          </Link>
        ),
        sort: true,
        sortCaret,
      },
      {
        dataField: uniqueId('policies_'),
        text: 'Associated policy ID(s)',
        formatter: (cell, row) =>
          row.policies.map((policy, index) => [
            index > 0 && ', ',
            <Link
              key={index}
              to={`/external-sales/policies/${policy.id}/policy`}
            >
              {policy.ref}
            </Link>,
          ]),
      },
      {
        dataField: 'vrm',
        text: 'Vehicle reg',
        formatter: (cell, row) => {
          let regs = row.policies.map((policy) => {
            return policy.vehicle.vrm
          })
          let uniqRegs = uniq(regs)

          return uniqRegs.map((policy, index) => [index > 0 && ', ', policy])
        },
      },
    ],
  }

  return (
    <>
      <br />
      <div className="container-fluid animated fadeIn">
        <PageHeader
          title="Policyholders"
          description="A list of all Warranty First policyholders. You can view all contact history between Warranty First and a policyholder by viewing their timeline."
        />
        <br />
        {policyholders && (
          <Table
            resource={policyholders}
            showOptions={options.showOptions}
            columns={options.columns}
            hasTableSearch={true}
            handleTableChange={handleTableChange}
            onSearch={handleSearch}
            searching={searching}
          />
        )}
      </div>
    </>
  )
}

Policyholders.propTypes = {}

export default Policyholders
