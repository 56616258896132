import { validInteger, validPhoneNumber, validPostcode } from '../../../../../utils/misc'
import { get } from 'lodash'

export const validate = fields => {
  let errors = {}

  if (!fields.name)
    errors.name = 'You must enter a dealership name.'

  if (!fields.address)
    errors.address = 'You must select an address.'

  if (!fields.postcode) errors.postcode = 'You must enter a postcode.'

  if (fields.postcode && !validPostcode(fields.postcode))
    errors.postcode = 'Postcode doesn\'t look quite right.'

  if (!fields.active_start_date)
    errors.active_start_date = 'You must select a sign-up date.'

  if (!fields.tel_office && !fields.tel_mobile)
    errors.tel_office = 'You must enter at least one telephone number.'

  if (fields.tel_office && !validPhoneNumber(fields.tel_office))
    errors.tel_office = 'That phone number doesn\'t look quite right.'

  if (fields.tel_mobile && !validPhoneNumber(fields.tel_mobile))
    errors.tel_mobile = 'That phone number doesn\'t look quite right.'

  if(fields._addressForm) {
    if (fields.address && fields.address.postcode && !validPostcode(fields.address.postcode)) {
      errors.address = {}
      errors.address.postcode = 'That postcode doesn\'t look quite right.'
    }
  }

  if (fields._hasPrimaryContact) {
    const office = get(fields.primary_contact, 'tel1');
    const mobile = get(fields.primary_contact, 'tel2');

    errors.primary_contact = {};

    if (office && !validPhoneNumber(office)) {
      errors.primary_contact.tel1 = 'That phone number doesn\'t look quite right.'
    }

    if (mobile && !validPhoneNumber(mobile))
      errors.primary_contact.tel2 = 'That phone number doesn\'t look quite right.'
  }

  if (!fields.price_band)
    errors.price_band = 'You must select a price band.'

  if (fields.policies_per_month && !validInteger(fields.policies_per_month))
    errors.policies_per_month = 'You must enter a positive number.'

  if (fields.potential_revenue && !validInteger(fields.potential_revenue))
    errors.potential_revenue = 'You must enter a positive number.'

  if (fields.cars_in_stock && !validInteger(fields.cars_in_stock))
    errors.cars_in_stock = 'You must enter a positive number.'

  if (fields.lowest_stock_price && !validInteger(fields.lowest_stock_price))
    errors.lowest_stock_price = 'You must enter a positive number.'

  if (fields.highest_stock_price && !validInteger(fields.highest_stock_price))
    errors.highest_stock_price = 'You must enter a positive number.'

  return errors
}