/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { times } from 'lodash'
import { Field, change, untouch } from 'redux-form'
import renderField from '../../../../../../utils/renderField'

const TargetTable = ({ dispatch, year, formKey, values }) => {
  const resetTable = (e) => {
    e.preventDefault()

    for (let i = 0; i < 12; i++) {
      dispatch(change('teamTargetForm', formKey + '.months.' + i, '0', false))
      dispatch(untouch('teamTargetForm', formKey + '.months.' + i))
    }
    dispatch(change('teamTargetForm', formKey + '.annual', '', true))
    dispatch(untouch('teamTargetForm', formKey + '.annual'))
  }

  return (
    <>
      <div style={{ minWidth: '2500px', paddingRight: '30px' }}>
        <table
          className={
            'table table-expand table-striped-inverse table-teamTargets'
          }
        >
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td>
                <label className={'label mb-2'}>Annual Budget</label>
              </td>
              {times(12, (idx) => {
                return (
                  <td key={idx}>
                    <label className={'label mb-2'}>
                      {moment().month(idx).format('MMM')} {year}
                    </label>
                  </td>
                )
              })}
            </tr>
            <tr>
              <td>Target</td>
              <td>
                <Field
                  type="text"
                  label=""
                  component={renderField}
                  append={{
                    direction: 'left',
                    text: <i className="wf-icon-gbp" />,
                  }}
                  name={formKey + `.annual`}
                  className="form-control form-control-sm"
                  showSuccessValidation={false}
                />
              </td>
              {times(12, (idx) => {
                return (
                  <td key={idx}>
                    <Field
                      type="text"
                      label=""
                      component={renderField}
                      append={{
                        direction: 'left',
                        text: <i className="wf-icon-gbp" />,
                      }}
                      name={formKey + `.months.${idx}`}
                      className="form-control form-control-sm"
                      showSuccessValidation={false}
                      disabled={!values.annual || values.annual === ''}
                    />
                  </td>
                )
              })}
            </tr>
            <tr>
              <td>Volume</td>
              <td>
                <strong>100%</strong>
              </td>
              {times(12, (idx) => {
                return (
                  <td key={idx}>
                    <strong>
                      {(values.annual &&
                        values.months &&
                        values.months[idx] &&
                        !isNaN(values.months[idx] / values.annual) &&
                        ((values.months[idx] / values.annual) * 100).toFixed(
                          2
                        ) + '%') ||
                        0 + '%'}
                    </strong>
                  </td>
                )
              })}
            </tr>
          </tbody>
        </table>
        <a
          style={{
            display: 'inline-block',
            marginBottom: '20px',
            marginTop: '10px',
            fontSize: '14px',
          }}
          href={'#'}
          onClick={resetTable}
        >
          Clear Table
        </a>
      </div>
    </>
  )
}

export default connect()(TargetTable)
