import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import CreateForm from '../forms/create'
import api from '../../.././.././../utils/api'
import { history } from '../../../../../utils/history'
import {
  convertVehicleValues,
  convertValues,
  updateVehicle,
} from '../forms/helpers'
import { NotificationManager } from 'react-notifications'
import { reset } from 'redux-form'
import { get } from 'lodash'
import { confirmAlert } from 'react-confirm-alert'

const SpecialVehicleEdit = ({ dispatch, match, ...props }) => {
  const onSubmit = (values) => {
    updateVehicle(convertValues(values, vehicle.target), match.params.id)
      .then(() => {
        dispatch(reset('special-vehicles/create'))
        history.push(
          `/admin/special-vehicles/${vehicle.target
            .split(' ')
            .map((word) => word.toLowerCase())
            .join('-')}`
        )
        NotificationManager.success('Woohoo! Your vehicle was saved!')
      })
      .catch((err) => {
        if (
          get(err.response, 'data.errors', false) &&
          Object.keys(get(err.response, 'data.errors', false)).filter((key) =>
            key.startsWith('models.')
          )
        ) {
          let errorString = err.response.data.errors[
            get(err.response, 'data.errors', false) &&
              Object.keys(
                get(err.response, 'data.errors', false)
              ).filter((key) => key.startsWith('models.'))[0]
          ].join(' ')
          confirmAlert({
            title: '🧐 Uh-oh!',
            message: errorString,
            customUI: ({ title, message, onClose }) => {
              return (
                <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                    <span
                      className="btn-close-modal fa fa-close"
                      onClick={onClose}
                    />
                    <h3>{title}</h3>
                    <p>{message}</p>

                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={onClose}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              )
            },
          })
        } else {
          NotificationManager.error(
            'Oops! Something went wrong saving your vehicle. Please try again.'
          )
        }
      })
  }

  const [vehicle, setVehicle] = useState(null)
  useEffect(() => {
    let vehicleId = match.params.id
    api
      .get(`/admin/special-vehicles/${vehicleId}`)
      .then((res) => {
        setVehicle(res.data)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          history.push('/404')
        } else {
          NotificationManager.error(
            'Oops! Something went wrong. Please refresh the page.'
          )
        }
      })
  }, [])

  const [coverLevels, setCoverLevels] = useState(null)
  useEffect(() => {
    if (vehicle) {
      api
        .get(`admin/cover-levels?target=${vehicle.target}&limit=1000`)
        .then((res) => {
          setCoverLevels(res.data.data)
        })
        .catch((err) => {
          setCoverLevels(null)
          NotificationManager.error(
            'Oops! Something went wrong. Please refresh the page.'
          )
        })
    }
  }, [vehicle])

  const getUrlTarget = (target) => {
    return target
      .split(' ')
      .map((w) => w.toLowerCase())
      .join('-')
  }

  return (
    <div>
      <ol className="breadcrumb">
        {vehicle && (
          <>
            <li className="breadcrumb-item">
              <Link
                to={`/admin/special-vehicles/${getUrlTarget(vehicle.target)}`}
                className="open active"
              >
                {vehicle.target} special vehicles
              </Link>
            </li>
            <li className="breadcrumb-item">
              <span className="active">Edit a special vehicle</span>
            </li>
          </>
        )}
      </ol>

      <div className="container-fluid">
        {vehicle && coverLevels && (
          <CreateForm
            editing={true}
            onSubmit={onSubmit}
            target={vehicle.target}
            onCancelRedirectTo={'/admin/special-vehicles/public'}
            initialValues={convertVehicleValues(vehicle, coverLevels)}
            vehicle={vehicle}
          />
        )}
      </div>
    </div>
  )
}

export default connect((state) => ({}))(SpecialVehicleEdit)
