import React, { Component } from 'react'
import PageHeader from '../../ui/PageHeader'
import { deleteUserGroup, fetchUserGroups, searchUserGroups } from '../../../actions/user-groups.actions'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Table from '../../ui/Table'
import ReactTooltip from 'react-tooltip'
import { sortCaret } from '../../../utils/misc'
import { confirmAlert } from 'react-confirm-alert'

class UserGroupsPage extends Component {
  state = {
    groups: [],
    query: '',
  }

  componentDidMount() {
    this.props.fetchUserGroups()
  }


  handleSearch = query => {
    this.setState({query})
    this.props.searchUserGroups(query)
  }

  handleTableChange = (
    type,
    {sortField, sortOrder, data, page, sizePerPage},
  ) => {
    let url = `?page=${page}&desc=${
      sortOrder === 'desc' ? true : false
      }&limit=${sizePerPage}`
    if (sortField) url = `${url}&order=${sortField}`

    this.props.fetchUserGroups(url)
  }

  handleDelete = (id, row) => {
    confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({title, message, onClose}) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose}/>
              <h3>{title}</h3>
              <p>
                Delete the user group <strong>{row.name}</strong>? It'll be lost
                forever.
              </p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  this.props.deleteUserGroup(id)
                  this.props.fetchUserGroups();
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  render() {
    const {userGroups, isPending} = this.props

    const options = {
      showOptions: false,
      columns: [
        {
          dataField: 'name',
          text: 'Group',
          sort: true,
          sortCaret,
        },
        {
          dataField: 'users_count',
          text: 'Members',
          sort: true,
          sortCaret,
        },
        {
          dataField: 'color',
          text: 'Colour',
          formatter: (cell, row) => {
            return <div style={{
              background: row.color,
              width: 25,
              height: 25,
              borderRadius: 25,
            }}/>

          },
        },
        {
          dataField: 'id',
          text: 'Actions',
          headerStyle: {
            width: 100,
          },
          formatter: (cell, row) => (
            <div className="d-flex align-items-center">
              <Link
                data-tip="Edit group"
                data-place="top"
                to={`/admin/user-groups/${row.id}/edit`}
                className="table-action-btn"
                primary="true"
                size="sm"
              >
                <span className="icon-note"/>
              </Link>
              <Link
                data-tip="Delete group"
                data-place="top"
                to={`#`}
                className="table-action-btn table-action-btn--delete"
                size="sm"
                onClick={() => this.handleDelete(row.id, row)}
              >
                <span className="icon-trash"/>
              </Link>
              <ReactTooltip effect="solid"/>
            </div>
          ),
        },
      ],
    }
    return (
      <>
        <div className="container-fluid animated fadeIn">
          <br/>
          <PageHeader
            title="User groups"
            description="Groups allow you to set user rights for different teams and individuals using this system. Once you have set up your groups, users can be assigned to them. Super admin rights can not be amended or deleted."
            buttonLink={'/admin/user-groups/create'}
            buttonText="Add a group"
            buttonIcon="d-block wf-icon-add"
          />

          {userGroups && (
            <Table
              resource={userGroups}
              loading={isPending}
              showOptions={options.showOptions}
              handleTableChange={this.handleTableChange}
              columns={options.columns}
              hasTableSearch={true}
              onSearch={this.handleSearch}
              searching={this.props.searching}
            />
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  isPending: state.territories.isPending,
  userGroups: state.userGroups.data,
  searching: state.userGroups.searching
})

export default connect(
  mapStateToProps,
  {fetchUserGroups, deleteUserGroup, searchUserGroups},
)(UserGroupsPage)
