import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import api from '../../../../../utils/api'
import { history } from '../../../../../utils/history'
import PolicyDetails from '../../Policies/Show/PolicyDetails'
import VehicleDetails from '../../Policies/Show/VehicleDetails';
import { echo, formatDate, prettyNumber } from '../../../../../utils/misc';
import { NotificationManager } from 'react-notifications';

const Claim = (props) => {

  const params = useParams()
  const [claim, setClaim] = useState(null)

  useEffect(() => {
    fetchClaim();
  }, [params.id])

  const fetchClaim = () => {
    api
      .get(`/policyholder-portal/claims/${params.id}`)
      .then((res) => {
        setClaim(res.data)
      })
      .catch((err) => {
        if (err && err.response.status === 404) {
          return history.push('/404')
        }
      })
  }

  /**
   * Handles a file download
   *
   * @param {string} endpoint  - API endpoint to fetch the download from
   * @param {string } filename  - The filename to give the downloaded file
   */
   const handleDownload = async (endpoint, filename) => {
    NotificationManager.success('Woohoo! Your download will start soon...')
    const xhr = await api.get(endpoint, {responseType: 'blob'})
    const url = window.URL.createObjectURL(new Blob([xhr.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  }

  const downloadCertificateClicked = async () => {
    await handleDownload(
      `/policyholder-portal/downloads/certificate/${claim.policy.id}`,
      `${claim.policy.ref}_Warranty_Certificate.pdf`
    );
  }

  return (
    claim && (
      <div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/policyholder-portal/claims" className="open active">
              Your claims
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">Claim ref. {claim.policy.ref}</span>
          </li>
        </ol>

        <div className="container" style={{ maxWidth: '750px' }}>
          <header className="py-4 d-flex align-items-center justify-content-between border-bottom">
            <div className="row">
              <div className="col-10">
                <h3 style={{ fontSize: 36 }}>
                    Claim ref. {claim.policy.ref}
                </h3>
              </div>
              <div className="col-2 text-right mt-1">
                {claim.status === 'Rejected' &&
                  <span className="badge badge-blacklisted badge-pill font-sm py-2 px-3">
                    {claim.status}
                  </span>
                }
                {claim.status !== 'Rejected' &&
                  <span className="badge badge-primary badge-pill font-sm py-2 px-3">
                    {claim.status}
                  </span>
                }
              </div>
            </div>
          </header>


          <div className="row mt-4">
            <div className="col-12">
              <div className="mt-4 mb-4">
                <div className="row">
                  <div className="col d-flex align-items-center">
                    <h6 className="text-uppercase">Fault details</h6>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <span className="label">Fault found on</span>
                  <p>{formatDate(claim.fault_date)}</p>
                </div>
                <div className="col-4">
                  <span className="label">
                    Current accurate mileage
                  </span>
                  <p>
                    {claim.mileage
                      ? prettyNumber(claim.mileage) + ' miles'
                      : '-'}
                  </p>
                </div>
                <div className="col-12">
                  <span className="label">Pre-diagnosis comments</span>
                  <p className="font-md">{echo(claim.prediagnosis)}</p>
                </div>
                <div className="col-12">
                  <span className="label d-block">
                    Post-diagnosis comments
                  </span>
                  <p className="font-md">
                    {echo(claim.postdiagnosis)}
                  </p>
                </div>
                <div className="col-12">
                  <span className="label">Repairing garage</span>
                  <p className="font-md">{claim.garage ? echo(claim.garage.name) : '-'}</p>
                </div>

              </div>

            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <hr />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12 mb-3">
              <VehicleDetails vehicle={claim.policy.vehicle} />
            </div>
          </div>

          <div className="row">
            <div className="col-5 pr-5">
              <div
                className="ph-claim d-flex justify-content-between align-items-center cursor-pointer"
                onClick={downloadCertificateClicked}
              >
                <div className="ph-document-ref font-md">
                  Warranty Certificate
                </div>
                <div className="ph-documents-links mt-2">
                  <i className="wf-icon-download d-inline-block text-blue font-weight-bold"/>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <hr />
            </div>
          </div>

          <div className="row">
            <div className="col-12 mb-4">
              <PolicyDetails
                policy={claim.policy}
                showWhatsCoveredLink={false}
              />
            </div>
          </div>

        </div>

      </div>
    )
  )
}

Claim.propTypes = {}

export default Claim
