import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const Modal = ({ isShowing, hide, title, style, subtitle, onCancel, textCancel, onSubmit, textAction, children, hideCloseButton, submitDisabled }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="wf-modal-overlay">
      <div className="wf-modal" style={style}>
        <div className="wf-modal-body">
          {!hideCloseButton && <span className="btn-close-modal fa fa-close" onClick={hide || onCancel}/>}
          {title && <h3 className="mb-0">{title}</h3>}
          {subtitle && <p className="mt-0">{subtitle}</p>}

          <div className={`${onSubmit && textAction && 'mb-3'}`}>
            {children}
          </div>

          {onSubmit && (
            <button
              className="btn btn-secondary"
              onClick={onSubmit}
              disabled={submitDisabled}
            >
              {textAction}
            </button>
          )}

          {onCancel && textCancel && <button
            type="button"
            className="btn btn-transparent text-secondary"
            onClick={onCancel}
          >
            {textCancel}
          </button>}
        </div>
      </div>
    </div>
  </React.Fragment>, document.querySelector('#root-modal'),
) : null

Modal.propTypes = {
  title    : PropTypes.string.isRequired,
  subtitle : PropTypes.string,
  hide     : PropTypes.func,
  isShowing: PropTypes.bool.isRequired,

  onSubmit  : PropTypes.func,
  textAction: PropTypes.string,

  onCancel  : PropTypes.func,
  textCancel: PropTypes.string,

  hideCloseButton: PropTypes.bool
}


Modal.defaultProps = {
  title: '🧐 Are you sure?',
  textAction: 'Yes, I\'m sure',
  textCancel: 'No, cancel',
  hideCloseButton: false,
}
export default Modal