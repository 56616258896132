import {
  FETCH_PIPELINE_DATA_FAILURE,
  FETCH_PIPELINE_DATA_REQUEST,
  FETCH_PIPELINE_DATA_SUCCESS,
  PIPELINE_UPDATE_CARD_POSITION,
} from '../constants'

const initialState = {
  isPending : true,
  data : null,
  error : null,
  searching : false,
}


const boards = (state = {}, action) => {
  switch (action.type) {
    case PIPELINE_UPDATE_CARD_POSITION:
      const oldLaneId = action.payload.oldLaneId
      const newLaneId = action.payload.newLaneId
      const cardId = action.payload.cardId
      const destinationIndex = action.payload.destinationIndex

      // if we are in the same column
      if (oldLaneId === newLaneId) {

        // make o copy of the current state
        let data = [...state]

        // select the board the user reorders
        const currentBoard = data.filter(board => board.id === oldLaneId)[0]
        // select the card the user is currently dragging
        const currentCard = currentBoard.items.data.filter(c => c.id === cardId)[0]

        const currentCardIndex = currentBoard.items.data.findIndex(card => card.id === cardId)

        data.map(board => {
          // board that the user is currently dragging into / reordering
          if (board.id === oldLaneId) {
            // remove and replace the card
            board = {
              ...board,
              items : {
                ...board.items,
                data : [
                  ...board.items.data.splice(currentCardIndex, 1),
                  ...board.items.data.splice(destinationIndex, 0, currentCard),
                ],
              },

            }
          }
          return board
        })


        return data
      }

      let oldBoard = state.filter(board => board.id === oldLaneId)[0]
      let newBoard = state.filter(board => board.id === newLaneId)[0]

      let card = oldBoard.items.data.filter(row => row.id === cardId)[0]

      // remove card from old board
      oldBoard = {
        ...oldBoard,
        items : {
          ...oldBoard.items,
          data : [
            ...oldBoard.items.data.filter(obj => obj.id !== card.id),
          ],
        },
      }
      newBoard.items.data.splice(destinationIndex, 0, card)

      newBoard = {
        ...newBoard,
        items : {
          ...newBoard.items,
          data : newBoard.items.data,
        },
      }

      const boards = [oldBoard, newBoard]

      boards.forEach(board => {
        let index = state.findIndex((row => row.id === board.id))
        state.splice(index, 1, board)
      })

      return state

    default:
      return state
  }
}

export function pipelines(state = initialState, action) {
  switch (action.type) {
    case FETCH_PIPELINE_DATA_REQUEST:
      return {
        ...state,
        isPending : true,
      }
    case FETCH_PIPELINE_DATA_SUCCESS:
      return {
        ...state,
        isPending : false,
        data : {...action.payload.pipelines},
      }
    case FETCH_PIPELINE_DATA_FAILURE:
      return {
        ...state,
        isPending : false,
        data : null,
        error : {...action.payload.error},
      }

    case PIPELINE_UPDATE_CARD_POSITION:
      return {
        ...state,
        isPending : false,
        data : {
          ...state.data,
          data : boards(state.data.data, action),
        },
      }

    default:
      return state
  }
}