import React from 'react'
import { NotificationManager } from 'react-notifications';
import { Form, reduxForm } from 'redux-form'
import { Field } from 'redux-form'
import api from '../../../../utils/api';
import pDebounce from '../../../../utils/pDebounce';
import CustomAsyncSelect from '../../../ui/CustomAsyncSelect';
import renderDatePicker from '../../../ui/renderDatePicker'

const DealerAndDatePickers = ({ handleSubmit }) => {

  const fetchDealers = async (query) => {
    try {
      const res = await api.get(`/misc/dealers`, {params: {
          query, limit: 100,
          is_prospect: false,
          active_only: true,
        }});
      return res.data.data;
    } catch {
      NotificationManager.error('Oops! Something went wrong retrieving dealer information.')
    }
  }

  const loadDealers = pDebounce(q => (fetchDealers(q)), 1000)


  return (
    <Form
      onSubmit={handleSubmit}
      className={'d-flex align-items-end pull-right mb-4'}
    >
      <div className="row">
        <div className="col-12">
          <Field
            component={CustomAsyncSelect}
            label="Which dealer is this report for?"
            name="dealer"
            placeholder="Search dealers..."
            isClearable={false}
            loadOptions={loadDealers}
            defaultOptions={false}
            getOptionLabel={(d) => d.name}
            getOptionValue={(d) => d.id}
          />
        </div>
        <div className="col-6">
          <Field
            type="date"
            name="date_from"
            label="From"
            className="form-control form-control-sm from-date-field"
            inputValueFormat="DD-MM-YYYY"
            placeholderText="DD/MM/YYYY"
            dateFormat="dd/MM/yyyy"
            fixedHeight
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            yearDropdownItemNumber={3}
            id="fromDate"
            component={renderDatePicker}
            icon={false}
          />
        </div>
        <div className="col-6">
          <Field
            type="date"
            name="date_to"
            label="To"
            className="form-control form-control-sm to-date-field"
            inputValueFormat="DD-MM-YYYY"
            placeholderText="DD/MM/YYYY"
            dateFormat="dd/MM/yyyy"
            fixedHeight
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            yearDropdownItemNumber={3}
            id="toDate"
            component={renderDatePicker}
            icon={false}
          />
        </div>
      </div>
    </Form>
  )
}

const validate = (values) => {
  let errors = {}
  if (!values.date_from) {
    errors.date_from = 'From Date is required'
  }
  if (!values.date_to) {
    errors.date_to = 'To Date is required'
  }

  return errors
}

export default reduxForm({
  form: 'report/dealeranddaterange',
  validate,
})(DealerAndDatePickers)
