import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, Form, reduxForm, change } from 'redux-form'
import renderField from '../../../../../utils/renderField'
import renderDatePicker from '../../../../ui/renderDatePicker'
import { Link } from 'react-router-dom'
import CustomSelect from '../../../../ui/CustomSelect'
import { API_URL } from '../../../../../utils/constants'
import api from '../../../../../utils/api'
import { NotificationManager } from 'react-notifications'
import SlideToggle from '../../../../ui/SlideToggle'
import {
  formatPostcode,
  validPostcode,
} from '../../../../../utils/misc'
import { useDispatch } from 'react-redux'
import { required } from 'redux-form-validators'
import CustomAsyncSelect from '../../../../ui/CustomAsyncSelect'
import AdditionalInfo from './AdditionalInfo'
import Invoicing from './Invoicing'
import DealerPricing from './DealerPricing'
import ContactDetails from './ContactDetails'
import { validate } from './validateForm'

const CreateDealerForm = props => {
  const { handleSubmit, onSubmit, cancel, valid, dirty, fetchGarages, fetchPricebands, addressFieldVisible, postcode } = props

  const [addressForm, setAddressForm] = useState(false)

  const [addresses, setAddresses] = useState()

  const [currentVatRegistered, setCurrentVatRegistered] = useState(false)
  const dispatch = useDispatch()

  const handleFormatPostcode = (event, value) => {
    event.preventDefault()

    const formatted = formatPostcode(value)
    dispatch(change('dealers/create', 'postcode', formatted, true))
    dispatch(change('dealers/create', 'address.postcode', formatted, true))
  }

  const handleLookup = postcode => {
    dispatch(change('dealers/create', 'address', null, false))
    api
      .get(`${API_URL}/misc/postcode-lookup?postcode=${postcode}&expand=1`)
      .then(res => {
        let data = res.data.addresses.map(row => {

          // if there is no second line of address and no third line of address
          // get the locality as that will be enough given the fact that they will have 1 line of address any way
          let address_2 = (row.line_2.length === 0) ? row.locality : row.line_2
          let address_3 = row.line_3.length === 0 ? row.locality : ''

          return {
            formatted_address: row.formatted_address.filter(r => r).join(', '),
            address_1: row.line_1,
            address_2,
            address_3: (address_2 === address_3) ? '' : address_3,
            city: row.town_or_city,
            county: row.county,
            postcode,
          }
        })

        setAddresses(data)
        dispatch(change('dealers/create', 'addressFieldVisible', true))
      })
      .catch(() => {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again.',
        )
      })
  }
  const handleVatRegisteredChange = (event) => {
    const vatRegistered = event.target.checked
    setCurrentVatRegistered(vatRegistered)
    if (!vatRegistered) {
      props.dispatch(
        change('dealer/create', 'vat_number', null, true)
      )
    }
  }

  return (
    <div className="mw-37-5rem mx-auto mt-5 mb-5">
      <h1 className="page-title mt-1 mb-4">Add a dealer</h1>

      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Dealership name"
              component={renderField}
              name={`name`}
              className="form-control"
              placeholder={'e.g. 123 Cars'}
            />
          </div>
        </div>
        {!addressForm && (
          <div className="row">
            <div className="col mb-2">
              <Field
                type="text"
                label="Postcode lookup"
                component={renderField}
                name={`postcode`}
                className="form-control"
                button="Lookup address"
                handleSubmit={handleLookup}
                onBlur={handleFormatPostcode}
              />
              {!addressFieldVisible && postcode && validPostcode(postcode || '') && <span className="d-block invalid-feedback pull-up">You must lookup the address.</span>}
              <Link
                to="#"
                className="mb-2 d-block font-sm"
                onClick={() => {
                  setAddressForm(true)
                  dispatch(change('dealers/create', '_addressForm', true))
                  dispatch(change('dealers/create', 'addressFieldVisible', false))
                }}
              >
                Or enter address manually
              </Link>
            </div>
          </div>
        )}

        <SlideToggle isVisible={addressFieldVisible}>
          <div className="row">
            <div className="col">
              <Field
                label="Please select the correct address"
                component={CustomSelect}
                name={`address`}
                id={'addresses'}
                options={addresses}
                getOptionValue={option => option}
                getOptionLabel={option => option.formatted_address}
              />
            </div>
          </div>
        </SlideToggle>

        <Field type={"hidden"} name="_addressForm" value={addressForm} component={renderField} />

        <SlideToggle isVisible={addressForm}>
          <>
            <div className="row">
              <div className="col">
                <Field
                  type="text"
                  label="Address"
                  component={renderField}
                  name={`address_1`}
                  className="form-control"
                  validate={[
                    required({ msg: 'You must enter the first line of address.'})
                  ]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                  type="text"
                  label=""
                  component={renderField}
                  name={`address_2`}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                  type="text"
                  label=""
                  component={renderField}
                  name={`address_3`}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                  type="text"
                  label="City"
                  component={renderField}
                  name={`city`}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                  type="text"
                  label="County"
                  component={renderField}
                  name={`county`}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                  type="text"
                  label="Postcode"
                  component={renderField}
                  name={`postcode`}
                  className="form-control"
                  onBlur={handleFormatPostcode}
                  validate={[
                    required({ msg: 'You must enter a postcode.'})
                  ]}
                />
              </div>
            </div>
          </>
        </SlideToggle>

        <div className="row">
          <div className="col-md-6 b-form-group">
            <Field
              name="active_start_date"
              style={{ width: '100%' }}
              label="Sign-up date"
              className="form-control"
              inputValueFormat="DD-MM-YYYY"
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              fixedHeight
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={3}
              id="dealerStartDate"
              component={renderDatePicker}
            />
          </div>
          <div className="col-md-6 b-form-group">
            <Field
              name="active_end_date"
              style={{ width: '100%' }}
              label="System access end date (optional)"
              className="form-control"
              inputValueFormat="DD-MM-YYYY"
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              fixedHeight
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={3}
              id="dealerEndDate"
              component={renderDatePicker}
            />
          </div>
        </div>
        <hr/>

        <ContactDetails />

        <div className="row">
          <div className="col-md-12 mb-4">
            <h6 className="text-uppercase">Does this dealer have a preferred garage?</h6>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              className="select-z-index-fix"
              label="Preferred garage (optional)"
              component={CustomAsyncSelect}
              name={`garage_id`}
              id={'garage'}
              isClearable
              loadOptions={fetchGarages}
              defaultOptions={true}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
            />
          </div>
        </div>

        <hr className="mt-3 mb-5"/>

        <div className="row">
          <div className="col-md-12 mb-4">
            <h6 className="text-uppercase">Is this dealer is VAT registered?</h6>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="checkbox"
              id={'vatRegistered'}
              label="This dealer is VAT registered"
              component={renderField}
              name={`vat_registered`}
              onChange={handleVatRegisteredChange}
            />
          </div>
        </div>

        {currentVatRegistered && (
          <div className="row">
            <div className="col">
              <Field
                type="text"
                label="VAT number"
                component={renderField}
                name={`vat_number`}
                className="form-control"
              />
            </div>
          </div>
        )}

        <hr className="mt-3 mb-5"/>

        <DealerPricing fetchPricebands={fetchPricebands} />

        <hr className="mt-3 mb-5"/>

        <AdditionalInfo />

        <Invoicing />

        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <button className="btn btn-secondary" disabled={!valid || !dirty}>Add dealer</button>
              <button
                className="btn btn-outline btn-form-cancel"
                onClick={cancel}
              >
                Cancel
              </button>
            </div>
          </div>
          <Field
            type="hidden"
            name="_hasPrimaryContact"
            value={false}
            component={renderField}
          />
        </div>
      </Form>
    </div>
  )
}

CreateDealerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'dealers/create',
  validate,
  initialValues: {
    _addressForm: false,
    _hasPrimaryContact: false,
    addressFieldVisible: false
  }
})(CreateDealerForm)
