import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { hasPermission } from '../../utils/auth';

const MenuLinkDropdown = ({ links }) => {
  return <ul className={`nav-dropdown-items`}>
    {links.map((link, index) => {

      if (!link.permission || hasPermission(link.permission)) {
        // this menu item either does not require a permission or it requires
        // a permission that the user has
        return (
          <li className="nav-item" key={index}>
            <NavLink
              to={link.url}
              className="nav-link"
            >
              {link.icon && <i className={link.icon}/>}
              {link.image && <img className={link.image} alt=""/>}
              <span>{link.name}</span>
            </NavLink>
          </li>
        )
      }

      // this menu item requires a permission that the user does not have
      return (
        <></>
      )

    })}
  </ul>
}

MenuLinkDropdown.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    icon: PropTypes.string,
    name: PropTypes.string
  }))
}

export default MenuLinkDropdown