import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Draggable} from 'react-beautiful-dnd'
import Avatar from './Avatar'
import {history} from '../../utils/history'
import {get, has} from 'lodash'

export const PipelineCardDirectSales = ({card}) => {

  let expires
  if (has(card, 'policy')) {
    expires = `${moment(card.policy.end_date).isBefore() ? 'Expired ' : 'Expires '} ${moment(card.policy.end_date).fromNow()}`
  }

  const viewPolicyholder = () => {
      if (card.hasOwnProperty('policy')) {
          history.push(`/direct-sales/policyholders/${get(card, 'policy.policyholder.id')}/timeline`)
      } else if (card.hasOwnProperty('quote')) {
          history.push(`/direct-sales/prospects/${get(card, 'quote.policyholder.id')}/timeline`)
      }
  }

  return (
      card && (
          <Draggable draggableId={card.id} index={card.rank}>
            {provided => (
                <div
                    className="card"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={viewPolicyholder}
                >


                  <header className="d-flex justify-content-between">
                    <div className="header__content">
                      <h3>
                        {get(card, 'policy.policyholder.first_name', false) || get(card, 'quote.policyholder.first_name')}
                        {' '}
                        {get(card, 'policy.policyholder.last_name', false)  || get(card, 'quote.policyholder.last_name')}
                      </h3>
                      <div className="font-sm gray-700 mt-1">{get(card, 'policy.ref', false) || get(card, 'quote.ref')}</div>
                    </div>
                    <div className="header__actions">
                      <span className="icon-note"/>
                    </div>
                  </header>

                  <div className="card__content d-flex mt-2">
                    <div className="flex-grow-1">
                      <div className="font-sm">
                          {get(card, 'policy.cover_level.name', false) || get(card, 'quote.cover_level.name')}
                          {card.hasOwnProperty('policy') ? ' policy' : ' quote'}
                      </div>
                      <div className="font-sm">
                        {card.lead_type} <br/>
                        {card.lead_type === 'Renewal' && expires}
                      </div>
                    </div>

                    <div className="d-flex align-items-end">
                      {card.prioritised ? <i className="card-prioritised wf-icon-prioritise-selected"/> : ''}
                      {card.assignee && <Avatar className="float-right" user={card.assignee} tooltip />}
                    </div>
                  </div>


                </div>
            )}
          </Draggable>
      )
  )
}

PipelineCardDirectSales.propTypes = {
  card: PropTypes.object.isRequired
}

export default PipelineCardDirectSales