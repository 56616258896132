import React, { useEffect } from 'react'
import { Field, reduxForm, Form } from 'redux-form'
import renderField from '../../../utils/renderField'
import validate from './formValidation'
import CustomSelect from '../../ui/CustomSelect'
import api from '../../../utils/api'
import CustomAsyncSelect from '../../ui/CustomAsyncSelect';

let UsersCreateForm = props => {
  const handleChange = select => {
    if (select.value !== 'none') {
      let group = props.groups.filter(group => group.name === 'Dealer')[0]
      props.change('groups', group)
    }
  }

  const [dealerships, setDealerships] = React.useState(null)

  useEffect(() => {
    async function fetchData () {
      const response = await api.get('/misc/dealers?limit=999999&is_prospect=false')
      let dealerships = response.data.data.map((d, i) => {
        return {
          value: d.id,
          name: d.name,
        }
      })
      dealerships.unshift({
        value: 'none',
        name: 'None'
      })
      setDealerships(dealerships)
    }

    fetchData()
  }, [])

  const loadDealerOptions = async (query) => {
    let res = await api.get(`/misc/dealers?query=${query}&limit=999999&is_prospect=false`);
    return res.data.data;
  }

  const { handleSubmit, onSubmit, cancel, error } = props
  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <h2 className="mb-4">Add a user</h2>
      {error && <div className="alert alert-danger">{error}</div>}

      <div className="row">
        <div className="col">
          <Field
            type="text"
            label="First name"
            component={renderField}
            name={`first_name`}
            className="form-control"
            placeholder="e.g. Jane"
          />
        </div>
        <div className="col">
          <Field
            type="text"
            label="Last name"
            component={renderField}
            name={`last_name`}
            className="form-control"
            placeholder="e.g. Johnson"
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Field
            type="text"
            label="Email address"
            component={renderField}
            name={`email`}
            className="form-control"
            placeholder="e.g. janejohnson@email.com"
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
          {dealerships ?
            <Field
              label="Assign to dealership (optional)"
              component={CustomAsyncSelect}
              // placeholder={props.dealer.name}
              name="dealer_id"
              loadOptions={loadDealerOptions}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              options={props.dealerships}
              className={`select-z-index-fix`}
            />
            :
            <>
              <span className="label">Dealership (optional)</span>
              <p className="font-md">Loading...please wait...</p>
            </>
            }
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <Field
              name="groups"
              isMulti
              value={props.groups}
              label="Groups"
              styles={customSelectStyles}
              onChange={handleChange}
              options={props.groups}
              component={CustomSelect}
              getOptionValue={option => option.value}
              getOptionLabel={option => option.name}
            />
          </div>
        </div>
      </div>

      <div className="form-group mt-3">
        <button disabled={!props.valid} className="btn btn-secondary">
          Add user
        </button>
        <button onClick={cancel} className="btn btn-outline" style={{ color: '#70b725' }}>
          Cancel
        </button>
      </div>
    </Form>
  )
}

const customSelectStyles = {
  input: () => ({
    paddingTop: 11,
    paddingBottom: 11,
  }),
  placeholder: () => ({
    color: '#999',
  }),
}

UsersCreateForm = reduxForm({
  form: 'users/create',
  validate,
})(UsersCreateForm)

export default UsersCreateForm
