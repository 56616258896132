import React from 'react';
import {reduxForm, Form, Field, isInvalid} from 'redux-form'
import CoverLevelTable from "./CoverLevelTable";
import {history} from "../../../../../../utils/history";
import {hasPermission} from "../../../../../../utils/auth";
import CustomSelect from "../../../../../ui/CustomSelect";
import CustomPricingRules from "./CustomPricingRules";
import _, {get} from "lodash";
import {connect} from 'react-redux'

const CustomPricingForm = ({dealer, onSubmit, handleSubmit, coverLevels, durations, allPrices, allValues, hasExistingPriceBand, invalid, currentStatus}) => {
  return <Form onSubmit={handleSubmit(onSubmit)}>
    {hasExistingPriceBand && (
      <div className="container-fluid">
        <div className="col">
          <div style={{ maxWidth: '275px' }}>
          {hasPermission('external_sales.dealers.update_custom_price_band_status') ? (
            <Field
              name="status"
              component={CustomSelect}
              label="Select a status..."
              options={[
                {name: 'In review', value: 'In review'},
                {name: 'Approved', value: 'Approved'},
                {name: 'Rejected', value: 'Rejected'}
              ]}
              getOptionLabel={(o) => o.name}
              getOptionValue={(o) => o.value}
            />
          ) : (
            <div>
              <span className="label">Status:</span>
              <p>{currentStatus}</p>
            </div>
          )}
          </div>
        </div>
      </div>
    )}
    {coverLevels && coverLevels.map((coverLevel, idx) => (
      <CoverLevelTable coverLevel={coverLevel} prices={allPrices} values={allValues} key={idx}/>
    ))}
    <div className={`px-4`}>
      <CustomPricingRules coverLevels={coverLevels} durations={durations} />
    </div>
    <div
      className="px-4 pt-4 d-flex justify-content-between align-items-center"
      style={{ marginBottom: '4rem' }}
    >
      <div>
        <button
          type="submit"
          name="submit"
          className={`btn btn-secondary`}
          disabled={invalid}
        >
          Save price band
        </button>
        <button
          type="submit"
          className="btn btn-transparent text-secondary"
          onClick={() => history.push(`/external-sales/dealers/${dealer.id}/edit`)}
        >
          Cancel
        </button>
      </div>
    </div>
  </Form>
}

const validate = values => {
  const checkRule = (rule, type) => {
    let errors = {};

    if ((type !== 'Sundry') && rule.title < 0) {
      errors.title = 'The value cannot less than 0'
    }

    if (type === 'Excess' && rule.title >= 100) {
      errors.title = 'The percentage must be less than 100'
    }

    if (get(rule, 'effect.value') === undefined) {
      errors.effect = 'You must choose an effect (+ or -)'
    }

    if (get(rule, 'cost_type.value') === undefined) {
      errors.cost_type = 'You must choose a cost type (% or £)'
    } else if (get(rule, 'cost_type.value') === '%' && rule.value >= 100) {
      errors.value = 'The percentage must be less than 100'
    }

    if (!get(rule, 'value')) {
      errors.value = 'Please enter a value'
    }

    if (get(rule, 'value') < 0) {
      errors.value = 'Please enter a positive number'
    }

    if (get(rule, 'cover_levels', []).filter(Boolean).length === 0) {
      errors.active = 'You must select a cover level.'
    }
    return errors;
  }

  let errors = {}

  errors = {...errors, labour_rates: [...get(values, 'labour_rates', []).map((rule, idx) => (checkRule(rule, 'Labour Rate')))]}
  errors = {...errors, rules: [...get(values, 'rules', []).map((rule, idx) => (checkRule(rule, 'Excess')))]}
  errors = {...errors, sundries: [...get(values, 'sundries', []).map((rule, idx) => (checkRule(rule, 'Sundry')))]}

  // eliminate empty errors
  for (const field in errors) {
    if (Object.hasOwnProperty.call(errors, field) && Array.isArray(errors[field])) {
      errors[field] = errors[field].filter((e) => !_.isEmpty(e));
    }
  }

  return errors
}

export default connect((state) => ({
  invalid: isInvalid('dealerCustom/pricingForm'),
}))(reduxForm({
  form: 'dealerCustom/pricingForm',
  enableReinitialize: true,
  validate,
})(CustomPricingForm))
