import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isLight, ucFirst } from '../../utils/misc';
import * as _ from 'lodash';
import searchIcon from '../../images/icons/icn-search.svg';

import FilterOptions from './FilterOptions'
import FilterOption from './FilterOption'
import OmniboxOptions from './OmniboxOptions'
import useDidUpdate from "../../hooks/useDidUpdate";

const SearchBox = (props) => {
  const [omniboxFocused, setOmniboxFocused] = useState(false);
  const [hasSearchQuery, setHasSearchQuery] = useState(false);
  const [localFilters, setLocalFilters] = useState([]);
  const [localQuery, setLocalQuery] = useState('');
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [tempGroup, setTempGroup] = useState(null);

  let inputRef = React.createRef();

  useEffect(() => {
    document.addEventListener('click', clickAwayListener);
    return () => {
      document.removeEventListener('click', clickAwayListener);
    };
  });

  useDidUpdate(() => {
    handleSearch()
    setTempGroup(null)
  }, [localFilters])

  const clickAwayListener = (e) => {
    let path = null;
    if (typeof e.composedPath == 'function') {
      path = e.composedPath()
    } else {
      path = [];
    }

    for (let i = 0; i < path.length; i++) {
      let element = path[i];

      try {
        if (element.classList.contains('omnibox')) {
          return;
        }
      } catch (e) {
        // Do Nothing
      }
    }
    setOmniboxFocused(false);
    setFiltersOpen(false);

    setTempGroup(null);
  };

  const setFiltersClosed = () => {
    let filters = document.querySelector('.option-box');
    if (filters) {
      filters.classList.remove('filters-open');
    }
  };

  const handleSearch = () => {
    props.onSearch(localQuery, localFilters);
    setOmniboxFocused(false);
    setFiltersOpen(false);
    setFiltersClosed();
  };

  const handleKeyInput = (e) => {
    const code = e.keyCode ? e.keyCode : e.which;
    // If you press the Enter key it performs a search
    if (code === 13) {
      handleSearch();
    }

    // If you press the ESC key it hides the option box
    if (code === 27) {
      setOmniboxFocused(false);
      setFiltersOpen(false);
      setTempGroup(null);
      setFiltersClosed();
    }

    // If you press backspace in the omnibox it will remove the last filter added
    if (code === 8 && localQuery === '' && localFilters.length > 0) {
      if (localFilters.length === 1) {
        setLocalFilters([]);
      } else {
        let copy = _.clone(localFilters);
        copy.pop();
        setLocalFilters(copy);
      }
      setTempGroup(null);
    }
  };

  const handleChange = (e) => {
    setLocalQuery(e.target.value);

    if (!filtersOpen) {
      if (e.target.value.length > 0) {
        setHasSearchQuery(true)
      } else {
        setHasSearchQuery(false);
      }
    }
  };

  const openGroupFilters = (e, group_name) => {
    setFiltersOpen(true);
    inputRef.current.focus();

    e.target.classList.add('option--open');

    let children = e.target.childNodes;

    document.querySelector('.option-box').classList.add('filters-open');
    for (let i = 0; i < children.length; i++) {
      let child = children[i];
      try {
        if (child.classList.contains('option-filters')) {
          child.classList.add('active');
        }
      } catch (e) {
        // Do Nothing
      }
    }
    setTempGroup(group_name);
  };

  const addGroupFilter = (group_name, filter) => {
    filter.group_name = group_name;
    filter.cid =
      group_name.split(' ').join('_').toLowerCase() + '_' + filter.id;
    setLocalFilters((filters) => {
      let exists = false;

      filters = filters.filter((f) => {
        if (f.group_name === group_name) {
          if (f.id === filter.id) {
            exists = true;
            return false;
          }
        }
        return f;
      });

      if (exists) return filters;
      else return [...filters, filter];
    });
  };

  const removeFilter = (e, filter) => {
    e.stopPropagation();

    setLocalFilters((filters) => {
      return filters.filter((f) => f.id !== filter.id);
    });
  };

  const clearOmnibox = () => {
    setLocalFilters([]);
    setLocalQuery('');
    setOmniboxFocused(false);
    setFiltersOpen(false);
    setHasSearchQuery(false);
    setTempGroup(null);
    setFiltersClosed();
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: 20,
        ...props.style,
      }}
      className={props.className}
    >
      <div
        className={`omnibox ${omniboxFocused && 'focus'}`}
        style={{ maxWidth: props.fullWidth ? '100%' : 'auto' }}
        onClick={() => setOmniboxFocused(true)}
      >
        <div className="filters">
          {props.omnibox &&
            localFilters.length > 0 &&
            localFilters.map((filter) => (
              <div className="filter" key={filter.cid}>
                {filter.group_name !== filter.name && (
                  <div className="badge filter-label">
                    <span>{ucFirst(filter.group_name)}</span>
                  </div>
                )}
                <div
                  className={
                    isLight(filter.color)
                      ? 'badge filter-label filter-value'
                      : 'badge filzter-label filter-value text-light'
                  }
                  key={filter.name}
                >
                  <div
                    className="badge badge-pill"
                    style={{
                      backgroundColor: filter.color,
                    }}
                  >
                    {filter.icon && (
                      <i className={'filter-icon ' + filter.icon} />
                    )}{' '}
                    {filter.name}
                  </div>
                  <span
                    className="remove"
                    role="presentation"
                    onClick={(e) => removeFilter(e, filter)}
                  >
                    <div className="wf-icon-x" />
                  </span>
                </div>
              </div>
            ))}

          {tempGroup && (
            <div className="filter">
              <div className="badge filter-label">
                <span>{ucFirst(tempGroup)}</span>
              </div>
            </div>
          )}
        </div>
        <div className="search-box">
          <input
            ref={inputRef}
            autoFocus
            id="search-box-input"
            className="form-control"
            placeholder={props.searchPlaceholder}
            type="text"
            onKeyDown={handleKeyInput}
            onChange={handleChange}
            value={localQuery}
            autoComplete="off"
          />

          {omniboxFocused && (
            <OmniboxOptions
              hasSearchQuery={hasSearchQuery}
              omnibox={props.omnibox}
              searchIcon={searchIcon}
              localQuery={localQuery}
              filtersOpen={filtersOpen}
              loopFilters={(group, i) => {
                if (
                  group.allowOnlyOne &&
                  localFilters.filter((f) => f.group_name === group.name)
                    .length > 0
                ) {
                  return false;
                }
                if (group.singleValueFilter) {
                  return (
                    <SingleValueFilter
                      group={group}
                      onClick={() =>
                        addGroupFilter(group.name, group.filters[0])
                      }
                      key={i}
                    />
                  );
                }

                return (
                  <FilterOptions
                    key={group.name}
                    onClick={(e) => openGroupFilters(e, group.name)}
                    group={group}
                    filtersOpen={filtersOpen}
                    filterQuery={(f) =>
                      new RegExp(localQuery, 'gi').test(f.name) ? f : false
                    }
                    localQuery={localQuery}
                    searchFilters={(filter) =>
                      filtersOpen &&
                      new RegExp(localQuery, 'gi').test(filter.name) ? (
                        <FilterOption
                          localFilters={localFilters}
                          callbackfn={(f) =>
                            f.cid === group.name + '_' + filter.id
                          }
                          key={group.name + '_' + filter.id}
                          onClick={() => addGroupFilter(group.name, filter)}
                          filter={filter}
                        />
                      ) : (
                        []
                      )
                    }
                  />
                );
              }}
            />
          )}
        </div>

        {props.searching && (
          <div className="spinner-border spinner-border-sm" role="status" />
        )}

        {hasSearchQuery && !props.searching && (
          <div className="clear" onClick={clearOmnibox}>
            <div className="wf-icon-x" />
          </div>
        )}

        {!hasSearchQuery && localFilters.length > 0 && !props.searching && (
          <div className="clear" onClick={clearOmnibox}>
            <div className="wf-icon-x" />
          </div>
        )}
      </div>
    </div>
  );
};

const SingleValueFilter = ({ group, onClick }) => {
  return (
    group && (
      <div className="option py-2 px-3" onClick={onClick}>
        <span>{group.name}</span>
      </div>
    )
  );
};

SearchBox.propTypes = {
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  searching: PropTypes.bool.isRequired,
  searchPlaceholder: PropTypes.string,
  omnibox: PropTypes.object,
  fullWidth: PropTypes.bool,
};

SearchBox.defaultProps = {
  searchPlaceholder: 'Search or filter table...',
  fullWidth: false,
};

export default SearchBox;
