import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import _ from 'lodash'

const CustomTableActions = ({ tableActions }) => {
  return (
    tableActions && (
      <div className="custom-table-actions table-actions">
        {tableActions.map((action, i) => {
          if (_.has(action, 'isVisible')) if (!action.isVisible) return false

          return (
            <span
              key={i}
              className={action.iconClass}
              data-tip={action.tooltip.text}
              data-place={action.tooltip.place}
              onClick={action.callback}
            />
          )
        })}
        <ReactTooltip effect="solid" />
      </div>
    )
  )
}

CustomTableActions.propTypes = {
  tableActions: PropTypes.arrayOf(
    PropTypes.shape({
      tooltip: PropTypes.shape({
        text: PropTypes.string,
        place: PropTypes.string,
      }),
      callback: PropTypes.func.isRequired,
      iconClass: PropTypes.string.isRequired,
      isVisible: PropTypes.bool, // for conditionally displaying certain table actions
    }),
  ).isRequired,
}

export default CustomTableActions
