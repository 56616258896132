import React, {useEffect, useState} from 'react'
import {Field, Form, reduxForm, initialize} from "redux-form";
import api from "../../../../../../utils/api";
import {NotificationManager} from "react-notifications";
import CustomSelect from "../../../../../ui/CustomSelect";

const BasePriceBandForm = ({dispatch, dealer}) => {
  const [dealerPriceBands, setDealerPriceBands] = useState([]);
  useEffect(() => {
    api
      .get('/misc/price-bands?target=Dealer&limit=999')
      .then((res) => {
        setDealerPriceBands(res.data.data)
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Failed to retrieve price bands.'
          )
        }
      })
  }, [])

  useEffect(() => {
    if (!dealer.base_price_band_id) {
      return;
    }
    if (dealerPriceBands === []) {
      return;
    }
    let dealerPriceBand = dealerPriceBands.find(pb => (pb.id === dealer.base_price_band_id));
    if (!dealerPriceBand) {
      return;
    }
    dispatch(initialize('dealerCustom/basePriceBandForm', {
      base_price_band_id: dealerPriceBand,
    }));
  }, [dealer, dealerPriceBands])


  return <>
    <Form onSubmit={() => {}} >
      <div className="col">
        <Field
          name="base_price_band_id"
          component={CustomSelect}
          label="Choose price band"
          options={dealerPriceBands}
          getOptionLabel={(o) => o.name}
          getOptionValue={(o) => o.id}
        />
      </div>
    </Form>
  </>
}

export default reduxForm({
  form: 'dealerCustom/basePriceBandForm',
  enableReinitialize: true,
})(BasePriceBandForm)