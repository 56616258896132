import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import moment from 'moment'
import api from '../../../../utils/api'
import PageHeader from '../../../ui/PageHeader'
import DatePickers from './Datepickers'
import Avatar from '../../../ui/Avatar'
import { money } from '../../../../utils/misc'
// import ProspectSource from './ProspectSource'
import TeamTargetTracking from './TeamTargetTracking'

function Dashboard() {
  const [month, setMonth] = useState(moment().format('M'))
  const [year, setYear] = useState(moment().format('YYYY'))

  const [summary, setSummary] = useState(null)
  const [detail, setDetail] = useState(null)

  // const totalProspects = summary ? summary.open + summary.renewal + summary['telephone quote'] : 0

  useEffect(() => {
    api
      .get('/dashboards/direct-sales/summary', {
        params: {
          month,
          year
        },
      })
      .then((res) => {
        setSummary(res.data)
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Whoops! Something went wrong. Please try again after refreshing the page.'
          )
        }
      })
  }, [month, year])

  useEffect(() => {
    api
      .get('/dashboards/direct-sales/detail', {
        params: {
          month,
          year
        },
      })
      .then((res) => {
        setDetail(res.data)
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Whoops! Something went wrong. Please try again after refreshing the page.'
          )
        }
      })
  }, [month,year])

  const handleFilterChange = (values) => {
    setMonth(values.month.value.toString())
    setYear(values.year.value.toString())
  }

  const projectedTotal = () => {
    return detail ? detail.map(item => item.projected).reduce((prev, next) => prev + next) : 0;
  }

  return (
    <div className="container-fluid animated fadeIn bg-grey min-vh-100 dashboard-grey-extend">
      <br />
      <div className="row">
        <div className="col-md-8">
          <PageHeader
            title="Direct Sales Dashboard"
            description={`View your team’s performance data below. See how you’re tracking against your targets and how individuals are performing month on month.`}
          />
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between">
        <div>
          {/* <h3 className="mt-5">Prospects</h3> */}
        </div>
        <div className="dashboard-date-select">
          <DatePickers
            onSubmit={handleFilterChange}
            month={month}
            year={year}
            initialValues={{
              month: {
                label: moment().format('MMMM'),
                value: moment().format('M'),
              },
              year: {
                value: moment().format('Y'),
              },
            }}
          />
        </div>
      </div>

      {/* <ProspectSource summary={summary} totalProspects={totalProspects} /> */}

      <TeamTargetTracking year={year} />

      <div className="bg-white table-spacing pb-1 mb-2">
        <h3>Individual performances</h3>

        <table className="table table-striped table-bordered d-table bg-white">
          <thead>
          <tr>
            <th width="250px">&nbsp;</th>
            <th>Monthly target</th>
            <th>Tracking to target</th>
            <th>Projected Finish</th>
          </tr>
          </thead>
          <tbody>
          {detail &&
          detail.map((row, key) => {
            return (
              <tr key={key}>
                <td>
                  <div className="d-flex align-items-center">
                    <Avatar user={row.user} className="flex-1" />
                      <div className="ml-2">
                        {row.user.first_name} {row.user.last_name}
                      </div>
                    </div>
                </td>
                <td>
                  {row.target === 0 ? 'Not set' : money.format(row.target)}
                </td>
                <td>
                  {row.won_value === 0 ? (
                    '-'
                  ) : (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <span>{row.tracking}%</span>
                        <span>{money.format(row.won_value)}</span>
                      </div>
                      <div className="progress-bar">
                        <div
                          className="progress"
                          style={{ width: row.tracking + '%' }}
                        />
                      </div>
                    </>
                  )}
                </td>
                <td>{money.format(row.projected)}</td>
              </tr>
            )
          })}
          </tbody>
          <tfoot>
          <tr>
            <th colspan="3" className='text-right'>TOTAL</th>
            <th>{money.format(projectedTotal())}</th>
          </tr>
          </tfoot>
      </table>
    </div>

    </div>
  )
}

export default Dashboard
