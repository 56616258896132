import React from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'
import api from '../../utils/api'
import { NotificationManager } from 'react-notifications'

DeleteModal.propTypes = {
  title   : PropTypes.string,
  subtitle: PropTypes.string,

  isShowing: PropTypes.bool.isRequired,
  toggle   : PropTypes.func.isRequired,
  onCancel : PropTypes.func,
  onDone   : PropTypes.func,

  /**
   * Array of row ids that will be deleted.
   */
  items: PropTypes.arrayOf(PropTypes.number.isRequired),

  endPoint: PropTypes.string.isRequired,

  singleDeleteMessage: PropTypes.string,
  multiDeleteMessage : PropTypes.string,
  notFoundMessage    : PropTypes.string,
  serverErrorMessage : PropTypes.string,

  textAction: PropTypes.string,
  textCancel: PropTypes.string,
}

function DeleteModal(props) {
  const { children, items, toggle, endPoint, onDone , style} = props

  const handleDelete = () => {
    return endPoint !== '#' && new Promise((resolve, reject) => {
       api.patch(endPoint, {
        action: 'delete',
        ids   : items,
      }).then(() => {
        toggle && toggle()
        if (items.length === 1) {
          NotificationManager.success(props.singleDeleteMessage)
        }

        if (items.length > 1) {
          NotificationManager.success(props.multiDeleteMessage)
        }
        resolve(onDone('ok'))
      })
        .catch(err => {
          console.log(err)
          if (err && err.response.status === 404) {
            NotificationManager.error(props.notFoundMessage)
          }
          NotificationManager.error(props.serverErrorMessage)
          reject(onDone('error'))
        })
    })
  }

  return (
    <Modal
      hide={props.toggle}
      isShowing={props.isShowing}
      style={style}
      title={props.title}
      onCancel={props.onCancel}
      onSubmit={handleDelete}
      textAction={props.textAction}
      textCancel={props.textCancel}
    >
      <div>
        {children}
      </div>
    </Modal>
  )
}

DeleteModal.defaultProps = {
  title              : '🧐 Are you sure?',
  singleDeleteMessage: 'Success! You deleted a record.',
  multiDeleteMessage : 'Success! You deleted multiple records.',
  notFoundMessage    : `Oops! Record doesn't exist anymore.`,
  serverErrorMessage : 'Oops! Something went wrong. Please try again after refreshing.',
  onDone: () => {},
}

export default DeleteModal