import React, { useState, useEffect } from 'react'
import PageHeader from '../../../../ui/PageHeader'
import IndividualCommissionForm from './IndividualCommissionForm'
import api from '../../../../../utils/api'
import { NotificationManager } from 'react-notifications'
import { set, get } from 'lodash'
import {unregisterField, change, isPristine, initialize, isDirty, reset} from 'redux-form'
import { connect } from 'react-redux'
import { Prompt } from 'react-router-dom'
import YearSelector from "../../Targets/Team/forms/YearSelector";
import moment from "moment";
import useModal from "../../../../../hooks/useModal";
import Modal from "../../../../modals/Modal";

async function initializeFormData(target, year) {
  // this will return an array of rules that need to be set with setFormValues(rules)
  let rules = await refreshData(target, year)

  // setAvailableRules(rules)
  let availableRules = await getAvailableRules()

  let values = {}
  for (let i = 0; i < rules.length; i++) {
    set(
      values,
      `rules.${i}.type`,
      availableRules.find((rule) => rule.value === rules[i].type)
    )
    set(values, `rules.${i}.value`, rules[i].value)
    set(values, `rules.${i}.commission`, rules[i].commission)
    set(values, `rules.${i}.id`, rules[i].id)
    if (rules[i].assignee) {
      set(values, `rules.${i}.assignee`, {
        label: rules[i].assignee.first_name + ' ' + rules[i].assignee.last_name,
        value: rules[i].assignee.id,
      })
    } else {
      set(values, `rules.${i}.assignee`, {
        label: rules[i].assignee_type,
        value: rules[i].assignee_type,
      })
    }
  }
  return {
    rules,
    values,
    availableRules,
  }
}

const refreshData = (header, year) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/admin/commission-rules?target=${header}&year=${year}`)
      .then((response) => {
        let rfIdCount = 0
        let results = response.data.map((item) => {
          return { rfid: rfIdCount++, ...item }
        })
        resolve(results)
      })
      .catch(() => {
        NotificationManager.error(
          'Oops! Something went wrong fetching the commission rules. Please refresh and try again!'
        )
        reject(null)
      })
  })
}
const getAvailableRules = () => {
  return new Promise((resolve, reject) => {
    api
      .get('/misc/commission-rules')
      .then((response) => {
        let rules = []
        for (let key in response.data) {
          if (response.data.hasOwnProperty(key)) {
            rules.push({
              value: key,
              name: response.data[key],
            })
          }
        }
        resolve(rules)
      })
      .catch(() => {
        NotificationManager.error(
          'Oops! Something went wrong fetching the available commission rules. Please refresh and try again!'
        )
        reject(null)
      })
  })
}

const IndividualCommission = ({ type, header, dispatch, pristine, dirty }) => {
  const [availableRules, setAvailableRules] = useState([])
  const [rules, setRules] = useState([])
  const [selectedYear, setSelectedYear] = useState(moment().year())
  const [confirmYear, setConfirmYear] = useState(null)

  useEffect(() => {
    function init() {
      initializeFormData(header, selectedYear).then(({ rules, values, availableRules }) => {
        setFormValues(values)
        setRules(rules)
        setAvailableRules(availableRules)
      })
    }

    init()
  }, [selectedYear])

  const [formValues, setFormValues] = useState({})
  useEffect(() => {}, [rules, availableRules])

  const handleAddNewRule = (e) => {
    e.preventDefault()
    let state = [...rules]
    let rfid = 0

    if (state.length > 0) {
      rfid = Math.max(...state.map((rule) => rule.rfid)) + 1
    }

    state.push({
      rfid: rfid,
      id: 0,
      target: header,
      type: {},
      value: '',
      commission: '',
      assignee_type: '',
      assignee: null,
    })
    setRules(state)
  }

  const [users, setUsers] = useState([])
  useEffect(() => {
    api
      .get('/misc/users', {
        params: {
          groups: [header],
        },
      })
      .then((response) => {
        setUsers(response.data)
      })
      .catch(() => {
        NotificationManager.error(
          'Oops, something went wrong fetching the account managers. Please refresh and try again.'
        )
      })
  }, [])

  const unregisterFields = (rfid) => {
    const fields = ['id', 'type', 'assignee', 'value', 'commission']
    const form = 'individualCommissionForm'
    for (let i = 0; i < fields.length; i++) {
      dispatch(change(form, `rules.${rfid}.${fields[i]}`, null))
      dispatch(unregisterField(form, `rules.${rfid}.${fields[i]}`))
    }
    dispatch(change(form, `rules.${rfid}`, null))
  }

  const handleDelete = (id, rfid) => {
    unregisterFields(rfid)
    if (id === 0) {
      //Hasn't been persisted so just remove.
      let state = [...rules]
      state = state.filter((rule) => rule.rfid !== rfid)
      setRules(state)
    } else {
      api
        .delete(`/admin/commission-rules/${id}`)
        .then(() => {
          let state = [...rules]
          state = state.filter((rule) => rule.rfid !== rfid)
          setRules(state)
          NotificationManager.success(
            'Woohoo! Your commission rule has been deleted.'
          )
        })
        .catch(() => {
          NotificationManager.error(
            'Oops! Something went wrong removing the commission rule.'
          )
        })
    }
  }

  const computePostValues = (rules) => {
    return rules.map((rule) => ({
      year: selectedYear,
      id: rule.id,
      target: header,
      type: get(rule, `type.value`, ``),
      value: rule.value ? rule.value : undefined,
      commission: rule.commission,
      assignee_type:
        typeof rule.assignee.value === 'number'
          ? 'Individual'
          : rule.assignee.value,
      assignee_id:
        typeof rule.assignee.value === 'number'
          ? rule.assignee.value
          : undefined,
    }))
  }

  const handleSubmit = (values) => {
    let data = computePostValues(values.rules.filter((rule) => !!rule))
    let requests = data.map((request) => {
      if (request.id > 0) {
        return api.patch(`/admin/commission-rules/${request.id}`, request)
      } else {
        return api.post(`/admin/commission-rules`, request)
      }
    })
    Promise.all(requests)
      .then(() => {
        NotificationManager.success('Woohoo! Your commission rules were saved!')
        initializeFormData(header, selectedYear).then(
          ({ rules: newRules, values, availableRules: newAvailableRules }) => {
            setRules(newRules)
            setFormValues(values)
            setAvailableRules(newAvailableRules)
            dispatch(initialize('individualCommissionForm', values))
          }
        )
      })
      .catch((err) => {
        if (get(err, `response.status`, 500) === 422) {
          NotificationManager.error(
            'Oops! There was a problem saving your commission rules. There was an issue with the provided data'
          )
        } else {
          NotificationManager.error(
            'Oops! There was a problem saving your commission rules. Please refresh and try again'
          )
        }
      })
  }
  const {isShowing: isShowingConfirmModal, toggle: toggleConfirmModal} = useModal()
  const yearChange = (value) => {
    if (dirty) {
      setConfirmYear(value.year)
      toggleConfirmModal()
    } else {
      setSelectedYear(value.year)
      return value
    }
  }

  const confirmYearChange = () => {
    setSelectedYear(confirmYear)
    toggleConfirmModal()
  }

  const cancelYearChange = () => {
    toggleConfirmModal()
    dispatch(reset('yearForm'))
  }

  return (
    <>
      <br />
      <div className="container-fluid animated fadeIn">
        <PageHeader title={'Commission'} description="" />
        <Prompt
          when={!pristine}
          message={'You have unsaved changes, are you sure you want to leave?'}
        />

        <ul className="list-info mb-4" style={{ maxWidth: '570px' }}>
          <li>Assign and edit budget rules and commission structures.</li>
          {header === 'External Sales' && <li>Rules can be assigned to Account Managers.</li>}
          {header === 'Direct Sales' && <li>Rules can be assigned to Direct Sales Agents and Managers.</li>}
        </ul>
        <YearSelector initialValues={{year: {year: selectedYear}}}  handleChange={yearChange} />
        <IndividualCommissionForm
          onSubmit={handleSubmit}
          type={type}
          header={header}
          rules={rules}
          availableRules={availableRules}
          initialValues={formValues}
          handleAddNewRule={handleAddNewRule}
          users={users}
          handleDelete={handleDelete}
        />
      </div>
      <Modal onCancel={cancelYearChange} onSubmit={confirmYearChange} isShowing={isShowingConfirmModal} hide={toggleConfirmModal} style={{maxWidth: '435px'}}>
        <>
          You have made changes to this year and not saved them. Do you want to change year?
        </>
      </Modal>
    </>
  )
}

export default connect((state) => {
  return {
    pristine: isPristine('individualCommissionForm')(state),
    dirty: isDirty('individualCommissionForm')(state),
  }
})(IndividualCommission)
