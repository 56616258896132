import { Link } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import { SubmissionError } from 'redux-form'
import { pick } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import DealerDetailsForm from './forms/DealerDetailsForm'
import SlideToggle from '../../../../ui/SlideToggle'
import api from '../../../../../utils/api'
import { checked } from '../../../../../utils/misc'

const DealerDetails = ({ dealer, setDealer }) => {
  const [toggle, setToggle] = useState(false)

  /**
   * Update dealer details form
   *
   * @param  {Object} values Form submitted values
   */
  const handleSubmitDealerDetailsForm = (values) => {
    const data = pick(values, [
      'name',
      'address_1',
      'address_2',
      'address_3',
      'city',
      'county',
      'postcode',
      'vat_registered',
      'vat_number'
    ])

    return api
      .patch(`/external-sales/prospects/${dealer.id}/details`, data)
      .then((res) => {
        const updated = { ...dealer, ...res.data }
        setDealer(updated)

        NotificationManager.success(
          'Woohoo! Dealer details updated successfully!'
        )
        setToggle(false)
      })
      .catch((err) => {
        if (err && err.response.status === 422) {
          throw new SubmissionError({
            ...err.response.data.errors,
            _error: err.response.data,
          })
        }
      })
  }

  return (
    <div className="row">
      <div className="col">
        <div style={{ maxWidth: 570 }} className="edit-dealer-column">
          <header className="d-flex align-items-center justify-content-between">
            <div>
              <h6 className="mt-4 text-uppercase">Dealer details</h6>
              <Link to={`/external-sales/prospects/${dealer.id}/timeline`}>View timeline</Link>
            </div>
            <button
              onClick={() => setToggle(!toggle)}
              className="btn-gray"
              data-tip="Edit dealer details"
              data-place="top"
            >
              <i className="wf-icon-edit d-block" />
            </button>
          </header>
          {!toggle && (
            <>
              <div className="row">
                <div className="col mt-4">
                  <span className="label">Dealership name</span>
                  <p className="font-md">{dealer.name}</p>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <span className="label">Address</span>
                  <p className="font-md">
                    {[
                      dealer.address_1,
                      dealer.address_2,
                      dealer.address_3,
                      dealer.city,
                      dealer.country,
                      dealer.postcode,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <p className="font-md">
                    {checked(dealer.vat_registered)}
                    <span>This dealer is VAT registered</span>
                  </p>
                </div>
              </div>

            </>
          )}

          <SlideToggle isVisible={toggle}>
            <DealerDetailsForm
              onSubmit={handleSubmitDealerDetailsForm}
              toggle={setToggle}
              initialValues={{ ...dealer, status: { value: dealer.status } }}
              isProspect={true}
              vatRegistered={dealer.vat_registered}
            />
          </SlideToggle>

          <hr />
        </div>
      </div>
    </div>
  )
}

DealerDetails.propTypes = {
  dealer: PropTypes.object.isRequired,
  setDealer: PropTypes.func.isRequired,
}

export default DealerDetails
