import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { change, Field, formValueSelector, reduxForm, untouch } from 'redux-form'
import { required, numericality } from 'redux-form-validators';
import { connect, useDispatch } from 'react-redux'
import renderField from '../../../../../utils/renderField'
import CustomSelect from '../../../../ui/CustomSelect'
import renderDatePicker from '../../../../ui/renderDatePicker'
import moment from 'moment'
import { money } from '../../../../../utils/misc'
import api from "../../../../../utils/api";
import { uniqBy, sortBy } from 'lodash'

const StepPolicy = ({
  handleSubmit,
  onSubmit,
  valid,
  policy_price,
  policy_start_date,
  net,
  vat,
  dealer,
  mileage,
  sale_price,
  vehicleData,
  coverLevel,
  duration,
  labourRate,
  claimLimit,
  excess,
  sundries,
  price,
  dealerId,
  policyCostText,
  policyPriceText
}) => {

  const dispatch = useDispatch()
  const [policyEndDate, setPolicyEndDate] = useState()
  const [specialVehicle, setSpecialVehicle] = useState([]);
  const [coverLevels, setCoverLevels] = useState([])
  const [createPolicyConfig, setCreatePolicyConfig] = useState({})

  useEffect(() => {
    api.get(`/misc/special-vehicle-lookup`, {
      params: {
        make: vehicleData.Make,
        model: vehicleData.Model,
        target: 'Dealer',
        dealer_id: dealerId || dealer.id,
      }
    })
      .then(res => setSpecialVehicle(res.data))
      .catch(() => setSpecialVehicle(null))
  }, [])

  useEffect(() => {
    if (specialVehicle && specialVehicle.type === 'Prestige') {
      // force labour rate to £100
      labourRate = {id: -1, title: '100', value: 0.00}
      setLabourRateOptions([labourRate])
      dispatch(change('create-policy-policy', 'labour_rate', labourRate, true))
    }
  }, [specialVehicle])

  useEffect(() => {
    if (!dealerId) {
      dealerId = dealer.id
    }
    api.get(`/external-sales/policies/create/${dealerId}/config`)
      .then(res => {setCreatePolicyConfig(res.data); console.log('config', res.data)})
      .catch(() => setCreatePolicyConfig({}))
  }, [dealer])

  useEffect(() => {
    if (!dealerId) {
      dealerId = dealer.id
    }
    api.get(`/external-sales/policies/create/${dealerId}/plans`, {
      params: {
        legacy: false,
        type: vehicleData.Type.value || vehicleData.Type,
        make: vehicleData.Make,
        model: vehicleData.Model,
        drive_type: vehicleData.DriveType,
        engine_size: vehicleData.EngineSize,
        reg_date: vehicleData.RegDate.split('-').reverse().join('-'),
        mileage: mileage,
        sale_price: sale_price,
      }
    })
      .then(res => setCoverLevels(res.data))
      .catch(() => setCoverLevels([]))
  }, [dealer])

  const [durationOptions, setDurationOptions] = useState([]);
  useEffect(() => {
    if (coverLevel && 'prices' in coverLevel) {
      //Set a bunch of unique options, by duration, unique by value, sorted by value.
      setDurationOptions(sortBy(
        uniqBy(coverLevel.prices.map(p =>
            ({name: (p.special_duration || p.duration), value: parseInt(p.duration.split(' ')[0])}))
          , (item) => (item.value))
        , (item) => (item.value)));
    } else {
      setDurationOptions([]);
    }
  }, [coverLevel])

  const [claimLimitOptions, setClaimLimitOptions] = useState([]);
  const [excessOptions, setExcessOptions] = useState([]);
  const [labourRateOptions, setLabourRateOptions] = useState([]);
  const [sundryOptions, setSundryOptions] = useState([]);
  useEffect(() => {

    if (!coverLevel || !('prices' in coverLevel) || !duration) {
      setClaimLimitOptions([]);
      setExcessOptions([]);
      setLabourRateOptions([]);
      setSundryOptions([]);
      return;
    }

    setClaimLimitOptions(sortBy(uniqBy(coverLevel.prices.filter((p) => (p.duration === duration.name)).map((p) => ({name: `£${p.claim_limit}`, value: parseInt(p.claim_limit)})), (i) => (i.value)), (i) => (i.value)));

    let params = {
      cover_level: coverLevel.id,
      dealer_id: dealerId || dealer.id,
    };
    api.get('/misc/price-rules/dealer', {params})
      .then(response => {

        // load dropdown options
        setExcessOptions(response.data.filter(pr => (pr.type === 'Excess')).sort((a, b) => (a.title - b.title)))
        if (!specialVehicle || specialVehicle.type !== 'Prestige') { // because for Prestige the labour rate is fixed at £100
          setLabourRateOptions(response.data.filter(pr => (pr.type === 'Labour Rate')).sort((a, b) => (a.title - b.title)))
        }
        if (duration) {
          setSundryOptions(response.data.filter(pr => (pr.type === 'Sundry' && pr.durations && pr.durations[duration.name])))
        } else {
          setSundryOptions([]);
        }

        // set default options (where applicable)
        if (!excess) {
          dispatch(change('create-policy-policy', 'excess', findDefaultValue(response.data.filter(pr => (pr.type === 'Excess'))), true))
        }
        if (!labourRate) {
          dispatch(change('create-policy-policy', 'labour_rate', findDefaultValue(response.data.filter(pr => (pr.type === 'Labour Rate'))), true))
        }

      });
  }, [coverLevel, duration])

  // when the dropdown options change, if an option is currently chosen that no longer exists
  // in the dropdown, clear the selected value
  useEffect(() => {
    if (duration && durationOptions.filter(o => { return o.value === duration.value }).length === 0) {
      dispatch(change('create-policy-policy', 'duration', []))
      dispatch(untouch('create-policy-policy', 'duration'))
    }
  }, [durationOptions])

  // when the dropdown options change, if an option is currently chosen that no longer exists
  // in the dropdown, clear the selected value
  useEffect(() => {
    if (claimLimit && claimLimitOptions.filter(o => { return o.value === claimLimit.value }).length === 0) {
      dispatch(change('create-policy-policy', 'claim_limit', []))
      dispatch(untouch('create-policy-policy', 'claim_limit'))
    }
  }, [claimLimitOptions])

  // when the dropdown options change, if an option is currently chosen that no longer exists
  // in the dropdown, clear the selected value
  useEffect(() => {
    if (excess && excessOptions.filter(o => { return o.id === excess.id }).length === 0) {
      dispatch(change('create-policy-policy', 'excess', []))
      dispatch(untouch('create-policy-policy', 'excess'))
    }
  }, [excessOptions])

  // when the dropdown options change, if an option is currently chosen that no longer exists
  // in the dropdown, clear the selected value
  useEffect(() => {
    if (labourRate && labourRateOptions.filter(o => { return o.id === labourRate.id }).length === 0) {
      dispatch(change('create-policy-policy', 'labour_rate', []))
      dispatch(untouch('create-policy-policy', 'labour_rate'))
    }
  }, [labourRateOptions])

  // when the sundries dropdown options change clear all selected values
  useEffect(() => {
    dispatch(change('create-policy-policy', 'sundries', []))
    dispatch(untouch('create-policy-policy', 'sundries'))
  }, [sundryOptions])

  const handleResetForm = () => {
    let fields = {
      cover_level      : [],
      duration         : [],
      claim_limit      : [],
      labour_rate      : [],
      policy_start_date: '',
      excess           : '',
      policy_price     : '',
    }

    setPolicyEndDate(false)

    Object.keys(fields).forEach(key => {
      dispatch(change('create-policy-policy', key, fields[key]))
      dispatch(untouch('create-policy-policy', key))
    })
  }

  const calculateDate = (months = 1) => {
    const date = moment(policy_start_date, 'DD/MM/YYYY')
    if (date.isValid()) {
      const future_date = moment(date)
        .add(months, 'month')
        .subtract(1, 'day')
        .format('DD/MM/YYYY')

      setPolicyEndDate(future_date)
    }
  }

  useEffect(() => {
    dispatch(change('create-policy-policy', 'policy_start_date', moment()))
  }, [])

  useEffect(() => {
    // if duration changed calculate new date
    duration && calculateDate(duration.value)
  }, [duration, policy_start_date])

  useEffect(() => {
    let net_price = parseFloat(policy_price) / 1.20
    let vat_price = parseFloat(policy_price) - net_price

    dispatch(change('create-policy-policy', 'net', net_price))
    dispatch(change('create-policy-policy', 'vat', vat_price))

  }, [policy_price])

  const findDefaultValue = (set) => {
    //Find one marked as default
    if (set.filter(i => i.default).length) {
      return set.filter(i => i.default)[0]
    }
    //Else return first
    if (set.length > 0) {
      return set[0];
    }
    //Return nothing if nothing found
    return null;
  }
  return (
    <div className="mx-auto" style={{ maxWidth: '900px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <h6 className="mt-4 mb-4 text-uppercase">Policy Details</h6>
          </div>
        </div>

        <div className="row justify-content-between">
          <div className="col-6">
            <div className="row">
              <div className="col">
                <Field
                  label="Cover level *"
                  component={CustomSelect}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.name}
                  name={`cover_level`}
                  id={'coverLevel'}
                  options={coverLevels}
                  validate={[
                    required({
                      message: 'You must select a cover level.',
                    }),
                  ]}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <Field
                  label="Duration *"
                  component={CustomSelect}
                  getOptionValue={option => option.value}
                  getOptionLabel={option => option.name}
                  name={`duration`}
                  id={'duration'}
                  options={durationOptions}
                  validate={[
                    required({
                      message: 'You must select a duration.',
                    }),
                  ]}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <Field
                      label="Claim limit *"
                      component={CustomSelect}
                      getOptionValue={option => option.value}
                      getOptionLabel={option => option.name}
                      name={`claim_limit`}
                      id={'claim_limit'}
                      options={claimLimitOptions}
                      validate={[required({ msg: 'You must select a claim limit.'})]}
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <Field
                  label="Labour rate *"
                  component={CustomSelect}
                  getOptionValue={option => option.title}
                  getOptionLabel={option => `£${option.title}`}
                  name={`labour_rate`}
                  id={'labourRate'}
                  options={labourRateOptions}
                  validate={[required({ msg: 'You must select a labour rate.'})]}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <Field
                  type="text"
                  id="policyStartDate"
                  label="Policy start date *"
                  component={renderDatePicker}
                  inputValueFormat="DD-MM-YYYY"
                  placeholderText="DD/MM/YYYY"
                  dateFormat="dd/MM/yyyy"
                  fixedHeight
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  yearDropdownItemNumber={3}
                  name={`policy_start_date`}
                  className="form-control"
                  validate={[required({ msg: 'You must select the policy start date.'})]}
                />
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="col-form-label pt-0" htmlFor="">
                    Policy end date
                  </label>
                </div>
                <div style={{ marginTop: '-10px' }}>{policyEndDate}</div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <Field
                  label="Excess *"
                  component={CustomSelect}
                  getOptionValue={option => option.title}
                  getOptionLabel={option => `${option.title}%`}
                  name={`excess`}
                  options={excessOptions}
                  validate={[required({ msg: 'You must select the excess.'})]}
                />
              </div>
              <div className="col-6">
                <Field
                  name="sundries"
                  isMulti
                  component={CustomSelect}
                  label="Sundries"
                  options={sundryOptions}
                  getOptionValue={(option) => option.title}
                  getOptionLabel={(option) => option.title}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p>* Mandatory fields</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {coverLevel && coverLevel.max_age_exceeded &&
                <div className="vehicle-warning">
                  <p style={{color: 'red'}}>{vehicleData.VRM} is too old for this cover level.</p>
                </div>
                }
                {coverLevel && coverLevel.max_mileage_exceeded &&
                <div className="vehicle-warning">
                  <p style={{color: 'red'}}>{vehicleData.VRM} current mileage is too high for this cover level.</p>
                </div>
                }
                {coverLevel && coverLevel.max_price_exceeded &&
                <div className="vehicle-warning">
                  <p style={{color: 'red'}}>{vehicleData.VRM} has a sale price that is too high for this cover level.</p>
                </div>
                }
              </div>
            </div>
            
          </div>
          <div className="col-4">
            {createPolicyConfig.can_view_dealer_pricing &&
            <div
              className="bg-white shadow-light mb-3"
              style={{ padding: '30px' }}
            >
              <span className="label d-block mb-2">Policy cost(exc. Tax)</span>
              <p>{policyCostText ? policyCostText : 'This is the cost Warranty First will charge the dealer.'}</p>

              <div className="mt-2 label font-lg fw-600">{price ? `£${price.toFixed(2)}` : `-`}</div>
            </div>
            }
            

            <div className="bg-white shadow-light" style={{ padding: '30px' }}>
              <span className="label d-block mb-2">Policy price (inc. Tax) *</span>
              <p className="mb-4">
                {policyPriceText ? policyPriceText : 'If you know the price the dealership is selling the policy for\n' +
                  'then enter it here.'}
              </p>

              <Field
                type="text"
                label=""
                component={renderField}
                name={`policy_price`}
                className="form-control"
                validate={[
                  required({
                    message: 'You must enter the policy price.',
                  }),
                  numericality({
                    '>=': 0, 
                    message: 'You must enter a number.',
                  })
                ]}
                append={{
                  direction: 'left',
                  text     : <i className="wf-icon-gbp"/>,
                }}
              />

              <div className="mt-2">
                <div className="d-flex justify-content-between mb-2">
                  <span className="label">Net</span>
                  <span className="label">{money.format(net)}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span className="label">Tax</span>
                  <span className="label">{money.format(vat)}</span>
                </div>

                <div className="d-flex justify-content-between mt-3">
                  <span className="font-xl fw-800 text-gray-800">Total</span>
                  <span className="font-xl fw-800 text-gray-800">
                    {money.format(policy_price)}
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!valid}
              >
                Last step
              </button>
              <button
                type="button"
                className="btn btn-outline btn-form-cancel"
                onClick={() => handleResetForm()}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

StepPolicy.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  valid       : PropTypes.bool.isRequired,
}

const validate = values => {
  let errors = {}

  // only numbers, floats, and no spaces.
  let pattern =  /^-?\d+\.?\d*$/

  if (values.policy_price && !pattern.test(values.policy_price)) {
    errors.policy_price = 'Price can be only a positive number'
    values.policy_price = 0
  }
  return errors
}

const selector = formValueSelector('create-policy-policy')
const dealerSelector = formValueSelector('create-policy-dealer')
const vehicleSelector = formValueSelector('create-policy-vehicle')

export default connect(
  state => ({
    policy_price     : selector(state, 'policy_price') || 0,
    policy_start_date: selector(state, 'policy_start_date'),
    net              : selector(state, 'net'),
    vat              : selector(state, 'vat'),
    coverLevel       : selector(state, 'cover_level'),
    duration         : selector(state, 'duration'),
    claimLimit       : selector(state, 'claim_limit'),
    labourRate       : selector(state, 'labour_rate'),
    excess           : selector(state, 'excess'),
    sundries         : selector(state, 'sundries'),
    dealer           : dealerSelector(state, 'dealer'),
    mileage          : vehicleSelector(state, 'current_mileage'),
    sale_price       : vehicleSelector(state, 'vehicle_sale_price'),
    initialValues    : {
      net: 0,
      vat: 0,
    },
  }),
  null,
)(
  reduxForm({
    form            : 'create-policy-policy',
    destroyOnUnmount: false,
    validate,
  })(StepPolicy),
)
