import React from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { echo, formatDate, prettyNumber, validPhoneNumber } from '../../../../../utils/misc'
import api from '../../../../../utils/api'
import { NotificationManager } from 'react-notifications'
import { history } from '../../../../../utils/history'
import renderField from '../../../../../utils/renderField';
import * as validator from 'validator'
import { usePostcodeLookupWithRedux } from '../../../../../hooks/usePostcodeLookupWithRedux';

const Step3 = ({
  onCancel,
  handleSubmit,
  valid,
  setStep,
  policy,
  fault_date,
  current_mileage,
  prediagnosis,
}) => {

  const { markup } = usePostcodeLookupWithRedux('policyholder-portal/claims/create', true)

  const onSubmit = values => {

    const data = {
      policy_id: values.policy_id.id,
      fault_date: values.fault_date,
      mileage: values.current_mileage,
      prediagnosis: values.prediagnosis,
      garage: {
        name: values.garage_name,
        address_1: values.address_1 || (values.address ? values.address.address_1 : null),
        address_2: values.address_2 || (values.address ? values.address.address_2 : null),
        address_3: values.address_3 || (values.address ? values.address.address_3 : null),
        city: values.city || (values.address ? values.address.city : null),
        county: values.county || (values.address ? values.address.county : null),
        postcode: values.postcode || (values.address ? values.address.postcode : null),
        email: values.garage_email,
        tel: values.garage_tel,
        contact_name: values.garage_contact_name,
        vat_registered: values.garage_vat_registered,
      }
    }

    return api.post('/policyholder-portal/claims', data).then((res) => {
      NotificationManager.success('Woohoo! You created a claim.')
      history.push('/policyholder-portal/claims')
    })
      .catch(err => {
        let errorText = 'An unexpected error occured. Please try again.'
        if (err.response && err.response.status && err.response.status === 422) {
          errorText = err.response.data.errors.shift()[0]
        }
        NotificationManager.error(errorText)
      })
  }

  return (
    <div className="mx-auto pb-5" style={{ maxWidth: 790 }} id="create-claim-step-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-10">
                <div className="mb-4">
                  <h6 className="text-uppercase">Fault summary</h6>
                  <p>"{prediagnosis}"</p>
                </div>
                <hr/>
              </div>
            </div>

            <div className="row">
              <div className="col my-4">
                <h6 className="text-uppercase">Preferred repairing garage</h6>
                <p>If you have a preferred garage that you would like to repair your vehicle, please enter their contact details below.</p>
              </div>
            </div>

            <Field
              label="Garage name"
              type="text"
              name="garage_name"
              component={renderField}
              className="form-control"
            />

            {/* Postcode lookup */}
            <div className="row">
              <div className="col">
                {markup}
              </div>
            </div>

            <Field
              label="Email address"
              type="email"
              name="garage_email"
              component={renderField}
              className="form-control"
            />
            <Field
              label="Phone number"
              type="text"
              name="garage_tel"
              component={renderField}
              className="form-control"
            />
            <Field
              label="Contact name"
              type="text"
              name="garage_contact_name"
              component={renderField}
              className="form-control"
            />
            {/* <Field
              label="This garage is VAT registered"
              name="garage_vat_registered"
              type="checkbox"
              className="mt-4"
              id="vatRegistered"
              component={renderField}
            /> */}

            <div className="row">
              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <button
                    className="btn btn-secondary"
                    type="submit"
                    disabled={!valid}
                  >
                    Create claim
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline btn-form-cancel"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>

          </div>

          {policy && <div className="col-4">
            <div className="row">
              <div className="col">
                <h6 className="mb-4 text-uppercase">Details summary</h6>
              </div>
            </div>
            <div
              className="bg-white shadow-light position-relative"
              style={{ padding: '30px' }}
            >
              <i
                className="wf-icon-edit position-absolute cursor-pointer"
                style={{ top: 30, right: 20 }}
                onClick={() => setStep(1)}
              />
              <span className="label d-block">Policy details</span>
              <p className="mb-0">{policy.ref}</p>
              <p>{policy.vehicle.vrm} - {echo(policy.vehicle.make)} {echo(policy.vehicle.model)}</p>

              <span className="label d-block">Cover level</span>
              {policy.cover_level &&
              <p className="font-md">{echo(policy.cover_level.name)}, {echo(policy.duration)}</p>}

              <span className="label d-block">Purchased from</span>
              <p className="mb-0">{policy.purchased_from}</p>
            </div>

            <div
              className="bg-white shadow-light position-relative mt-3 pb-3"
              style={{ padding: '30px' }}
            >
              <i
                className="wf-icon-edit position-absolute cursor-pointer"
                style={{ top: 30, right: 20 }}
                onClick={() => setStep(2)}
              />
              <span className="label d-block">Fault found on</span>
              <p>{formatDate(fault_date)}</p>

              <span className="label d-block">Current accurate mileage</span>
              <p>{prettyNumber(current_mileage)}</p>
            </div>
          </div>}
        </div>
      </form>

    </div>
  )
}

Step3.propTypes = {
  setStep: PropTypes.func.isRequired,
  prediagnosis: PropTypes.string.isRequired,
}

const selector = formValueSelector('policyholder-portal/claims/create')

const validate = (fields) => {
  let errors = {};

  if (fields.garage_tel && !validPhoneNumber(fields.garage_tel)) {
    errors.garage_tel = 'That phone number doesn\'t look right.'
  }
  if (fields.garage_email && !validator.isEmail(fields.garage_email)) {
    errors.garage_email = 'That email address doesn\'t look right.'
  }

  return errors;
}

export default connect(
  state => ({
    fault_date: selector(state, 'fault_date'),
    prediagnosis: selector(state, 'prediagnosis'),
    current_mileage: selector(state, 'current_mileage')
  }),
  null,
)(
  reduxForm({
    form: 'policyholder-portal/claims/create',
    destroyOnUnmount: false,
    validate
  })(Step3),
)