import React from 'react'
import { Form, reduxForm } from 'redux-form'
import { Field } from 'redux-form'
import renderDatePicker from '../../../ui/renderDatePicker'

const DatePickers = ({ handleSubmit }) => {
  return (
    <Form
      onSubmit={handleSubmit}
      className={'form-inline d-flex align-items-end pull-right mb-4'}
    >
      <div className="row">
        <div className="col">
          <Field
            type="date"
            name="date_from"
            label="From"
            className="form-control form-control-sm from-date-field"
            inputValueFormat="DD-MM-YYYY"
            placeholderText="DD/MM/YYYY"
            dateFormat="dd/MM/yyyy"
            fixedHeight
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            yearDropdownItemNumber={3}
            id="fromDate"
            component={renderDatePicker}
            icon={false}
          />
        </div>
        <div className="col">
          <Field
            type="date"
            name="date_to"
            label="To"
            className="form-control form-control-sm to-date-field"
            inputValueFormat="DD-MM-YYYY"
            placeholderText="DD/MM/YYYY"
            dateFormat="dd/MM/yyyy"
            fixedHeight
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            yearDropdownItemNumber={3}
            id="toDate"
            component={renderDatePicker}
            icon={false}
          />
        </div>
      </div>
    </Form>
  )
}

const validate = (values) => {
  let errors = {}
  if (!values.date_from) {
    errors.date_from = 'From Date is required'
  }
  if (!values.date_to) {
    errors.date_to = 'To Date is required'
  }

  return errors
}

export default reduxForm({
  form: 'report/daterange',
  validate,
})(DatePickers)
