import React, { Component } from 'react'
import RegisterForm from './RegisterForm'
import * as S from '../../Login/Login.style.js'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getFormValues, isValid } from 'redux-form'
import { resetForm } from '../../../../actions/cover-levels.actions'
import { login } from '../../../../actions/authentication.action'
import api from '../../../../utils/api';

class RegisterPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formError: '',
      linkSent: false,
    }
  }

  handleSubmit = values => {
    api.post(`/policyholder-portal/auth/register`, values).then(res => {
      this.setState({
        formError: null,
        linkSent: true,
      });
    })
    .catch(err => {
      let errorText = 'An unexpected error occured. Please try again.'

      if (err.response && err.response.status && err.response.status === 422) {
        errorText = err.response.data.errors.policyholder[0]
      }

      this.setState({
        formError: errorText,
        linkSent: false
      })
    })
  }

  render() {
    return (
      <S.LoginPageWrapper>
        <div className="page-column">
          <RegisterForm onSubmit={this.handleSubmit} submitError={this.state.formError} linkSent={this.state.linkSent}/>

          <div className="meta-links">
            <div>
              Already have an account? <Link to="/policyholder-portal">Login</Link>
            </div>
            <div className="font-xs tos">
              By registering, you agree to the <Link to="#">Terms of Service</Link>
              {' '}and{' '}
              <Link to="#">Privacy Policy</Link>
            </div>
          </div>
        </div>

        <div className="page-column bg-primary">
          <S.HaveQuestions>
            <h1 className="text-white">Have a question?</h1>
            <p className="mb-4">
              You can sign in to your account to access and update your policy information, to make a claim or to notify us of any changes.
            </p>
            <p className="mb-4">
              If you have any questions please get in touch and speak to one of our advisors.
            </p>
            <h3 className="heading-call-us">
              Give us a call
              <Link
                to="tel:+441733830278"
                className="heading-font text-warning"
              >
                01733 830278
              </Link>
            </h3>
          </S.HaveQuestions>
        </div>
      </S.LoginPageWrapper>
    )
  }
}

export default connect(
  state => ({
    valid: isValid('policyholder-portal/register')(state),
    values: getFormValues('policyholder-portal/register')(state),
  }),
  {resetForm, login},
)(RegisterPage)
