import {
  FETCH_DEALER_COVER_LEVELS_REQUEST,
  FETCH_DEALER_COVER_LEVELS_SUCCESS,
  FETCH_DEALER_COVER_LEVELS_FAILURE,
  DELETE_COVER_LEVEL_REQUEST,
  DELETE_COVER_LEVEL_SUCCESS,
  FETCH_DEALER_COVER_LEVEL_REQUEST,
  FETCH_DEALER_COVER_LEVEL_FAILURE,
  FETCH_DEALER_COVER_LEVEL_SUCCESS,
  UPDATE_DEALER_COVER_LEVEL_REQUEST,
  UPDATE_DEALER_COVER_LEVEL_FAILURE,
  UPDATE_DEALER_COVER_LEVEL_SUCCESS,
  SEARCH_COVER_LEVELS,
  FETCH_DIRECT_SALES_COVER_LEVELS_REQUEST,
  FETCH_DIRECT_SALES_COVER_LEVELS_SUCCESS,
  FETCH_DIRECT_SALES_COVER_LEVELS_FAILURE,
  DELETE_DIRECT_SALES_COVER_LEVEL_REQUEST,
  DELETE_DIRECT_SALES_COVER_LEVEL_SUCCESS,
  UPDATE_DIRECT_SALES_COVER_LEVEL_REQUEST,
  UPDATE_DIRECT_SALES_COVER_LEVEL_FAILURE,
  UPDATE_DIRECT_SALES_COVER_LEVEL_SUCCESS,
  FETCH_DIRECT_SALES_COVER_LEVEL_REQUEST, FETCH_DIRECT_SALES_COVER_LEVEL_FAILURE, FETCH_DIRECT_SALES_COVER_LEVEL_SUCCESS,
  FETCH_PUBLIC_COVER_LEVELS_REQUEST, FETCH_PUBLIC_COVER_LEVELS_SUCCESS, FETCH_PUBLIC_COVER_LEVELS_FAILURE,
  REMOVE_PUBLIC_COVER_LEVEL_REQUEST,
  REMOVE_PUBLIC_COVER_LEVEL_SUCCESS,
  UPDATE_PUBLIC_COVER_LEVEL_FAILURE,
  UPDATE_PUBLIC_COVER_LEVEL_REQUEST,
  UPDATE_PUBLIC_COVER_LEVEL_SUCCESS,
  FETCH_PUBLIC_COVER_LEVEL_REQUEST,
  FETCH_PUBLIC_COVER_LEVEL_FAILURE,
  FETCH_PUBLIC_COVER_LEVEL_SUCCESS,
} from '../constants'
import api from '../utils/api'
import { NotificationManager } from 'react-notifications'
import { reset, SubmissionError } from 'redux-form'
import { history } from '../utils/history'

export const fetchDealerCoverLevelsRequest = () => ({
  type: FETCH_DEALER_COVER_LEVELS_REQUEST,
})

export const fetchDealerCoverLevelsSuccess = data => ({
  type: FETCH_DEALER_COVER_LEVELS_SUCCESS,
  payload: data,
})

export const fetchDealerCoverLevelsFailure = err => ({
  type: FETCH_DEALER_COVER_LEVELS_FAILURE,
  payload: err,
})

/**
 * Fetch dealer cover levels
 */
export const fetchDealerCoverLevels = url => dispatch => {
  dispatch(fetchDealerCoverLevelsRequest())

  api
    .get(`/admin/cover-levels?target=Dealer${url ? url : ''}`)
    .then(res => {
      dispatch(fetchDealerCoverLevelsSuccess(res.data))
    })
    .catch(err => {
      return dispatch(fetchDealerCoverLevelsFailure(err.response))
    })
}


export const searchCoverLevelsAction = data => ({
  type: SEARCH_COVER_LEVELS,
  payload: {
    ...data,
  },
})


/**
 * Search cover levels depending on their type
 * @param query
 * @param target
 * @returns {Function}
 */
export const searchCoverLevels = (query, target) => (dispatch, getState) => {
  if (!target)
    return

  if (target === 'Dealer' || target === 'Public' || target === 'Direct Sales')
    dispatch(searchCoverLevelsAction({
      searching: true,
    }))

  api.get(`/admin/cover-levels?target=${target}&query=${query}`)
    .then(res => {
      dispatch(searchCoverLevelsAction({
        searching: false,
        data: res.data,
      }))
    })
    .catch(err => {
      dispatch(searchCoverLevelsAction({
        searching: false,
        data: err.response,
      }))
    })
}

/**
 * Create dealer cover level
 * @param {Object} values
 */
export const addDealerCoverLevel = values => dispatch => {
  values.rank = 1

  return api
    .post(`admin/cover-levels?target=Dealer`, values)
    .then(res => {
      NotificationManager.success('Woohoo! You added a new cover level.')
      history.push('/admin/dealer-cover-levels')
    })
    .catch(err => {
      const errors = err.response.data.errors
      throw new SubmissionError({
        ...errors,
        _error: err.response.data.message,
      })
    })
}

/**
 * Reset cover level form
 */
export const resetCreateCoverLevelForm = () => dispatch =>
  dispatch(reset('createCoverLevel'))

export const deleteDealerCoverLevel = (id, redirectTo = false) => dispatch => {
  dispatch({type: DELETE_COVER_LEVEL_REQUEST})
  return api
    .delete(`admin/cover-levels/${id}`)
    .then(res => {
      dispatch({
        type: DELETE_COVER_LEVEL_SUCCESS,
        payload: {
          id,
        },
      })
      NotificationManager.success('Woohoo! You deleted a cover level.')
      if (redirectTo) history.push(redirectTo)
    })
    .catch(err => {
      NotificationManager.error('Oops! Could not delete the cover level.')
    })
}

export const resetForm = formName => dispatch => dispatch(reset(formName))

export const fetchDealerCoverLevelRequest = () => ({
  type: FETCH_DEALER_COVER_LEVEL_REQUEST,
})

export const fetchDealerCoverLevelFailure = err => ({
  type: FETCH_DEALER_COVER_LEVEL_FAILURE,
  payload: err,
})

export const fetchDealerCoverLevelSuccess = coverLevel => ({
  type: FETCH_DEALER_COVER_LEVEL_SUCCESS,
  payload: coverLevel,
})

/**
 * Fetch single dealer level cover based on ID
 */
export const fetchDealerCoverLevel = coverId => dispatch => {
  dispatch(fetchDealerCoverLevelRequest())

  api
    .get(`/admin/cover-levels/${coverId}`)
    .then(res => {
      return dispatch(fetchDealerCoverLevelSuccess(res.data))
    })
    .catch(err => {
      history.push('/404')
      return dispatch(
        fetchDealerCoverLevelFailure({
          message:
            'Oops! Something went wrong. Cover level could not be loaded.',
          ...err.response,
        }),
      )
    })
}

export const updateDealerCoverLevelRequest = () => ({
  type: UPDATE_DEALER_COVER_LEVEL_REQUEST,
})

export const updateDealerCoverLevelFailure = error => ({
  type: UPDATE_DEALER_COVER_LEVEL_FAILURE,
  payload: error,
})

export const updateDealerCoverLevelSuccess = coverLevel => ({
  type: UPDATE_DEALER_COVER_LEVEL_SUCCESS,
  payload: coverLevel,
})

export const updateDealerCoverLevel = ({id, ...rest}) => dispatch => {
  dispatch(updateDealerCoverLevelRequest())

  return api
    .patch(`/admin/cover-levels/${id}`, rest)
    .then(res => {
      NotificationManager.success('Woohoo! Your changes were saved.')
      return dispatch(updateDealerCoverLevelSuccess(res.data))
    })
    .catch(err => {
      dispatch(updateDealerCoverLevelFailure(err.response.data))
      const errors = err.response.data.errors
      throw new SubmissionError({
        ...errors,
        _error: err.response.data.message,
      })
    })
}


export const fetchDirectSalesCoverLevelsRequest = () => ({
  type: FETCH_DIRECT_SALES_COVER_LEVELS_REQUEST,
})

export const fetchDirectSalesCoverLevelsSuccess = data => ({
  type: FETCH_DIRECT_SALES_COVER_LEVELS_SUCCESS,
  payload: data,
})

export const fetchDirectSalesCoverLevelsFailure = err => ({
  type: FETCH_DIRECT_SALES_COVER_LEVELS_FAILURE,
  payload: err,
})

/**
 * Fetch direct sales cover levels
 */
export const fetchDirectSalesCoverLevels = url => dispatch => {
  dispatch(fetchDirectSalesCoverLevelsRequest())

  api
    .get(`/admin/cover-levels?target=Direct Sales${url ? url : ''}`)
    .then(res => {
      dispatch(fetchDirectSalesCoverLevelsSuccess(res.data))
    })
    .catch(err => {
      return dispatch(fetchDirectSalesCoverLevelsFailure(err.response))
    })
}

export const deleteDirectSalesCoverLevel = (id, redirectTo = false) => dispatch => {
  dispatch({type: DELETE_DIRECT_SALES_COVER_LEVEL_REQUEST})
  return api
    .delete(`admin/cover-levels/${id}`)
    .then(res => {
      dispatch({
        type: DELETE_DIRECT_SALES_COVER_LEVEL_SUCCESS,
        payload: {
          id,
        },
      })
      NotificationManager.success('Woohoo! You deleted a cover level.')
      if (redirectTo) history.push(redirectTo)
    })
    .catch(() => {
      NotificationManager.error('Oops! Could not delete the cover level.')
    })
}

export const fetchDirectSalesCoverLevelRequest = () => ({
  type: FETCH_DIRECT_SALES_COVER_LEVEL_REQUEST,
})

export const fetchDirectSalesCoverLevelFailure = err => ({
  type: FETCH_DIRECT_SALES_COVER_LEVEL_FAILURE,
  payload: err,
})

export const fetchDirectSalesCoverLevelSuccess = coverLevel => ({
  type: FETCH_DIRECT_SALES_COVER_LEVEL_SUCCESS,
  payload: coverLevel,
})

/**
 * Fetch single direct sales cover based on ID
 */
export const fetchDirectSalesCoverLevel = coverId => dispatch => {
  dispatch(fetchDirectSalesCoverLevelRequest())

  api
    .get(`/admin/cover-levels/${coverId}`)
    .then(res => {
      return dispatch(fetchDirectSalesCoverLevelSuccess(res.data))
    })
    .catch(err => {
      history.push('/404')
      dispatch(
        fetchDirectSalesCoverLevelFailure({
          message:
            'Oops! Something went wrong. Cover level could not be loaded.',
          ...err.response,
        }),
      )
      if (err && err.response.status > 499)
        NotificationManager.error('Oops! Something went wrong.')
    })
}

export const fetchPublicCoverLevelsRequest = () => ({
  type: FETCH_PUBLIC_COVER_LEVELS_REQUEST,
})

export const fetchPublicCoverLevelsSuccess = coverLevels => ({
  type: FETCH_PUBLIC_COVER_LEVELS_SUCCESS,
  payload: {coverLevels},
})

export const fetchPublicCoverLevelsFailure = error => ({
  type: FETCH_PUBLIC_COVER_LEVELS_FAILURE,
  payload: {error},
})

/**
 * Fetch dealer cover levels
 */
export const fetchPublicCoverLevels = url => dispatch => {
  dispatch(fetchPublicCoverLevelsRequest())

  api
    .get(`/admin/cover-levels?target=Public${url ? url : ''}`)
    .then(res => {
      dispatch(fetchPublicCoverLevelsSuccess(res.data))
    })
    .catch(err => {
      return dispatch(fetchPublicCoverLevelsFailure(err.response))
    })
}

export const deletePublicCoverLevel = (id, redirectTo = false) => dispatch => {
  dispatch({type: REMOVE_PUBLIC_COVER_LEVEL_REQUEST})
  return api
    .delete(`admin/cover-levels/${id}`)
    .then(() => {
      dispatch({
        type: REMOVE_PUBLIC_COVER_LEVEL_SUCCESS,
        payload: {
          id,
        },
      })
      NotificationManager.success('Woohoo! You deleted a cover level.')
      if (redirectTo) history.push(redirectTo)
    })
    .catch(() => {
      NotificationManager.error('Oops! Could not delete the cover level.')
    })
}


/**
 * Add public cover level
 * @param {Object} values
 */
export const addPublicCoverLevel = values => dispatch => {
  values.rank = 1

  return api
    .post(`admin/cover-levels?target=Public`, values)
    .then(() => {
      NotificationManager.success('Woohoo! You added a new cover level.')
      history.push('/admin/public-cover-levels')
    })
    .catch((err) => {
      if (err && err.response.status > 499)
        NotificationManager.error('Oops! Something went wrong. Please try again after refreshing the page.')
    })
}

/**
 * Update direct sales cover level
 * @returns {{type: string}}
 */
export const updateDirectSalesCoverLevelRequest = () => ({
  type: UPDATE_DIRECT_SALES_COVER_LEVEL_REQUEST,
})

export const updateDirectSalesCoverLevelFailure = error => ({
  type: UPDATE_DIRECT_SALES_COVER_LEVEL_FAILURE,
  payload: error,
})

export const updateDirectSalesCoverLevelSuccess = coverLevel => ({
  type: UPDATE_DIRECT_SALES_COVER_LEVEL_SUCCESS,
  payload: coverLevel,
})

export const updateDirectSalesCoverLevel = ({id, ...rest}) => dispatch => {
  dispatch(updateDirectSalesCoverLevelRequest())

  return api
    .patch(`/admin/cover-levels/${id}`, rest)
    .then(res => {
      NotificationManager.success('Woohoo! Your changes were saved.')
      return dispatch(updateDirectSalesCoverLevelSuccess(res.data))
    })
    .catch(err => {
      dispatch(updateDirectSalesCoverLevelFailure(err.response.data))
    })
}

/* Create dealer cover level
* @param {Object} values
*/
export const addDirectSalesCoverLevel = values => dispatch => {
  values.rank = 1

  return api
    .post(`admin/cover-levels?target=Direct Sales`, values)
    .then(() => {
      NotificationManager.success('Woohoo! You added a new cover level.')
      history.push('/admin/direct-sales-cover-levels')
    })
    .catch(err => {
      if (err.response.status >= 500)
        NotificationManager.error('Oops! Something went wrong. Please try again.')

      const errors = err.response.data.errors
      throw new SubmissionError({
        ...errors,
        _error: err.response.data.message,
      })
    })
}


export const fetchPublicCoverLevelRequest = () => ({
  type: FETCH_PUBLIC_COVER_LEVEL_REQUEST,
})

export const fetchPublicCoverLevelFailure = err => ({
  type: FETCH_PUBLIC_COVER_LEVEL_FAILURE,
  payload: err,
})

export const fetchPublicCoverLevelSuccess = coverLevel => ({
  type: FETCH_PUBLIC_COVER_LEVEL_SUCCESS,
  payload: coverLevel,
})

/**
 * Fetch single dealer level cover based on ID
 */
export const fetchPublicCoverLevel = coverId => dispatch => {
  dispatch(fetchPublicCoverLevelRequest())

  api
    .get(`/admin/cover-levels/${coverId}`)
    .then(res => {
      return dispatch(fetchPublicCoverLevelSuccess(res.data))
    })
    .catch(err => {
      history.push('/404')
      dispatch(
        fetchPublicCoverLevelFailure({
          message:
            'Oops! Something went wrong. Cover level could not be loaded.',
          ...err.response,
        }),
      )
      if (err.response.status >= 500)
        NotificationManager.error('Oops! Something went wrong. Please try again.')

      const errors = err.response.data.errors
      throw new SubmissionError({
        ...errors,
        _error: err.response.data.message,
      })
    })
}

export const updatePublicCoverLevelRequest = () => ({
  type: UPDATE_PUBLIC_COVER_LEVEL_REQUEST,
})

export const updatePublicCoverLevelFailure = error => ({
  type: UPDATE_PUBLIC_COVER_LEVEL_FAILURE,
  payload: error,
})

export const updatePublicCoverLevelSuccess = coverLevel => ({
  type: UPDATE_PUBLIC_COVER_LEVEL_SUCCESS,
  payload: coverLevel,
})

export const updatePublicCoverLevel = ({id, ...rest}) => dispatch => {
  dispatch(updatePublicCoverLevelRequest())

  return api
    .patch(`/admin/cover-levels/${id}`, rest)
    .then(res => {
      NotificationManager.success('Woohoo! Your changes were saved.')
      return dispatch(updatePublicCoverLevelSuccess(res.data))
    })
    .catch(err => {
      dispatch(updatePublicCoverLevelFailure(err.response.data))
      const errors = err.response.data.errors
      throw new SubmissionError({
        ...errors,
        _error: err.response.data.message,
      })
    })
}
