import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const MenuLink = ({ link }) => {
  return !link.children &&
    <li className="nav-item">
      { link.isExternal ?
        <a href={link.url} target="_new" className="nav-link">
          {link.icon && <i className={link.icon}/>}
          {link.image && <img className={link.image} alt=""/>}
          <span>{link.name}</span>
        </a>
        :
        <NavLink to={link.url} className="nav-link">
          {link.icon && <i className={link.icon}/>}
          {link.image && <img className={link.image} alt=""/>}
          <span>{link.name}</span>
        </NavLink>
      }
    </li>
}

MenuLink.propTypes = {
  link: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
    image: PropTypes.string,
  })
}

export default MenuLink