import React, { Component } from 'react'
import {
  change,
  Field,
  Form,
  formValueSelector,
  isValid,
  reduxForm,
  untouch,
} from 'redux-form'
import renderField from '../../../../../../utils/renderField'
import CustomSelect from '../../../../../ui/CustomSelect'
import Modal from '../../../../../modals/Modal'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { history } from '../../../../../../utils/history'
import {
  isNumber,
  isPositiveFloat,
} from '../../../../../../utils/misc'
import { numericality } from 'redux-form-validators'
import { tableHeadings } from './table-headings'
import CoverLevelTable from "../../CoverLevelTable"
import {set} from "lodash";

class CreateForm extends Component {
  state = {
    specialDurationModal: false,
    durationModal: false,
    coverLevelName: null,
    specialDurations: [],
  }

  toggleModal = (modal, coverLevelName) => {
    const { dispatch, change, untouch } = this.props

    if (modal === 'special') {
      this.setState((pre) => ({
        ...pre,
        specialDurationModal: !pre.specialDurationModal,
      }))

      // reset form fields
      dispatch(change('pb_duration', ''))
      dispatch(change('pb_drive', ''))
      dispatch(change('target_cover_level', ''))

      dispatch(untouch('price-band/create', 'pb_duration'))
      dispatch(untouch('price-band/create', 'pb_drive'))
      dispatch(untouch('price-band/create', 'target_cover_level'))
    } else {
      this.setState((pre) => ({
        ...pre,
        durationModal: !pre.durationModal,
        coverLevelName,
      }))
      dispatch(change('pb_duration', ''))
      dispatch(untouch('price-band/create', 'pb_duration'))
    }
  }

  clearSpecialDuration = () => {
    this.setState(prevState => {
      return {...prevState, specialDuration: null}
    })
  }

  handleSpecialDurationSubmit = () => {
    const { pb_duration, pb_drive, target_cover_level } = this.props
    if (
      !isNumber(pb_duration) ||
      !isNumber(pb_drive) ||
      typeof target_cover_level === 'undefined'
    ) {
      return false
    }
    let title = `${pb_duration} months (drive ${pb_drive})`
    let specialDuration = {};
    specialDuration[target_cover_level.name] =   {
      title    : title,
      value    : parseInt(pb_duration),
      hiddenFor: [],
      special  : true,
    };
    this.setState(prevState => {
      return {...prevState, specialDuration}
    })
    this.toggleModal('special')
    const { dispatch, change, untouch } = this.props
    // reset form fields
    dispatch(change('pb_duration', ''))
    dispatch(change('pb_drive', ''))
    dispatch(change('target_cover_level', ''))
    dispatch(untouch('price-band/create', 'pb_duration'))
    dispatch(untouch('price-band/create', 'pb_drive'))
    dispatch(untouch('price-band/create', 'target_cover_level'))
  }

  render() {
    const {
      handleSubmit,
      onSubmit,
      coverLevelOptions,
      coverLevels,
      isValid,
    } = this.props

    return (
      <>

        <Modal
          hide={() => this.toggleModal('special')}
          isShowing={this.state.specialDurationModal}
          style={{ width: '435px' }}
          title="Special pricing rule"
          subtitle="e.g. Pay for 24 months, drive for 36"
          onCancel={() => this.toggleModal('special')}
          onSubmit={this.handleSpecialDurationSubmit}
          textAction="Save price rule"
          textCancel="Cancel"
        >
          <div className="mt-4 d-flex">
            <Field
              name="pb_duration"
              type="text"
              label="Pay for (duration)"
              className="w-50 form-control"
              component={renderField}
              validate={[
                numericality({
                  msg: 'You must enter a number.',
                  allowBlank: false,
                }),
              ]}
              append={{
                direction: 'right',
                text: 'months',
              }}
            />
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <Field
              name="pb_drive"
              type="text"
              label="Drive for (duration)"
              className="w-50 form-control"
              component={renderField}
              validate={[
                numericality({
                  msg: 'You must enter a number.',
                  allowBlank: false,
                }),
              ]}
              append={{
                direction: 'right',
                text: 'months',
              }}
            />
          </div>

          <div>
            {coverLevels && (
              <Field
                name="target_cover_level"
                component={CustomSelect}
                label="Choose cover level"
                options={coverLevels}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
              />
            )}
          </div>
        </Modal>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-7">
                <div className="row">
                  <div className="col">
                    <Field
                      type="text"
                      label="Price band name"
                      component={renderField}
                      name={`name`}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    {coverLevelOptions && (
                      <Field
                        name="coverLevels"
                        isMulti
                        component={CustomSelect}
                        label="Included cover level(s)"
                        options={coverLevelOptions}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {coverLevels && coverLevels.length > 0 && (
              <>
                <hr />
                <div className="row">
                  <ul
                    className="list-info mb-4 ml-3"
                    style={{ maxWidth: '570px' }}
                  >
                    <li>Prices exclude VAT.</li>
                    <li>
                      A price of £0 or a blank cell will prevent the warranty /
                      duration / claim limit from being chosen when setting up a
                      policy.
                    </li>
                  </ul>
                </div>
                {/*<div className="row">*/}
                {/*  <div className="col">*/}
                {/*    <button*/}
                {/*      type="button"*/}
                {/*      className="btn btn-warning btn-sm font-weight-bold mr-4"*/}
                {/*      onClick={() => this.toggleModal('special')}*/}
                {/*    >*/}
                {/*      Add special duration*/}
                {/*    </button>*/}
                {/*    <span>e.g. Pay for 24 months, drive for 36.</span>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </>
            )}
          </div>

          {coverLevels &&
            coverLevels.map((coverLevel, i) => (
              <div className="row" key={i}>
                <CoverLevelTable
                  clearSpecialDuration={this.clearSpecialDuration}
                  specialDuration={this.state.specialDuration || null}
                  key={i}
                  reduxForm={'price-band/create'}
                  coverLevel={coverLevel} cols={tableHeadings} />
              </div>
            ))}

          <div className="px-4">
            {coverLevels && coverLevels.length > 0 && (
              <div style={{ marginBottom: '4rem' }}>
                <button
                  type="submit"
                  name="submit"
                  className={`btn btn-secondary`}
                  disabled={!isValid}
                >
                  Save price band
                </button>
                <button
                  type="submit"
                  className="btn btn-transparent text-secondary"
                  onClick={() => history.push(this.props.onCancelRedirectTo)}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </Form>
      </>
    )
  }
}

CreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancelRedirectTo: PropTypes.string.isRequired,
}

const selector = formValueSelector('price-band/create')

const validate = (values) => {
  let errors = {}

  if (!values.name) errors.name = 'Price band name is required.'

  if (values.prices) {
    errors.prices = {}

    for(let [coverLevelId, claimLimits] of Object.entries(values.prices)) {
      for(let [claimLimit, durations] of Object.entries(claimLimits)) {
        for(let [duration, prices] of Object.entries(durations)) {
          if(prices.hasOwnProperty('price')) {
            if (!isPositiveFloat(prices.price)) {
              set(errors.prices, `${coverLevelId}.${claimLimit}.${duration}.price`, 'Must be a positive price')
            }
          } else {
            let key = Object.getOwnPropertyNames(prices)[0];
            if (!isPositiveFloat(prices[key])) {
              set(errors.prices, `${coverLevelId}.${claimLimit}.${duration}.${key}`, 'Must be a positive price')
            }
          }
        }
      }
    }
  }
  return errors
}

export default connect(
  (state) => ({
    coverLevels: selector(state, 'coverLevels'),
    pb_duration: selector(state, 'pb_duration'),
    pb_drive: selector(state, 'pb_drive'),
    target_cover_level: selector(state, 'target_cover_level'),
    isValid: isValid('price-band/create')(state),
  }),
  { change, untouch }
)(
  reduxForm({
    form: 'price-band/create',
    validate,
  })(CreateForm)
)
