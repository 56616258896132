import {
  FETCH_USER_GROUPS_REQUEST,
  FETCH_USER_GROUPS_SUCCESS,
  FETCH_USER_GROUPS_FAILURE,
  DELETE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP_FAILURE,
  DELETE_USER_GROUP_REQUEST,
  SEARCH_USER_GROUPS,
  FETCH_USER_GROUP_REQUEST,
  FETCH_USER_GROUP_SUCCESS,
  FETCH_USER_GROUP_FAILURE,
} from '../constants'
import api from '../utils/api'
import { NotificationManager } from 'react-notifications'
import { history } from '../utils/history'

export const fetchUserGroupsRequest = () => ({
  type: FETCH_USER_GROUPS_REQUEST,
})

export const fetchUserGroupsSuccess = data => ({
  type: FETCH_USER_GROUPS_SUCCESS,
  payload: data,
})

export const fetchUserGroupsFailure = err => ({
  type: FETCH_USER_GROUPS_FAILURE,
  payload: err,
})

/**
 * Fetch user groups
 */
export const fetchUserGroups = url => dispatch => {
  dispatch(fetchUserGroupsRequest())

  api
    .get(`/admin/users/groups${url ? url : ''}`)
    .then(res => {
      return dispatch(fetchUserGroupsSuccess(res.data))
    })
    .catch(err => {
      dispatch(fetchUserGroupsFailure(err.response))
    })
}

/**
 * Delete user group
 *
 * @param {Number} id
 * @param {string} redirectTo
 */
export const deleteUserGroup = (id, redirectTo = false) => dispatch => {
  dispatch({
    type: DELETE_USER_GROUP_REQUEST,
  })

  return api
    .delete(`admin/users/groups/${id}`)
    .then(res => {
      dispatch({
        type: DELETE_USER_GROUP_SUCCESS,
        payload: {
          id,
        },
      })
      NotificationManager.success('Woohoo! You deleted a user group.')
      if (redirectTo) history.push(redirectTo)
    })
    .catch(err => {
      if (err) {
        console.log(err.response)
        NotificationManager.error(
          'Oops! Something went wrong. Please try again after refreshing',
        )

        if (err) {
          dispatch({
            type: DELETE_USER_GROUP_FAILURE,
            payload: { error: err.response.data },
          })
        }
      }
    })
}

/**
 * Search through user groups
 * @param query
 * @returns {Function}
 */
export const searchUserGroups = query => dispatch => {
  dispatch({
    type: SEARCH_USER_GROUPS,
    payload: {
      searching: true,
    },
  })

  api
    .get(`/admin/users/groups?query=${query}`)
    .then(res => {
      dispatch({
        type: SEARCH_USER_GROUPS,
        payload: {
          searching: false,
          data: res.data,
        },
      })
    })
    .catch(err => {
      dispatch({
        type: SEARCH_USER_GROUPS,
        payload: {
          searching: false,
          error: err.response,
        },
      })
    })
}

/**
 * Fetch a single user group
 *
 * @param {Number} id
 */
export const fetchUserGroup = id => dispatch => {
  dispatch({
    type: FETCH_USER_GROUP_REQUEST,
  })

  return api
    .get(`admin/users/groups/${id}`)
    .then(res => {
      dispatch({
        type: FETCH_USER_GROUP_SUCCESS,
        payload: {
          group: res.data,
        },
      })

      return res
    })
    .catch(err => {
      if (err && err.response && err.response.status === 404)
        return history.push('/404')

      if (err && err.response && err.response.status !== 404) {
        return dispatch({
          type: FETCH_USER_GROUP_FAILURE,
          payload: {
            error: err.response.message,
          },
        })
      }
      NotificationManager.error(
        'Oops! Something went wrong. Please try again after refreshing.',
      )
    })
}
