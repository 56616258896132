import { createBrowserHistory } from 'history'
import CustomPrompt from '../components/modals/CustomPrompt'

export const history = createBrowserHistory({
  getUserConfirmation: (message, cb) => {
    let data = JSON.stringify({
      title: 'Are you sure?',
      textAction: 'Leave page',
      message: 'You have unsaved changes.',
      textCancel: 'Stay on this page'
    })
    return CustomPrompt(data, cb)
  }
})
