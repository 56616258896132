import React from 'react'
import { Form, Field, reduxForm } from 'redux-form'
import renderField from '../../../utils/renderField'
import validate from './formValidation'
import propTypes from 'prop-types'
import CustomAsyncSelect from '../../ui/CustomAsyncSelect'
import renderDatePicker from '../../ui/renderDatePicker'

let CreateTerritoryForm = props => {
  const {
    handleSubmit,
    onSubmit,
    valid,
    cancel,
    fetch,
    accountManagers,
  } = props

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <h2 className="mb-4">Add a territory</h2>

      <div className="row">
        <div className="col">
          <Field
            type="text"
            label="Territory name"
            component={renderField}
            name={`name`}
            className="form-control"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Field
            component={CustomAsyncSelect}
            label="Current Account Manager"
            type="text"
            name="current_account_manager"
            defaultOptions={accountManagers}
            isClearable={true}
            loadOptions={fetch}
            getOptionLabel={option =>
              `${option.first_name} ${option.last_name}`
            }
            getOptionValue={option => option.id}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Field
            name="start_date"
            style={{ width: '100%' }}
            label="Account Manager start date"
            className="form-control"
            inputValueFormat="DD-MM-YYYY"
            placeholderText="DD/MM/YYYY"
            dateFormat="dd/MM/yyyy"
            fixedHeight
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            yearDropdownItemNumber={3}
            id="accountManagerStartDate"
            component={renderDatePicker}
          />
        </div>

        <div className="col">
          <Field
            name="end_date"
            style={{ width: '100%' }}
            label="Account Manager end date (optional)"
            className="form-control"
            inputValueFormat="DD-MM-YYYY"
            placeholderText="DD/MM/YYYY"
            dateFormat="dd/MM/yyyy"
            fixedHeight
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            yearDropdownItemNumber={3}
            id="accountManagerEndDate"
            component={renderDatePicker}
          />
        </div>
      </div>

      <hr/>

      <div className="row">
        <div className="col">
          <h6 className="text-uppercase form-heading-xs">
            Add territory postcodes
          </h6>
          <p className="mb-0">
            Separate multiple postcodes with a comma e.g. MK1,MK3.
          </p>
          <p>
            To add a sequential range, separate postcodes with a dash e.g.
            MK10-MK30.
          </p>

          <Field
            component={renderField}
            type="textarea"
            placeholder="e.g. MK1,MK3,MK10-MK30"
            className="custom-textarea form-control"
            name="postcodes"
          />
        </div>
      </div>

      <div className="form-group mt-3">
        <button disabled={!valid} className="btn btn-secondary">
          Add territory
        </button>
        <button
          onClick={cancel}
          className="btn btn-outline"
          style={{color: '#70b725'}}
        >
          Cancel
        </button>
      </div>
    </Form>
  )
}

CreateTerritoryForm.propTypes = {
  fetch: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
  cancel: propTypes.func.isRequired,
  accountManagers: propTypes.arrayOf(propTypes.object).isRequired,
}

CreateTerritoryForm = reduxForm({
  form: 'territories/create',
  fields: ['name', 'start_date', 'end_date', 'postcodes'],
  validate,
})(CreateTerritoryForm)

export default CreateTerritoryForm
