import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field, reduxForm } from 'redux-form'
import renderField from '../../../utils/renderField'
import isEmail from 'validator/lib/isEmail'

const ForgottenForm = props => {
  const {handleSubmit, onSubmit, submitError, linkSent} = props

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="card w-100 p-4 shadow-light border-0">
        <div className="card-body">
          <h1 className="h2">Forgotten Password?</h1>
          <h6 className="text-uppercase mb-4 font-weight-bold">We'll email you with a link to reset it</h6>

          <Field
            component={renderField}
            type="text"
            className="form-control"
            name="email"
            label="Email address"
            placeholder="Your email address"
            disabled={linkSent}
          />
          {submitError && <div className="alert alert-danger">{submitError}</div>}
          {!linkSent && <button type="submit" disabled={!props.valid} className="btn mt-4 px-4 btn-secondary">Reset password</button>}
          {linkSent && <p className="text-green">An email has been sent to the specified email address, with a password reset link it it.</p>}
        </div>
      </div>
    </Form>
  )
}

const validate = values => {
  let errors = {}

  if (!values.email)
    errors.email = 'Please provide your email address.'
  if (values.email && !isEmail(values.email))
    errors.email = 'That email address doesn\'t look quite right.'

  return errors
}

ForgottenForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
}

ForgottenForm.defaultProps = {
  linkSent: false,
}

export default reduxForm({
  form: 'auth/forgot',
  validate,
})(ForgottenForm)