import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import EditDealerHeader from './EditDealerHeader'
import { useParams } from 'react-router-dom'
import api from '../../../../../utils/api'
import { pick, get as _get } from 'lodash'
import { NotificationManager } from 'react-notifications'
import Invoicing from './Invoicing'
import SpecialVehicles from './SpecialVehicles'
import Pricing from './Pricing'
import PersonalContactsTable from './PersonalContactsTable'
import DealerDetails from './DealerDetails'
import AdditionalInformation from '../../../../ui/AdditionalInformation'
import DealerContactDetails from '../../../../ui/DealerContactDetails'
import { SubmissionError } from 'redux-form'
import Targets from './Targets';

const EditDealer = () => {
  const [dealer, setDealer] = useState(null)
  const [contacts, setContacts] = useState([])

  const params = useParams()

  const fetchDealer = () => {
    api
      .get(`/external-sales/dealers/${params.id}`)
      .then((res) => {
        setDealer(res.data)
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing'
          )
        }
      })
  }

  useEffect(() => {
    fetchDealer()
  }, [])

  /**
   * Fetch contacts
   */
  useEffect(() => {
    api
      .get(`/external-sales/dealers/${params.id}/contacts?limit=100`)
      .then((res) => {
        setContacts(res.data.data)
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing'
          )
        }
      })
  }, [])

  const handleAdditionalInfoSubmit = (values) => {
    const data = pick(values, [
      'existing_warranty_provider',
      'policies_per_month',
      'potential_revenue',
      'cars_in_stock',
      'min_stock_price',
      'max_stock_price',
      'comments',
    ])
    return api
      .patch(`/external-sales/dealers/${dealer.id}/additional-info`, data)
      .then((res) => {
        setDealer(res.data)
        NotificationManager.success(
          'Woohoo! Additional information updated successfully!'
        )
      })
      .catch(_promiseCatch)
  }

  const handlePricingSubmit = (values) => {
    const data = {}
    data.allow_dealer_price = values.allow_dealer_price
    data.base_price_band_id = _get(values, 'base_price_band_id.id')
    data.disable_special_vehicle_pricing = values.disable_special_vehicle_pricing
    data.allow_staff_view_pricing = values.allow_staff_view_pricing
    data.labour_rate = _get(values, 'labour_rate.price')

    return api
      .patch(`/external-sales/dealers/${params.id}/pricing`, data)
      .then(() => {
        fetchDealer()
        NotificationManager.success(
          'Woohoo! You updated the pricing successfully.'
        )
      })
      .catch(_promiseCatch)
  }

  /**
   * Handle any errors on post request
   * @param err
   */
  const _promiseCatch = (err) => {
    if (err && err.response.status === 422) {
      throw new SubmissionError({
        ...err.response.data.errors,
        _error: err.response.data.message,
      })
    } else {
      NotificationManager.error(
        'Oops! Something went wrong. Please try again after refreshing'
      )
    }
  }

  return (
    dealer && (
      <>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/external-sales/dealers" className="open active">
              Dealers
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">Edit dealer</span>
          </li>
        </ol>

        <div className="mx-auto px-3" style={{ maxWidth: 980 }}>
          <EditDealerHeader dealer={dealer} />

          <div
            className="edit-dealer-container mx-auto position-relative d-flex"
            style={{ maxWidth: 980 }}
          >
            <div className="edit-dealer-column border-right flex-grow-1">
              {dealer && (
                <DealerDetails
                  isProspect={false}
                  dealer={dealer}
                  setDealer={setDealer}
                />
              )}

              <div className="row">
                <div className="edit-dealer-column col pb-4 border-bottom">
                  <DealerContactDetails dealer={dealer} setDealer={setDealer} />
                </div>
              </div>
            </div>

            <Invoicing dealer={dealer} setDealer={setDealer} />
          </div>

          <div className="row">
            <div className="col mb-3" style={{ marginTop: 40 }}>
              <h6 className="text-uppercase">Personal contacts</h6>
            </div>
          </div>

          {contacts && (
            <PersonalContactsTable
              onSubmit={() => {}}
              contacts={contacts}
              setContacts={setContacts}
            />
          )}

          <AdditionalInformation
            dealer={dealer}
            formName={'dealer/additional-info'}
            onSubmit={handleAdditionalInfoSubmit}
          />

          <Pricing
            handleSubmit={handlePricingSubmit}
            allowDealerPrice={dealer.allow_dealer_price}
            basePriceBandId={dealer.base_price_band_id}
            dealer={dealer}
          />

          <hr style={{ marginTop: 40, marginBottom: 30 }} />

          <SpecialVehicles dealer={dealer} />

          <hr style={{ marginTop: 40, marginBottom: 40 }} />

          <Targets dealer={dealer} />

        </div>
      </>
    )
  )
}

export default EditDealer
