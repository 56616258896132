import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, Form, reduxForm } from 'redux-form'
import renderField from '../../../../../utils/renderField'
import renderDatePicker from '../../../../ui/renderDatePicker'
import { validPostcode } from '../../../../../utils/misc'

const EditGarageForm = (props) => {
  const {
    initialValues,
    handleSubmit,
    onSubmit,
    valid,
    dirty,
    toggleForm,
  } = props

  const [currentVatRegistered, setCurrentVatRegistered] = useState(initialValues.vat_registered)

  const handleVatRegisteredChange = (event) => {
    const vatRegistered = event.target.checked
    setCurrentVatRegistered(vatRegistered)
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Garage name"
              component={renderField}
              name={`name`}
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Address"
              component={renderField}
              name={`address_1`}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label=""
              component={renderField}
              name={`address_2`}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label=""
              component={renderField}
              name={`address_3`}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="City"
              component={renderField}
              name={`city`}
              className="form-control"
            />
          </div>
          <div className="col">
            <Field
              type="text"
              label="County"
              component={renderField}
              name={`county`}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Postcode"
              component={renderField}
              name={`postcode`}
              className="form-control"
            />
          </div>
          <div className="col">
            <Field
              name="signup_date"
              label="Sign-up date"
              style={{ width: '100%' }}
              className="form-control"
              inputValueFormat="yyyy-MM-dd"
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              fixedHeight
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={3}
              id="signupDate"
              component={renderDatePicker}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Field
              type="checkbox"
              label="This garage is an authorised repairer"
              name="authorised_repairer"
              component={renderField}
              id={`authorised_repairer`}
            />
            <Field
              type="checkbox"
              label="This garage is VAT registered"
              name="vat_registered"
              component={renderField}
              id={`vatRegistered`}
              onChange={handleVatRegisteredChange}
            />
            {currentVatRegistered && (
              <Field
                type="text"
                label="VAT number"
                component={renderField}
                name={`vat_number`}
                className="form-control"
              />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col mt-3">
            <div className="form-group mt-3">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!valid || !dirty}
                onClick={() => toggleForm(false)}
              >
                Save changes
              </button>
              <button
                type="button"
                className="btn btn-outline btn-form-cancel"
                onClick={() => toggleForm(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}

const validate = (fields) => {
  let errors = {}

  if (!fields.name) errors.name = 'Please provide a garage name'
  if (!fields.address_1)
    errors.address_1 = 'You must enter the first line if the address'
  if (fields.address_1 && fields.address_1.length > 100)
    errors.address_1 = 'The first address line must be less than 100 characters'
  if (fields.address_2 && fields.address_2.length > 100)
    errors.address_2 =
      'The second address line must be less than 100 characters'
  if (fields.address_3 && fields.address_3.length > 100)
    errors.address_3 = 'The third address line must be less than 100 characters'

  if (!fields.city) errors.city = 'The city is required'
  if (!fields.county) errors.county = 'The county is required'

  if (!fields.postcode) {
    errors.postcode = 'The postcode is required'
  }
  if (fields.postcode && !validPostcode(fields.postcode)) {
    errors.postcode = "That postcode doesn't look quite right."
  }

  return errors
}

EditGarageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'garage/edit',
  validate,
})(EditGarageForm)
