import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { uniqueId } from 'lodash'
import api from '../../../../../utils/api'
import { hasPermission } from '../../../../../utils/auth'
import { echoMoney, sortCaret } from '../../../../../utils/misc'
import PageHeader from '../../../../ui/PageHeader'
import Table from '../../../../ui/Table'
import { NotificationManager } from 'react-notifications'
import moment from 'moment'
import { connect } from 'react-redux'

class Claims extends React.Component {
  state = {
    filterGroups: [
      {
        name: 'Open/Closed',
        allowOnlyOne: true,
        filters: [
          {
            id: uniqueId(),
            name: 'Open',
            value: 'Open',
          },
          {
            id: uniqueId(),
            name: 'Closed',
            value: 'Closed',
          },
        ],
      },
      {
        name: 'status',
        allowOnlyOne: true,
        filters: [
          { id: uniqueId(), name: 'New claim', value: 'New claim' },
          { id: uniqueId(), name: 'Awaiting approval', value: 'Awaiting approval' },
          { id: uniqueId(), name: 'Awaiting information', value: 'Awaiting information' },
          { id: uniqueId(), name: 'Assessor appointed', value: 'Assessor appointed' },
          { id: uniqueId(), name: 'Diagnosis requested', value: 'Diagnosis requested' },
          { id: uniqueId(), name: 'Awaiting underwriter decision', value: 'Awaiting underwriter decision' },
          { id: uniqueId(), name: 'Reviewed by underwriters', value: 'Reviewed by underwriters' },
          { id: uniqueId(), name: 'Awaiting liability acceptance', value: 'Awaiting liability acceptance' },
          { id: uniqueId(), name: 'Approved', value: 'Approved' },
          { id: uniqueId(), name: 'Payment docs not received', value: 'Payment docs not received' },
          { id: uniqueId(), name: 'Awaiting Liability Payment', value: 'Awaiting Liability Payment' },
          { id: uniqueId(), name: 'Liability Paid', value: 'Liability Paid' },
          { id: uniqueId(), name: 'Approved not paid', value: 'Approved not paid' },
          { id: uniqueId(), name: 'Approved paid', value: 'Approved  paid' },
          { id: uniqueId(), name: 'No comms within 14 days', value: 'No comms within 14 days' },
          { id: uniqueId(), name: 'Parts not covered', value: 'Parts not covered' },
          { id: uniqueId(), name: 'Inherent Fault', value: 'Inherent Fault' },
          { id: uniqueId(), name: 'Rejected', value: 'Rejected' },
          { id: uniqueId(), name: 'Cancelled', value: 'Cancelled' },
          { id: uniqueId(), name: 'Closed expired MOT', value: 'Closed expired MOT' },
          { id: uniqueId(), name: 'Closed expired service', value: 'Closed expired service' },
        ],
      },
    ],
    query: '',
    page: 1,
    order: '',
    desc: true,
    limit: 10,
    status: '',
    open: false,
    closed: false,
    claims: null,
  }

  componentDidMount() {
    this.buildUrl()
  }

  fetchData = (url) => {
    api
      .get(`/claims/claims${url ? url : ''}`)
      .then((res) => {
        this.setState({ claims: res.data, searching: false })
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing.'
          )
        }
      })
  }

  handleTableChange = (
    type,
    { sortField, sortOrder = 'desc', page, sizePerPage }
  ) => {
    let url = `?query=${this.state.query}&page=${page}&desc=${
      sortOrder === 'desc' ? true : false
    }&limit=${sizePerPage}`

    if (this.state.open) {
      url += `&open=true`
    }

    if (this.state.closed) {
      url += `&closed=true`
    }

    if (this.state.status) {
      url = `${url}&status=${this.state.status}`
    }

    if (this.state.order) {
      url = `${url}&order=${this.state.order}`
    }

    if (sortField) {
      url = `${url}&order=${sortField}`
    }

    this.fetchData(url)
  }

  handleSearch = (query, filters) => {
    let status = ''
    let open;
    let closed;

    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]
      if (filter.group_name === 'status') {
        status = filter.name
      }
      if (filter.group_name === 'Open/Closed') {
        open = filter.value === 'Open'
        closed = filter.value === 'Closed'
      }
    }

    this.setState(
      {
        query,
        status,
        open,
        closed
      },
      this.buildUrl
    )
  }

  /**
   * Builds URL and to fetch claims
   */
  buildUrl = () => {
    const { query, page, desc, limit, status, open, closed, order } = this.state

    let url = `?query=${query}&page=${page}&limit=${limit}`

    if (order !== ''){
      url += `&order=${order}&desc=${desc}`
    }
    if (open) {
      url += '&open=true'
    }
    if (closed) {
      url += '&closed=true'
    }
    if (status !== '') {
      url += `&status=${status}`
    }

    this.fetchData(url)
  }

  render() {
    let cols = [
      {
        dataField: 'claim.ref',
        text: 'Claim ID',
        formatter: (cell, row) => {
          return (
            <Link key={row.ref} to={`/claims/claims/${row.id}`}>
              {row.ref}
            </Link>
          )
        },
      },
      {
        dataField: 'created_at',
        text: 'Claim start date',
        sort: true,
        formatter: (cell, row) => moment(row.created_at).format('DD/MM/YYYY'),
        sortCaret,
      },
      {
        dataField: 'policyholder_name',
        text: 'Policyholder name',
        formatter: (cell, row) => {
          if (hasPermission('claims.claims.update')) {
            return (
              <Link
                key={uniqueId('pname')}
                to={`/claims/policyholders/${row.policy.policyholder.id}/timeline`}
              >
                {row.policy.policyholder.title} {row.policy.policyholder.first_name +
                  ' ' +
                  row.policy.policyholder.last_name}
              </Link>
            )
          }
          return (
            row.policy.policyholder.title +
            ' ' +
            row.policy.policyholder.first_name +
            ' ' +
            row.policy.policyholder.last_name
          )
        },
      },
      {
        dataField: 'policy.vehicle.vrm',
        text: 'Vehicle reg',
      },
      {
        dataField: 'purchased_from',
        text: 'Purchased from',
        formatter: (cell, row) => {
          if (row.policy.dealer) {
            if (hasPermission('claims.dealers.view')) {
              return (
                <Link
                  key={uniqueId('pf')}
                  to={`/claims/dealers/${row.policy.dealer.id}/timeline`}
                >
                  {row.policy.dealer.name}
                </Link>
              )
            }
            return row.policy.dealer.name
          }
          return row.policy.purchased_from
        },
      },
      {
        dataField: 'payout',
        text: 'Payout',
        sort: true,
        sortCaret,
        formatter: (cell, row) => echoMoney(row.payout)
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        sortCaret,
      },
      {
        dataField: 'policy.ref',
        text: 'Policy ID',
        formatter: (cell, row) => {
          if (hasPermission('claims.claims.update')) {
            return (
              <Link
                key={uniqueId('pid')}
                to={`/claims/policies/${row.policy.id}/policy`}
              >
                {row.policy.ref}
              </Link>
            )
          }
          return row.policy.ref
        },
      },
      {
        dataField: 'garage.name',
        text: 'Garage',
        formatter: (cell, row) => {
          return row.garage && row.garage.name ? row.garage.name : '-'
        },
      },
    ]

    if (hasPermission('claims.claims.update')) {
      cols.push({
        dataField: 'actions',
        text: 'Actions',
        headerStyle: {
          width: 80,
        },
        formatter: (cell, row) => (
          <Link
            key={uniqueId('link')}
            data-tip="Edit claim"
            data-place="top"
            to={`/claims/claims/${row.id}`}
            className="table-action-btn table-action-btn--edit"
            size="sm"
          >
            <i className="wf-icon-edit" />
          </Link>
        ),
      })
    }

    const options = {
      showOptions: false,
      columns: cols,
    }

    return (
      <Fragment>
        <br />
        <div className="container-fluid animated fadeIn">
          {hasPermission('claims.claims.create') && (
            <PageHeader
              title="Claims"
              description={
                'A list of all dealer and public claims. You can view all contact history between Warranty First and a customer by viewing their timeline.'
              }
              buttonLink={'/claims/claims/create'}
              buttonText="Start new claim"
            />
          )}

          {!hasPermission('claims.claims.create') && (
            <PageHeader
              title="Claims"
              description={
                'A list of all dealer and public claims. You can view all contact history between Warranty First and a customer by viewing their timeline.'
              }
            />
          )}

          {this.state.claims && (
            <Table
              resource={this.state.claims}
              selected={[]}
              loading={false}
              showOptions={options.showOptions}
              omnibox={{ groups: this.state.filterGroups }}
              handleTableChange={this.handleTableChange}
              columns={options.columns}
              hasTableSearch={true}
              onSearch={this.handleSearch}
              searching={false}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
  }
}
export default connect(mapStateToProps, {})(Claims)
