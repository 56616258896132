import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { change, Field, Form, reduxForm } from 'redux-form'
import renderField from '../../../../../../utils/renderField'
import renderDatePicker from '../../../../../ui/renderDatePicker'
import { formatPostcode, postcodeValidator } from '../../../../../../utils/misc'
import { required } from 'redux-form-validators'
import CustomAsyncSelect from '../../../../../ui/CustomAsyncSelect'
import api from '../../../../../../utils/api'
import CustomSelect from '../../../../../ui/CustomSelect'
import { hasPermission } from '../../../../../../utils/auth';

const DealerDetailsForm = (props) => {
  const { handleSubmit, onSubmit, valid, dirty, toggle, status, vatRegistered } = props
  const [currentStatus, setCurrentStatus] = useState(status)
  const [currentVatRegistered, setCurrentVatRegistered] = useState(vatRegistered)

  const handleFormatPostcode = (event, value) => {
    event.preventDefault()

    const formatted = formatPostcode(value)
    props.dispatch(
      change('dealer/update-dealer-details', 'postcode', formatted, true)
    )
  }

  const handleVatRegisteredChange = (event) => {
    const vatRegistered = event.target.checked
    setCurrentVatRegistered(vatRegistered)
    if (!vatRegistered) {
      props.dispatch(
        change('dealer/update-dealer-details', 'vat_number', null, true)
      )
    }
  }

  const fetchGarages = (query) =>
    new Promise((resolve, reject) => {
      api
        .get(`misc/garages?query=${query ? query : ''}&limit=999999`)
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          if (err) {
            reject([])
          }
        })
    })

  return (
    <div className="my-3 px-1">
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Dealership name"
              component={renderField}
              name={`name`}
              className="form-control"
              placeholder={'e.g. 123 Cars'}
              validate={[
                required({
                  message: 'You must enter a dealership name.',
                }),
              ]}
            />
          </div>
        </div>

        {hasPermission('external_sales.dealers.update_status') && (
          <div className="row">
            <div className="col">
              <Field
                label="Status"
                component={CustomSelect}
                name={`status`}
                id={'status'}
                cacheOptions
                options={[
                  { value: 'In review' },
                  { value: 'Active' },
                  { value: 'Rejected' },
                ]}
                defaultOptions={false}
                getOptionLabel={(option) => option.value}
                getOptionValue={(option) => option.value}
                onChange={(option) => setCurrentStatus(option.value)}
              />
            </div>
            <div className="col">
              {currentStatus === 'Rejected' && (
                <Field
                  label="Sub status"
                  component={CustomSelect}
                  name={`sub_status`}
                  id={'sub_status'}
                  cacheOptions
                  options={[
                    { value: 'Due to inactivity' },
                    { value: 'Due to non-payment' },
                  ]}
                  defaultOptions={false}
                  getOptionLabel={(option) => option.value}
                  getOptionValue={(option) => option.value}
                />
              )}
            </div>
          </div>
        )}

        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Address"
              component={renderField}
              name={`address_1`}
              className="form-control"
              validate={[
                required({
                  message: 'You must enter the first line of address.',
                }),
              ]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label=""
              component={renderField}
              name={`address_2`}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label=""
              component={renderField}
              name={`address_3`}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="City"
              component={renderField}
              name={`city`}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="County"
              component={renderField}
              name={`county`}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Postcode"
              component={renderField}
              name={`postcode`}
              className="form-control"
              onBlur={handleFormatPostcode}
              validate={[
                required({ message: 'You must enter a postcode.' }),
                postcodeValidator(),
              ]}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 b-form-group">
            <Field
              name="active_start_date"
              style={{ width: '100%' }}
              label="Sign-up date"
              className="form-control"
              inputValueFormat="DD-MM-YYYY"
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              fixedHeight
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={3}
              id="dealerStartDate"
              component={renderDatePicker}
              validate={[
                required({
                  message: 'You must enter a date.',
                }),
              ]}
            />
          </div>
          <div className="col-md-6 b-form-group">
            <Field
              name="active_end_date"
              style={{ width: '100%' }}
              label="System access end date (optional)"
              className="form-control"
              inputValueFormat="DD-MM-YYYY"
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              fixedHeight
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={3}
              id="dealerEndDate"
              component={renderDatePicker}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              label="Preferred garage (optional)"
              component={CustomAsyncSelect}
              placeholder={'Search for a garage'}
              name={`garage`}
              id={'garage'}
              className={'select-z-index-fix'}
              isClearable
              loadOptions={fetchGarages}
              cacheOptions
              defaultOptions={false}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              type="checkbox"
              id={'allowOwnGarageRepairs'}
              label="Allow dealer’s own garage for repairs"
              component={renderField}
              name={`allow_own_garage_repairs`}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              type="checkbox"
              id={'vatRegistered'}
              label="This dealer is VAT registered"
              component={renderField}
              name={`vat_registered`}
              onChange={handleVatRegisteredChange}
            />
          </div>
        </div>

        {currentVatRegistered && (
          <div className="row">
            <div className="col">
              <Field
                type="text"
                label="VAT number"
                component={renderField}
                name={`vat_number`}
                className="form-control"
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col mt-3">
            <div className="form-group">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!valid || !dirty}
              >
                Save changes
              </button>
              <button
                type="button"
                className="btn btn-outline btn-form-cancel"
                onClick={() => toggle(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

DealerDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'dealer/update-dealer-details',
})(DealerDetailsForm)
