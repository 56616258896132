import React from 'react'
import { hasPermission } from '../../../utils/auth'
import * as _ from 'lodash'
import { NavLink } from 'react-router-dom'
import MenuLink from '../../ui/MenuLink'
import MenuLinkDropdown from '../../ui/MenuLinkDropdown'

export const SidebarMenu = ({ navigation, pathname, toggleMenu }) => {
  const openFirstMenuDropdown = menuLinkPath => {
    if (pathname && pathname.includes(menuLinkPath)) {
      return 'open'
    }
  }

  return (
    navigation && <ul className="nav">
      {/* eslint-disable-next-line array-callback-return */}
      {navigation.map((item, i) => {
        // eslint-disable-line
        if (hasPermission(item.permission) && _.startsWith(pathname, item.displayOn)) {
          return (
            <div key={i}>
              <div className="nav-title">{item.menuTitle}</div>
              {item.routes.map((r, i) => {
                if (!hasPermission(r.permission)) {
                  return false
                }
                return !r.children ?
                  <MenuLink link={r} key={i}/>
                  : (
                    <li
                      key={i}
                      className={`nav-item nav-dropdown ${openFirstMenuDropdown(r.url)}`}
                    >
                      <NavLink
                        to={r.url}
                        className="nav-link nav-dropdown-toggle"
                        onClick={toggleMenu}
                      >
                        {r.icon && <i className={r.icon}/>}
                        {r.image && <img className={r.image} alt=""/>}
                        <span>{r.name}</span>
                      </NavLink>
                      <MenuLinkDropdown links={r.children}/>
                    </li>
                  )
              })}
            </div>
          )
          // eslint-disable-next-line no-unreachable
          return <div key={i}/>
        }
      })}
    </ul>
  )
}