import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PageHeader from '../../../../ui/PageHeader'
import moment from 'moment'
import YearSelector from './forms/YearSelector'
import TeamTargetForm from './forms/TeamTargetForm'
import api from '../../../../../utils/api'
import { isDirty, reset } from "redux-form"
import useModal from "../../../../../hooks/useModal"
import Modal from "../../../../modals/Modal";
import {NotificationManager} from "react-notifications";

const TeamTargets = ({dispatch, ...props}) => {
  const [selectedYear, setSelectedYear] = useState(moment().year())
  const [confirmYear, setConfirmYear] = useState(null)
  const [hasYear, setHasYear] = useState(false)
  const [formValues, setFormValues] = useState({})

  const {isShowing: isShowingConfirmModal, toggle: toggleConfirmModal} = useModal()

  const yearChange = (value) => {
    if (props.yearDirty) {
      setConfirmYear(value.year)
      toggleConfirmModal()
    } else {
      setSelectedYear(value.year)
      return value
    }
  }

  const confirmYearChange = () => {
    setSelectedYear(confirmYear)
    toggleConfirmModal()
  }

  const cancelYearChange = () => {
    toggleConfirmModal()
    dispatch(reset('yearForm'))
  }

  const formValuesFromApi = (months) => {
    return months.sort((a, b) => {
          if (a.month < b.month) return -1
          if (a.month > b.month) return 1
          return 0
        }).map(item => {
          return item.value
        })
  }

  const annualAmount = (months) => {
    return months.reduce((acc, month) => acc + parseFloat(month.value), 0).toFixed(2)
  }

  const getFormValues = (data) => {
    let returnData = {};
    if (data['Direct Sales'].length > 0) {
      returnData.direct_sales =  {
          annual: annualAmount(data['Direct Sales']),
          months: formValuesFromApi(data['Direct Sales'])
      }
    }
    if (data['External Sales'].length > 0) {
      returnData.external_sales =  {
        annual: annualAmount(data['External Sales']),
        months: formValuesFromApi(data['External Sales'])
      }
    }
    return returnData;
  }

  useEffect(() => {
    api.get('/admin/targets/team/' + selectedYear).then((response => {
      setHasYear(true)
      setFormValues(getFormValues(response.data))
    })).catch(err => {
      if (err.response.status === 404) {
        setHasYear(false)
        setFormValues({})
      }
    })
  }, [selectedYear])

  const formatPostData = (values) => {
    let postData = {}
    if (typeof values.direct_sales !== 'undefined') {
      postData['Direct Sales'] = values.direct_sales.months.map((item, index) => {
        return {
          month: index + 1,
          value: parseFloat(item)
        }
      })
    }
    if (typeof values.external_sales !== 'undefined') {
      postData['External Sales'] = values.external_sales.months.map((item, index) => {
        return {
          month: index + 1,
          value: parseFloat(item),
        }
      })
    }
    if (!hasYear) {
      postData['year'] = selectedYear
    }
    return postData
  }

  const handleSubmit = (values) => {
    let url = hasYear ? 'admin/targets/team/' + selectedYear : 'admin/targets/team'
    if (hasYear) {
      api.patch(url, formatPostData(values))
        .then((response) => {
          NotificationManager.success('Woohoo! Your changes were saved')
          setFormValues(getFormValues(response.data))
        }).catch((err) => {
          console.error(err)
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing.',
          )
      })
    } else {
      api.post(url, formatPostData(values))
        .then((response) => {
          setHasYear(true) //As the year now exists, we need to ensure this is true so if they attempt a save again it doens't post
          NotificationManager.success('Woohoo! Your budgets were saved')
          setFormValues(getFormValues(response.data))
        }).catch((err) => {
          console.error(err)
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing.',
          )
      })
    }
  }

  return (
    <>
      <br />
      <div
        className="container-fluid animated fadeIn"
        style={{ overflowX: 'scroll' }}
      >
        <PageHeader title="Team Budgets" description="" />
        <ul className="list-info mb-4" style={{ maxWidth: '570px' }}>
          <li>Set annual budgets for your Warranty First sales teams.</li>
          <li>
            Start by entering the team's total budget for the year. You'll then
            be able to adjust monthly volumes and targets.
          </li>
        </ul>
        <YearSelector initialValues={{year: {year: selectedYear}}}  handleChange={yearChange} />
        <br />
        <TeamTargetForm year={selectedYear} initialValues={formValues} onSubmit={handleSubmit}  />
      </div>
      <Modal onCancel={cancelYearChange} onSubmit={confirmYearChange} isShowing={isShowingConfirmModal} hide={toggleConfirmModal} style={{maxWidth: '435px'}}>
        <>
          You have made changes to this year and not saved them. Do you want to change year?
        </>
      </Modal>
    </>
  )
}

export default connect((state) => ({
  yearDirty: isDirty('teamTargetForm')(state),
}))(TeamTargets)
