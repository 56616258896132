import React from 'react'
import PropTypes from 'prop-types'
import { echo } from '../../../../../utils/misc'

const DealerDetails = ({ dealer }) => {
  return (dealer &&
    <div className="row">
      <div className="col-12 pr-5">
        <h6 className="mt-5 mb-4 text-uppercase">Dealer details</h6>

        <div className="row">
          <div className="col">
            <span className="label">Dealership</span>
            <p>{dealer.name}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <span className="label">Address</span>
            <p>{[
              dealer.address_1,
              dealer.address_2,
              dealer.address_3,
              dealer.city,
              dealer.country,
              dealer.postcode,
            ]
              .filter(Boolean)
              .join(', ')}
              </p>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Telephone (office)</span>
            <p>{echo(dealer.tel_office)}</p>
          </div>
          <div className="col-4">
            <span className="label">Email address</span>
            <p>{echo(dealer.email)}</p>
          </div>
        </div>

        <hr/>
      </div>
    </div>
  )
}

DealerDetails.propTypes = {
  dealer: PropTypes.object.isRequired
}

export default DealerDetails
