import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import useModal from '../../../../../hooks/useModal'
import Modal from '../../../../modals/Modal'
import { hasPermission } from '../../../../../utils/auth'
import api from '../../../../../utils/api'
import { history } from '../../../../../utils/history'
import PolicyDetails from './PolicyDetails'
import { checked, echo, formatDate, money, prettyNumber } from '../../../../../utils/misc'
import EditFaultDetailsForm from './EditFaultDetailsForm'
import AdminComments from './AdminComments'
import { NotificationManager } from 'react-notifications'
import AddItemForm from './forms/AddItemForm'
import { SubmissionError } from 'redux-form'
import ClaimStatusForm from './forms/ClaimStatusForm'
import { clone, get, has } from 'lodash'
import SlideToggle from '../../../../ui/SlideToggle'
import { confirmAlert } from 'react-confirm-alert'
import ClaimItems from './ClaimItems'
import EditMotDateForm from "./EditMotDateForm";
import EditServiceDateForm from './EditServiceDateForm';
import { API_URL } from "../../../../../utils/constants";
import moment from 'moment';
import { connect } from 'react-redux';
import EditItemForm from './forms/EditItemForm';

const Claim = (props) => {
  const { loggedInPerson } = props
  const {
    isShowing: isShowingFaultDetailsModal,
    // toggle: toggleFaultDetailsModal,
  } = useModal()
  const {
    toggle: toggleAddClaimItemModal,
    isShowing: isShowingAddClaimItemModal,
  } = useModal()
  const {
    toggle: toggleEditClaimItemModal,
    isShowing: isShowingEditClaimItemModal,
  } = useModal()
  const {
    toggle: toggleGarageDetailsModal,
    isShowing: isShowingGarageDetailsModal,
  } = useModal()
  const {
    toggle: toggleDealerDetailsModal,
    isShowing: isShowingDealerDetailsModal,
  } = useModal()

  const params = useParams()
  const [claim, setClaim] = useState(null)
  const [claimItem, setClaimItem] = useState(null)
  const [editFaultDetails, setEditFaultDetails] = useState(false)
  const [editServiceDate, setEditServiceDate] = useState(false)
  const [editMotDate, setEditMotDate] = useState(false)
  const [claimProcessEmailLoading, setClaimProcessEmailLoading] = useState(false)
  const [contactEmailLoading, setContactEmailLoading] = useState(false)
  const [forceRerender, setForceRerender] = React.useState(false)
  const [payout, setPayout] = useState(0)

  useEffect(() => {
    fetchClaim();
  }, [params.id])

  useEffect(() => {
    // clear selected claim item if modal is close
    if (!isShowingEditClaimItemModal) {
      setClaimItem(null)
    }
  }, [isShowingEditClaimItemModal])

  const fetchClaim = () => {
    api
      .get(`/claims/claims/${params.id}`)
      .then((res) => {
        setClaim(res.data)
      })
      .catch((err) => {
        if (err && err.response.status === 404) {
          return history.push('/404')
        }
      })
  }

  const handleFaultDetailsSubmit = (values) => {
    return api
      .patch(`/claims/claims/${params.id}/details`, values)
      .then((res) => {
        NotificationManager.success('Woohoo! Your changes were saved.')

        setClaim((c) => {
          return {
            ...c,
            mileage: res.data.mileage,
            fault_date: res.data.fault_date,
            prediagnosis: res.data.prediagnosis,
            postdiagnosis: res.data.postdiagnosis,
            able_to_drive: res.data.able_to_drive,
            service_required: res.data.service_required,
          }
        })

        setEditFaultDetails(false)
      })
      .catch((err) => {
        if (err && err.response.status === 422) {
          throw new SubmissionError({
            ...err.response.data.errors,
            _error: err.response.data.message,
          })
        }
      })
  }

  const handleServiceDateSubmit = (values) => {
    return api
      .patch(`/claims/claims/${params.id}/service`, values)
      .then(response => {
        NotificationManager.success('Woohoo! Your changes were saved.')
        setClaim(response.data)
        setEditServiceDate(false)
      }).catch((err) => {
        if (err && err.response.status === 422) {
          throw new SubmissionError({
            ...err.response.data.errors,
            _error: err.response.data.message,
          })
        } else {
          NotificationManager.error('Oops! There was a problem saving the service Date. Please refresh and try again!')
        }
      })
  }

  const handleMotDateSubmit = (values) => {
    return api
      .patch(`/claims/claims/${params.id}/mot`, values)
      .then(response => {
        NotificationManager.success('Woohoo! Your changes were saved.')
        setClaim(response.data)
        setEditMotDate(false)
      }).catch((err) => {
        if (err && err.response.status === 422) {
          throw new SubmissionError({
            ...err.response.data.errors,
            _error: err.response.data.message,
          })
        } else {
          NotificationManager.error('Oops! There was a problem saving the MOT Date. Please refresh and try again!')
        }
      })
  }

  const handleRemoveItem = (id) => {
    api
      .delete(`/claims/claims/${params.id}/items/${id}`)
      .then(() => {
        NotificationManager.success('Woohoo! You deleted a claim item.')

        const copy = { ...claim }
        copy.items = copy.items.filter((item) => item.id !== id)
        setClaim(copy)

        fetchClaim() // so payout_net is updated
      })
      .catch((err) => {
        if (err.response.status === 404) {
          NotificationManager.success(
            'Oops! Claim item does not exist anymore.'
          )
        }
      })
  }

  const addClaimItem = (values) => {
    return api
      .post(`/claims/claims/${params.id}/items`, values)
      .then((res) => {
        setClaim((c) => {
          return {
            ...c,
            items: [...c.items, res.data],
          }
        })
        fetchClaim() // so payout_net is updated
        toggleAddClaimItemModal()
      })
      .catch((err) => {
        if (err && err.response.status === 422) {
          throw new SubmissionError({
            ...err.response.data.errors,
            _error: err.response.data.message,
          })
        }
      })
  }

  const updateClaimItem = (values) => {
    return api
      .patch(`/claims/claims/${params.id}/items/${values.id}`, values)
      .then((res) => {
        setClaim((c) => {
          return {
            ...c,
            items: [...c.items, res.data],
          }
        })
        fetchClaim() // so payout_net is updated
        toggleEditClaimItemModal()
      })
      .catch((err) => {
        if (err && err.response.status === 422) {
          throw new SubmissionError({
            ...err.response.data.errors,
            _error: err.response.data.message,
          })
        }
      })
  }

  const handleClaimStatusSubmit = (values) => {
    return api
      .patch(`/claims/claims/${params.id}/status`, {
        repair_quote: get(values, 'repair_quote'),
        status: get(values, 'status.value'),
        payee:  get(values, 'payee.value'),
        garage_id: get(values, 'garage_id.id') || null,
        dealer_id: get(values, 'dealer_id.id') || null,
        extension_date: get(values, 'extension_date') || null,
      })
      .then(async (res) => {
        NotificationManager.success('Woohoo! Your changes were saved.')

        if (values.status.value === 'Diagnosis requested' && values.status.value !== claim.status) {
          // send email to policyholder
          await api.post(`/claims/claims/${params.id}/email/diagnosis-requested`);
          
          // add message to timeline
          const message = {
            message: `Diagnosis Requested email sent by ${loggedInPerson.first_name} ${loggedInPerson.last_name} on the ${moment().format('Do')} of ${moment().format('MMM')} ${moment().format('YYYY')} at ${moment().format('HH:mm')}`,
            message_type: 'Email',
            person_id: loggedInPerson.person_id,
          };
          await createClaimEmailMessage(message);
          await createPolicyholderEmailMessage(message);

          setForceRerender();
        }

        fetchClaim()
      })
      .catch((err) => {
        if (err && err.response.status === 422) {
          throw new SubmissionError({
            ...err.response.data.errors,
            _error: err.response.data,
          })
        }
      })
  }
  
  const createPolicyholderEmailMessage = async (message) => {
    const section = claim.policy.purchased_from === 'Dealer' ? 'external-sales' : 'direct-sales'
    try {
      await api.post(`/${section}/policyholders/${claim.policy.policyholder.id}/timeline/message`, message)
    } catch(_) {}
  }

  const createClaimEmailMessage = async (message) => {
    try {
      await api.post(`/claims/claims/${claim.id}/notes`, message)
    } catch(_) {}
  }

  const createPolicyEmailMessage = async (message) => {
    const section = claim.policy.purchased_from === 'Dealer' ? 'external-sales' : 'direct-sales'
    try {
      await api.post(`/${section}/policies/${claim.policy.id}/timeline/message`, message)
    } catch(_) {}
  }

  const repairForm = async () => {
    api
      .get(`/downloads/pdf/claims/${params.id}/diagnosis`, {
        responseType: 'blob',
      })
      .then((res) => {
        NotificationManager.success('Your download will start automatically...')
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${claim.ref}_Diagnosis.pdf`)
        document.body.appendChild(link)
        link.click()
      })
  }

  /**
   * Makes API call to authorise repairs then another call
   * to download the authorisation form
   *
   * @returns {Promise<void>}
   */
  const authoriseForm = async () => {
    try {
      const authoriseReq = await api.post(
        `/claims/claims/${params.id}/authorise-repairs`
      )

      const copy = clone(claim)
      copy.items = copy.items.map((item) => {
        if (!item.auth_code) {
          item.auth_code = authoriseReq.data.auth_code
        }
        return item
      })

      setClaim(copy)

      NotificationManager.success('Woohoo! You authorised these items.')

      fetchClaim();

    } catch (err) {
      const errors = err.response.data.errors
      if (has(errors, 'claim_id')) {
        NotificationManager.error('Oops! ' + errors.claim_id[0])
      }
    }
  }

  const hasRepairItems = () => {
    return !!claim.items.filter(i => i.auth_code !== null).length
  }

  const downloadAuthForm = async () => {
    NotificationManager.success(
      'Woohoo! Your download will start soon...'
    )

    const pdfReq = await api.get(
      `/downloads/pdf/claims/${params.id}/authorisation`,
      {
        responseType: 'blob',
      }
    )

    const url = window.URL.createObjectURL(new Blob([pdfReq.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute(
      'download',
      `${claim.ref}_Authorisation.pdf`
    )
    document.body.appendChild(link)
    link.click()
  }

  const confirmAuthorisation = () => {
    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>Do you want to authorise repairs?</p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  authoriseForm()
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const certificateDownloadUrl = () => {
    return API_URL + `/downloads/pdf/direct-sales/policies/${claim.policy.id}/certificate?token=` + JSON.parse(localStorage.getItem('wf-token'))
  }

  const getStatusFormInitialValues = () => {
    return {
      repair_quote: claim.repair_quote,
      status: {
        value: claim.status,
      },
      payee: {
        value: claim.payee
      },
      garage_id: get(claim, 'garage'),
      dealer_id: get(claim, 'dealer'),
      extension_date: get(claim, 'extension_date'),
    }
  }

  const faultWasBeforePolicyStarted = () => {
    const faultDate = new Date(claim.fault_date);
    const policyStartDate = new Date(claim.policy.start_date);
    return faultDate < policyStartDate;
  }

  const sendClaimProcessEmail = async () => {
    setClaimProcessEmailLoading(true);

    try {
      // send email to policyholder
      await api.post(`/claims/claims/${params.id}/email/process`);

      // add message to timeline
      const message = {
        message: `Claims process sent by ${loggedInPerson.first_name} ${loggedInPerson.last_name} on the ${moment().format('Do')} of ${moment().format('MMM')} ${moment().format('YYYY')} at ${moment().format('HH:mm')}`,
        message_type: 'Email',
        person_id: loggedInPerson.person_id,
      };
      await createClaimEmailMessage(message);
      await createPolicyholderEmailMessage(message);
      await createPolicyEmailMessage(message);
 
      setForceRerender();
      NotificationManager.success('Woohoo! You sent claims process email.')
    } catch (err) {
      NotificationManager.error(
        'Oops! Something went wrong. Please try again after refreshing the page'
      )
    }

    setClaimProcessEmailLoading(false);
  }

  const sendContactEmail = async () => {
    setContactEmailLoading(true);

    try {
      // send email to policyholder
      await api.post(`/claims/claims/${params.id}/email/contact`);

      // add message to timeline
      const message = {
        message: `Can't contact email sent by ${loggedInPerson.first_name} ${loggedInPerson.last_name} on the ${moment().format('Do')} of ${moment().format('MMM')} ${moment().format('YYYY')} at ${moment().format('HH:mm')}`,
        message_type: 'Email',
        person_id: loggedInPerson.person_id,
      };
      await createClaimEmailMessage(message);
      await createPolicyholderEmailMessage(message);

      setForceRerender();
      NotificationManager.success('Woohoo! You sent can\'t contact email.')
    } catch (err) {
      NotificationManager.error(
        'Oops! Something went wrong. Please try again after refreshing the page'
      )
    }

    setContactEmailLoading(false);
  }

  return (
    claim && (
      <div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/claims/claims" className="open active">
              All claims
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">Edit claim ref. {claim.policy.ref}</span>
          </li>
        </ol>

        <div className="container">
          <header className="py-4 d-flex align-items-center justify-content-between border-bottom">
            <div>
              <div className="d-flex">
                <h3 style={{ fontSize: 36 }}>
                  Ref. {claim.ref},{' '}
                  <span className="font-xl mt-2- fw-400 text-gray-700">
                    {claim.policy.ref}
                  </span>
                  <span className="badge badge-primary badge-pill font-sm py-2 px-3 ml-3">
                    {claim.policy.purchased_from === 'Dealer'
                      ? 'Dealer'
                      : 'Public'}
                  </span>
                </h3>
              </div>
            </div>

              {hasPermission('claims.claims.update') && (
                <div className="ml-3 d-flex justify-content-end flex-wrap" style={{ gap: '1rem' }}>
                  <button
                    className="btn btn-sm btn-primary font-sm"
                    disabled={contactEmailLoading}
                    onClick={sendContactEmail}
                    style={{
                      paddingTop: 9,
                      paddingBottom: 9,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    Please contact
                  </button>
                  <button
                    className="btn btn-sm btn-primary font-sm"
                    disabled={claimProcessEmailLoading}
                    onClick={sendClaimProcessEmail}
                    style={{
                      paddingTop: 9,
                      paddingBottom: 9,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    Send Claim Process
                  </button>
                  {/* <button
                    className="btn btn-sm btn-primary font-sm"
                    onClick={repairForm}
                    style={{
                      paddingTop: 9,
                      paddingBottom: 9,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    Repair diagnosis form
                  </button> */}
                  {hasRepairItems() &&
                  <button
                    className="btn btn-sm btn-primary font-sm"
                    onClick={downloadAuthForm}
                    style={{
                      paddingTop: 9,
                      paddingBottom: 9,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    Authorisation form
                  </button>
                  }
                </div>
              )}
          </header>

          <div className="row">
            <div className="col-8 border-right mb-5">
              <div className="row">
                <div className="col-12 pr-5">
                  <div className="row">
                    <div className="col pb-3">
                      <h6 className="mt-5 mb-4 text-uppercase">
                        Policyholder timeline
                      </h6>
                      <p className="mb-0">
                        A history of all communication between the customer and
                        Warranty First.
                      </p>
                      <Link
                        to={`/claims/policyholders/${claim.policy.policyholder.id}/timeline`}
                        className="font-sm"
                      >
                        View timeline
                      </Link>
                    </div>
                  </div>

                  <hr />
                  <PolicyDetails policy={claim.policy} claimMileage={claim.mileage} />
                </div>
              </div>

              <div className="row">
                <div className="col-12 pr-5 pb-4">
                  <div className="mt-4 mb-4">
                    <div className="row">
                      <div className="col d-flex align-items-center">
                        <h6 className="text-uppercase">SERVICE</h6>
                      </div>
                      <div className="col d-flex align-items-center justify-content-end">
                        {hasPermission('claims.claims.update') && (
                          <button
                            className="btn btn-light btn-sm"
                            style={{
                              top: 0,
                              right: 0,
                              width: '40px',
                              height: '34px',
                            }}
                          >
                            <i
                              className="wf-icon-edit mr-0"
                              onClick={() => setEditServiceDate(!editServiceDate)}
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  <SlideToggle isVisible={editServiceDate}>
                    <EditServiceDateForm
                      onSubmit={handleServiceDateSubmit}
                      onCancel={() => setEditServiceDate(false)}
                      initialValues={{
                        service_date: claim.service_date,
                        service_mileage: claim.service_mileage,
                      }}
                    />
                  </SlideToggle>
                  {!editServiceDate && (
                      <>
                        <div className="row">
                          <div className="col-4">
                            <span className="label">Date of last service</span>
                            <p>{claim.service_date ? formatDate(claim.service_date) : '-'}</p>
                          </div>
                          <div className="col-4">
                            <span className="label">Mileage</span>
                            <p>{claim.service_mileage ? prettyNumber(claim.service_mileage) : '-'}</p>
                          </div>
                        </div>
                      </>
                  )}
                  <hr />
                </div>
              </div>

              <div className="row">
                <div className="col-12 pr-5 pb-4">
                  <div className="mt-4 mb-4">
                    <div className="row">
                      <div className="col d-flex align-items-center">
                        <h6 className="text-uppercase">MOT</h6>
                      </div>
                      <div className="col d-flex align-items-center justify-content-end">
                        {hasPermission('claims.claims.update') && (
                          <button
                            className="btn btn-light btn-sm"
                            style={{
                              top: 0,
                              right: 0,
                              width: '40px',
                              height: '34px',
                            }}
                          >
                            <i
                              className="wf-icon-edit mr-0"
                              onClick={() => setEditMotDate(!editMotDate)}
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  <SlideToggle isVisible={editMotDate}>
                    <EditMotDateForm
                      onSubmit={handleMotDateSubmit}
                      onCancel={() => setEditMotDate(false)}
                      initialValues={{
                        mot_date: claim.mot_date,
                        mot_mileage: claim.mot_mileage,
                      }}
                    />
                  </SlideToggle>
                  {!editMotDate && (
                      <>
                        <div className="row">
                          <div className="col-4">
                            <span className="label">Date of last MOT</span>
                            <p>{claim.mot_date ? formatDate(claim.mot_date) : '-'}</p>
                          </div>
                          <div className="col-4">
                            <span className="label">Mileage</span>
                            <p>{claim.mot_mileage ? prettyNumber(claim.mot_mileage) : '-'}</p>
                          </div>
                        </div>
                      </>
                  )}
                  <hr />
                </div>
              </div>

              <div className="row">
                <div className="col-12 pr-5 pb-4">
                  <div className="mt-4 mb-4">
                    <div className="row">
                      <div className="col d-flex align-items-center">
                        <h6 className="text-uppercase">Fault details</h6>
                      </div>
                      <div className="col d-flex align-items-center justify-content-end">
                        {hasPermission('claims.claims.update') && (
                          <button
                            className="btn btn-light btn-sm"
                            style={{
                              top: 0,
                              right: 0,
                              width: '40px',
                              height: '34px',
                            }}
                          >
                            <i
                              className="wf-icon-edit mr-0"
                              onClick={() =>
                                setEditFaultDetails(!editFaultDetails)
                              }
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  <SlideToggle isVisible={editFaultDetails}>
                    <EditFaultDetailsForm
                      onSubmit={handleFaultDetailsSubmit}
                      onCancel={() => setEditFaultDetails(false)}
                      initialValues={{
                        fault_date: claim.fault_date,
                        mileage: claim.mileage,
                        prediagnosis: claim.prediagnosis,
                        postdiagnosis: claim.postdiagnosis,
                        able_to_drive: claim.able_to_drive,
                        service_required: claim.service_required,
                      }}
                    />
                  </SlideToggle>

                  {!editFaultDetails && (
                    <>
                      <div className="row">
                        <div className="col-4">
                          <span className="label">Fault found on</span>
                          <p className={`${faultWasBeforePolicyStarted() ? "font-weight-bold text-red" : ""}`}>{formatDate(claim.fault_date)}</p>
                        </div>
                        <div className="col-4">
                          <span className="label">
                            Current accurate mileage
                          </span>
                          <p>
                            {claim.mileage
                              ? prettyNumber(claim.mileage) + ' miles'
                              : '-'}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <span className="label">Pre-diagnosis comments</span>
                          <p className="font-md">{echo(claim.prediagnosis)}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <span className="label d-block">
                            Post-diagnosis comments
                          </span>
                          {claim.postdiagnosis ||
                          !hasPermission('claims.claims.update') ? (
                            <p className="font-md">
                              {echo(claim.postdiagnosis)}
                            </p>
                          ) : (
                            <Link
                              to="#"
                              className="font-md"
                              onClick={() => setEditFaultDetails(true)}
                            >
                              Add information
                            </Link>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col mt-2">
                          <span className="font-md">
                            {checked(claim.able_to_drive)} Vehicle able to be driven
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col mt-2">
                          <span className="font-md">
                            {checked(claim.service_required)} Service required
                          </span>
                        </div>
                      </div>

                    </>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col pr-5">
                  <hr />
                  <AdminComments key={forceRerender} />
                </div>
              </div>

              <div className="row">
                <div className="col pr-5">
                  <hr />

                  <div className="row">
                    <div className="col">
                      <h6 className="mt-4 mb-5 text-uppercase">
                        Claims history
                      </h6>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Ref.</th>
                            <th>Fault date</th>
                            <th>Gross</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {claim.policy.claims.length > 0 ? (
                            claim.policy.claims.map((claim) => (
                              <tr key={claim.id}>
                                <td>
                                  <Link to={`/claims/claims/${claim.id}`}>
                                    {claim.ref}
                                  </Link>
                                </td>
                                <td>{formatDate(claim.fault_date)}</td>
                                <td>{money.format(claim.gross)}</td>
                                <td>{claim.status}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4} className="text-center">
                                No claims history.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="pl-5 w-100">
                  <div className="col p-0">
                    <h6 className="mt-5 mb-4 text-uppercase">
                      Policy documents
                    </h6>
                    <div
                      className="ph-claim d-flex justify-content-between align-items-center cursor-pointer"
                      onClick={() => {window.open(certificateDownloadUrl())}}
                    >
                      <div className="ph-claim-meta pb-2">
                        <div className="ph-document-date font-sm font-weight-medium">
                          {formatDate(claim.policy.created_at)}
                        </div>
                        <div className="ph-document-ref font-md">
                          Warranty Certificate
                        </div>
                      </div>

                      <div className="ph-documents-links">
                        <i className="wf-icon-download d-inline-block text-blue font-weight-bold"/>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="pl-5 w-100">
                  <ClaimItems
                    claim={claim}
                    payee={claim.payee}
                    garage={claim.garage}
                    dealer={claim.dealer}
                    claimItems={claim.items}
                    claimLimit={claim.policy.claim_limit}
                    removeItem={handleRemoveItem}
                    toggleAddClaimItemModal={toggleAddClaimItemModal}
                    setItem={setClaimItem}
                    toggleEditClaimItemModal={toggleEditClaimItemModal}
                    confirmAuth={confirmAuthorisation}
                    setPayout={setPayout}
                  />
                </div>

                <div className="pl-5 w-100">
                  <div className="d-block mb-3">
                    <hr className="mt-1 mb-4" />
                    <ClaimStatusForm
                      onSubmit={handleClaimStatusSubmit}
                      initialValues={getStatusFormInitialValues()}
                      statusText={claim.status}
                      payout={payout}
                      repairQuote={claim.repair_quote}
                      payee={claim.payee}
                      garage={claim.garage}
                      dealer={claim.dealer}
                      policyholder={claim.policy.policyholder}
                      onGarageInfoClick={(garage) => () => {
                        setClaim({...claim, garage: garage}) // so the modal displays the chosen garage even if the page has unsaved changes
                        toggleGarageDetailsModal()
                      }}
                      onDealerInfoClick={(dealer) => () => {
                        setClaim({...claim, dealer: dealer}) // so the modal displays the chosen garage even if the page has unsaved changes
                        toggleDealerDetailsModal()
                      }}                      
                      expiryDate={claim.expiry_date}
                      extensionDate={claim.extension_date}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal isShowing={isShowingFaultDetailsModal} />

        <Modal
          hide={toggleEditClaimItemModal}
          title={'Edit claim item'}
          style={{ maxWidth: 600 }}
          isShowing={isShowingEditClaimItemModal}
        >
          <EditItemForm
            onSubmit={updateClaimItem}
            onCancel={toggleEditClaimItemModal}
            initialValues={claimItem}
          />
        </Modal>

        <Modal
          hide={toggleAddClaimItemModal}
          title={'Add claim item'}
          style={{ maxWidth: 600 }}
          isShowing={isShowingAddClaimItemModal}
        >
          <AddItemForm onSubmit={addClaimItem} onCancel={toggleAddClaimItemModal} />
        </Modal>

        {claim.garage &&
        <Modal
          isShowing={isShowingGarageDetailsModal}
          hide={toggleGarageDetailsModal}
          title="Garage info"
          style={{ width: 600 }}
        >
          <div className="row">
            <div className="col">
              <span className="label">Name</span>
              <p className="font-md">{echo(claim.garage.name)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="label">Address</span>
              <p className="font-md">{
                [
                  claim.garage.address_1, claim.garage.address_2, claim.garage.address_3,
                  claim.garage.city, claim.garage.county, claim.garage.postcode
                ].filter(Boolean).join(', ')
              }</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="label">Tel (office)</span>
              <p className="font-md">{echo(claim.garage.tel_office)}</p>
            </div>
            <div className="col">
              <span className="label">Tel (mobile)</span>
              <p className="font-md">{echo(claim.garage.tel_mobile)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="label">Email</span>
              <p className="font-md">{echo(claim.garage.email)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="label">Status</span>
              <p className="font-md">{echo(claim.garage.status)}</p>
            </div>
            <div className="col">
              <span className="label">Sign-up date</span>
              <p className="font-md">{claim.garage.signup_date ? moment(claim.garage.signup_date).format('DD/MM/YYYY') : '-'}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="label">Minimum Labour Rate</span>
              <p className="font-md">{claim.garage.minimum_labour_rate || '-'}</p>
            </div>
            <div className="col">
              <span className="label">Sell Out Labour Rate</span>
              <p className="font-md">{claim.garage.sell_out_labour_rate || '-'}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className='small'>
                <i
                  className={`wf-icon-${claim.garage.authorised_repairer ? 'circle-tick' : 'red-cross'} d-inline-block text-green`}
                  style={{ marginBottom: 3 }}
                />{' '}
                This garage is {claim.garage.authorised_repairer ? '' : 'not'} an authorised repairer
              </p>
            </div>
            <div className="col-12">
              <p className='small'>
                <i
                  className={`wf-icon-${claim.garage.vat_registered ? 'circle-tick' : 'red-cross'} d-inline-block text-green`}
                  style={{ marginBottom: 3 }}
                />{' '}
                This garage is {claim.garage.vat_registered ? '' : 'not'} VAT registered
              </p>
            </div>
          </div>
        </Modal>
        }

        {claim.dealer &&
        <Modal
          isShowing={isShowingDealerDetailsModal}
          hide={toggleDealerDetailsModal}
          title="Dealer info"
          style={{ width: 600 }}
        >
          <div className="row">
            <div className="col">
              <span className="label">Name</span>
              <p className="font-md">{echo(claim.dealer.name)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="label">Address</span>
              <p className="font-md">{
                [
                  claim.dealer.address_1, claim.dealer.address_2, claim.dealer.address_3,
                  claim.dealer.city, claim.dealer.county, claim.dealer.postcode
                ].filter(Boolean).join(', ')
              }</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="label">Tel (office)</span>
              <p className="font-md">{echo(claim.dealer.tel_office)}</p>
            </div>
            <div className="col">
              <span className="label">Tel (mobile)</span>
              <p className="font-md">{echo(claim.dealer.tel_mobile)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="label">Email</span>
              <p className="font-md">{echo(claim.dealer.email)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="label">Status</span>
              <p className="font-md">{echo(claim.dealer.status)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className='small'>
                <i
                  className={`wf-icon-${claim.dealer.vat_registered ? 'circle-tick' : 'red-cross'} d-inline-block text-green`}
                  style={{ marginBottom: 3 }}
                />{' '}
                This dealer is {claim.dealer.vat_registered ? '' : 'not'} VAT registered
              </p>
            </div>
          </div>
        </Modal>
        }

      </div>
    )
  )
}

Claim.propTypes = {}

export default connect(
  (state) => ({
    loggedInPerson: state.authentication.user,
  }),
  null
)(Claim)
