import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import moment from 'moment'
import { get } from 'lodash'
import { echo, formatExcess, money, prettyNumber } from '../../../utils/misc'
import SlideToggle from "../../ui/SlideToggle";
import EditPolicyholderContact from "./EditPolicyholderContactForm";
import { updatePolicyholderContactDetails as ESUpdatePH } from "../../../actions/external-sales/policy-holders.actions";
import { updatePolicyholderContactDetails as DSUpdatePH } from "../../../actions/direct-sales/policy-holders.actions";
import { updatePolicyholderContactDetails as ClaimsUpdatePH } from "../../../actions/claims/policy-holders.actions";
import {connect} from "react-redux";

function PolicyholderCard({
  loggedInPerson,
  policyHolder,
  activePolicyTab,
  setActivePolicyTab,
  setForceRerender,
  section,
  ESUpdatePH,
  DSUpdatePH,
  ClaimsUpdatePH,
}) {
  let address = [
    policyHolder.address_1,
    policyHolder.address_2,
    policyHolder.address_3,
    policyHolder.city,
    policyHolder.county,
    policyHolder.postcode,
  ]
    .filter((component) => {
      return component != null && component !== ''
    })
    .join(', ')

  let expiredPolicies = []

  let [editContact, setEditContact] = useState(false);

  expiredPolicies =
    policyHolder &&
    policyHolder.policies.filter((p) => moment(p.end_date).isBefore(moment()))
  let activePolicies =
    policyHolder &&
    policyHolder.policies.filter((p) => moment(p.end_date).isAfter(moment()))

  let hasMultipleActivePolicies = policyHolder && activePolicies.length > 1

  const initialValues = () => ({
    title: policyHolder.title,
    first_name: policyHolder.first_name,
    last_name: policyHolder.last_name,
    address_1: policyHolder.address_1,
    address_2: policyHolder.address_2,
    address_3: policyHolder.address_3,
    city: policyHolder.city,
    county: policyHolder.county,
    postcode: policyHolder.postcode,
    email: policyHolder.email,
    tel1: policyHolder.tel1,
    tel2: policyHolder.tel2
  })

  const handleSubmitEditForm = (values) => {
    switch(section) {
      case 'claims':
        ClaimsUpdatePH(policyHolder.id, values)
        break;
      case 'direct-sales':
        DSUpdatePH(policyHolder.id, values)
        break;
      case 'external-sales':
        ESUpdatePH(policyHolder.id, values)
        break;
      default:
    }
    setEditContact(false)
  }

  return (
    policyHolder && (
      <div className="ph-details d-flex shadow-dark">
        <div className="ph-details-person">
          <header className="d-flex align-items-center justify-content-between mb-4">
            <h6 className="text-uppercase">Contact details</h6>
            <button className="btn-gray" data-tip="Edit contact details" data-place="top" onClick={() => {setEditContact(!editContact)}}>
              <i className="wf-icon-edit d-block" /></button>
          </header>

          {!editContact && <>
            <div className="form-group">
              <label className="form-label mb-0">Name</label>
              <div className="font-md">{policyHolder.title} {policyHolder.first_name} {policyHolder.last_name}</div>
            </div>
            <div className="form-group">
              <label className="form-label mb-0">Address</label>
              <div className="font-md">{echo(address)}</div>
            </div>
            <div className="form-group">
              <label className="form-label mb-0">Email</label>
              <div className="font-md">{echo(policyHolder.email)}</div>
            </div>
            <div className="form-group">
              <label className="form-label mb-0">Telephone (home)</label>
              <div className="font-md">{echo(policyHolder.tel1)}</div>
            </div>
            <div className="form-group">
              <label className="form-label mb-0">Telephone (mobile)</label>
              <div className="font-md">{echo(policyHolder.tel2)}</div>
            </div>
          </>
          }
          <SlideToggle isVisible={editContact}>
            <EditPolicyholderContact initialValues={initialValues()} onSubmit={handleSubmitEditForm} />
          </SlideToggle>
        </div>
        <div className="ph-details-policies flex-grow-1">
          <h6 className="mb-4">Policies</h6>
          {hasMultipleActivePolicies ? (
            <>
              <Nav tabs>
                {policyHolder &&
                  activePolicies.map((policy, index) => {
                    return (
                      <NavItem
                        key={index}
                        className="border-right-0 rounded-0 bg-gray-100"
                      >
                        <NavLink
                          className={`${
                            activePolicyTab === index && 'active'
                          } font-md`}
                          onClick={() => setActivePolicyTab(index)}
                        >
                          {policy.ref}
                        </NavLink>
                      </NavItem>
                    )
                  })}
              </Nav>

              <TabContent
                activeTab={activePolicyTab}
                className="border-bottom-0"
              >
                <TabPane className="pb-0 pl-0 pr-0 " tabId={activePolicyTab}>
                  <PolicyDetails
                    policy={activePolicies[activePolicyTab]}
                    section={section}
                  />
                </TabPane>
              </TabContent>
            </>
          ) : (
            <>
              {activePolicies.length > 0 ? (
                <PolicyDetails policy={activePolicies[0]} section={section} />
              ) : (
                <p className="font-sm">
                  This user currently does not have any active policies.
                </p>
              )}
            </>
          )}

          {expiredPolicies.length > 0 && (
            <div className="mt-4">
              <hr className="mt-0 mb-3" />
              <h6 className="mt-4 mb-4">Expired policies</h6>

              <div className="label">Policy ID</div>
              {expiredPolicies.map((policy, index) => {
                if (expiredPolicies.length - 1 === index) {
                  return (
                    <Link
                      key={index}
                      to={`/${section}/policies/${policy.id}/policy`}
                    >
                      {policy.ref}
                    </Link>
                  )
                }
                return (
                  <Link
                    key={index}
                    to={`/${section}/policies/${policy.id}/policy`}
                  >
                    {policy.ref},{' '}
                  </Link>
                )
              })}
            </div>
          )}
        </div>
      </div>
    )
  )
}

function PolicyDetails(props) {
  const policy = props.policy

  if (typeof policy == 'undefined') {
    return <></>
  }

  const dealerName =
    policy.purchased_from === 'Dealer' && get(policy, 'cover_level.dealer.name')

  const section = props.section

  return (
    policy && (
      <div className="ph-details-items">
        <div className="ph-details__row">
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Policy ID</label>
              <div className="font-md">
                <Link to={`/${section}/policies/${policy.id}/policy`}>
                  {echo(policy.ref)}
                </Link>
              </div>
            </div>
          </div>
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Policy type</label>
              <div className="font-md">{echo(policy.type)}</div>
            </div>
          </div>
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Policy status</label>
              <div className="font-md">{echo(policy.status)}</div>
            </div>
          </div>
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Company</label>
              <div className="font-md">{echo(policy.company_name)}</div>
            </div>
          </div>
        </div>

        <div className="ph-details__row">
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Vehicle reg.</label>
              <div className="font-md">{echo(policy.vehicle.vrm)}</div>
            </div>
          </div>
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Type</label>
              <div className="font-md">{echo(policy.vehicle.type)}</div>
            </div>
          </div>
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Make and model</label>
              {policy.vehicle && (
                <div className="font-md">
                  {echo(policy.vehicle.make)}, {echo(policy.vehicle.model)}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="ph-details__row">
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Cover level</label>
              <div className="font-md">{policy.cover_level.name}, {policy.duration}</div>
            </div>
          </div>

          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Start date</label>
              <div className="font-md">
                {policy.start_date
                  ? moment(policy.start_date).format('DD/MM/YYYY')
                  : '-'}
              </div>
            </div>
          </div>

          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">End date</label>
              <div className="font-md">
                {policy.end_date
                  ? moment(policy.end_date).format('DD/MM/YYYY')
                  : '-'}
              </div>
            </div>
          </div>
        </div>

        <div className="ph-details__row">
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Claim limit</label>
              <div className="font-md">
                {policy.claim_limit ? money.format(policy.claim_limit) : '-'}
              </div>
            </div>
          </div>

          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Labour rate</label>
              <div className="font-md">
                {policy.labour_rate ? money.format(policy.labour_rate) : '-'}
              </div>
            </div>
          </div>

          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Excess</label>
              {formatExcess(policy.excess_type, policy.excess)}
            </div>
          </div>
        </div>

        <div className="ph-details__row">
          <div className="ph-details__column">
            <div className="form-group pb-0 mb-0">
              <label className="form-label mb-0">Starting mileage</label>
              <div className="font-md">
                {policy.vehicle.mileage && prettyNumber(policy.vehicle.mileage)}
              </div>
            </div>
          </div>

          <div className="ph-details__column">
            <div className="form-group pb-0 mb-0">
              <label className="form-label mb-0">Purchased from</label>

              <div className="font-md">
                {dealerName ? dealerName : echo(policy.purchased_from)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

PolicyDetails.propTypes = {
  policy: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired,
}

export default connect(null, {
  ESUpdatePH,
  DSUpdatePH,
  ClaimsUpdatePH
})(PolicyholderCard)
