import {
  FETCH_TERRITORIES_REQUEST,
  FETCH_TERRITORIES_SUCCESS,
  FETCH_TERRITORIES_FAILURE,
  TERRITORIES_TABLE_ROW_ADD,
  TERRITORIES_TABLE_ROW_REMOVE,
  FETCH_TERRITORY_REQUEST,
  FETCH_TERRITORY_SUCCESS,
  FETCH_TERRITORY_FAILURE,
  DELETE_TERRITORY_SUCCESS,
  ADD_ACCOUNT_MANAGER,
} from '../constants'

const initialState = {
  isPending: true,
  data: null,
  error: null,
  singleTerritory: null,
  selectedRows: [],
  multiSuspendModal: false,
}

export function territories(state = initialState, action) {
  switch (action.type) {
    case FETCH_TERRITORIES_REQUEST:
      return {
        ...state,
        isPending: true,
      }
    case FETCH_TERRITORIES_SUCCESS:
      return {
        ...state,
        isPending: false,
        data: { ...action.payload },
      }
    case FETCH_TERRITORIES_FAILURE:
      return {
        ...state,
        isPending: false,
        data: null,
        error: { ...action.payload },
      }

    case TERRITORIES_TABLE_ROW_ADD:
      return {
        ...state,
        selectedRows: [...state.selectedRows, ...action.payload.data],
      }

    case TERRITORIES_TABLE_ROW_REMOVE:
      return {
        ...state,
        selectedRows:
          action.payload.data.length === 1
            ? [
                ...state.selectedRows.filter(
                  row => row !== action.payload.data[0],
                ),
              ]
            : [],
      }

    case FETCH_TERRITORY_REQUEST:
      return {
        ...state,
        isPending: true,
      }

    case FETCH_TERRITORY_SUCCESS:
      return {
        ...state,
        isPending: false,
        singleTerritory: action.payload.territory,
      }

    case FETCH_TERRITORY_FAILURE:
      return {
        ...state,
        isPending: false,
        singleTerritory: null,
        error: action.payload.error,
      }
    case DELETE_TERRITORY_SUCCESS:
      return {
        ...state,
        singleTerritory: null,
        data: {
          ...state.data,
          data: state.data.data.filter(item => item.id !== action.payload.id),
        },
      }
    case ADD_ACCOUNT_MANAGER:
      let accManager = action.payload.accountManager

      return {
        ...state,
        singleTerritory: {
          ...state.singleTerritory,
          account_managers: [
            ...state.singleTerritory.account_managers,
            accManager,
          ],
        },
      }
    default:
      return state
  }
}
