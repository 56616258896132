import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { echo } from '../../../../../utils/misc'
import { hasPermission } from '../../../../../utils/auth';

const PolicyholderDetails = ({ policyholder }) => {
  return (
    <div className="row">
      <div className="col-12 pr-5">
        <div className="mb-5">
          <div>
            <h6 className="text-uppercase">Policyholder details</h6>
            {hasPermission('claims.policyholders.*') &&
            <Link to={`/claims/policyholders/${policyholder.id}/timeline`} className="font-md">
              View timeline
            </Link>
            }
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Driver</span>
            <p className="font-md">{echo(policyholder.first_name)} {echo(policyholder.last_name, '')}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <span className="label">Address</span>
            <p className="font-md"> {[
              policyholder.address_1,
              policyholder.address_2,
              policyholder.address_3,
              policyholder.city,
              policyholder.country,
              policyholder.postcode,
            ]
              .filter(Boolean)
              .join(', ')}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Telephone (office)</span>
            <p className="font-md">{echo(policyholder.tel1)}</p>
          </div>
          <div className="col-4">
            <span className="label">Telephone (mobile)</span>
            <p className="font-md">{echo(policyholder.tel2)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <span className="label">Email</span>
            <p className="font-md">{echo(policyholder.email)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

PolicyholderDetails.propTypes = {
  policyholder: PropTypes.object.isRequired
}

export default PolicyholderDetails
