import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form, reduxForm } from 'redux-form'
import CustomSelect from "../../../../ui/CustomSelect";

const EditGarageFormStatus = ({
                          handleSubmit,
                          onSubmit,
                          valid,
                          dirty,
                          toggleForm,
                        }) => {
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row">
          <div className="col">
            <Field
              name="status"
              component={CustomSelect}
              label="Status"
              options={[{name: 'In review'}, {name: 'Active'}, {name: 'Blacklisted'}]}
              getOptionLabel={(o) => o.name}
              getOptionValue={(o) => o.name}
            />
          </div>
        </div>

        <div className="row">
          <div className="col mt-3">
            <div className="form-group">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!valid || !dirty}
                onClick={() => toggleForm(false)}
              >
                Save changes
              </button>
              <button
                type="button"
                className="btn btn-outline btn-form-cancel"
                onClick={() => toggleForm(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}

const validate = (fields) => {
  let errors = {}

  return errors
}

EditGarageFormStatus.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  toggleForm: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'garage/editStatus',
  validate,
})(EditGarageFormStatus)
