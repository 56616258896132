import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { history } from "../../../../../utils/history";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { confirmAlert } from "react-confirm-alert";
import api from "../../../../../utils/api";

const EditDealerHeader = ({ dealer }) => {

  useEffect(() => {
    // odd scroll behaviour fix.
    window.scroll(0, 0)
  }, [])

  const deleteDealer = async () => {
    const res = await api.delete(
      `/external-sales/dealers/${dealer.id}`
    )
    if (res.status === 200) {
      history.push('/external-sales/dealers')
      NotificationManager.success('Dealer deleted successfully!')
    } else if (res.status === 404) {
      NotificationManager.error('The dealer could not be found.')
    }
  }

  const deleteModal = () => {
    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>Delete the dealer <strong>{dealer.name}</strong>? It'll be lost forever.</p>
              <button
                className="btn btn-secondary" onClick={() => {
                  onClose()
                  deleteDealer()
                }}
              >
                Yes, I'm sure
              </button>
              <button className="btn btn-transparent text-secondary" onClick={onClose}>
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  return (
    <div>
      <header className="py-4 d-flex align-items-center justify-content-between border-bottom">
        <h3 style={{ fontSize: 36 }}>Edit dealer</h3>
        <button onClick={() => deleteModal()} className="btn-gray" data-tip="Delete dealer" data-place="top">
          <i className="wf-icon-delete d-block"/>
        </button>
      </header>
    </div>
  );
};

EditDealerHeader.propTypes = {
  dealerName: PropTypes.string
};

export default EditDealerHeader;
