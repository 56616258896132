import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PageHeader from '../../../ui/PageHeader'
import Table from '../../../ui/Table'
import api from '../../../../utils/api'
import { sortCaret } from '../../../../utils/misc'
import moment from 'moment'
import Avatar from '../../../ui/Avatar'
import { confirmAlert } from 'react-confirm-alert'
import { filterGroups } from './filterGroups'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'
import AsyncSelect from 'react-select/lib/Async'
import {
  assignRenewal,
  deleteRenewals,
  fetchRenewals,
  renewalsTableRowAdd,
  renewalsTableRowRemove,
  restoreRenewals,
} from '../../../../actions/direct-sales/renewals.actions'
import { Link } from 'react-router-dom'
import pDebounce from '../../../../utils/pDebounce';
import { hasPermission } from '../../../../utils/auth';

class RenewalsPage extends Component {
  state = {
    pipelines          : [],
    filterGroups,
    query              : '',
    page               : 1,
    desc               : false,
    limit              : 10,
    order              : '',
    filters            : [],
    dateRange          : [],
    now                : moment(),
    showDeletedRenewals: false,
    selectedAssignee   : false,
    prioritise         : false,
  }

  componentDidMount() {
    this.props.fetchRenewals()

    api.get('direct-sales/pipeline/lanes').then(res => {
      this.setState({
        filterGroups: [
          {
            name        : 'Pipeline lane',
            filters     : res.data.data,
            allowOnlyOne: true,
          },
          ...this.state.filterGroups,
        ],
      })
    })

    this.fetchAssignees().then(res => {
      this.setState({
        filterGroups: [
          {
            name        : 'Assignee',
            filters     : res.map((user, index) => ({
              id   : index,
              name : (
                <div
                  className="d-flex align-items-center"
                  style={{ marginBottom: '-2px' }}
                >
                  <Avatar user={user} size="xsmall"/>
                  <span className="font-md ml-1" style={{ marginTop: '-2px' }}>
                    {user.first_name} {user.last_name}
                  </span>
                </div>
              ),
              value: user.id,
            })),
            allowOnlyOne: true,
          },
          ...this.state.filterGroups,
        ],
      })
    })
  }

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    this.setState(
      {
        page : page || 1,
        desc : sortOrder === 'desc' ? true : false || false,
        limit: sizePerPage || 10,
        order: sortField === 'pipeline_item.id' ? 'lane_id' : sortField || '',
      },
      this.buildUrlAndFetchRenewals,
    )
  }

  buildUrlAndFetchRenewals = () => {
    const { query, page, desc, limit, order, filters } = this.state
    let dateRange = filters.filter(f => f.group_name === 'Expires within')
    let pipelines = filters.filter(f => f.group_name === 'Pipeline lane')
    let deleted = filters.filter(f => f.group_name === 'Deleted')
    let assignee = filters.filter(f => f.group_name === 'Assignee')

    let url = `?query=${query}&page=${page}&desc=${desc}&limit=${limit}`

    if (order !== '') url = `${url}&order=${order}`

    if (pipelines.length > 0) url = `${url}&lane_id=${pipelines[0].id}`

    if (dateRange.length === 1) {
      if (dateRange[0].name === 'Expired') {
        url = `${url}&max_end_date=${moment().subtract(1, 'day').format('YYYY-MM-DD')}`
      } else {
        url = `${url}&min_end_date=${moment().add(1, 'day').format(
          'YYYY-MM-DD',
        )}&max_end_date=${dateRange[0].value}`
      }
    }

    if (deleted.length === 1) {
      url = `${url}&deleted=1`
      this.setState({ showDeletedRenewals: true })
    } else {
      this.setState({ showDeletedRenewals: false })
    }
    if (assignee.length === 1) {
      url = `${url}&assignee_id=${assignee[0].value}`
    }
    this.props.fetchRenewals(url)
    this.props.renewalsTableRowRemove([])
  }

  handleSearch = (query, filters) => {
    this.setState(
      {
        filters,
        query,
      },
      this.buildUrlAndFetchRenewals,
    )
  }

  handleRowSelect = (row, isSelect, rowIndex, e) => {
    if (isSelect) this.props.renewalsTableRowAdd([row.id])
    else this.props.renewalsTableRowRemove([row.id])
  }

  handleRowSelectAll = (isSelect, rows, e) => {
    let ids = rows.map(row => row.id)
    if (isSelect) this.props.renewalsTableRowAdd(ids)
    else this.props.renewalsTableRowRemove(ids)
  }

  /**
   * Get prospect's full name
   */
  getPolicyholderName = id => {
    const { renewals } = this.props

    if (id) {
      let rows = _.clone(renewals.data)
      let renewal = rows.filter(p => p.id === id)[0]

      return `${renewal.policyholder.title} ${renewal.policyholder.first_name} ${renewal.policyholder.last_name}`
    }
  }

  showDeleteModal = row => {
    const selectedRows = this.props.selectedRows
    let policyholderName

    if (selectedRows && selectedRows.length === 1) {
      policyholderName = this.getPolicyholderName(selectedRows[0])
    }

    return confirmAlert({
      title   : '🧐 Are you sure?',
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose}/>
              <h3>{title}</h3>
              {selectedRows.length === 1 ? (
                <p>
                  Delete <strong>{policyholderName}</strong> from your renewals? You
                  can undo this later.
                </p>
              ) : (
                 <p>
                   Delete <strong>{selectedRows.length}</strong> renewals? You
                   can undo this later.
                 </p>
               )}

              <button
                className="btn btn-secondary"
                onClick={() =>
                  this.props
                    .deleteRenewals(this.props.selectedRows)
                    .then(() => {
                      this.buildUrlAndFetchRenewals()
                      onClose()
                    })
                }
              >
                Yes, I'm sure
              </button>

              <button
                type="button"
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  fetchAssignees = (val) => {
    return new Promise((resolve, reject) => {
      api
        .get('/direct-sales/renewals/assignees?limit=999&query=' + (val || ''))
        .then(res => {
          resolve(res.data.data)
        })
        .catch(err => {
          if (err) {
            reject([])
          }
        })
    })
  }

  loadAssigneeOptions = pDebounce((val) => {
    return this.fetchAssignees(val)
  }, 1000)

  showAssignModal = renewal => {
    let selectedRows = this.props.selectedRows
    let singleRenewalsText = `${renewal && this.getPolicyholderName(renewal.id)}`

    let renewalsIds = renewal ? [renewal.id] : selectedRows
    let multiRenewalText = `${renewalsIds && renewalsIds.length} renewals`

    const handleAssignRenewal = onClose => {
      const { selectedAssignee } = this.state
      const prioritised = document
        .querySelector('.assign-priority')
        .classList.contains('wf-icon-prioritise-selected')

      this.props
        .assignRenewal(renewalsIds, selectedAssignee, prioritised)
        .then(() => {
          this.setState({
            selectedAssignee: null,
            prioritise      : false,
          })
          onClose()
        })
    }

    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <span className="btn-close-modal fa fa-close" onClick={onClose}/>
            <h3 className="mb-0">Assign renewals</h3>
            <p className="mt-0">
              {renewal ? singleRenewalsText : multiRenewalText}
            </p>

            <div className="form-group">
              <label htmlFor="assignee">Assignee</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={this.loadAssigneeOptions}
                placeholder={'Select an agent'}
                onChange={option =>
                  this.setState({
                    selectedAssignee: option.id,
                  })
                }
                getOptionValue={user => user.id}
                getOptionLabel={user => (
                  <div className="d-flex">
                    <Avatar user={user} size="small"/>
                    <div className="ml-3">{`${user.first_name} ${user.last_name}`}</div>
                  </div>
                )}
                styles={{
                  control           : base => ({
                    ...base,
                    height   : 48,
                    minHeight: 48,
                  }),
                  menuList          : base => ({
                    ...base,
                    paddingTop   : 0,
                    paddingBottom: 0,
                  }),
                  indicatorSeparator: () => ({ display: 'none' }),
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="prioritise">Prioritise</label>
              <br/>
              <div
                className="assign-priority wf-icon-prioritise"
                style={{ cursor: 'pointer' }}
                onClick={this.togglePrioritise}
              />
            </div>
            <button
              className="btn btn-secondary"
              onClick={() => handleAssignRenewal(onClose)}
            >
              Assign to agent
            </button>
            <button
              type="button"
              className="btn btn-transparent text-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        )
      },
    })
  }

  togglePrioritise = e => {
    e.target.classList.toggle('wf-icon-prioritise')
    e.target.classList.toggle('wf-icon-prioritise-selected')
  }

  showRestoreModal = row => {
    let policyholderName

    // Handle single prospect restore
    if (row) policyholderName = this.getPolicyholderName(row.id)
    // It's not preptty but
    if (this.props.selectedRows.length === 1)
      policyholderName = this.getPolicyholderName(this.props.selectedRows[0])

    return confirmAlert({
      title   : '🧐 Are you sure?',
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose}/>
              <h3>{title}</h3>
              {policyholderName ? (
                <p>
                  Restore <strong>{policyholderName}</strong> to your renewals table?
                </p>
              ) : (
                 <p>
                   Restore <strong>{this.props.selectedRows.length}</strong>{' '}
                   drivers to your renewals table?
                 </p>
               )}

              <button
                className="btn btn-secondary"
                onClick={() =>
                  this.props
                    .restoreRenewals(this.props.selectedRows)
                    .then(() => onClose())
                }
              >
                Yes, I'm sure
              </button>

              <button
                type="button"
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  handleCheckboxChange = () => {
  }

  render() {
    const { isPending, renewals } = this.props
    const { showDeletedRenewals } = this.state

    const options = {
      showOptions: false,
      columns    : [
        {
          dataField  : 'ref',
          text       : 'Policy ID',
          headerStyle: {
            width: 150,
          },
          formatter: (cell, row) => (
            <Link to={`/direct-sales/policies/${row.id}/policy`}>
              {row.ref}
            </Link>
          ),
        },
        {
          dataField: 'policyholder_name',
          text     : 'Policyholder name',
          formatter: (cell, row) => (
            <Link to={`/direct-sales/policyholders/${row.policyholder.id}/timeline`}>
              {row.policyholder.title} {row.policyholder.first_name} {row.policyholder.last_name}
            </Link>
          ),
        },
        {
          dataField: 'vehicle.vrm',
          text     : 'Vehicle reg',
        },
        {
          dataField: 'cover_level.name',
          text     : 'Cover level',
        },
        {
          dataField: 'end_date',
          text     : 'Expiry date',
          sort     : true,
          sortCaret,
          formatter: (cell, row) => (
            <>{moment(row.end_date).format('DD/MM/YYYY')}</>
          ),
        },
        {
          dataField: '',
          text     : 'Expires',
          sort     : true,
          sortCaret,
          formatter: (cell, row) => {
            let endDate = moment(row.end_date).endOf('day')
            let now = this.state.now
            if (now.isAfter(endDate)) {
              return <>Expired {endDate.fromNow()}</>
            } else {
              return <>{endDate.fromNow()}</>
            }
          },
        },
        {
          dataField: 'pipeline_item.id',
          text     : 'Pipeline lane',
          sort     : true,
          sortCaret,
          formatter: (cell, row) => {
            if (row.pipeline_item && row.pipeline_item.lane) {
              return `${row.pipeline_item && row.pipeline_item.lane.name}`
            }

            return 'Unassigned'
          },
        },
        {
          dataField  : 'id',
          text       : 'Assignee',
          hidden     : showDeletedRenewals,
          headerStyle: {
            width: 100,
          },
          formatter  : (cell, row) =>
            row.pipeline_item ? (
              <Avatar
                user={row.pipeline_item.assignee}
                tooltip
                dataTip={`${row.pipeline_item.assignee.first_name} ${row.pipeline_item.assignee.last_name}`}
              />
            ) : hasPermission('direct_sales.renewals.assign') ? (
              <>
                <div
                  data-tip="Assign to agent"
                  className="wf-icon-assign"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.showAssignModal(row)}
                />
              </>
            ) : (
              <></>
            ),
        },
        {
          dataField  : 'id',
          text       : 'Action',
          hidden     : !showDeletedRenewals,
          headerStyle: {
            width: 100,
          },
          formatter  : (cell, row) => {
            return (
              <>
                <span
                  data-tip="Restore"
                  data-place="top"
                  className="table-action-btn"
                  onClick={() => this.showRestoreModal(row)}
                  size="sm"
                >
                  <span
                    className="wf-icon-restore d-inline-block"
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                </span>
                <ReactTooltip effect="solid"/>
              </>
            )
          },
        },
      ],
      selectRow  : {
        mode             : 'checkbox',
        clickToSelect    : false,
        selected         : this.props.selectedRows,
        onSelect         : this.handleRowSelect,
        onSelectAll      : this.handleRowSelectAll,
        selectionRenderer: ({ mode, rowIndex, ...rest }) => {
          return (
            <div className="text-center lh-1 position-relative align-checkbox">
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  className="custom-control-input"
                  type={mode}
                  {...rest}
                  rowindex={rowIndex}
                  onChange={e => this.handleCheckboxChange(e, rowIndex)}
                  style={{ width: 10, height: 10 }}
                  id={`checkbox-${rowIndex}`}
                />
                <label
                  htmlFor={`checkbox-${rowIndex}`}
                  className="custom-control-label"
                  style={{ pointerEvents: 'none' }}
                />
              </div>
            </div>
          )
        },

        selectionHeaderRenderer: ({ mode, checked, indeterminate }) => {
          return (
            <div className="text-center lh-1 position-relative align-checkbox">
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  className="custom-control-input"
                  type={mode}
                  checked={checked}
                  onChange={this.handleCheckboxChange}
                />
                <label className="custom-control-label"/>
              </div>
            </div>
          )
        },
      },
    }

    return (
      <Fragment>
        <br/>
        <div
          className="container-fluid animated fadeIn"
          style={{ marginBottom: '5rem' }}
        >
          <PageHeader
            title="Renewals"
            description="Assign single or multiple renewals to your team from the table below. You’ll be able to see all assigned renewals in your team pipeline."
          />
          <br/>
          {renewals && (
            <Table
              resource={renewals}
              selected={this.props.selectedRows}
              selectRow={options.selectRow}
              loading={isPending}
              showOptions={options.showOptions}
              handleTableChange={this.handleTableChange}
              columns={options.columns}
              dispatch={this.props.dispatch}
              omnibox={{
                groups: this.state.filterGroups,
              }}
              hasTableSearch={true}
              searching={false}
              onSearch={this.handleSearch}
              tableActions={[
                {
                  iconClass: 'wf-icon-assign wf-icon-assign--table-action font-lg',
                  callback : () => this.showAssignModal(false),
                  isVisible: hasPermission('direct_sales.renewals.assign') && !showDeletedRenewals,
                  tooltip  : {
                    text : 'Assign to agent',
                    place: 'top',
                  },
                },
                {
                  iconClass: 'wf-icon-delete text-danger',
                  callback : this.showDeleteModal,
                  isVisible: hasPermission('direct_sales.renewals.delete') && !showDeletedRenewals,
                  tooltip  : {
                    text : 'Delete',
                    place: 'top',
                  },
                },
                {
                  iconClass: 'wf-icon-restore text-success',
                  callback : this.showRestoreModal,
                  isVisible: hasPermission('direct_sales.renewals.restore') && showDeletedRenewals,
                  tooltip  : {
                    text : 'Restore',
                    place: 'top',
                  },
                },
              ]}
              minSelection={0}
              columnToggle={true}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRenewals,
      dispatch,
      renewalsTableRowAdd,
      renewalsTableRowRemove,
      deleteRenewals,
      restoreRenewals,
      assignRenewal,
    },
    dispatch,
  )

export default connect(
  state => ({
    isPending   : state.directSales.renewals.isPending,
    renewals    : state.directSales.renewals.data,
    selectedRows: state.directSales.renewals.selectedRows,
  }),
  mapDispatchToProps,
)(RenewalsPage)
