import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import api from '../../../../utils/api';
import { hasPermission } from '../../../../utils/auth';
import { echo } from '../../../../utils/misc';
import ContactDetails from './ContactDetails';

const AccountDetails = () => {

  const [policyholder, setDealer] = useState(null)

  const fetchPolicyholder = () => {
    api
      .get(`/policyholder-portal/account-details`)
      .then((res) => {
        setDealer(res.data)
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing'
          )
        }
      })
  }

  useEffect(() => {
    fetchPolicyholder()
  }, [])

  return (

    <div className="container" style={{ maxWidth: '600px' }}>

      <header className="py-4 d-flex align-items-center justify-content-between">
        <div>
          <div className="d-flex">
            <h3 style={{ fontSize: 36 }}>
              Your account
            </h3>
          </div>
        </div>
      </header>

      {policyholder && <div className="row">
        <div className="col-12">

          <h6 className="mt-4 mb-4 text-uppercase">Your Information</h6>

          <div className="row d-flex flex-start">
            <div className="col-12">
              <span className="label">Title</span>
              <p className="font-md">{echo(policyholder.title)}</p>
            </div>
            <div className="col-6">
              <span className="label">First name</span>
              <p className="font-md">{echo(policyholder.first_name)}</p>
            </div>
            <div className="col-6">
              <span className="label">Last name</span>
              <p className="font-md">{echo(policyholder.last_name)}</p>
            </div>
          </div>

          <div className="pt-1">
            <hr className="mt-4" style={{ height: 20 }} />
          </div>

          <div className="row">
            <div className="col mb-5">
              <ContactDetails
                policyholder={policyholder}
                allowEdit={hasPermission('policyholder_portal.account_details')}
                refresh={fetchPolicyholder}
              />
            </div>
          </div>

        </div>
      </div>}
    </div>
  )

}

export default AccountDetails
