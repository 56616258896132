import React from 'react'
import { Field } from 'redux-form'
import renderField from '../../../utils/renderField'
import CustomSelect from '../CustomSelect'
import { uniqueId } from 'lodash'
import { Link } from 'react-router-dom'
import { numericality } from 'redux-form-validators'

const renderLabourRates = ({ fields, meta: { error, submitFailed }, coverLevels, deleteRow = false}) => {
  return <>
    {fields.map((field, index) => <tr key={index}>
      <td>
        <Field
          name={`${field}.title`}
          type="text"
          append={{
            direction: 'left',
            text     : '£',
            size     : 'sm',
          }}
          component={renderField}
          showSuccessValidation={false}
          validate={[numericality({
            int: true,
          })]}
        />

      </td>
      <td>
        <div className="pricing-rule-input">
          <Field
            component={CustomSelect}
            placeholder="+ / -"
            name={`${field}.effect`}
            size="sm"
            options={[
              {
                operator: '+',
                value   : '+',
              },
              {
                operator: '-',
                value   : '-',
              },
            ]}
            getOptionLabel={o => o.operator}
            getOptionValue={o => o.value}
            showSuccessValidation={false}
          />
        </div>
        <div className="pricing-rule-input" style={{ width: 80 }}>
          <Field
            name={`${field}.value`}
            type="text"
            className="form-control"
            component={renderField}
            placeholder="Value"
            showSuccessValidation={false}
          />
        </div>
        <div className="pricing-rule-input">
          <Field
            component={CustomSelect}
            placeholder="% / £"
            name={`${field}.cost_type`}
            size="sm"
            options={[
              {
                operator: '£',
                value   : '£',
              },
              {
                operator: '%',
                value   : '%',
              },
            ]}
            getOptionLabel={o => o.operator}
            getOptionValue={o => o.value}
            showSuccessValidation={false}
          />
        </div>
      </td>
      <td width="355px" className="price-rule-coverlevel">
        <div>
          {coverLevels && coverLevels.map((cover, key) => {
            return <Field
              key={key}
              type="checkbox"
              label={cover.name}
              name={`${field}.cover_levels.${cover.id}`}
              component={renderField}
              id={`lr_${index}_${cover.id}`}
            />
          })}
        </div>
      </td>
      <td className="sans-label" style={{ paddingTop: 10 }}>
        <Field
          type="radio"
          label=""
          component={renderField}
          name={`labour_rate_default`}
          value={index}
          id={uniqueId()}
        />
      </td>
      <td className="sans-label">
        <Field
          type="checkbox"
          label=""
          component={renderField}
          id={uniqueId(`${field}.active`)}
          name={`${field}.active`}
        />
      </td>
      {deleteRow && <td>
        <Field
          type="checkbox"
          label=""
          component={renderField}
          id={uniqueId(`${field}.delete`)}
          name={`${field}.delete`}
        />
      </td>}
    </tr>)}
    <tr>
      <td colSpan={deleteRow ? 6 : 5}>
        <Link to={'#'} onClick={(e) => {e.preventDefault(); fields.push({})}}>
          Add labour rate
        </Link>
        {submitFailed && error && <span className="invalid-feedback">{error}</span>}
      </td>
    </tr>
  </>
}

export default renderLabourRates
