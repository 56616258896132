import React, { Component, Fragment } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import PageHeader from '../../../ui/PageHeader'
import { sortCaret } from '../../../../utils/misc'
import Table from '../../../ui/Table'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import api from "../../../../utils/api";
import { NotificationManager } from "react-notifications";

class Garages extends Component {
  state = {
    filterGroups: [
      {
        name        : 'status',
        allowOnlyOne: true,
        filters     : [
          { id: 5, name: 'Active', value: 'Active' },
          { id: 4, name: 'In review', value: 'In Review' },
          { id: 6, name: 'Blacklisted', value: 'Blacklisted' },
        ],
      },
    ],
    query: '',
    page: 1,
    desc: false,
    limit: 10,
    order: '',
    account_manager: '',
    garages: null,
    searching: true,
  }

  fetchData = url => {
    api.get(`/external-sales/garages${url ? url : ''}`)
      .then(res => {
        this.setState({ garages: res.data, searching: false })

      })
      .catch(err => {
        if (err) {
          NotificationManager.error('Oops! Something went wrong. Please try again after refreshing.')
        }
      })
  }

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    let url = `?page=${page}&desc=${
      sortOrder === 'desc' ? true : false
    }&limit=${sizePerPage}&query=${this.state.query}`
    if (sortField) url = `${url}&order=${sortField}`

    this.fetchData(url)
  }

  handleSearch = (query, filters) => {
    let status = ''

    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]

      if (filter.group_name === 'status') status = filter.name
    }
    this.setState(
      {
        query,
        status,
      },
      this.buildUrl,
    )
  }

  /**
   * Builds URL and to fetch policies
   */
  buildUrl = () => {
    const { query, page, desc, limit, status } = this.state

    let url = `?query=${query}&page=${page}&desc=${desc}&limit=${limit}`

    if (status !== '') url = `${url}&status=${status}`

    this.setState({ searching: true })
    this.fetchData(url)
  }

  componentDidMount() {
    this.fetchData()
  }


  render() {
    const options = {
      showOptions: false,
      columns: [
        {
          dataField: 'name',
          text: 'Garage name',
        },
        {
          dataField: 'dealers.name',
          text: 'Linked to dealer(s)',
          formatter: (cell, row) => {
            if (row.dealers && row.dealers.length > 0) {
              return row.dealers.map((dealer) => (<Link to={`/claims/dealers/${dealer.id}/timeline`}>{dealer.name}</Link>)).reduce((p, c) => (p === null ? [c] : [...p, ', ', c]), null)
            }
          }
        },
        {
          dataField: 'phone',
          text: 'Telephone',
          formatter: (cell, row) => {
            if (row.tel_office) {
              return row.tel_office
            } else {
              return row.tel_mobile
            }
        }
        },
        {
          dataField: 'email',
          text: 'Email',
        },
        {
          dataField: 'status',
          text: 'Status',
          sort: true,
          sortCaret,
        },

        {
          dataField: 'id',
          text: 'Actions',
          headerStyle: {
            width: 100,
          },
          formatter: (cell, row) => (
            <div className="d-flex align-items-center" key={row.id}>
              <Link
                data-tip="Edit garage"
                data-place="top"
                to={`#`}
                className="table-action-btn"
                primary="true"
                size="sm"
              >
                <span className="wf-icon-edit"/>
              </Link>
              <Link
                data-tip="Delete garage"
                data-place="top"
                to={`#`}
                className="table-action-btn table-action-btn--delete"
                size="sm"
              >
                <span className="d-block wf-icon-delete"/>
              </Link>
              <ReactTooltip effect="solid"/>
            </div>
          ),
        },
      ],
    }

    return (
      <Fragment>
        <br/>
        <div className="container-fluid animated fadeIn">
          <PageHeader
            title="Garages"
            description="View all your garages below. You can link a garage to a dealer when you add a new garage or edit an existing garage."
            buttonLink={'#'}
            buttonText="Add a garage"
            buttonIcon="d-block wf-icon-add"
          />
          <br/>
          {this.state.garages && (
            <Table
              resource={this.state.garages}
              showOptions={options.showOptions}
              columns={options.columns}
              omnibox={{ groups: this.state.filterGroups }}
              hasTableSearch={true}
              searching={this.state.searching}
              handleTableChange={this.handleTableChange}
              onSearch={this.handleSearch}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

export default Garages
