import React, { useRef, useState } from 'react'
import DirectDebitImage from '../../../images/direct-debit.png'
import { echo, formatDate } from '../../../utils/misc';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import api from '../../../utils/api';

export const NewInstruction = ({wfSettings, createURL}) => {

  const [newMandate, setNewMandate] = useState({
    account_name: '',
    account_number: '',
    sort_code: '',
    bank_found: false,
    bank_address: '',
    account_allows_direct_debits: false,
    agreed: false,
  })
  const [formError, setFormError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const bottomAnchor = useRef(null)

  const wfAddress = () => {
    const a = wfSettings.invoice
    return [a.address1, a.address2, a.city, a.county, a.postcode].filter(Boolean).join(', ')
  }

  const bankAddress = () => {
    return newMandate.bank_address.length ? newMandate.bank_address : 'Please enter your bank account details (above)'
  }

  const doModulusCheck = () => {

    setNewMandate(o => {return {...o, bank_found: false, account_allows_direct_debits: false}})
    setFormError('')

    if (validateForm(true, false)) {
      setNewMandate(o => {return {...o, bank_address: 'Please wait...'}})

      api
        .get(`/misc/bank-account-modulus-check?sort_code=${newMandate.sort_code}&account_number=${newMandate.account_number}`)
        .then((res) => {
          if (res.data.bank_name) {
            let address = [
              res.data.bank_name,
              res.data.bank_address1,
              res.data.bank_address2,
              res.data.bank_address3,
              res.data.bank_address4,
              res.data.bank_addressTown,
              res.data.bank_addressPostcode,
            ].filter(Boolean).join(', ')
            setNewMandate(o => {return {...o, bank_address: address, bank_found: true, account_allows_direct_debits: res.data.direct_debits}})
            if (!res.data.direct_debits) {
              setFormError('This bank account does not allow direct debits')
            }
          } else {
            setNewMandate(o => {return {...o, bank_address: 'Bank not found' }})
          }
        })
        .catch((err) => {
          setNewMandate(o => {return {...o, bank_address: ''}}) // show placeholder text again
          NotificationManager.error('Oops! Something went wrong. Please try again after refreshing')
        })
    }
  }

  const validateForm = (quiet = false, mustAgree = true) => {

    if (!newMandate.account_name) {
      if (!quiet) {
        setFormError('Please enter the name of the account holder');
      }
      return false;
    }

    if (!newMandate.account_number) {
      if (!quiet) {
        setFormError('Please enter the account number');
      }
      return false;
    }
    newMandate.account_number = newMandate.account_number.replaceAll(' ', '').replaceAll('-', '').trim();
    if (newMandate.account_number.length !== 8) {
      if (!quiet) {
        setFormError('The account number must be 8 digits long');
      }
      return false;
    }
    if (!newMandate.account_number.match(/^[0-9]+$/)) {
      if (!quiet) {
        setFormError('The account number must only contain digits 0-9');
      }
      return false;
    }

    if (!newMandate.sort_code) {
      if (!quiet) {
        setFormError('Please enter the sort code');
      }
      return false;
    }
    newMandate.sort_code = newMandate.sort_code.replaceAll(' ', '').replaceAll('-', '').trim();
    if (newMandate.sort_code.length !== 6) {
      if (!quiet) {
        setFormError('The sort code must be 6 digits long');
      }
      return false;
    }
    if (!newMandate.sort_code.match(/^[0-9]+$/)) {
      if (!quiet) {
        setFormError('The sort code must only contain digits 0-9');
      }
      return false;
    }

    if (mustAgree && !newMandate.agreed) {
      if (!quiet) {
        setFormError('You must agree to the terms before submitting your application');
      }
      return false;
    }

    setFormError(null);
    return true;
  }

  const scrollToBottom = () => {
    // without a slight delay it doesn't work (a threading issue perhaps?!?)
    setTimeout(function() {
        bottomAnchor.current.scrollIntoView({ behavior: 'smooth' });
    }, 1);
  }

  const handleSubmit = () => {

    setIsSubmitting(true);

    if (!validateForm()) {
        scrollToBottom();
        setIsSubmitting(false);
        return;
    }

    // create the new direct debit mandate
    api
      .post(createURL, {
        sort_code: newMandate.sort_code,
        account_number: newMandate.account_number,
        account_name: newMandate.account_name,
      }).then((res) => {
        NotificationManager.success('Woohoo! Your debit debit mandate has been submitted.')
        // show the new mandate on-screen now
        window.location.reload(false)
      })
      .catch((err) => {
        NotificationManager.error('Oops! Something went wrong. Please try again after refreshing')
        setIsSubmitting(false);
      })
  }

  return wfSettings && (
    <div className="direct-debit-mandate mt-5">
      <div className="row">
        <div className="col-12 text-right">
          <img src={DirectDebitImage} className="direct-debit-logo" alt="Direct Debit Logo" />
          <div className='direct-debit-logo'></div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 mt-3">
          <h5>Warranty First</h5>
          <p className="font-md">{wfAddress()}</p>
        </div>
        <div className="col-6 mt-3">
          <h5>Instruction to your bank or building society to pay by Direct Debit</h5>
        </div>
        <div className="col-6 mt-3">
          <span className="label">Name of account holder</span>
          <input type="text" name="account_name" className="form-control"
            onChange={(e) => {
              newMandate.account_name = e.target.value.trim()
              setFormError('')
            } }
            onBlur={() => doModulusCheck()}
          />
        </div>
        <div className="col-6 mt-3">
          <span className="label">Service User Number</span>
          <h2 className='sun'>{echo(wfSettings.direct_debit.sun.number)}</h2>
        </div>
        <div className="col-6 mt-3">
          <span className="label">Bank/Building Society account number</span>
          <input type="text" name="account_number" className="form-control"
            onChange={(e) => {
              newMandate.account_number = e.target.value.trim()
              setFormError('')
            } }
            onBlur={() => doModulusCheck()}
          />
        </div>
        <div className="col-6 mt-3">
          <span className="label">Reference</span>
          <p className="font-md" style={{paddingTop:'12px'}}>(Please leave blank)</p>
        </div>
        <div className="col-6 mt-3">
          <span className="label">Branch sort code</span>
          <input type="text" name="sort_code" className="form-control"
            onChange={(e) => {
              newMandate.sort_code = e.target.value.trim()
              setFormError('')
            } }
            onBlur={() => doModulusCheck()}
          />
        </div>
        <div className="col-6 mt-3">
          <span className="label">Instruction to your Bank or Building Society</span>
          <p className='mb-0 instruction'>Please pay Warranty First Direct Debits from the account detailed in this Instruction subject to the safeguards assured by the
          Direct Debit Guarantee. I understand that this instruction may remain with Warranty First and, if so, details will be passed
          electronically to my bank/building society.</p>
        </div>
        <div className="col-6 mt-3">
          <span className="label">Name and full postal address of your<br/> Bank/Building Society</span>
          <p className={`font-md mb-0  ${bankAddress() === 'Please wait...' ? 'text-red' : ''}`}>
            {bankAddress()}
          </p>
        </div>
        <div className="col-6 mt-3 pt-4">
          <span className="label">Date</span>
          <p className="font-md mb-0">{formatDate(moment().startOf('day'))}</p>
        </div>

      </div>

      <hr className="mt-5 mb-5"/>

      <p className="font-md mt-5">
        Your first payment via direct debit will be made no earlier than {echo(wfSettings.direct_debit.advance_notice_days)} working days after you submit this application.
        Subsequent payments will be taken {echo(wfSettings.direct_debit.advance_notice_days)} working days after an invoice has been raised against your account.
      </p>
      <p className="font-md">
        Invoices will continue be sent to you for information purposes only.
      </p>

      <hr className="mt-5 mb-5"/>

      <h4>The Direct Debit Guarantee</h4>
      <ul className='direct-debit-guarantee'>
        <li>This Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits.</li>
        <li>If there are any changes to the amount, date or frequency of your Direct Debit Warranty First will notify you {echo(wfSettings.direct_debit.advance_notice_days)} working days in advance of your account being debited or as otherwise agreed.
            If you request Warranty First to collect a payment, confirmation of the amount and date will be given to you at the time of the request.</li>
        <li>If an error is made in the payment of your Direct Debit, by Warranty First or your bank or building society, you are entitled to a full and immediate refund of the amount paid from your bank or building society
            – if you receive a refund you are not entitled to, you must pay it back when Warranty First asks you to.</li>
        <li>You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation may be required. Please also notify us.</li>
      </ul>

      <hr className="mt-5 mb-5"/>

      {formError &&
      <div className="row mb-3">
        <div className="col">
          <p className="mandate-error">{formError}</p>
        </div>
      </div>
      }

      <div className="row mb-5">
        <div className="col">

          <div className="custom-control custom-checkbox custom-control-inline">
            <input name="agreed" id="agreed" className="custom-control-input" type="checkbox" onChange={(e) => {
              newMandate.agreed = !newMandate.agreed
              setFormError('')
            } } />
            <label className="custom-control-label font-weight-normal undefined" htmlFor="agreed">I agree to the above terms</label>
          </div>
        </div>
        <div className="col-6 text-right">
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={!newMandate.bank_found || !newMandate.account_allows_direct_debits || isSubmitting}
          >
            {isSubmitting ? 'Please wait...' : 'Submit application'}
          </button>
        </div>
      </div>

      <div ref={bottomAnchor} />

    </div>
  )
}

export default NewInstruction
