import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../../../../utils/api'
import { useParams } from 'react-router-dom'
import { history } from '../../../../../utils/history'
import VehicleDetails from './VehicleDetails'
import PolicyDetails from './PolicyDetails'
import Downloads from './Downloads';

const Policy = (props) => {
  const params = useParams()
  const [policy, setPolicy] = useState(null)

  const fetchPolicy = () => {
    api
      .get('/policyholder-portal/policies/' + params.id)
      .then((res) => {
        setPolicy(res.data)
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 404) {
          history.push('/404')
        }
      })
  }

  useEffect(() => {
    fetchPolicy();
  }, [])

  return (
    policy && (
      <div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dealer-portal/policies" className="open active">
              Your policies
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">Policy {policy.ref}</span>
          </li>
        </ol>

        <div className="container" style={{ maxWidth: '600px' }}>
          <header className="py-4 d-flex align-items-center justify-content-between border-bottom">
            <div className="row">
              <div className="col-10">
                <h3 style={{ fontSize: 36 }}>
                    Policy {policy.ref}
                </h3>
              </div>
              <div className="col-2 text-right mt-1">
                {policy.status === 'Rejected' &&
                  <span className="badge badge-blacklisted badge-pill font-sm py-2 px-3 ml-3">
                    {policy.status}
                  </span>
                }
                {policy.status !== 'Rejected' &&
                  <span className="badge badge-primary badge-pill font-sm py-2 px-3 ml-3">
                    {policy.status}
                  </span>
                }
              </div>
            </div>
          </header>

          <div className="row">
            <div className="col-12 pt-5 mb-2">
              <VehicleDetails vehicle={policy.vehicle} endpoint={`/policyholder-portal/policies/${policy.id}/vehicle`} />
            </div>
            <div className="col-12">
              <Downloads policy={policy} />
            </div>
            <div className="col-12 mb-5">
              <PolicyDetails policy={policy} />
            </div>
          </div>
        </div>
      </div>
    )
  )
}

Policy.propTypes = {}

export default Policy
