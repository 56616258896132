import React, { useState } from 'react'
import { Field, formValueSelector, reduxForm, SubmissionError } from 'redux-form'
import CustomSelect from '../../../../ui/CustomSelect'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import useModal from '../../../../../hooks/useModal'
import Modal from '../../../../modals/Modal'
import CustomAsyncSelect from '../../../../ui/CustomAsyncSelect'
import { echo, formatDate, prettyNumber } from '../../../../../utils/misc'
import { required } from 'redux-form-validators'
import moment from 'moment'
import api from '../../../../../utils/api'
import { NotificationManager } from 'react-notifications'
import { history } from '../../../../../utils/history'

const StepThree = ({ handleSubmit, valid, setStep, fetchGarages, policy, fault_date, current_mileage, prediagnosis, loggedInPerson }) => {
  const { toggle, isShowing } = useModal()
  const [claim, setClaim] = useState(null)

  const getOptionLabel = o => {
    const address = [o.address_1, o.address_2, o.address_3, o.postcode].filter(Boolean)
      .join(', ')

    return `${o.name} - ${address}`
  }

  const onSubmit = values => {
    const data = {
      policy_id: values.policy_id.id,
      fault_date: moment(values.fault_date).format('YYYY-MM-DD'),
      mileage: values.current_mileage,
      prediagnosis: values.prediagnosis,
      status: values.status.value,
      comments: values.comments,
      items: [],
    }

    if (values.hasOwnProperty('garage_id') && values.garage_id) {
      data.garage_id = values.garage_id.id
    }

    return api.post('/claims/claims', data).then((res) => {
      // toggle()
      setClaim(res.data)
      NotificationManager.success('Woohoo! You created a claim.')
      history.push('/claims/claims')
    })
      .catch(err => {
        setClaim(null)
        if (err && err.response.status === 422) {
          const errors = err.response.data.errors

          for (const field of Object.keys(errors)) {
            NotificationManager.error(errors[field][0])
          }

          throw new SubmissionError({
            ...errors,
            _error: err.response.data.message,
          })
        }
      })
  }

  const toggleWithRedirect = () => {
    toggle()
    if (claim) {
      history.push('/claims/claims/' + claim.id)
    }
  }
  return (
    <div className="mx-auto pb-5" style={{ maxWidth: 790 }}>
      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-10">
                <div className="mb-4">
                  <h6 className="text-uppercase">Fault summary</h6>
                  <p>"{prediagnosis}"</p>
                </div>
                <hr/>
              </div>
            </div>

            <div className="row">
              <div className="col my-4">
                <h6 className="text-uppercase">Claim details</h6>
              </div>
            </div>

            <div className="row">
              <div className="col-10">
                <Field
                  component={CustomAsyncSelect}
                  label="Repairing garage"
                  placeholder="Search by garage name or address"
                  name={`garage_id`}
                  loadOptions={fetchGarages}
                  isClearable={true}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={o => o.id}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-10">
                <Field
                  component={CustomSelect}
                  label="Claim status"
                  name={`status`}
                  options={[
                    { value: 'New claim' },
                    { value: 'Awaiting approval' },
                    { value: 'Awaiting information' },
                    { value: 'Assessor appointed' },
                    { value: 'Diagnosis requested' },
                    { value: 'Awaiting underwriter decision' },
                    { value: 'Reviewed by underwriters' },
                    { value: 'Awaiting liability acceptance' },
                    { value: 'Approved' },
                    { value: 'Payment docs not received' },
                    { value: 'Awaiting Liability Payment' },
                    { value: 'Liability Paid' },
                    { value: 'Approved not paid' },
                    { value: 'Approved paid' },
                    { value: 'No comms within 14 days' },
                    { value: 'Parts not covered' },
                    { value: 'Inherent Fault' },
                    { value: 'Rejected' },
                    { value: 'Cancelled' },
                    { value: 'Closed expired MOT' },
                    { value: 'Closed expired service' },
                  ]}
                  isClearable={true}
                  getOptionLabel={o => o.value}
                  getOptionValue={o => o.value}
                  showSuccessValidation={false}
                  validate={[required({ msg: 'You must select the claim status' })]}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <button
                    className="btn btn-secondary"
                    type="submit"
                    disabled={!valid}
                  >
                    Create claim
                  </button>
                </div>
              </div>
            </div>

          </div>

          {policy && <div className="col-4">
            <div className="row">
              <div className="col">
                <h6 className="mb-4 text-uppercase">Details summary</h6>
              </div>
            </div>
            <div
              className="bg-white shadow-light position-relative"
              style={{ padding: '30px' }}
            >
              <i
                className="wf-icon-edit position-absolute cursor-pointer"
                style={{ top: 12, right: 12 }}
                onClick={() => setStep(1)}
              />
              <span className="label d-block">Policy details</span>
              <p className="mb-0">{policy.ref}</p>
              <p>{policy.policyholder.title} {policy.policyholder.first_name} {policy.policyholder.last_name}</p>

              <span className="label d-block">Cover level</span>
              {policy.cover_level &&
              <p className="font-md">{echo(policy.cover_level.name)}, {echo(policy.duration)}</p>}

              <span className="label d-block">Purchased from</span>
              <p className="mb-0">{policy.purchased_from}</p>
            </div>

            <div
              className="bg-white shadow-light position-relative mt-3"
              style={{ padding: '30px' }}
            >
              <i
                className="wf-icon-edit position-absolute cursor-pointer"
                style={{ top: 12, right: 12 }}
                onClick={() => setStep(2)}
              />
              <span className="label d-block">Fault found on</span>
              <p>{formatDate(fault_date)}</p>

              <span className="label d-block">Current accurate mileage</span>
              <p>{prettyNumber(current_mileage)}</p>
            </div>
          </div>}
        </div>
      </form>

      <Modal
        hide={toggle}
        isShowing={isShowing}
        style={{ width: 500 }}
        title="😇 Before you go..."
        subtitle="Would you like to send the policyholder an automated confirmation email, letting them know their claim has been opened?"
      >
        <div className="mb-4">
          <button onClick={toggleWithRedirect} className="btn btn-primary btn-sm mr-3" style={{ padding: 10 }}>Send standard email
          </button>
          <button onClick={toggleWithRedirect} className="btn btn-secondary btn-sm" style={{ padding: 10 }}>Send Euro cover email
          </button>
        </div>

        <span className="text-blue cursor-pointer" onClick={toggleWithRedirect}>No, I'll email them myself</span>
      </Modal>
    </div>
  )
}

StepThree.propTypes = {
  setStep: PropTypes.func.isRequired,
  fetchGarages: PropTypes.func.isRequired,
  prediagnosis: PropTypes.string.isRequired,
}

const selector = formValueSelector('claims/create')

export default connect(
  state => ({
    loggedInPerson: state.authentication.user,
    fault_date: selector(state, 'fault_date'),
    prediagnosis: selector(state, 'prediagnosis'),
    current_mileage: selector(state, 'current_mileage')
  }),
  null,
)(
  reduxForm({
    form: 'claims/create',
    destroyOnUnmount: false,
  })(StepThree),
)