import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getFormValues, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import Switch from 'react-switch'
import ProductTileSnapshot from './ProductTileSnapshot'

const StepPolicy = props => {
  const { handleSubmit, onSubmit, prevStep, plans, data, payNow, setPayNow, selectCoverLevel } = props

  useEffect(() => {
    // all the way up
    window.scroll(0, 0)

  }, [])

  return (
    data && (
      <div className="mt-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-auto mb-5" style={{ maxWidth: '600px' }}>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h6 className="mb-0 text-uppercase">Vehicle details</h6>
              <Link
                to="#"
                className="font-md text-secondary font-weight-bold"
                onClick={prevStep}
              >
                Edit
              </Link>
            </div>

            <table className="table table-striped table-bordered mt-4">
              <tbody>
              <tr>
                <td className="fw-600">Vehicle reg.</td>
                <td>{data.VRM}</td>
              </tr>
              <tr>
                <td className="fw-600">Make</td>
                <td>{data.Make}</td>
              </tr>
              <tr>
                <td className="fw-600">Model</td>
                <td>{data.Model}</td>
              </tr>
              </tbody>
            </table>
          </div>

          {payNow !== undefined && <div className="bg-grey py-5 text-center mx-auto">
            <div className="mx-auto mb-5" style={{ maxWidth: '475px' }}>
              <h2>This vehicle qualifies for the warranty plans below</h2>
            </div>

            <div
              className="d-flex mx-auto align-items-center justify-content-between text-uppercase mb-4 payment-toggle-wrapper"
              style={{ maxWidth: '285px' }}
            >
              <span className={`fw-600 ${!payNow && 'text-blue'}`}>
                Pay monthly
              </span>
              <div className="custom-switch pl-0">
                <Switch
                  onChange={() => setPayNow(!payNow)}
                  checked={payNow}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  offColor="#1673FF"
                  onColor="#1673FF"
                  id="paymentType"
                  activeBoxShadow={false}
                  handleDiameter={24}
                  width={64}
                  height={32}
                />
              </div>
              <span className={`fw-600 ${payNow && 'text-blue'}`}>
                Pay now
              </span>
            </div>

            {plans &&
            <div className="container-fluid">
              <div className="product-cards mx-auto products mt-5">
                <div className="row no-gutters justify-content-center">
                {Object.keys(plans).map(function(key) {
                  return (
                    <>
                      {/* include the 'relative' class for all tiles except the first and last tile */}
                      <div className={`col-lg-4 card ${key.toLocaleLowerCase()} align-self-start
                        ${key === 'Premier' ? 'most-popular' : ''}
                        ${key !== Object.keys(plans)[0] && key !== Object.keys(plans)[Object.keys(plans).length-1] ? 'position-relative' : ''}
                      `}>
                        {key === 'Premier' &&
                        <div className="most-popular-tag position-absolute ml-4">
                          Most popular
                        </div>
                        }
                        <ProductTileSnapshot
                          title={`${key} cover`}
                          description={'Our value plan provides cover for your vehicle at a great price.'}
                          price={plans[key].monthly_amt}
                          meta={plans[key].meta}
                          select={selectCoverLevel}
                          unmetCriteriaMessage={plans[key].meta.msg}
                          payNow={payNow}
                          coverLevel={plans[key].cover_level}
                        />
                      </div>
                    </>
                  )
                })}
                </div>
              </div>
            </div>
            }
          </div>}
        </form>
      </div>
    )
  )
}

StepPolicy.propTypes = {
  handleSubmit    : PropTypes.func.isRequired,
  onSubmit        : PropTypes.func.isRequired,
  prevStep        : PropTypes.func.isRequired,
  selectCoverLevel: PropTypes.func.isRequired,
  lastStepVisited : PropTypes.func.isRequired,
  payNow          : PropTypes.bool.isRequired,
  setPayNow       : PropTypes.func.isRequired,
}

const validate = values => {
  let errors = {}

  return errors
}

export default connect(
  state => ({
    values: getFormValues('get-quote')(state),
  }),
  null,
)(
  reduxForm({
    form            : 'get-quote',
    destroyOnUnmount: false,
    validate,
  })(StepPolicy),
)
