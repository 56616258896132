import React from 'react'
import { reduxForm, Field, Form } from 'redux-form'
import { fetchPublicCoverLevel } from '../../../actions/cover-levels.actions'
import { connect } from 'react-redux'
import renderField from '../../../utils/renderField'
import PropTypes from 'prop-types'
import CustomSelect from '../../ui/CustomSelect';
import { vehicleTypes } from '../../../utils/misc';

const EditPublicCoverLevelForm = props => {
  const {handleSubmit, onSubmit, cancel, handleDelete} = props
  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
      <h2 className="mb-4">Edit cover level</h2>

      <div className="row">
        <div className="col-md-12">
          <Field
            type="text"
            label="Cover level name"
            component={renderField}
            name="name"
            className="form-control"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 b-form-group">
          <Field
            type="text"
            label="Max. mileage"
            component={renderField}
            name="max_mileage"
            className="form-control"
          />
        </div>
        <div className="col-md-6 b-form-group">
          <Field
            type="text"
            label="Max. Age (years)"
            component={renderField}
            name="max_age"
            className="form-control"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 b-form-group">
          <Field
            type="text"
            label="Max. retail price"
            component={renderField}
            name="max_price"
            className="form-control"
            fieldIcon="wf-icon-gbp"
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <Field
              name="vehicle_types"
              isMulti
              component={CustomSelect}
              label="Vehicle Types"
              options={vehicleTypes}
              getOptionValue={option => option}
              getOptionLabel={option => option}
            />
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col">
          <button disabled={!props.valid} className="btn btn-secondary">
            Save changes
          </button>
          <button
            className="btn btn-outline"
            style={{color: '#70b725'}}
            onClick={cancel}
          >
            Cancel
          </button>
        </div>
        <div className="col d-flex justify-content-end btn-delete">
          <button
            type="button"
            className="btn-delete-resource"
            onClick={() => handleDelete()}
          >
            <span className="icon-trash"/>
          </button>
        </div>
      </div>
    </Form>
  )
}

const validate = values => {
  let errors = {}

  if (!values.name) errors.name = 'Please give this cover level a name.'
  if (values.name && values.name.length < 2)
    errors.name = 'Must be 2 characters or more.'
  if (values.name && values.name.length > 50)
    errors.name = 'Must be 50 characters or less.'

  if (!values.max_mileage) errors.max_mileage = 'Please add a maximum mileage.'
  if (values.max_mileage && isNaN(Number(values.max_mileage)))
    errors.max_mileage = 'Must be a number.'

  if (!values.max_age) errors.max_age = 'Please add a maximum age.'
  if (values.max_age && isNaN(Number(values.max_age)))
    errors.max_age = 'Must be a number.'

  if (!values.max_price) errors.max_price = 'Please add a maximum retail price.'
  if (values.max_price && isNaN(Number(values.max_price)))
    errors.max_price = 'Must be a number.'

  return errors
}

EditPublicCoverLevelForm.propTypes = {
  valid: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
  initialValues: PropTypes.object,
}

export default connect(
  state => ({
    isPending: state.publicCoverLevels.isPending,
    initialValues: state.publicCoverLevels.singleCoverLevel,
  }),
  {fetchPublicCoverLevel},
)(
  reduxForm({
    form: 'public-cover-level/edit',
    enableReinitialize: true,
    validate,
  })(EditPublicCoverLevelForm),
)
