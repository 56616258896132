import React from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { history } from '../../../../../utils/history'
import { connect} from 'react-redux'
import renderField from '../../../../../utils/renderField'
import { numericality, required } from 'redux-form-validators'
import moment from 'moment'

const StepTwo = ({ handleSubmit, onSubmit, valid, fault_date }) => {
  const badDate = fault_date && moment(fault_date).isAfter(moment())

  return (
    <div className="mw-37-5rem mx-auto pb-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h6 className="mt-4 mb-5 text-uppercase">Fault details</h6>

        <div className="row">
          <div className="col">
            <Field
              type="date"
              name="fault_date"
              style={{ width: '100%', marginBottom: 0 }}
              label="When was the fault found?"
              max={moment().format('DD-MM-YYYY')}
              required
              className={`form-control ${badDate ? 'is-invalid' : ''}`}
              id="faultFoundDate"
              component={renderField}
              showSuccessValidation={false}
            />
            {badDate && <div className="invalid-feedback d-block">Fault date cannot be in the future.</div>}
          </div>

          <div className="col">
            <Field
              type="text"
              label="Current accurate mileage"
              component={renderField}
              name={`current_mileage`}
              className="form-control"
              validate={[required({ msg: 'You must enter the current mileage.'}), numericality({
                int: true,
                msg: 'Field must contain only numbers.',
              })]}
              append={{
                direction: 'right',
                text     : 'miles',
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col mt-3">
            <Field
              label="Pre-diagnosis driver comments (will show on the repair diagnosis form)"
              component={renderField}
              className="form-control"
              name="prediagnosis"
              validate={[required({ msg: 'You must enter pre-diagnosis driver comments.'})]}
              type="textarea"
              rows={4}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              label="Admin comments"
              className="form-control"
              name="comments"
              component={renderField}
              type="textarea"
              rows={4}
              placeholder=""
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-4">
            <div className="form-group">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!valid}
              >
                Next step
              </button>
              <button
                type="button"
                className="btn btn-outline btn-form-cancel"
                onClick={() => history.push('/external-sales/policies')}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

StepTwo.propTypes = {}

const selector = formValueSelector('claims/create')

const validate = values => {
  const errors = {}

  let pattern =  /^-?\d+\.?\d*$/

  if (values.current_mileage && !pattern.test(values.current_mileage)) {
    errors.current_mileage = 'Mileage can be only a positive number'
  }


  return errors;
}

export default connect(
  state => ({
    policy: selector(state, 'policy'),
    fault_date: selector(state, 'fault_date'),
  }),
  null,
)(
  reduxForm({
    form            : 'claims/create',
    fields: ['fault_date'],
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(StepTwo),
)
