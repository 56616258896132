import React, { useState, useEffect } from 'react'
import {Link, useParams} from 'react-router-dom'
import { connect } from 'react-redux'
import CreateForm from '../../../../Admin/SpecialVehicles/forms/create'
import api from '../../.././../../../utils/api'
import { history } from '../../../../../../utils/history'
import {
  convertVehicleValues,
  convertValues,
} from '../../../../Admin/SpecialVehicles/forms/helpers'
import {
  updateVehicle
} from './helpers'
import { NotificationManager } from 'react-notifications'
import { reset } from 'redux-form'
import { get } from 'lodash'
import { confirmAlert } from 'react-confirm-alert'

const SpecialVehicleEdit = ({ dispatch, match, ...props }) => {
  const onSubmit = (values) => {
    updateVehicle(dealer.id, vehicle.id, convertValues(values, vehicle.target), match.params.id)
      .then(() => {
        dispatch(reset('special-vehicles/create'))
        history.push(`/external-sales/dealers/${dealer.id}/edit`)
        NotificationManager.success('Woohoo! Your vehicle was saved!')
      })
      .catch((err) => {
        if (
          get(err.response, 'data.errors', false) &&
          Object.keys(get(err.response, 'data.errors', false)).filter((key) =>
            key.startsWith('models.')
          )
        ) {
          let errorString = err.response.data.errors[
          get(err.response, 'data.errors', false) &&
          Object.keys(
            get(err.response, 'data.errors', false)
          ).filter((key) => key.startsWith('models.'))[0]
            ].join(' ')
          confirmAlert({
            title: '🧐 Uh-oh!',
            message: errorString,
            customUI: ({ title, message, onClose }) => {
              return (
                <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                    <span
                      className="btn-close-modal fa fa-close"
                      onClick={onClose}
                    />
                    <h3>{title}</h3>
                    <p>{message}</p>

                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={onClose}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              )
            },
          })
        } else {
          NotificationManager.error(
            'Oops! Something went wrong saving your vehicle. Please try again.'
          )
        }
      })
  }

  const [dealer, setDealer] = useState(null);
  const params = useParams()
  useEffect(() => {
    api
      .get(`/external-sales/dealers/${params.id}`)
      .then((res) => {
        setDealer(res.data)
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing'
          )
        }
      })
  }, [params.id])

  const [vehicle, setVehicle] = useState(null)
  useEffect(() => {
      let vehicleId = params.vehicleId
      api
        .get(`/external-sales/dealers/${params.id}/special-vehicles/${vehicleId}`)
        .then((res) => {
          setVehicle(res.data)
        })
        .catch((err) => {
          if (err.response.status === 404) {
            history.push('/404')
          } else {
            NotificationManager.error(
              'Oops! Something went wrong. Please refresh the page.'
            )
          }
        })
  }, [params.vehicleId])

  const [coverLevels, setCoverLevels] = useState(null)
  useEffect(() => {
    if (vehicle) {
      api.get(`external-sales/dealers/${params.id}/cover-levels`)
        .then((res) => {
          setCoverLevels(res.data)
        })
        .catch((err) => {
          setCoverLevels(null)
          NotificationManager.error(
            'Oops! Something went wrong. Please refresh the page.'
          )
        })
    }
  }, [vehicle])

  return (<>
    {dealer && <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to={`/external-sales/dealers/`}>
            Dealers
          </Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={`/external-sales/dealers/${dealer.id}/edit`} className="open active">
            {dealer.name}
          </Link>
        </li>
        <li className="breadcrumb-item">
          <span className="active">Edit a special vehicle</span>
        </li>
      </ol>

      <div className="container-fluid">
        {vehicle && coverLevels && (
          <CreateForm
            editing={true}
            onSubmit={onSubmit}
            target={vehicle.target}
            onCancelRedirectTo={'/external-sales/dealers/' + dealer.id + '/edit'}
            initialValues={convertVehicleValues(vehicle, coverLevels)}
            vehicle={vehicle}
            dealer={dealer}
          />
        )}
      </div>
    </div>}
    </>
  )
}

export default connect((state) => ({}))(SpecialVehicleEdit)
