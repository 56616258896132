import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, Form, reduxForm } from 'redux-form'
import CustomSelect from '../../../../ui/CustomSelect'
import { hasPermission } from '../../../../../utils/auth'
import { echo } from '../../../../../utils/misc';

const firstStageStatusOptions = [
  { value: 'Awaiting Approval' },
];
const secondStageStatusOptions = [
  { value: 'Rejected' },
  { value: 'Approved' },
  { value: 'Cancelled' },
  { value: 'Transferred' },
  { value: 'Expired' },
];
const defaultStatusOptions = [...firstStageStatusOptions, ...secondStageStatusOptions];

const PolicyStatusForm = ({ handleSubmit, onSubmit, valid, statusText }) => {
  const [status, setStatus] = useState(statusText);

  const statusOptions = secondStageStatusOptions.some(option => option.value === statusText)
    ? [...secondStageStatusOptions]
    : defaultStatusOptions

  if (hasPermission('direct_sales.policies.update*')) return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        component={CustomSelect}
        label="Policy status"
        name={`status`}
        options={statusOptions}
        getOptionValue={(o) => o.value}
        getOptionLabel={(o) => o.value}
        onChange={(o) => setStatus(o.value)}
      />

      {status === 'Cancelled' && (
        <Field
          component={CustomSelect}
          label="Policy sub status"
          name={`sub_status`}
          options={[
            { value: 'Customer Request' },
          ]}
          getOptionValue={(o) => o.value}
          getOptionLabel={(o) => o.value}
        />
      )}

      <div className="row">
        <div className="col-md-12 mt-3">
          <div className="form-group">
            <button
              className="btn btn-secondary"
              type="submit"
              disabled={!valid}
            >
              Save changes
            </button>
            <button
              type="button"
              className="btn btn-outline btn-form-cancel"
              onClick={() => {}}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Form>
  )

  return (
    <>
    <div>
      <span className="label">Policy status</span>
      <p>{echo(statusText)}</p>
    </div>
    </>
  )
}

PolicyStatusForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'policy-status'
})(PolicyStatusForm)
