import api from '../utils/api'
import { NotificationManager } from 'react-notifications'
import { SubmissionError } from 'redux-form'
import { history } from '../utils/history'
import {
  FETCH_TERRITORIES_REQUEST,
  FETCH_TERRITORIES_SUCCESS,
  FETCH_TERRITORIES_FAILURE,
  DELETE_TERRITORY_SUCCESS,
  DELETE_TERRITORY_FAILURE,
  TERRITORIES_TABLE_ROW_ADD,
  TERRITORIES_TABLE_ROW_REMOVE, FETCH_TERRITORY_SUCCESS, UPDATE_TERRITORY_SUCCESS, ADD_ACCOUNT_MANAGER,
} from '../constants'
import { jsDateToIso } from "../utils/misc";

export const fetchTerritoriesRequest = () => ({
  type: FETCH_TERRITORIES_REQUEST,
})

export const fetchTerritoriesSuccess = data => ({
  type: FETCH_TERRITORIES_SUCCESS,
  payload: data,
})

export const fetchTerritoriesFailure = err => ({
  type: FETCH_TERRITORIES_FAILURE,
  payload: err,
})

/**
 * Fetch territories
 */
export const fetchTerritories = url => dispatch => {
  dispatch(fetchTerritoriesRequest())

  api
    .get(`/admin/territories${url ? url : ''}`)
    .then(res => {
      dispatch(fetchTerritoriesSuccess(res.data))
    })
    .catch(err => {
      return dispatch(fetchTerritoriesFailure(err.response))
    })
}

/**
 * Delete a territory by ID
 *
 * @param {integer} id
 * @param {boolean} redirectTo
 */
export const deleteTerritory = (id, redirectTo = false) => dispatch => {
  return api
    .delete(`admin/territories/${id}`)
    .then(res => {
      dispatch({
        type: DELETE_TERRITORY_SUCCESS,
        payload: {
          id,
        },
      })
      NotificationManager.success('Woohoo! You deleted a territory.')
      if (redirectTo) history.push(redirectTo)
    })
    .catch(err => {
      dispatch({
        type: DELETE_TERRITORY_FAILURE,
        payload: {error: err.response},
      })
      NotificationManager.error('Oops! Could not delete the territory.')
    })
}

export const territoriesTableRowAdd = data => ({
  type: TERRITORIES_TABLE_ROW_ADD,
  payload: {data},
})

export const territoriesTableRowRemove = data => ({
  type: TERRITORIES_TABLE_ROW_REMOVE,
  payload: {data},
})


/**
 * Create territory
 *
 * @param {Object} values
 */
export const createTerritory = values => dispatch => {
  if (values.current_account_manager) {
    values.person_id = values.current_account_manager.id
    delete values.current_account_manager
  }

  // Convert date from JS date to string
  values.start_date = jsDateToIso(values.start_date)
  if (values.end_date)
    values.end_date = jsDateToIso(values.end_date)
  else
    delete values.end_date

  return api
    .post('/admin/territories', values)
    .then(res => {
      NotificationManager.success('Woohoo! You added a new territory.')
      history.push('/admin/territories')
    })
    .catch(err => {
      const errors = err.response.data.errors
      throw new SubmissionError({
        ...errors,
        _error: err.response.data.message,
      })
    })
}

const fetchSingleTerritorySuccess = territory => ({
  type: FETCH_TERRITORY_SUCCESS,
  payload: {territory},
})

const fetchSingleTerritoryRequest = () => ({
  type: FETCH_TERRITORIES_REQUEST,
})

// const fetchSingleTerritoryFailure = error => ({
//   type: FETCH_TERRITORIES_FAILURE,
//   payload: {error},
// })

/**
 * Fetch territory
 *
 * @param id
 * @returns {Function}
 */
export const fetchSingleTerritory = id => dispatch => {
  dispatch(fetchSingleTerritoryRequest())

  api.get(`admin/territories/${id}`)
    .then(res => {
      dispatch(fetchSingleTerritorySuccess(res.data))
    })
    .catch(err => {
      history.push('/404')
    })
}

export const updateTerritorySuccess = territory => ({
  type: UPDATE_TERRITORY_SUCCESS,
  payload: {territory},
})

export const updateTerritoryFailure = error => ({
  type: UPDATE_TERRITORY_SUCCESS,
  payload: {error},
})

/**
 * Update territory
 * @param territory
 * @returns {Function}
 */
export const updateTerritory = territory => dispatch => {
  return api.patch(`/admin/territories/${territory.id}`, {name: territory.name})
    .then(res => {
      dispatch(updateTerritorySuccess(territory))
      NotificationManager.success('Woohoo! Your changes were saved.')
    })
    .catch(err => {
      const errors = err.response.data.errors
      dispatch(updateTerritoryFailure(err.response.data.message))
      throw new SubmissionError({
        ...errors,
        _error: err.response.data.message,
      })
    })
}

/**
 * Add account manager to table.
 * @param accountManager
 * @returns {{payload: {accountManager: *}, type: string}}
 */
export const addAccountManager = accountManager => ({
  type: ADD_ACCOUNT_MANAGER,
  payload: {accountManager},
})