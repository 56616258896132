import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { change, Field, reduxForm } from 'redux-form'
import { connect, useDispatch } from 'react-redux'
import renderField from '../../../../../utils/renderField'
import CustomSelect from '../../../../ui/CustomSelect'
import { formatPostcode, validPostcode } from '../../../../../utils/misc'
import { Link } from 'react-router-dom'
import { isEmail } from 'validator'
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { API_URL } from '../../../../../utils/constants';
import api from '../../../../../utils/api';

const EditPolicyholder = props => {
  const { handleSubmit, onSubmit, onCancel, valid, addressFieldVisible, postcode } = props

  const [addressForm, setAddressForm] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [addresses, setAddresses] = useState()
  const dispatch = useDispatch()

  const handleFormatPostcode = (event, value) => {
    event.preventDefault()

    const formatted = formatPostcode(value)
    dispatch(change('dealer-portal/policies', 'postcode', formatted, true))
    dispatch(change('dealer-portal/policies', 'address.postcode', formatted, true))
  }

  const handleLookup = postcode => {
    dispatch(change('dealer-portal/policies', 'address', null, false))
    api
      .get(`${API_URL}/misc/postcode-lookup?postcode=${postcode}&expand=1`)
      .then(res => {
        let data = res.data.addresses.map(row => {

          // if there is no second line of address and no third line of address
          // get the locality as that will be enough given the fact that they will have 1 line of address any way
          let address_2 = (row.line_2.length === 0) ? row.locality : row.line_2
          let address_3 = row.line_3.length === 0 ? row.locality : ''

          return {
            formatted_address: row.formatted_address.filter(r => r).join(', '),
            address_1: row.line_1,
            address_2,
            address_3: (address_2 === address_3) ? '' : address_3,
            city: row.town_or_city,
            county: row.county,
            postcode,
          }
        })

        setAddresses(data)
        dispatch(change('dealer-portal/policies', 'addressFieldVisible', true))
      })
      .catch(() => {
        NotificationManager.error('Oops! Something went wrong. Please try again.')
      })
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="ml-1 row justify-content-between">
          <div className="col-12">

            <div className="row">
              <div className="col-6">
                <Field
                  label="Title"
                  component={CustomSelect}
                  size={'md'}
                  name={`title`}
                  options={[
                    { value: 'Mr' },
                    { value: 'Mrs' },
                    { value: 'Miss' },
                    { value: 'Ms' },
                    { value: 'Doctor' },
                    { value: 'Reverend' },
                  ]}
                  getOptionLabel={(option) => option.value}
                  getOptionValue={(option) => option.value}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <Field
                  label="First name"
                  component={renderField}
                  name={`ph_first_name`}
                  id={'firstName'}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col">
                <Field
                  label="Last name"
                  component={renderField}
                  name={`ph_last_name`}
                  id={'lastName'}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            {!addressForm &&
            <div className="row">
              <div className="col mb-2">
                <Field
                  type="text"
                  label="Postcode lookup"
                  component={renderField}
                  name={`postcode`}
                  className="form-control"
                  button="Lookup address"
                  handleSubmit={handleLookup}
                  onBlur={handleFormatPostcode}
                />
                {!addressFieldVisible && postcode && validPostcode(postcode || '') && <span className="d-block invalid-feedback pull-up">You must lookup the address.</span>}
                <Link
                  to="#"
                  className="mb-2 d-block font-sm"
                  onClick={() => {
                    setAddressForm(true)
                    dispatch(change('dealers/create', '_addressForm', true))
                    dispatch(change('dealers/create', 'addressFieldVisible', false))
                  }}
                >
                  Or enter address manually
                </Link>
              </div>
            </div>
            }

            <div className="row">
              <div className="col">
                <Field
                  label="Full address"
                  component={CustomSelect}
                  name={`ph_address`}
                  id={'address'}
                  options={[
                    {
                      name: '21 Road Place, Milton Keynes, Buckinghamshire',
                      value: 1,
                    },
                    {
                      name: '23 Road Place, Milton Keynes, Buckinghamshire',
                      value: 2,
                    },
                    {
                      name: '24 Road Place, Milton Keynes, Buckinghamshire',
                      value: 3,
                    },
                  ]}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 b-form-group">
                <Field
                  type="text"
                  label="Telephone (mobile)"
                  component={renderField}
                  name={`ph_tel_mobile`}
                  className="form-control"
                />
              </div>
              <div className="col-md-6 b-form-group">
                <Field
                  type="text"
                  label="Telephone (home)"
                  component={renderField}
                  name={`ph_tel_home`}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <Field
                  type="email"
                  label="Email"
                  component={renderField}
                  name={`ph_email`}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="form-group">
                  <button
                    className="btn btn-secondary"
                    type="submit"
                    disabled={!valid}
                  >
                   Save changes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline btn-form-cancel"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

EditPolicyholder.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

const validate = values => {
  let errors = {}

  if (!values.title) {
    errors.title = 'Please select a title.'
  }

  if (!values.ph_first_name) errors.ph_first_name = 'Please enter a first name'
  if (!values.ph_last_name) errors.ph_last_name = 'Please enter a last name.'
  if (!values.ph_postcode) errors.ph_postcode = 'Please enter a postcode.'
  if (!values.ph_address) errors.ph_address = 'Please select an address.'
  if (!values.ph_tel_mobile)
    errors.ph_tel_mobile = 'Please enter a telephone number'
  if (!values.ph_email) errors.ph_email = 'Please enter an email address.'

  if (values.ph_email && !isEmail(values.ph_email)) {
    errors.ph_email = 'The email address doesn\'t quite look right.'
  }

  return errors
}

export default connect()(
  reduxForm({
    form: 'ph-edit',
    destroyOnUnmount: true,
    validate,
  })(EditPolicyholder),
)
