import React, { useState, useEffect } from 'react'
import Avatar from '../../../../ui/Avatar'
import { times } from 'lodash'
import { Field } from 'redux-form'
import renderField from '../../../../../utils/renderField'
import { money } from '../../../../../utils/misc'

const UserRow = ({ user, totalBudget, recalculate }) => {
  const [total, setTotal] = useState(0)
  useEffect(() => {
    let t = user.targets.reduce((a, b) => a + parseFloat(b || 0), 0)
    setTotal(t)
  }, [user.targets])

  return (
    <tr>
      <td>
        <Avatar user={user} /> {user.first_name} {user.last_name}
      </td>
      <td>
        {totalBudget > 0
          ? isNaN((total / totalBudget) * 100)
            ? '0.00'
            : ((total / totalBudget) * 100).toFixed(2)
          : '-'}
        %
      </td>
      <td>{money.format(total)}</td>
      {times(12, (idx) => {
        return (
          <td key={idx}>
            <Field
              type="text"
              label=""
              component={renderField}
              append={{
                direction: 'left',
                text: <i className="wf-icon-gbp" />,
              }}
              name={`users.${user.person_id}.months.${idx}`}
              className="form-control form-control-sm"
              showSuccessValidation={false}
              onBlur={recalculate}
            />
          </td>
        )
      })}
    </tr>
  )
}

export default UserRow
