import React, { useEffect, useState } from 'react'
import { NotificationContainer } from 'react-notifications'
import VerificationInput from "react-verification-input";
import { connect } from 'react-redux'
import { set2FAError, verifyTwoFactorCode, sendTwoFactorCode } from '../../../actions/authentication.action'

const CodeForm = props => {
  const { auth, set2FAError, verifyTwoFactorCode, sendTwoFactorCode } = props

  useEffect(() => {
    sendTwoFactorCode()
  }, [props.sendTwoFactorCode])

  const [inputCode, setInputCode] = useState('')

  const handleCodeChange = code => {
    set2FAError('')
    setInputCode(code)
  }

  const verifyCode = () => {
    verifyTwoFactorCode(inputCode)
  }

  const resendCode = () => {
    sendTwoFactorCode()
  }

  const isCodeComplete = () => inputCode.length === 6

  return (
    <div className="card w-100 p-4 shadow-light border-0">
      <NotificationContainer />
      <div className="card-body">
        <h1 className="h2">Two-factor Authentication</h1>
        <h6 className="text-uppercase mb-4 font-weight-bold">Enter 6-digit code from your email</h6>

        {auth.errors && <div className="mb-4 alert alert-danger">{auth.errors}</div>}
        
        <VerificationInput
          classNames={{
            container: "container",
            character: "form-control",
            characterInactive: "form-control",
            characterSelected: "form-control",
            characterFilled: "form-control",
          }}
          placeholder={``}
          onChange={handleCodeChange}
        />


        <button type="button" 
                disabled={!isCodeComplete()} 
                className="btn mt-4 mr-4 px-4 btn-secondary" 
                onClick={verifyCode}>Submit</button>
        <button type="button" 
                className="btn mt-4 px-4 btn-link" 
                onClick={resendCode}>Resend</button>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  auth: state.authentication,
})

export default connect(mapStateToProps, { set2FAError, verifyTwoFactorCode, sendTwoFactorCode })(CodeForm)