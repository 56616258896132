import React from 'react'
import PropTypes from 'prop-types'
import { Droppable } from 'react-beautiful-dnd'

export const BoardRenderer = props => {
  const { board, render } = props

  return (
    <div className="board-wrapper">
      {board && <>
        <header className="board-header">
          <span className="badge badge-pill text-white font-md" style={{ backgroundColor: board.colour, height: '20px'}}>{board.name}</span>
        </header>
        <div className="board">
          <Droppable droppableId={board.id}>
            {provided => (
              <div
                className="board-items"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {board.items && board.items.data.map((card, index) => render({ index, card }))}
              </div>
            )}
          </Droppable>
        </div>
      </>}
    </div>
  )
}

BoardRenderer.propTypes = {
  board: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
}

export default BoardRenderer
