import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import 'simple-line-icons/css/simple-line-icons.css'
import 'react-notifications/lib/notifications.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { store } from './store'
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://ecd1781b48d592a2558e1d6657770183@o159062.ingest.us.sentry.io/4507430733479936",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

serviceWorker.unregister()