import React, { useState } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import CustomSelect from '../../../ui/CustomSelect'
import './rev-counter.css';
import { echoMoney } from '../../../../utils/misc';

const monthOptions = [
  { name: 'January', value: 1 },
  { name: 'February', value: 2 },
  { name: 'March', value: 3 },
  { name: 'April', value: 4 },
  { name: 'May', value: 5 },
  { name: 'June', value: 6 },
  { name: 'July', value: 7 },
  { name: 'August', value: 8 },
  { name: 'September', value: 9 },
  { name: 'October', value: 10 },
  { name: 'November', value: 11 },
  { name: 'December', value: 12 },
];
// set current month as default
const defaultMonthOption = monthOptions.find(option => option.value === new Date().getMonth() + 1);


function RevCounter({dealer, data}) {
  const { targets, actuals } = data
  const [selectedMonth, setSelectedMonth] = useState(defaultMonthOption.value);

  const rotateStyle = () => {
    const percentage = getVariancePercentage();
    let arr = ['translate(-50%, -50%)'];
    if (percentage >= 90 && percentage < 100) {
      arr.push('rotate(90deg)');
    } else if (percentage >= 100) {
      arr.push('rotate(170deg)');
    } else {
      arr.push('rotate(10deg)');
    }
    return {
      transform: arr.join(' ')
    };
  }

  const getVariance = () => {
    const i = selectedMonth - 1;
    const variance = targets[i] && actuals[i] ? actuals[i].value - targets[i].value : null;
    return variance;
  }

  const getVarianceClass = (percentage) => {
    const styles = ['label'];
    if (percentage >= 90 && percentage < 100) {
      styles.push('text-yellow');
    } else if (percentage >= 100) {
      styles.push('text-green');
    } else {
      styles.push('text-red');
    }
    return styles.join(' ');
  }

  const getVariancePercentage = () => {
    const i = selectedMonth - 1;
    let percentage = 0;
    if (targets[i] && actuals[i]) {
      percentage = targets[i].value === 0 ? 0 : (actuals[i].value * 100 / targets[i].value);
    }
    return percentage;
  }

  const handleSubmit = () => {}

  return (
    dealer && data && (
      <Form onSubmit={handleSubmit} noValidate>
        <div>These are the profit made/on track for.</div>
        <div className="dealer-rev-counter">
          <div className="gauge-container">
            <div className="center-point" style={rotateStyle()} />
            <div className="arrow" style={rotateStyle()} />
            <div className="bottom-rectangle" />
            <ul className="slice-container">
              <li><div className="slice" /></li>
              <li><div className="slice" /></li>
              <li><div className="slice" /></li>
            </ul>
            <div className="gauge-scale gauge-scale-1" />
            <div className="gauge-scale gauge-scale-2" />
            <div className="gauge-scale gauge-scale-3" />
            <div className="gauge-scale gauge-scale-4" />
            <div className="gauge-scale gauge-scale-5" />
            <div className="gauge-scale gauge-scale-6" />
            <div className="gauge-scale gauge-scale-7" />
            <div className="gauge-scale gauge-scale-8" />
            <div className="gauge-scale gauge-scale-9" />
            <div className="gauge-scale gauge-scale-10" />
            <div className="gauge-scale gauge-scale-11" />
            <div className="gauge-scale gauge-scale-12" />
            <div className="gauge-scale gauge-scale-13" />
          </div>

          <div className="row" style={{ marginTop: '20px' }}>
            <div className="col-2">
              <Field
                component={CustomSelect}
                name="month"
                options={monthOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                onChange={(v) => setSelectedMonth(v.value)}
              />
            </div>
            <div className="col-auto">
              <div
                style={{
                  height: '38px',
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid rgba(45, 44, 49, 0.2)',
                  borderRadius: '2px'
                }}
              >
                <div
                  style={{
                    height: '100%',
                    background: '#F8F8F8',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 24px 0 12px'
                  }}
                >
                  Variance
                </div>
                <div
                  className={getVarianceClass(getVariancePercentage())}
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 12px',
                  }}
                >
                  {getVariance() && echoMoney(getVariance())}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    )
  );
}

RevCounter.propTypes = {};

export default reduxForm({
  form: 'revCounter',
  initialValues: { month: defaultMonthOption },
})(RevCounter)
