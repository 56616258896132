import {
  FETCH_RENEWALS_REQUEST,
  FETCH_RENEWALS_SUCCESS,
  FETCH_RENEWALS_FAILURE,
  RENEWALS_TABLE_ROW_ADD,
  RENEWALS_TABLE_ROW_REMOVE,
  DELETE_RENEWALS_REQUEST,
  DELETE_RENEWALS_SUCCESS,
  DELETE_RENEWALS_FAILURE,
  RESTORE_RENEWALS_REQUEST,
  RESTORE_RENEWALS_SUCCESS,
  RESTORE_RENEWALS_FAILURE,
  ASSIGN_RENEWALS_SUCCESS,
} from '../../constants'
import _ from 'lodash'

const initialState = {
  isPending: true,
  data: null,
  error: null,
  singleProspect: null,
  multiSuspendModal: false,
  selectedRows: [],
}

export function renewals(state = initialState, action) {
  switch (action.type) {
    case FETCH_RENEWALS_REQUEST:
      return {
        ...state,
        isPending: true,
      }
    case FETCH_RENEWALS_SUCCESS:
      return {
        ...state,
        isPending: false,
        data: { ...action.payload },
      }
    case FETCH_RENEWALS_FAILURE:
      return {
        ...state,
        isPending: false,
        data: null,
        error: { ...action.payload },
      }
    case RENEWALS_TABLE_ROW_ADD:
      return {
        ...state,
        selectedRows: _.uniq([...state.selectedRows, ...action.payload]),
      }

    case RENEWALS_TABLE_ROW_REMOVE:
      return {
        ...state,
        selectedRows:
          action.payload.length === 1
            ? [...state.selectedRows.filter(row => row !== action.payload[0])]
            : [],
      }
    case DELETE_RENEWALS_REQUEST:
      return {
        ...state,
        isPending: true,
      }
    case DELETE_RENEWALS_SUCCESS:
      let ids = action.payload
      let copy = _.map(state.data.data, _.clone)
      if (ids.length > 1)
        for (let i = 0; i < copy.length; i++)
          copy = copy.filter(prospect => prospect.id !== ids[i])
      else
        copy = copy.filter(p => {
          return p.id !== ids[0]
        })
      return {
        ...state,
        data: {
          ...state.data,
          data: [...copy],
        },
        selectedRows: [],
      }

    case DELETE_RENEWALS_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      }

    case RESTORE_RENEWALS_REQUEST:
      return {
        ...state,
        isPending: true,
      }
    case RESTORE_RENEWALS_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      }

    case RESTORE_RENEWALS_SUCCESS:
      let selectedProspects = action.payload
      let prospectsCopy = _.map(state.data.data, _.clone)
      if (selectedProspects.length > 1)
        for (let i = 0; i < prospectsCopy.length; i++)
          prospectsCopy = prospectsCopy.filter(
            prospect => prospect.id !== selectedProspects[i],
          )
      else
        prospectsCopy = prospectsCopy.filter(p => {
          return p.id !== selectedProspects[0]
        })
      return {
        ...state,
        data: {
          ...state.data,
          data: [...prospectsCopy],
        },
        selectedRows: [],
      }
    case ASSIGN_RENEWALS_SUCCESS:
      let copyAssignees = [...state.data.data]
      action.payload.forEach(row => {
        let index = state.data.data.findIndex(item => item.id === row.id)
        copyAssignees.splice(index, 1, row)
      })
      return {
        ...state,
        data: {
          ...state.data,
          data: copyAssignees,
        },
        selectedRows: [],
      }
    default:
      return state
  }
}
