import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { echo, formatDate, formatExcess, money, prettyNumber } from '../../../../../utils/misc'
import moment from 'moment';

const PolicyDetails = ({ policy, claimMileage }) => {
  const { policyholder, cover_level, vehicle } = policy

  const today = moment().startOf('day')

  const ageOfVehicle = () => {
    const years = today.diff(moment(vehicle.reg_date), 'years', false);
    const months = today.diff(moment(vehicle.reg_date), 'months', false) % 12;
    return `${years} year${years === 1 ? '' : 's'} & ${months} month${months === 1 ? '' : 's'}`
  }

  const mileageSinceInception = () => {
    if (vehicle.mileage && claimMileage) {
      return prettyNumber(claimMileage - vehicle.mileage)
    }
    return '-'
  }

  return (
    policy && (
      <div>
        <div className="row">
          <div className="col pb-4">
            <h6 className="mt-4 mb-0 text-uppercase">Policy details</h6>
            <Link to={`/claims/policies/${policy.id}/policy`} className="font-sm">
              View policy
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Company</span>
            <p className="font-md">{echo(policy.company_name)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Driver</span>
            <p className="font-md">{policyholder.title} {policyholder.first_name} {policyholder.last_name}</p>
          </div>

          <div className="col-4">
            <span className="label">Email</span>
            <p className="font-md">{policyholder.email}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Telephone (home)</span>
            <p className="font-md">{echo(policyholder.tel1)}</p>
          </div>

          <div className="col-4">
            <span className="label">Telephone (mobile)</span>
            <p className="font-md">{echo(policyholder.tel2)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Cover level</span>
            <p className="font-md">{cover_level.name}, {policy.duration}</p>
          </div>

          <div className="col-4">
            <span className="label">Start date</span>
            <p className="font-md">{formatDate(policy.start_date)}</p>
          </div>

          <div className="col-4">
            <span className="label">End date</span>
            <p className={`font-md ${moment(policy.end_date).isBefore(today) ? 'text-red font-weight-bold' : ''}`}>{formatDate(policy.end_date)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Claim limit</span>
            <p className="font-md">{money.format(policy.claim_limit)}</p>
          </div>

          <div className="col-4">
            <span className="label">Labour rate</span>
            <p className="font-md">{money.format(policy.labour_rate)}</p>
          </div>

          <div className="col-4">
            <span className="label">Excess</span>
            {formatExcess(policy.excess_type, policy.excess)}
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Vehicle reg.</span>
            <p className="font-md">{vehicle.vrm}</p>
          </div>
          <div className="col-8">
            <span className="label">Make and model</span>
            <p className="font-md">{vehicle.make} {vehicle.model}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Transmission</span>
            <p className="font-md">{vehicle.transmission}</p>
          </div>
          <div className="col-4">
            <span className="label">Purchased from</span>
            <p className="font-md">{policy.purchased_from}</p>
          </div>
          <div className="col-4">
            <span className="label">Starting mileage</span>
            <p className="font-md">{prettyNumber(vehicle.mileage)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Invoice</span>
            <p className={`font-md ${policy.invoice_status === 'Payment outstanding' ? 'text-red font-weight-bold' : ''}`}>{echo(policy.invoice_status)}</p>
          </div>
          <div className="col-4">
            <span className="label">Age of vehicle</span>
            <p className="font-md">{ageOfVehicle()}</p>
          </div>
          <div className="col-4">
            <span className="label">Mileage since inception</span>
            <p className="font-md">{mileageSinceInception()}</p>
          </div>
        </div>
        <hr />
      </div>
    )
  )
}

PolicyDetails.propTypes = {
  policy: PropTypes.object.isRequired,
}

export default PolicyDetails
