import {
  FETCH_PH_FAILURE,
  FETCH_PH_REQUEST,
  FETCH_PH_SUCCESS,
  FETCH_SINGLE_PH_FAILURE,
  FETCH_SINGLE_PH_REQUEST,
  FETCH_SINGLE_PH_SUCCESS,
  SEARCH_PH, UPDATE_PH_CONTACT_DETAILS_SUCCESS,
} from '../../constants'
import { NotificationManager } from 'react-notifications'
import api from '../../utils/api'

export const fetchPolicyHoldersRequest = () => ({
  type: FETCH_PH_REQUEST,
})

export const fetchPolicyHoldersSuccess = policyHolders => ({
  type   : FETCH_PH_SUCCESS,
  payload: { policyHolders },
})

export const fetchPolicyHoldersFailure = errors => ({
  type   : FETCH_PH_FAILURE,
  payload: { errors },
})

/**
 * Fetch policyholders
 * @param url
 * @returns {Function}
 */
export const fetchPolicyHolders = url => dispatch => {
  dispatch(fetchPolicyHoldersRequest())

  api
    .get(`/claims/policyholders${url ? url : ''}`)
    .then(res => {
      dispatch(searchPolicyHolders({ searching: false }))
      return dispatch(fetchPolicyHoldersSuccess(res.data))
    })
    .catch(err => {
      if (err && err.response) {
        dispatch(fetchPolicyHoldersFailure(err.response.data))
        NotificationManager.error(
          'Oops! Something went wrong. Please try again after refreshing.',
        )
      }
    })
}

export const searchPolicyHolders = data => ({
  type   : SEARCH_PH,
  payload: {
    ...data,
  },
})

export const fetchPolicyHolderRequest = () => ({
  type: FETCH_SINGLE_PH_REQUEST,
})

export const fetchPolicyHolderFailure = error => ({
  type   : FETCH_SINGLE_PH_FAILURE,
  payload: { error },
})

export const fetchPolicyHolderSuccess = policyHolder => ({
  type   : FETCH_SINGLE_PH_SUCCESS,
  payload: { policyHolder },
})


/**
 * Fetch single policyholder
 * @param id
 * @returns {Function}
 */
export const fetchPolicyHolder = id => dispatch => {
  dispatch(fetchPolicyHolderRequest())

  api
    .get(`/claims/policyholders/${id}`)
    .then(res => {
      return dispatch(fetchPolicyHolderSuccess(res.data))
    })
    .catch(err => {
      if (err && err.response) {
        dispatch(fetchPolicyHolderFailure(err.response.data))
        NotificationManager.error(
          'Oops! Something went wrong. Please try again after refreshing.',
        )
      }
    })
}


export const updatePolicyholderContactDetailsSuccess = policyHolder => {
  return {
    type: UPDATE_PH_CONTACT_DETAILS_SUCCESS,
    payload: { policyHolder }
  }
}

export const updatePolicyholderContactDetails = (id, data) => dispatch => {
  api.patch(`/claims/policyholders/${id}`, data)
    .then(res => {
      dispatch(updatePolicyholderContactDetailsSuccess(res.data))
      NotificationManager.success('Woohoo! Policyholder contact details updated.')
    })
    .catch((err) => {
      if(err && err.response) {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again after refreshing.',
        )
      }
    })
}