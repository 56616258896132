import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { has } from 'lodash';
import { NotificationManager } from 'react-notifications';
import Modal from '../../modals/Modal';

function AddMeeting(props) {
  const [meeting, setMeeting] = React.useState('');
  const [modal, setModal] = React.useState(false);
  const [error, setError] = React.useState(null);

  function closeModal() {
    setModal(false);
    setMeeting('');
  }

  function addMeeting() {
    props
      .handleAddMeeting({
        message: meeting,
        message_type: 'Meeting',
        person_id: props.personId,
      })
      .then(() => {
        closeModal();
      })
      .catch((err) => {
        if (err) {
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          } else {
            NotificationManager.error(
              'Oops! Something went wrong. Please try again after refreshing the page.'
            );
          }
        }
      });
  }

  return (
    <Fragment>
      <Modal
        hide={() => setModal(false)}
        isShowing={modal}
        title="Add a new meeting"
        subheading={props.for}
        onSubmit={null}
        textAction={null}
        onCancel={closeModal}
        textCancel={null}
      >
        <div className="form-group mt-1">
          <textarea
            onChange={(e) => setMeeting(e.target.value)}
            name="meeting"
            cols="30"
            rows="5"
            className="form-control"
            value={meeting}
          ></textarea>
          {error && (
            <div className="d-block invalid-feedback">
              {has(error, 'message') && error.message[0]}
            </div>
          )}
        </div>

        <button
          className="btn btn-secondary"
          disabled={meeting.length < 2}
          onClick={addMeeting}
        >
          Save meeting
        </button>
        <button
          className="btn btn-transparent text-secondary"
          onClick={() => closeModal()}
        >
          No, cancel
        </button>
      </Modal>

      <button
        onClick={() => setModal(true)}
        className="btn btn-warning btn-sm font-weight-bold d-block font-sm ml-3"
      >
        Add a new meeting
      </button>
    </Fragment>
  );
}

AddMeeting.propTypes = {
  for: PropTypes.string.isRequired,
  personId: PropTypes.number.isRequired,
  handleAddMeeting: PropTypes.func.isRequired,
};

export default AddMeeting;
