import React from 'react';
import SlideToggle from '../../../../../ui/SlideToggle';
import { isValid } from 'redux-form';
import { history } from '../../../../../../utils/history';

export function BespokeVehicle(vehicle_type, props) {
  return (
    <SlideToggle isVisible={vehicle_type === 'Bespoke'}>
      <div className="row">
        <div className="col">
          {props.target === 'Dealer' && (
            <p className="mt-4">
              Vehicle is assigned to a dealer with special pricing, they will not
              need a bespoke quote.
            </p>
          )}
          {props.target === 'Public' && (
            <p className="mt-4">
              Customer tries to buy a policy for this vehicle, that they
              require a bespoke quote. This will then be flagged against their
              record in the prospects table.
            </p>
          )}
          {props.target === 'Direct Sales' && (
            <p className="mt-4">
              Agent adds a policy for this vehicle, they will be notified that
              they require a bespoke quote.
            </p>
          )}
        </div>
      </div>
      <div style={{ marginTop: '1rem', marginBottom: '4rem' }}>
        <button
          type="submit"
          name="submit"
          className={`btn btn-secondary`}
          disabled={!isValid}
        >
          Save
        </button>
        <button
          type="submit"
          className="btn btn-transparent text-secondary"
          onClick={() => history.push(props.onCancelRedirectTo)}
        >
          Cancel
        </button>
      </div>
    </SlideToggle>
  );
}
