import React from 'react'
import AdminLayout from './AdminLayout'
import AdminRoutes from '../../../routes/AdminRoutes'
import DirectSalesRoutes from '../../../routes/DirectSalesRoutes'
import UserRoutes from '../../../routes/UserRoutes'
import navigation from '../../../utils/navigation'
import NotFoundPage from '../NotFoundPage'
import PrivateRoute from '../../PrivateRoute'
import ExternalSalesRoutes from '../../../routes/ExternalSalesRoutes'
import ClaimsRoutes from '../../../routes/ClaimsRoutes'
import DashboardRoutes from '../../../routes/DashboardRoutes'
import ReportRoutes from "../../../routes/ReportRoutes";
import DealerPortalRoutes from '../../../routes/DealerPortalRoutes'
import PolicyholderPortalRoutes from '../../../routes/PolicyholderPortalRoutes'

const Admin = props => {

  return (
    props && (
      <AdminLayout navigation={navigation} permissions={props.permissions}>
        <AdminRoutes/>
        <DirectSalesRoutes/>
        <ExternalSalesRoutes/>
        <UserRoutes/>
        <ClaimsRoutes/>
        <DashboardRoutes/>
        <ReportRoutes />
        <DealerPortalRoutes />
        <PolicyholderPortalRoutes />
        <PrivateRoute exact path="/404" component={NotFoundPage}/>
      </AdminLayout>
    )
  )
}

export default Admin
