import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PageHeader from '../../../../ui/PageHeader'
import YearSelector from '../Team/forms/YearSelector'
import Modal from '../../../../modals/Modal'
import useModal from '../../../../../hooks/useModal'
import moment from 'moment'
import { initialize, isDirty, reset } from 'redux-form'
import TeamTargetTable from './TeamTargetTable'
import api from '../../../../../utils/api'
import IndividualForm from './IndividualForm'
import { NotificationManager } from 'react-notifications'
import { get } from 'lodash'

const IndividualTargets = ({ dispatch, type, header, ...props }) => {
  const [selectedYear, setSelectedYear] = useState(moment().year())
  const [confirmYear, setConfirmYear] = useState(null)
  const [teamBudgets, setTeamBudgets] = useState([
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ])
  const [hasYear, setHasYear] = useState(false)
  const {
    isShowing: isShowingConfirmModal,
    toggle: toggleConfirmModal,
  } = useModal()
  const [users, setUsers] = useState([])
  const [formIsClean, setFormIsClean] = useState(false)

  const yearChange = (value) => {
    if (props.isDirty) {
      setConfirmYear(value.year)
      toggleConfirmModal()
    } else {
      setSelectedYear(value.year)
      return value
    }
  }

  const confirmYearChange = () => {
    setSelectedYear(confirmYear)
    toggleConfirmModal()
  }

  const cancelYearChange = () => {
    toggleConfirmModal()
    dispatch(reset('yearForm'))
  }

  const cannotEditYear = () => {
    setTeamBudgets([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  }

  useEffect(() => {
    api
      .get('/misc/users', {
        params: {
          groups: [header],
        },
      })
      .then((response) => {
        setUsers(response.data)
      })
      .catch(() => {
        NotificationManager.error(
          'Oops, something went wrong fetching the account managers. Please refresh and try again.'
        )
      })
  }, [header])

  useEffect(() => {
    api
      .get(`/admin/targets/team/${selectedYear}`)
      .then((response) => {
        if (
          typeof response.data[header] === 'undefined' ||
          response.data[header].length !== 12
        ) {
          cannotEditYear()
        }
        setTeamBudgets(response.data[header].map((month) => month.value))
      })
      .catch((err) => {
        if (err.response.status === 404) {
          cannotEditYear()
        }
      })

    api
      .get(`/admin/targets/${type}/${selectedYear}`)
      .then((response) => {
        setHasYear(true)
        dispatch(
          initialize(
            'individualTargetForm',
            setPostValues(response.data, users)
          )
        )
        setCalculationEnabled(true)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setHasYear(false)
          dispatch(initialize('individualTargetForm', setPostValues([], users)))
          setCalculationEnabled(true)
        }
      })

    setFormIsClean(false)
  }, [selectedYear, users, formIsClean === true])

  const [calculationEnabled, setCalculationEnabled] = useState(false)
  const handleResetCalculations = () => {
    setCalculationEnabled(false)
  }

  const searchForTargets = (person_id, values) => {
    return values.find((value) => person_id === value.id)
  }

  const setPostValues = (values, users) => {
    let defaultMonths = []
    for (let i = 0; i < 12; i++) {
      defaultMonths[i] = {
        month: i,
        value: '',
      }
    }
    let people = []
    users.forEach((person) => {
      let targetArray = searchForTargets(person.person_id, values)
        ? searchForTargets(person.person_id, values).targets
        : defaultMonths
      people[person.person_id] = {
        months: targetArray.map((target) =>
          target.value === '0.00' ? '' : target.value
        ),
      }
    })

    return {
      users: people,
    }
  }

  const getPostValues = (values) => {
    let people = []
    users.forEach((user) => {
      people.push({
        id: user.person_id,
        months: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((val, idx) => {
          return {
            month: idx + 1,
            value: parseFloat(get(values, `${user.person_id}.months[${idx}]`, 0) || 0),
          }
        }),
      })
    });
    return people
  }

  const handleSubmit = (values) => {
    let url = hasYear
      ? `admin/targets/${type}/${selectedYear}`
      : `admin/targets/${type}`
    if (hasYear) {
      api
        .patch(url, {
          year: selectedYear,
          people: getPostValues(values.users),
        })
        .then(() => {
          setHasYear(true)
          setFormIsClean(true)
          NotificationManager.success('Woohoo! Your budgets were saved')
          //Set form values
        })
        .catch(() => {
          setFormIsClean(false)
          NotificationManager.error(
            'Oops! There was a problem saving your budgets. Please refresh and try again.'
          )
        })
    } else {
      api
        .post(url, {
          year: selectedYear,
          people: getPostValues(values.users),
        })
        .then(() => {
          setFormIsClean(true)
          setHasYear(true)
          NotificationManager.success('Woohoo! Your budgets were saved')
          //Set form values
        })
        .catch(() => {
          setFormIsClean(false)
          NotificationManager.error(
            'Oops! There was a problem saving your budgets. Please refresh and try again.'
          )
        })
    }
  }

  return (
    <>
      <br />
      <div
        className="container-fluid animated fadeIn"
        style={{ overflowX: 'scroll' }}
      >
        <PageHeader title={'Budgets'} description="" />
        <ul className="list-info mb-4" style={{ maxWidth: '570px' }}>
          <li>Set budgets for individual Account Managers below.</li>
          <li>These figures will be used to populate individual dashboards.</li>
          <li>Start by entering a sales volume for an AM.</li>
        </ul>
        <YearSelector
          initialValues={{ year: { year: selectedYear } }}
          handleChange={yearChange}
        />
        <br />
        <TeamTargetTable year={selectedYear} budgets={teamBudgets} />
        <br />
        <br />
        <IndividualForm
          users={users}
          teamBudgets={teamBudgets}
          onSubmit={handleSubmit}
          calculationFlag={calculationEnabled}
          setCalculationEnabled={setCalculationEnabled}
          resetCalculation={handleResetCalculations}
          formIsClean={formIsClean}
        />
      </div>
      <Modal
        onCancel={cancelYearChange}
        onSubmit={confirmYearChange}
        isShowing={isShowingConfirmModal}
        hide={toggleConfirmModal}
        style={{ maxWidth: '435px' }}
      >
        <>
          You have made changes to this year and not saved them. Do you want to
          change year?
        </>
      </Modal>
    </>
  )
}

export default connect((state) => ({
  // yearDirty: isDirty('individualTargetForm')(state),
  isDirty: isDirty('individualTargetForm')(state),
}))(IndividualTargets)
