import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import api from '../../../utils/api'
import { NotificationManager } from 'react-notifications'

const CreateClaimLimitForm = ({
  onClose,
  coverLevel,
  updateClaimLimits,
  fetchClaimLimits,
}) => {

  const create = e => {
    e.preventDefault()

    api.post(`/admin/cover-levels/${coverLevel.id}/claim-limits`, {claim_limits: claimLimit})
      .then(res => {
        NotificationManager.success('Woohoo! You added a claim limit. ')
        onClose()
      })
      .then(() => fetchClaimLimits(coverLevel.id))
      .catch(err => {
        if (err.response.status === 422)
          setError(err.response.data.errors.claim_limits[0])
      })

  }
  const [claimLimit, setClaimLimit] = React.useState('')
  const [error, setError] = React.useState(false)

  return (
    <Fragment>
      <form onSubmit={create}>
        <p>Separate multiple limits with a comma e.g. 100, 150.</p>
        <div className="form-group">
          <div className="input-group">
              <span className="input-group-prepend">
                <span className="input-group-text">
                  <i className={'wf-icon-gbp'}/>
                </span>
              </span>
            <input
              name="claim_limits"
              className={`form-control ${error ? 'is-invalid' : ''}`}
              onChange={(e) => setClaimLimit(e.target.value)}
              type="text"
            />

            <div className="invalid-feedback">{error}</div>
          </div>
        </div>

        <button
          className="btn btn-secondary"
          value={claimLimit}
        >
          Add claim limits
        </button>
        <button
          className="btn btn- transparent text-secondary"
          onClick={onClose}
        >
          Cancel
        </button>
      </form>
    </Fragment>
  )
}

CreateClaimLimitForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  coverLevel: PropTypes.object.isRequired,
  updateClaimLimits: PropTypes.func.isRequired,
  fetchClaimLimits: PropTypes.func.isRequired,
}

export default CreateClaimLimitForm