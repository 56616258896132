import {
    LOGIN_REQUEST,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    SET_TOKEN_CLAIMS,
    CHANGE_PASSWORD_FAILED,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    ACTIVATE_2FA,
    INACTIVATE_2FA,
    SET_2FA_ERROR,
} from '../constants';

let access_token = JSON.parse(localStorage.getItem('wf-token'))

let initialState = {
  isLoading: true, // use for setTokenClaims
  isPending: false,
  need2FA: false,
  access_token: access_token ? access_token : null,
  user: null,
  loggedIn: false,
  errors: null,
}

export function authentication(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isPending: true,
        user: action.user,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isPending: false,
        loggedIn: true,
        ...action.payload,
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        isPending: false,
        ...action.payload,
      }
    case SET_TOKEN_CLAIMS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      }
    case ACTIVATE_2FA:
      return {
        ...state,
        need2FA: true,
      }
    case INACTIVATE_2FA:
      return {
        ...state,
        need2FA: false,
      }
    case SET_2FA_ERROR:
      return {
        ...state,
        errors: action.payload.errors,
      }      
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isPending: true,
        errors: null,
      }
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isPending: false,
        errors: null,
        ...action.payload
      }
    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        isPending: false,
        errors: action.payload.errors,
      }
    case LOGOUT:
      return {}
    default:
      return state
  }
}
