import api from '../../../../../utils/api'
import { tableHeadings } from '../../PriceBands/forms/edit/table-headings'
import { find, has, get, set, uniq, uniqBy, differenceBy } from 'lodash'

export const convertValues = (formValues, target) => {
  let values = {}
  values.make = formValues.make.name
  values.models = formValues.model.map((model) => model.name)
  values.target = target
  values.type = formValues.vehicle_type
  values.prices = []
  switch (values.type) {
    case 'Prestige':
    case 'Commercial':
      for (let coverLevel in formValues.price_band[values.type]) {
        let fullCoverLevel = find(
          formValues.coverLevels[values.type],
          (findCoverLevel) => findCoverLevel.name === coverLevel
        )
        for (let price in formValues.price_band[values.type][coverLevel]) {
          if (!(formValues.price_band[values.type][coverLevel][price] > 0)) {
            continue
          }
          let priceSplit = price.split('_')
          //Check for special duration
          if (
            has(
              formValues,
              `special_duration.${fullCoverLevel.name}.m${priceSplit[1]}`
            )
          ) {
            values.prices.push({
              cover_level_id: fullCoverLevel.id,
              claim_limit: priceSplit[0],
              duration: priceSplit[1] + (priceSplit[1] === 1 ? ' month' : ' months'),
              price: formValues.price_band[values.type][coverLevel][price],
              is_special_duration: 1,
              special_duration:
                get(
                  formValues,
                  `special_duration.${fullCoverLevel.name}.m${priceSplit[1]}`,
                  0
                ) + ' months',
            })
          } else {
            values.prices.push({
              cover_level_id: fullCoverLevel.id,
              claim_limit: priceSplit[0],
              duration: priceSplit[1] + (priceSplit[1] === 1 ? ' month' : ' months'),
              price: formValues.price_band[values.type][coverLevel][price],
              is_special_duration: 0,
            })
          }
        }
      }
      break
    case 'Specialist':
      values.percentage = formValues.increase_prices_by
      break
    case 'Bespoke':
      //No Values need adding to the bespoke thing, its just recorded.
      break
    default:
      return null
  }
  return values
}
/**
 *
 * @param v - Vehicle
 * @param l - Cover Levels for Vehicle Target
 * @returns {{}} - initialValues for Special Vehicles Form
 */
export const convertVehicleValues = (v, l) => {
  let values = {}
  values.make = {
    id: v.make,
    name: v.make,
  }
  values.model = v.models.map((model) => ({
    id: model.model,
    name: model.model,
  }))
  values.vehicle_type = v.type
  switch (v.type) {
    case 'Prestige':
    case 'Commercial':
      //Set Cover Levels to full objects of all the ones in the special vehicle prices.
      let coverLevelIds = uniq(v.prices.map((price) => price.cover_level_id))
      set(
        values,
        `coverLevels.${v.type}`,
        l.filter((level) => coverLevelIds.includes(level.id))
      )
      //Set all price boxes in correct format
      l.filter((level) => coverLevelIds.includes(level.id)).forEach(
        (coverLevel) => {
          let coverLevelPrices = v.prices.filter(
            (price) => price.cover_level_id === coverLevel.id
          )
          coverLevelPrices
            .filter((price) => price.price > 0)
            .forEach((price) => {
              let duration = price.duration.split(' ')[0] //Remove the 'months' and space from duration
              let claim_limit = price.claim_limit
              set(
                values,
                `price_band.${v.type}.${coverLevel.name}.${claim_limit}_${duration}`,
                price.price
              )
            })
          //Check special durations and add form flag
          getColumnsFromVehicle(v, coverLevel.id)
            .filter((column) => column.special)
            .forEach((column) => {
              set(
                values,
                `special_duration.${coverLevel.name}.m${column.value}`,
                column.special_duration
              )
            })
        }
      )
      break
    case 'Specialist':
      values.increase_prices_by = v.percentage
      break
    case 'Bespoke':
      break
    default:
      break
  }

  return values
}

export const getColumnsFromVehicle = (v, coverLevelId) => {
  if (!v) {
    return []
  }
  let durations = uniqBy(v.prices, (price) => price.duration).filter(
    (price) => price.cover_level_id === coverLevelId
  )
  let headings = durations.map((duration) => {
    let split = duration.duration.split(' ')
    if (duration.is_special_duration) {
      let specialSplit = duration.special_duration.split(' ')
      return {
        title: duration.duration + ` (drive ${specialSplit[0]})`,
        value: split[0],
        hiddenFor: [],
        special: true,
        special_duration: specialSplit[0],
      }
    } else {
      return {
        title: duration.duration,
        value: split[0],
        hiddenFor: [],
        special: false,
      }
    }
  })
  return differenceBy(headings, tableHeadings, (heading) => heading.title)
}

export const createVehicle = (data) => {
  return api.post('/admin/special-vehicles', data)
}

export const updateVehicle = (data, id) => {
  return api.patch(`/admin/special-vehicles/${id}`, data)
}
