import React from 'react'
import { Link } from 'react-router-dom'
import CreateProspectForm from './CreateProspectForm'
import { history } from '../../../../../utils/history'
import api from '../../../../../utils/api'
import { NotificationManager } from 'react-notifications'
import { SubmissionError } from 'redux-form'
import { get } from 'lodash'

const CreateProspect = () => {


  const onSubmit = values => {
    const data = {}
    const spreadValues = { ...values, ...values.address }
    const fields = [
      'name', 'postcode', 'address_1', 'address_2', 'address_3', 'city',
      'county', 'email', 'tel_office', 'vat_registered', 'vat_number'
    ]

    fields.forEach(field => data[field] = spreadValues[field])

    data.existing_warranty_provider = get(values, 'existing_warranty_provider')
    data.policies_per_month = get(values, 'policies_per_month')
    data.potential_revenue = get(values, 'potential_revenue')
    data.cars_in_stock = get(values, 'cars_in_stock')
    data.min_stock_price = get(values, 'min_stock_price')
    data.max_stock_price = get(values, 'max_stock_price')
    data.tel_mobile = get(values, 'tel_mobile')
    data.website = get(values, 'website')
    data.comments = get(values, 'comments')

    if (values._hasPrimaryContact) {
      data.primary_contact = {}
      data.primary_contact.first_name = get(values, 'primary_contact_first_name')
      data.primary_contact.last_name = get(values, 'primary_contact_last_name')
      data.primary_contact.email = get(values, 'primary_contact_email')
      data.primary_contact.tel1 = get(values, 'primary_contact_tel_mobile')
      data.primary_contact.tel2 = get(values, 'primary_contact_tel_office')
    }

    return api.post('/external-sales/prospects', data).then(() => {
      history.push('/external-sales/prospects')
      NotificationManager.success('Woohoo! You added a new prospect.')
    })
      .catch(err => {
        if (err && err.response.status === 422) {
          const errors = {
            ...err.response.data.errors,
            _error: err.response.data.message
          }
          throw new SubmissionError(errors)
        }
      })
  }

  const onCancel = () => {
    history.push('/external-sales/prospects')
  }

  return (
    <>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/external-sales/prospects" className="open active">
            Prospects
          </Link>
        </li>
        <li className="breadcrumb-item">
          <span className="active">Add a prospect</span>
        </li>
      </ol>

      <CreateProspectForm onSubmit={onSubmit} cancel={onCancel}/>
    </>
  )
}

export default CreateProspect
