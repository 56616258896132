import React from 'react'
import { Field } from 'redux-form'
import renderField from '../../../../../utils/renderField'
import CustomSelect from '../../../../ui/CustomSelect'
import { email, required } from 'redux-form-validators'

const Invoicing = () => {
  return (
    <>
      <h6 className="mt-3 mb-4 text-uppercase">Invoicing</h6>
      <div className="row">
        <div className="col-md-6">
          <Field
            type="checkbox"
            label="Show registrations on invoice"
            component={renderField}
            name={`registrations_on_invoice`}
            id={'registrationsOnInvoice'}
          />
        </div>

        <div className="col-md-6">
          <Field
            type="checkbox"
            label="Exclude from debtor ledger"
            component={renderField}
            name={`exclude_from_debtor_ledger`}
            id={'excludeFromDebtorLedger'}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Field
            label="Invoice cycle"
            component={CustomSelect}
            name={`invoice_cycle`}
            validate={[required({ message: 'You must select an invoice cycle.' })]}
            id={'invoiceCycle'}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.name}
            options={[
              {
                name: 'Weekly',
                value: 'weekly',
              },
              {
                name: 'Monthly',
                value: 'monthly',
              },
            ]}
          />
        </div>

        <div className="col-md-6">
          <Field
            type="email"
            label="Invoice email"
            component={renderField}
            name={`invoice_email`}
            validate={[required({ msg: 'You must enter an email address.' }), email({ message: 'That email address doesn\'t quite look right.' })]}
            className="form-control"
            placeholder="e.g. 123cars@email.com"
          />
        </div>
      </div>
    </>
  )
}

export default Invoicing