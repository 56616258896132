import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import renderField from '../../../../utils/renderField'
import { email, required } from 'redux-form-validators';
import { validPhoneNumber } from '../../../../utils/misc';
import { usePostcodeLookupWithRedux } from '../../../../hooks/usePostcodeLookupWithRedux';

const validatePhoneNumber = value => value && !validPhoneNumber(value) ? 'That phone number doesn\'t quite look right.' : undefined

const EditPolicyholderDetails = ({
  handleSubmit,
  onSubmit,
  onCancel,
  valid,
}) => {

  const { forceManualAddress, markup } = usePostcodeLookupWithRedux('dealer-portal/account-details')

  useEffect(() => {
    forceManualAddress();
  }, [])

  return (
    <div className="col-12 policyholder-details-form">
      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="row">
          <div className="col-12 p-0">

            <div className="row">
              <div className="col-6">
                <Field
                  type="text"
                  label="Telephone (mobile)"
                  component={renderField}
                  name={`tel1`}
                  className="form-control"
                  validate={validatePhoneNumber}
                />
              </div>
              <div className="col-6">
                <Field
                  type="text"
                  label="Telephone (home)"
                  component={renderField}
                  name={`tel2`}
                  className="form-control"
                  validate={validatePhoneNumber}
                />
              </div>
              <div className="col-12">
                <Field
                  type="text"
                  label="Email address"
                  component={renderField}
                  name={`email`}
                  validate={[
                    required({ msg: 'You must enter an email address.' }),
                    email({ message: 'That email address doesn\'t quite look right.' }),
                  ]}
                  className="form-control"
                />
              </div>
            </div>

            {markup}

            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="form-group">
                  <button
                    className="btn btn-secondary"
                    type="submit"
                    disabled={!valid}
                  >
                  Save changes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline btn-form-cancel"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </form>
    </div>
  )
}

EditPolicyholderDetails.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

const validate = values => {
  const warnings = {}

  if (values.tel1 === '' && values.tel2 === '')
    warnings.tel1 = 'Please enter at least one phone number'

  return warnings
}

export default connect()(
  reduxForm({
    form: 'dealer-portal/account-details',
    destroyOnUnmount: true,
    validate,
  })(EditPolicyholderDetails),
)
