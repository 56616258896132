import React, { Component, Fragment } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import PageHeader from '../../ui/PageHeader'
import { connect } from 'react-redux'
import { fetchPipelineData, updateBoards } from '../../../actions/pipelines.action'
import { DragDropContext } from 'react-beautiful-dnd'
import { BoardRenderer } from './Board'
import SearchBox from '../../ui/SearchBox'
import api from '../../../utils/api'
import { filterGroups } from '../DirectSales/RenewalsProspects/filterGroups'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import PipelineCardDirectSales from '../../ui/PipelineCardDirectSales'
import Avatar from '../../ui/Avatar'


class PipelinePage extends Component {
  state = {
  filters : [
    filterGroups[0],
    {
      name : 'Lead type',
      allowOnlyOne : true,
      filters : [
        {
          id : 1,
          name : 'Renewal',
          value : 'Renewal',
        },
        {
          id : 2,
          name : 'Prospect',
          value : 'Prospect',
        },
      ],
    },
  ],
}

componentDidMount() {
  this.props.fetchPipelineData()

  // // Fetch omnibox filters - Assignees
  api.get('/direct-sales/renewals/assignees?limit=999').then(res => {
    this.setState({
      filters : [
        ...this.state.filters,
        {
          name : 'Assignees',
          filters : res.data.data.map((user, index) => {
            return {
              id : user.id,
              name : (
                <div
                  className="d-flex align-items-center"
                  style={{marginBottom : '-2px'}}
                  key={index}
                >
                  <Avatar user={user} size="xsmall"/>
                  <span className="font-md ml-1" style={{marginTop : '-2px'}}>
                    {user.first_name} {user.last_name}
                  </span>
                </div>
              ),
              value : user.id,
            }
          }),
          allowOnlyOne : false,
        },
      ],
    })
  })
}

onBeforeDragStart = () => {
  /*...*/
}

onDragStart = () => {
  /*...*/
}
onDragUpdate = () => {
  /*...*/
}
onDragEnd = payload => {

  const {destination, source, draggableId} = payload

  if (!destination) {
    return
  }

  // if card is in the same position after it has been dragged around do nothing
  if (destination.droppableId === source.droppableId && destination.index === source.index) {
    return false
  }

  const oldLaneId = source.droppableId
  const newLaneId = destination.droppableId

  // Don't allow the user to move items from/to the Closed won lane manually
  const {pipelines} = this.props
  const filtered = pipelines.data.filter((lane) => lane.name === 'Closed won')
  if (filtered.length > 0) {
    const closedWonLandID = filtered[0].id
    if (oldLaneId === closedWonLandID || newLaneId === closedWonLandID) {
      return
    }
  }

  // update the UI
  this.props.updateBoards(oldLaneId, newLaneId, draggableId, destination.index)

  // update the database
  this.updateCardPosition(draggableId, destination.index + 1, destination.droppableId)
    .then(() => {
    })
    .catch((err) => {
      if (err) {
        NotificationManager.error('Oops! Please try again after refreshing the page.')
      }
    })

}

updateCardPosition(cardId, rank, laneId) {
  return new Promise((resolve, reject) => {
    api.patch(`/direct-sales/pipeline/items/${cardId}`, {
      action : 'change_lane',
      rank: rank - 1,
      lane_id : laneId,
    })
      .then(() => {
        resolve([])
      })
      .catch(err => {
        reject(err)
      })
  })
}

onSearch = (query, filters) => {
  let url = '?query='

  if (query && query.length > 0) {
    url += query
  }

  // eslint-disable-next-line array-callback-return
  filters.map(filter => {
    if (filter.name === 'Renewal') {
      url += `&lead_type=${filter.name}`
    }

    if (filter.name === 'Prospect') {
      url += `&lead_type=${filter.name}`
    }

  })

  let dateRange = filters.filter(f => f.group_name === 'Expires within')
  if (dateRange.length === 1) {
    if (dateRange[0].name === 'Expired') {
      url = `${url}&max_end_date=${moment().subtract(1, 'day').format('YYYY-MM-DD')}`
    } else {
      url = `${url}&min_end_date=${moment().format(
        'YYYY-MM-DD',
      )}&max_end_date=${dateRange[0].value}`
    }
  }


  let assignees = filters.filter(f => f.group_name === 'Assignees')
  if (assignees.length > 0) {
    assignees.map(assignee => assignee.value)
      .map(row => url = `${url}&assignee_ids[]=${row}`)
  }


  this.props.fetchPipelineData(url)
}

render() {
  const {pipelines, searching} = this.props
  return (
    pipelines ? <Fragment>
      <br/>
      <div className="container-fluid animated fadeIn pipeline-page">
        <PageHeader title="Pipeline"/>
        <br/>
        <SearchBox
          omnibox={{
            groups : this.state.filters,
          }}
          searchPlaceholder={'Search or filter pipeline...'}
          searching={searching}
          onSearch={this.onSearch}
          fullWidth={true}
        />

        <div className="kanban-board">
          <DragDropContext
            onBeforeDragStart={this.onBeforeDragStart}
            onDragStart={this.onDragStart}
            onDragUpdate={this.onDragUpdate}
            onDragEnd={this.onDragEnd}
          >
            {pipelines ?
              pipelines.data.map((board, i) => (
                <BoardRenderer
                  key={i}
                  board={board}
                  render={props => <PipelineCardDirectSales board={board} key={props.card.id} {...props} />}
                />
              )) : <>
               {[...Array(6)].map((item, index) => (
                <div key={index} className="board-wrapper d-flex align-items-center justify-content-center">
                  <div className="spinner-border spinner-border-sm" role="status"/>
                </div>
               ))}
            </>}
          </DragDropContext>
        </div>
      </div>
    </Fragment> : <>loading...</>
  )
}
}

export default connect(
  state => ({
    pipelines : state.pipelines.data,
    searching : state.pipelines.searching,
  }),
  {fetchPipelineData, updateBoards},
)(PipelinePage)
