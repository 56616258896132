import React from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'
import api from '../../utils/api'
import { NotificationManager } from 'react-notifications'
import { API_URL } from '../../utils/constants'

RestoreModal.propTypes = {
  title   : PropTypes.string,
  subtitle: PropTypes.string,

  isShowing: PropTypes.bool.isRequired,
  toggle   : PropTypes.func.isRequired,
  onCancel : PropTypes.func,
  onDone : PropTypes.func,

  /**
   * Array of ids that will be deleted.
   */
  items: PropTypes.arrayOf(PropTypes.number.isRequired),

  endPoint: PropTypes.string.isRequired,

  singleRestoreMessage: PropTypes.string,
  multiRestoreMessage : PropTypes.string,
  notFoundMessage    : PropTypes.string,
  serverErrorMessage : PropTypes.string,

  textAction: PropTypes.string,
  textCancel: PropTypes.string,
}

function RestoreModal(props) {
  const { children, endPoint, items, onDone } = props

  const handleRestore = () => {
    return new Promise((resolve, reject) => {
      api.patch(`${API_URL}/${endPoint}`, {
        action: 'restore',
        ids   : items
      })
        .then((res) => {
          props.toggle();
          if (items.length === 1) {
            NotificationManager.success(props.singleRestoreMessage)
          }

          if (items.length > 1) {
            NotificationManager.success(props.multiRestoreMessage)
          }

          // resolve with status and array of restored prospects
          resolve(onDone('ok', res.data.map(row => row.id)))
        })
        .catch(err => {
          if (err) {
            NotificationManager.error(props.notFoundMessage)
          }

          NotificationManager.error(props.serverErrorMessage)

          reject(onDone('error', err))
        })
    })
  }

  return (
    <Modal
      hide={props.toggle}
      isShowing={props.isShowing}
      title={props.title}
      onCancel={props.onCancel}
      onSubmit={handleRestore}
      textAction={props.textAction}
      textCancel={props.textCancel}
    >
      <div className="mb-3">
        {children}
      </div>
    </Modal>
  )
}

RestoreModal.defaultProps = {
  title: '🧐 Are you sure?',
  singleRestoreMessage: 'Success! You restored a record.',
  multiRestoreMessage : 'Success! You restored multiple records.',
  notFoundMessage    : `Oops! Record doesn't exist anymore.`,
  serverErrorMessage : 'Oops! Something went wrong. Please try again after refreshing.',
}

export default RestoreModal