import React, {useEffect, useState} from 'react'
import { get } from 'lodash'
import {change, Field, formValueSelector, untouch} from "redux-form";
import renderField from "../../../../../../utils/renderField";
import {Link} from "react-router-dom";
import useModal from "../../../../../../hooks/useModal";
import Modal from "../../../../../modals/Modal";
import {numericality} from "redux-form-validators";
import {NotificationManager} from "react-notifications";
import {connect} from "react-redux";

const CoverLevelTable = ({coverLevel, prices, values, newDuration, dispatch}) => {
  const {isShowing: durationModal, toggle: toggleDurationModal} = useModal()

  const [durations, setDurations] = useState([]);

  useEffect(() => {
    setDurations([...coverLevel.durations]) //Copy in the cols so its immutable
  }, [])

  const addDuration = (durationObj) => {
    let tempDurations = durations
    tempDurations.push(durationObj)
    tempDurations.sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    })
    setDurations([...tempDurations])
  }

  const handleDurationModalSubmit = () => {
    let tempDurations = durations
    for(let i = 0; i < tempDurations.length; i++) {
      if (parseInt(newDuration) === tempDurations[i].value) {
        NotificationManager.error('This duration already exists on ' + coverLevel.name)
        return;
      }
    }
    addDuration({
      'title': newDuration + ' months',
      'value': parseInt(newDuration),
    });
    dispatch(change('dealerCustom/pricingForm', 'pb_duration', ''))
    dispatch(untouch('dealerCustom/pricingForm', 'pb_duration'))
    toggleDurationModal()
  }

  return <>
    <Modal
      hide={toggleDurationModal}
      isShowing={durationModal}
      title="Add duration"
      subtitle="e.g. 48 months"
      onCancel={toggleDurationModal}
      onSubmit={() => {handleDurationModalSubmit()}}
      textAction="Add duration"
      textCancel="Cancel"
    >
      <div className="row">
        <div className="mt-2 col">
          <Field
            name="pb_duration"
            type="text"
            label="Duration"
            className="w-50 form-control"
            component={renderField}
            validate={[
              numericality({
                msg: 'You must enter a number.',
              }),
            ]}
            append={{
              direction: 'right',
              text: 'months',
            }}
          />
        </div>
      </div>
    </Modal>


    <div
      className="px-4 mt-5"
      style={{ overflowX: 'scroll' }}
    >
      <div className="col">
        <div className="label mb-2">
          {coverLevel.name.toUpperCase()} PRICES
        </div>
        <table
          className="table table-striped table-bordered price-band-table"
          style={{
            marginBottom: '56px',
            marginRight: '30px',
          }}
        >
          <thead>
          <tr>
            <th>Claim Limits</th>
            {durations.map(d => (
              <th style={{minWidth: '200px'}} key={d.value}>{d.title}<span className="pull-right">Base</span></th>
            ))}
            <th><Link to={`#`} onClick={toggleDurationModal}>Add Duration</Link></th>
          </tr>
          </thead>
          <tbody>
          {coverLevel.claimLimits.map(l => (
            <tr key={l}>
              <td>&pound;{l}</td>
              {durations.map(d => (
                <td key={d.value}>
                  <Field
                    component={renderField}
                    name={`prices._${coverLevel.id}_${l}_${d.value}`}
                    showSuccessValidation={false}
                    className={
                      `form-control input-group-price pull-left
                      ${(get(prices, `_${coverLevel.id}_${l}_${d.value}`, 0) !== get(values, `_${coverLevel.id}_${l}_${d.value}`, 0)) ? 'pricing-different' : ''}
                      `}
                    type="text"
                    fieldIcon="wf-icon-gbp"
                    style={{maxWidth: 'calc(100% - 70px)', display: 'inline-block', top: '-3px', position: 'relative', minWidth: '100px'}}
                  />
                  <span className="pull-right">&pound;{get(prices, `_${coverLevel.id}_${l}_${d.value}`, 0).toFixed(2)}</span>
                </td>
              ))}
              <td></td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  </>
}

const selector = formValueSelector('dealerCustom/pricingForm')

export default connect((s) => ({
  newDuration: selector(s, 'pb_duration'),
}))(CoverLevelTable)