import React, {useState, useEffect} from 'react'
import {Link} from "react-router-dom";
import api from "../../../../../utils/api";
import {NotificationManager} from "react-notifications";
import BasePriceBandForm from "./customPricing/BasePriceBandForm";
import CustomPricingForm from "./customPricing/CustomPricingForm";
import {uniqBy, uniq, sortBy, get, set, unionBy} from 'lodash'
import {formValueSelector, getFormValues, change} from "redux-form";
import {connect} from "react-redux";
import CustomPricingHeader from "./customPricing/CustomPricingHeader";
import {history} from "../../../../../utils/history";

const CustomPrices = ({match: {params: {id}}, basePriceBandId, formValues, dispatch}) => {
  const [dealer, setDealer] = useState(null);
  useEffect(() => {
    api
      .get(`/external-sales/dealers/${id}`)
      .then((res) => {
        setDealer(res.data)
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing'
          )
        }
      })
  }, [id])

  //false = not set yet, null = no price band, values = has price band
  const [currentPriceBand, setCurrentPriceBand] = useState(false)
  const [isDefaultPriceBand, setIsDefaultPriceBand] = useState(true)
  const [dealerPricingRules, setDealerPricingRules] = useState([])
  useEffect(() => {
    api.get(`/external-sales/dealers/${id}/custom-price-band`)
      .then(res => {
          setCurrentPriceBand(res.data.prices)
          setIsDefaultPriceBand(res.data.prices.dealer_id === null)
          setDealerPricingRules(res.data.rules);
          setDurations(res.data.durations);
      })
      .catch((err) => {
        if (get(err, 'response.status', 0) === 404) {
          setCurrentPriceBand(null)
          setDealerPricingRules([]);
        } else {
          console.error(err);
          NotificationManager.error('Oops! Something went wrong fetching the current price band for the dealer.');
        }
      })
  }, [id])

  const getRuleType = (type) => (dealerPricingRules.filter(rule => (rule.type === type)))

  const convertTypeToInitialValues = (type) => {
    let rules = getRuleType(type)
    return rules.map(rule => {
      let cover_levels = [];
      rule.cover_levels.forEach((val) => {
        cover_levels[val.id] = true;
      })

      return {
        ...rule,
        effect: impactToOperator(rule.impact_positive),
        cost_type: costTypeToOperator(rule.cost_type),
        cover_levels
      }})
  }

  const impactToOperator = (impact) => ({operator: impact ? '+' : '-', value: impact ? '+' : '-'})
  const costTypeToOperator = (type) => ({operator: type === 'Percentage' ? '%' : '£', value: type === 'Percentage' ? '%' : '£'})
  const getDefault = (type) => {
    let defaultIndex = 0;
    getRuleType(type).forEach((val, key) => {
      if (val.default) {
        defaultIndex = key;
      }
    })
    return defaultIndex
  }


  const initializeValues = () => {
    if (currentPriceBand === null || currentPriceBand === false) return {}
    let prices = currentPriceBand.prices;
    let values = {};
    prices.forEach((p) => {
      let ref = `prices._${p.cover_level.id}_${p.claim_limit}_${p.duration.split(' ')[0]}`
      set(values, ref, p.price)
    })
    values.status = {name: currentPriceBand.status, value: currentPriceBand.status};

    values = {
      ...values,
      rules: convertTypeToInitialValues('Excess'),
      labour_rates: convertTypeToInitialValues('Labour Rate'),
      sundries: convertTypeToInitialValues('Sundry'),
      excess_default: getDefault('Excess'),
      labour_rate_default: getDefault('Labour Rate'),
    }
    return values
  }

  //We need the cover levels for the selected price band.
  //This gets all the prices, condenses to the allowed cover levels and displays those.
  //It then re-iterates through everything to find the table headings/durations available and adds that info.
  //Returns [{id, name, durations: [{title: '12 months', value: 12}, [...claimLimits]}]
  //And the duration is sorted by the value field.
  //And then it retrieves all the claim limits for the cover level too.
  //And then finally adds the prices in the correct format keys to the prices state object
  const processPriceBandValues = (prices) => {
    let returnData = {};
    let coverLevels = prices.map(price => (price.cover_level))
    coverLevels = uniqBy(coverLevels, (coverLevel => (coverLevel.id)))
    coverLevels = coverLevels.map(cl => ({...cl,
      durations: sortBy(uniq(prices.filter(price => price.cover_level.id === cl.id).map(price => price.duration))
        .map(duration => ({
          title: duration,
          value: parseInt(duration.split(' ')[0]),
        })), (d => (d.value)))
    }))
    coverLevels = coverLevels.map(cl => ({...cl,
      claimLimits: uniq(prices.filter(price => price.cover_level.id === cl.id).map(price => price.claim_limit).sort((a, b) => (a - b))),
    }))
    returnData.coverLevels = coverLevels;
    let formattedPrices = {};
    prices.forEach((price) => {
      formattedPrices[`_${price.cover_level.id}_${price.claim_limit}_${price.duration.split(' ')[0]}`] = price.price;
    });
    returnData.prices = formattedPrices;
    return returnData;
  }

  const [coverLevels, setCoverLevels] = useState([]);
  const [durations, setDurations] = useState([]);
  const [prices, setPrices] = useState({});

  const [basePriceBand, setBasePriceBand] = useState(null);

  useEffect(() => {
    if (!dealer) return;
    api.get(`/admin/price-bands/${basePriceBandId.id}`)
      .then(res => {
        setBasePriceBand(res.data);
      })
      .catch((err) => {
        console.log(err)
        NotificationManager.error('Oops! Something went wrong fetching the cover levels for this dealer.')
      })
  }, [basePriceBandId])

  //Merge the two together to ensure we get the same durations if they have been added, and extra cover levels from the base price band.
  useEffect(() => {
    if (!basePriceBand) return;
    if (currentPriceBand === false) return;
    let baseBandData = processPriceBandValues(basePriceBand.prices)
    let currentBandData = processPriceBandValues(get(currentPriceBand, 'prices', []))
    let coverLevels = baseBandData.coverLevels;
    coverLevels = coverLevels.map(level => {
      let currentBandLevel = currentBandData.coverLevels.find((cb) => (cb.id === level.id)) //Attempt to find the current band cover level
      if (currentBandLevel) {
        level.durations = unionBy(level.durations, currentBandLevel.durations, v => v.value)
      }
      return level;
    })
    //We don't want to merge cover levels - this adds cover levels to the interface which don't matter for this price band.
    //coverLevels = unionBy(coverLevels, currentBandData.coverLevels, cv => cv.id)
    setCoverLevels(coverLevels)
    setPrices(baseBandData.prices)
  }, [basePriceBand, currentPriceBand])

  const [oldPrices, setOldPrices] = useState([]);
  useEffect(() => {
    let references = Object.keys(prices);
    let valuePrices = get(formValues, 'prices', {});
    let referencesToChange = [];
    references.forEach(ref => {
      if (get(valuePrices, ref, false) && (get(oldPrices, ref, null) === get(valuePrices, ref, null))) {
        referencesToChange.push(ref);
      }
    });
    referencesToChange.forEach(ref => {
      dispatch(change('dealerCustom/pricingForm', `prices.${ref}`, prices[ref]))
    })
    setOldPrices(prices);
  }, [prices]);

  const resetPricesToBase = () => {
    let references = Object.keys(prices)
    references.forEach((ref) => {
      dispatch(change('dealerCustom/pricingForm', `prices.${ref}`, prices[ref].toFixed(2)))
    })
  }

  //Side effect to ensure if we don't have an existing price band then it'll set the values to default.
  useEffect(() => {
    if (currentPriceBand !== null) return //If price band isn't set yet, or has some values, don't do this
    if (prices === {}) return //If current base prices haven't been set, don't do anything
    resetPricesToBase();
  }, [prices, currentPriceBand])


  const transformFormValues = (values) => {
    let postData = {};
    if (values.status) {
      postData.status = values.status.value;
    }
    postData.prices = [];
    Object.keys(values.prices).forEach(ref => {
      let refParts = ref.split('_')
      let p = {
        cover_level_id: refParts[1],
        claim_limit: refParts[2],
        duration: (refParts[3] === 1) ? refParts[3] + ' month' : refParts[3] + ' months',
        price: get(values, `prices.${ref}`, 0),
      }
      postData.prices.push(p)
    })

    postData.rules = [];
    if (values.labour_rates) {
      values.labour_rates.forEach((lr, idx) => {
        postData.rules.push(pricingRuleValue(lr, 'Labour Rate', (values.labour_rate_default === idx)))
      })
    }
    //Excess = rules
    if (values.rules) {
      values.rules.forEach((e, idx) => {
        postData.rules.push(pricingRuleValue(e, 'Excess', (values.excess_default === idx)))
      })
    }
    if (values.sundries) {
      values.sundries.forEach((s, idx) => {
        postData.rules.push(pricingRuleValue(s, 'Sundry', false))
      })
    }

    return postData;
  }

  const pricingRuleValue = (rule, type, isDefault) => {
    let cover_levels = [];
    rule.cover_levels.forEach((val, key) => {
      if (val) {
        cover_levels.push(key);
      }
    })
    return {
      id: get(rule, 'id', null),
      type,
      title: rule.title,
      impact_positive: (rule.effect.value === '+'),
      value: rule.value,
      cost_type: (rule.cost_type.value === '%' ? 'Percentage' : 'Absolute'),
      default: isDefault,
      active: rule.active || false,
      delete: rule.delete,
      cover_levels,
      durations: rule.durations || null,
    }
  }

  const executeUpdateRequest = (data) => {
    if (currentPriceBand === null || isDefaultPriceBand) {
      return api.post(`/external-sales/dealers/${id}/custom-price-band`, data)
    } else {
      return api.patch(`/external-sales/dealers/${id}/custom-price-band`, data)
    }
  }

  const handleSubmit = (values) => {
    api
      .patch(`/external-sales/dealers/${id}/pricing`, {base_price_band_id: basePriceBandId.id, allow_dealer_pricing: true})
      .then(() => {
        executeUpdateRequest(transformFormValues(values))
          .then(() => {
            NotificationManager.success('Woohoo! Custom Price band updated.')
            history.push(`/external-sales/dealers/${id}/edit`)
          })
          .catch(err => {
            console.log(err);
            NotificationManager.error('Oops! There was an error updating the custom price band.')
          })
      }).catch((err) => {
        console.log(err);
      NotificationManager.error('Oops! There was an error setting the base price band.')
    })
  };

  return <>
    {dealer && <>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/external-sales/dealers" className="open active">
            Dealers
          </Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={`/external-sales/dealers/${id}/edit`} className="open active">
            {dealer.name}
          </Link>
        </li>
        <li className="breadcrumb-item">
          <span className="active">Custom Pricing</span>
        </li>
      </ol>
      <div className="container-fluid animated fadeIn">
        <CustomPricingHeader
          title="Custom Pricing"
          dealer={dealer}
          action={resetPricesToBase}
        />
        <div style={{ maxWidth: '275px' }}>
          <BasePriceBandForm dealer={dealer} />
        </div>
        <ul className="list-info mb-4" style={{ maxWidth: '570px' }}>
          <li>Prices exclude VAT</li>
          <li>
            A price of &pound;0 will prevent the warranty / duration / claim limit from being chosen
            when setting up a policy.
          </li>
        </ul>
        {/*<div className="d-flex mb-3 align-items-center">*/}
        {/*  <button*/}
        {/*    type="button"*/}
        {/*    className="btn btn-warning btn-sm font-weight-bold mr-3"*/}
        {/*    onClick={() => {}}*/}
        {/*  >*/}
        {/*    Add special duration*/}
        {/*  </button>*/}
        {/*  <p className="mb-0">e.g.Pay for 24 months, drive for 36.</p>*/}
        {/*</div>*/}
      </div>
      <CustomPricingForm
        initialValues={initializeValues()}
        dealer={dealer}
        coverLevels={coverLevels}
        durations={durations}
        allPrices={prices}
        allValues={get(formValues, 'prices', {})}
        onSubmit={handleSubmit}
        hasExistingPriceBand={currentPriceBand !== null}
        currentStatus={currentPriceBand ? currentPriceBand.status : null}
      />
    </>}
  </>
}

const basePriceBandSelector = formValueSelector('dealerCustom/basePriceBandForm');

export default connect((state => ({
  basePriceBandId: basePriceBandSelector(state, 'base_price_band_id'),
  formValues: getFormValues('dealerCustom/pricingForm')(state)
})))(CustomPrices)
