import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { selectInputStyles, sortCaret } from '../../../utils/misc'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import BootstrapTable from 'react-bootstrap-table-next'
import api from '../../../utils/api'
import Select from 'react-select'
import DatePicker from 'react-datepicker/es'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import Avatar from "../../ui/Avatar";

const TerritoryAccountManagersTable = ({ territory, fetchTerritory }) => {
  const [editModal, setEditModal] = useState(false)
  const [accountManagers, setAccountManagers] = useState(false)
  const newAccManagerRef = useRef(null)
  const [errors, setErrors] = useState()
  const [manager] = useState({})

  const [form, setForm] = useState({
    isValid: null,
    account_manager: manager || null,
    selectedAccManager: null, // if user edits current account manger and selects different one this will get populated
  })

  useEffect(() => {
    getAllAccountManagers()
  }, [])

  const [columns] = useState([
    {
      dataField: 'name',
      text: 'Account manager',
      sort: true,
      sortCaret,
      formatter: (cell, row) => (
        <div className="d-flex align-items-center">
          <Avatar user={row.user} />&nbsp;
          {row.name}
        </div>
      ),
    },
    {
      dataField: 'start_date',
      text: 'Start date',
      headerStyle: {
        width: 110,
      },
    },
    {
      dataField: 'end_date',
      text: 'End date',
      headerStyle: {
        width: 110,
      },
    },
    {
      dataField: 'id',
      text: 'Actions',
      headerStyle: {
        width: 80,
      },
      formatter: (cell, row) => (
        <>
          <Link
            data-tip="Edit account manager"
            data-place="top"
            to={`#`}
            className="table-action-btn table-action-btn--edit"
            size="sm"
            onClick={() => {
              setEditModal(true)
              let fullName = row.name.split(' ')
              setForm({
                ...form,
                account_manager: {
                  ...row,
                  first_name: fullName[0],
                  last_name: fullName[1],
                  person_id: row.person_id,
                },
              })
            }}
          >
            <span className="icon-note"/>
          </Link>
          <ReactTooltip effect="solid"/>
        </>
      ),
    },
  ])

  const handleSubmit = e => {
    e.preventDefault()

    let startDate = form.account_manager.start_date
    let endDate = form.account_manager.end_date

    // Territory-person-realtionship ID
    let tprID = form.account_manager.id

    let person_id = form.account_manager.person_id

    let data = {
      territory_id: territory.id,
      person_id,
      start_date: startDate
        ? startDate
          .split('/')
          .reverse()
          .join('-')
        : null,
      end_date: endDate
        ? endDate
          .split('/')
          .reverse()
          .join('-')
        : null,
    }

    api
      .patch(`admin/territories/account-managers/${tprID}`, data)
      .then(() => {
        setEditModal(false)
        NotificationManager.success('Woohoo! Your changes were saved.')
        fetchTerritory(territory.id)
      })
      .catch(err => {
        if(err && err.response.status === 422) {
          console.log(err.response)
          setErrors(err.response.data.errors)
        }
        NotificationManager.error(
          'Oops! Something went wrong. Please try again after refreshing.',
        )
      })
  }

  let datePickerProps = {
    placeholderText: 'DD/MM/YYYY',
    dateFormat: 'dd/MM/yyyy',
    peekNextMonth: true,
    showMonthDropdown: true,
    showYearDropdown: true,
    yearDropdownItemNumber: 3,
  }
  const stringToDate = (_date, _format, _delimiter) => {
    let formatLowerCase = _format.toLowerCase()
    let formatItems = formatLowerCase.split(_delimiter)
    let dateItems = _date.split(_delimiter)
    let monthIndex = formatItems.indexOf('mm')
    let dayIndex = formatItems.indexOf('dd')
    let yearIndex = formatItems.indexOf('yyyy')
    let month = parseInt(dateItems[monthIndex])
    month -= 1
    let formatedDate = new Date(
      dateItems[yearIndex],
      month,
      dateItems[dayIndex],
    )
    return formatedDate
  }

  const getAllAccountManagers = query =>
    new Promise((resolve, reject) => {
      api
        .get(`admin/territories/account-managers?query=${query ? query : ''}`)
        .then(res => {
          setAccountManagers(res.data.data)
        })
        .catch(err => setAccountManagers([]))
    })

  return (
    territory && (
      <>
        <div className="row">
          <div className="col">
            <BootstrapTable
              keyField={'id'}
              data={territory.account_managers}
              noDataIndication={'No matching records.'}
              columns={columns}
              striped
              hover
              condensed
            />

            <div className="row align-items-center">
              <div className="col text-muted small">
                <small>
                  Showing 0 to {territory.account_managers.length} entries
                </small>
              </div>
            </div>
          </div>
        </div>

        {editModal && territory && (
          <div className="react-confirm-alert-overlay">
            <div className="react-confirm-alert">
              <div
                className="react-confirm-alert-body"
                style={{ width: '40rem' }}
              >
                <span
                  className="btn-close-modal fa fa-close"
                  onClick={() => setEditModal(false)}
                />

                <h3 className="mb-0">Edit Account Manager</h3>
                <h4
                  className="text-black-50 font-weight-normal text-capitalize mb-4"
                  style={{ fontSize: '1.12rem' }}
                >
                  {territory.name}
                </h4>

                {form.account_manager && (
                  <form
                    onSubmit={handleSubmit}
                    className="editAccountManagerModal"
                  >
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="newAccountManager">
                            Account Manager
                          </label>
                          <Select
                            styles={selectInputStyles}
                            value={
                              form.selectedAccountManager ||
                              form.account_manager
                            }
                            onChange={value =>
                              setForm({
                                ...form,
                                selectedAccManager: value,
                                account_manager: {
                                  ...form.account_manager,
                                  first_name: value.first_name,
                                  last_name: value.last_name,
                                  person_id: value.id,
                                },
                              })
                            }
                            getOptionLabel={option =>
                              `${option.first_name} ${option.last_name}`
                            }
                            getOptionValue={option => option.id}
                            options={accountManagers || []}
                            ref={newAccManagerRef}
                            name={'account_manager'}
                          />

                          {errors && errors.account_manager && (
                            <div className="invalid-feedback">
                              {errors.account_manager}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="accManagerStartDate">
                            Account Manager start date
                          </label>
                          <div className="input-group d-flex flex-nowrap">
                            <span className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="icon-calendar"/>
                              </span>
                            </span>

                            <DatePicker
                              {...datePickerProps}
                              selected={
                                form.account_manager.start_date
                                  ? stringToDate(
                                  form.account_manager.start_date,
                                  'DD/MM/YYYY',
                                  '/',
                                  )
                                  : null
                              }
                              className={`form-control w-225 ${errors &&
                              errors.start_date &&
                              'is-invalid'}`}
                              name={'start_date'}
                              autoComplete={'off'}
                              onChange={value =>
                                setForm({
                                  ...form,
                                  account_manager: {
                                    ...form.account_manager,
                                    start_date: value
                                      ? moment(value).format('DD/MM/YYYY')
                                      : '',
                                  },
                                })
                              }
                            />
                          </div>

                          {errors && errors.start_date && (
                            <div className="invalid-feedback d-block">
                              {errors.start_date[0]}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="accManagerStartDate">
                            Account Manager end date (optional)
                          </label>
                          <div className="input-group d-flex flex-nowrap">
                            <span className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="icon-calendar"/>
                              </span>
                            </span>
                            <DatePicker
                              {...datePickerProps}
                              selected={
                                form.account_manager.end_date
                                  ? stringToDate(
                                  form.account_manager.end_date,
                                  'DD/MM/YYYY',
                                  '/',
                                  )
                                  : null
                              }
                              className={`form-control w-225 ${errors &&
                              errors.end_date &&
                              'is-invalid'}`}
                              name={'end_date'}
                              autoComplete={'off'}
                              onChange={value =>
                                setForm({
                                  ...form,
                                  account_manager: {
                                    ...form.account_manager,
                                    end_date: value
                                      ? moment(value).format('DD/MM/YYYY')
                                      : '',
                                  },
                                })
                              }
                            />

                            {errors && errors.end_date && (
                              <div className="invalid-feedback d-block">
                                {errors.end_date[0]}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group mt-3">
                          <button className="btn btn-secondary">
                            Save changes
                          </button>
                          <button
                            onClick={() => setEditModal(false)}
                            className="btn btn-outline"
                            style={{ color: '#70b725' }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    )
  )
}

TerritoryAccountManagersTable.propTypes = {
  territory: PropTypes.object.isRequired,
  fetchTerritory: PropTypes.func.isRequired,
}

export default TerritoryAccountManagersTable
