import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { checked, echo } from '../../../../../utils/misc';
import AddedBy from '../../../../ui/AddedBy';
import SlideToggle from "../../../../ui/SlideToggle";
import InvoicingForm from "./forms/InvoicingForm";
import { NotificationManager } from 'react-notifications';
import { SubmissionError } from 'redux-form';
import { pick } from 'lodash';
import api from '../../../../../utils/api';

const Invoicing = ({ dealer, setDealer }) => {
  const [showForm, setShowForm] = useState(false);

  const handleInvoiceForm = values => {
    const data = pick(values, ['show_vrms_on_invoice', 'show_policy_refs_on_invoice', 'exclude_from_debtor_ledger', 'invoice_cycle', 'invoice_email', 'invoice_header'])
    data.invoice_cycle = data.invoice_cycle.value

    return api.patch(`/external-sales/dealers/${dealer.id}/invoicing`, data)
    .then(res => {
      setDealer(res.data)
      NotificationManager.success('Woohoo! You updated the invoicing.');
      setShowForm(false)
    })
    .catch(err => {
      if (err && err.response.status === 422) {
        throw new SubmissionError({
          ...err.response.data.errors,
          _error: err.response.data.message
        })
      }
    })
  }
  return (
      <div className="edit-dealer-column border-bottom flex-grow-1" style={{ maxWidth: 340 }}>
        <div className="edit-dealer-invoicing edit-dealer-column mt-4" style={{ maxWidth: 275, marginTop: 50 }}>
          <header className="d-flex align-items-center justify-content-between" style={{ marginBottom: 16 }}>
            <h6 className="mb-0 mt-1 text-uppercase">Invoicing</h6>
            <button
              className="btn-gray"
              data-tip="Edit invoicing"
              data-place="top"
              onClick={() => setShowForm(!showForm)}
            >
              <i className="wf-icon-edit d-block" />
            </button>
          </header>

          {!showForm && (
            <>
              <ul className="pl-0">
                <li className="d-flex align-items-center mb-1">
                  {checked(dealer.show_vrms_on_invoice)}{' '}
                  <span className="font-md">Show registration on invoice</span>
                </li>
                <li className="d-flex align-items-center mb-1">
                  {checked(dealer.exclude_from_debtor_ledger)}{' '}
                  <span className="font-md">Exclude from debtor ledger</span>
                </li>
              </ul>

              <div className="row">
                <div className="col">
                  <span className="label">Invoice cycle</span>
                  <p className="font-md">{echo(dealer.invoice_cycle)}</p>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <span className="label">Invoice email address</span>
                  <p className="font-md">{echo(dealer.invoice_email)}</p>
                </div>
              </div>
            </>
          )}

          <SlideToggle isVisible={showForm}>
            <InvoicingForm onSubmit={handleInvoiceForm} cancel={setShowForm} setDealer={setDealer} initialValues={{...dealer, invoice_cycle: { value: dealer.invoice_cycle}}}  />
          </SlideToggle>

          <hr className="my-4" />

          <AddedBy user={{id: 1, first_name: 'John', last_name: 'Doe', avatar: null}} />
        </div>
      </div>
  );
};

Invoicing.propTypes = {
  dealer: PropTypes.object.isRequired,
  setDealer: PropTypes.func.isRequired
};

export default Invoicing;
