import React, { useEffect, useRef, useState } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import PageHeader from '../../../../ui/PageHeader';
import Table from '../../../../ui/Table';
import { Link } from 'react-router-dom';
import Avatar from '../../../../ui/Avatar';
import useModal from '../../../../../hooks/useModal';
import api from '../../../../../utils/api';
import AssignModal from '../../../../modals/AssignModal';
import { clone, uniqBy } from 'lodash';
import DeleteModal from '../../../../modals/DeleteModal';
import RestoreModal from '../../../../modals/RestoreModal';
import PrioritiseField from '../../../../ui/PriortiseField';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import { has } from 'lodash';
import { sortCaret } from '../../../../../utils/misc';
import ReactTooltip from 'react-tooltip';
import pDebounce from '../../../../../utils/pDebounce';
import AsyncSelect from 'react-select/lib/Async'
import { hasPermission } from '../../../../../utils/auth';

const ProspectsPage = () => {
  const [state, setState] = useState({
    filters: [],
    filterGroups: [
      {
        name: 'Deleted',
        allowOnlyOne: true,
        singleValueFilter: true,
        filters: [
          {
            id: 1,
            name: 'Deleted',
            value: true,
          },
        ],
      },
    ],
    query: '',
    page: 1,
    limit: 10,
    order: 'id', // show newest quotes first by default
    desc: true,
    searching: false,
    selectedRows: [],
  });

  const [priority, setPriority] = useState(false);
  const [assignee, setAssignee] = useState(null);
  const [prospects, setProspects] = useState(null);

  const {
    isShowing: isShowingAssignModal,
    toggle: toggleAssignModal,
  } = useModal();
  const {
    isShowing: isShowingDeleteModal,
    toggle: toggleDeleteModal,
  } = useModal();
  const {
    isShowing: isShowingRestoreModal,
    toggle: toggleRestoreModal,
  } = useModal();

  let url = `?query=${state.query}&page=${state.page}&desc=${state.desc}&limit=${state.limit}`;

  if (state.order !== '') url = `${url}&order=${state.order}`;

  let pipelines = state.filters.filter((f) => f.group_name === 'Pipeline lane');
  if (pipelines.length > 0) url = `${url}&lane_id=${pipelines[0].id}`;

  let deletedFilter = state.filters.filter((f) => f.group_name === 'Deleted');
  let deleted = false;

  if (deletedFilter.length === 1) {
    url = `${url}&deleted=1`;
    deleted = true;
  } else {
    deleted = false;
  }

  let assigneeFilter = state.filters.filter((f) => f.group_name === 'Assignee');

  if (assigneeFilter.length === 1) {
    url = `${url}&assignee_id=${assigneeFilter[0].id}`;
  }

  useEffect(() => {
    api.get('direct-sales/pipeline/lanes').then((res) => {
      setState((prevState) => ({
        ...prevState,
        filterGroups: [
          {
            name: 'Pipeline lane',
            filters: res.data.data,
            allowOnlyOne: true,
          },
          ...prevState.filterGroups,
        ],
      }));
    });

    getAssignees()
  }, []);

  const fetchProspects = () => {
    api
      .get(`/direct-sales/prospects${url ? url : ''}`)
      .then((res) => {
        setProspects(res.data);
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing.'
          );
        }
      });
  }

  /**
   * Fetch prospects
   */
  useEffect(() => {
    fetchProspects()
  }, [url]);

  const fetchAssignees = (val) => {
    return new Promise((resolve, reject) => {
      api
        .get('/direct-sales/prospects/assignees?limit=999&query=' + (val || ''))
        .then(res => {
          resolve(res.data.data)
        })
        .catch(err => {
          if (err) {
            reject([])
          }
        })
    })
  }

  const loadAssigneeOptions = pDebounce((val) => {
    return fetchAssignees(val)
  }, 1000)

  const singleAssignRef = useRef(null);

  const getAssignees = () =>
    fetchAssignees().then(data => {
      let assignees = data;

      assignees = assignees.map((assignee, index) => {
        assignee.name = (
          <div
            className="d-flex align-items-center"
            style={{ marginBottom: '-2px' }}
            key={index}
          >
            <Avatar user={assignee} size="xsmall" />
            <span className="font-md ml-1" style={{ marginTop: '-2px' }}>
              {assignee.first_name} {assignee.last_name}
            </span>
          </div>
        );
        assignee.avatar = false;
        return assignee;
      });

      setState((prevState) => ({
        ...prevState,
        filterGroups: [
          { name: 'Assignee', allowOnlyOne: true, filters: assignees },
          ...prevState.filterGroups,
        ],
      }));
    });

  const options = {
    showOptions: false,
    columns: [
      {
        dataField: 'created_at',
        text: 'Quote date',
        headerStyle: {
          width: 150,
        },
        sort: true,
        sortCaret,
        formatter: (cell, row) => moment(row.created_at).format('DD/MM/YYYY'),
      },
      {
        dataField: 'ref',
        text: 'Quote number',
        sort: true,
        sortCaret,
        formatter: (cell, row) => (
          <Link to={`/direct-sales/prospects/${row.id}/quote`} key={row.id}>
            {row.ref}
          </Link>
        ),
      },
      {
        dataField: 'source',
        text: 'Quote source',
        headerStyle: {
          width: 200,
        },
      },
      {
        dataField: 'created_by',
        text: 'Quoted by',
        headerStyle: {
          width: 100,
        },
        formatter: (cell, row) =>
          row.source === 'Call' && row.created_by ? <Avatar user={row.created_by} /> : null,
      },
      {
        dataField: 'policyholder',
        text: 'Driver name',
        formatter: (cell, row) => (
          <Link to={`/direct-sales/prospects/${row.policyholder.id}/timeline`} key={row.id}>
            {row.policyholder.title} {row.policyholder.first_name} {row.policyholder.last_name}
          </Link>
        ),
      },
      {
        dataField: 'vehicle.vrm',
        text: 'Vehicle reg',
        headerStyle: {
          width: 130,
        },
      },
      {
        dataField: 'start_date',
        text: 'Start date',
        headerStyle: {
          width: 150,
        },
        sort: true,
        sortCaret,
        formatter: (cell, row) => moment(row.start_date).format('DD/MM/YYYY'),
      },
      {
        dataField: 'pipeline_item.lane.name',
        text: 'Pipeline lane',
        headerStyle: {
          width: 130,
        },
      },
      {
        dataField: 'assignee',
        text: 'Assignee',
        hidden: deleted,
        formatter: (cell, row) => {
          if (!row.pipeline_item || !row.pipeline_item.assignee) {
            if (hasPermission('direct_sales.prospects.assign')) {
              return (
                <div
                  data-tip="Assign to agent"
                  className="wf-icon-assign"
                  ref={singleAssignRef}
                  style={{ cursor: 'pointer' }}
                  onClick={() => showAssignModal(row)}
                />
              );
            }

            return (
              <></>
            )
          }

          return <Avatar user={row.pipeline_item.assignee} />;
        },
        headerStyle: {
          width: 100,
        },
      },
      {
        dataField: 'id',
        text: 'Action',
        hidden: !deleted,
        headerStyle: {
          width: 90,
        },
        formatter: (cell, row) => {
          return (
            <div className={'d-flex'}>
              {deleted && (
                <span
                  data-tip="Restore"
                  data-place="top"
                  className="table-action-btn"
                  onClick={() => {
                    setState((pre) => ({
                      ...pre,
                      selectedRows: uniqBy([...pre.selectedRows, row], 'id'),
                    }));
                    toggleRestoreModal();
                  }}
                >
                  <span
                    className="wf-icon-restore d-inline-block"
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                </span>
              )}

              <ReactTooltip effect="solid" />
            </div>
          );
        },
      },
    ],
  };

  const showAssignModal = (row) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows: uniqBy([...prevState.selectedRows, row], 'id'),
    }));

    toggleAssignModal();
  };
  const handleAssignModalSubmit = (values) => {
    api
      .patch('/direct-sales/prospects', {
        action: 'assign',
        ids: state.selectedRows.map((r) => r.id),
        assignee_id: assignee,
        prioritised: priority,
      })
      .then((res) => {
        toggleAssignModal();
        if (state.selectedRows.length > 1)
          NotificationManager.success(
            'Woohoo! You assigned multiple prospects.'
          );
        else NotificationManager.success('Woohoo! You assigned a prospect.');

        let copy = clone(prospects.data);
        res.data.forEach((row) => {
          let index = copy.findIndex((item) => item.id === row.id);
          copy.splice(index, 1, row);
        });

        setProspects((pre) => ({
          ...pre,
          data: copy,
        }));

        setState((pre) => ({
          ...pre,
          selectedRows: [],
        }));

        setPriority(false);
        setAssignee(null);
      })
      .catch((err) => {
        if (err && err.response.status === 422) {
          let errors = err.response.data.errors;
          if (has(errors, 'assignee_id')) {
            NotificationManager.error(errors.assignee_id[0]);
          } else {
            NotificationManager.error(
              'Oops! Something went wrong. Please try again after refreshing.'
            );
            toggleAssignModal();
          }
        }
      });
  };

  const handleSearch = (query, filters) => {
    setState((pre) => ({ ...pre, query, filters }));
  };

  const removeRowsOnCallbackStatus = (status) => {
    // remove selected table rows
    if (status === 'ok') {
      fetchProspects()
    }
  };

  const tableActions = [
    {
      iconClass: 'wf-icon-assign wf-icon-assign--table-action font-lg',
      callback: () => toggleAssignModal(),
      isVisible: hasPermission('direct_sales.prospects.assign'),
      tooltip: {
        text: 'Assign to agent',
        place: 'top',
      },
    },
    {
      iconClass: 'wf-icon-delete text-danger',
      callback: () => toggleDeleteModal(),
      isVisible: hasPermission('direct_sales.prospects.delete') && !deleted,
      tooltip: {
        text: 'Delete',
        place: 'top',
      },
    },
    {
      iconClass: 'wf-icon-restore text-success',
      callback: () => toggleRestoreModal(),
      isVisible: deleted,
      tooltip: {
        text: hasPermission('direct_sales.prospects.restore') && 'Restore',
        place: 'top',
      },
    },
  ];

  const handleTableChange = (
    type,
    { sortField, sortOrder = 'desc', page, sizePerPage }
  ) => {
    setState({
      ...state,
      page: page || 1,
      desc: sortOrder === 'desc' ? true : false,
      limit: sizePerPage || 10,
      order: sortField || '',
    });
  };

  const handleRowSelect = (row, isSelect, rowIndex, e) => {
    if (isSelect) {
      setState((prevState) => ({
        ...prevState,
        selectedRows: [...state.selectedRows, row],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        selectedRows: [...state.selectedRows.filter((r) => r.id !== row.id)],
      }));
    }
  };

  const handleRowSelectAll = (isSelect, rows) => {
    isSelect
      ? setState({
          ...state,
          selectedRows: rows,
        })
      : setState({
          ...state,
          selectedRows: [],
        });
  };

  const handleCheckboxChange = () => {};

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    selected: state.selectedRows.map((row) => row.id),
    onSelect: handleRowSelect,
    onSelectAll: handleRowSelectAll,
    selectionRenderer: ({ mode, rowIndex, ...rest }) => {
      return (
        <div className="text-center lh-1 position-relative align-checkbox">
          <div className="custom-control custom-checkbox custom-control-inline">
            <input
              className="custom-control-input"
              type={mode}
              {...rest}
              rowindex={rowIndex}
              onChange={(e) => this.handleCheckboxChange(e, rowIndex)}
              style={{ width: 10, height: 10 }}
              id={`checkbox-${rowIndex}`}
            />
            <label
              htmlFor={`checkbox-${rowIndex}`}
              className="custom-control-label"
              style={{ pointerEvents: 'none' }}
            />
          </div>
        </div>
      );
    },
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => {
      return (
        <div className="text-center lh-1 position-relative align-checkbox">
          <div className="custom-control custom-checkbox custom-control-inline">
            <input
              className="custom-control-input"
              type={mode}
              checked={checked}
              onChange={handleCheckboxChange}
            />
            <label className="custom-control-label" />
          </div>
        </div>
      );
    },
  };

  return (
    <>
      <br />
      <div className="container-fluid animated fadeIn">
        <PageHeader
          title="Prospects"
          description="Assign single or multiple prospects to your team from the table below. You’ll be able to see all assigned prospects in your team pipeline."
        />
        <br />

        {prospects && (
          <Table
            resource={prospects}
            selected={state.selectedRows}
            selectRow={selectRow}
            showOptions={options.showOptions}
            columns={options.columns}
            hasTableSearch={true}
            onSearch={handleSearch}
            handleTableChange={handleTableChange}
            omnibox={{
              groups: state.filterGroups,
            }}
            searching={false}
            tableActions={tableActions}
            minSelection={0}
          />
        )}

        <AssignModal
          title={'Assign prospect'}
          hide={toggleAssignModal}
          isShowing={isShowingAssignModal}
          onSubmit={handleAssignModalSubmit}
          toggle={toggleAssignModal}
          assignTo={'prospects'}
          onCancel={() => {
            toggleAssignModal();
            setState({ ...state, selectedRows: [] });
          }}
          selected={state.selectedRows.map((r) => r.policyholder)}
        >
          <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={loadAssigneeOptions}
            placeholder={'Select an agent'}
            onChange={option => setAssignee(option.id)}
            getOptionValue={user => user.id}
            getOptionLabel={user => (
              <div className="d-flex">
                <Avatar user={user} size="small"/>
                <div className="ml-3">{`${user.first_name} ${user.last_name}`}</div>
              </div>
            )}
            styles={{
              control           : base => ({
                ...base,
                height   : 48,
                minHeight: 48,
              }),
              menuList          : base => ({
                ...base,
                paddingTop   : 0,
                paddingBottom: 0,
              }),
              indicatorSeparator: () => ({ display: 'none' }),
            }}
          />

          <PrioritiseField onValue={(value) => setPriority(value)} />
        </AssignModal>

        <DeleteModal
          toggle={toggleDeleteModal}
          endPoint={'/direct-sales/prospects'}
          onDone={removeRowsOnCallbackStatus}
          isShowing={isShowingDeleteModal}
          onCancel={() => {
            toggleDeleteModal();
            setState({
              ...state,
              selectedRows: [],
            });
          }}
          items={state.selectedRows.map((row) => row.id)}
        >
          {state.selectedRows.length === 1 && (
            <>
              Delete{' '}
              <strong>
                {state.selectedRows[0].policyholder.first_name}{' '}
                {state.selectedRows[0].policyholder.first_name}
              </strong>{' '}
              from your prospects? You can undo this later.
            </>
          )}
          {state.selectedRows.length > 1 && (
            <>
              Delete <strong>{state.selectedRows.length}</strong> prospects from
              your table? You can undo this later.
            </>
          )}
        </DeleteModal>

        <RestoreModal
          toggle={toggleRestoreModal}
          endPoint={'direct-sales/prospects'}
          isShowing={isShowingRestoreModal}
          onDone={removeRowsOnCallbackStatus}
          onCancel={() => {
            toggleRestoreModal();
            setState({
              ...state,
              selectedRows: [],
            });
          }}
          items={state.selectedRows.map((row) => row.id)}
        >
          {state.selectedRows.length === 1 && (
            <>
              Restore{' '}
              <strong>
                {state.selectedRows[0].policyholder.first_name}{' '}
                {state.selectedRows[0].policyholder.last_name}
              </strong>{' '}
              to your prospects table?
            </>
          )}
          {state.selectedRows.length > 1 && (
            <>
              Restore <strong>{state.selectedRows.length}</strong> prospects to
              your table?
            </>
          )}
        </RestoreModal>
      </div>
    </>
  );
};

export default ProspectsPage;
