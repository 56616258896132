import React from 'react'
import { Field, reduxForm, Form } from 'redux-form'
import { vehicleTypes } from '../../../utils/misc';
import renderField from '../../../utils/renderField'
import CustomSelect from '../../ui/CustomSelect';

const validate = values => {
  let errors = {}

  if (!values.name) errors.name = 'Please give this cover level a name.'
  if (values.name && values.name.length < 2)
    errors.name = 'Must be 2 characters or more.'
  if (values.name && values.name.length > 50)
    errors.name = 'Must be 50 characters or less.'

  if (!values.max_mileage) errors.max_mileage = 'Please add a maximum mileage.'
  if (values.max_mileage && isNaN(Number(values.max_mileage)))
    errors.max_mileage = 'Must be a number.'

  if (values.max_mileage && Number(values.max_mileage) && values.max_mileage > 999999)
    errors.max_mileage = 'The max mileage may not be greater than 999,999.'


  if (!values.max_age) errors.max_age = 'Please add a maximum age.'
  if (values.max_age && isNaN(Number(values.max_age)))
    errors.max_age = 'Must be a number.'

  if (values.max_age && Number(values.max_age) && values.max_age > 99)
    errors.max_age = 'The max age may not be greater than 99.'

  if (!values.max_price) errors.max_price = 'Please add a maximum retail price.'
  if (values.max_price && isNaN(Number(values.max_price)))
    errors.max_price = 'Must be a number.'

  if (values.max_price && Number(values.max_price) && values.max_price > 5000000)
    errors.max_price = 'The max retail price may not be greater than 5,000,000.'

  if (!values.claim_limits)
    errors.claim_limits = 'Please add a claim limit to this cover level'

  return errors
}

const CreateCoverLevelForm = props => {
  const {handleSubmit, onSubmit, cancel} = props

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
      <h2 className="mb-4">Add a cover level</h2>

      <div className="row">
        <div className="col-md-12">
          <Field
            type="text"
            label="Cover level name"
            component={renderField}
            name={`name`}
            className="form-control"
            placeholder="e.g. Dynamic"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 b-form-group">
          <Field
            type="text"
            label="Max. mileage"
            component={renderField}
            name="max_mileage"
            className="form-control"
          />
        </div>
        <div className="col-md-6 b-form-group">
          <Field
            type="text"
            label="Max. Age (years)"
            component={renderField}
            name="max_age"
            className="form-control"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 b-form-group">
          <Field
            type="text"
            label="Max. retail price"
            component={renderField}
            name="max_price"
            className="form-control"
            fieldIcon="wf-icon-gbp"
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <Field
              name="vehicle_types"
              isMulti
              component={CustomSelect}
              label="Vehicle Types"
              options={vehicleTypes}
              getOptionValue={option => option}
              getOptionLabel={option => option}
            />
          </div>
        </div>
      </div>

      <hr/>

      <div className="row mt-5">
        <div className="col">
          <h6 style={{marginBottom: '1.875rem'}}>Add claim limits to this cover level</h6>
          <p>Separate multiple limits with a comma e.g. 100, 150.</p>

          <Field
            type="text"
            component={renderField}
            name="claim_limits"
            className="form-control"
            fieldIcon="wf-icon-gbp"
          />
        </div>
      </div>

      <div className="form-group mt-3">
        <button disabled={!props.valid} className="btn btn-secondary">
          Add cover level
        </button>
        <button className="btn btn-outline btn-form-cancel" onClick={cancel}>
          Cancel
        </button>
      </div>
    </Form>
  )
}

export default reduxForm({
  form: 'cover-levels/create',
  validate,
})(CreateCoverLevelForm)
