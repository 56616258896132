import React, { Component, useState } from 'react'
import propTypes from 'prop-types'
import { confirmAlert } from 'react-confirm-alert'
import {
  updateSuspendedUser,
  activateUsers,
} from '../../../actions/users.actions'
import { ACTIVATE_USER_SUCCESS } from '../../../constants/index'
import { Tooltip } from 'reactstrap'
 
export const SuspendForm = ({
  onClose,
  handleSuspend,
  label,
  userId,
  dispatch,
}) => {
  const [field, setField] = useState('')
  const [errorField, setErrorField] = useState('')

  function handleSubmit(e) {
    e.preventDefault()
    handleSuspend(userId, field)
      .then(res => {
        if (Array.isArray(userId)) {
          for (let i = 0; i < userId.length; i++) {
            dispatch(updateSuspendedUser(userId[i], field))
          }
          return onClose()
        }
        dispatch(updateSuspendedUser(userId, field))
        return onClose()
      })
      .catch(err => {
        setErrorField(err.errors.suspended_reason)
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        {label && <label htmlFor="suspendReason">{label}</label>}
        <textarea
          placeholder="e.g Awaiting payment"
          id="suspendReason"
          defaultValue={field}
          onChange={e => setField(e.target.value)}
          className={`form-control ${errorField ? 'is-invalid' : ''}`}
          cols="30"
          rows="3"
        />
        {errorField && <div className="invalid-feedback">{errorField}</div>}
      </div>
      <button type="submit" name="submit" className="btn btn-secondary">
        Yes, I'm sure
      </button>
      <button
        type="submit"
        className="btn btn-transparent text-secondary"
        onClick={onClose}
      >
        No, cancel
      </button>
    </form>
  )
}

class SuspendUser extends Component {
  state = {
    tooltipState: false,
  }

  showModal = () =>
    confirmAlert({
      title: '🧐 Are you sure?',
      message: this.props.message,
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{this.props.title}</h3>
              <p>{this.props.message}</p>

              <SuspendForm
                label={this.props.label}
                onClose={onClose}
                dispatch={this.props.dispatch}
                userId={this.props.userId}
                handleSuspend={this.props.handleSuspend}
              />
            </div>
          </div>
        )
      },
    })

  activateUser = () =>
    confirmAlert({
      title: '🧐 Are you sure?',
      message: `Activate ${
        this.props.name
      }? They'll be able to login to the system. You can undo this later.`,
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>{message}</p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  return activateUsers([this.props.userId]).then(res => {
                    this.props.dispatch({
                      type: ACTIVATE_USER_SUCCESS,
                      payload: { id: this.props.userId },
                    })
                  })
                }}
              >
                Yes, I'm sure
              </button>

              <button
                type="button"
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })

  toggle = () =>
    this.setState(prevState => ({
      tooltipState: !prevState.tooltipState,
    }))

  render() {
    let icons = !this.props.isActive ? (
      <>
        <i
          id={`suspendedStateButton-${this.props.userId}`}
          className="icon-lock table-action-btn text-danger"
          style={{padding: 0}}
          onClick={this.activateUser}
        />
        <Tooltip
          placement="top"
          className="custom-tooltip"
          isOpen={this.state.tooltipState}
          target={`suspendedStateButton-${this.props.userId}`}
          toggle={this.toggle}
        >
          Suspended <br/> Click to activate
        </Tooltip>
      </>
    ) : (
      <>
        <i
          id={`activeStateButton-${this.props.userId}`}
          className="icon-lock-open table-action-btn text-success"
          style={{padding: 0}}
          onClick={this.showModal}
        />
        <Tooltip
          placement="top"
          className="custom-tooltip"
          isOpen={this.state.tooltipState}
          target={`activeStateButton-${this.props.userId}`}
          toggle={this.toggle}
        >
          Active <br/> Click to suspend
        </Tooltip>
      </>
    )
    return <div className="text-left">{icons}</div>
  }
}

SuspendUser.propTypes = {
  suspended: propTypes.bool.isRequired,
  handleSuspend: propTypes.func.isRequired,
}

export default SuspendUser
