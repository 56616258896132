import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  UPDATE_SUSPENDED_USER,
  USERS_TABLE_ROW_ADD,
  USERS_TABLE_ROW_REMOVE,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  ACTIVATE_USER_SUCCESS,
  FETCH_USER_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_FAILURE,
  UPDATE_USER_INFO_REQUEST,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILURE,
  RESTORE_USER,
} from '../constants'
import _ from 'lodash'

const initialState = {
  isPending: true,
  data: null,
  error: null,
  selectedRows: [],
  multiSuspendModal: false,
  singleUser: false,
}

export function users(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        isPending: true,
      }
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        isPending: false,
        data: { ...action.payload },
      }
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        isPending: false,
        data: null,
        error: { ...action.payload },
      }
    case UPDATE_SUSPENDED_USER:
      let { id, reason } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          data: state.data.data.map(user => {
            if (user.id === id) {
              user.is_active = false
              user.suspended_reason = reason
            }
            return user
          }),
        },
        selectedRows: [],
      }
    case USERS_TABLE_ROW_ADD:
      return {
        ...state,
        selectedRows: [...state.selectedRows, ...action.payload.data],
      }

    case USERS_TABLE_ROW_REMOVE:
      // looks funny but.
      // checks if the payload contains just one ID
      // if so, it filters it out otherwise it defaults to []
      // that's when users perform unselect all.
      return {
        ...state,
        selectedRows:
          action.payload.data.length === 1
            ? [
                ...state.selectedRows.filter(
                  row => row !== action.payload.data[0],
                ),
              ]
            : [],
      }

    case DELETE_USER_SUCCESS:
      let ids = action.payload.id
      let copy = _.map(state.data.data, _.clone)

      if (ids.length > 1)
        for (let i = 0; i < copy.length; i++)
          copy = copy.filter(userRow => userRow.id !== ids[i])
      else
        copy = copy
          .filter(userRow => userRow.id !== action.payload.id)
          .map(userRow => {
            if (userRow.id === action.payload.id) userRow.deleted_at = true
            return userRow
          })

      return {
        ...state,
        data: {
          ...state.data,
          data: [...copy],
        },
        selectedRows: [],
      }

    case DELETE_USER_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload.error,
      }
    case FETCH_USER_REQUEST:
      return {
        ...state,
        isPending: true,
      }
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        isPending: false,
        error: null,
        singleUser: {
          ...action.payload.user,
          dealer_id: {
            id: action.payload.user.dealer_id,
            name: action.payload.user.dealer_name,
          },
        },
      }
    case FETCH_USER_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload.error,
      }
    case UPDATE_USER_INFO_REQUEST:
      return {
        ...state,
        isPending: true,
      }
    case UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        // isPending: false,
        // error: null,
        // singleUser: {
        //   ...action.payload.user,
        //   dealer_id: [{
        //     id: action.payload.user.dealer_id,
        //     name: action.payload.user.dealer_name
        //   }]
        // }
      }
    case UPDATE_USER_INFO_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload.error,
      }

    case ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          data: state.data.data.map(user => {
            if (!user.is_active && user.id === action.payload.id) {
              user.is_active = true
              user.suspended_reason = null
            }
            return user
          }),
        },
        selectedRows: [],
      }
    case RESTORE_USER:
      return {
        ...state,
        singleUser: {
          ...state.singleUser,
          deleted_at: null,
        },
      }
    default:
      return state
  }
}
