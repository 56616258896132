import React, { useState } from 'react';
import PropTypes from 'prop-types';

function TimelineHeader(props) {
  const { user } = props;
  const [sendingPolicyDocumentUpdateEmail, setSendingPolicyDocumentUpdateEmail] = useState(false);

  const sendPolicyDocumentUpdateEmail = async () => {
    if (!props.sendPolicyDocumentUpdateEmail) return;
  
    setSendingPolicyDocumentUpdateEmail(true);
    await props.sendPolicyDocumentUpdateEmail();
    setSendingPolicyDocumentUpdateEmail(false)
  };

  return user ? (
    <section className="timeline-header bg-white">
      <div className="timeline-page-container animated fadeIn">
        <header className="ph-detail-header">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <h2 className="h2">
                {user.first_name} {user.last_name}
              </h2>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="ph-detail-actions">
                {props.sendPolicyDocumentUpdateEmail && (
                  <button
                    className="btn btn-outline-info btn-md"
                    disabled={sendingPolicyDocumentUpdateEmail}
                    onClick={sendPolicyDocumentUpdateEmail}
                  >
                    Policy document update email
                  </button>
                )}
                {/*<button className="btn btn-primary btn-large font-lg d-flex align-items-center">*/}
                {/*  <span className="wf-icon-text-msg mr-1" /> Text*/}
                {/*</button>*/}
                {/*<button className="btn btn-primary btn-large d-flex align-items-center font-lg">*/}
                {/*  <span className="wf-icon-email-filled mr-2 mt-1" /> Email*/}
                {/*</button>*/}
                {/*<button className="btn btn-secondary btn-large d-flex align-items-center font-lg">*/}
                {/*  <span className="wf-icon-call-filled d-block mr-2" /> Call*/}
                {/*</button>*/}
              </div>
            </div>
          </div>
        </header>
      </div>
    </section>
  ) : (
    <div />
  );
}

TimelineHeader.propTypes = {
  user: PropTypes.object.isRequired,
};

export default TimelineHeader;
