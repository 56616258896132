import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import CreateForm from '../../forms/create'
import { convertValues, createVehicle } from '../../forms/helpers'
import { NotificationManager } from 'react-notifications'
import { history } from '../../../../../../utils/history'
import { reset } from 'redux-form'
import { get } from 'lodash'
import { confirmAlert } from 'react-confirm-alert'

const PublicSpecialVehiclesCreate = ({ dispatch }) => {
  const onSubmit = (values) => {
    createVehicle(convertValues(values, 'Public'))
      .then(() => {
        dispatch(reset('special-vehicles/create'))
        history.push('/admin/special-vehicles/public')
        NotificationManager.success('Woohoo! Your vehicle was saved!')
      })
      .catch((err) => {
        if (
          get(err.response, 'data.errors', false) &&
          Object.keys(get(err.response, 'data.errors', false)).filter((key) =>
            key.startsWith('models.')
          )
        ) {
          let errorString = err.response.data.errors[
            get(err.response, 'data.errors', false) &&
              Object.keys(
                get(err.response, 'data.errors', false)
              ).filter((key) => key.startsWith('models.'))[0]
          ].join(' ')
          confirmAlert({
            title: '🧐 Uh-oh!',
            message: errorString,
            customUI: ({ title, message, onClose }) => {
              return (
                <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                    <span
                      className="btn-close-modal fa fa-close"
                      onClick={onClose}
                    />
                    <h3>{title}</h3>
                    <p>{message}</p>

                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={onClose}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              )
            },
          })
        } else {
          NotificationManager.error(
            'Oops! Something went wrong saving your vehicle. Please try again.'
          )
        }
      })
  }

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/admin/special-vehicles/public" className="open active">
            Public special vehicles
          </Link>
        </li>
        <li className="breadcrumb-item">
          <span className="active">Add a special vehicle</span>
        </li>
      </ol>

      <div className="container-fluid">
        <CreateForm
          onSubmit={onSubmit}
          target={'Public'}
          onCancelRedirectTo={'/admin/special-vehicles/public'}
        />
      </div>
    </div>
  )
}

PublicSpecialVehiclesCreate.propTypes = {}

export default connect((state) => ({}))(PublicSpecialVehiclesCreate)
