import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form, reduxForm } from 'redux-form'
import renderField from '../../../utils/renderField'
import { validInteger, validPrice } from '../../../utils/misc'

const AdditionalInformationForm = (props) => {
  const { handleSubmit, onSubmit, valid, dirty, cancel } = props

  return (
    <div className="my-3 px-1">
      <Form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="row">
          <div className="col">
            <Field
              type="text"
              id={'previousWarrantyProvider'}
              label="Previous warranty provider"
              component={renderField}
              className="form-control"
              name={`existing_warranty_provider`}
            />
          </div>
          <div className="col">
            <Field
              type="text"
              id={'policiesPerMonth'}
              label="Policies per month"
              component={renderField}
              className="form-control"
              name={`policies_per_month`}
            />
          </div>
          <div className="col">
            <Field
              type="text"
              id={'potentialRevenue'}
              label="Potential monthly revenue"
              component={renderField}
              className="form-control"
              name={`potential_revenue`}
              fieldIcon="wf-icon-gbp"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              type="text"
              id={'carsInStock'}
              label="Number of cars in stock"
              component={renderField}
              className="form-control"
              name={`cars_in_stock`}
            />
          </div>
          <div className="col">
            <Field
              type="text"
              id={'lowestStockPrice'}
              label="Lowest stock price"
              component={renderField}
              className="form-control"
              name={`min_stock_price`}
              fieldIcon="wf-icon-gbp"
            />
          </div>
          <div className="col">
            <Field
              type="text"
              label="Highest stock price"
              component={renderField}
              name={`max_stock_price`}
              className="form-control"
              fieldIcon="wf-icon-gbp"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              type="textarea"
              label="Other"
              component={renderField}
              name={`comments`}
              className="form-control"
              placeholder="Reg company number, Directors name(s) & Reg address"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!valid || !dirty}
              >
                Save changes
              </button>
              <button
                type="button"
                className="btn btn-outline btn-form-cancel"
                onClick={cancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

AdditionalInformationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

const validate = fields => {
  let errors = {}

  if (fields.policies_per_month && !validInteger(fields.policies_per_month))
    errors.policies_per_month = 'You must enter a positive number.'

  if (fields.potential_revenue && !validInteger(fields.potential_revenue))
    errors.potential_revenue = 'You must enter a positive number.'

  if (fields.cars_in_stock && !validInteger(fields.cars_in_stock))
    errors.cars_in_stock = 'You must enter a positive number.'

  if (fields.min_stock_price && !validPrice(fields.min_stock_price))
    errors.min_stock_price = 'You must enter a positive number.'

  if (fields.max_stock_price && !validPrice(fields.max_stock_price))
    errors.max_stock_price = 'You must enter a positive number.'

  return errors
}

export default reduxForm({
  form: 'dealer/additional-information',
  validate,
})(AdditionalInformationForm)