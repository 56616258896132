import React, { Component } from 'react'
import PageHeader from '../../../../../ui/PageHeader'
import { Table } from '../../../../../ui/Table'
import { connect } from 'react-redux'
import { fetchPriceBands } from '../../../../../../actions/admin/price-bands/price-bands.action'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { uniq } from 'lodash'
import { confirmAlert } from 'react-confirm-alert'
import api from '../../../../../../utils/api'
import { NotificationManager } from 'react-notifications'

class DirectSalesPriceBands extends Component {
  state = {
    query: '',
  }

  componentDidMount() {
    this.props.fetchPriceBands('Direct Sales')
  }

  handleDelete = (row) => {
    confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>
                Delete the price band <strong>{row.name}</strong>? It'll be lost
                forever.
              </p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  this.deletePriceBand(row.id)
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  deletePriceBand = (id) => {
    api
      .delete(`/admin/price-bands/${id}`)
      .then(() => {
        NotificationManager.success('Woohoo! You deleted a price band.')
        this.props.fetchPriceBands('Direct Sales')
      })
      .catch((err) => {
        if (err && err.response.status === 404) {
          NotificationManager.error('Oops! Price band could not be found.')
        }
      })
  }

  handleSearch = (query) => {
    this.setState({ query })
    this.props.fetchPriceBands('Direct Sales', '&query=' + query)
  }

  handleTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage }
  ) => {
    let params = `&page=${page}&desc=${
      sortOrder === 'desc' ? true : false
    }&limit=${sizePerPage}&query=${this.state.query}`
    if (sortField) params = `${params}&order=${sortField}`

    this.props.fetchPriceBands('Direct Sales', params)
  }

  render() {
    const { priceBands, isPending, searching } = this.props
    const options = {
      showOptions: false,
      columns: [
        {
          dataField: 'name',
          text: 'Price band name',
        },
        {
          dataField: 'cover_levels',
          text: 'Included cover level(s)',
          formatter: (cell, row) => {
            return uniq(row.prices.map((r) => r.cover_level.name)).join(', ')
          },
        },
        {
          dataField: 'id',
          text: 'Actions',
          headerStyle: {
            width: 100,
          },
          formatter: (cell, row) => (
            <div className="d-flex align-items-center">
              <Link
                data-tip="Edit price band"
                data-place="top"
                to={{
                  pathname: `/admin/direct-sales-price-bands/${row.id}/edit`,
                  priceBand: row,
                }}
                className="table-action-btn"
                primary="true"
                size="sm"
              >
                <span className="wf-icon-edit" />
              </Link>
              <Link
                data-tip="Delete price band"
                data-place="top"
                to={`#`}
                className="table-action-btn table-action-btn--delete"
                size="sm"
                onClick={() => this.handleDelete(row)}
              >
                <span className="wf-icon-delete" />
              </Link>
              <ReactTooltip effect="solid" />
            </div>
          ),
        },
      ],
    }

    return (
      <>
        <br />
        <div className="container-fluid animated fadeIn">
          <PageHeader
            title="Direct sales price bands"
            description={
              'Set base prices for the Direct Sales team.'
            }
            buttonLink={'/admin/direct-sales-price-bands/create'}
            buttonText="Add a price band"
            buttonIcon="d-block wf-icon-add"
          />

          {priceBands && (
            <Table
              resource={priceBands}
              selected={[]}
              loading={isPending}
              showOptions={options.showOptions}
              handleTableChange={this.handleTableChange}
              columns={options.columns}
              dispatch={this.props.dispatch}
              hasTableSearch={true}
              onSearch={this.handleSearch}
              searching={searching}
            />
          )}
        </div>
      </>
    )
  }
}

DirectSalesPriceBands.propTypes = {}

export default connect(
  (state) => ({
    priceBands: state.priceBands.data,
    isPending: state.priceBands.isPending,
    searching: state.priceBands.searching,
  }),
  { fetchPriceBands }
)(DirectSalesPriceBands)
