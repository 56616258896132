import React from 'react'
import PropTypes from 'prop-types'
import { Form, reduxForm } from 'redux-form'
import { Field } from 'redux-form'
import CustomSelect from '../../../ui/CustomSelect'
import api from '../../../../utils/api'
import moment from 'moment'

const DatePickers = ({ handleSubmit, month, year }) => {
  const download = () => {
    let params = {
      summary_month: month,
      summary_year: year,
      detail_month: month,
      detail_year: year,
      tracking_year: year,
    }

    api
      .get(
        `/downloads/csv/dashboards/external-sales`,
        {
          params: { ...params },
        },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: 'text/csv' })
        )
        link.download = `${year}-${month}-${moment().format('DD')}_external_sales.csv`
        link.click()
      })
  }

  const baseYear = 2018
  const toYear = moment().add(1, 'year').year()
  const yearOptions = []
  for (let year = baseYear; year <= toYear; year++) {
    yearOptions.push({ value: year })
  }

  return (
    <Form onSubmit={handleSubmit} className={'d-flex align-items-start'}>
      <Field
        name="year"
        component={CustomSelect}
        options={yearOptions}
        getOptionLabel={(o) => o.value}
        getOptionValue={(o) => o.value}
        size={'sm'}
      />
      <Field
        component={CustomSelect}
        name="month"
        size={'sm'}
        getOptionLabel={(o) => o.label}
        getOptionValue={(o) => o.value}
        options={[
          { value: '1', label: 'January' },
          { value: '2', label: 'February' },
          { value: '3', label: 'March' },
          { value: '4', label: 'April' },
          { value: '5', label: 'May' },
          { value: '6', label: 'June' },
          { value: '7', label: 'July' },
          { value: '8', label: 'August' },
          { value: '9', label: 'September' },
          { value: '10', label: 'October' },
          { value: '11', label: 'November' },
          { value: '12', label: 'December' },
        ]}
      />
      <button type="submit" className={'btn btn-secondary btn-sm font-sm'}>
        Apply
      </button>
      <button
        onClick={download}
        type="button"
        className={'btn btn-primary btn-sm font-sm ml-1'}
      >
        <svg viewBox="0 0 512 512" role="img" width={22} height={22}>
          <polygon
            fill="#fff"
            points="272 434.744 272 209.176 240 209.176 240 434.744 188.118 382.862 165.49 405.489 256 496 346.51 405.489 323.882 382.862 272 434.744"
          />
          <path
            fill="#fff"
            d="M400,161.176c0-79.4-64.6-144-144-144s-144,64.6-144,144a96,96,0,0,0,0,192h80v-32H112a64,64,0,0,1,0-128h32v-32a112,112,0,0,1,224,0v32h32a64,64,0,0,1,0,128H320v32h80a96,96,0,0,0,0-192Z"
          />
        </svg>
      </button>
    </Form>
  )
}

const validate = (values) => {
  let errors = {}
  if (!values.month) {
    errors.month = 'Month is required'
  }
  if (!values.year) {
    errors.year = 'Year is required'
  }

  return errors
}

DatePickers.propTypes = {
  month: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
}

export default reduxForm({
  form: 'rangeFormDirectSales',
  validate,
})(DatePickers)
