import React, { Component } from 'react'
import { Nav } from 'reactstrap'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { logout } from '../actions/authentication.action'
import { hasPermission } from '../utils/auth'
import Avatar from './ui/Avatar'
import Notifications from './ui/notifications/Notifications'
import { API_URL } from '../utils/constants';

class Header extends Component {
  state = {
    dropdownOpen: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickAway, false)
    document.addEventListener('keydown', this.handleEscape, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickAway, false)
    document.removeEventListener('keydown', this.handleEscape, false)
  }

  handleClickAway = e => {
    if(!this.dropdownRef.contains(e.target)) {
      this.toggleDropdown(false)
    }
  }

  handleEscape = e => {
    document.onkeydown = e => {
      e = e || window.event
      var isEscape = false
      if ('key' in e) {
        isEscape = e.key === 'Escape' || e.key === 'Esc'
      } else {
        isEscape = e.keyCode === 27
      }
      if (isEscape) {
        this.toggleDropdown(false)
      }
    }
  }

  toggleDropdown = val =>
    this.setState({
      dropdownOpen: val,
    })

  render() {
    const { user, location } = this.props

    // if user is on account/profile add blue border around avatar component
    const dropdownClasses = `dropdown user-dropdown nav-item ${
      location && location.pathname === '/account/profile' ? 'active' : ''
    }`

    /**
     * Highlight part of the system (top nav link) the user is currently viewing
     */
    const isActivePage = (page) => (location && location.pathname.indexOf(page) !== -1 && location.pathname.indexOf('admin') === -1) ? 'active' : ''

    const defaultDirectSalesURL = () => {
      if (user) {
        if (hasPermission('direct_sales.renewals.*')) {
          return '/direct-sales/renewals';
        }
        if (hasPermission('direct_sales.prospects.*')) {
          return '/direct-sales/prospects';
        }
        if (hasPermission('direct_sales.pipeline.*')) {
          return '/direct-sales/pipeline';
        }
        if (hasPermission('direct_sales.policies.*')) {
          return '/direct-sales/policies';
        }
        if (hasPermission('direct_sales.policyholders.*')) {
          return '/direct-sales/policyholders';
        }
      }
      return '/direct-sales/renewals'; // fallback case (should never happen)
    }

    const defaultExternalSalesURL = () => {
      if (user) {
        if (hasPermission('external_sales.dealers.*')) {
          return '/external-sales/dealers';
        }
        if (hasPermission('external_sales.prospects.*')) {
          return '/external-sales/prospects';
        }
        if (hasPermission('external_sales.pipeline.*')) {
          return '/external-sales/pipeline';
        }
        if (hasPermission('external_sales.territories.*')) {
          return '/external-sales/territories';
        }
        if (hasPermission('external_sales.policies.*')) {
          return '/external-sales/policies';
        }
        if (hasPermission('external_sales.policyholders.*')) {
          return '/external-sales/policyholders';
        }
      }
      return '/external-sales/dealers'; // fallback case (should never happen)
    }

    return (
      <React.Fragment>
        { // The WF logo (top-left) should take the user to the page that is displayed when they
          // first login (which depends on their permissions)
          user && hasPermission('dealer_portal.*') ?
            (
              (user && hasPermission('dealer_portal.dashboard'))
              ? <Link to={'/dealer-portal/dashboard'} className="navbar-brand"/>
              : <Link to={'/dealer-portal/policies'} className="navbar-brand"/>
            )
          : <Link to={'/dashboard'} className="navbar-brand"/>
        }
        <Nav className="mr-auto top-nav" navbar style={{ marginLeft: '1rem' }}>
        {user && hasPermission('dashboards.*') && (
          <NavLink to="/dashboard" exact className={`nav-link mr-4 ${isActivePage('dashboard')}`}>
            Dashboard
          </NavLink>
        )}
        {user && hasPermission('direct_sales.*') && (
          <NavLink to={defaultDirectSalesURL} className={`nav-link mr-4 ${isActivePage('direct-sales')}`}>
            Direct sales
          </NavLink>
        )}
        {user && hasPermission('external_sales.*') && (
          <NavLink to={defaultExternalSalesURL} className={`nav-link mr-4 ${isActivePage('external-sales')}`}>
            External sales
          </NavLink>
        )}
        {user && hasPermission('claims.*') && (
          <NavLink to="/claims/claims" className={`nav-link mr-4 ${isActivePage('claims')}`}>
            Claims
          </NavLink>
        )}
        {user && hasPermission('direct_sales.prospects.create') && (
          <NavLink to="/direct-sales/get-quote" className={`nav-link mr-4 ${isActivePage('get-quote')}`}>
            Get a quote
          </NavLink>
        )}
        {user && hasPermission('reports.*') && (
          <NavLink to="/reports" className={`nav-link mr-4 ${isActivePage('reports')}`}>
            Reports
          </NavLink>
        )}
        {user && hasPermission('dealer_portal.*') && (
          <NavLink to="/dealer-portal/policies" className={`nav-link mr-4 ${isActivePage('dealer-portal')}`}>
            Dealer Portal
          </NavLink>
        )}
        {user && hasPermission('policyholder_portal.*') && (
          <NavLink to="/policyholder-portal/policies" className={`nav-link mr-4 ${isActivePage('policyholder-portal')}`}>
            Policyholder Portal
          </NavLink>
        )}
        </Nav>

        {API_URL.includes('migration') &&
        <span className="badge badge-blacklisted badge-pill font-sm py-2 px-3 mr-5 test-site-pill">
          *** MIGRATION SITE ***
        </span>
        }
        {API_URL.includes('staging') &&
        <span className="badge badge-blacklisted badge-pill font-sm py-2 px-3 mr-5 test-site-pill">
          *** STAGING SITE ***
        </span>
        }
        {!API_URL.includes('staging') && !API_URL.includes('migration') && !API_URL.includes('api.warrantyfirst.co.uk') &&
        <span className="badge badge-blacklisted badge-pill font-sm py-2 px-3 mr-5 test-site-pill">
          *** TEST/DEV SITE ***
        </span>
        }

        <Nav className="ml-auto top-nav" navbar style={{ marginRight: '1rem' }}>
        {user && hasPermission('admin.*') && (
          <NavLink to="/admin/users" className={`nav-link-icon ${isActivePage('/admin/users')}`}>
            <i className="icon-settings" />
          </NavLink>
        )}

          <Notifications />

          {user && (
            <div
              data-toggle="dropdown"
              className={dropdownClasses}
              id="dropdownMenu"
              ref={dropdownRef => (this.dropdownRef = dropdownRef)}
              onClick={() =>
                this.state.dropdownOpen && this.toggleDropdown(false)
              }
            >
              <Avatar
                user={this.props.user}
                size="medium"
                onClick={() => this.toggleDropdown(true)}
              />

              <div
                className={`dropdown-menu dropdown-menu-right user-dropdown-menu ${
                  this.state.dropdownOpen ? 'show' : ''
                }`}
              >
                <Link to="/account/profile" className="dropdown-item">
                  <span className="icon-user mr-2"/> Your profile
                </Link>

                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={this.props.logout}
                >
                  <span className="icon-logout mr-2"/> Logout
                </Link>
              </div>
            </div>
          )}
        </Nav>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: state.authentication.user,
})

export default connect(
  mapStateToProps,
  { logout },
)(Header)
