import React, { useEffect, useState } from 'react'
import StepDetails from './StepDetails'
import StepPolicy from './StepPolicy'
import StepQuote from './StepQuote'
import api from '../../../../utils/api'
import { API_URL } from '../../../../utils/constants'
import { NotificationManager } from 'react-notifications'
import {
  change,
  formValueSelector,
  getFormValues,
  initialize,
  reduxForm,
} from 'redux-form'
import { connect, useDispatch } from 'react-redux'
import { money } from '../../../../utils/misc'
import { clone, get, set } from 'lodash'
import moment from "moment";

const GetQuoteForm = (props) => {
  const [step, setStep] = useState(1)
  const [lookupData, setLookupData] = useState()
  const [coverLevel, setCoverLevel] = useState(null)
  const [plans, setPlans] = useState(false)
  const [queryParams, setQueryParams] = useState(false)
  // const [payNow, setPayNow] = useState(false)
  const [lastStepVisited, setLastStepVisited] = useState(1)
  const [quoteData, setQuoteData] = useState(null)

  const [loadedQuote, setLoadedQuote] = useState(false)
  const [customPrices, setCustomPrices] = useState({})

  const [useManualInfo, setUseManualInfo] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState({});

  const dispatch = useDispatch()

  const { formValues, payNow = false } = props

  useEffect(() => {
    //If Manual Data entry, set vehicle info, else use lookupdata
    if (useManualInfo) {
      let vehicleInfo = get(formValues, 'vehicle', {});
      if (vehicleInfo.Manufactured) {
        vehicleInfo.RegDate = moment(vehicleInfo.Manufactured).format('DD-MM-YYYY');
      }
      setVehicleInfo(vehicleInfo);
    } else {
      setVehicleInfo(lookupData);
    }
  }, [lookupData, useManualInfo, formValues])


  const setPayNow = (val) => {
    return dispatch(change('get-quote', 'payNow', val))
  }

  const handleSelectCoverLevel = (coverLevel, payNow) => {
    setCoverLevel(coverLevel)
    setLastStepVisited(step + 1)
    window.scroll(0, 0)
    setPayNow(payNow)
  }

  useEffect(() => {
    if (!coverLevel) return;
    fetchQuoteOptions(coverLevel, payNow);
  }, [coverLevel, payNow, formValues])

  const fetchQuoteOptions = (coverLevel, payNow) => {
    let optionParams = {...queryParams};
    optionParams.pay_monthly = payNow;
    optionParams.cover_level = coverLevel.id;
    optionParams.labour_rate = get(props, 'formValues.labour_rate.title', null);
    optionParams.excess = get(props, 'formValues.excess.title', null);
    optionParams.sundries = get(props, 'formValues.sundries', []).map(s => s.title);
    api
      .get(
        `/quote/options`, {params: optionParams}
      )
      .then((res) => {
        let pricesObj = res.data['Prices']
        for (const month in pricesObj) {
          let prices = pricesObj[month]
          // we are adding custom_price field so we can easily change if customer require custom pricing
          // if custom_price != initial price that means we need to send a 'custom_price' field in our 'store quote' API call
          prices.map((p) => {
            p.custom_price = get(customPrices, `${coverLevel.id}.${month}.cl${p.claim_limit}`, null)
            return p
          })
        }

        setQuoteData({ ...res.data, Prices: pricesObj })
        setPayNow(payNow)
      })
      .catch((err) => {
        if (err) {
          console.log(err.response)
        }
      })
  }

  const updateCustomPrice = (planLength, claimLimit, price) => {
    let quote = { ...quoteData }
    let prices = quote.Prices[planLength + ' months']
    let updated = prices.map((p) => {
      if (p.claim_limit === claimLimit) {
        p.custom_price = parseFloat(price)
      }
      return p
    })

    const data = {
      ...quoteData,
      Prices: {
        ...quoteData.Prices,
        [planLength + ' months']: updated,
      },
    }

    setCustomPrices(set({...customPrices}, `${coverLevel.id}.${planLength + ' months'}.cl${claimLimit}`, parseFloat(price)))
    setQuoteData(data)
  }
  /**
   * Swaps the position of the day and year parts of the given date string
   * @param {string} d
   */
  const swapDY = (d) => {
    let delimiter = d.indexOf('-') === -1 ? '/' : '-'
    let parts = d.split(delimiter)
    return parts[2] + delimiter + parts[1] + delimiter + parts[0]
  }

  /**
   * Format form data & retrieve plans data before going to next step
   *
   * @param values
   */
  const handleSubmit = (values) => {
    fetchPlans()
    nextStep()

    // going to step details (2)
    setLastStepVisited(2)
  }

  /**
   * Fetch plans
   */
  const fetchPlans = () => {
    let fields = {}
    if (vehicleInfo) {
      fields.vrm = vehicleInfo.VRM
      fields.vin = vehicleInfo.VIN
      fields.type = vehicleInfo.Type.value || vehicleInfo.Type
      fields.make = vehicleInfo.Make
      fields.model = vehicleInfo.Model
      fields.series = vehicleInfo.Series
      fields.drive_type = vehicleInfo.DriveType
      fields.engine_size = vehicleInfo.EngineSize
      fields.fuel_type = vehicleInfo.FuelType
      fields.transmission = vehicleInfo.Transmission
      fields.reg_date = swapDY(vehicleInfo.RegDate)

      fields.mileage = formValues.mileage
      fields.annual_mileage = formValues.annual_mileage
      fields.first_name = formValues.first_name
      fields.last_name = formValues.last_name
      fields.title = formValues.title.value
      fields.email = formValues.email
      fields.tel = formValues.tel_mobile
      fields.custom_quote_requested = formValues.custom_quote_requested || false
      fields.legacy = 0
    }

    setQueryParams(fields)

    api
      .get(`/quote/plans`, {params: fields})
      .then((res) => {
        // setPlans(res.data)
        let data = clone(res.data)
        let covers = Object.keys(res.data)

        for (let i = 0; i < covers.length; i++) {
          let cover = covers[i]
          let coverLevel = res.data[cover]
          coverLevel.meta = {}
          if (coverLevel.price > 0) {
            coverLevel.meta.annual = money.format(coverLevel.price)
            coverLevel.meta.monthly = money.format(coverLevel.monthly_amt)
            coverLevel.meta.msg = null
          } else {
            coverLevel.meta.msg = 'Your vehicle is not eligible for this level of cover'
            data[cover] = coverLevel
          }
        }

        setPlans(data)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }

  const nextStep = () => {
    setStep(step + 1)
    setCustomPrices({})
  }
  const previousStep = () => setStep((step) => step - 1)

  useEffect(() => {
    setSteps((steps) => [
      ...steps.map((item) => {
        if (item.id === step) {
          item.current = true
        } else {
          item.current = false
        }
        return item
      }),
    ])
  }, [step])

  const [steps, setSteps] = useState([
    {
      id: 1,
      title: 'Details',
      current: true,
      stepLinkIsGreen: false,
      valid: false,
    },
    {
      id: 2,
      title: 'Policy',
      current: false,
      stepLinkIsGreen: false,
      valid: false,
    },
    {
      id: 3,
      title: 'Quote',
      current: false,
      stepLinkIsGreen: false,
    },
  ])

  const handleLookup = (vrm) => {
    if (!vrm) return false

    api
      .get(`${API_URL}/misc/vrm-lookup?vrm=${vrm}`)
      .then((res) => {
        if (get(res, 'data.Model', false) === false) {
          setLookupData(null);
          setUseManualInfo(true);
          NotificationManager.error('Oops! We could not find information for this registration. Please enter the details manually.');
        } else {
          setLookupData(res.data)
        }
      })
      .catch((err) => {
        // if (err && err.response.status  422) {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again.'
        )
        // }
      })
  }

  /**
   * Only allow the user to navigate
   * to the last step he visited.
   *
   * @param {Number} step
   */
  const handleGoBackTo = (step) => {
    if (lastStepVisited >= step.id) {
      setStep(step.id)
    }
  }

  const getQuoteValues = (userValues) => {
    let fields = {}
    let form = props.formValues
    let vehicle = vehicleInfo

    let regDate = swapDY(vehicle.RegDate)

    fields.cover_level = coverLevel.id
    fields.claim_limit = form.quote_claim_limit.value
    fields.duration = userValues.duration
    fields.labour_rate = get(formValues, 'labour_rate.title', null); //Defaultable on the API
    fields.excess = get(formValues, 'excess.title', null); //Defaultable on the API
    fields.sundries = get(formValues, 'sundries', []).map(s => s.title);

    fields.vrm = formValues.vrm
    fields.vin = vehicle.VIN
    fields.type = vehicle.Type
    fields.make = vehicle.Make
    fields.model = vehicle.Model
    fields.drive_type = vehicle.DriveType
    fields.engine_size = vehicle.EngineSize
    fields.transmission = vehicle.Transmission
    fields.fuel_type = vehicle.FuelType
    fields.manual_data = useManualInfo
    fields.annual_mileage = formValues.annual_mileage
    fields.service_history = get(formValues, 'service_history.value', undefined)
    fields.time_owned = get(formValues, 'time_owned.value', undefined)

    fields.reg_date = regDate
    fields.mileage = form.mileage
    fields.retail_price = form.vehicle_sale_price
    fields.title = form.title.name
    fields.first_name = form.first_name
    fields.last_name = form.last_name
    fields.email = form.email
    fields.tel1 = form.tel_mobile
    fields.tel2 = form.tel_home

    //We need to ensure that we get the looked up address if they haven't entered a manual address into the form.
    fields.address_1 = get(form, 'address_1', get(form, 'address.address_1'))
    fields.address_2 = get(form, 'address_2', get(form, 'address.address_2'))
    fields.address_3 = get(form, 'address_3', get(form, 'address.address_3'))
    fields.city = get(form, 'city', get(form, 'address.city'))
    fields.county = get(form, 'county', get(form, 'address.county'))
    fields.postcode = get(form, 'postcode', get(form, 'address.postcode'))

    fields.custom_quote_requested = formValues.custom_quote_requested || false

    const prices = quoteData.Prices[fields.duration].filter(
      (i) => i.claim_limit === fields.claim_limit
    )

    fields.custom_price = !prices[0].custom_price
      ? undefined
      : prices[0].custom_price

    fields.start_date = form.start_date

    if (get(loadedQuote, 'loadType', false) === 'policy') {
      fields.renewal_policy_id = loadedQuote.id;
    }


    return Object.assign({}, fields, userValues) //Return all fields, overwritten with user values if required.
  }

  const handlePrepopulateForm = (id, type) => {
    const url =
      type === 'quote'
        ? `/direct-sales/prospects/quotes/${id}`
        : `/direct-sales/policies/${id}`
    api
      .get(url)
      .then((res) => {
        let fv = {} //fv = formValues but reduced for brevity.
        let data = res.data
        fv.title = {
          name: data.policyholder.title,
          value: data.policyholder.title,
        }
        fv.first_name = data.policyholder.first_name
        fv.last_name = data.policyholder.last_name
        fv.tel_mobile = data.policyholder.tel1
        fv.tel_home = data.policyholder.tel2
        fv.email = data.policyholder.email

        fv.address_1 = data.policyholder.address_1
        fv.address_2 = data.policyholder.address_2
        fv.address_3 = data.policyholder.address_3
        fv.city = data.policyholder.city
        fv.county = data.policyholder.county
        fv.postcode = data.policyholder.postcode

        fv.vrm = data.vehicle.vrm
        if (type === 'quote') {
          fv.mileage = data.vehicle.mileage
          fv.annual_mileage = data.vehicle.annual_mileage
        }
        fv.vehicle_sale_price = data.vehicle.retail_price
        fv.time_owned = {
          name: data.vehicle.time_owned,
          value: data.vehicle.time_owned,
        }
        fv.service_history = {
          name: data.vehicle.service_history,
          value: data.vehicle.service_history,
        }

        if (type === 'policy') {
          fv.start_date = moment(data.end_date).add('1', 'day').toDate()
        } else {
          fv.start_date = moment(data.start_date).toDate()
        }

        fv.labour_rate = {
          title: data.labour_rate,
          value: data.labour_rate
        }

        fv.excess = {
          title: data.excess,
          value: data.excess
        }

        fv.sundries = data.extras.map(s => ({title: s.title, value: s.title}))

        fv.custom_quote_requested = data.policyholder.custom_quote_requested

        fv.quote_id = data

        setPayNow(!data.pay_monthly)

        setUseManualInfo(data.vehicle.manual_data);
        if (!data.vehicle.manual_data) {
          handleLookup(fv.vrm)
        } else {
          //initalize values from vehicle data
          fv.vehicle = {};
          fv.vehicle.VIN = data.vehicle.vin;
          fv.vehicle.Type = data.vehicle.type;
          fv.vehicle.Make = data.vehicle.make;
          fv.vehicle.Model = data.vehicle.model;
          fv.vehicle.DriveType = data.vehicle.drive_type;
          fv.vehicle.FuelType = data.vehicle.fuel_type;
          fv.vehicle.Transmission = data.vehicle.transmission;
          fv.vehicle.FuelType = data.vehicle.fuel_type;
          fv.vehicle.EngineSize = data.vehicle.engine_size;
          fv.vehicle.Manufactured = moment(data.vehicle.reg_date).toDate();
        }

        dispatch(initialize('get-quote', fv))

        setLoadedQuote({...data, loadType: type})

        triggerDPReload()
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 404) {
          NotificationManager.error(
            'Oops! We could not find that quote. Please load another quote.'
          )
        } else {
          console.log(err)
          NotificationManager.error(
            'Oops! There was a problem loading this quote. Please try another quote.'
          )
        }
      })
  }

  useEffect(() => {
    let policyToLoad = new URLSearchParams(window.location.search).get('policy');
    if (policyToLoad) {
      handlePrepopulateForm(policyToLoad, 'policy');
    }
  }, []);

  /** Pricing Rules **/
  const [excessOptions, setExcessOptions] = useState();
  const [sundryOptions, setSundryOptions] = useState();
  const [labourRateOptions, setLabourRateOptions] = useState();

  useEffect(() => {
    if (!coverLevel) {
      return;
    }
    let params = {cover_level: coverLevel.id}
    api.get('/misc/price-rules/direct-sales', {params})
      .then(response => {
        setExcessOptions(response.data.filter(pr => (pr.type === 'Excess')).sort((a, b) => (a.title - b.title)))
        setLabourRateOptions(response.data.filter(pr => (pr.type === 'Labour Rate')).sort((a, b) => (a.title - b.title)))
        setSundryOptions(response.data.filter(pr => (pr.type === 'Sundry')))
        if (!get(formValues, 'excess', false)) {
          dispatch(change('get-quote', 'excess', findDefaultValue(response.data.filter(pr => (pr.type === 'Excess'))), true))
        }
        if (!get(formValues, 'labour_rate', false)) {
          dispatch(change('get-quote', 'labour_rate', findDefaultValue(response.data.filter(pr => (pr.type === 'Labour Rate'))), true))
        }
      });
  }, [coverLevel])

  const findDefaultValue = (set) => {
    //Find one marked as default
    if (set.filter(i => i.default).length) {
      return set.filter(i => i.default)[0]
    }
    //Else return first
    if (set.length > 0) {
      return set[0];
    }
    //Return nothing if nothing found
    return null;
  }

  const [renderDp, setRenderDp] = useState(true)

  const triggerDPReload = () => {
    setRenderDp(false)
    setRenderDp(true)
  }

  const handleRetrievePolicy = (id) => {

  }

  return (
    <>
      <h2 className="text-center mt-5 mb-5">Get a quote</h2>

      {steps && (
        <div className="px-5 bg-grey text-text-center d-flex justify-content-center py-3 form-steps">
          {steps.map((step, i) => (
            <h5
              onClick={() => handleGoBackTo(step)}
              className={`form-step fw-800 text-capitalize ${
                step.current ? 'text-primary current' : ''
              }`}
              key={i}
            >
              {step.id}. {step.title}
            </h5>
          ))}
        </div>
      )}

      <div className="container-fluid mt-5">
        {step === 1 && (
          <StepDetails
            onSubmit={handleSubmit}
            handleLookup={handleLookup}
            lookupData={lookupData}
            setLastStepVisited={setLastStepVisited}
            lastStepVisited={lastStepVisited}
            handleRetrieveQuote={handlePrepopulateForm}
            handleRetrievePolicy={handleRetrievePolicy}
            renderDP={renderDp}
            manualInfo={useManualInfo}
            setManualInfo={setUseManualInfo}
            vehicleInfo={vehicleInfo}
            loadedQuote={loadedQuote}
          />
        )}
      </div>
      {'undefined' !== payNow && (
        <>
          {step === 2 && (
            <StepPolicy
              selectCoverLevel={handleSelectCoverLevel}
              data={vehicleInfo}
              plans={plans}
              fetchPlans={fetchPlans}
              onSubmit={nextStep}
              prevStep={previousStep}
              setLastStepVisited={setLastStepVisited}
              lastStepVisited={lastStepVisited}
              payNow={payNow}
              setPayNow={setPayNow}
            />
          )}

          {step === 3 && quoteData && (
            <StepQuote
              fetchQuoteOptions={fetchQuoteOptions}
              coverLevel={coverLevel}
              onSubmit={nextStep}
              prevStep={previousStep}
              setLastStepVisited={setLastStepVisited}
              lastStepVisited={lastStepVisited}
              payNow={payNow}
              setPayNow={setPayNow}
              quoteData={quoteData}
              getQuoteValues={getQuoteValues}
              updateCustomPrice={updateCustomPrice}
              labourRateOptions={labourRateOptions}
              excessOptions={excessOptions}
              sundryOptions={sundryOptions}
            />
          )}
        </>
      )}
    </>
  )
}
const selector = formValueSelector('get-quote')

export default connect(
  (state) => ({
    formValues: getFormValues('get-quote')(state),
    payNow: selector(state, 'payNow'),
    initialValues: {
      payNow: false,
    },
  }),
  { change }
)(
  reduxForm({
    form: 'get-quote',
  })(GetQuoteForm)
)
