import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PageHeader from '../../../ui/PageHeader'
import { Table } from '../../../ui/Table'
import { Link } from 'react-router-dom'
import { sortCaret } from '../../../../utils/misc'
import {
  fetchPolicyHolders,
  searchPolicyHolders,
} from '../../../../actions/claims/policy-holders.actions'
import { uniq, uniqBy } from 'lodash'

class Policyholders extends React.Component {
  state = {
    query: '',
    page: '',
    desc: '',
    limit: 10,
    status: '',
  }

  componentDidMount() {
    // const url = new URLSearchParams(this.state).toString()
    this.props.fetchPolicyHolders()
  }

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    let url = `?page=${page}&desc=${
      sortOrder === 'desc' ? true : false
    }&limit=${sizePerPage}&query=${this.state.query}`
    if (sortField) url = `${url}&order=${sortField}`

    this.props.fetchPolicyHolders(url)
  }

  handleSearch = (query, filters) => {
    let status = ''

    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]

      if (filter.group_name === 'status') status = filter.name
    }

    this.setState(
      {
        query,
        status,
      },
      this.buildUrl
    )
  }

  /**
   * Builds URL and to fetch policyholders
   */
  buildUrl = () => {
    const { query, page, desc, limit } = this.state

    let url = `?query=${query}&page=${page}&desc=${desc}&limit=${limit}`

    this.props.searchPolicyHolders({ searching: true })
    this.props.fetchPolicyHolders(url)
  }

  policyLink(policy) {
    return (
      <Link
        to={`/claims/policies/${policy.id}/policy`}
        style={{
          textDecoration: 'none',
          color: '#1673FF',
        }}
        size="sm"
      >
        {policy.ref}
      </Link>
    )
  }

  render() {
    const { policyHolders, isPending, searching } = this.props

    const options = {
      showOptions: false,
      columns: [
        {
          dataField: 'name',
          text: 'Policyholder name',
          formatter: (cell, row) => (
            <Link
              to={`/claims/policyholders/${row.id}/timeline`}
              className="font-sm"
              primary="true"
            >
              {row.title} {row.first_name} {row.last_name}
            </Link>
          ),
          sort: true,
          sortCaret,
        },

        {
          dataField: 'ref',
          text: 'Associated policy ID(s)',
          formatter: (cell, row) => {
            let policies = uniqBy(row.policies, (policy) => (policy.ref))
            return (
              <>
                {policies.map((policy, index) => {
                  return [index > 0 && ', ', this.policyLink(policy)]
                })}
              </>
            )
          },
        },

        {
          dataField: 'policies.vehicle.vrm',
          text: 'Vehicle reg',
          formatter: (cell, row) => {
            let vehicleRegs = []
            for (const policy of row.policies) {
              vehicleRegs.push(policy.vehicle.vrm)
            }
            vehicleRegs = uniq(vehicleRegs);
            return vehicleRegs.toString();
          },
        },
      ],
    }

    return (
      <Fragment>
        <br />
        <div className="container-fluid animated fadeIn">
          <PageHeader
            title="Policyholders"
            description={
              'A list of all Warranty First policyholders. You can view all contact history between Warranty First and a customer by viewing their timeline.'
            }
          />

          {policyHolders && !isPending && (
            <Table
              resource={policyHolders}
              selected={[]}
              loading={isPending}
              showOptions={options.showOptions}
              handleTableChange={this.handleTableChange}
              columns={options.columns}
              hasTableSearch={true}
              onSearch={this.handleSearch}
              searching={searching}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    isPending: state.claims.policyHolders.isPending,
    searching: state.claims.policyHolders.searching,
    policyHolders: state.claims.policyHolders.data,
  }),
  { fetchPolicyHolders, searchPolicyHolders }
)(Policyholders)
