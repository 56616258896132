import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field, reduxForm } from 'redux-form'
import renderField from '../../../utils/renderField'
import { PermissionListItem } from '../../ui/PermissionListItem'
import api from '../../../utils/api'
import { colorPickerSwatches } from '../../../utils/misc'
import ColorPicker from '../../ui/ColorPicker'

let UserGroupEditForm = props => {
  const {
    onSubmit,
    handleSubmit,
    onCancel,
    selectAll,
    deselect,
    handleChange,
    permissions,
    perms,
    handleDelete,
    change,
    error,
  } = props
  const [allPermissions, setAllPermsissions] = React.useState([])

  React.useEffect(() => {
    api.get('admin/users/groups/permissions').then(res => {
      setAllPermsissions(res.data)
    })
  }, [])

  const handleColorChange = color => {
    change('color', color)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="form--mbot">
      <div className="row">
        <div className="col-6">
          <Field
            type="text"
            name="name"
            label="Group name"
            component={renderField}
            className="form-control"
          />
        </div>

        <div className="col-6">
          <Field
            label="Group color (used in tables)"
            component={ColorPicker}
            onColorChange={handleColorChange}
            colors={colorPickerSwatches}
            name="color"
          />
        </div>
      </div>

      <h4 className="font-sm fw-600 text-capitalize mt-3 mb-3">
        Access rights
      </h4>

      {error && error.errors && error.errors.permissions && (
        <div className="invalid-feedback mt-0 d-block mb-3">
          {error.errors.permissions[0]}
        </div>
      )}

      {allPermissions &&
        Object.keys(allPermissions).map((key, i) => {
          return (
            <PermissionListItem
              key={i}
              name={key}
              allPermissions={allPermissions}
              selectAll={selectAll}
              handleChange={handleChange}
              permissions={permissions}
              deselect={deselect}
              selected={perms}
              expanded={true}
            />
          )
        })}

      <div className="row mt-4">
        <div className="col">
          <button disabled={!props.valid} className="btn btn-secondary">
            Save changes
          </button>
          <button
            className="btn btn-outline"
            style={{ color: '#70b725' }}
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
        <div className="col d-flex justify-content-end btn-delete">
          <button
            type="button"
            className="btn-delete-resource"
            onClick={() => handleDelete()}
          >
            <span className="icon-trash" />
          </button>
        </div>
      </div>
    </Form>
  )
}

UserGroupEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

const validate = fields => {
  let errors = {}
  if (fields.permissions && fields.permissions.length === 0)
    errors.permissions = 'You need to select at least one permission.'
  return errors
}
export default reduxForm({
  form: 'user-group/edit',
  validate,
  enableReinitialize: true,
})(UserGroupEditForm)
