import React from 'react'
import SpecialVehiclesTable from '../../../ui/SpecialVehiclesTable'
import PageHeader from '../../../ui/PageHeader'
import { specialVehicleTypes } from '../../../../utils/filters'

const DealerSpecialVehicles = ({target, url, header}) => {
    return (
        <div className="container-fluid mt-4">
            <PageHeader
                title={`${header} special vehicles`}
                description={'You can assign and edit price bands for special vehicles'}
                buttonLink={`/admin/special-vehicles/${url}/create`}
                buttonText={'Add a special vehicle'}
                buttonIcon="wf-icon-add"
            />

            <SpecialVehiclesTable
                filters={specialVehicleTypes}
                staticData={[]}
                endpoint={`admin/special-vehicles`}
                target={target}
            />
        </div>
    )
}

export default DealerSpecialVehicles
