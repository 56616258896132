import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { echo } from '../../utils/misc'
import SlideToggle from './SlideToggle'
import EditContactDetailsForm from './forms/EditContactDetailsForm'
import { pick } from 'lodash'
import { NotificationManager } from 'react-notifications'
import api from '../../utils/api'
import { SubmissionError } from 'redux-form'

const DealerContactDetails = ({ dealer, setDealer }) => {
  const [editContactDetails, setEditContactDetails] = useState(false)

  const handleEditContactDetailsForm = (values) => {
    const data = pick(values, ['tel_office', 'tel_mobile', 'email', 'website'])

    return api
      .patch(`/external-sales/dealers/${dealer.id}/contact-details`, data)
      .then((res) => {
        setDealer(res.data)
        NotificationManager.success(
          'Woohoo! You updated the contact details successfully!'
        )
      })
      .catch((err) => {
        if (err && err.response.status === 422) {
          throw new SubmissionError({
            ...err.response.data.errors,
            _error: err.response.data.message,
          })
        }
      })
  }

  return (
    <div
      className={`edit-dealer-column {dealer.is_prospect ? 'border-bottom' : ''}`}
      style={{ maxWidth: 570 }}
    >
      <header className="d-flex align-items-center justify-content-between mb-4">
        <h6 className="mt-4 text-uppercase">Dealership contact details</h6>
        <button
          onClick={() => setEditContactDetails(!editContactDetails)}
          className="btn-gray"
          data-tip="Edit contact details"
          data-place="top"
        >
          <i className="wf-icon-edit d-block" />
        </button>
      </header>
      {!editContactDetails && (
        <>
          <div>
            <div className="row">
              <div className="col-4">
                <span className="label">Telephone (office)</span>
                <p>{echo(dealer.tel_office)}</p>
              </div>
              <div className="col-4">
                <span className="label">Telephone (mobile)</span>
                <p>{echo(dealer.tel_mobile)}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 mt-4">
                <span className="label">Email address</span>
                <p>{dealer.email}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 mt-4">
                <span className="label">Website</span>
                <p>{echo(dealer.website)}</p>
              </div>
            </div>
          </div>
        </>
      )}

      <SlideToggle isVisible={editContactDetails}>
        <EditContactDetailsForm
          onSubmit={handleEditContactDetailsForm}
          toggleForm={setEditContactDetails}
          initialValues={{ ...dealer }}
        />
      </SlideToggle>
    </div>
  )
}

DealerContactDetails.propTypes = {
  dealer: PropTypes.object.isRequired,
  setDealer: PropTypes.func.isRequired
}

export default DealerContactDetails
