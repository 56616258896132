import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../../../../utils/api'
import AdminComment from '../../../../ui/AdminComment'
import { confirmAlert } from 'react-confirm-alert'
import { remove } from 'lodash'
import AddComment from '../../../../ui/timeline/AddComment'
import { hasPermission } from '../../../../../utils/auth'

const AdminComments = (props) => {
  const [comments, setComments] = useState(null)
  const params = useParams()
  //
  useEffect(() => {
    api
      .get(`/direct-sales/policies/${params.id}/timeline?limit=50`)
      .then((res) => {
        setComments(res.data)
      })
      .catch((err) => {})
  }, [])

  const updateComment = (comment, message) => {
    return api.patch(`/direct-sales/policies/${params.id}/timeline/message/${comment.id}`, {
      ...comment,
      message,
    }).then((res) => {
      // update the comment in memory
      let comment = comments.data.find((c) => c.id === res.data.id)
      comment.message = message
    })
  }

  const deleteComment = (id) => {
    api.delete(`/direct-sales/policies/${params.id}/timeline/message/${id}`).then((res) => {
      const list = remove(comments.data, (c) => c.id !== id)

      setComments({
        ...comments,
        data: list,
      })
    })
  }

  function handleAddComment(data) {
    return new Promise((resolve, reject) => {
      api
        .post(`/direct-sales/policies/${params.id}/timeline/message`, data)
        .then((res) => {
          setComments((c) => {
            return {
              ...c,
              data: [res.data, ...c.data],
            }
          })
          resolve([])
        })
        .catch((err) => {
          if (err) reject(err)
        })
    })
  }

  const handleDelete = (comment) => {
    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>Delete the comment? It'll be lost forever.</p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  deleteComment(comment.id)
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  return (
    <>
      <div className="row">
        <div className="col pr-5 pb-4">
          <h6 className="mt-4 mb-3 text-uppercase">Admin comments</h6>
        </div>
        <div className="col">
          <div className="mt-4 d-flex justify-content-end">
            {hasPermission('claims.policies.update')  &&
              <AddComment handleAddComment={handleAddComment} />
            }
          </div>
        </div>
      </div>

      {comments &&
        comments.data.map((comment) => (
          <AdminComment
            key={comment.id}
            comment={comment}
            handleUpdate={hasPermission('claims.policies.update') ? updateComment : null}
            handleDelete={hasPermission('claims.policies.update') ? handleDelete : null}
          />
        ))}
    </>
  )
}

AdminComments.propTypes = {}

export default AdminComments
