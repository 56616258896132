/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import UserRow from './UserRow'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, reset, change, Form } from 'redux-form'
import { times, get, set } from 'lodash'
import { money, isPositiveFloat } from '../../../../../utils/misc'

const IndividualForm = ({
  handleSubmit,
  dispatch,
  users,
  formValues,
  teamBudgets,
  pristine,
  invalid,
  calculationFlag,
  resetCalculation,
  setCalculationEnabled,
}) => {
  // Augment the user array with their budgets from the form values.
  const [userFormValues, setUserFormValues] = useState([])
  const recalculate = () => {
    let augmentedUsers = users.map((user) => {
      let targets = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((val, idx) => {
        return parseFloat(
          get(formValues, `${user.person_id}.months.${idx}`, 0) || 0
        )
      })
      return { targets, ...user }
    })
    setUserFormValues(augmentedUsers)
  }
  useEffect(() => {
    if (calculationFlag) {
      recalculate()
      resetCalculation()
      setCalculationEnabled(false)
    }
  }, [calculationFlag])

  // Setup total cost calculation based on the above.
  const [totalCost, setTotalCost] = useState(0)
  useEffect(() => {
    setTotalCost(
      userFormValues.reduce((total, user) => {
        return total + user.targets.reduce((a, b) => a + parseFloat(b || 0), 0)
      }, 0)
    )
  }, [userFormValues])

  // Team Budget Total Calculation based on set team budgets got via ajax
  const [totalBudget, setTotalBudget] = useState(0)
  useEffect(() => {
    setTotalBudget(
      teamBudgets.reduce((total, current) => {
        return total + parseFloat(current || 0)
      }, 0)
    )
  }, [teamBudgets])

  //Total Budgets for each month calculation
  const [monthBudgets, setMonthBudgets] = useState([])
  useEffect(() => {
    let targets = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((val, idx) => {
      //For Each Month, add up each users targets for that month.
      return userFormValues.reduce(
        (total, user) => total + parseFloat(user.targets[idx] || 0),
        0
      )
    })
    setMonthBudgets(targets)
  }, [userFormValues])

  /** If actual < expected, return icon down etc **/
  const getRelevantIcon = (actual, expected) => {
    if (actual < expected) {
      return 'icn-arrow-down'
    } else if (actual > expected) {
      return 'icn-arrow-up'
    } else {
      return 'icn-equalto'
    }
  }

  const handleReset = (e) => {
    e.preventDefault()

    users.forEach((user) => {
      for (let i = 0; i < 12; i++) {
        dispatch(
          change(
            'individualTargetForm',
            `users.${user.person_id}.months.${i}`,
            ''
          )
        )
      }
    })

    const updatedUsers = users.map((u) => {
      u.targets = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      return u
    })

    setUserFormValues(updatedUsers)
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <table
          className={
            'table table-expand table-striped-inverse table-bordered table-individualTargets'
          }
        >
          <tbody>
            {userFormValues.map((user, idx) => (
              <UserRow
                recalculate={recalculate}
                user={user}
                key={idx}
                totalBudget={totalBudget}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>Totals:</td>
              <td>
                {totalBudget > 0
                  ? isNaN(totalCost / (totalBudget * 100))
                    ? '0.00'
                    : ((totalCost / totalBudget) * 100).toFixed(2)
                  : '-'}
                %{' '}
                <span className={'pull-right'} style={{ marginTop: 5 }}>
                  <i className={getRelevantIcon(totalCost, totalBudget)} />
                </span>
              </td>
              <td>
                {money.format(totalCost)}{' '}
                <span className={'pull-right'} style={{ marginTop: 5 }}>
                  <i className={getRelevantIcon(totalCost, totalBudget)} />
                </span>
              </td>
              {times(12, (idx) => {
                return (
                  <td key={idx}>
                    {money.format(monthBudgets[idx])}{' '}
                    <span className={'pull-right'} style={{ marginTop: 5 }}>
                      <i
                        className={getRelevantIcon(
                          monthBudgets[idx],
                          teamBudgets[idx]
                        )}
                      />
                    </span>
                  </td>
                )
              })}
            </tr>
          </tfoot>
        </table>
        <a
          style={{
            display: 'inline-block',
            marginBottom: '20px',
            marginTop: '10px',
            fontSize: '14px',
          }}
          onClick={handleReset}
          href={'#'}
        >
          Clear Table
        </a>

        <div style={{ marginBottom: '4rem', marginTop: '1rem' }}>
          <button
            type="submit"
            name="submit"
            disabled={pristine || invalid}
            className="btn btn-secondary"
          >
            Save Budgets
          </button>
          <button
            type="reset"
            className="btn btn-transparent text-secondary"
            onClick={() => {
              dispatch(reset('individualTargetForm'))
            }}
          >
            Cancel
          </button>
        </div>
      </Form>
    </>
  )
}

const selector = formValueSelector('individualTargetForm')

const validate = (values, form) => {
  let errors = {}
  get(values, 'users', []).forEach((user, id) => {
    user.months.forEach((value, month) => {
      if (value === '') {
        return
      } //Blank - ignore
      if (!isPositiveFloat(value)) {
        set(
          errors,
          `users.${id}.months.${month}`,
          'You must enter a positive number'
        )
      }
    })
  })
  return errors
}

export default connect((state) => ({
  formValues: selector(state, 'users'),
}))(
  reduxForm({
    form: 'individualTargetForm',
    validate,
  })(IndividualForm)
)
