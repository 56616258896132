import React, { useState } from 'react'
import AsyncSelect from 'react-select/lib/Async'
import { selectInputStyles } from '../../utils/misc'

const CustomAsyncSelect = props => {
  const [currentQuery, setCurrentQuery] = useState('')

  // eslint-disable-next-line no-unused-vars
  let validated = false
  const {
    meta: { touched, error, valid },
  } = props

  if (touched && (error && 'error')) {
    validated = false
  }

  if (touched && valid) {
    validated = true
  }

  const noOptionsMessage = () => {
    if (!currentQuery) return 'Please enter keyword'
    return 'No option'
  }

  return (
    <div className="b-form-group form-group select-z-index-fix">
      <label htmlFor="col-form-label pt-0">{props.label}</label>
      <AsyncSelect
        {...props}
        value={props.input.value}
        styles={selectInputStyles}
        components={{ IndicatorSeparator: () => null }}
        onChange={value => props.input.onChange(value)}
        onInputChange={query => setCurrentQuery(query)}
        onBlur={field => props.input.onBlur(props.input.value)}
        noOptionsMessage={noOptionsMessage}
      />

      {touched && error && (
        <div
          style={{
            marginTop: '0.25rem',
            fontSize: '90%',
            color: '#e10808',
          }}
        >
          {error}
        </div>
      )}
    </div>
  )
}

export default CustomAsyncSelect
