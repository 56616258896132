import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import CustomAsyncSelect from '../../../../ui/CustomAsyncSelect'

const DealerPricing = ({ fetchPricebands }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-4">
          <h6 className="text-uppercase">Dealer pricing</h6>
          <p>
            You can request custom prices for this dealer after you have added
            them
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Field
            className="select-z-index-fix"
            label="Dealer price band (to use as base)"
            component={CustomAsyncSelect}
            name={`price_band`}
            id={'dealerPriceBand'}
            isClearable
            loadOptions={fetchPricebands}
            defaultOptions
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
          />
        </div>
      </div>
    </>
  )
}

DealerPricing.propTypes ={
  fetchPricebands: PropTypes.func.isRequired
}

export default DealerPricing