import React from 'react'
import PropTypes from 'prop-types'
import NotificationManager from 'react-notifications/lib/NotificationManager';
import api from '../../../../../utils/api';

const Downloads = ({ policy }) => {

  /**
   * Handles a file download
   *
   * @param {string} endpoint  - API endpoint to fetch the download from
   * @param {string } filename  - The filename to give the downloaded file
   */
  const handleDownload = async (endpoint, filename) => {
    NotificationManager.success('Woohoo! Your download will start soon...')
    const xhr = await api.get(endpoint, {responseType: 'blob'})
    const url = window.URL.createObjectURL(new Blob([xhr.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  }

  const downloadCertificateClicked = async () => {
    await handleDownload(
      `/policyholder-portal/downloads/certificate/${policy.id}`,
      `${policy.ref}_Warranty_Certificate.pdf`
    );
  }

  const downloadTsAndCsClicked = async () => {
    await handleDownload(
      `/policyholder-portal/downloads/terms-and-conditions/${policy.id}`,
      `${policy.ref}_TermsAndConditions.pdf`
    );
  }

  return (
    <div className="mb-0">
      <div className="row">

        <div className="col-5 pr-5">
          <div
            className="ph-claim d-flex justify-content-between align-items-center cursor-pointer"
            onClick={downloadCertificateClicked}
          >
            <div className="ph-document-ref font-md">
              Warranty Certificate
            </div>
            <div className="ph-documents-links mt-2">
              <i className="wf-icon-download d-inline-block text-blue font-weight-bold"/>
            </div>
          </div>
        </div>

        <div className="col-5 pr-5">
          <div
            className="ph-claim d-flex justify-content-between align-items-center cursor-pointer"
            onClick={downloadTsAndCsClicked}
          >
            <div className="ph-document-ref font-md">
              Terms &amp; Conditions
            </div>
            <div className="ph-documents-links mt-2">
              <i className="wf-icon-download d-inline-block text-blue font-weight-bold"/>
            </div>
          </div>
        </div>

      </div>

      <div className="row">
        <div className="col">
          <hr className="mt-4"/>
        </div>
      </div>

    </div>
  )
}

Downloads.propTypes = {
  policy: PropTypes.object.isRequired,
}

export default Downloads
