import styled from 'styled-components'

export const LoginPageWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }  
  
  .page-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }

  .meta-links {
    text-align: center;
    font-size: 0.875em;
    max-width: 24.375rem;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    div {
      &:first-of-type {
        margin-bottom: .5rem;
      }
    }
    
    .tos {
      margin-top: 1.25rem;
    }
  }
`

export const LoginFormWrapper = styled.div`
  max-width: 24.4rem;
  width: 100%;
  box-shadow: 0 4px 20px 0 rgba(45, 44, 49, 0.1); 
  padding: 30px;
  border-radius: 0.25rem;
  
  .link-forgot-password {
    font-weight: 800;
    color: #70B725;
  }
  
  h4 {
    font-size: 1em;
    margin-bottom: 1.3rem;
    font-weight: 600;
  }
`

export const HaveQuestions = styled.div`
 h1 {
  font-size: 3.125em !important; 
  font-weight: 800;
 }
  max-width: 28.125rem;
  text-align: center;
  p {
    font-size: 1.12em;
    max-width: 23rem;
    margin-left: auto;
    margin-right: auto;
    color: rgba(255,255,255,0.7);
    line-height: 1.5em;
  }
  
  .heading-call-us {
    text-transform: none;
    font-size: 1.25em;  
    font-weight: 800;
    color: #fff;
    a {
      margin-left: 0.625rem;
    }
  }
`