import api from '../utils/api'
import {
  FETCH_PIPELINE_DATA_REQUEST,
  FETCH_PIPELINE_DATA_SUCCESS,
  FETCH_PIPELINE_DATA_FAILURE, PIPELINE_UPDATE_CARD_POSITION,
} from '../constants'

export const fetchPipelineData = (query, target = 'direct-sales') => dispatch => {
  dispatch({
    type: FETCH_PIPELINE_DATA_REQUEST,
  })
  let url;

  if (query)
    url = `/${target}/pipeline/lanes${query}`
  else
    url = `/${target}/pipeline/lanes`

  return api
    .get(url)
    .then(res => {
      dispatch({
        type: FETCH_PIPELINE_DATA_SUCCESS,
        payload: {
          pipelines: res.data,
        },
      })
    })
    .catch(err => {
      console.log(err.response)
      dispatch({
        type: FETCH_PIPELINE_DATA_FAILURE,
        payload: {
          error: err.response,
        },
      })
    })
}


export const updateBoards = (oldLaneId, newLaneId, cardId, destinationIndex) => dispatch => {
  return dispatch({
    type: PIPELINE_UPDATE_CARD_POSITION,
    payload: { oldLaneId, newLaneId, cardId, destinationIndex}
  })
}