import React from 'react'
import PropTypes from 'prop-types'

const ColorPicker = props => {
  const [showPicker, setShowPicker] = React.useState()
  const [color, setColor] = React.useState('#111E60')

  const toggle = () => setShowPicker(!showPicker)

  const handleChange = color => {
    setColor(color.value)
    props.onColorChange(color.value)
    toggle()
  }

  React.useEffect(() => {
    if (meta && meta.initial) {
      setColor(meta.initial)
    }
  }, [])

  React.useEffect(() => {
    document.addEventListener('click', clickAwayListener)

    props.onColorChange(color)
    return () => {
      document.removeEventListener('click', clickAwayListener)
    }
  })

  const clickAwayListener = e => {
    if (
      !e.target.classList.contains('color-picker-dropdown') &&
      !e.target.classList.contains('color-picker') &&
      !e.target.classList.contains('swatch')
    )
      setShowPicker(false)
  }

  const { colors, label, meta } = props

  return (
    <div className="form-group">
      {label && <label>{label}</label>}
      <div className="color-picker">
        <div
          className="swatch swatch--default"
          onClick={() => toggle()}
          style={{ background: color }}
        />

        {showPicker && (
          <div className="color-picker-dropdown">
            {colors &&
              colors.map((color, index) => {
                return (
                  <div
                    className="swatch"
                    style={{ background: color.value }}
                    key={index}
                    onClick={() => handleChange(color)}
                  />
                )
              })}
          </div>
        )}
      </div>
    </div>
  )
}

ColorPicker.propTypes = {
  onColorChange: PropTypes.func.isRequired,
  colors: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
}

export default ColorPicker
