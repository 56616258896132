import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import SlideToggle from '../../../../ui/SlideToggle'
import EditGarageForm from './EditGarageForm'
import EditContactDetailsForm from '../../../../ui/forms/EditContactDetailsForm'
import api from '../../../../../utils/api'
import { history } from '../../../../../utils/history'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import { confirmAlert } from 'react-confirm-alert'
import AdminComments from "./AdminComments";
import EditGarageFormStatus from "./EditGarageStatusForm";
import EditLinkedDealersForm from "./EditLinkedDealersForm";
import { hasPermission } from '../../../../../utils/auth';


const Garage = ({ match, ...props }) => {
    const [editGarageDetails, setEditGarageDetails] = useState(false)
    const [editContactDetails, setEditContactDetails] = useState(false)
    const [editGarageStatus, setEditGarageStatus] = useState(false)
    const [editLinkedDealers, setEditLinkedDealers] = useState(false)

    const handleEditGarageSubmit = (values) => {
        api
            .patch(`/claims/garages/${match.params.id}/garage-details`, {
                name: values.name,
                address_1: values.address_1 || '',
                address_2: values.address_2 || undefined,
                address_3: values.address_3 || undefined,
                city: values.city || '',
                county: values.county || '',
                postcode: values.postcode || '',
                authorised_repairer: values.authorised_repairer || false,
                vat_registered: values.vat_registered || false,
                vat_number: values.vat_registered ? values.vat_number : null,
                signup_date: values.signup_date
                    ? moment(values.signup_date).format('YYYY-MM-DD')
                    : undefined,
            })
            .then((response) => {
                setGarage(response.data)
                NotificationManager.success(
                    'Woohoo! The garage details have been updated!'
                )
            })
            .catch(() => {
                NotificationManager.error(
                    'Oops! Something went wrong saving the garage details.'
                )
            })
    }
    const handleEditContactDetailsSubmit = (values) => {
        api
            .patch(
                `/claims/garages/${match.params.id}/garage-contact-details`,
                values
            )
            .then((response) => {
                //Don't overwrite other data.
                setGarage({...garage, ...response.data})
                NotificationManager.success(
                    'Woohoo! The garage contact details have been updated!'
                )
            })
            .catch(() => {
                NotificationManager.error(
                    'Oops! Something went wrong saving the garage contact details.'
                )
            })
    }

    const handleEditLinkedDealerSubmit = (values) => {
        api.patch(`/claims/garages/${match.params.id}/garage-labour-rates`, {
            minimum_labour_rate: values.minimum_labour_rate || '',
            sell_out_labour_rate: values.sell_out_labour_rate || '',
        })
        .then((response) => {
            setGarage(response.data)
        })
        .catch(() => {})

        api.patch(`/claims/garages/${match.params.id}/garage-dealer`, {dealer_ids: values.dealers.map(d => d.id)})
          .then(() => {
              setGarage({...garage, dealers: values.dealers})
              fetchGarage()
              NotificationManager.success('Woohoo! Garage dealers updated.');
          }).catch(() => {
            NotificationManager.error(
              'Oops! Something went wrong saving the garage dealers.'
            )
        })
    }

    const handleDelete = () => {
        confirmAlert({
            title: '🧐 Are you sure?',
            customUI: ({ title, onClose }) => {
                return (
                    <div className="react-confirm-alert">
                        <div className="react-confirm-alert-body">
                            <span className="btn-close-modal fa fa-close" onClick={onClose} />
                            <h3>{title}</h3>
                            <p>
                                Delete the garage <strong>{garage.name}</strong>? It'll be lost
                                forever.
                            </p>

                            <button
                                className="btn btn-secondary"
                                onClick={() => {
                                    onClose()
                                    deleteGarage()
                                }}
                            >
                                Yes, I'm sure
                            </button>
                            <button
                                className="btn btn-transparent text-secondary"
                                onClick={onClose}
                            >
                                No, cancel
                            </button>
                        </div>
                    </div>
                )
            },
        })
    }

    const deleteGarage = () => {
        api
            .delete(`/claims/garages/${match.params.id}`)
            .then(() => {
                history.push(`/claims/garages`)
                NotificationManager.success('Woohoo! The garage has been deleted!')
            })
            .catch(() => {
                NotificationManager.error(
                    'Oops! Something went wrong saving the garage contact details.'
                )
            })
    }

    const handleStatusChange = (status) => {
        api
            .patch(`/claims/garages/${match.params.id}/garage-status`, { status })
            .then((response) => {
                setGarage({ ...garage, status: response.data.status })
                NotificationManager.success(
                    'Woohoo! The garage status has been updated!'
                )
            })
            .catch(() => {
                NotificationManager.error(
                    'Oops! Something went wrong saving the garage status.'
                )
            })
    }

    const [garage, setGarage] = useState()

    const fetchGarage = () => {
        api
          .get(`/claims/garages/${match.params.id}`)
          .then((response) => {
              setGarage(response.data)
          })
          .catch((err) => {
              if (err.response && err.response.status === 404) {
                  history.push('/404')
              }
          })
    }

    useEffect(() => {
        fetchGarage()
    }, [])

    const editDetailsInitialValues = () => {
        return {
            name: garage.name,
            address_1: garage.address_1,
            address_2: garage.address_2,
            address_3: garage.address_3,
            city: garage.city,
            county: garage.county,
            postcode: garage.postcode,
            signup_date: garage.signup_date
                ? moment(garage.signup_date).toDate()
                : null,
            authorised_repairer: garage.authorised_repairer,
            vat_registered: garage.vat_registered,
            vat_number: garage.vat_number,
        }
    }

    const editContactInitialValues = () => {
        return {
            tel_office: garage.tel_office,
            tel_mobile: garage.tel_mobile,
            email: garage.email,
            website: garage.website,
        }
    }

    const editAdditionalInformationInitialValues = () => {
        return {
            minimum_labour_rate: garage.minimum_labour_rate,
            sell_out_labour_rate: garage.sell_out_labour_rate,
            dealers: garage.dealers,
        }
    }

    const handleGarageStatusSubmit = (values) => {
      handleStatusChange(values.status.name);
    };

    return (
        <div>
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to="/claims/garages" className="open active">
                        Garages
                    </Link>
                </li>
                <li className="breadcrumb-item">
                    <span className="active">{hasPermission('claims.garages.update') ? 'Edit' : 'View'} garage</span>
                </li>
            </ol>

            {garage && (
                <div className="container">
                    <header className="py-4 d-flex align-items-center justify-content-between border-bottom">
                        <div>
                            <div className="d-flex">
                                <h3>
                                    {hasPermission('claims.garages.update') ? 'Edit' : 'View'} garage
                                    {garage.status === 'Blacklisted' && (
                                        <span className="badge badge-blacklisted badge-pill font-sm  py-2 px-3 ml-3">
                                            Blacklisted
                                        </span>
                                    )}
                                </h3>

                            </div>
                        </div>
                        <div>
                            {hasPermission('claims.garages.update') && garage.status === 'Active' && (
                                <button
                                    onClick={() => {
                                        handleStatusChange('Blacklisted')
                                    }}
                                    className="btn btn-error btn-md bg-red text-white mr-4"
                                >
                                    Blacklist
                                </button>
                            )}
                            {hasPermission('claims.garages.update') && garage.status === 'Blacklisted' && (
                                <button
                                    onClick={() => {
                                        handleStatusChange('Active')
                                    }}
                                    className="btn btn-error btn-md bg-green text-white mr-4"
                                >
                                    Reinstate
                                </button>
                            )}
                            {hasPermission('claims.garages.update') && (
                                <button
                                    className="btn btn-light"
                                    style={{ width: 55 }}
                                    onClick={handleDelete}
                                >
                                    <i className="wf-icon-delete d-block" />
                                </button>
                            )}
                        </div>
                    </header>

                    <div className="row">
                        <div className="col-8 border-right mb-5">
                            <div className="row">
                                <div className="col-12 pr-5">
                                    <header className="position-relative d-flex align-items-center justify-content-between">
                                        <h6 className="mt-5 mb-4 text-uppercase">Garage details</h6>
                                        {hasPermission('claims.garages.update') && !editGarageDetails && (
                                            <button
                                                className="btn btn-light btn-sm"
                                                onClick={() => setEditGarageDetails(true)}
                                                style={{ marginTop: 25, width: 34, height: 34 }}
                                            >
                                                <i className="wf-icon-edit mr-0" />
                                            </button>
                                        )}
                                    </header>

                                    {!editGarageDetails && (
                                        <>
                                            <div className="row">
                                                <div className="col">
                                                    <span className="label">Garage name</span>
                                                    <p>{garage.name}</p>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <span className="label">Address</span>
                                                    <p>
                                                        {[
                                                            garage.address_1,
                                                            garage.address_2,
                                                            garage.address_3,
                                                            garage.city,
                                                            garage.county,
                                                            garage.postcode,
                                                        ]
                                                            .filter(Boolean)
                                                            .join(', ')}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <span className="label">Sign-up date</span>
                                                    <p>
                                                        {garage.signup_date
                                                            ? moment(garage.signup_date).format('DD/MM/YYYY')
                                                            : '-'}
                                                    </p>
                                                </div>
                                                <div className="col-12">
                                                    {garage.authorised_repairer ? (
                                                        <p>
                                                            <i className="wf-icon-circle-tick d-inline-block text-green" />{' '}
                                                            This garage is an authorised repairer.
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            <i className="wf-icon-red-cross d-inline-block text-green" />{' '}
                                                            This garage is not an authorised repairer.
                                                        </p>
                                                    )}
                                                    {garage.vat_registered ? (
                                                        <p>
                                                            <i className="wf-icon-circle-tick d-inline-block text-green" />{' '}
                                                            This garage is VAT registered.
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            <i className="wf-icon-red-cross d-inline-block text-green" />{' '}
                                                            This garage is VAT registered.
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <SlideToggle isVisible={editGarageDetails}>
                                        <EditGarageForm
                                            initialValues={editDetailsInitialValues()}
                                            toggleForm={setEditGarageDetails}
                                            onSubmit={handleEditGarageSubmit}
                                        />
                                    </SlideToggle>
                                    <hr />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 pr-5">
                                    <header className="position-relative d-flex align-items-center justify-content-between">
                                        <h6 className="mt-5 mb-4 text-uppercase">Garage status</h6>
                                        {hasPermission('claims.garages.update') && !editGarageStatus && (
                                          <button
                                            className="btn btn-light btn-sm"
                                            onClick={() => setEditGarageStatus(true)}
                                            style={{ marginTop: 25, width: 34, height: 34 }}
                                          >
                                              <i className="wf-icon-edit mr-0" />
                                          </button>
                                        )}
                                    </header>

                                    {!editGarageStatus && (
                                      <>
                                          <div className="row">
                                              <div className="col-4">
                                                  <span className="label">Status</span>
                                                  <p>
                                                      {garage.status}
                                                  </p>
                                              </div>
                                          </div>
                                      </>
                                    )}

                                    <SlideToggle isVisible={editGarageStatus}>
                                        <EditGarageFormStatus
                                          initialValues={{status: {name: garage.status}}}
                                          toggleForm={setEditGarageStatus}
                                          onSubmit={handleGarageStatusSubmit}
                                        />
                                    </SlideToggle>
                                    <hr />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 pr-5">
                                    <header className="mt-4 mb-5 d-flex align-items-center justify-content-between">
                                        <h6 className="text-uppercase mb-0">Contact details</h6>
                                        {hasPermission('claims.garages.update') && !editContactDetails && (
                                            <button
                                                className="btn btn-light btn-sm"
                                                onClick={() => setEditContactDetails(true)}
                                                style={{ width: 34, height: 34 }}
                                            >
                                                <i className="wf-icon-edit mr-0" />
                                            </button>
                                        )}
                                    </header>

                                    {!editContactDetails && (
                                        <>
                                            <div className="row">
                                                <div className="col-4">
                                                    <span className="label">Telephone (office)</span>
                                                    <p>{garage.tel_office}</p>
                                                </div>

                                                <div className="col-4">
                                                    <span className="label">Telephone (mobile)</span>
                                                    <p>{garage.tel_mobile || '-'}</p>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-4">
                                                    <span className="label">Email address</span>
                                                    <p>{garage.email || '-'}</p>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-4">
                                                    <span className="label">Website</span>
                                                    <p>{garage.website || '-'}</p>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <SlideToggle isVisible={editContactDetails}>
                                        <EditContactDetailsForm
                                            initialValues={editContactInitialValues()}
                                            toggleForm={setEditContactDetails}
                                            onSubmit={handleEditContactDetailsSubmit}
                                        />
                                    </SlideToggle>

                                    <hr />

                                    <AdminComments />

                                </div>
                            </div>
                        </div>
                        <div className="col-4 mb-5">
                            <div className="row">
                                <div className="col-12 pl-5">
                                    <div className="mt-5 mb-4 d-flex align-items-center justify-content-between">
                                        <h6 className="mb-0 text-uppercase">
                                            Additional information
                                        </h6>
                                        {hasPermission('claims.garages.update') && (
                                        <button
                                            className="btn btn-light btn-sm"
                                            style={{ width: 34, height: 34 }}
                                            onClick={() => {setEditLinkedDealers(true)}}
                                        >
                                            <i className="wf-icon-edit mr-0" />
                                        </button>
                                        )}
                                    </div>

                                    {!editLinkedDealers &&
                                    <div className="row">
                                        <div className="col-12">
                                            <span className="label mb-2">Minimum Labour Rate</span>
                                            <p>{garage.minimum_labour_rate || '-'}</p>
                                        </div>
                                        <div className="col-12">
                                            <span className="label mb-2">Sell Out Labour Rate</span>
                                            <p>{garage.sell_out_labour_rate || '-'}</p>
                                        </div>
                                        <div className="col-12">
                                            <span className="label mb-2">Linked to dealer(s)</span>
                                            {garage.dealers && garage.dealers.map(dealer => <p key={dealer.id}>{dealer.name}</p>)}
                                        </div>
                                    </div>
                                    }
                                    <SlideToggle isVisible={editLinkedDealers}>
                                        <EditLinkedDealersForm
                                            initialValues={editAdditionalInformationInitialValues()}
                                            onSubmit={handleEditLinkedDealerSubmit}
                                            toggleForm={setEditLinkedDealers}
                                        />
                                    </SlideToggle>

                                    <hr />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

Garage.propTypes = {}

export default Garage