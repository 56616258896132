import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Table from '../../../../ui/Table'
import { sortCaret } from '../../../../../utils/misc'
import api from '../../../../../utils/api'
import { history } from '../../../../../utils/history'

const Show = ({ match, ...props }) => {
  const [territory, setTerritory] = useState(null)
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    order: 'postcode',
    desc: true,
    query: '',
  });

  useEffect(() => {
    api
      .get(`external-sales/territories/${match.params.id}?${buildUrl()}`)
      .then((response) => {
        setTerritory(response.data)
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 404) {
          history.push('/404')
        }
      })
  }, [match.params.id, filters])

  const buildUrl = () => {
    const params = new URLSearchParams({...filters})
    return params.toString();
  }


  const [accountManagers, setAccountManagers] = useState({
    total: 0,
    page: 0,
    data: [],
  })

  useEffect(() => {
    if (!territory) return;
    setAccountManagers({
      total: territory.account_managers.length,
      page: 1,
      from: 1,
      to: territory.account_managers.length,
      data: territory.account_managers
    });
  }, [territory])

  const columns = [
    {
      dataField: 'name',
      text: 'Account manager',
      sort: true,
      sortCaret,
    },
    {
      dataField: 'start_date',
      text: 'Start date',
    },
    {
      dataField: 'end_date',
      text: 'End date',
    },
  ]

  const columnsPostcodeTable = [
    {
      dataField: 'postcode',
      text: 'Postcode',
      sort: true,
      sortCaret,
    },
  ]

  const handleSearch = (val) => {
    setFilters({...filters, query: val})
  }

  const handleTableChange = (type, values) => {
    setFilters({
      ...filters,
      page: values.page,
      limit: values.sizePerPage,
      order: values.sortField || 'postcode',
      desc: !values.sortOrder || (values.sortOrder !== 'asc')
    })
  }

  return (
    <>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/external-sales/territories" className="open active">
            Territories
          </Link>
        </li>
        {territory && (
          <li className="breadcrumb-item">
            <span className="active">{territory.name}</span>
          </li>
        )}
      </ol>

      {territory && (
        <div className="container-fluid animated fadeIn">
          <div className="mw-37-5rem mx-auto">
            <h2 className="mb-5 mt-5">{territory.name}</h2>
            <h6 className="font-sm font-weight-medium mb-4">
              Territory Account Managers
            </h6>

            <Table
              resource={accountManagers}
              columns={columns}
              showSizePerPage={false}
              searching={false}
            />

            <div style={{ marginTop: '-50px' }}>
              <hr style={{ marginBottom: '45px' }} />
              <h6 className="font-sm font-weight-medium mb-4">
                Territory Postcodes
              </h6>

              <Table
                resource={territory.postcodes}
                columns={columnsPostcodeTable}
                handleTableChange={handleTableChange}
                hasTableSearch={true}
                onSearch={handleSearch}
                searching={false}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Show
