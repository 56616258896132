import React from 'react'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const PageHeader = props => {
  const {
    title,
    description,
    buttonLink,
    buttonText,
    buttonIcon,
    svgIcon,
    children,
  } = props
  return (
    <Row>
      <Col sm="7">
        <h1 className="page-title">{title}</h1>
        <p dangerouslySetInnerHTML={{__html: description}}/>
        {children}
      </Col>
      <Col sm="5" style={{textAlign: 'right'}}>
        {buttonLink && (
          <Link className="btn btn-secondary" to={buttonLink}>
            {buttonIcon && <i className={`${buttonIcon} float-left`} style={{ marginTop: '0.5em'}}/>}
            {svgIcon && (
              <img
                src={svgIcon}
                style={{marginRight: 5}}
                alt="Add user icon"
              />

            )}{' '}
            {buttonText}
          </Link>
        )}
      </Col>
    </Row>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.string,
}

export default PageHeader
