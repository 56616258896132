import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  UPDATE_SUSPENDED_USER,
  USERS_TABLE_ROW_ADD,
  USERS_TABLE_ROW_REMOVE,
  DELETE_USER_SUCCESS,
  FETCH_USER_SUCCESS,
  ACTIVATE_USER_SUCCESS,
} from '../constants'
import api from '../utils/api'
import { has, cloneDeep, isNull } from 'lodash'
import { SubmissionError } from 'redux-form'
import { NotificationManager } from 'react-notifications'
import { history } from '../utils/history'
import {
  FETCH_USER_REQUEST,
  FETCH_USER_FAILURE,
  UPDATE_USER_INFO_REQUEST,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILURE,
  RESTORE_USER,
} from '../constants/index'
import { authSuccess } from './authentication.action'
import { accessTokenClaims } from '../utils/auth'

export const fetchUsersRequest = () => ({
  type: FETCH_USERS_REQUEST,
})

export const fetchUsersSuccess = data => ({
  type: FETCH_USERS_SUCCESS,
  payload: data,
})

export const fetchUsersFailure = err => ({
  type: FETCH_USERS_FAILURE,
  payload: err,
})

/**
 * Fetch users
 */
export const fetchUsers = url => dispatch => {
  dispatch(fetchUsersRequest())

  api
    .get(`/admin/users/users${url ? url : ''}`)
    .then(res => {
      dispatch(fetchUsersSuccess(res.data))
    })
    .catch(err => {
      return dispatch(fetchUsersFailure(err.response))
    })
}

/**
 * Create user
 *
 * @param {Object} values
 */
export const addUser = values => dispatch => {
  let data = cloneDeep(values)

  if (has(data, 'dealer_id')) {
    if (data.dealer_id.value === 'none' || data.dealer_id.value === '') {
      delete data.dealer_id
    } else {
      data.dealer_id = data.dealer_id.id
    }
    if (Array.isArray(data.groups)) {
      data.groups = data.groups.map(item => item.value)
    } else {
      data.groups = [data.groups.value]
    }
  } else {
    data.groups = data.groups.map(g => g.value)
  }

  data.is_active = true

  return api
    .post('/admin/users/users', data)
    .then(res => {
      NotificationManager.success('Woohoo! You added a new user.')
      history.push('/admin/users')
    })
    .catch(err => {
      const errors = err.response.data.errors
      throw new SubmissionError({
        ...errors,
        _error: err.response.data.message,
      })
    })
}

export const updateSuspendedUser = (id, reason) => ({
  type: UPDATE_SUSPENDED_USER,
  payload: { id, reason },
})

/**
 * Suspend user(s)
 *
 * @param {Array} userId
 * @param {String} reason
 */
export const suspendUser = (userId, reason) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .patch(`admin/users/users`, {
        action: 'suspend',
        ids: [userId],
        suspended_reason: reason,
      })
      .then(res => {
        NotificationManager.success('Woohoo! You suspended a user.')
        dispatch(updateSuspendedUser(userId, reason))
        resolve(res.data)
      })
      .catch(err => {
        // Unprocessable entity status code / field(s) error.
        if (err.response.status !== 422)
          NotificationManager.error(
            'Oops! Something went wrong. Please try again.',
          )

        reject(err.response.data)
      })
  })
}

/**
 * Multi user suspend
 *
 * @param {Array} ids
 * @param {String} reason
 */
export const suspendMultipleUsers = (ids, reason) =>
  new Promise((resolve, reject) => {
    api
      .patch(`admin/users/users`, {
        action: 'suspend',
        ids: ids,
        suspended_reason: reason,
      })
      .then(res => {
        NotificationManager.success('Woohoo! You suspended multiple users.')
        return resolve(res)
      })
      .catch(err => {
        return reject(err.response.data)
      })
  })

export const usersTableRowAdd = data => ({
  type: USERS_TABLE_ROW_ADD,
  payload: { data },
})

export const usersTableRowRemove = data => ({
  type: USERS_TABLE_ROW_REMOVE,
  payload: { data },
})

/**
 * Delete a user by ID
 *
 * @param {integer} id
 * @param {boolean} redirectTo
 */
export const deleteUser = (id, redirectTo = false) => dispatch => {
  return api
    .delete(`admin/users/users/${id}`)
    .then(res => {
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: {
          id,
        },
      })
      NotificationManager.success('Woohoo! You deleted a user.')
      if (redirectTo) history.push(redirectTo)
    })
    .catch(() => {
      NotificationManager.error('Oops! Could not delete the user.')
    })
}

const fetchUserReq = () => ({
  type: FETCH_USER_REQUEST,
})

const fetchUserSuccess = user => ({
  type: FETCH_USER_SUCCESS,
  payload: { user },
})

const fetchUserFailure = error => ({
  type: FETCH_USER_FAILURE,
  payload: { error },
})

/**
 * Fetch user by id
 *
 * @param {Integer} id
 */
export const fetchUser = id => dispatch => {
  dispatch(fetchUserReq())
  api
    .get(`/admin/users/users/${id}`)
    .then(res => {
      return dispatch(fetchUserSuccess(res.data))
    })
    .catch(err => {
      return dispatch(fetchUserFailure(err.response))
    })
}

const updateUserInfoRequest = () => ({
  type: UPDATE_USER_INFO_REQUEST,
})

const updateUserInfoSuccess = user => ({
  type: UPDATE_USER_INFO_SUCCESS,
  payload: { user },
})

const updateUserInfoFailure = error => ({
  type: UPDATE_USER_INFO_FAILURE,
  payload: { error },
})

/**
 * Update user
 *
 * @param {Object} user
 */
export const updateUserInfo = values => dispatch => {
  dispatch(updateUserInfoRequest())
  let data = cloneDeep(values)

  if (
    (values.dealer_id && values.dealer_id.name === 'None') ||
    isNull(values.dealer_id) ||
    isNull(values.dealer_id.name)
  ) {
    delete data.dealer_id
    delete data.dealer_name
    data.groups = values.groups.map(g => (g.id ? g.id : g.value))
  } else {
    data.dealer_id = values.dealer_id.id
    data.dealer_name = values.dealer_id.name
    if (Array.isArray(data.groups)) {
      data.groups = data.groups.map(g => (g.id ? g.id : g.value))
    } else {
      data.groups = [data.groups.id ? data.groups.id : data.groups.value]
    }
  }

  if (values.is_active === false && values.move_sales_to) {
    delete data.move_sales_to
    data.move_sales_to_id = values.move_sales_to.person_id
  }

  return api
    .patch(`/admin/users/users/${data.id}`, data)
    .then(res => {
      NotificationManager.success('Woohoo! Your changes were saved.')
      dispatch(updateUserInfoSuccess(res.data))
    })
    .catch(err => {
      const errors = err.response.data.errors
      dispatch(updateUserInfoFailure(err.response.data))
      throw new SubmissionError({
        ...errors,
        _error: err.response.data.message,
      })
    })
}

/**
 * Multi user delete
 *
 * @param {Array} ids
 * @param {String} reason
 */

export const deleteMultipleUsers = ids =>
  new Promise((resolve, reject) => {
    api
      .patch(`admin/users/users`, {
        action: 'delete',
        ids: ids,
      })
      .then(res => {
        NotificationManager.success('Woohoo! You deleted multiple users.')
        resolve(res)
      })
      .catch(err => {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again.',
        )
        reject(err)
      })
  })

/**
 * Activate user(s)
 *
 * @param {Array} ids
 */
export const activateUsers = ids =>
  new Promise((resolve, reject) => {
    api
      .patch(`admin/users/users/`, {
        action: 'activate',
        ids,
      })
      .then(res => {
        if (ids.length > 1)
          NotificationManager.success('Woohoo! You activated multiple users.')
        else NotificationManager.success('Woohoo! You activated a user.')
        resolve(res)
      })
      .catch(err => {
        if (err.response.status > 422)
          NotificationManager.error(
            'Oops! Something went wrong. Please try again.',
          )
        reject(err)
      })
  })

/**
 * Restore single user
 *
 */
export const restoreSingleUser = id => dispatch => {
  api
    .patch(`admin/users/users`, {
      action: 'restore',
      ids: [id],
    })
    .then(res => {
      NotificationManager.success('Woohoo! You restored a user.')
      dispatch({
        type: RESTORE_USER,
      })
    })
    .catch(err => {
      NotificationManager.error('Oops! Something went wrong. Please try again.')
    })
}

/**
 * Activate user
 *
 * @param {Number} id
 */
export const activateUserSuccess = id => ({
  type: ACTIVATE_USER_SUCCESS,
  payload: { id },
})

const onProfileUpdate = (token, fields, dispatch) => {
  NotificationManager.success('Whoo! Your changes were saved.')
  localStorage.setItem('wf-token', JSON.stringify(token))
  const claims = accessTokenClaims(token)

  // using the same action creator because we need the same data mutation?
  return dispatch(
    authSuccess({
      access_token: token,
      user: {
        ...fields,
        ...claims,
      },
    }),
  )
}

/**
 * Update user profile
 *
 * @param {Object} fields
 */
export const updateProfile = fields => dispatch => {
  return api
    .patch('/profile', fields)
    .then(res => onProfileUpdate(res.data.access_token, fields, dispatch))
    .catch(err => {
      if (err && err.response.status === 422) {
        throw new SubmissionError({
          ...err.response.data.errors,
          _error: err.response.data,
        })
      }

      NotificationManager.error(
        'Whoops! Something went wrong. Please try again after refreshing.',
      )
    })
}

export const updateAvatar = (formData, removed) => dispatch => {
  return api.post('/profile/avatar', formData).then(res => {
    if (typeof formData === 'object' && formData.avatar === null ) {
      NotificationManager.success('Woohoo! You removed your avatar.')
    } else {
      NotificationManager.success('Woohoo! Your new avatar was saved.')
    }

    localStorage.setItem('wf-token', JSON.stringify(res.data.access_token))
    const claims = accessTokenClaims(res.data.access_token)

    // using the same action creator because we need the same data mutation?
    return dispatch(
      authSuccess({
        access_token: res.data.access_token,
        user: {
          ...claims,
        },
      }),
    )
  })
}
