import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {change, Field, formValueSelector, reduxForm} from "redux-form";
import CustomSelect from "../../../../ui/CustomSelect";
import renderDatePicker from "../../../../ui/renderDatePicker";
import renderField from "../../../../../utils/renderField";
import api from "../../../../../utils/api";

const PolicyEditForm = ({
  onCancel,
  handleSubmit,
  policy,
  company_name,
  coverLevel,
  duration,
  claimLimit,
  excess,
  labourRate,
  sundries,
  policyPrice,
  dispatch,
  soldBy,
}) => {

  const [soldByUsers, setSoldByUsers] = useState([])
  useEffect(() => {
    api.get('/direct-sales/users')
      .then(res => {
        setSoldByUsers(res.data)
      })
  }, [])

  const [coverLevels, setCoverLevels] = useState([]);
  useEffect(() => {
    api.get(`/quote/plans`, {
      params: {
        legacy: false,
        type: policy.vehicle.type,
        make: policy.vehicle.make,
        model: policy.vehicle.model,
        drive_type: policy.vehicle.drive_type,
        engine_size: policy.vehicle.engine_size,
        reg_date: policy.vehicle.reg_date,
        mileage: policy.vehicle.mileage,
        sale_price: policy.vehicle.retail_price,
      }
    })
      .then(res => {
        setCoverLevels(Object.values(res.data).map(a => a.cover_level))
      })
      .catch(() => setCoverLevels([]))
  }, [])

  const [durationOptions, setDurationOptions] = useState([]);
  const [claimLimitOptions, setClaimLimitOptions] = useState([]);

  useEffect(() => {
    if (coverLevel) {
      api.get(`/quote/options`, {
        params: {
          type: policy.vehicle.type,
          make: policy.vehicle.make,
          model: policy.vehicle.model,
          drive_type: policy.vehicle.drive_type,
          engine_size: policy.vehicle.engine_size,
          reg_date: policy.vehicle.reg_date,
          mileage: policy.vehicle.mileage,
          sale_price: policy.vehicle.retail_price,
          cover_level: coverLevel.id,
        }
      })
        .then(res => {
          setDurationOptions(Object.keys(res.data.Prices).map(a => {
            return {
              name: a,
              value: a,
            }
          }))
          setClaimLimitOptions(res.data.ClaimLimits.map(a => {
            return {
              name: `£${a}`,
              value: parseInt(a),
            }
          }))
        }).catch(() => {
          setDurationOptions([])
          setClaimLimitOptions([])
        })
    }
  }, [coverLevel]);

  const [excessOptions, setExcessOptions] = useState([]);
  const [labourRateOptions, setLabourRateOptions] = useState([]);
  const [sundryOptions, setSundryOptions] = useState([]);
  useEffect(() => {

    if (!coverLevel || !duration) {
      setExcessOptions([]);
      setLabourRateOptions([]);
      setSundryOptions([]);
      return;
    }

    let params = {
      cover_level: coverLevel.id,
    };
    api.get('/misc/price-rules/direct-sales', {params})
      .then(response => {

        // load dropdown options
        setExcessOptions(response.data.filter(pr => (pr.type === 'Excess')).sort((a, b) => (a.title - b.title)))
        setLabourRateOptions(response.data.filter(pr => (pr.type === 'Labour Rate')).sort((a, b) => (a.title - b.title)))
        if (duration) {
          setSundryOptions(response.data.filter(pr => (pr.type === 'Sundry' && pr.durations && pr.durations[duration.name])))
        } else {
          setSundryOptions([]);
        }

        // set default options (where applicable)
        if (!excess) {
          dispatch(change('direct-sales/policy/edit', 'excess', findDefaultValue(response.data.filter(pr => (pr.type === 'Excess'))), true))
        }
        if (!labourRate) {
          dispatch(change('direct-sales/policy/edit', 'labour_rate', findDefaultValue(response.data.filter(pr => (pr.type === 'Labour Rate'))), true))
        }

      });
  }, [coverLevel, duration])


  const findDefaultValue = (set) => {
    //Find one marked as default
    if (set.filter(i => i.default).length) {
      return set.filter(i => i.default)[0]
    }
    //Else return first
    if (set.length > 0) {
      return set[0];
    }
    //Return nothing if nothing found
    return null;
  }

  return <>
    <form onSubmit={handleSubmit}>
      <div className="row mt-4 policy-edit-form" >
        <div className="col-12">
          <Field
            type="text"
            label="Company"
            component={renderField}
            name={`company_name`}
            id={'company_name'}
            className="form-control"
          />
        </div>
        <div className="col-12">
          <Field
            label="Cover level"
            component={CustomSelect}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            name={`cover_level`}
            id={'coverLevel'}
            options={coverLevels}
          />
        </div>
        <div className="col-12">
          <Field
            type="text"
            id="policyStartDate"
            label="Start date"
            component={renderDatePicker}
            inputValueFormat="DD-MM-YYYY"
            placeholderText="DD/MM/YYYY"
            dateFormat="dd/MM/yyyy"
            fixedHeight
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            yearDropdownItemNumber={3}
            name={`start_date`}
            className="form-control"
          />
        </div>
        <div className="col-12">
          <Field
            label="Duration"
            component={CustomSelect}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.name}
            name={`duration`}
            id={'duration'}
            options={durationOptions}
          />
        </div>
        <div className="col-12">
          <Field
            label="Claim limit"
            component={CustomSelect}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.name}
            name={`claim_limit`}
            id={'claim_limit'}
            options={claimLimitOptions}
            prependIcon={<i className="wf-icon-gbp" />}
          />
        </div>
        <div className="col-12">
          <Field
            label="Labour Rate"
            component={CustomSelect}
            getOptionValue={option => option.title}
            getOptionLabel={option => option.title}
            name={`labour_rate`}
            id={'labour_rate'}
            options={labourRateOptions}
            prependIcon={<i className="wf-icon-gbp" />}
          />
        </div>
        <div className="col-12">
          <Field
            label="Excess"
            getOptionValue={option => option.title}
            getOptionLabel={option => option.title}
            component={CustomSelect}
            appendIcon={<i className="wf-icon-percent" />}
            className="prepended-select mb-0"
            name={`excess`}
            id={'excess'}
            options={excessOptions}
          />
        </div>
        <div className="col-12">
          <Field
            name="sundries"
            isMulti
            component={CustomSelect}
            label="Sundries"
            options={sundryOptions}
            getOptionValue={(option) => option.title}
            getOptionLabel={(option) => option.title}
          />
        </div>
        <div className="col-12 mb-2">
          <h5>Price: &pound;{policy.net + policy.vat}</h5>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <Field
            label="Sold by"
            component={CustomSelect}
            getOptionValue={option => option.id}
            getOptionLabel={option => `${option.first_name} ${option.last_name}`}
            name={`sold_by`}
            id={'sold_by'}
            options={soldByUsers}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-4">
          <div className="form-group">
            <button
              className="btn btn-secondary"
              type="submit"
            >
              Save changes
            </button>
            <button
              type="button"
              className="btn btn-outline btn-form-cancel"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </>
}

const selector = formValueSelector('direct-sales/policy/edit')

export default connect((state) => ({
  coverLevel: selector(state, 'cover_level'),
  claimLimit: selector(state, 'claim_limit'),
  duration: selector(state, 'duration'),

  excess: selector(state, 'excess'),
  labourRate: selector(state, 'labour_rate'),
  sundries: selector(state, 'sundries'),

  soldBy: selector(state, 'sold_by'),
}))(reduxForm({
  form: 'direct-sales/policy/edit',
  //validate - We'll need this function later.
})(PolicyEditForm))
