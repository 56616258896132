import React, { Component } from 'react'
import PageHeader from '../../../../../ui/PageHeader'
import { Link } from 'react-router-dom'
import CreateForm from '../../forms/create'
import api from '../../../../../../utils/api'
import { NotificationManager } from 'react-notifications'
import { history } from '../../../../../../utils/history'
import { addPriceBandToTable } from '../../../../../../actions/admin/price-bands/price-bands.action'
import { connect } from 'react-redux'
import { formatPrices } from '../../../../../../utils/misc'

class CreatePriceBand extends Component {
  state = {
    coverLevelOptions: [],
  }

  componentDidMount() {
    this.fetchCoverLevels('Direct Sales')
  }

  fetchCoverLevels = (target) => {
    api
      .get('/admin/cover-levels?limit=50&target=' + target)
      .then((res) => {
        this.setState({ coverLevelOptions: res.data.data })
      })
      .catch((err) => {
        if (err && err.response) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing.'
          )
        }
      })
  }

  onSubmit = (values) => {
    api
      .post(`/admin/price-bands`, {
        name: values.name,
        target: 'Direct Sales',
        rank: 1,
        prices: formatPrices(values.prices),
      })
      .then((res) => {
        NotificationManager.success('Woohoo! You added a new price band.')
        history.push('/admin/direct-sales-price-bands')
      })
      .catch((err) => {
        if (err && err.response.status === 422) {
          const errors = err.response.data.errors
          if (errors.hasOwnProperty('prices')) {
            NotificationManager.error(errors.prices[0])
          }
        }
      })
  }

  render() {
    const { coverLevelOptions } = this.state

    return (
      <>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin/direct-sales-price-bands" className="open active">
              Direct sales price bands
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">Add a price band</span>
          </li>
        </ol>

        <div className="container-fluid animated fadeIn">
          <PageHeader
            title="Add a price band"
            description={
              "Please start by giving your price band a name and including at least one cover level. You'll then be able to customise duration and prices."
            }
          />
        </div>

        {coverLevelOptions.length > 0 && (
          <CreateForm
            onSubmit={this.onSubmit}
            coverLevelOptions={coverLevelOptions}
            onCancelRedirectTo={'/admin/direct-sales-price-bands'}
          />
        )}
      </>
    )
  }
}

CreatePriceBand.propTypes = {}

export default connect((state) => ({}), { addPriceBandToTable })(
  CreatePriceBand
)
