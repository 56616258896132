import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PageHeader from '../../ui/PageHeader'
import ProfileForm from './ProfileForm'
import ChangePasswordForm from './ChangePasswordForm'
import {
  hasUpperCase,
  hasNumber,
  hasSpecialCharacters,
  resetForm,
} from '../../../utils/misc'
import { bindActionCreators } from 'redux'
import { authSuccess } from '../../../actions/authentication.action'
import { updateProfile, updateAvatar } from '../../../actions/users.actions'
import { NotificationManager } from 'react-notifications'
import { accessTokenClaims } from '../../../utils/auth'
import api from '../../../utils/api'
import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
} from '../../../constants'
import { SubmissionError } from 'redux-form'

class ProfilePage extends Component {
  state = {
    modalOpen: false,
    errors: null,
  }

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  onCancel = () => resetForm('profile/update')

  handleChangePassword = () => {
    const { values, dispatch } = this.props

    dispatch({
      type: CHANGE_PASSWORD_REQUEST,
    })

    return api
      .patch('/profile/password', values)
      .then(res => {
        NotificationManager.success('Woohoo! Your new password was saved.')

        // update local storage with reissued token
        localStorage.setItem('wf-token', JSON.stringify(res.data.access_token))

        // get token claims to update redux store
        const claims = accessTokenClaims(res.data.access_token)

        // hide modal
        this.toggleModal()

        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
          payload: {
            access_token: res.data.access_token,
            user: {
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              ...claims,
            },
          },
        })
      })
      .catch(err => {
        if (err && err.response.status === 422) {
          dispatch({
            type: CHANGE_PASSWORD_FAILED,
            payload: {
              errors: err.response.data.errors,
            },
          })
          throw new SubmissionError({
            ...err.response.data.errors,
            _error: err.response.data,
          })
        } else {
          NotificationManager.success(
            'Oops! Something went wrong. Please try again after refreshing.',
          )
        }
      })
  }

  render() {
    const passHasUppercase =
      this.props.values &&
      this.props.values.password &&
      hasUpperCase(this.props.values.password)
        ? 'is-valid'
        : ''

    const passHasNumber =
      this.props.values &&
      this.props.values.password &&
      hasNumber(this.props.values.password)
        ? 'is-valid'
        : ''
    const passIsLongEnough =
      this.props.values &&
      this.props.values.password &&
      this.props.values.password.length >= 6
        ? 'is-valid'
        : ''
    const passHasSpecialChar =
      this.props.values &&
      this.props.values.password &&
      hasSpecialCharacters(this.props.values.password)
        ? 'is-valid'
        : ''

    return (
      <Fragment>
        <div
          className="container animated fadeIn"
          style={{ maxWidth: '600px' }}
        >
          <br />
          <PageHeader title="Your Profile" />
          <br />
          {this.props.user && <ProfileForm
            user={this.props.user}
            onSubmit={this.props.updateProfile}
            changePasswordModal={this.changePasswordModal}
            toggleModal={this.toggleModal}
            onUpdateAvatar={this.props.updateAvatar}
            onCancel={this.onCancel}
          />}
          <br />
          {this.state.modalOpen && (
            <div className="react-confirm-alert-overlay">
              <div className="react-confirm-alert">
                <div className="react-confirm-alert-body">
                  <span
                    className="btn-close-modal fa fa-close"
                    onClick={this.toggleModal}
                  />
                  <h3>Change Password</h3>

                  <ChangePasswordForm
                    onSubmit={this.handleChangePassword}
                    toggleModal={this.toggleModal}
                    errors={this.props.errors}
                  />

                  <ul className="validation px-0 mt-4">
                    <li className={passIsLongEnough}>
                      <span>At least 6 characters</span>
                    </li>
                    <li className={passHasNumber}>
                      <span>At least one number</span>
                    </li>
                    <li className={passHasUppercase}>
                      <span>At least one uppercase letter</span>
                    </li>
                    <li className={passHasSpecialChar}>
                      <span>At least one special character</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const {
    authentication: { user, errors },
    form,
  } = state
  return {
    form: 'profile/update',
    errors,
    user,
    values: form['profile/updatePassword']
      ? form['profile/updatePassword'].values
      : null,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      authSuccess,
      updateProfile,
      updateAvatar,
      dispatch,
    },
    dispatch,
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfilePage)
