import {
  FETCH_DEALER_COVER_LEVELS_REQUEST,
  FETCH_DEALER_COVER_LEVELS_SUCCESS,
  FETCH_DEALER_COVER_LEVELS_FAILURE,
  DELETE_COVER_LEVEL_REQUEST,
  DELETE_COVER_LEVEL_SUCCESS,
  DELETE_COVER_LEVEL_FAILURE,
  FETCH_DEALER_COVER_LEVEL_REQUEST,
  FETCH_DEALER_COVER_LEVEL_SUCCESS,
  FETCH_DEALER_COVER_LEVEL_FAILURE, SEARCH_COVER_LEVELS,
} from '../constants'

const initialState = {
  isPending: true,
  data: null,
  error: null,
  singleCoverLevel: null,
  selectedRows: [],
  searching: false,
}

export function dealerCoverLevels(state = {...initialState}, action) {
  switch (action.type) {
    case FETCH_DEALER_COVER_LEVELS_REQUEST:
      return {
        ...state,
        isPending: true,
      }
    case FETCH_DEALER_COVER_LEVELS_SUCCESS:
      return {
        ...state,
        isPending: false,
        data: {...action.payload},
      }
    case FETCH_DEALER_COVER_LEVELS_FAILURE:
      return {
        ...state,
        isPending: false,
        data: null,
        error: {...action.payload},
      }
    case DELETE_COVER_LEVEL_REQUEST:
      return {
        ...state,
        isPending: true,
      }
    case DELETE_COVER_LEVEL_SUCCESS:
      return {
        ...state,
        isPending: false,
        data: {
          ...state.data,
          data: state.data.data ? state.data.data.filter(item => item.id !== action.payload.id) : null,
        },
      }
    case DELETE_COVER_LEVEL_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload.error,
      }
    case FETCH_DEALER_COVER_LEVEL_REQUEST:
      return {
        ...state,
        isPending: true,
      }
    case FETCH_DEALER_COVER_LEVEL_SUCCESS:
      return {
        ...state,
        isPending: false,
        singleCoverLevel: action.payload,
      }
    case FETCH_DEALER_COVER_LEVEL_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload.error,
      }
    case SEARCH_COVER_LEVELS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
