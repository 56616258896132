import React from 'react'
import PropTypes from 'prop-types'
import Avatar from './Avatar'
import moment from "moment";
import { has } from 'lodash';
import { connect } from 'react-redux';

const AdminComment = ({ comment, handleUpdate, handleDelete, user}) => {

  const formatDateTime = (dateTime) => {
    // created_at is stored in the database as a UTC timestamp, so convert to local timezone for display purposes
    // Note: This will also handle adjustments for Daylight Savings Time
    return moment.utc(dateTime).local().format('DD MMM YYYY - h:mma');
  }

  const [message, setMessage] = React.useState(comment.message)

  const [editing, setEditing] = React.useState(false)
  const [editingErrors, setEditingErrors] = React.useState()

  const onSaveClicked = comment => {
    handleUpdate(comment, message)
      .then(res => {
        setEditing(false)
      })
      .catch(err => {
        if (err && err.response.status === 422) {
          setEditingErrors(err.response.data.errors)
        }
      })
  }

  return (
    <div className="row mb-4">
      <div className="col pr-5">
        <header className="d-flex">
          {comment.created_by ? (
              <>
                <Avatar
                  user={comment.created_by}
                  size="small"
                />
                <span className="ml-2">{comment.created_by.first_name} {comment.created_by.last_name} - {formatDateTime(comment.created_at)}</span>
              </>
          ) : (
            <span>System generated - {formatDateTime(comment.created_at)}</span>
          )}
        </header>

        {editing ? <>
          <div className="form-group mt-1">
            <textarea
              onChange={e => setMessage(e.target.value)}
              name="note"
              cols="30"
              rows="5"
              className="form-control"
              value={message}
            />
            {editingErrors &&
            <div className="d-block invalid-feedback">{has(editingErrors, 'comment') && editingErrors.message[0]}</div>}
          </div>

          <button
            className="btn btn-secondary"
            // disabled={comment.comment.length < 2}
            onClick={() => onSaveClicked(comment)}
          >
            Save changes
          </button>
          <button
            className="btn btn-transparent text-secondary"
            onClick={() => setEditing(false)}
          >
            No, cancel
          </button>
        </> :
        <>
          <div className="my-3">
            <p style={{whiteSpace: 'pre-wrap'}}>
              “{comment.message}”
            </p>
          </div>

          {comment.message_type !== 'Email' && (
            <footer className="d-flex justify-content-end">
              {handleUpdate && comment.created_by && user.person_id === comment.created_by.id &&
                <button
                  className="btn-gray mr-2"
                  data-tip="Edit note"
                  data-place="top"
                  onClick={() => setEditing(true)}
                >
                  <i className="wf-icon-edit d-block"/>
                </button>
              }
              {handleDelete && comment.created_by && user.person_id === comment.created_by.id &&
                <button className="btn-gray" data-tip="Delete note" data-place="top" onClick={() => handleDelete(comment)}>
                  <i className="wf-icon-delete d-block" />
                </button>
              }
            </footer>
          )}
        </>
        }

      </div>
    </div>
  )
}

AdminComment.propTypes = {
  comment: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
}

export default connect((state) => ({
  user: state.authentication.user,
}))(AdminComment)
