import React, { Fragment } from 'react'

import PrivateRoute from '../components/PrivateRoute'
// Users
import UsersPage from '../components/pages/Users'
import UserEditPage from '../components/pages/UserEdit/UserEditPage'
import UsersCreatePage from '../components/pages/UserCreate'
// User groups
import UserGroupsPage from '../components/pages/UserGroups/UserGroupsPage'
import UserGroupEdit from '../components/pages/UserGroupEdit'
import UserGroupsCreate from '../components/pages/UserGroupsCreate/UserGroupsCreate'
// Cover levels
import CreateCoverLevelPage from '../components/pages/CoverLevelsCreate'
import CoverLevels from '../components/pages/DealerCoverLevels/DealerCoverLevels'
import CoverLevelEditPage from '../components/pages/CoverLevelEdit'
// Territories
import TerritoriesPage from '../components/pages/Territories'
import TerritoryCreate from '../components/pages/TerritoryCreate'
import TerritoryEditPage from '../components/pages/TerritoryEdit'
// Direct Sales cover levels
import DirectSalesPage from '../components/pages/DirectSalesCoverLevels'
import DirectSalesEditPage from '../components/pages/DirectSalesCoverLevelEdit'
import DirectSalesCreatePage from '../components/pages/DirectSalesCoverLevelCreate/DirectSalesCreatePage'
// Public cover levels
import PublicCoverLevels from '../components/pages/PublicCoverLevels'
import CreatePublicCoverLevelPage from '../components/pages/CreatePublicCoverLevelPage'
import PublicCoverLevelEditPage from '../components/pages/PublicCoverLevelEditPage'
import Dashboard from '../components/pages/Dashboard'
// Price bands - Dealer
import PriceBands from '../components/pages/Admin/PriceBands/Dealer/Index'
import CreatePriceBand from '../components/pages/Admin/PriceBands/Dealer/Create'
import DealerEditPriceBand from '../components/pages/Admin/PriceBands/Dealer/Edit'
// Price bands - Direct sales
import DirectSalesPriceBands from '../components/pages/Admin/PriceBands/DirectSales/Index'
import DirectSalesCreatePriceBand from '../components/pages/Admin/PriceBands/DirectSales/Create'
import DirectSalesEditPriceBand from '../components/pages/Admin/PriceBands/DirectSales/Edit'
// Price bands - Public
import PublicPriceBands from '../components/pages/Admin/PriceBands/Public/Index'
import PublicCreatePriceBands from '../components/pages/Admin/PriceBands/Public/Create'
import PublicEditPriceBand from '../components/pages/Admin/PriceBands/Public/Edit'
// Pricing rules
import PricingRules from "../components/pages/Admin/PricingRules";
// Special Vehicles - Generic
import SpecialVehicles from '../components/pages/Admin/SpecialVehicles'
import SpecialVehicleEdit from "../components/pages/Admin/SpecialVehicles/Edit";
//Special Vehicles - Dealer Create
import DealerSpecialVehiclesCreate from '../components/pages/Admin/SpecialVehicles/Dealer/Create/DealerSpecialVehiclesCreate'
// Special Vehicles - Public Create
import PublicSpecialVehiclesCreate from '../components/pages/Admin/SpecialVehicles/Public/Create'
// Special Vehicles - Direct sales create
import DirectSalesSpecialVehiclesCreate from '../components/pages/Admin/SpecialVehicles/DirectSales/Create/DirectSalesSpecialVehiclesCreate'
// Targets/Budgets
import TeamTargets from '../components/pages/Admin/Targets/Team'
import IndividualTargets from '../components/pages/Admin/Targets/Individual/IndividualTargets'
import IndividualCommission from '../components/pages/Admin/Commission/Individual'

function AdminRoutes() {
  return (
    <Fragment>
      <PrivateRoute path={`/dashboard`} exact component={Dashboard} />
      <PrivateRoute path={`/admin/users`} exact component={UsersPage} />
      <PrivateRoute
        exact
        path={`/admin/users/create`}
        component={UsersCreatePage}
      />
      <PrivateRoute
        exact
        path={`/admin/users/:id/edit`}
        component={UserEditPage}
      />

      {/**
       * User groups
       */}
      <PrivateRoute
        exact
        path={`/admin/user-groups`}
        component={UserGroupsPage}
      />
      <PrivateRoute
        exact
        path={`/admin/user-groups/:id/edit`}
        component={UserGroupEdit}
      />
      <PrivateRoute
        exact
        path={`/admin/user-groups/create`}
        component={UserGroupsCreate}
      />

      {/**
       * Dealer cover levels
       */}
      <PrivateRoute
        exact
        path={`/admin/dealer-cover-levels`}
        component={CoverLevels}
      />
      <PrivateRoute
        exact
        path={`/admin/dealer-cover-levels/:id/edit`}
        component={CoverLevelEditPage}
      />
      <PrivateRoute
        exact
        path={`/admin/dealer-cover-levels/create`}
        component={CreateCoverLevelPage}
      />

      {/**
       * Direct sales cover levels
       */}
      <PrivateRoute
        exact
        path={`/admin/direct-sales-cover-levels`}
        component={DirectSalesPage}
      />
      <PrivateRoute
        exact
        path={`/admin/direct-sales-cover-levels/create`}
        component={DirectSalesCreatePage}
      />
      <PrivateRoute
        exact
        path={`/admin/direct-sales-cover-levels/:id/edit`}
        component={DirectSalesEditPage}
      />

      {/**
       * Public cover level
       */}
      <PrivateRoute
        exact
        path={`/admin/public-cover-levels`}
        component={PublicCoverLevels}
      />
      <PrivateRoute
        exact
        path={`/admin/public-cover-levels/create`}
        component={CreatePublicCoverLevelPage}
      />
      <PrivateRoute
        exact
        path={`/admin/public-cover-levels/:id/edit`}
        component={PublicCoverLevelEditPage}
      />
      {/**
       *  Targets/Budgets
       */}
      <PrivateRoute
        exact
        path={`/admin/budgets/team`}
        component={TeamTargets}
      />

      <PrivateRoute
        exact
        path={`/admin/budgets/external-sales`}
        component={() => (
          <IndividualTargets
            type={`external-sales`}
            header={`External Sales`}
          />
        )}
      />

      <PrivateRoute
        exact
        path={`/admin/budgets/direct-sales`}
        component={() => (
          <IndividualTargets type={`direct-sales`} header={`Direct Sales`} />
        )}
      />

      {/**
       *  Commission
       **/}
      <PrivateRoute
        exact
        path={`/admin/commission/external-sales`}
        component={() => (
          <IndividualCommission
            type={`external-sales`}
            header={`External Sales`}
          />
        )}
      />
      <PrivateRoute
        exact
        path={`/admin/commission/direct-sales`}
        component={() => (
          <IndividualCommission type={`direct-sales`} header={`Direct Sales`} />
        )}
      />
      {/**
       *  Dealer Price bands
       */}
      <PrivateRoute
        exact
        path={`/admin/dealer-price-bands`}
        component={PriceBands}
      />

      <PrivateRoute
        exact
        path={`/admin/dealer-price-bands/create`}
        component={CreatePriceBand}
      />

      <PrivateRoute
        exact
        path={`/admin/dealer-price-bands/:id/edit`}
        component={DealerEditPriceBand}
      />

      {/**
       *  Direct sales Price bands
       */}
      <PrivateRoute
        exact
        path={`/admin/direct-sales-price-bands`}
        component={DirectSalesPriceBands}
      />

      <PrivateRoute
        exact
        path={`/admin/direct-sales-price-bands/create`}
        component={DirectSalesCreatePriceBand}
      />

      <PrivateRoute
        exact
        path={`/admin/direct-sales-price-bands/:id/edit`}
        component={DirectSalesEditPriceBand}
      />

      {/*
       * Public price bands
       */}
      <PrivateRoute
        exact
        path={`/admin/public-price-bands`}
        component={PublicPriceBands}
      />

      <PrivateRoute
        exact
        path={`/admin/public-price-bands/create`}
        component={PublicCreatePriceBands}
      />

      <PrivateRoute
        exact
        path={`/admin/public-price-bands/:id/edit`}
        component={PublicEditPriceBand}
      />

      {/*
       * Price band - Pricing rules
       */}
      <PrivateRoute
        exact
        path={`/admin/pricing-rules/public`}
        component={() => (<PricingRules target={'Public'} url={'public'} hasSundries={false} />)}
      />

      <PrivateRoute
        exact
        path={`/admin/pricing-rules/direct-sales`}
        component={() => (<PricingRules target={'Direct Sales'} url={'direct-sales'} hasSundries={true} />)}
      />

      <PrivateRoute
        exact
        path={`/admin/pricing-rules/dealer`}
        component={() => (<PricingRules target={'Dealer'} url={'dealer'} hasSundries={true} />)}
      />

      {/*
       * Special Vehicles
       */}
      <PrivateRoute
        exact
        path={`/admin/special-vehicles/dealer`}
        component={() => (
          <SpecialVehicles target={`dealer`} header={`Dealer`} url={`dealer`} />
        )}
      />

      <PrivateRoute
        exact
        path={`/admin/special-vehicles/dealer/create`}
        component={DealerSpecialVehiclesCreate}
      />

      <PrivateRoute
        exact
        path={`/admin/special-vehicles/public`}
        component={() => (
          <SpecialVehicles target={`public`} header={`Public`} url={`public`} />
        )}
      />

      <PrivateRoute
        exact
        path={`/admin/special-vehicles/public/create`}
        component={PublicSpecialVehiclesCreate}
      />

      <PrivateRoute
        exact
        path={`/admin/special-vehicles/direct-sales`}
        component={() => (
          <SpecialVehicles
            target={`direct sales`}
            header={`Direct Sales`}
            url={`direct-sales`}
          />
        )}
      />

      <PrivateRoute
        exact
        path={`/admin/special-vehicles/direct-sales/create`}
        component={DirectSalesSpecialVehiclesCreate}
      />

      <PrivateRoute
        exact
        path={`/admin/special-vehicles/:id/edit`}
        component={SpecialVehicleEdit}
      />

      {/**
       * Territories
       */}
      <PrivateRoute
        exact
        path={`/admin/territories`}
        component={TerritoriesPage}
      />
      <PrivateRoute
        exact
        path={`/admin/territories/create`}
        component={TerritoryCreate}
      />
      <PrivateRoute
        exact
        path={`/admin/territories/:id/edit`}
        component={TerritoryEditPage}
      />
    </Fragment>
  )
}

export default AdminRoutes
