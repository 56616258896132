import React, { Fragment } from 'react'
import PageHeader from '../../../../ui/PageHeader'
import { Table } from '../../../../ui/Table'
import { Link } from 'react-router-dom'
import { sortCaret } from '../../../../../utils/misc'
import { uniqueId, get } from 'lodash'
import api from "../../../../../utils/api";
import {NotificationManager} from "react-notifications";
import moment from "moment";
import { hasPermission } from '../../../../../utils/auth';

class Policies extends React.Component {
  state = {
    policies: null,
    policyHighlights: [],
    filterGroups: [
      {
        name        : 'status',
        allowOnlyOne: true,
        filters     : [
          {id: uniqueId(), name:'Draft', value: 'Draft'},
          {id: uniqueId(), name:'Awaiting Approval', value: 'Awaiting Approval'},
          {id: uniqueId(), name:'Rejected', value: 'Rejected'},
          {id: uniqueId(), name:'Approved', value: 'Approved'},
          {id: uniqueId(), name:'Cancelled', value: 'Cancelled'},
          {id: uniqueId(), name:'Transferred', value: 'Transferred'},
          {id: uniqueId(), name:'Expired', value: 'Expired'},
        ],
      },
    ],
    query: '',
    page: 1,
    limit: 10,
    order: '',
    desc: false,
    searching: false,
    selected: [],
  }

  fetchData = url => {
    api.get(`/external-sales/policies${url ? url : ''}`)
        .then(res => {
          const policies = res.data.data.map(policy => {
            policy.highlight = {
              success: get(this.state, 'policyHighlights.ok', []).includes(policy.id),
              error: get(this.state, 'policyHighlights.error', []).includes(policy.id),
            }
            return policy;
          })
          this.setState({policies: {...res.data, policies}, searching: false })
        })
        .catch(err => {
          if (err) {
            console.log(err)
            NotificationManager.error('Oops! Something went wrong. Please try again after refreshing.')
          }
        })
  }

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    let url = `?page=${page}&limit=${sizePerPage}&query=${this.state.query}`
    if (sortField) {
      url += `&order=${sortField}&desc=${sortOrder === 'desc'}`
    }
    if (this.state.status) {
      url += `&status=${this.state.status}`
    }
    this.fetchData(url)
  }

  handleSearch = (query, filters) => {
    let status = ''

    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]
      if (filter.group_name === 'status') {
        status = filter.name
      }
    }

    this.setState(
      {
        query,
        status,
        page: 1
      },
      this.buildUrl,
    )
  }

  /**
   * Builds URL and to fetch policies
   */
  buildUrl = () => {
    const { query, page, desc, limit, status } = this.state

    let url = `?query=${query}&page=${page}&desc=${desc}&limit=${limit}`

    if (status !== '') url = `${url}&status=${status}`

    this.setState({ searching: true })
    this.fetchData(url)
  }

  componentDidMount() {
    this.fetchData()
  }

  handleSelect = (row, isSelect, rowIndex, e) => {
    if (isSelect) {
      this.setState({selected: [...this.state.selected, row]})
    } else {
      //Remove the selected one from the array.
      this.setState({selected: [...this.state.selected].filter(policy => (policy.id !== row.id))})
    }
  }

  handleSelectAll = (isSelect, rows, e) => {
    if (isSelect) {
      this.setState({selected: [...this.state.selected, ...rows]})
    } else {
      //Remove the ones on the current page.
      const removedRows = rows.map(p => p.id)
      this.setState({selected: this.state.selected.filter(policy => !removedRows.includes(policy.id))});
    }
  }

  approvePolicies = () => {
    console.log(this.state.selected);
    api.patch(`/external-sales/policies/approve`, {policies: this.state.selected.map(p => p.id)})
      .then(response => {
        NotificationManager.info(`Policies Approved: ${response.data.ok.length}. Policies with Issues: ${response.data.error.length}`);
        this.setState({policyHighlights: {ok: response.data.ok.map(p => p.id), error: response.data.error.map(p => p.id)}})
        this.buildUrl();
      })
      .catch(() => {
        NotificationManager.error('Oops! Something went wrong while approving these policies.');
      })
  }

  render() {

    let cols = [
      {
        dataField  : 'ref',
        text       : 'Policy ID',
        headerStyle: {
          width: 150,
        },
        formatter  : (cell, row) => <Link to={`/external-sales/policies/${row.id}/policy`}>{row.ref}</Link>,
        sort       : true,
        sortCaret,
      },
      {
        dataField: 'purchased_from',
        text     : 'Purchased from',
        formatter: (cell, row) => {
          if (row.dealer) {
            if (hasPermission('external_sales.dealers.*')) {
              return <Link to={`/external-sales/dealers/${row.dealer.id}/timeline`}>{row.dealer.name}</Link>
            }
            return row.dealer.name
          }
          return row.purchased_from
        }
      },
      {
        dataField: 'cover_level.name',
        text     : 'Cover level',
      },
      {
        dataField: 'policyholder_name',
        text     : 'Policyholder name',
        formatter: (cell, row) => {
          if (hasPermission('external_sales.policyholders.*')) {
            return <Link to={`/external-sales/policyholders/${row.policyholder.id}/timeline`}>{row.policyholder.title} {row.policyholder.first_name} {row.policyholder.last_name}</Link>
          }
          return `${row.policyholder.title} ${row.policyholder.first_name} ${row.policyholder.last_name}`;
        }
      },
      {
        dataField: 'vehicle.vrm',
        text     : 'Vehicle reg',
      },
      {
        dataField: 'start_date',
        text     : 'Start date',
        sort     : true,
        sortCaret,
        formatter: (cell, row) => {
          if (row.status === 'Awaiting Approval' && moment(row.start_date) < moment().subtract(7, 'day')) {
            return <div className="font-weight-bold text-red">{moment(row.start_date).format('DD/MM/YYYY')}</div>
          }
          return moment(row.start_date).format('DD/MM/YYYY')
        }
      },
      {
        dataField: 'duration',
        text     : 'Duration',
      },
      {
        dataField: 'status',
        text     : 'Status',
        sort     : true,
        sortCaret,
      },
    ]

    if (hasPermission('external_sales.policies.update')) {
      cols.push({
        dataField  : '',
        text       : 'Actions',
        headerStyle: {
          width: 80,
        },
        formatter  : (cell, row) => (
            <Link
                data-tip="Edit policy"
                data-place="top"
                to={`/external-sales/policies/${row.id}/policy`}
                className="table-action-btn table-action-btn--edit"
                size="sm"
            >
              <i className="wf-icon-edit"/>
            </Link>
        ),
      });
    }

    let selectOptions = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: this.handleSelect,
      onSelectAll: this.handleSelectAll,
    }
    if (!hasPermission('external_sales.policies.update')) {
      selectOptions.hideSelectColumn = true
    }

    const options = {
      showOptions: false,
      columns    : cols,
    }

    const tableActions = [
      {
        iconClass: 'wf-icon-prioritise wf-icon-prioritise--table-action font-lg',
        callback: () => {this.approvePolicies()},
        tooltip: {
          text: 'Approve Policies',
          place: 'top',
        },
      },
    ];

    return (
        <Fragment>
          <br/>
          <div className="container-fluid animated fadeIn">
            {hasPermission('external_sales.policies.create') ?
            <PageHeader
                title="Policies"
                description={
                  'A list of all Warranty First dealer policies. You can view all contact history between Warranty First and a customer by viewing their timeline.'
                }
                buttonLink={'/external-sales/policies/create'}
                buttonText="Add a policy"
                buttonIcon="d-block wf-icon-add"
            />
            :
            <PageHeader
              title="Policies"
              description={
                'A list of all Warranty First dealer policies. You can view all contact history between Warranty First and a customer by viewing their timeline.'
              }
            />
            }
            <div className="es-policy-table">
            {this.state.policies && (
                <Table
                    selectRow={selectOptions}
                    resource={this.state.policies}
                    selected={this.state.selected}
                    searching={this.state.searching}
                    showOptions={options.showOptions}
                    omnibox={{ groups: this.state.filterGroups }}
                    handleTableChange={this.handleTableChange}
                    columns={options.columns}
                    hasTableSearch={true}
                    onSearch={this.handleSearch}
                    searchPlaceholder={'Search for a policy...'}
                    tableActions={hasPermission('external_sales.policies.update_status') ? tableActions : null}
                    minSelection={0}
                    rowClasses={(row, idx) => {
                      if (get(row, 'highlight.success', false)) return 'policy-approval-success';
                      if (get(row, 'highlight.error', false)) return 'policy-approval-error';
                      return '';
                    }}
                />
            )}
            </div>
          </div>
        </Fragment>
    )
  }
}

export default Policies
