import React from 'react'
import DirectDebitImage from '../../../images/direct-debit.png'
import { echo, formatDate } from '../../../utils/misc';

export const Mandate = ({wfSettings, mandate}) => {

  /**
    * Inserts a hypens in a sort code
    * i.e. converts '123456' to '12-34-56'
    *
    * @param {string} sortCode
    * @returns string
    */
  const echoSortCode = (sortCode) => {
    if (sortCode) {
      if (sortCode.length === 6) {
        return sortCode.substring(0, 2) + '-' + sortCode.substring(2, 4) + '-' + sortCode.substring(4);
      }
      return sortCode;
    }
    return '';
  }

  const wfAddress = () => {
    const a = wfSettings.invoice
    return [a.address1, a.address2, a.city, a.county, a.postcode].filter(Boolean).join(', ')
  }

  return wfSettings && mandate && (
    <div className="direct-debit-mandate mt-5">
      <div className="row">
        <div className="col-12 text-right">
          <img src={DirectDebitImage} className="direct-debit-logo" alt="Direct Debit Logo" />
          <div className='direct-debit-logo'></div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 mt-3">
          <h5>Warranty First</h5>
          <p className="font-md">{wfAddress()}</p>
        </div>
        <div className="col-6 mt-3">
          <h5>Instruction to your bank or building society to pay by Direct Debit</h5>
        </div>
        <div className="col-6 mt-3">
          <span className="label">Name of account holder</span>
          <p className="font-md">{echo(mandate.account_name)}</p>
        </div>
        <div className="col-6 mt-3">
          <span className="label">Service User Number</span>
          <h2 className='sun'>{echo(wfSettings.direct_debit.sun.number)}</h2>
        </div>
        <div className="col-6 mt-3">
          <span className="label">Bank/Building Society account number</span>
          <p className="font-md">{echo(mandate.account_number)}</p>
        </div>
        <div className="col-6 mt-3">
          <span className="label">Reference</span>
          <p className="font-md">{echo(mandate.ref)}</p>
        </div>
        <div className="col-6 mt-3">
          <span className="label">Branch sort code</span>
          <p className="font-md">{echoSortCode(mandate.sort_code)}</p>
        </div>
        <div className="col-6 mt-3">
          <span className="label">Instruction to your Bank or Building Society</span>
          <p className='mb-0 instruction'>Please pay Warranty First Direct Debits from the account detailed in this Instruction subject to the safeguards assured by the
          Direct Debit Guarantee. I understand that this instruction may remain with Warranty First and, if so, details will be passed
          electronically to my bank/building society.</p>
        </div>
        <div className="col-6 mt-3">
          <span className="label">Name and full postal address of your<br/> Bank/Building Society</span>
          <p className="font-md bank-address">{echo(mandate.bank_name)}<br/>{echo(mandate.bank_address)}</p>
        </div>
        <div className="col-6 mt-3 pt-4">
          <span className="label">Date</span>
          <p className="font-md">{formatDate(mandate.agreed_at)}</p>
        </div>
      </div>
      <p className="font-md mt-5">
        Your first payment via direct debit will be made no earlier than {echo(mandate.advance_notice_days)} working days after you submit this application.
        Subsequent payments will be taken {echo(mandate.advance_notice_days)} working days after an invoice has been raised against your account.
      </p>
      <p className="font-md">
        Invoices will continue be sent to you for information purposes only.
      </p>

      <hr className="mt-5 mb-5"/>

      <div className="row">
        <div className="col-6">
          <span className="label">Agreed by</span>
          <p className="font-md">{echo(mandate.agreed_by.title)} {echo(mandate.agreed_by.first_name)} {echo(mandate.agreed_by.last_name)}</p>
        </div>
        <div className="col-6">
          <span className="label">Date</span>
          <p className="font-md">{formatDate(mandate.agreed_at)}</p>
        </div>
        <div className="col-6">
          <span className="label">Status</span>
          <p className="font-md mb-0">{echo(mandate.status)} ({formatDate(mandate.status_at)})</p>
        </div>
      </div>

      <hr className="mt-5 mb-5"/>

      <h4>The Direct Debit Guarantee</h4>
      <ul className='direct-debit-guarantee'>
        <li>This Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits.</li>
        <li>If there are any changes to the amount, date or frequency of your Direct Debit Warranty First will notify you {echo(mandate.advance_notice_days)} working days in advance of your account being debited or as otherwise agreed.
            If you request Warranty First to collect a payment, confirmation of the amount and date will be given to you at the time of the request.</li>
        <li>If an error is made in the payment of your Direct Debit, by Warranty First or your bank or building society, you are entitled to a full and immediate refund of the amount paid from your bank or building society
            – if you receive a refund you are not entitled to, you must pay it back when Warranty First asks you to.</li>
        <li>You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation may be required. Please also notify us.</li>
      </ul>
    </div>
  )
}

export default Mandate
