import React, { Component, Fragment } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import PageHeader from '../../../ui/PageHeader'
import { sortCaret } from '../../../../utils/misc'
import Table from '../../../ui/Table'
import { Link } from 'react-router-dom'
import Avatar from '../../../ui/Avatar'
import ReactTooltip from 'react-tooltip'
import api from '../../../../utils/api'
import { NotificationManager } from 'react-notifications'
import { uniqueId} from 'lodash'
import { confirmAlert } from "react-confirm-alert"

class DealersPage extends Component {
  state = {
    dealers: null,
    filterGroups: [
      {
        name: 'status',
        allowOnlyOne: true,
        filters: [
          { id: 5, name: 'Active', value: 'Active' },
          { id: 4, name: 'In review', value: 'In Review' },
          { id: 6, name: 'Rejected', value: 'Rejected' },
        ],
      },
      {
        name: 'Deleted',
        allowOnlyOne: true,
        singleValueFilter: true,
        filters: [
          {
            id: 1,
            name: 'Deleted',
            value: true,
          },
        ],
      },
    ],
    query: '',
    page: 1,
    limit: 10,
    order: '',
    desc: false,
    searching: false,
    deleted: false,
  }

  fetchData = url => {
    api.get(`/external-sales/dealers${url ? url : ''}`)
      .then(res => {
        this.setState({ dealers: res.data, searching: false })
      })
      .catch(err => {
        if (err) {
          NotificationManager.error('Oops! Something went wrong. Please try again after refreshing.')
        }
      })
  }

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    let url = `?page=${page}&limit=${sizePerPage}&query=${this.state.query}`
    if (sortField) {
      url += `&order=${sortField}&desc=${sortOrder === 'desc'}`
    }
    if (this.state.status) {
      url += `&status=${this.state.status}`
    }
    if (this.state.deleted) {
      url += `&deleted=true`
    }
    this.fetchData(url)
  }

  handleSearch = (query, filters) => {
    let status = ''
    let deleted = false

    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]

      if (filter.group_name === 'status') {
        status = filter.name
      } else if (filter.group_name === 'Deleted') {
        deleted = true
      }
    }
    this.setState(
      {
        query,
        status,
        deleted
      },
      this.buildUrl,
    )
  }

  /**
   * Builds URL and to fetch policyholders
   */
  buildUrl = () => {
    const { query, page, desc, limit, status, deleted} = this.state

    let url = `?query=${query}&page=${page}&desc=${desc}&limit=${limit}`

    if (status !== '') url = `${url}&status=${status}`
    if (deleted) url = `${url}&deleted=1`

    this.setState({ searching: true })
    this.fetchData(url)
  }

  componentDidMount() {
    this.fetchData()
  }

  handleDelete = row => {
    confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>
                Delete the dealer <strong>{row.name}</strong>? You can undo this later.
              </p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  this.deleteDealer(row)
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  handleRestore = (row) => {
    confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <span className="btn-close-modal fa fa-close" onClick={onClose} />
                <h3>{title}</h3>
                <p>
                  Restore the dealer <strong>{row.name}</strong>?
                </p>

                <button
                    className="btn btn-secondary"
                    onClick={() => {
                      onClose()
                      this.restoreDealer(row)
                    }}
                >
                  Yes, I'm sure
                </button>
                <button
                    className="btn btn-transparent text-secondary"
                    onClick={onClose}
                >
                  No, cancel
                </button>
              </div>
            </div>
        )
      },
    })
  }

  restoreDealer = (row) => {
    api.patch(`/external-sales/dealers/${row.id}/restore`)
        .then(() => {
          NotificationManager.success('Woohoo! The dealer was restored.')
          this.buildUrl()
        })
        .catch(() => {
          NotificationManager.error('Oops! Something went wrong restoring the dealer. Please try again after refreshing.')
        })
  }

  deleteDealer = row => {
    api.delete(`/external-sales/dealers/${row.id}/`)
    .then(() => {
      NotificationManager.success('Woohoo! You deleted a dealer.')
      this.fetchData()
    })
    .catch(err => {
      if (err) {
        NotificationManager.error('Oops! Something went wrong. Please try again after refreshing.')
      }
    })
  }


  render() {
    const { dealers, searching, filterGroups } = this.state

    const options = {
      showOptions: false,
      columns    : [
        {
          dataField: 'id',
          text: 'Dealership ID',
          headerStyle: {
            width: '135px'
          },
          formatter: (cell, row) => row.id.toString().padStart(6, '0'),
          sort: true,
          sortCaret
        },
        {
          dataField: 'name',
          text     : 'Dealership name',
          formatter: (cell, row) => {
            return <Link key={'view_'} to={`/external-sales/dealers/${row.id}/timeline`}>{row.name}</Link>
          },
          sort     : true,
          sortCaret,
        },
        {
          dataField: 'id',
          text     : 'Assigned user(s)',
          formatter: (cell, row) => row.contacts.map((c, index) => [
            index > 0 && ', ', <span key={uniqueId('link_')}>{c.first_name} {c.last_name}</span>,
          ]),
        },
        {
          dataField  : 'postcode',
          text       : 'Postcode',
          sort       : true,
          sortCaret,
          headerStyle: {
            width: '135px',
          },
        },

        {
          dataField: uniqueId('territory_'),
          text     : 'Territory',
          formatter: (cell, row) => row.territory && row.territory.name,
        },

        {
          dataField  : uniqueId('am_'),
          text       : 'AM',
          headerStyle: {
            width: '50px',
          },
          formatter  : (cell, row) => {
            if (!row.territory)
              return

            if (row.territory.current_account_manager) {
              let user = {
                first_name: row.territory.current_account_manager.split(' ')[0],
                last_name: row.territory.current_account_manager.split(' ')[1],
              };
              return <Avatar key={uniqueId('avatar_')} user={user}/>
            }
          },
        },

        {
          dataField: 'status',
          text     : 'Status',
          sort     : true,
          sortCaret,
        },

        {
          dataField  : uniqueId('actions_'),
          text       : 'Actions',
          headerStyle: {
            width: 100,
          },
          formatter  : (cell, row) => (
            <div className="d-flex align-items-center">
              {this.state.deleted ? (
                  <span
                      data-tip="Restore"
                      data-place="top"
                      className="table-action-btn"
                      onClick={() => {this.handleRestore(row)}}
                  >
                  <span
                      className="wf-icon-restore d-inline-block"
                      style={{
                        cursor: 'pointer',
                      }}
                  />
                </span>
              ) : (
                  <>
              <Link
                data-tip="Edit dealer"
                data-place="top"
                to={`/external-sales/dealers/${row.id}/edit`}
                className="table-action-btn"
                primary="true"
                size="sm"
              >
                <span className="wf-icon-edit"/>
              </Link>
              <Link
                data-tip="Delete&nbsp;dealer"
                data-place="top"
                to={`#`}
                onClick={() => this.handleDelete(row)}
                className="table-action-btn table-action-btn--delete"
                size="sm"
              >
                <span className="d-block wf-icon-delete"/>
              </Link>
              </>
                )}
              <ReactTooltip effect="solid" className="tooltip-nobreak" />
            </div>
          ),
        },
      ],
    }

    return (
      <Fragment>
        <br/>
        <div className="container-fluid animated fadeIn">
          <PageHeader
            title="Dealers"
            description="View all your dealerships below. You can view all contact history between Warranty First and a dealership by clicking on the dealer name and viewing their timeline."
            buttonLink={'/external-sales/dealers/create'}
            buttonText="Add a dealer"
            buttonIcon="d-block wf-icon-add"
          />
          <br/>
          {dealers && (
            <Table
              resource={dealers}
              showOptions={options.showOptions}
              handleTableChange={this.handleTableChange}
              columns={options.columns}
              hasTableSearch={true}
              omnibox={{ groups: filterGroups }}
              searching={searching}
              onSearch={this.handleSearch}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

export default DealersPage
