import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field, reduxForm } from 'redux-form'
import renderField from '../../../../utils/renderField'

const ResetPasswordForm = props => {

  const {handleSubmit, onSubmit, submitError} = props

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="card w-100 p-4 shadow-light border-0">
        <div className="card-body">
          <h1 className="h2">Reset password</h1>
          <h6 className="text-uppercase mb-4 font-weight-bold">Create a new, secure password</h6>
          <Field
            label="Password"
            type="password"
            name="password"
            className="form-control"
            placeholder="Your password"
            component={renderField}
          />
          <ul className="list-info mb-4" style={{ maxWidth: '570px' }}>
            <li>At least 8 characters</li>
            <li>At least one symbol (e.g., !@#%)</li>
            <li>At least one uppercase letter</li>
            <li>At least one lowercase letter</li>
            <li>At least one number</li>
          </ul>
          {submitError && <div className="alert alert-danger">{submitError}</div>}
          <button type="submit" disabled={!props.valid} className="btn mt-4 px-4 btn-secondary">
            Reset password and login
          </button>
        </div>
      </div>
    </Form>
  )
}

const validate = values => {
  let errors = {}

  if (!values.password)
    errors.password = 'Please enter a password.'
  else if (values.password.length < 8)
    errors.password = 'Password must be at least 8 characters long.'
  else if (!RegExp('(?=.*?[#?!@$%^&*-])', 'g').test(values.password))
    errors.password = 'Password must contain at least one symbol.'
  else if (!RegExp('(?=.*?[A-Z])', 'g').test(values.password))
    errors.password = 'Password must contain one uppercase letter.'
  else if (!RegExp('(?=.*?[a-z])', 'g').test(values.password))
    errors.password = 'Password must contain one lowercase letter.'
  else if (!RegExp('(?=.*?[0-9])', 'g').test(values.password))
    errors.password = 'Password must contain at least one number.'

  return errors
}

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: 'auth/reset-password',
  validate,
})(ResetPasswordForm)