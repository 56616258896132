import React from 'react'
import PrivateRoute from '../components/PrivateRoute'
import { Switch } from 'react-router-dom'
import ClaimsDashboard from '../components/pages/Dashboard/Claims'
import DirectSalesDashboard from '../components/pages/Dashboard/DirectSales'
import ExternalSalesDashboard from "../components/pages/Dashboard/ExternalSales/Dashboard";

function ClaimsRoutes() {
  return (
    <Switch>
      <PrivateRoute
        exact
        path={`/dashboard/claims`}
        component={ClaimsDashboard}
      />
      <PrivateRoute
        exact
        path={`/dashboard/direct-sales`}
        component={DirectSalesDashboard}
      />
      <PrivateRoute
        exact
        path={`/dashboard/external-sales`}
        component={ExternalSalesDashboard}
      />
    </Switch>
  )
}

export default ClaimsRoutes
