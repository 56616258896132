import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, Form } from 'redux-form'
import renderField from '../../../utils/renderField'
import validate from './formValidation'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { hasPermission } from '../../../utils/auth';
import { echo } from '../../../utils/misc';

let ProfileForm = props => {
  const { user, error, handleSubmit } = props
  const [
    avatarForm,
    // setAvatarForm
  ] = React.useState({
    imagePreview: '',
    errors: null,
    saving: false,
  })

  // const handleFileChange = e => {
  //   let file = e.currentTarget.files.length === 1 && e.currentTarget.files[0]

  //   // make sure file is < than 2 MB
  //   if (file.size > 2097152) {
  //     return setAvatarForm({
  //       ...avatarForm,
  //       errors: {
  //         avatar: ['File is bigger than 2MB.'],
  //       },
  //     })
  //   }

  //   let img = window.URL.createObjectURL(file)
  //   setAvatarForm({ ...avatarForm, imagePreview: img, saving: true })

  //   const formData = new FormData()
  //   formData.append('avatar', file, file.name)

  //   onUpdateAvatar(formData)
  //     .then(() => {
  //       // need to reset file input otherwise user can't upload same file twice.
  //       setAvatarForm({ ...avatarForm, saving: false })
  //       formData.delete('avatar')
  //       if (e.currentTarget.files[0]) e.currentTarget.value = ''
  //     })
  //     .catch(err => {
  //       console.log('ereerer', JSON.stringify(err))
  //       if (err && err.response.status === 422) {
  //         setAvatarForm({ ...avatarForm, errors: err.response.data.errors })
  //       } else {
  //         setAvatarForm({ ...avatarForm, saving: false })
  //         NotificationManager.error(
  //           'Something went wrong. Please try again after refreshing.',
  //         )
  //       }
  //     })
  // }

  // const handleRemoveAvatar = e => {
  //   e.preventDefault()

  //   onUpdateAvatar({ avatar: null })
  //   setAvatarForm({ ...avatarForm, errors: null, saving: false })
  // }

  const hasAvatar = {
    backgroundImage: `url(${user.avatar})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPosition: 'center',
  }
  return (
    <Form onSubmit={handleSubmit}>
      {error && <div className="alert alert-danger">{error}</div>}

      <div className="row pb-4">
        <div className="col-xs-12 mr-3">
          <div className={`avatar-container`} style={user.avatar && hasAvatar}>
            {avatarForm.saving ? (
              <i className="fa fa-spinner fa-lg fa-spin" />
            ) : (
              <Fragment>
                {!avatarForm.imagePreview && user && !user.avatar && (
                  <div className="no-avi">
                    {user.first_name.charAt(0) + user.last_name.charAt(0)}
                  </div>
                )}

                {avatarForm.imagePreview && !user.avatar && (
                  <div className="no-avi">
                    {user.first_name.charAt(0) + user.last_name.charAt(0)}
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
        {/*<div className="col-xs-12 col-sm">*/}
        {/*  <Field*/}
        {/*    type="file"*/}
        {/*    label="Upload new avatar"*/}
        {/*    component={renderField}*/}
        {/*    onChange={handleFileChange}*/}
        {/*    className="file-input"*/}
        {/*    name={`avatar`}*/}
        {/*    id="avatar"*/}
        {/*    fieldInfo="The maximum file size allowed is 2 MB"*/}
        {/*  />*/}
        {/*  {avatarForm.errors && (*/}
        {/*    <div className="invalid-feedback d-block">*/}
        {/*      {avatarForm.errors.avatar && avatarForm.errors.avatar[0]}*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*  <button*/}
        {/*    className="btn p-0 font-weight-normal font-sm"*/}
        {/*    style={{*/}
        {/*      color: '#1673FF',*/}
        {/*    }}*/}
        {/*    onClick={e => handleRemoveAvatar(e)}*/}
        {/*  >*/}
        {/*    Remove Avatar*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>

      {hasPermission('policyholder_portal.*') ? (
      <>
        <div className="row">
          <div className="col">
            <span className="label">First name</span>
            <p className="font-md">{echo(user.first_name)}</p>
          </div>
          <div className="col">
            <span className="label">Last name</span>
            <p className="font-md">{echo(user.last_name)}</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <span className="label">Email</span>
            <p className="font-md">{echo(user.email)}</p>
          </div>
        </div>
      </>
      ) : (
      <>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="First name"
              component={renderField}
              className="form-control"
              name={`first_name`}
              placeholder="e.g. Jane"
            />
          </div>
          <div className="col">
            <Field
              type="text"
              label="Last name"
              component={renderField}
              className="form-control"
              name={`last_name`}
              placeholder="e.g. Doe"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="email"
              label="Email"
              component={renderField}
              className="form-control"
              validate={[]}
              name={`email`}
              placeholder="e.g. jane.doe@example.com"
            />
          </div>
        </div>

        {user.is_two_factor_email_active && (
          <div className="row">
            <div className="col">
              <Field
                type="text"
                placeholder="Separate multiple emails with a comma (no space)"
                label="Copy 2FA email code"
                component={renderField}
                name="copy_two_factor_emails"
                className="form-control"
              />
            </div>
          </div>
        )}
      </>
      )}

      <div className="form-group" style={{ marginBottom: '3.1rem', marginTop: '0.5rem'}}>
        <button
          className="btn btn-warning btn-sm"
          type="button"
          onClick={props.toggleModal}
        >
          Change password
        </button>
      </div>

      {!hasPermission('policyholder_portal.*') && (
      <div className="form-group">
        <button
          disabled={!props.valid || props.pristine}
          className="btn btn-secondary"
        >
          Save
        </button>
        <Link
          to="#"
          onClick={e => {
            e.preventDefault()
            props.onCancel()
          }}
          className="btn btn-outline text-success"
          style={{ color: '#70b725', border: 0 }}
        >
          Cancel
        </Link>
      </div>
      )}

    </Form>
  )
}

ProfileForm = reduxForm({
  form: 'profile/update',
  validate,
})(ProfileForm)

const mapPropsToState = state => {
  const {
    authentication: { user },
  } = state
  return {
    initialValues: {
      avatar: user && user.avatar,
      first_name: user && user.first_name,
      last_name: user && user.last_name,
      email: user && user.email,
      is_two_factor_email_active: user && user.is_two_factor_email_active,
      copy_two_factor_emails: user && user.copy_two_factor_emails,
    },
    enableReinitialize: true,
  }
}

ProfileForm.propTypes = {
  onUpdateAvatar: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  error: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
}

export default connect(
  mapPropsToState,
  {},
)(ProfileForm)
