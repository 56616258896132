import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import moment from 'moment';
import { echo, money, prettyNumber } from '../../../../../utils/misc';
import Modal from '../../../../modals/Modal';
import EditProspectDetails from './EditProspectDetails';
import api from '../../../../../utils/api'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'

function ProspectCard({ prospect, activeTab, setActiveTab, reloadProspect }) {
  const [editContactModal, setEditContactModal] = useState(false);


  const address = () => {
    let address = '';

    if (prospect) {
      if (prospect.address_1) {
        address = prospect.address_1;
      }
      if (prospect.address_2) {
        address += `, ${prospect.address_2}`;
      }
      if (prospect.address_3) {
        address += `, ${prospect.address_3}`;
      }
    }

    return address
  }

  const handleContactSubmit = (values) => {
    api
      .patch(`/direct-sales/prospects/${prospect.id}`, values)
      .then((res) => {
        NotificationManager.success('Woohoo! You update the contact details.')
        reloadProspect()
        setEditContactModal(false)
      })
      .catch((err) => {
        NotificationManager.error(
          'Oops! Your quote was not saved. Please try again after refreshing the page.'
        )
      })
  }

  const contactInitialValues = () => 
    _.pick(
      prospect, 
      ['address_1', 'address_2', 'address_3', 'city', 'county', 'postcode', 'email', 'tel1', 'tel2']
    )

  let hasMultipleQuotes = prospect && prospect.quotes.length > 1;
  let expiredQuotes = [];

  expiredQuotes =
    prospect &&
    prospect.quotes
      .filter((p) => moment(p.end_date).isBefore(moment()))
      .map((p) => p.ref);
  let activeQuotes =
    prospect &&
    prospect.quotes.filter((p) => moment(p.end_date).isAfter(moment()));

  return (
    prospect && (
      <div className="ph-details d-flex shadow-dark">
        <div className="ph-details-person">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h6 className="text-uppercase mb-0">Contact Details </h6>
            <span
              className="d-block cursor-pointer"
              style={{ width: 20, height: 20 }}
              onClick={() => setEditContactModal(!editContactModal)}
            >
              <i className="wf-icon-edit mr-0" />
            </span>
          </div>

          <Modal
            isShowing={editContactModal}
            style={{ width: '435px', paddingTop: 0 }}
            title="Edit contact details"
            subtitle={`${prospect.first_name} ${prospect.last_name}`}
            onCancel={() => setEditContactModal(false)}
            textCancel={null}
          >
            <EditProspectDetails initialValues={contactInitialValues()} onSubmit={handleContactSubmit} onCancel={() => setEditContactModal(false)} />
          </Modal>

          <div className="form-group">
            <label className="form-label mb-0">Address</label>
            <div className="font-md">{echo(address())}</div>
          </div>

          <div className="form-group">
            <label className="form-label mb-0">Email</label>
            <div className="font-md">{echo(prospect.email)}</div>
          </div>

          <div className="form-group">
            <label className="form-label mb-0">Telephone (home)</label>
            <div className="font-md">{echo(prospect.tel1)}</div>
          </div>
          <div className="form-group">
            <label className="form-label mb-0">Telephone (mobile)</label>
            <div className="font-md">{echo(prospect.tel2)}</div>
          </div>
        </div>
        <div className="ph-details-policies flex-grow-1">
          <h6 className="mb-4 text-uppercase">Quotes</h6>
          {hasMultipleQuotes ? (
            <>
              <Nav tabs>
                {prospect &&
                  activeQuotes.map((policy, index) => {
                    return (
                      <NavItem
                        key={index}
                        className="border-right-0 rounded-0 bg-gray-100"
                      >
                        <NavLink
                          className={`${
                            activeTab === index && 'active'
                          } font-md`}
                          onClick={() => setActiveTab(index)}
                        >
                          {policy.ref}
                        </NavLink>
                      </NavItem>
                    );
                  })}
              </Nav>

              <TabContent activeTab={activeTab} className="border-bottom-0">
                <TabPane className="pb-0 pl-0 pr-0 " tabId={activeTab}>
                  <QuoteDetails quote={activeQuotes[activeTab]} />
                </TabPane>
              </TabContent>
            </>
          ) : (
            <>
              {activeQuotes.length > 0 ? (
                <QuoteDetails quote={activeQuotes[0]} />
              ) : (
                <p className="font-sm">
                  This user currently does not have any quotes.
                </p>
              )}
            </>
          )}

          {expiredQuotes.length > 0 && (
            <div className="mt-4">
              <hr className="mt-0 mb-3" />
              <h6 className="mt-4 mb-4">Expired policies</h6>

              <div className="label">Quote ID</div>
              {expiredQuotes.map((p, index) => {
                if (expiredQuotes.length - 1 === index) {
                  return (
                    <Link key={index} to="#">
                      {p}
                    </Link>
                  );
                }

                return (
                  <Link key={index} to="#">
                    {p},{' '}
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </div>
    )
  );
}

const formatExcess = (type, excess) => {
  if (type === 'Absolute')
    return <div className="font-md">{excess ? `£${excess}` : '-'}</div>;
  else if (type === 'Percentage')
    return <div className="font-md">{excess ? `${excess}%` : '-'}</div>;
};

function QuoteDetails(props) {
  const quote = props.quote;

  return (
    quote && (
      <div className="ph-details-items">
        <div className="ph-details__row">
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Quote number</label>
              <div className="font-md">
                {quote.ref}
              </div>
            </div>
          </div>

          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Quote date</label>
              <div className="font-md">
                {moment(quote.start_date).format('DD/MM/YYYY')}
              </div>
            </div>
          </div>
        </div>

        <div className="ph-details__row">
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Vehicle reg.</label>
              <div className="font-md">AB12 CDE</div>
            </div>
          </div>
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Type</label>
              <div className="font-md">Car</div>
            </div>
          </div>
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Make and model</label>
              <div className="font-md">Seat, Ibiza</div>
            </div>
          </div>
        </div>

        <div className="ph-details__row">
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Cover level</label>
              <div className="font-md">Premier, 12 months</div>
            </div>
          </div>

          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Preferred start date</label>
              <div className="font-md">
                {quote.start_date
                  ? moment(quote.start_date).format('DD/MM/YYYY')
                  : '-'}
              </div>
            </div>
          </div>

          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">End date</label>
              <div className="font-md">
                {quote.end_date
                  ? moment(quote.end_date).format('DD/MM/YYYY')
                  : '-'}
              </div>
            </div>
          </div>
        </div>

        <div className="ph-details__row">
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Claim limit</label>
              <div className="font-md">
                {quote.claim_limit ? money.format(quote.claim_limit) : '-'}
              </div>
            </div>
          </div>

          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Labour rate</label>
              <div className="font-md">
                {quote.labour_rate ? money.format(quote.labour_rate) : '-'}
              </div>
            </div>
          </div>

          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Excess</label>
              {formatExcess(quote.excess_type, quote.excess)}
            </div>
          </div>
        </div>

        <div className="ph-details__row">
          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Quoted mileage</label>
              <div className="font-md">
                {prettyNumber(quote.cover_level.max_mileage)} miles
              </div>
            </div>
          </div>

          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Quote source</label>
              <div className="font-md">{echo(quote.source)}</div>
            </div>
          </div>

          <div className="ph-details__column">
            <div className="form-group pb-0">
              <label className="form-label mb-0">Quote price</label>
              <div className="font-md">
                {money.format(quote.net + quote.vat)}
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  );
}

QuoteDetails.propTypes = {
  quote: PropTypes.object.isRequired,
};

export default ProspectCard;
