import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import moment from 'moment'
import api from '../../../../utils/api'
import PageHeader from '../../../ui/PageHeader'
import DatePickers from './Datepickers'
import Avatar from '../../../ui/Avatar'
import { money } from '../../../../utils/misc'
import TeamTargetTracking from './TeamTargetTracking'

function Dashboard() {
    const [month, setMonth] = useState(moment().format('M'))
    const [year, setYear] = useState(moment().format('YYYY'))

    const [detail, setDetail] = useState(null)
    const [exportingCsv, setExportingCsv] = useState(false)

    useEffect(() => {
        api
            .get('/dashboards/external-sales/detail', {
                params: {
                    month,
                    year
                },
            })
            .then((res) => {
                setDetail(res.data)
            })
            .catch((err) => {
                if (err) {
                    NotificationManager.error(
                        'Whoops! Something went wrong. Please try again after refreshing the page.'
                    )
                }
            })
    }, [month,year])

    const handleFilterChange = (values) => {
        setMonth(values.month.value.toString())
        setYear(values.year.value.toString())
    }

    const newlyAcquiredTotal = () => {
        return detail ? detail.map(item => item.newly_acquired_sales).reduce((prev, next) => prev + next) : 0;
    }

    const dealersOpenedTotal = () => {
        return detail ? detail.map(item => item.newly_opened_dealers).reduce((prev, next) => prev + next) : 0;
    }

    const monthlyTargetTotal = () => {
        return detail ? detail.map(item => {
            if (!item.target) return 0
            const val = parseFloat(item.target)
            return isNaN(val) ? 0 : val
        }).reduce((prev, next) => prev + next) : 0;
    }

    const trackingToTargetTotal = () => {
        return detail ? detail.map(item => item.won_value).reduce((prev, next) => prev + next) : 0;
    }

    const projectedTotal = () => {
        return detail ? detail.map(item => item.projected).reduce((prev, next) => prev + next) : 0;
    }

    const getProgressClasses = (percent) => {
        const classes = []
        if (percent > 0 && percent < 85) classes.push('progress-red')
        else if (percent >= 85 && percent < 100) classes.push('progress')
        else if (percent >= 100) classes.push('progress-green')

        return classes.join(' ')
    }

    const exportCsv = async () => {
        setExportingCsv(true);

        try {
            const response = await api.get(
                '/downloads/csv/dashboards/external-sales/policies', {
                    params: {
                        month,
                        year
                    },
                }, {
                responseType: 'blob',
            });
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(
            new Blob([response.data], { type: 'text/csv' })
            )
            link.download = `${year}-${month}_external_sales_policies.csv`
            link.click()
        } catch (err) {
            NotificationManager.error(
                'Whoops! Something went wrong. Please try again after refreshing the page.'
            );
        }
        setExportingCsv(false);
    }

    return (
        <div className="container-fluid animated fadeIn bg-grey min-vh-100 dashboard-grey-extend">
            <br />
            <div className="row">
                <div className="col-md-8">
                    <PageHeader
                        title="External Sales Dashboard"
                        description={`View your team’s performance data below. See how you’re tracking against your targets and how individuals are performing month on month.`}
                    />
                </div>
            </div>

            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <h3 style={{ marginTop: 64 }}>&nbsp;</h3>
                </div>
                <div className="dashboard-date-select">
                    <DatePickers
                        onSubmit={handleFilterChange}
                        month={month}
                        year={year}
                        initialValues={{
                            month: {
                                label: moment().format('MMMM'),
                                value: moment().format('M'),
                            },
                            year: {
                                value: moment().format('Y'),
                            },
                        }}
                    />
                </div>
            </div>

            <TeamTargetTracking year={year} />

            <div className="bg-white table-spacing pb-1 mb-2">
                <h3>Individual performances</h3>

                <div className="text-right" style={{ marginBottom: '1rem' }}>
                    <button
                        className="btn btn-secondary btn-sm"
                        disabled={exportingCsv}
                        onClick={exportCsv}
                    >
                        Export CSV
                    </button>
                </div>

                <table className="table table-striped table-bordered d-table bg-white">
                    <thead>
                    <tr>
                        <th width="250px">&nbsp;</th>
                        <th>Newly Acquired (R6M)</th>
                        <th>Dealers Opened (R3M)</th>
                        <th>Monthly target</th>
                        <th style={{minWidth: "300px"}}>Tracking to target</th>
                        <th>Projected Finish</th>
                    </tr>
                    </thead>
                    <tbody>
                    {detail &&
                    detail.map((row, key) => {
                        return (
                            <tr key={key}>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <Avatar user={row.user} className="flex-1" />
                                        <div className="ml-2">
                                            {row.user.first_name} {row.user.last_name}
                                        </div>
                                    </div>
                                </td>
                                <td>{money.format(row.newly_acquired_sales)}</td>
                                <td>{row.newly_opened_dealers}</td>
                                <td>{row.target === 0 ? 'Not set' : money.format(row.target)}</td>
                                <td style={{minWidth: "300px"}}>
                                    {row.won_value === 0 ? (
                                        '-'
                                    ) : (
                                        <>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>{row.tracking}%</span>
                                                <span>{money.format(row.won_value)}</span>
                                            </div>
                                            <div className="progress-bar">
                                                <div
                                                    className={getProgressClasses(row.tracking)}
                                                    style={{ width: row.tracking + '%' }}
                                                />
                                            </div>
                                        </>
                                    )}
                                </td>
                                <td>{money.format(row.projected)}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <th className="text-right">TOTAL</th>
                        <th>{money.format(newlyAcquiredTotal())}</th>
                        <th>{dealersOpenedTotal()}</th>
                        <th>{money.format(monthlyTargetTotal())}</th>
                        <th className="text-right">{money.format(trackingToTargetTotal())}</th>
                        <th>{money.format(projectedTotal())}</th>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )
}

export default Dashboard
