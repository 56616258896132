import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import api from '../../../../utils/api'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import { money } from '../../../../utils/misc'

function convertDatesToLabels(dates) {
  let arr = []
  for (let d of Object.keys(dates)) {
    arr.push(moment(d).format('MMMM'))
  }

  return [...new Set(arr)]
}

function TeamTargetTracking({ year }) {
  const [data, setData] = useState(null)

  useEffect(() => {
    api
      .get('/dashboards/external-sales/tracking', {
        params: {
          year
        },
      })
      .then((res) => {
        const labels = convertDatesToLabels(res.data.dates)
        const values = Object.values(res.data.dates)

        let obj = {
          ...res.data,
          labels,
          values,
          totalRevenue: money.format(Object.values(res.data.dates).reduce((a,b) => parseFloat(a) + parseFloat(b), 0)),
          yearlyTarget: money.format(res.data.target.reduce((a,b) => parseFloat(a) + parseFloat(b), 0))
        }
        setData(obj)
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Whoops! Something went wrong. Please try again after refreshing the page.'
          )
        }
      })
  }, [year])

  return (
    <div className="bg-white table-spacing pb-1 mb-2">
      <h3>Team target tracking</h3>
      <p>{year}</p>

      {data && (
        <Bar
          options={{
            legend: {
              display: true,
              position: 'bottom',
              align: 'start',
            },
            scales: {
              y: {
                min: 0,
              },
            },
          }}
          data={{
            labels: Object.keys(data.dates),
            data: data.values,
            datasets: [
              {
                label: `Yearly Target - ${data.yearlyTarget} `,
                data: data.target,
                backgroundColor: ['rgba(255,255,255,0)'],
                borderColor: '#E10808',
                borderDash: [10, 10],
                borderWidth: 1,
              },
              {
                label: `Total Revenue - ${data.totalRevenue}`,
                data: data.values,
                borderColor: '#311EDB',
                backgroundColor: 'rgb(255,255,255,0)',
                borderWidth: 1,
              },
            ],
          }}
        />
      )}
    </div>
  )
}

TeamTargetTracking.propTypes = {
  year: PropTypes.string.isRequired,
}

export default TeamTargetTracking
