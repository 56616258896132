import React, { useState } from 'react'
import { change, Field } from 'redux-form'
import renderField from '../../../../../utils/renderField'
import { email, required, url } from 'redux-form-validators'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const ContactDetails = () => {
  const [primaryContactForm, setPrimaryContactForm] = useState(false)
  const dispatch = useDispatch()

  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-4">
          <h6 className="mt-4 text-uppercase">Contact details</h6>
          <p>Please include at least one contact telephone number</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 b-form-group">
          <Field
            type="text"
            label="Telephone (office)"
            component={renderField}
            name={`tel_office`}
            className="form-control"
          />
        </div>
        <div className="col-md-6 b-form-group">
          <Field
            type="text"
            label="Telephone (mobile)"
            component={renderField}
            name={`tel_mobile`}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 b-form-group">
          <Field
            type="email"
            label="Email address"
            component={renderField}
            name={`email`}
            validate={[required({ msg: 'You must enter an email address.' }), email({ message: 'That email address doesn\'t quite look right.' })]}
            className="form-control"
            placeholder="e.g. 123cars@gmail.com"
          />
        </div>
        <div className="col-md-6 b-form-group">
          <Field
            type="text"
            label="Website (optional)"
            component={renderField}
            validate={[url({
              message: 'That URL doesn\'t quite look right.',
              allowBlank: true,
              protocolIdentifier: false
            })]}
            name={`website`}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 pb-4">
          {!primaryContactForm && (
            <Link
              to="#"
              className="font-sm"
              onClick={() => {
                dispatch(change('dealers/create', '_hasPrimaryContact', true))
                setPrimaryContactForm(!primaryContactForm)
              }}
            >
              Add a primary contact
            </Link>
          )}
          {primaryContactForm && (
            <>
              <div className="mb-4 mt-3">
                <h6 className="mt-4">Primary contact (optional)</h6>
                <p>
                  Add the contact details of a specific person at the
                  dealership.
                </p>
              </div>

              <div className="row">
                <div className="col-md-6 mt-2">
                  <Field
                    type="text"
                    label="Contact's first name"
                    component={renderField}
                    name={`primary_contact.first_name`}
                    validate={[required({ message: 'You must enter a first name.'})]}
                    className="form-control"
                  />
                </div>

                <div className="col-md-6 mt-2">
                  <Field
                    type="text"
                    label="Contact's last name"
                    component={renderField}
                    name={`primary_contact.last_name`}
                    validate={[required({ message: 'You must enter a last name.'})]}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mt-2">
                  <Field
                    type="email"
                    label="Contact's email address"
                    component={renderField}
                    name={`primary_contact.email`}
                    validate={[email({ allowBlank: false, message: 'That email address doesn\'t quite look right.' })]}
                    className="form-control"
                    placeholder="e.g. 123cars@email.com"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <Field
                    type="text"
                    label="Telephone (office) "
                    component={renderField}
                    name={`primary_contact.tel1`}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    type="text"
                    label="Telephone (mobile)"
                    component={renderField}
                    name={`primary_contact.tel2`}
                    className="form-control"
                  />
                </div>
              </div>
            </>
          )}
          <hr style={{ marginTop: '2.25rem' }}/>
        </div>
      </div>
    </>
  )
}

export default ContactDetails