import _ from 'lodash'

export const accessTokenClaims = token => {
  if (!token) {
    return null
  }
  let base64Url = token.split('.')[1]
  let base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}

export const isSuperAdmin = () => {

  let token = localStorage.getItem('wf-token')
  let tokenClaims = accessTokenClaims(token)

  if (typeof token === 'undefined')
    return false
  if (!tokenClaims)
    return false

  // eslint-disable-next-line no-unused-vars
  const {is_super_admin, permissions} = tokenClaims

  return is_super_admin
}

export const hasGroup = (group) => {
  let token = localStorage.getItem('wf-token')
  let tokenClaims = accessTokenClaims(token)

  if (typeof token === 'undefined')
    return false
  if (!tokenClaims)
    return false

  const {groups} = tokenClaims

  return Array.isArray(groups) && groups.includes(group)
}

export const hasPermission = (required_permission) => {
  let token = localStorage.getItem('wf-token')
  let tokenClaims = accessTokenClaims(token)

  if (typeof token === 'undefined')
    return false
  if (!tokenClaims)
    return false

  const {is_super_admin, permissions} = tokenClaims

  // if the user is a super admin, allow everything apart from the dealer portal and policyholder portal
  if (is_super_admin && !_.startsWith(required_permission, 'dealer_portal') && !_.startsWith(required_permission, 'policyholder_portal'))
    return true

  if (_.includes(permissions, required_permission))
    return true

  // Search for wildcard match (e.g. 'admin.users.*')
  if (_.endsWith(required_permission, '*')) {
    return !!_.find(permissions,
      permission => _.startsWith(permission, required_permission.substring(0, required_permission.length - 1)),
    )
  }

  return false
}