import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import api from '../../../../../utils/api';
import { history } from "../../../../../utils/history";
import { echo } from '../../../../../utils/misc';
import { confirmAlert } from "react-confirm-alert";
import Timeline from '../../../../ui/timeline/Timeline';
import Avatar from '../../../../ui/Avatar';

class ProspectTimeline extends Component {
  state = {
    dealer: null,
  };

  componentDidMount() {
    // window.scroll(0,0)
    // fetch dealer info
    api
      .get(`/external-sales/dealers/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ dealer: res.data });
      })
      .catch(() => {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again after refreshing.'
        );
      });
  }

  render() {
    const { dealer } = this.state;
    const { loggedInPersonID } = this.props;

    let dealerEmail = dealer && dealer.email;

    if (dealer && dealer.email.length > 30) {
      dealerEmail = dealer.email.substr(0, 30) + '...';
    }

    const location = this.props.match && this.props.match;
    const section = location.path.split('/')[1];

    const handleCreateAccount = () => {
      confirmAlert({
        title: '🧐 Are you sure?',
        customUI: ({ title, onClose }) => {
          return (
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <span className="btn-close-modal fa fa-close" onClick={onClose} />
                <h3>{title}</h3>
                <p>
                  Create a dealer account for <strong>{dealer.name}</strong>?
                </p>
                <button className="btn btn-secondary" onClick={() => {
                  onClose()
                  createAccount()
                }}>
                  Yes, I'm sure
                </button>
                <button className="btn btn-transparent text-secondary" onClick={onClose}>
                  No, cancel
                </button>
              </div>
            </div>
          )
        },
      })
    }

    const createAccount = () => {
      api.patch(`/external-sales/prospects/${dealer.id}/create-account`)
        .then(res => {
          NotificationManager.success('Woohoo! You created a dealer.')
          history.push(`/external-sales/dealers/${dealer.id}/edit`)
        })
        .catch(err => {
          NotificationManager.error('Oops! Could not create a dealer account for the prospect.')
        })
    }

    return (
      dealer && (
        <div className="timeline-page bg-grey">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to={`/${section}/prospects`} className="open active">
                Prospects
              </Link>
            </li>
            <li className="breadcrumb-item">
              <span className="active">Prospect Timeline</span>
            </li>
          </ol>
          <section className="timeline-header bg-white">
            <div className="timeline-page-container animated fadeIn">
              <header>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <div className="d-flex align-items-start">
                      <h2 className="mb-0">{dealer.name}</h2>
                      {dealer.is_prospect && (
                        <span
                          className="badge badge-primary badge-pill font-sm ml-3"
                          style={{ padding: '6px 12px' }}
                        >
                          Prospect
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <div className="ph-detail-actions">
                      <button
                        onClick={() => handleCreateAccount()}
                        className="btn btn-success btn-md mr-3 fl-right"
                      >
                        Create Account
                      </button>
                      {/*<a href={`mailto:${dealer.email}`} className="btn btn-primary btn-large d-flex align-items-center font-lg"> <span className="wf-icon-email-filled mr-2 mt-1"/>Email</a>*/}
                    </div>
                  </div>
                </div>
              </header>
            </div>
          </section>

          <div
            className="pt-5 pl-4 pr-4"
            style={{ paddingBottom: '60px' }}
          >
            <div className="timeline-page-container animated fadeIn pb-6">
              <div className="timeline-page-content">
                <div className="d-flex flex-column align-self-start border-0 ">
                  <div className="dealer-details bg-white shadow-dark">
                    <div className="container-fluid no-gutters p-0">

                      <div className="mb-4">
                        <h6 className="mb-0">Contact Details</h6>
                      </div>

                      <div className="row">
                        <div className="col-4">
                          <span className="label">Address</span>
                          <p className="font-md">
                            {echo(dealer.address_1)}, {echo(dealer.address_2)}{' '}
                            {dealer.address_3 ? `, ${dealer.address_3}` : ''}
                          </p>
                        </div>
                        <div className="col-4">
                          <span className="label">Telephone (office)</span>
                          <p className="font-md">{echo(dealer.tel_office)}</p>
                        </div>
                        <div className="col-4">
                          <span className="label">Primary contact</span>
                          {dealer.primary_contact ? (
                            <div className="d-flex mt-1">
                              <span className="font-md">
                                {dealer.primary_contact.first_name}{' '}
                                {dealer.primary_contact.last_name}
                              </span>
                            </div>
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4">
                          <span className="label">Email</span>
                          <p
                            className="font-md text-wrap"
                            data-tip={dealer.email}
                            data-place="top"
                          >
                            {dealerEmail}
                          </p>
                          <ReactTooltip effect="solid" />
                        </div>
                        <div className="col-4">
                          <span className="label">Telephone (mobile)</span>
                          <p className="font-md">{echo(dealer.tel_mobile)}</p>
                        </div>
                        <div className="col-4">
                          <span className="label">VAT registered</span>
                          <p className="font-md">{echo(dealer.vat_registered ? 'Yes' : 'No')}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {loggedInPersonID && (
                    <Timeline
                      personId={loggedInPersonID}
                      for={dealer.name}
                      loggedInPersonID={loggedInPersonID}
                      apiUrl={`/external-sales/dealers/${dealer.id}/timeline`}
                      allowMeetings={true}
                    />
                  )}
                </div>
                <div className="wf-timeline-sidebar">
                  <div className="wf-timeline-card shadow-dark bg-white ph-claim-limits">
                    <h6 className="h6 fw-600 font-sm">Account Manager</h6>

                    {dealer.territory &&
                    dealer.territory.current_account_manager ? (
                      <div className="d-flex align-items-center">
                        <Avatar
                          size="medium"
                          user={{ first_name: 'Sarah', last_name: 'King' }}
                        />
                        <span className="font-md ml-2">
                          {dealer.territory.current_account_manager.first_name}{' '}
                          {dealer.territory.current_account_manager.last_name}
                        </span>
                      </div>
                    ) : (
                      <div>-</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

ProspectTimeline.propTypes = {};

export default connect(
  (state) => ({
    loggedInPersonID:
      state.authentication.user && state.authentication.user.person_id,
  }),
  null
)(ProspectTimeline);
