import { Link } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import { SubmissionError } from 'redux-form'
import { pick, get, has } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux';

import { checked, formatDate } from '../../../../../utils/misc'
import DealerDetailsForm from './forms/DealerDetailsForm'
import SlideToggle from '../../../../ui/SlideToggle'
import api from '../../../../../utils/api'

const DealerDetails = ({ dealer, setDealer, loggedInPerson }) => {
  const [toggle, setToggle] = useState(false)

  /**
   * Update dealer details form
   *
   * @param  {Object} values Form submitted values
   */
  const handleSubmitDealerDetailsForm = (values) => {
    const data = pick(values, [
      'name',
      'address_1',
      'address_2',
      'address_3',
      'city',
      'county',
      'postcode',
      'active_start_date',
      'allow_own_garage_repairs',
      'vat_registered',
      'vat_number'
    ])

    // format dates to be accepted by the backend
    data.active_start_date = values.active_start_date
      ? moment(values.active_start_date).format('YYYY-MM-DD')
      : null
    data.active_end_date = values.active_end_date
      ? moment(values.active_end_date).format('YYYY-MM-DD')
      : null

    if (get(values, 'garage.id')) {
      data.garage_id = values.garage.id
    } else {
      data.garage_id = null
    }

    data.status = values.status.value
    data.sub_status = values.status.value === 'Rejected' ? values.sub_status.value : ''

    return api
      .patch(`/external-sales/dealers/${dealer.id}/details`, data)
      .then(async (res) => {
        // when we remove the garage the API doesn't return a `garage` object along with `garage_id` property
        // this causes problems and we're dealing with it here
        const updated = { ...dealer, ...res.data }
        if (!has(res.data, 'garage')) {
          delete updated.garage
        }
        setDealer(updated)

        NotificationManager.success(
          'Woohoo! Dealer details updated successfully!'
        )
        setToggle(false)

        // send email and add email message to timeline
        if (dealer.status !== data.status && data.status === 'Active') {
          await sendEmail({ type: 'new-dealer-welcome' })
          const message = {
            message: `Welcome email sent on the ${moment().format('Do')} of ${moment().format('MMM')} ${moment().format('YYYY')} at ${moment().format('HH:mm')}`,
            message_type: 'Email',
            person_id: loggedInPerson.person_id,
          };
          await createMessage(message)
        } else if (
          dealer.sub_status !== data.sub_status &&
          data.status === 'Rejected' &&
          data.sub_status === 'Due to non-payment'
        ) {
          await sendEmail({ type: 'dealer-closure-non-payment' })
          const message = {
            message: `Account closed due to Dealer outstanding payment email sent by ${loggedInPerson.first_name} ${loggedInPerson.last_name} on the ${moment().format('Do')} of ${moment().format('MMM')} ${moment().format('YYYY')} at ${moment().format('HH:mm')}`,
            message_type: 'Email',
            person_id: loggedInPerson.person_id,
          };
          await createMessage(message)
        }
      })
      .catch((err) => {
        if (err && err.response.status === 422) {
          throw new SubmissionError({
            ...err.response.data.errors,
            _error: err.response.data,
          })
        }
      })
  }

  const sendEmail = async ({ type }) => {
    try {
      await api.post(`/external-sales/dealers/${dealer.id}/email`, { type });
    } catch (err) {}
  }


  const createMessage = async (message) => {
    try {
      await api.post(`/external-sales/dealers/${dealer.id}/timeline/message`, message)
    } catch (err) {}
  }

  const garageName = get(dealer, 'garage.name')

  return (
    <div className="row">
      <div className="col">
        <div style={{ maxWidth: 570 }} className="edit-dealer-column">
          <header className="d-flex align-items-center justify-content-between">
            <div>
              <h6 className="mt-4 text-uppercase">Dealer details</h6>
              <Link to={`/external-sales/dealers/${dealer.id}/timeline`}>View timeline</Link>
            </div>
            <button
              onClick={() => setToggle(!toggle)}
              className="btn-gray"
              data-tip="Edit dealer details"
              data-place="top"
            >
              <i className="wf-icon-edit d-block" />
            </button>
          </header>
          {!toggle && (
            <>
              <div className="row">
                <div className="col">
                  <span className="label">Dealership name</span>
                  <p className="font-md">{dealer.name}</p>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <span className="label">Status</span>
                  <p className="font-md">{dealer.status}</p>
                </div>
                {dealer.sub_status && (
                  <div className="col">
                    <span className="label">Sub status</span>
                    <p className="font-md">{dealer.sub_status}</p>
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col">
                  <span className="label">Address</span>
                  <p className="font-md">
                    {[
                      dealer.address_1,
                      dealer.address_2,
                      dealer.address_3,
                      dealer.city,
                      dealer.country,
                      dealer.postcode,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <span className="label">Sign-up date</span>
                  <p className="font-md">
                    {dealer.active_start_date
                      ? formatDate(dealer.active_start_date)
                      : '-'}
                  </p>
                </div>
                <div className="col-4">
                  <span className="label">System access end date</span>
                  <p className="font-md">
                    {dealer.active_end_date
                      ? formatDate(dealer.active_end_date)
                      : '-'}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <span className="label">Preferred garage</span>
                  <p className="font-md">
                    {dealer.garage_id ? garageName : '-'}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col mt-2">
                  <p className="font-md">
                    {checked(dealer.allow_own_garage_repairs)}
                    <span>Allow dealer’s own garage for repairs</span>
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col mb-3">
                  <p className="font-md">
                    {checked(dealer.vat_registered)}
                    <span>This dealer is VAT registered</span>
                  </p>
                </div>
              </div>
            </>
          )}

          <SlideToggle isVisible={toggle}>
            <DealerDetailsForm
              onSubmit={handleSubmitDealerDetailsForm}
              toggle={setToggle}
              initialValues={{ ...dealer, status: { value: dealer.status }, sub_status: { value: dealer.sub_status }, active_start_date: dealer.active_start_date ? moment(dealer.active_start_date).toDate() : null, active_end_date: dealer.active_end_date ? moment(dealer.active_end_date).toDate() : null }}
              isProspect={false}
              status={dealer.status}
              vatRegistered={dealer.vat_registered}
            />
          </SlideToggle>

          <hr />
        </div>
      </div>
    </div>
  )
}

DealerDetails.propTypes = {
  dealer: PropTypes.object.isRequired,
  setDealer: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    loggedInPerson: state.authentication.user,
  }),
  null
)(DealerDetails);
