import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

const DealerDetails = ({ dealer }) => {
  return (
    <div className="row">
      <div className="col-12 pr-5">
        <h6 className="my-5 text-uppercase">Dealer details</h6>

        <div className="row">
          <div className="col">
            <span className="label">Dealership</span>
            <p>{get(dealer, 'name', '-')}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <span className="label">Address</span>
            <p>{[
              get(dealer, 'address_1', ''),
              get(dealer, 'address_2', ''),
              get(dealer, 'address_3', ''),
              get(dealer, 'city', ''),
              get(dealer, 'county', ''),
              get(dealer, 'postcode', ''),
            ]
              .filter(Boolean)
              .join(', ')}
              </p>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Telephone (office)</span>
            <p>{get(dealer, 'tel_office', '-')}</p>
          </div>
          <div className="col-4">
            <span className="label">Email address</span>
            <p>{get(dealer, 'email', '-')}</p>
          </div>
        </div>

        <hr/>
      </div>
    </div>
  )
}

DealerDetails.propTypes = {
  dealer: PropTypes.object.isRequired
}

export default DealerDetails
