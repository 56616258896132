import React, { Component, Fragment } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import PageHeader from '../../../../ui/PageHeader'
import { sortCaret } from '../../../../../utils/misc'
import Table from '../../../../ui/Table'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import api from "../../../../../utils/api";
import {NotificationManager} from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import { hasPermission } from '../../../../../utils/auth';

class Garages extends Component {
  state = {
    garages: null,
    filterGroups: [
      {
        name        : 'status',
        allowOnlyOne: true,
        filters     : [
          { id: 5, name: 'Active', value: 'Active' },
          { id: 4, name: 'In review', value: 'In Review' },
          { id: 6, name: 'Blacklisted', value: 'Blacklisted' },
        ],
      },
      {
        name        : 'Authorised Garages',
        allowOnlyOne: true,
        filters: [
          {
            id: 1,
            name: 'Authorised',
            value: 1,
          },
          {
            id: 2,
            name: 'Non-authorised ',
            value: 0,
          },
        ],
      },
    ],
    query: '',
    page: 1,
    limit: 10,
    order: '',
    desc: false,
    searching: false,
    toggleModal: false,
    status: '',
    authorisedRepairer: null,
  };

  fetchData = url => {
    api.get(`/claims/garages${url ? url : ''}`)
      .then(res => {
        this.setState({ garages: res.data, searching: false })
      })
      .catch(err => {
        if (err) {
          NotificationManager.error('Oops! Something went wrong. Please try again after refreshing.')
        }
      })
  }

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    let url = `?page=${page}&limit=${sizePerPage}&query=${this.state.query}`
    if (sortField) {
      url += `&order=${sortField}&desc=${sortOrder === 'desc'}`
    }
    if (this.state.status) {
      url += `&status=${this.state.status}`
    }
    if (this.state.authorisedRepairer !== null) {
      url += `&authorised_repairer=${this.state.authorisedRepairer}`
    }
    this.fetchData(url)
  }

  handleSearch = (query, filters) => {
    let status = ''
    let authorisedRepairer = null

    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]

      if (filter.group_name === 'status') {
        status = filter.name
      } else if (filter.group_name === 'Authorised Garages') {
        authorisedRepairer = filter.value
      }
    }
    this.setState(
        {
          query,
          status,
          authorisedRepairer,
        },
        this.buildUrl,
    )
  }

  /**
   * Builds URL and to fetch garages
   */
  buildUrl = () => {
    const { query, page, desc, limit, status, authorisedRepairer } = this.state

    let url = `?query=${query}&page=${page}&desc=${desc}&limit=${limit}`

    if (status !== '') url = `${url}&status=${status}`
    if (authorisedRepairer !== null) url = `${url}&authorised_repairer=${authorisedRepairer}`

    this.setState({ searching: true })
    this.fetchData(url)
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    const options = {
      showOptions: false,
      columns: [
        {
          dataField: 'name',
          text: 'Garage name',
          formatter: (cell, row) => {
            return <Link to={`/claims/garages/${row.id}`}>{row.name}</Link>
          }
        },
        {
          dataField: 'dealer',
          text: 'Linked to dealer(s)',
          formatter: (cell, row) => {
            if (row.dealers && row.dealers.length > 0) {
              return row.dealers.map((dealer) => (<Link to={`/claims/dealers/${dealer.id}/timeline`}>{dealer.name}&nbsp;</Link>)).reduce((p, c) => (p === null ? [c] : [...p, ', ', c]), null)
            }
          }
        },
        {
          dataField: 'telephone',
          text: 'Telephone',
          formatter: (cell, row) => {
            return row.tel_office ? row.tel_office : row.tel_mobile
          }
        },
        {
          dataField: 'email',
          text: 'Email',
        },
        {
          dataField: 'status',
          text: 'Status',
          sort: true,
          sortCaret,
        },
      ],
    };

    if (hasPermission('claims.garages.update') || hasPermission('claims.garages.delete')) {
      options.columns.push(
        {
          dataField: 'id',
          text: 'Actions',
          headerStyle: {
            width: 100,
          },
          formatter: (cell, row) => (
            <div className="d-flex align-items-center" key={row.id}>
              <Link
                data-tip="Edit garage"
                data-place="top"
                to={`/claims/garages/${row.id}`}
                className={`table-action-btn ${hasPermission('claims.garages.update') ? '' : 'invisible'}`}
                primary="true"
                size="sm"
              >
                <span className="wf-icon-edit"/>
              </Link>
              <Link
                data-tip="Delete garage"
                data-place="left"
                onClick={() => handleDelete(row)}
                to={`#`}
                className={`table-action-btn table-action-btn--delete ${hasPermission('claims.garages.delete') ? '' : 'invisible'}`}
                primary="true"
                size="sm"
              >
                <span className="wf-icon-delete"/>
              </Link>
              <ReactTooltip effect="solid"/>
            </div>
          ),
        },
      )
    }

    const handleDelete = row => {
      confirmAlert({
        title: '🧐 Are you sure?',
        customUI: ({ title, onClose }) => {
          return (
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <span className="btn-close-modal fa fa-close" onClick={onClose} />
                <h3>{title}</h3>
                <p>
                  Delete the garage <strong>{row.name}</strong>? It'll be
                  lost forever.
                </p>

                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    onClose()
                    deleteGarage(row.id)
                  }}
                >
                  Yes, I'm sure
                </button>
                <button
                  className="btn btn-transparent text-secondary"
                  onClick={onClose}
                >
                  No, cancel
                </button>
              </div>
            </div>
          )
        },
      })
    }

    const deleteGarage = id => {
      api.delete(`/claims/garages/${id}`)
        .then(res => {
          NotificationManager.success('Woohoo! You deleted a garage.')
          const { query, page, desc, limit, status } = this.state

          let url = `?query=${query}&page=${page}&desc=${desc}&limit=${limit}`

          if ((typeof status !== 'undefined') && (status !== '')) url = `${url}&status=${status}`

          this.setState({ searching: true })
          this.fetchData(url)
        })
        .catch(err => {
          NotificationManager.error('Oops! Could not delete the garage.')
        })
    }

    return (
      <Fragment>
        <br/>
        <div className="container-fluid animated fadeIn">
          <PageHeader
            title="Garages"
            description="View all your garages below. You can link a garage to a dealer when you add a new garage or edit an existing garage."
            buttonLink={hasPermission('claims.garages.create') ? '/claims/garages/create' : null}
            buttonText={hasPermission('claims.garages.create') ? "Add a garage" : null}
            buttonIcon={hasPermission('claims.garages.create') ? "d-block wf-icon-add" : null}
          />
          <br/>
          {this.state.garages && (
            <Table
              resource={this.state.garages}
              showOptions={options.showOptions}
              columns={options.columns}
              omnibox={{ groups: this.state.filterGroups }}
              handleTableChange={this.handleTableChange}
              searching={this.state.searching}
              hasTableSearch={true}
              onSearch={this.handleSearch}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

export default Garages
