import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../../../ui/Avatar';
import api from '../../../../utils/api';
import { NotificationManager } from 'react-notifications';
import { echo, formatDate } from '../../../../utils/misc';
import Timeline from '../../../ui/timeline/Timeline';
import { connect } from 'react-redux';
import DealerDetailsSection from "./DealerDetailsSection"
import DealerDashboard from './DealerDashboard';

class DealerTimeline extends Component {
  state = {
    dealer: null,
  };

  componentDidMount() {
    // fetch dealer info
    api
      .get(`/external-sales/dealers/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ dealer: res.data });
      })
      .catch(() => {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again after refreshing.'
        );
      });

    // fetch timeline message
    api
      .get(
        `external-sales/dealers/${this.props.match.params.id}/timeline?limit=5`
      )
      .then((res) => {
        this.setState({
          timeline: res.data,
        });
      })
      .catch((err) => {
        NotificationManager.error(
          'Oops! Something went wrong when fetching the timeline messages. Please try again after refreshing.'
        );
      });
  }

  render() {
    const { dealer } = this.state;
    const { loggedInPersonID } = this.props;

    const location = this.props.match && this.props.match;
    const section = location.path.split('/')[1];

    return (
      dealer && (
        <div className="timeline-page bg-grey">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to={`/${section}/dealers`} className="open active">
                Dealers
              </Link>
            </li>
            <li className="breadcrumb-item">
              <span className="active">Dealers Timeline</span>
            </li>
          </ol>
          <section className="timeline-header bg-white">
            <div className="timeline-page-container animated fadeIn">
              <header>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <h2 className="mb-0">{dealer.name}</h2>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <div className="ph-detail-actions">
                      {/*<a href={`mailto:${dealer.email}`} className="btn btn-primary btn-large d-flex align-items-center font-lg"> <span className="wf-icon-email-filled mr-2 mt-1"/> Email </a>*/}
                    </div>
                  </div>
                </div>
              </header>
            </div>
          </section>

          <div
            className="pt-5 pl-4 pr-4"
            style={{ paddingBottom: '60px' }}
          >
            <div className="timeline-page-container animated fadeIn pb-6">
              <div className="timeline-page-content">
                <div className="d-flex flex-column align-self-start border-0 ">

                  <DealerDetailsSection dealer={dealer}/>

                  <DealerDashboard dealer={dealer}/>

                  {loggedInPersonID && (
                    <Timeline
                      personId={loggedInPersonID}
                      for={dealer.name}
                      loggedInPersonID={loggedInPersonID}
                      apiUrl={`/external-sales/dealers/${dealer.id}/timeline`}
                      allowMeetings={true}
                    />
                  )}
                </div>
                <div className="wf-timeline-sidebar">
                  <div className="wf-timeline-card shadow-dark bg-white ph-claim-limits">
                    <h6 className="h6 fw-600 font-sm">Account Manager</h6>

                    {dealer.territory &&
                    dealer.territory.current_account_manager ? (
                      <div className="d-flex align-items-center">
                        <Avatar
                          size="medium"
                          user={{ first_name: 'Sarah', last_name: 'King' }}
                        />
                        <span className="font-md ml-2">
                          {dealer.territory.current_account_manager.first_name}{' '}
                          {dealer.territory.current_account_manager.last_name}
                        </span>
                      </div>
                    ) : (
                      <div>-</div>
                    )}
                  </div>

                  {dealer.garage && (
                    <div className="wf-timeline-card shadow-dark bg-white ph-claim-limits">
                      <h6 className="h6 fw-600 font-sm">Associated Garage</h6>
                      <div className="dealer-garages">
                        <div className="mb-3">
                          <div className="label">Garage</div>
                          <div className="font-md">
                            {echo(dealer.garage.name)}
                          </div>
                        </div>
                        <div className="mb-0">
                          <div className="label">Allow repairs?</div>
                          <div className="font-md">{dealer.allow_own_garage_repairs ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="wf-timeline-card shadow-dark bg-white ph-claim-limits">
                    <h6 className="h6 fw-600 font-sm">Direct Debit</h6>
                    <div className="dealer-garages">
                      <div className="mb-3">
                        <div className="label">Status</div>
                        <div className="font-md">
                          {dealer.mandate ? `${echo(dealer.mandate.status)} (${formatDate(dealer.mandate.status_at)})` : 'Not signed-up'}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="label">Start Date</div>
                        <div className="font-md">{dealer.mandate ? formatDate(dealer.mandate.start_date) : '-'}</div>
                      </div>
                      <div className="mb-0">
                        <div className="label">End Date</div>
                        <div className="font-md">{dealer.mandate ? formatDate(dealer.mandate.end_date) : '-'}</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

DealerTimeline.propTypes = {};

export default connect(
  (state) => ({
    loggedInPersonID:
      state.authentication.user && state.authentication.user.person_id,
  }),
  null
)(DealerTimeline);
