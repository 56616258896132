import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Timeline from '../../ui/timeline/Timeline'
import TimelineHeader from '../../ui/timeline/TimelineHeader'
import PolicyholderCard from './PolicyholderCard'
import TimelineSidebar from './TimelineSidebar'
import { connect } from 'react-redux'
import { fetchPolicyHolder as directSalesFetchPolicyHolder } from '../../../actions/direct-sales/policy-holders.actions'
import { fetchPolicyHolder as externalSalesFetchPolicyHolder } from '../../../actions/external-sales/policy-holders.actions'
import { fetchPolicyHolder as claimsFetchPolicyHolder } from '../../../actions/claims/policy-holders.actions'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import api from '../../../utils/api';
import { NotificationManager } from 'react-notifications'

const PageHeader = () => {
  const location = useLocation()
  const section = location.pathname.split('/')[1]

  return (
    <ol className="breadcrumb mb-0">
      <li className="breadcrumb-item">
        <Link to={`/${section}/policyholders`} className="open active">
          Policyholders
        </Link>
      </li>
      <li className="breadcrumb-item">
        <span className="active">Policyholder timeline</span>
      </li>
    </ol>
  )
}

class PolicyHolderDetail extends Component {
  state = {
    activePolicyTab: 0, // determines which policy the user is viewing currently
    forceRerender: 0,
  }

  componentDidMount() {
    if (this.props.section === 'external-sales') {
      this.props.externalSalesFetchPolicyHolder(this.props.match.params.id)
    } else if (this.props.section === 'direct-sales') {
      this.props.directSalesFetchPolicyHolder(this.props.match.params.id)
    } else if (this.props.section === 'claims') {
      this.props.claimsFetchPolicyHolder(this.props.match.params.id)
    }

    this.scrollTop()
  }

  scrollTop() {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200)
  }

  componentWillUnmount() {
    clearTimeout(this.scrollTop)
  }

  setActivePolicyTab = (activePolicyTab) => {
    this.setState({ activePolicyTab })
  }

  setForceRerender = () => {
    this.setState({ forceRerender: this.state.forceRerender + 1 })
  }

  isPolicyActive = (policy) => {
    return policy && (policy.status === 'Approved') &&
      moment(policy.end_date).isAfter(moment());
  }

  getActivePolicies = () => {
    const policyHolder = this.props.policyHolder
    if (policyHolder && Array.isArray(policyHolder.policies)) {
      const activePolicies = policyHolder.policies.filter((p) => this.isPolicyActive(p))
      console.log(activePolicies);
      return activePolicies;
    }
    return []
  }

  sendPolicyDocumentUpdateEmail = async () => {
    const activePolicies = this.getActivePolicies();
    if (!activePolicies || !activePolicies.length) return;

    let type = '';
    const section = this.props.section;

    if (section === 'claims') type = 'direct-sales'
    else if (section === 'direct-sales') type = 'direct-sales'
    else if (section === 'external-sales') type = 'external-sales'

    if (type && Array.isArray(activePolicies)) {
      for (const policy of activePolicies) {
        try {
          await api.post(`/${type}/policies/${policy.id}/email`, { type: 'policy-document-update' });
          const message = {
            message: `The updated policy document was emailed on the ${moment().format('Do')} of ${moment().format('MMM')} ${moment().format('YYYY')} at ${moment().format('HH:mm')}`,
            message_type: 'Email',
            person_id: this.props.loggedInPersonID,
          };
          await api.post(`/${type}/policyholders/${this.props.policyHolder.id}/timeline/message`, message);
          await api.post(`/${type}/policies/${policy.id}/timeline/message`, message);
          this.setForceRerender();
          NotificationManager.success('Woohoo! You sent policy update document email.')
        } catch (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing the page'
          )
        }
      }
    }
  };

  render() {
    const { policyHolder, loggedInPersonID } = this.props

    return !policyHolder ? (
      <div>Loading</div>
    ) : (
      <div>
        {this.props.match && <PageHeader match={this.props.match} />}
        <TimelineHeader
          user={policyHolder}
          section={this.props.section}
          sendPolicyDocumentUpdateEmail={this.getActivePolicies() && this.getActivePolicies().length ? this.sendPolicyDocumentUpdateEmail : null}
        />
        <div
          className="bg-grey pt-5 pl-4 pr-4"
          style={{ paddingBottom: '60px' }}
        >
          <div className="timeline-page-container animated fadeIn pb-6">
            <div className="timeline-page-content">
              <div className="d-flex flex-column align-self-start border-0 flex-grow-1">
                <PolicyholderCard
                  policyHolder={policyHolder}
                  activePolicyTab={this.state.activePolicyTab}
                  setActivePolicyTab={this.setActivePolicyTab}
                  section={this.props.section}
                  setForceRerender={this.setForceRerender}
                />

                {loggedInPersonID && (
                  <Timeline
                    key={this.state.forceRerender}
                    personId={policyHolder.id}
                    for={`${policyHolder.first_name} ${policyHolder.last_name}`}
                    loggedInPersonID={loggedInPersonID}
                    apiUrl={`/${this.props.section}/policyholders/${policyHolder.id}/timeline`}
                  />
                )}
              </div>

              <TimelineSidebar
                policyHolder={policyHolder}
                section={this.props.section}
                activePolicyTab={this.state.activePolicyTab}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PolicyHolderDetail.propTypes = {
  policyHolder: PropTypes.object,
}

export default connect(
  (state) => ({
    loggedInPersonID:
      state.authentication.user && state.authentication.user.person_id,
    policyHolder: state.directSales.policyHolders.policyHolder,
  }),
  {
    externalSalesFetchPolicyHolder,
    directSalesFetchPolicyHolder,
    claimsFetchPolicyHolder,
  }
)(PolicyHolderDetail)
