import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import moment from 'moment'
import renderField from '../../../../../utils/renderField'
import { numericality, required } from 'redux-form-validators'

const EditFaultDetailsForm = ({
  handleSubmit,
  onSubmit,
  onCancel,
  valid,
  ...rest
}) => {

  // const badDate = fault_date && moment(fault_date).isAfter(moment())

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col">
          <Field
            type="date"
            name="fault_date"
            style={{ width: '100%', marginBottom: 0 }}
            label="When was the fault found?"
            max={moment().format('DD-MM-YYYY')}
            required
            className="form-control"
            // className={`form-control ${badDate ? 'is-invalid' : ''}`}
            id="faultFoundDate"
            component={renderField}
            showSuccessValidation={false}
          />
          {/*{badDate && (*/}
          {/*  <div className="invalid-feedback d-block">*/}
          {/*    Fault date cannot be in the future.*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>

        <div className="col">
          <Field
            type="text"
            label="Current accurate mileage"
            component={renderField}
            name={`mileage`}
            className="form-control"
            validate={[
              required({ msg: 'You must enter the current mileage.' }),
              numericality({
                int: true,
                msg: 'Field must contain only numbers.',
              }),
            ]}
            append={{
              direction: 'right',
              text: 'miles',
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col mt-3">
          <Field
            label="Pre-diagnosis comments (will show on the repair diagnosis form)"
            component={renderField}
            className="form-control"
            name="prediagnosis"
            validate={[
              required({
                msg: 'You must enter pre-diagnosis comments.',
              }),
            ]}
            type="textarea"
            rows={4}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Field
            label="Post-diagnosis comments"
            className="form-control"
            name="postdiagnosis"
            component={renderField}
            type="textarea"
            rows={4}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Field
            name={`able_to_drive`}
            type="checkbox"
            label="Vehicle able to be driven"
            id="able_to_drive"
            component={renderField}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Field
            name={`service_required`}
            type="checkbox"
            label="Service required"
            id="service_required"
            component={renderField}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mt-4">
          <div className="form-group">
            <button
              className="btn btn-secondary"
              type="submit"
              disabled={!valid}
            >
              Save changes
            </button>
            <button
              type="button"
              className="btn btn-outline btn-form-cancel"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

EditFaultDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: 'claims/edit-fault-details',
})(EditFaultDetailsForm)
