import React from "react";
import PrivateRoute from "../components/PrivateRoute";
import { Switch } from "react-router-dom";

// Claims
import Claims from "../components/pages/Claims/Claims/Index/Claims";
import Claim from "../components/pages/Claims/Claims/Show";
import ClaimsCreate from "../components/pages/Claims/Claims/Create";

// Policies
import Policies from "../components/pages/Claims/Policies/Policies";
import Policy from '../components/pages/Claims/Policies/Show'

// Policyholders
import Policyholders from "../components/pages/Claims/Policyholders/Policyholders";
import PolicyHolderDetail from "../components/pages/PolicyHolderDetail";

// Garages
import Garages from "../components/pages/Claims/Garages/Index";
import CreateGarage from "../components/pages/Claims/Garages/Create";
import Garage from "../components/pages/Claims/Garages/Show";

// Dealers
import DealersPage from "../components/pages/Claims/Dealers";
import DealerTimeline from "../components/pages/ExternalSales/DealerTimeline/DealerTimeline";


function ClaimsRoutes() {
  return (
    <Switch>
      <PrivateRoute
        exact
        path={`/claims/policies`}
        component={Policies}
      />

      <PrivateRoute
        exact
        path={`/claims/dealers`}
        component={DealersPage}
      />

    <PrivateRoute
      exact
      path={`/claims/dealers/:id/timeline`}
      component={(props) => <DealerTimeline {...props} section="claims" />}
    />

      <PrivateRoute
        exact
        path={`/claims/policyholders`}
        component={Policyholders}
      />

      <PrivateRoute
        exact
        path={`/claims/garages`}
        component={Garages}
      />

      <PrivateRoute
        exact
        path={`/claims/garages/create`}
        component={CreateGarage}
      />

      <PrivateRoute
        exact
        path={`/claims/garages/:id`}
        component={Garage}
      />

      <PrivateRoute
        exact
        path={`/claims/claims`}
        component={Claims}
      />

      <PrivateRoute
        exact
        path={`/claims/claims/create`}
        component={ClaimsCreate}
      />

      <PrivateRoute
        exact
        path={`/claims/claims/:id/`}
        component={Claim}
      />

      <PrivateRoute
        exact
        path={`/claims/policyholders/:id/timeline`}
        component= {(props) => <PolicyHolderDetail {...props} section="claims" />}
      />

      <PrivateRoute
        exact
        path={`/claims/policies/:id/policy`}
        component={Policy}
      />
    </Switch>
  );
}

export default ClaimsRoutes;
