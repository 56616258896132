/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import RuleRow from './RuleRow'
import { connect } from 'react-redux'
import { reduxForm, isInvalid, Form } from 'redux-form'
import { get, set, sortBy } from 'lodash'
import { isPositiveFloat } from '../../../../../utils/misc'

const IndividualCommissionForm = ({
  handleSubmit,
  rules,
  handleAddNewRule,
  availableRules,
  users,
  handleDelete,
  formNotValid,
  header,
}) => {
  const getUserOptions = (users) => {
    const formattedUsers = [
      ...users.map((user) => ({
        label: user.first_name + ' ' + user.last_name,
        value: user.person_id,
      })),
    ]

    return [
      {
        label: 'Groups',
        options:
          header === 'External Sales'
            ? [
                { label: 'Account Managers', value: 'Account Managers' },
              ]
            : [
                { label: 'Direct Sales Agents', value: 'Direct Sales Agents' },
                { label: 'Direct Sales Managers', value: 'Direct Sales Managers' },
              ],
      },
      {
        label: 'Individuals',
        options: [...sortBy(formattedUsers, ['label'])],
      },
    ]
  }

  return (
    <Form onSubmit={handleSubmit}>
      <table className={'table table-striped table-individualCommission'}>
        <thead>
          <tr>
            <th style={{ width: '35%' }}>Rule (if)</th>
            <th style={{ width: '15%' }}>Value (% or &pound;)</th>
            <th style={{ width: '15%' }}>Pay commission (%)</th>
            <th>Assign to group/individual</th>
            <th style={{ width: '8%' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rules &&
            rules.map((rule, idx) => (
              <RuleRow
                key={idx}
                rule={rule}
                availableRules={availableRules}
                idx={idx}
                users={getUserOptions(users)}
                handleDelete={handleDelete}
              />
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={5}>
              <a href={`#`} onClick={handleAddNewRule}>
                Add another commission rule
              </a>
            </td>
          </tr>
        </tfoot>
      </table>
      <div style={{ marginBottom: '4rem', marginTop: '1rem' }}>
        <button
          type="submit"
          name="submit"
          className="btn btn-secondary"
          disabled={formNotValid}
        >
          Save Commission
        </button>
      </div>
    </Form>
  )
}

const validate = (values) => {
  let errors = {}
  get(values, 'rules', []).forEach((rule, idx) => {
    if (get(rule, `type.value`, '') === '') {
      set(errors, `rules.${idx}.type`, 'You must set a commission type')
    }
    //If we need a value here, i.e its % or £, then we should validate the field.
    if (
      (get(rule, `type.name`, ' ')[0] === '%' ||
        get(rule, `type.name`, ' ')[0] === '£') &&
      (parseFloat(get(rule, 'value', '')) === 0 ||
        !isPositiveFloat(get(rule, 'value', '')))
    ) {
      set(errors, `rules.${idx}.value`, 'Value must be greater than 0')
    }
    if (get(rule, `type.name`, ' ')[0] === '%' && get(rule, `value`, 0) > 100) {
      set(errors, `rules.${idx}.value`, 'Percentage must be less than 100')
    }
    if (get(rule, `type.name`, ' ')[0] === '£') {
      let value = get(rule, `value`, '')
      if (value.toString().includes(',')) {
        set(errors, `rules.${idx}.value`, 'Invalid character.')
      }
    }
    if (
      parseFloat(get(rule, 'commission', '')) === 0 ||
      !isPositiveFloat(get(rule, 'commission', ''))
    ) {
      set(
        errors,
        `rules.${idx}.commission`,
        'Percentage must be greater than 0'
      )
    }
    if (get(rule, 'commission', '0') > 100) {
      set(errors, `rules.${idx}.commission`, 'Percentage must be less than 100')
    }
    if (get(rule, `assignee.value`, '') === '') {
      set(errors, `rules.${idx}.assignee`, 'Select who this rule applies to')
    }
  })
  return errors
}

export default connect((state) => ({
  formNotValid: isInvalid('individualCommissionForm')(state),
}))(
  reduxForm({
    form: 'individualCommissionForm',
    enableReinitialize: true,
    validate,
  })(IndividualCommissionForm)
)
