/**
 * Authentication
 */
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const SET_TOKEN_CLAIMS = 'SET_TOKEN_CLAIMS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'
export const ACTIVATE_2FA = 'ACTIVATE_2FA'
export const INACTIVATE_2FA = 'INACTIVATE_2FA'
export const SET_2FA_ERROR = 'SET_2FA_ERROR'

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'

/**
 * Cover Levels
 */
export const FETCH_DEALER_COVER_LEVELS_REQUEST =
  'FETCH_DEALER_COVER_LEVELS_REQUEST'
export const FETCH_DEALER_COVER_LEVELS_SUCCESS =
  'FETCH_DEALER_COVER_LEVELS_SUCCESS'
export const FETCH_DEALER_COVER_LEVELS_FAILURE =
  'FETCH_DEALER_COVER_LEVELS_FAILURE'

export const FETCH_DEALER_COVER_LEVEL_REQUEST =
  'FETCH_DEALER_COVER_LEVEL_REQUEST'
export const FETCH_DEALER_COVER_LEVEL_SUCCESS =
  'FETCH_DEALER_COVER_LEVEL_SUCCESS'
export const FETCH_DEALER_COVER_LEVEL_FAILURE =
  'FETCH_DEALER_COVER_LEVEL_FAILURE'

export const UPDATE_DEALER_COVER_LEVEL_REQUEST =
  'UPDATE_DEALER_COVER_LEVEL_REQUEST'
export const UPDATE_DEALER_COVER_LEVEL_SUCCESS =
  'UPDATE_DEALER_COVER_LEVEL_SUCCESS'
export const UPDATE_DEALER_COVER_LEVEL_FAILURE =
  'UPDATE_DEALER_COVER_LEVEL_FAILURE'

export const REMOVE_DEALER_COVER_LEVEL_REQUEST =
  'REMOVE_DEALER_COVER_LEVEL_REQUEST'
export const REMOVE_DEALER_COVER_LEVEL_SUCCESS =
  'REMOVE_DEALER_COVER_LEVEL_SUCCESS'
export const REMOVE_DEALER_COVER_LEVEL_FAILURE =
  'REMOVE_DEALER_COVER_LEVEL_FAILURE'

/**
 * One action type for 3 cover level types (dealers, public, renewals)
 * @type {string}
 */

export const SEARCH_COVER_LEVELS = 'SEARCH_COVER_LEVELS'

/**
 * Users
 */
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

export const UPDATE_SUSPENDED_USER = 'UPDATE_SUSPENDED_USER'

export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE'

export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS'
export const ACTIVATE_USER_FAILURE = 'ACTIVATE_USER_FAILURE'

export const USERS_TABLE_ROW_ADD = 'USERS_TABLE_ROW_ADD'
export const USERS_TABLE_ROW_REMOVE = 'USERS_TABLE_ROW_REMOVE'
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

export const UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST'
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS'
export const UPDATE_USER_INFO_FAILURE = 'UPDATE_USER_INFO_FAILURE'

export const RESTORE_USER = 'RESTORE_USER'

/**
 * Users groups
 */
export const FETCH_USER_GROUPS_REQUEST = 'FETCH_USER_GROUPS_REQUEST'
export const FETCH_USER_GROUPS_SUCCESS = 'FETCH_USER_GROUPS_SUCCESS'
export const FETCH_USER_GROUPS_FAILURE = 'FETCH_USER_GROUPS_FAILURE'

export const DELETE_USER_GROUP_REQUEST = 'DELETE_USER_GROUP_REQUEST'
export const DELETE_USER_GROUP_SUCCESS = 'DELETE_USER_GROUP_SUCCESS'
export const DELETE_USER_GROUP_FAILURE = 'DELETE_USER_GROUP_FAILURE'

export const FETCH_USER_GROUP_REQUEST = 'FETCH_USER_GROUP_REQUEST'
export const FETCH_USER_GROUP_SUCCESS = 'FETCH_USER_GROUP_SUCCCESS'
export const FETCH_USER_GROUP_FAILURE = 'FETCH_USER_GROUP_FAILURE'

export const SEARCH_USER_GROUPS = 'SEARCH_USER_GROUPS'

export const DELETE_COVER_LEVEL_REQUEST = 'DELETE_COVER_LEVEL_REQUEST'
export const DELETE_COVER_LEVEL_SUCCESS = 'DELETE_COVER_LEVEL_SUCCESS'
export const DELETE_COVER_LEVEL_FAILURE = 'DELETE_COVER_LEVEL_FAILURE'

/**
 * Territories
 */

export const FETCH_TERRITORIES_REQUEST = 'FETCH_TERRITORIES_REQUEST'
export const FETCH_TERRITORIES_SUCCESS = 'FETCH_TERRITORIES_SUCCESS'
export const FETCH_TERRITORIES_FAILURE = 'FETCH_TERRITORIES_FAILURE'

export const DELETE_TERRITORY_SUCCESS = 'DELETE_TERRITORY_SUCCESS'
export const DELETE_TERRITORY_FAILURE = 'DELETE_TERRITORY_FAILURE'

export const TERRITORIES_TABLE_ROW_ADD = 'TERRITORIES_TABLE_ROW_ADD'
export const TERRITORIES_TABLE_ROW_REMOVE = 'TERRITORIES_TABLE_ROW_REMOVE'

export const ADD_ACCOUNT_MANAGER = 'ADD_ACCOUNT_MANAGER'

/**
 * Territory
 * @type {string}
 */
export const FETCH_TERRITORY_REQUEST = 'FETCH_TERRITORY_REQUEST'
export const FETCH_TERRITORY_SUCCESS = 'FETCH_TERRITORY_SUCCESS'
export const FETCH_TERRITORY_FAILURE = 'FETCH_TERRITORY_FAILURE'

export const UPDATE_TERRITORY_REQUEST = 'UPDATE_TERRITORY_REQUEST'
export const UPDATE_TERRITORY_SUCCESS = 'UPDATE_TERRITORY_SUCCESS'
export const UPDATE_TERRITORY_FAILURE = 'UPDATE_TERRITORY_FAILURE'

export const FETCH_PUBLIC_COVER_LEVELS_REQUEST =
  'FETCH_PUBLIC_COVER_LEVELS_REQUEST'
export const FETCH_PUBLIC_COVER_LEVELS_SUCCESS =
  'FETCH_PUBLIC_COVER_LEVELS_SUCCESS'
export const FETCH_PUBLIC_COVER_LEVELS_FAILURE =
  'FETCH_PUBLIC_COVER_LEVELS_FAILURE'

export const FETCH_PUBLIC_COVER_LEVEL_REQUEST =
  'FETCH_PUBLIC_COVER_LEVEL_REQUEST'
export const FETCH_PUBLIC_COVER_LEVEL_SUCCESS =
  'FETCH_PUBLIC_COVER_LEVEL_SUCCESS'
export const FETCH_PUBLIC_COVER_LEVEL_FAILURE =
  'FETCH_PUBLIC_COVER_LEVEL_FAILURE'

export const UPDATE_PUBLIC_COVER_LEVEL_REQUEST =
  'UPDATE_PUBLIC_COVER_LEVEL_REQUEST'
export const UPDATE_PUBLIC_COVER_LEVEL_SUCCESS =
  'UPDATE_PUBLIC_COVER_LEVEL_SUCCESS'
export const UPDATE_PUBLIC_COVER_LEVEL_FAILURE =
  'UPDATE_PUBLIC_COVER_LEVEL_FAILURE'

export const REMOVE_PUBLIC_COVER_LEVEL_REQUEST =
  'REMOVE_PUBLIC_COVER_LEVEL_REQUEST'
export const REMOVE_PUBLIC_COVER_LEVEL_SUCCESS =
  'REMOVE_PUBLIC_COVER_LEVEL_SUCCESS'
export const REMOVE_PUBLIC_COVER_LEVEL_FAILURE =
  'REMOVE_PUBLIC_COVER_LEVEL_FAILURE'

export const FETCH_DIRECT_SALES_COVER_LEVELS_REQUEST =
  'FETCH_DIRECT_SALES_COVER_LEVELS_REQUEST'
export const FETCH_DIRECT_SALES_COVER_LEVELS_SUCCESS =
  'FETCH_DIRECT_SALES_COVER_LEVELS_SUCCESS'
export const FETCH_DIRECT_SALES_COVER_LEVELS_FAILURE =
  'FETCH_DIRECT_SALES_COVER_LEVELS_FAILURE'

export const DELETE_DIRECT_SALES_COVER_LEVEL_REQUEST =
  'DELETE_DIRECT_SALES_COVER_LEVEL_REQUEST'
export const DELETE_DIRECT_SALES_COVER_LEVEL_SUCCESS =
  'DELETE_DIRECT_SALES_COVER_LEVEL_SUCCESS'
export const DELETE_DIRECT_SALES_COVER_LEVEL_FAILURE =
  'DELETE_DIRECT_SALES_COVER_LEVEL_FAILURE'

export const UPDATE_DIRECT_SALES_COVER_LEVEL_REQUEST =
  'UPDATE_DIRECT_SALES_COVER_LEVEL_REQUEST'
export const UPDATE_DIRECT_SALES_COVER_LEVEL_FAILURE =
  'UPDATE_DIRECT_SALES_COVER_LEVEL_FAILURE'
export const UPDATE_DIRECT_SALES_COVER_LEVEL_SUCCESS =
  'UPDATE_DIRECT_SALES_COVER_LEVEL_SUCCESS'

export const FETCH_DIRECT_SALES_COVER_LEVEL_REQUEST =
  'FETCH_DIRECT_SALES_COVER_LEVEL_REQUEST'
export const FETCH_DIRECT_SALES_COVER_LEVEL_SUCCESS =
  'FETCH_DIRECT_SALES_COVER_LEVEL_SUCCESS'
export const FETCH_DIRECT_SALES_COVER_LEVEL_FAILURE =
  'FETCH_DIRECT_SALES_COVER_LEVEL_FAILURE'

/**
 * Direct sales - Renewals
 */
export const FETCH_RENEWALS_REQUEST = 'FETCH_RENEWALS_REQUEST'
export const FETCH_RENEWALS_SUCCESS = 'FETCH_RENEWALS_SUCCESS'
export const FETCH_RENEWALS_FAILURE = 'FETCH_RENEWALS_FAILURE'

export const RENEWALS_TABLE_ROW_ADD = 'RENEWALS_TABLE_ROW_ADD'
export const RENEWALS_TABLE_ROW_REMOVE = 'RENEWALS_TABLE_ROW_REMOVE'

export const DELETE_RENEWALS_REQUEST = 'DELETE_RENEWALS_REQUEST'
export const DELETE_RENEWALS_SUCCESS = 'DELETE_RENEWALS_SUCCESS'
export const DELETE_RENEWALS_FAILURE = 'DELETE_RENEWALS_FAILURE'

export const RESTORE_RENEWALS_REQUEST = 'RESTORE_RENEWALS_REQUEST'
export const RESTORE_RENEWALS_SUCCESS = 'RESTORE_RENEWALS_SUCCESS'
export const RESTORE_RENEWALS_FAILURE = 'RESTORE_RENEWALS_FAILURE'

export const ASSIGN_RENEWALS_REQUEST = 'ASSIGN_RENEWALS_REQUEST'
export const ASSIGN_RENEWALS_SUCCESS = 'ASSIGN_RENEWALS_SUCCESS'
export const ASSIGN_RENEWALS_FAILURE = 'ASSIGN_RENEWALS_FAILURE'

/**
 * Pipelines
 */
export const FETCH_PIPELINE_DATA_REQUEST = 'FETCH_PIPELINE_DATA_REQUEST'
export const FETCH_PIPELINE_DATA_SUCCESS = 'FETCH_PIPELINE_DATA_SUCCESS'
export const FETCH_PIPELINE_DATA_FAILURE = 'FETCH_PIPELINE_DATA_FAILURE'

export const PIPELINE_UPDATE_CARD_POSITION = 'PIPELINE_UPDATE_CARD_POSITION'

/**
 * Policyholders
 */
export const FETCH_PH_REQUEST = 'FETCH_PH_REQUEST'
export const FETCH_PH_SUCCESS = 'FETCH_PH_SUCCESS'
export const FETCH_PH_FAILURE = 'FETCH_PH_FAILURE'

export const SEARCH_PH = 'SEARCH_PH'

export const FETCH_SINGLE_PH_REQUEST = 'FETCH_SINGLE_PH_REQUEST'
export const FETCH_SINGLE_PH_SUCCESS = 'FETCH_SINGLE_PH_SUCCESS'
export const FETCH_SINGLE_PH_FAILURE = 'FETCH_SINGLE_PH_FAILURE'

export const UPDATE_PH_CONTACT_DETAILS_SUCCESS = 'UPDATE_PH_CONTACT_DETAILS_SUCCESS'


/**
 * Price bands
 */

export const FETCH_PRICE_BANDS_REQUEST = 'FETCH_PRICE_BANDS_REQUEST'
export const FETCH_PRICE_BANDS_SUCCESS = 'FETCH_PRICE_BANDS_SUCCESS'
export const FETCH_PRICE_BANDS_FAILURE = 'FETCH_PRICE_BANDS_FAILURE'

export const SEARCH_PRICE_BANDS = 'FETCH_PRICE_BANDS_FAILURE'

export const FETCH_SINGLE_PRICE_BAND_REQUEST = 'FETCH_SINGLE_PRICE_BAND_REQUEST'
export const FETCH_SINGLE_PRICE_BAND_SUCCESS = 'FETCH_SINGLE_PRICE_BAND_SUCCCESS'
export const FETCH_SINGLE_PRICE_BAND_FAILURE = 'FETCH_SINGLE_PRICE_BAND_FAILURE'

export const ADD_PRICE_BAND_TO_TABLE = 'ADD_PRICE_BAND_TO_TABLE'

export const CLAIM_PAYEE_TYPES = {
  REPAIRING_GARAGE: 'Repairing garage',
  DEALER: 'Dealer',
  POLICYHOLDER: 'Policyholder'
}
