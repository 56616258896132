import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const InfoModal = ({ isShowing, hide, children, style, fromTop}) => isShowing ? ReactDOM.createPortal(

  <React.Fragment>
    <div className="wf-modal-overlay">
      <div className="wf-modal" style={style}>
        <div className="wf-modal-body">
          <span className="btn-close-modal fa fa-close" onClick={hide}/>
          <div className="mb-3">
            {children}
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>, document.querySelector('#root-modal'),
) : null

InfoModal.propTypes = {
  hide     : PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
}

InfoModal.defaultProps = {
}

export default InfoModal