import moment from 'moment';
import React, { useEffect, useState } from 'react';
import api from '../../../../utils/api';
import CustomSelect from '../../../ui/CustomSelect';
import { Field, Form, reduxForm } from 'redux-form';
import { times } from 'lodash';
import { echoMoney } from '../../../../utils/misc';

// default to the current year
const defaultYear = moment().year()

function DealerDashboard({
  dealer,
  isForDealerPortal = false,
  setDashboardData,
}) {

  // show years since the dealer account was activated, plus the next 2 years
  const baseYear = dealer.active_start_date ? moment(dealer.active_start_date).year() : 2018
  const toYear = moment().add('2', 'year').year()
  let yearOptions = []
  for (let i = baseYear; i <= toYear; i++) {
    yearOptions.push({
      year: i,
    })
  }

  const [selectedYear, setSelectedYear] = useState(defaultYear)
  const [targets, setTargets] = useState([])
  const [sales, setSales] = useState([])
  const [actuals, setActuals] = useState([])

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData()
  }

  const fetchData = () => {
    const uri = isForDealerPortal ? `dealer-portal/dashboard` : `external-sales/dealers/${dealer.id}/dashboard`
    api.get(`${uri}?year=${selectedYear}`).then(response => {
      setTargets(response.data.targets)
      setSales(response.data.sales)
    })
  }

  useEffect(() => {
    fetchData() // run once on page load
  }, [])

  useEffect(() => {
    // calculate the actuals as total sales for each month
    let tmpActuals = []
    times(12, (i) => {
      tmpActuals[i] = {
        month: i + 1,
        value: 0.00,
      };
      sales.forEach((person) => {
        tmpActuals[i].value += parseFloat(person.sales[i].value);
      });
    })
    setActuals(tmpActuals)
    if (setDashboardData) {
      setDashboardData({
        targets,
        actuals: tmpActuals,
      })
    }
  }, [sales])

  const getVariance = (i) => {
    return targets[i] && actuals[i] ? actuals[i].value - targets[i].value : 0;
  }

  const getVarianceClass = (percentage) => {
    const styles = ['label'];
    if (percentage >= 90 && percentage < 100) {
      styles.push('text-yellow');
    } else if (percentage >= 100) {
      styles.push('text-green');
    } else {
      styles.push('text-red');
    }
    return styles.join(' ');
  }

  const getVariancePercentage = (i) => {
    return targets[i] && actuals[i] ? (actuals[i].value * 100 / targets[i].value) : 0;
  }

  const getTotalTarget = () => {
    return targets.map(a => parseFloat(a.value)).reduce((partialSum, a) => partialSum + a, 0);
  }

  const getTotalActual = () => {
    return actuals.map(a => parseFloat(a.value)).reduce((partialSum, a) => partialSum + a, 0);
  }

  /**
   * The projected profit at the end of the year
   */
  const getProjection = () => {
    const perDay = getTotalActual() / moment().dayOfYear();
    return perDay * 365;
  }

  const getTotalVariance = () => {
    return getTotalActual() - getTotalTarget();
  }

  const getTotalVariancePercentage = () => {
    return getTotalActual() * 100 / getTotalTarget();
  }

  const getTotalSales = (person) => {
    return person.sales.map(a => parseFloat(a.value)).reduce((partialSum, a) => partialSum + a, 0);
  }

  const getGrandTotalSales = () => {
    let grandTotal = 0.00;
    sales.forEach((person) => {
      grandTotal += getTotalSales(person)
    })
    return grandTotal
  }

  return (
    dealer && (
      <div className={`dealer-dashboard bg-white shadow-dark ${isForDealerPortal ? 'fullscreen' : ''}`}>
        <div className="container-fluid no-gutters p-0">
          <Form
            onSubmit={handleSubmit}
            noValidate
          >
            <div className="row">
              <div className="col-8">
                <h6 className="h6 fw-600 mb-0 text-uppercase font-sm">Performance Tracker</h6>
              </div>
              <div className="col-4 d-flex justify-content-end">
                <div className="col-8">
                  <Field
                    component={CustomSelect}
                    name="year"
                    options={yearOptions}
                    getOptionLabel={(option) => option.year}
                    getOptionValue={(option) => option.year}
                    onChange={(v) => setSelectedYear(v.year)}
                  />
                </div>
                <div>
                  <button type="submit" className="btn btn-secondary btn-sm font-sm">Apply</button>
                </div>
              </div>
            </div>

            <div className="row mt-2 mb-4">
              <div className="col">
                <div style={{ overflowX: 'scroll' }}>
                  <table className='table table-striped-inverse table-bordered'>
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                        {times(12, (idx) => {
                          return (
                            <td key={idx}>
                              <label className={'label'}>
                                {moment().month(idx).format('MMM')}
                              </label>
                            </td>
                          )
                        })}
                        <td><label className={'label'}>Total</label></td>
                      </tr>
                      <tr>
                        <td><label className={'label'}>Budget</label></td>
                        {times(12, (idx) => {
                          return (
                            <td key={idx}>
                              {targets[idx] && echoMoney(targets[idx].value)}
                            </td>
                          )
                        })}
                        <td>
                          <label className={'label'}>{echoMoney(getTotalTarget())}</label>
                          <p>Total Budget</p>
                        </td>
                      </tr>
                      <tr>
                        <td><label className={'label'}>Actual</label></td>
                        {times(12, (idx) => {
                          return (
                            <td key={idx}>
                              {actuals[idx] && echoMoney(actuals[idx].value)}
                            </td>
                          )
                        })}
                        <td>
                          <label className={'label'}>{echoMoney(getProjection())}</label>
                          <p>Projection</p>
                        </td>
                      </tr>
                      <tr className='variance-row'>
                        <td><label className={'label'}>Variance</label></td>
                        {times(12, (idx) => {
                          return (
                            <td key={idx}>
                              <label className={getVarianceClass(getVariancePercentage(idx))}>{getVariance(idx) && echoMoney(getVariance(idx))}</label>
                            </td>
                          )
                        })}
                        <td>
                          <label className={getVarianceClass(getTotalVariancePercentage())}>{echoMoney(getTotalVariance())}</label>
                          <p>Variance to Date</p>
                        </td>
                      </tr>
                      {sales.map((person, i) => {
                        return (
                          <tr key={i}>
                            <td><label className={'label'}>{person.name}</label></td>
                            {times(12, (idx) => {
                              return (
                                <td key={idx}>
                                  {person.sales[idx] && echoMoney(person.sales[idx].value)}
                                </td>
                              )
                            })}
                            <td>
                              <label className='label'>{echoMoney(getTotalSales(person))}</label>
                            </td>
                          </tr>
                        )
                      })}
                      <tr>
                        <td colSpan={13} className='text-right'>YTD TOTAL INCOME</td>
                        <td>
                          <label className={'label'}>{echoMoney(getGrandTotalSales())}</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <ul className="list-info mb-4 font-md">
                  <li>Prices exclude VAT</li>
                </ul>
              </div>
            </div>

          </Form>

        </div>

      </div>
    )
  );
}

DealerDashboard.propTypes = {

};

export default reduxForm({
  form: 'dealerDashboard',
  initialValues: { year: { year: defaultYear } },
})(DealerDashboard)
