import React from 'react'
import PropTypes from 'prop-types'
import { echo, formatDate, formatExcess, money } from '../../../../../utils/misc'
import { PUBLIC_SITE_URL } from '../../../../../utils/constants';

export const PolicyDetails = ({ policy, showWhatsCoveredLink }) => {
  const { cover_level } = policy

  return (
    policy &&
    <div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <h6 className="my-5 text-uppercase">
            Policy details
          </h6>
        </div>
      </div>

      <div className="row">
       <div className="col">
          <span className="label">Policy ID</span>
          <p className="font-md">{echo(policy.ref)}</p>
        </div>
        <div className="col">
          <span className="label">Cover level</span>
          <p className="font-md">{echo(cover_level.name)}</p>
        </div>
        <div className="col">
          <span className="label">Duration</span>
          <p className="font-md">{echo(policy.duration)}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          <span className="label">Start date</span>
          <p className="font-md">{formatDate(policy.start_date)}</p>
        </div>
        <div className="col-4">
          <span className="label">End date</span>
          <p className="font-md">{formatDate(policy.end_date)}</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <span className="label">Claim limit</span>
          <p className="font-md">{money.format(policy.claim_limit)}</p>
        </div>
        <div className="col">
          <span className="label">Labour rate</span>
          <p className="font-md">{money.format(policy.labour_rate)}</p>
        </div>
        <div className="col">
          <span className="label">Excess</span>
          {formatExcess(policy.excess_type, policy.excess)}
        </div>
      </div>

      {showWhatsCoveredLink &&
      <div className="row mt-4">
        <div className="col">
          <a href={`${PUBLIC_SITE_URL}/our-plans`} target="_new">What's covered</a>
        </div>
      </div>
      }

    </div>
  )
}

PolicyDetails.defaultProps = {
  showWhatsCoveredLink: true,
}

PolicyDetails.propTypes = {
  policy: PropTypes.object.isRequired,
}

export default PolicyDetails