import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import EditCoverLevelForm from './EditCoverLevelForm'
import { connect } from 'react-redux'
import {
  fetchDealerCoverLevel,
  resetForm,
  updateDealerCoverLevel,
  deleteDealerCoverLevel,
} from '../../../actions/cover-levels.actions'
import { isValid } from 'redux-form'
import PropTypes from 'prop-types'
import Table from '../../ui/Table'
import { sortCaret } from '../../../utils/misc'
import ReactTooltip from 'react-tooltip'
import api from '../../../utils/api'
import { confirmAlert } from 'react-confirm-alert'
import { NotificationManager } from 'react-notifications'
import CreateClaimLimitForm from './CreateClaimLimitForm'
import { history } from '../../../utils/history'

class CoverLevelEditPage extends Component {
  state = {
    claimLimits: [],
    searchingClaimLimits: false,
    claimLimitSearchQuery: '',
    columns: [
      {
        dataField: 'claim_limit',
        text: 'Limits',
        sort: true,
        sortCaret,
      },
      {
        dataField: 'id',
        text: 'Actions',
        headerStyle: {
          width: 100,
        },
        formatter: (cell, row) => (
          <Fragment>
            <Link
              data-tip="Delete claim limit"
              data-place="top"
              to={`#`}
              className="table-action-btn table-action-btn--delete"
              size="sm"
              onClick={() => this.handleDelete(row)}
            >
              <span className="icon-trash"/>
            </Link>
            <ReactTooltip effect="solid"/>
          </Fragment>
        ),
      },
    ],
    selected: [],
  }

  componentDidMount() {
    const cid = this.props.match.params.id

    if (!isNaN(Number(cid))) {
      this.props.fetchDealerCoverLevel(cid)
      this.fetchClaimLimits(cid)
    } else {
      this.props.history.push('/404')
    }
  }

  handleCheckboxChange = (e, rowIndex, row) => {
    const claimLimitID = this.state.claimLimits.data[rowIndex].id
    if (row.checked) {
      this.setState(prevState => ({
        selected: [...prevState.selected, claimLimitID],
      }))
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== claimLimitID),
      }))
    }
  }

  handleRowSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id)
    if (isSelect) {
      this.setState(() => ({
        selected: ids,
      }))
    } else {
      this.setState(() => ({
        selected: [],
      }))
    }
  }
  handleRowSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row.id],
      }))
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== row.id),
      }))
    }
  }

  /**
   * Return array of claim limit prices
   * @param ids
   */
  getClaimLimitValues = ids => {
    // eslint-disable-next-line array-callback-return
    return this.state.claimLimits.data.map((item, i) => {
      if (item.claim_limit === ids[i]) {
        return item.claim_limit
      }
    })
  }

  showDeleteModal = () => {
    const selected = this.state.selected
    const multipleClaims = selected.length > 1 && (
      <p>Delete <strong>{selected.length}</strong> claim limits? They'll be lost forever.</p>)
    const singleClaim = selected.length === 1 && (
      <p>Delete <strong>{this.getClaimLimitValues(selected)} claim limits? They'll be lost forever.</strong></p>)

    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({title, message, onClose}) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
                    <span
                      className="btn-close-modal fa fa-close"
                      onClick={onClose}
                    />
              <h3>{title}</h3>

              {singleClaim}
              {multipleClaims}
              <button
                className="btn btn-secondary"
                onClick={() => {
                  this.deleteMultiple()
                  onClose()
                }}
              >
                Yes, I'm sure
              </button>

              <button
                type="button"
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  fetchClaimLimits = coverLevelId => {
    api.get(`/admin/cover-levels/${coverLevelId}/claim-limits`)
      .then(res => {
        this.setState({claimLimits: res.data})
      })
  }

  /**
   * Loops through all selected rows (claim limit IDs)
   * Matches the id & returns array with claim_limit values
   * @returns {Array}
   */
  getClaimLimitValuesFromIds = () => {
    let ids = []

    for (let i = 0; i < this.state.selected.length; i++)
      for (let j = 0; j < this.state.claimLimits.data.length; j++)
        if (this.state.claimLimits.data[j].id === this.state.selected[i])
          ids.push(this.state.claimLimits.data[j].claim_limit)

    return ids
  }

  deleteMultiple = () => {

    let claim_limits = this.getClaimLimitValuesFromIds()

    api.patch(`admin/cover-levels/${this.props.coverLevel.id}/claim-limits/`, {claim_limits: claim_limits.toString()})
      .then(res => {
        this.fetchClaimLimits(this.props.match.params.id)
        NotificationManager.success(`Woohoo! You deleted ${claim_limits.length} claim limits.`)
      })
      .catch(err => {
        NotificationManager.error('Oops! Could not delete the claim limits.')
      })
  }

  cancel = e => {
    e.preventDefault()
    this.props.resetForm('dealer-cover-levels/edit')
    this.props.history.push('/admin/dealer-cover-levels')
  }

  handleDelete = claimLimit => {
    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({title, onClose}) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose}/>
              <h3>{title}</h3>
              <p>
                Delete the claim limit <strong>£{claimLimit.claim_limit}</strong>? It'll be lost
                forever.
              </p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  this.deleteClaimLimit(claimLimit)
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  handleClaimLimitSearch = (val, params) => {
    this.setState({
      searchingClaimLimits: true,
      claimLimitSearchQuery: val,
    })

    api.get(`/admin/cover-levels/${this.props.match.params.id}/claim-limits?query=${val}${params ? params : ''}`)
      .then(res => {
        this.setState({claimLimits: res.data, searchingClaimLimits: false})
      })
  }

  updateClaimLimits = claimLimits => this.setState({claimLimits})

  handleTableChange = (type, {sortField, sortOrder, page, sizePerPage}) => {
    let url = `&page=${page}&desc=${
      sortOrder === 'desc' ? true : false
      }&limit=${sizePerPage}`
    if (sortField) url = `${url}&order=${sortField}`

    this.handleClaimLimitSearch(this.state.claimLimitSearchQuery, url)
  }


  addClaimLimitModal = () => {
    return confirmAlert({
      title: 'Add claim limits',
      customUI: ({title, onClose}) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body" style={{width: '40rem'}}>
              <span className="btn-close-modal fa fa-close" onClick={onClose}/>
              <h3 className="mb-0">{title}</h3>
              <h4 className="text-black-50 font-weight-normal text-capitalize mb-4"
                  style={{fontSize: '1.12rem'}}>{this.props.coverLevel.name}</h4>
              <CreateClaimLimitForm coverLevel={this.props.coverLevel} onClose={onClose}
                                    updateClaimLimits={this.updateClaimLimits}
                                    fetchClaimLimits={this.fetchClaimLimits}/>
            </div>
          </div>
        )
      },
    })
  }

  deleteDealerCoverLevel = id => {
    return api
      .delete(`admin/cover-levels/${id}`)
      .then(res => {
        NotificationManager.success('Woohoo! You deleted a cover level.')
        history.push('/admin/dealer-cover-levels')
      })
      .catch(err => {
        NotificationManager.error('Oops! Could not delete the cover level.')
      })
  }

  handleDeleteCoverLevel = () => {
    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({title, onClose}) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose}/>
              <h3>{title}</h3>
              <p>
                Delete the cover level <strong>{this.props.coverLevel.name}</strong>? It'll be lost
                forever.
              </p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  this.deleteDealerCoverLevel(this.props.coverLevel.id)
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  render() {
    const {error, isPending} = this.props
    const {columns, claimLimits, searchingClaimLimits} = this.state

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      selected: this.state.selected,
      onSelect: this.handleRowSelect,
      onSelectAll: this.handleRowSelectAll,
      selectionRenderer: ({mode, rowIndex, ...rest}) => {
        return (
          <div className="text-center lh-1 position-relative align-checkbox">
            <div className="custom-control custom-checkbox custom-control-inline">
              <input
                className="custom-control-input"
                type={mode}
                rowindex={rowIndex}
                onChange={e => this.handleCheckboxChange(e, rowIndex, rest)}
                style={{width: 10, height: 10}}
                id={`checkbox-${rowIndex}`}
                {...rest}
              />
              <label
                htmlFor={`checkbox-${rowIndex}`}
                className="custom-control-label"
              />
            </div>
          </div>
        )
      },

      selectionHeaderRenderer: ({mode, checked, indeterminate, ...rest}) => {
        return (
          <div className="text-center lh-1 position-relative align-checkbox">
            <div className="custom-control custom-checkbox custom-control-inline">
              <input
                className="custom-control-input"
                type={mode}
                checked={checked}
                onChange={this.handleCheckboxChange}
                {...rest}
              />
              <label className="custom-control-label"/>
            </div>
          </div>
        )
      },
    }

    return isPending ? (
      <div>Loading</div>
    ) : (
      <Fragment>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin/dealer-cover-levels" className="open active">
              Dealer cover levels
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">Edit a cover level</span>
          </li>
        </ol>

        <div className="container-fluid animated fadeIn">
          <div className="mw-37-5rem mx-auto">
            {error && error.message && (
              <div className="alert alert-danger">{error.message}</div>
            )}

            <EditCoverLevelForm
              onSubmit={this.props.updateDealerCoverLevel}
              valid={this.props.valid}
              isPending={this.props.isPending}
              cancel={this.cancel}
              handleDelete={this.handleDeleteCoverLevel}
            />

            <div className="row">
              <div className="col" style={{marginTop: '2.1rem'}}>
                <hr style={{marginBottom: '3.1rem'}}/>
                <h6 className="title-xs mb-4">Claim limits</h6>

                <Link to={'#'} className="font-md" style={{marginBottom: '35px', display: 'block'}}
                      onClick={this.addClaimLimitModal}>Add claim limits</Link>
                {claimLimits && claimLimits.data &&
                <Table
                  handleTableChange={this.handleTableChange}
                  onSearch={this.handleClaimLimitSearch}
                  searching={searchingClaimLimits}
                  resource={claimLimits}
                  columns={columns}
                  selectRow={selectRow}
                  deleteMultiple={this.deleteMultiple}
                  tableActions={[
                    {
                      iconClass: 'icon-trash font-lg text-danger',
                      callback: this.showDeleteModal,
                      tooltip: {
                        text: 'Delete multiple claim limits',
                        place: 'top',
                      },
                    },
                  ]}
                />}
              </div>
            </div>

          </div>
        </div>
      </Fragment>
    )
  }

  deleteClaimLimit = row => {
    api.delete(`/admin/cover-levels/${row.cover_level_id}/claim-limits/${row.claim_limit}`)
      .then(res => {
        NotificationManager.success('Woohoo! You deleted a claim limit.')
        this.setState(prevState => ({
          claimLimits: {
            ...prevState.claimLimits,
            data: prevState.claimLimits.data.filter(claimLimit => claimLimit.claim_limit !== row.claim_limit),
          },
        }))
      })
      .catch(err => {
        NotificationManager.error('Oops! Could not delete the claim limit.')
      })
  }
}

const mapStateToProps = state => ({
  isPending: state.dealerCoverLevels.isPending,
  valid: isValid('dealer-cover-levels/edit')(state),
  coverLevel: state.dealerCoverLevels.singleCoverLevel,
})

CoverLevelEditPage.propTypes = {
  isPending: PropTypes.bool.isRequired,
  // coverLevel: PropTypes.object.singleCoverLevel,
  valid: PropTypes.bool.isRequired,
}

export default connect(
  mapStateToProps,
  {fetchDealerCoverLevel, resetForm, updateDealerCoverLevel, deleteDealerCoverLevel},
)(CoverLevelEditPage)
