import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker/es'
import { selectInputStyles } from '../../../utils/misc'
import moment from 'moment'
import Select from 'react-select'
import { NotificationManager } from 'react-notifications'

const AddAccountManager = props => {
  const newAccManagerRef = React.useRef(null)
  const [errors, setErrors] = React.useState()
  const [accountManagers, setAccountManagers] = React.useState([])

  const [form, setForm] = React.useState({
    isValid: null,
    account_managers: [],
    fields: {
      current_end_date: null,
      new_account_manager: null,
      start_date: null,
      end_date: null,
    },
  })

  React.useEffect(() => {
    props
      .getAccountManagers(form.new_account_manager)
      .then(data => {
        setAccountManagers(data)
      })
      .catch(err => setAccountManagers([]))
  }, [])

  React.useEffect(() => {
    const { new_account_manager, start_date, end_date } = form.fields
    if (new_account_manager && start_date && end_date)
      setForm({ ...form, isValid: true })
  })

  const onSubmit = e => {
    e.preventDefault()

    let data = {}

    if (props.territory.current_account_manager) {
      setForm({ ...form, isValid: false })
    }

    if (form.current_end_date) {
      data.current_end_date = moment(form.current_end_date).format('YYYY-MM-DD')
    }

    data.territory_id = props.territory && props.territory.id
    data.end_date = form.end_date && moment(form.end_date).format('YYYY-MM-DD')
    data.start_date =
      form.start_date && moment(form.start_date).format('YYYY-MM-DD')
    data.person_id = form.new_account_manager.id

    props
      .handleSubmit(data)
      .then(() => {
        NotificationManager.success('Woohoo! You added an account manager.')
        props.onClose()
      })
      .catch(err => {
        if (err && err.status === 422) {
          setErrors({ ...err.data.errors })
          return
        }
        NotificationManager.error(
          'Oops! There was a problem submitting your form. Please try again.',
        )
      })
  }

  let datePickerProps = {
    placeholderText: 'DD/MM/YYYY',
    dateFormat: 'dd/MM/yyyy',
    peekNextMonth: true,
    showMonthDropdown: true,
    showYearDropdown: true,
    yearDropdownItemNumber: 3,
  }

  const {
    territory: { current_account_manager },
  } = props

  // eslint-disable-next-line no-mixed-operators
  const disableButton =
    !form.new_account_manager ||
    !form.start_date ||
    (props.territory.current_account_manager && !form.current_end_date)

  return (
    <form onSubmit={onSubmit} noValidate className="mt-1">
      {current_account_manager && (
        <>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="startDate">Current Account Manager</label>
                <input
                  type="text"
                  className="form-control"
                  name="current_account_manager"
                  value={current_account_manager}
                  disabled={current_account_manager}
                  autoComplete={'off'}
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <label htmlFor="endDate">Account Manager end date</label>
                <div className="input-group d-flex flex-nowrap">
                  <span className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icon-calendar" />
                    </span>
                  </span>
                  <DatePicker
                    {...datePickerProps}
                    selected={form.current_end_date}
                    name={'current_end_date'}
                    autoComplete={'off'}
                    className={`form-control w-225`}
                    onChange={val =>
                      setForm({
                        ...form,
                        current_end_date: val,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
        </>
      )}

      <div className="row">
        <div className="col-6 mt-3">
          <div className="form-group">
            <label htmlFor="newAccountManager">New Account Manager</label>
            <Select
              styles={selectInputStyles}
              onChange={value =>
                setForm({ ...form, new_account_manager: value })
              }
              getOptionLabel={option =>
                `${option.first_name} ${option.last_name}`
              }
              getOptionValue={option => option.id}
              /** Filter out the current AM to avoid it showing in the list **/
              options={(accountManagers || []).filter((am) => (`${am.first_name} ${am.last_name}` !== current_account_manager))}
              ref={newAccManagerRef}
              name={'new_account_manager'}
            />

            {errors && errors.new_account_manager && (
              <div className="invalid-feedback d-block">
                {errors.new_account_manager}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="accManagerStartDate">
              Account Manager start date
            </label>
            <div className="input-group d-flex flex-nowrap">
              <span className="input-group-prepend">
                <span className="input-group-text">
                  <i className="icon-calendar" />
                </span>
              </span>
              <DatePicker
                {...datePickerProps}
                selected={form.start_date}
                className="form-control w-225"
                name={'start_date'}
                autoComplete={'off'}
                onChange={val =>
                  setForm({
                    ...form,
                    start_date: val,
                  })
                }
                minDate={form.current_end_date}
              />

              {errors && errors.start_date && (
                <div className="invalid-feedback d-block">
                  {errors.start_date}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="accManagerStartDate">
              Account Manager end date (optional)
            </label>
            <div className="input-group d-flex flex-nowrap">
              <span className="input-group-prepend">
                <span className="input-group-text">
                  <i className="icon-calendar" />
                </span>
              </span>
              <DatePicker
                {...datePickerProps}
                className="form-control w-225"
                selected={form.end_date}
                name={'end_date'}
                autoComplete={'off'}
                onChange={val =>
                  setForm({
                    ...form,
                    end_date: val,
                  })
                }
                minDate={form.start_date}
              />

              {errors && errors.end_date && (
                <div className="invalid-feedback d-block">
                  {errors.end_date}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group mt-3">
            <button className="btn btn-secondary" disabled={disableButton}>
              Assign Account Manager
            </button>
            <button
              onClick={props.onClose}
              className="btn btn-outline"
              style={{ color: '#70b725' }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

AddAccountManager.propTypes = {
  getAccountManagers: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  territory: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AddAccountManager
