import { PermissionListItem } from './../../ui/PermissionListItem';
import React from 'react'
import renderField from '../../../utils/renderField'
import { Field, Form, reduxForm } from 'redux-form'
import ColorPicker from '../../ui/ColorPicker'
import { history } from '../../../utils/history';
import { colorPickerSwatches } from '../../../utils/misc';

const validate = fields => {
  let errors = {}
  if (fields && !fields.name) errors.name = 'Please give this group a name.'

  if (fields && fields.name && fields.name.length === 1)
    errors.name = 'Group name is too short.'

  if (fields && fields.name && fields.name.length > 30)
    errors.name = 'Group name is too long.'

  return errors
}

const UserGroupForm = ({
  allPermissions,
  permissions,
  handleChange,
  onSubmit,
  handleSubmit,
  change,
  valid,
  selectAll,
  deselect,
}) => {
  const handleColorChange = color => {
    change('color', color)
  }

  const cancel = e => {
    e.preventDefault()

    history.push('/admin/user-groups')
  }
  React.useEffect(() => {
    change('permissions', permissions)
  }, [permissions])

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="form-user-group-create">
      <div className="row">
        <div className="col-6">
          <Field
            component={renderField}
            type="text"
            label="Group name"
            placeholder="e.g. Sales team"
            className="form-control"
            name="name"
          />
        </div>
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="groupColor">Group color (used in tables)</label>
            <Field
              component={ColorPicker}
              onColorChange={handleColorChange}
              colors={colorPickerSwatches}
              name="color"
            />
          </div>
        </div>
      </div>

      <h4 className="font-sm fw-600 text-capitalize mt-3 mb-3">
        Access rights
      </h4>

      {allPermissions &&
        Object.keys(allPermissions).map((key, i) => {
          return (
            <PermissionListItem
              key={i}
              name={key}
              allPermissions={allPermissions}
              selectAll={selectAll}
              handleChange={handleChange}
              permissions={permissions}
              deselect={deselect}
            />
          )
        })}

      <div className="form-group mt-3">
        <button
          disabled={!valid || permissions.length === 0}
          className="btn btn-secondary"
        >
          Add group
        </button>
        <button className="btn btn-outline btn-form-cancel" onClick={cancel}>
          Cancel
        </button>
      </div>
    </Form>
  )
}

export default reduxForm({
  form: 'user-groups/create',
  validate,
})(UserGroupForm)


