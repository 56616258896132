import React, { Fragment } from 'react'
import PrivateRoute from '../components/PrivateRoute'
import DealersPage from '../components/pages/ExternalSales/Dealers'
import TerritoriesPage from '../components/pages/ExternalSales/Territories/Index'
import DealerTimeline from '../components/pages/ExternalSales/DealerTimeline'
import Garages from '../components/pages/ExternalSales/Garages'
import Policies from '../components/pages/ExternalSales/Policies/Index'
import CreateDealer from '../components/pages/ExternalSales/Dealers/Create'
import ProspectsPage from '../components/pages/ExternalSales/Prospects/Index'
import PipelinePage from '../components/pages/ExternalSales/Pipeline/PipelinePage'
import Policyholders from '../components/pages/ExternalSales/Policyholders'
import Show from '../components/pages/ExternalSales/Territories/Show'
import CreatePolicy from '../components/pages/ExternalSales/Policies/Create'
import Policy from '../components/pages/ExternalSales/Policies/Show'
import PolicyHolderDetail from '../components/pages/PolicyHolderDetail'
import ProspectTimeline from '../components/pages/ExternalSales/Prospects/Timeline'
import CreateProspect from '../components/pages/ExternalSales/Prospects/Create'
import EditProspect from '../components/pages/ExternalSales/Prospects/Edit'
import EditDealer from '../components/pages/ExternalSales/Dealers/Edit'
import CustomPrices from '../components/pages/ExternalSales/Dealers/Edit/CustomPrices'
import CreateSpecialVehicle from "../components/pages/ExternalSales/Dealers/Edit/SpecialVehicles/Create";
import EditSpecialVehicle from "../components/pages/ExternalSales/Dealers/Edit/SpecialVehicles/Edit"
import TransferPolicy from "../components/pages/PolicyTransfers";

function ExternalSalesRoutes() {
  return (
    <Fragment>
      <PrivateRoute
        exact
        path={`/external-sales/dealers`}
        component={DealersPage}
      />

      <PrivateRoute
        exact
        path={`/external-sales/prospects`}
        component={ProspectsPage}
      />

      <PrivateRoute
        exact
        path={`/external-sales/prospects/create`}
        component={CreateProspect}
      />

      <PrivateRoute
        exact
        path={`/external-sales/prospects/:id/edit`}
        component={EditProspect}
      />

      <PrivateRoute
        exact
        path={`/external-sales/prospects/:id/timeline`}
        component={ProspectTimeline}
      />

      <PrivateRoute
        exact
        path={`/external-sales/dealers/create`}
        component={CreateDealer}
      />

      <PrivateRoute
        exact
        path={`/external-sales/dealers/:id/edit`}
        component={EditDealer}
      />

      <PrivateRoute
        exact
        path={`/external-sales/dealers/:id/edit/special-vehicles`}
        component={CreateSpecialVehicle}
      />

      <PrivateRoute
        exact
        path={`/external-sales/dealers/:id/edit/special-vehicles/:vehicleId`}
        component={EditSpecialVehicle}
      />

      <PrivateRoute
        exact
        path={`/external-sales/dealers/:id/timeline`}
        component={DealerTimeline}
      />

      <PrivateRoute
        exact
        path={`/external-sales/dealers/:id/custom-price-band`}
        component={CustomPrices}
      />

      <PrivateRoute
        exact
        path={`/external-sales/territories`}
        component={TerritoriesPage}
      />

      <PrivateRoute
        exact
        path={`/external-sales/garages`}
        component={Garages}
      />

      <PrivateRoute
        exact
        path={`/external-sales/policies`}
        component={Policies}
      />

      <PrivateRoute
        exact
        path={`/external-sales/policies/create`}
        component={CreatePolicy}
      />

      <PrivateRoute
        exact
        path={`/external-sales/pipeline`}
        component={PipelinePage}
      />

      <PrivateRoute
        exact
        path={`/external-sales/territories/:id/`}
        component={Show}
      />

      <PrivateRoute
        exact
        path={`/external-sales/policyholders`}
        component={Policyholders}
      />

      <PrivateRoute
        exact
        path={`/external-sales/policyholders/:id/timeline`}
        component={(props) => (
          <PolicyHolderDetail {...props} section="external-sales" />
        )}
      />
      <PrivateRoute
        exact
        path={`/external-sales/policies/:id/transfer`}
        component={(props) => <TransferPolicy {...props} target={`external-sales`} />}
      />

      <PrivateRoute
        exact
        path={`/external-sales/policies/:id/policy`}
        component={Policy}
      />
    </Fragment>
  )
}

export default ExternalSalesRoutes
