import React, { Component, Fragment } from 'react'
import { lastSeen } from '../../../utils/misc'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import api from '../../../utils/api'
import PageHeader from '../../ui/PageHeader'
import {
  fetchUsers,
  suspendUser,
  usersTableRowAdd,
  usersTableRowRemove,
  deleteUser,
  deleteMultipleUsers,
  activateUsers,
  suspendMultipleUsers,
  activateUserSuccess,
} from '../../../actions/users.actions'
import { connect } from 'react-redux'
import { nameFormatter, groupsFormatter } from './tableFormatters'
import { sortCaret } from '../../../utils/misc'
import SuspendUser, { SuspendForm } from './SuspendUser'
import Table from '../../ui/Table'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import ReactTooltip from 'react-tooltip'
import { hasPermission, isSuperAdmin, hasGroup } from '../../../utils/auth';

class UsersPage extends Component {
  state = {
    filterGroups: [
      {
        name: 'status',
        allowOnlyOne: true,
        filters: [
          {
            id: 0,
            name: 'Active',
            icon: 'icon-lock-open table-action-btn text-success',
          },
          {
            id: 1,
            name: 'Suspended',
            icon: 'icon-lock table-action-btn text-danger',
          },
          { id: 2, name: 'Deleted', icon: 'icon-ghost table-action-btn' },
        ],
      },
      {
        name: 'last seen',
        allowOnlyOne: true,
        filters: [
          { id: 0, name: 'Today' },
          { id: 1, name: 'Last week' },
          { id: 2, name: 'Last month' },
          { id: 3, name: 'Not last month' },
          { id: 4, name: 'Never' },
        ],
      },
    ],
    query: '',
    page: 1,
    desc: false,
    limit: 10,
    order: '',
    groups: [],
    is_active: null,
    deleted: false,
    last_seen: '',
  }

  componentDidMount() {
    this.props.fetchUsers()
    this.fetchGroups()
  }

  fetchGroups = () => {
    api.get('/admin/users/groups?limit=1000').then(response => {

      let groups

      // External Sales Managers should be able to assign users to a Dealer user group or the External Sales group
      // Account Managers (which are also part of external sales) should only should be able to assign users to a Dealer user group
      if (hasGroup('Management')) {
        groups = response.data.data
      } else if (!isSuperAdmin() && hasPermission('external_sales.dealers.view')) { // is an External Sales member of staff
        if (hasPermission('external_sales.dealers.view_all')) { // is an External Sales Manager
          groups = response.data.data.filter(g => g.name.startsWith('Dealer') || g.name === 'External Sales')
        } else { // is an Account Manager
          groups = response.data.data.filter(g => g.name.startsWith('Dealer'))
        }
      } else {
        groups = response.data.data
      }

      this.setState({
        filterGroups: [
          { name: 'groups', filters: groups },
          ...this.state.filterGroups,
        ],
      })
    })
  }

  handleCheckboxChange = (e, index) => {
    // console.log('row index', e)
  }

  handleRowSelect = (row, isSelect, rowIndex, e) => {
    if (isSelect) this.props.usersTableRowAdd([row.id])
    else this.props.usersTableRowRemove([row.id])
  }

  handleDelete = (id, row) => {
    confirmAlert({
      title: '🧐 Are you sure?',
      message: `Delete ${row.first_name || ''} ${row.last_name || ''}? They won't be able to login once deleted. You can undo this later.`,
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>{message}</p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  this.buildUrlAndFetchUsers()
                  this.props.deleteUser(id)
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  handleRowSelectAll = (isSelect, rows, e) => {
    let ids = rows.map(row => row.id)
    if (isSelect) this.props.usersTableRowAdd(ids)
    else this.props.usersTableRowRemove(ids)
  }

  handleTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage },
  ) => {
    this.setState(
      {
        page: page || 1,
        desc: sortOrder === 'desc' ? true : false || false,
        limit: sizePerPage || 10,
        order: sortField || '',
      },
      this.buildUrlAndFetchUsers,
    )
  }

  handleSearch = (query, filters) => {
    let groups = []
    let is_active = null
    let deleted = false
    let last_seen = ''

    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]
      if (filter.group_name === 'groups') groups.push(filter.id)
      if (filter.group_name === 'status') {
        switch (filter.id) {
          case 0:
            is_active = 1
            break
          case 1:
            is_active = 0
            break
          case 2:
            deleted = true
            break
          default:
            break
        }
      }
      if (filter.group_name === 'last seen')
        last_seen = filter.name.toLowerCase().replace(/ /g, '_')
    }

    this.setState(
      {
        query,
        groups,
        is_active,
        deleted,
        last_seen,
      },
      this.buildUrlAndFetchUsers,
    )
  }

  buildUrlAndFetchUsers = () => {
    const {
      query,
      page,
      desc,
      limit,
      order,
      groups,
      is_active,
      deleted,
      last_seen,
    } = this.state

    let url = `?query=${query}&page=${page}&desc=${desc}&limit=${limit}`

    if (order !== '') url = `${url}&order=${order}`

    if (groups.length > 0) groups.map(gid => url = `${url}&groups[]=${gid}`)

    if (is_active !== null) url = `${url}&is_active=${is_active}`

    if (deleted) url = `${url}&deleted=${deleted}`

    if (last_seen) url = `${url}&last_seen=${last_seen}`

    this.props.fetchUsers(url)
  }

  render() {
    const { isPending, users, selectedRows } = this.props

    const options = {
      showOptions: false,
      columns: [
        {
          dataField: 'first_name',
          text: 'User',
          formatter: nameFormatter,
          sort: true,
          sortCaret,
        },
        {
          dataField: 'last_seen',
          text: 'Last seen',
          headerStyle: {
            width: 170,
          },
          formatter: lastSeen,
          sort: true,
          sortCaret,
        },
        {
          dataField: 'is_active',
          text: 'Status',
          sort: true,
          sortCaret,
          formatter: (cell, row) => {
            return (
              <SuspendUser
                isActive={row.is_active}
                suspended={!row.is_active}
                reason={row.suspended_reason || false}
                name={`${row.first_name || ''} ${row.last_name || ''}`}
                userId={row.id}
                title={`🧐 Are you sure?`}
                label={'Why do you want to suspend this user?'}
                message={`Suspend ${row.first_name || ''} ${row.last_name || ''}? They won’t be able to login whilst suspended. You can undo this later.`}
                handleSuspend={this.props.suspendUser}
                dispatch={this.props.dispatch}
              />
            )
          },
          headerStyle: {
            width: 100,
          },
        },
        {
          dataField: 'dealer_name',
          text: 'Dealership',
        },
        {
          dataField: 'groups',
          text: 'Groups',
          formatter: groupsFormatter,
        },
        {
          dataField: 'id',
          text: 'Actions',
          headerStyle: {
            width: 100,
          },
          formatter: (cell, row) => (
            <div className="d-flex align-items-center">
            {hasPermission('admin.users.update') && (
              <Link
                data-tip="Edit user"
                data-place="top"
                to={`/admin/users/${row.id}/edit`}
                className="table-action-btn"
                primary="true"
                size="sm"
              >
                <span className="icon-note" />
              </Link>
            )}
            {hasPermission('admin.users.delete') && (
              <Link
                data-tip="Delete user"
                data-place="top"
                to={`#`}
                className="table-action-btn table-action-btn--delete"
                size="sm"
                onClick={() => this.handleDelete(row.id, row)}
              >
                <span className="icon-trash" />
              </Link>
            )}
              <ReactTooltip effect="solid" />
            </div>
          ),
        },
      ],
      selectRow: {
        mode: 'checkbox',
        clickToSelect: true,
        selected: this.props.selectedRows,
        onSelect: this.handleRowSelect,
        onSelectAll: this.handleRowSelectAll,
        selectionRenderer: ({ mode, rowIndex, ...rest }) => {
          return (
            <div className="text-center lh-1 position-relative align-checkbox">
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  className="custom-control-input"
                  type={mode}
                  {...rest}
                  rowindex={rowIndex}
                  onChange={e => this.handleCheckboxChange(e, rowIndex)}
                  style={{ width: 10, height: 10 }}
                  id={`checkbox-${rowIndex}`}
                />
                <label
                  htmlFor={`checkbox-${rowIndex}`}
                  className="custom-control-label"
                  style={{ pointerEvents: 'none' }}
                />
              </div>
            </div>
          )
        },

        selectionHeaderRenderer: ({ mode, checked, indeterminate }) => {
          return (
            <div className="text-center lh-1 position-relative align-checkbox">
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  className="custom-control-input"
                  type={mode}
                  checked={checked}
                  onChange={this.handleCheckboxChange}
                />
                <label className="custom-control-label" />
              </div>
            </div>
          )
        },
      },
      groups: [],
    }

    let tableActions = [
      {
        iconClass: 'icon-lock-open font-lg text-success mr-2',
        callback: () =>
          confirmAlert({
            title: '🧐 Are you sure?',
            customUI: ({ title, onClose }) => {
              return (
                <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                    <span
                      className="btn-close-modal fa fa-close"
                      onClick={onClose}
                    />
                    <h3>{title}</h3>
                    <p>
                      Activate <strong>{this.props.selectedRows.length}</strong>{' '}
                      users from the system? They will be able to login to the
                      system. You can undo this later.`,
                    </p>

                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        onClose()
                        return activateUsers(this.props.selectedRows).then(
                          res => {
                            this.props.selectedRows.forEach(id =>
                              this.props.dispatch(activateUserSuccess(id)),
                            )
                          },
                        )
                      }}
                    >
                      Yes, I'm sure
                    </button>

                    <button
                      type="button"
                      className="btn btn-transparent text-secondary"
                      onClick={onClose}
                    >
                      No, cancel
                    </button>
                  </div>
                </div>
              )
            },
          }),
        tooltip: {
          text: 'Activate users',
          place: 'top',
        },
        isVisible: hasPermission('admin.users.update'),
      },
      {
        iconClass: 'icon-lock font-lg text-danger mr-2',
        callback: () =>
          confirmAlert({
            title: '🧐 Are you sure?',
            customUI: ({ title, onClose }) => {
              return (
                <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                    <span
                      className="btn-close-modal fa fa-close"
                      onClick={onClose}
                    />
                    <h3>{title}</h3>
                    <p>
                      Suspend <strong>{this.props.selectedRows.length}</strong>{' '}
                      users from the system? They won't be able to login whilst
                      suspended. You can undo this later.
                    </p>

                    <SuspendForm
                      isMulti={true}
                      dispatch={this.props.dispatch}
                      label={`Why do you want to suspend these users?`}
                      userId={this.props.selectedRows}
                      handleSuspend={suspendMultipleUsers}
                      onClose={onClose}
                    />
                  </div>
                </div>
              )
            },
          }),
        tooltip: {
          text: 'Suspend multiple users',
          place: 'top',
        },
        isVisible: hasPermission('admin.users.update'),
      },
      {
        iconClass: 'icon-trash font-lg text-danger mr-2',
        callback: () =>
          confirmAlert({
            title: '🧐 Are you sure?',
            customUI: ({ title, onClose }) => {
              return (
                <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                    <span
                      className="btn-close-modal fa fa-close"
                      onClick={onClose}
                    />
                    <h3>{title}</h3>
                    <p>
                      Delete <strong>{this.props.selectedRows.length}</strong>{' '}
                      users from the system? They won't be able to login whilst
                      suspended. You can undo this later.
                    </p>

                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        deleteMultipleUsers(this.props.selectedRows).then(
                          res => {
                            this.buildUrlAndFetchUsers()
                          },
                        )
                        onClose()
                      }}
                    >
                      Yes, I'm sure
                    </button>

                    <button
                      type="button"
                      className="btn btn-transparent text-secondary"
                      onClick={onClose}
                    >
                      No, cancel
                    </button>
                  </div>
                </div>
              )
            },
          }),
        tooltip: {
          text: 'Delete multiple users',
          place: 'top',
        },
        isVisible: hasPermission('admin.users.delete'),
      },
    ]

    return (
      <Fragment>
        <br />
        <div
          className="container-fluid animated fadeIn"
          style={{ marginBottom: '5rem' }}
        >
          <PageHeader
            title="Users"
            description="View all system users with the ability to search and filter by name, dealership, account status and groups."
            buttonLink={hasPermission('admin.users.create') ? '/admin/users/create' : null}
            buttonText={hasPermission('admin.users.create') ? "Add a user" : null}
            buttonIcon={hasPermission('admin.users.create') ? "d-block wf-icon-add" : null}
          />
          <br />
          {users && (
            <Table
              selected={selectedRows}
              resource={users}
              loading={isPending}
              showOptions={options.showOptions}
              handleExport={this.export}
              hasTableSearch
              searching={false}
              onSearch={this.handleSearch}
              omnibox={{
                groups: this.state.filterGroups,
              }}
              handleTableChange={this.handleTableChange}
              columns={options.columns}
              selectRow={options.selectRow}
              dispatch={this.props.dispatch}
              deleteMultiple={deleteMultipleUsers}
              tableActions={tableActions}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUsers,
      suspendUser,
      usersTableRowAdd,
      usersTableRowRemove,
      dispatch,
      deleteUser,
    },
    dispatch,
  )

export default connect(
  state => ({
    isPending: state.users.isPending,
    users: state.users.data,
    selectedRows: state.users.selectedRows,
  }),
  mapDispatchToProps,
)(UsersPage)
