import React from 'react'
import Avatar from '../../ui/Avatar'

export function nameFormatter(cell, row) {
  return (
    <div className="d-flex align-items-center">
      <Avatar size="small" user={row} />
      <span className="ml-1">
        {row.first_name || ''} {row.last_name || ''}
      </span>
    </div>
  )
}

export const groupsFormatter = (cell, row) => {
  let groups = row.groups.map((group, id) => (
    <span
      key={id}
      style={{ backgroundColor: group.color, marginRight: 2 }}
      className="badge badge-pill badge-secondary"
    >
      {group.name}
    </span>
  ))
  return groups
}
