import React, { Fragment } from 'react'

import PrivateRoute from '../components/PrivateRoute'
import RenewalsPage from '../components/pages/DirectSales/RenewalsProspects'
import PolicyHoldersPage from '../components/pages/DirectSales/PolicyHoldersPage'
import PolicyHolderDetail from '../components/pages/PolicyHolderDetail'
import PipelinePage from '../components/pages/Pipeline/PipelinePage'
import PoliciesPage from '../components/pages/DirectSales/Policies/Index/Policies'
import ProspectsPage from '../components/pages/DirectSales/Prospects/Index'
import Quote from '../components/pages/DirectSales/Prospects/Quote'
import GetQuote from '../components/pages/DirectSales/GetQuote'
import ProspectTimeline from '../components/pages/DirectSales/Prospects/Timeline/ProspectTimeline'
import Policy from '../components/pages/DirectSales/Policies/Show/Policy'
import TransferPolicy from "../components/pages/PolicyTransfers";

function DirectSalesRoutes() {
  return (
    <Fragment>
      <PrivateRoute
        exact
        path={`/direct-sales/renewals`}
        component={RenewalsPage}
      />

      <PrivateRoute
        exact
        path={`/direct-sales/prospects`}
        component={ProspectsPage}
      />

      <PrivateRoute
        exact
        path={`/direct-sales/prospects/:id/quote`}
        component={Quote}
      />

      <PrivateRoute
        exact
        path={`/direct-sales/prospects/:id/timeline`}
        component={ProspectTimeline}
      />

      <PrivateRoute
        exact
        path={`/direct-sales/policyholders`}
        component={PolicyHoldersPage}
      />

      <PrivateRoute
        exact
        path={`/direct-sales/policyholders/:id/timeline`}
        component={(props) => (
          <PolicyHolderDetail {...props} section="direct-sales" />
        )}
      />

      <PrivateRoute
        exact
        path={`/direct-sales/pipeline/`}
        component={PipelinePage}
      />

      <PrivateRoute
        exact
        path={`/direct-sales/policies/`}
        component={PoliciesPage}
      />

      <PrivateRoute
        exact
        path={`/direct-sales/policies/:id/transfer`}
        component={(props) => <TransferPolicy {...props} target={`direct-sales`} />}
      />

      <PrivateRoute
        exact
        path={`/direct-sales/policies/:id/policy`}
        component={Policy}
      />

      <PrivateRoute
        exact
        path={`/direct-sales/get-quote/`}
        component={GetQuote}
      />
    </Fragment>
  )
}

export default DirectSalesRoutes
