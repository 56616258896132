import React from 'react'
import PropTypes from 'prop-types'

const TimelineSidebarCard = ({
  data,
  title,
  renderRow,
  loadMoreText,
  children,
  showMoreText,
  showLessText,
  wrapperClasses,
}) => {
  const [showAll, setShowAll] = React.useState(false)
  const items = showAll ? data && data : data && data.slice(0, 3)
  return (
    <div className={wrapperClasses}>
      {title && <h6 className="h6 fw-600 font-sm">{title}</h6>}

      {children}

      {/* eslint-disable-next-line array-callback-return */}
      {items.map((item, index) => {
        if (index === 3 && showAll) {
          return false
        }

        return renderRow(item, index)
      })}
      {data.length > 3 && (
        <span className="card-view-more" onClick={() => setShowAll(!showAll)}>
          {showAll ? showLessText : showMoreText}
        </span>
      )}
    </div>
  )
}

TimelineSidebarCard.defaultProps = {
  wrapperClasses: 'wf-timeline-card shadow-dark bg-white ph-claim-limits',
}

TimelineSidebarCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  renderRow: PropTypes.func.isRequired,
  loadMoreText: PropTypes.string,
  showAll: PropTypes.bool,
  showMoreText: PropTypes.string,
  showLessText: PropTypes.string,
  wrapperClasses: PropTypes.string,
}

export default TimelineSidebarCard
