import React from 'react'
import PropTypes from 'prop-types'
import { NotificationManager } from 'react-notifications'
import api from '../../../utils/api'

const AddPostcodesForm = ({onClose, territory, fetchPostcodes}) => {

  const [postcodes, setPostcodes] = React.useState('')
  const [fieldError, setFieldError] = React.useState(false)


  const add = e => {
    e.preventDefault()

    api.post(`admin/territories/${territory.id}/postcodes`, {postcodes})
      .then(res => {
        NotificationManager.success('Woohoo! You added new postcode(s).')
        onClose()
        fetchPostcodes(territory.id)
      })
      .catch(err => {
        if (err.response && err.response.data)
          setFieldError(err.response.data.errors)
      })
  }

  return (
    <form onSubmit={add}>
      <p className="mb-0">Separate multiple postcodes with a comma e.g. MK1, MK2.</p>
      <p className="mt-0">To add a sequential range, separate postcodes with a dash e.g. MK10-MK30.</p>

      <div className="form-group">
          <textarea name="postcodes" className={`form-control ${fieldError ? 'is-invalid' : ''}`} cols="30" rows="4"
                    placeholder="e.g. MK1, MK2, MK10-MK30" value={postcodes}
                    onChange={e => setPostcodes(e.target.value)}/>

        {fieldError && <div className="invalid-feedback">{fieldError.postcodes[0]}</div>}
      </div>

      <div className="form-group">
        <button
          className="btn btn-secondary"
        >
          Add postcodes
        </button>
        <button
          className="btn btn- transparent text-secondary"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </form>
  )
}

AddPostcodesForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  territory: PropTypes.object.isRequired,
  fetchPostcodes: PropTypes.func.isRequired,
}

export default AddPostcodesForm