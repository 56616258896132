import React, {useEffect, useState} from 'react'
import PageHeader from "../../../ui/PageHeader";
import PricingRuleForm from "./PricingRuleForm";
import api from '../../../../utils/api'
import { NotificationManager } from 'react-notifications'
import { get } from 'lodash'

const PricingRules = ({target, url, hasSundries, ...props}) => {
  const [pricingRules, setPricingRules] = useState([]);

  useEffect(() => {
    api.get(`/admin/price-rules/${url}`)
      .then(response => {
        setPricingRules(response.data);
      })
      .catch(() => {
        NotificationManager.error('Oops! We could not fetch the current pricing rules. Please refresh and try again.')
      })
  }, []);

  const getRuleType = (type) => (pricingRules.filter(rule => (rule.type === type)))

  const convertTypeToInitialValues = (type) => {
    let rules = getRuleType(type)

    return rules.map(rule => {
      let cover_levels = [];
      rule.cover_levels.forEach((val) => {
        cover_levels[val.id] = true;
      })

      return {
        ...rule,
        effect: impactToOperator(rule.impact_positive),
        cost_type: costTypeToOperator(rule.cost_type),
        cover_levels
      }
    })
  }

  const impactToOperator = (impact) => ({operator: impact ? '+' : '-', value: impact ? '+' : '-'})
  const costTypeToOperator = (type) => ({operator: type === 'Percentage' ? '%' : '£', value: type === 'Percentage' ? '%' : '£'})
  const getDefault = (type) => {
    let defaultIndex = 0;
    getRuleType(type).forEach((val, key) => {
      if (val.default) {
        defaultIndex = key;
      }
    })
    return defaultIndex
  }

  const initialValues = () => ({
    rules: convertTypeToInitialValues('Excess'),
    labour_rates: convertTypeToInitialValues('Labour Rate'),
    sundries: convertTypeToInitialValues('Sundry'),
    excess_default: getDefault('Excess'),
    labour_rate_default: getDefault('Labour Rate'),
  })

  const entryFromValue = (rule, type, isDefault) => {
    let cover_levels = [];
    rule.cover_levels.forEach((val, key) => {
      if (val) {
        cover_levels.push(key);
      }
    })
    return {
      id: get(rule, 'id', null),
      type,
      title: rule.title,
      impact_positive: (rule.effect.value === '+'),
      value: rule.value,
      cost_type: (rule.cost_type.value === '%' ? 'Percentage' : 'Absolute'),
      default: isDefault,
      active: rule.active || false,
      cover_levels,
      durations: rule.durations,
    }
  }

  const convertFormValues = (values) => {
    let submit = [];
    values.labour_rates.forEach((lr, idx) => {
      submit.push(entryFromValue(lr, 'Labour Rate', (values.labour_rate_default === idx)))
    })
    //Excess = rules
    values.rules.forEach((e, idx) => {
      submit.push(entryFromValue(e, 'Excess', (values.excess_default === idx)))
    })
    if (hasSundries) {
      values.sundries.forEach((s, idx) => {
        submit.push(entryFromValue(s, 'Sundry', false))
      })
    }
    return submit;
  }

  const onSubmit = (values) => {
    api.post(`/admin/price-rules/${url}`, {rules: convertFormValues(values)})
      .then(res => {
        setPricingRules(res.data)
        NotificationManager.success('Woohoo! Pricing Rules Updated!')
      })
      .catch(() => {
        NotificationManager.error('Oops! There was a problem saving your pricing rules.');
      })
  }

  return <>
    <br/>
    <div className="container-fluid animated fadeIn">
      <PageHeader
        title="Pricing rules"
        description={
          'Set prices for policy excess and labour rates.'
        }
      />
      {pricingRules &&
        <PricingRuleForm onSubmit={onSubmit} initialValues={initialValues()} hasSundries={hasSundries} coverType={target} url={url}>
          <p className="mb-0">* When adding a new policy, this option will be the default in the corresponding dropdown
            (excess or labour
            rate).</p>
          <p>** ‘Active’ enables the price rule for the dealers when adding a policy.</p>
        </PricingRuleForm>
      }
    </div>
  </>
}

export default PricingRules