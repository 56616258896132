import React from 'react'
import { specialVehicleTypeColor } from '../../../utils/misc'

const renderVehicleTypesField = ({ input, id, type, label, className, touched, error, data, ...rest }) => {
  return  <div className={`vehicle-type-radio ${className}`}>
      <input
        {...input}
        id={id}
        className={``}
        type={type}
        checked={rest.meta.active}
      />

      <label
        className={`vehicle-type-radio-label border-radius-md px-3`}
        htmlFor={id}
      >
        <span className="badge text-white rounded-pill mb-2 font-md py-1" style={{ backgroundColor: specialVehicleTypeColor(input.value)}}>{data.title}</span>
        <p className="mb-0">{data.text}</p>
      </label>

      {touched && error && (
        <div className="invalid-feedback">{error}</div>
      )}
  </div>
}

export default renderVehicleTypesField
