import React, { Component, Fragment } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import PageHeader from '../../../../ui/PageHeader'
import { sortCaret } from '../../../../../utils/misc'
import { Link } from 'react-router-dom'
import Avatar from '../../../../ui/Avatar'
import ReactTooltip from 'react-tooltip'
import Table from "../../../../ui/Table"
import api from "../../../../../utils/api";
import { NotificationManager } from 'react-notifications'
import { API_URL } from "../../../../../utils/constants";


class TerritoriesPage extends Component {
  state = {
    filterGroups: [],
    query: '',
    page: 1,
    desc: false,
    limit: 10,
    order: '',
    account_manager: '',
    url: '',
    current_account_manager: '',
  };

  fetchData = url => {
    api.get(`/external-sales/territories${url ? url : ''}`)
        .then(res => {
          this.setState({ territories: res.data, searching: false })

        })
        .catch(err => {
          if (err) {
            NotificationManager.error('Oops! Something went wrong. Please try again after refreshing.')
          }
        })
  };

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    let url = `?page=${page}&desc=${
        sortOrder === 'desc' ? true : false
    }&limit=${sizePerPage}&query=${this.state.query}`

    if (sortField) url = `${url}&order=${sortField}`

    if (this.state.current_account_manager) {
      url = `${url}&current_account_manager=${this.state.current_account_manager}`
    }

    this.fetchData(url)
  };

  handleSearch = (query, filters) => {

    let ams = filters.filter(f => f.group_name === 'account manager')
    if (ams.length === 1) {
      let am_fullname = ams.map(am => `${am.first_name} ${am.last_name}`)[0]
      this.setState(prevState => ({
        ...prevState,
        current_account_manager: am_fullname
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        current_account_manager: ''
      }))
    }

    this.setState(
        {
          query
        },
        this.buildUrl,
    )
  };

  getAccountManagers = () => {
    return api
      .get(`${API_URL}/misc/account-managers`)
      .then(res => {
        let ams = res.data

        ams = ams.map((am, index) => {
          am.name = (
            <div
              className="d-flex align-items-center"
              style={{ marginBottom: '-2px' }}
              key={index}
            >
              <Avatar user={am} size="xsmall"/>
              <span className="font-md ml-1" style={{ marginTop: '-2px' }}>
                    {am.first_name} {am.last_name}
                  </span>
            </div>
          )
          return am
        })

        this.setState(prevState => (
          { ...prevState.filterGroups, filterGroups: [...prevState.filterGroups, {name: 'account manager', allowOnlyOne: true, filters: ams}] }
        ))
      })
  }


  /**
   * Builds URL and to fetch policyholders
   */
  buildUrl = () => {
    const { query, page, desc, limit, current_account_manager } = this.state

    let url = `?query=${query}&page=${page}&desc=${desc}&limit=${limit}`

    if (current_account_manager) {
      url = `${url}&current_account_manager=${current_account_manager}`
    }

    this.setState({ searching: true })
    this.fetchData(url)
  };

  componentDidMount() {
    this.fetchData()

    this.getAccountManagers()
  }


  render() {
    const options = {
      showOptions: false,
      columns: [
        {
          dataField: 'name',
          text: 'Area',
          formatter: (cell, row) => <Link to={`/external-sales/territories/${row.id}`}>{row.name}</Link>,
          sort: true,
          sortCaret,
        },
        {
          dataField: 'current_account_manager',
          text: 'AM',
          headerStyle: {
            width: '50px'
          },
          formatter: (cell, row) => {
            if (row.current_account_manager) {
              let user = {
                first_name: row.current_account_manager.split(' ')[0],
                last_name: row.current_account_manager.split(' ')[1]
              };
              return <Avatar key={row.id} user={user}/>
            }
          }
        },
        {
          dataField: 'postcodes',
          text: 'Postcode',
          formatter: (cell, row) => {
            let p = row.postcodes
            if(p.length > 9) {
              return `${p.slice(0, 9).join(', ')}...`
            } else {
              return row.postcodes;
            }

          }
        },

        {
          dataField: 'id',
          text: 'Actions',
          headerStyle: {
            width: 85,
          },
          formatter: (cell, row) => (
            <div className="d-flex align-items-center" key={row.id}>
              <Link
                to={`/external-sales/territories/${row.id}`}
                className="table-action-btn"
                data-tip="More details"
                data-place="top"
                primary="true"
                size="sm"
              >
                <span className="icon-magnifier"/>
              </Link>
              <ReactTooltip effect="solid"/>
            </div>
          ),
        },
      ],
    };

    return (
      <Fragment>
        <br/>
        <div className="container-fluid animated fadeIn">
          <PageHeader
            title="Territories"
          />
          <br/>
          {this.state.territories && (
            <Table
              resource={this.state.territories}
              showOptions={options.showOptions}
              columns={options.columns}
              omnibox={{ groups: this.state.filterGroups }}
              searching={this.state.searching}
              hasTableSearch={true}
              onSearch={this.handleSearch}
              handleTableChange={this.handleTableChange}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

export default TerritoriesPage
