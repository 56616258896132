import React, { useEffect } from 'react';
import SearchBox from '../SearchBox';
import api from '../../../utils/api';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom'
import AddNote from './AddNote';
import AddMeeting from './AddMeeting';
import { NotificationManager } from 'react-notifications';
import TimelineCard from './TimelineCard';
import { hasPermission } from '../../../utils/auth';

const Timeline = (props) => {
  const [messages, setMessages] = React.useState();
  const [search, setSearch] = React.useState('');
  const [searching, setSearching] = React.useState(false);
  const [loadingMore, setLoadingMore] = React.useState(false);

  function fetchData(query) {
    let url = `${props.apiUrl}?limit=5`;
    url = `${url}&query=${query ? query : ''}`;

    api
      .get(url)
      .then((res) => {
        setSearching(false);
        setMessages(res.data);
      })
      .catch((err) => {
        setSearching(false);
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing.'
          );
        }
      });
  }

  useEffect(() => {
    // setSearching(true)
    fetchData(search);
  }, [search]);

  function handleAddNote(data) {
    return new Promise((resolve, reject) => {
      api
        .post(`${props.apiUrl}/message`, data)
        .then((res) => {
          res.data.pinned = false;

          setMessages((msgs) => {
            return {
              ...msgs,
              data: [res.data, ...msgs.data],
            };
          });
          resolve([]);
        })
        .catch((err) => {
          if (err) reject(err);
        });
    });
  }

  function handleAddMeeting(data) {
    return new Promise((resolve, reject) => {
      api
        .post(`${props.apiUrl}/message`, data)
        .then((res) => {
          res.data.pinned = false;

          setMessages((msgs) => {
            return {
              ...msgs,
              data: [res.data, ...msgs.data],
            };
          });
          resolve([]);
        })
        .catch((err) => {
          if (err) reject(err);
        });
    });
  }

  function deleteCard(id) {
    api
      .delete(`${props.apiUrl}/message/${id}`)
      .then(() => {
        NotificationManager.success('Woohoo! You deleted a record.');

        setMessages((cards) => ({
          ...cards,
          data: [...cards.data.filter((item) => item.id !== id)],
        }));
      })
      .catch((err) => {
        if (err) {
          if (err.response.status === 404) {
            NotificationManager.error(
              'Oops! The record does not exist anymore. Please refresh the page'
            );
          } else {
            NotificationManager.error(
              'Oops! Something went wrong. Please try again after refreshing the page.'
            );
          }
        }
      });
  }

  function updateNoteMessage(item) {
    let data = [
      ...messages.data.map((row) => {
        if (row.id === item.id) {
          row = item;
        }
        return row;
      }),
    ];

    let sorted = data
      .sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      })
      .sort((a, b) => b.pinned - a.pinned);

    setMessages((state) => ({
      ...state,
      data: sorted,
    }));
  }

  function editNote(note) {
    return new Promise((resolve, reject) => {
      api
        .patch(`${props.apiUrl}/message/${note.id}`, note)
        .then((res) => {
          updateNoteMessage(res.data);
          NotificationManager.success('Woohoo! You updated your note.');
          return resolve(res);
        })
        .catch((err) => {
          if (err) {
            return reject(err);
          }
        });
    });
  }

  function editMeeting(meeting) {
    return new Promise((resolve, reject) => {
      api
        .patch(`${props.apiUrl}/message/${meeting.id}`, meeting)
        .then((res) => {
          updateNoteMessage(res.data);
          NotificationManager.success('Woohoo! You updated your meeting.');
          return resolve(res);
        })
        .catch((err) => {
          if (err) {
            return reject(err);
          }
        });
    });
  }

  function onPin(id) {
    api
      .patch(`${props.apiUrl}/message/${id}/toggle-pin`)
      .then((res) => {
        updateNoteMessage(res.data);
      })
      .catch((err) => {
        if (err) {
          console.log(err.response);
        }
      });
  }

  function handleDelete(row) {
    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>Delete this {row.message_type.toLowerCase()}? It'll be lost forever.</p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  deleteCard(row.id);
                  onClose();
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        );
      },
    });
  }

  function loadMore() {
    if (messages.next_page_url) {
      setLoadingMore(true);
      api
        .get(`${messages.next_page_url}&limit=5`)
        .then((res) => {
          const { data, ...paginationProps } = res.data;

          setMessages({
            ...messages,
            data: [...messages.data, ...res.data.data],
            ...paginationProps,
          });
          setLoadingMore(false);
        })
        .catch((err) => {
          setLoadingMore(false);
        });
    }
  }

  return (
    <div className="wf-timeline bg-white shadow-dark rounded">
      <header className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <h6 className="h6 fw-600 mb-0 text-uppercase font-sm">Timeline</h6>
            {props.apiUrl.indexOf('/claims') === 0 && hasPermission('claims.claims.create') && (
              <Link
                to={'/claims/claims/create'}
                className="btn btn-warning btn-sm font-weight-bold d-block font-sm ml-3 grey-400"
                disabled={true}
                style={{ width: '150px' }}
              >
                Start a new claim
              </Link>
            )}

            <AddNote
              for={props.for}
              personId={props.personId}
              handleAddNote={handleAddNote}
            />

            {props.allowMeetings &&
              <AddMeeting
                for={props.for}
                personId={props.personId}
                handleAddMeeting={handleAddMeeting}
              />
            }
        </div>

        <SearchBox
          onSearch={(query) => setSearch(query)}
          searchPlaceholder={'Search or filter timeline…'}
          className="mb-0 w-50"
          searching={searching}
        />
      </header>

      {messages && (
        <div className="wf-timeline-cards">
          {messages.total === 0 && (
            <div className="px-4 pb-4">No matching records.</div>
          )}

          {messages.data
            .sort((a, b) => b.pinned - a.pinned)
            .map((row, index) => {
              const { created_by, policyholder, ...rest } = row;
              const ownerId = created_by && created_by.id;
              const isOwner = ownerId === props.loggedInPersonID;

              return (
                <TimelineCard
                  createdBy={created_by}
                  isOwner={isOwner}
                  message={rest}
                  handleDelete={() => handleDelete(rest)}
                  key={index}
                  editNote={(note) => editNote(note)}
                  editMeeting={(meeting) => editMeeting(meeting)}
                  onEdit={(msg) => {
                    row.message = msg;
                    updateNoteMessage(row);
                  }}
                  onPin={onPin}
                />
              );
            })}

          {messages.next_page_url && (
            <div className="card-view-more w-100 m-0" onClick={loadMore}>
              {loadingMore ? 'Loading...' : 'Load more...'}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Timeline.defaultProps = {
  allowMeetings: false
};

Timeline.propTypes = {
  loggedInPersonID: PropTypes.number.isRequired, // currently authenticated user_person_id (every user has a person_id attached - that one)
  personId: PropTypes.number,
  for: PropTypes.string.isRequired, // could be policyholder name or dealer name or anything else?
  apiUrl: PropTypes.string.isRequired,
  allowMeetings: PropTypes.bool
};

export default Timeline;
