import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import TimelineSidebarCard from '../../ui/TimelineSidebarCard'
import { has } from 'lodash'
import { echo, formatDate, money } from '../../../utils/misc'
import {API_URL} from "../../../utils/constants";
import { NotificationManager } from 'react-notifications'
import { hasPermission } from '../../../utils/auth';

function TimelineSidebar(props) {
  const { policyHolder, activePolicyTab } = props


  let activePolicies = policyHolder && policyHolder.policies.filter((p) => moment(p.end_date).isAfter(moment()))
  const card = activePolicies[activePolicyTab]
  const quotes = has(card, 'quotes') ? card.quotes : []

  const [claims, setClaims] = useState([]);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {

    if (card) { // this will be null if the policyholder has no active policies
      // We need the warranty certificate to be dynamically generated here so this adds it to the policy documents.
      const tDocuments = has(card, 'documents') ? [...card.documents] : []
      if ((card.status === 'Approved') || (card.status === 'Cancelled') || (card.status === 'Expired')) {
        tDocuments.push({
          name: 'Warranty Certificate',
          created_at: card.created_at,
          url: downloadCertificateUrl(card),
        })
      }
      if (props.section === 'external-sales') {
        tDocuments.push({
          name: 'Policy Summary',
          created_at: card.created_at,
          url: downloadSummaryUrl(card),
        })
      }
      setDocuments(tDocuments)
    }

    // get the claims now too (for all of the policyholder's policies)
    const tClaims = []
    policyHolder.policies.forEach((p) => p.claims.forEach(c => tClaims.push(c)))
    setClaims(tClaims)

  }, [card])

  const downloadCertificateUrl = (policy) => {
    if (props.section === 'external-sales') {
      return API_URL + `/downloads/pdf/external-sales/policies/${policy.id}/certificate?token=` + JSON.parse(localStorage.getItem('wf-token'))
    } else {
      return API_URL + `/downloads/pdf/direct-sales/policies/${policy.id}/certificate?token=` + JSON.parse(localStorage.getItem('wf-token'))
    }
  }

  const downloadSummaryUrl = (policy) => {
    return API_URL + `/downloads/pdf/external-sales/policies/${policy.id}/summary?token=` + JSON.parse(localStorage.getItem('wf-token'))
  }

  const renderClaimStatusIconClasses = (status) => {
    switch (status) {
      case 'Approved':
      case 'Approved not paid':
      case 'Approved paid':
        return 'wf-icon-check-rounded mr-1'
      case 'No comms within 14 days':
      case 'Parts not covered':
      case 'Rejected':
      case 'Cancelled':
      case 'Closed expired MOT':
      case 'Closed expired service':
        return 'icon-close font-lg fw-800 text-red mr-1'
      default:
        return 'wf-icon-text-outline mr-1' // an ongoing claim
    }
  }

  return (
    policyHolder && (
      <div className="wf-timeline-sidebar">
        <TimelineSidebarCard
          title="Claims history"
          data={claims}
          showMoreText={'Show all claims'}
          showLessText={'Hide older claims'}
          renderRow={(row, index) => {
            return (
              <div
                className="ph-claim d-flex justify-content-between"
                key={index}
              >
                <div className="ph-claim-meta">
                  <div className="ph-claim-date font-sm font-weight-medium">
                    {moment(row.created_at).format('DD/MM/YYYY')}
                  </div>
                  <div className="ph-claim-ref font-md mb-1">{row.ref}</div>
                  <div className="ph-claim-price font-md d-flex align-items-center">
                    <i className={renderClaimStatusIconClasses(row.status)} />
                    {money.format(row.payout_net)}
                  </div>
                </div>

                <div className="ph-claim-links">
                  <Link to={`/claims/claims/${row.id}`} className="font-sm">
                    View claim
                  </Link>
                </div>
              </div>
            )
          }}
        >
          {props.section === 'claims' && hasPermission('claims.claims.create') && (
            <Link
              to={'/claims/claims/create'}
              className="btn btn-warning btn-sm font-weight-bold d-block font-sm mb-4 grey-400"
              disabled={true}
              style={{ width: '150px' }}
            >
              Start a new claim
            </Link>
          )}
        </TimelineSidebarCard>

        <TimelineSidebarCard
          title="Policy documents"
          data={documents}
          showMoreText={'Show all documents'}
          showLessText={'Hide older documents'}
          renderRow={(row, index) => {
            return (
              <div
                className="ph-claim d-flex justify-content-between align-items-center cursor-pointer"
                key={index}
                onClick={() => {
                  if (row.url) {
                    window.open(row.url);
                  } else {
                    NotificationManager.error('This item has no link associated with it.');
                  }
                }}
              >
                <div className="ph-claim-meta">
                  <div className="ph-document-date font-sm font-weight-medium">
                    {moment(row.created_at).format('DD/MM/YYYY')}
                  </div>
                  <div className="ph-document-ref font-md">{row.name}</div>
                </div>

                <div className="ph-documents-links">
                  <i className="wf-icon-download d-inline-block text-blue font-weight-bold" />
                </div>
              </div>
            )
          }}
        />

        {quotes.length > 0 && (
          <TimelineSidebarCard
            title="Open quotes"
            data={quotes}
            showMoreText={'Show all quotes'}
            showLessText={'Hide older quotes'}
            renderRow={(row, index) => {
              return (
                <div key={index}>
                  <div className="ph-quote-date font-sm font-weight-medium">
                    {moment(row.created_at).format('DD/MM/YYYY')}
                  </div>
                  <Link to="#" className="font-sm">
                    {row.ref}
                  </Link>
                </div>
              )
            }}
          />
        )}

        <div className="wf-timeline-card shadow-dark bg-white ph-claim-limits">
          <h6 className="h6 fw-600 font-sm">Direct Debit</h6>
          <div className="dealer-garages">
            <div className="mb-3">
              <div className="label">Status</div>
              <div className="font-md">
                {policyHolder.mandate ? `${echo(policyHolder.mandate.status)} (${formatDate(policyHolder.mandate.status_at)})` : 'Not signed-up'}
              </div>
            </div>
            <div className="mb-3">
              <div className="label">Start Date</div>
              <div className="font-md">{policyHolder.mandate ? formatDate(policyHolder.mandate.start_date) : '-'}</div>
            </div>
            <div className="mb-0">
              <div className="label">End Date</div>
              <div className="font-md">{policyHolder.mandate ? formatDate(policyHolder.mandate.end_date) : '-'}</div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

TimelineSidebar.propTypes = {
  policyHolder: PropTypes.object.isRequired,
}

export default TimelineSidebar
