export const tableHeadings =  [
  {
    'title'    : '3 months',
    'value'    : 3,
    'hiddenFor': [],
    'special'  : false,
  },
  {
    'title'    : '6 months',
    'value'    : 6,
    'hiddenFor': [],
    'special'  : false,
  },
  {
    'title'    : '12 months',
    'value'    : 12,
    'hiddenFor': [],
    'special'  : false,
  },
  {
    'title'    : '18 months',
    'value'    : 18,
    'hiddenFor': [],
    'special'  : false,
  },
  {
    'title'    : '24 months',
    'value'    : 24,
    'hiddenFor': [],
    'special'  : false,
  },
  {
    'title'    : '36 months',
    'value'    : 36,
    'hiddenFor': [],
    'special'  : false,
  },
]
