import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { echo, formatDate, getAge, money, prettyNumber } from '../../../../../utils/misc'
import SlideToggle from '../../../../ui/SlideToggle';
import VehicleDetailsEdit from './VehicleDetailsEdit';
import api from "../../../../../utils/api";
import {API_URL} from "../../../../../utils/constants";
import {get} from "lodash";
import {NotificationManager} from "react-notifications";
import moment from "moment";

const VehicleDetails = ({ vehicle, allowEdit, endpoint, refresh, coverLevel }) => {

  const [vehicleEdit, setVehicleEdit] = useState(false);

  const handleVehicleEditSubmit = (values) => {
    let data = {
      vrm: values.vrm,
      vin: useManualInfo ? values.vin : lookupData.VIN,
      type: useManualInfo ? values.type.value : lookupData.Type,
      make: useManualInfo ? values.make : lookupData.Make,
      model: useManualInfo ? values.model : lookupData.Model,
      series: useManualInfo ? values.series : lookupData.Series,
      drive_type: useManualInfo ? values.drive_type : lookupData.DriveType,
      engine_size: useManualInfo ? values.engine_size : lookupData.EngineSize,
      transmission: useManualInfo ? values.transmission : lookupData.Transmission,
      fuel_type: useManualInfo ? values.fuel_type : lookupData.FuelType,
      mileage: values.mileage,
      retail_price: values.retail_price,
      reg_date: useManualInfo ? moment(values.reg_date).format('YYYY-MM-DD') : lookupData.RegDate.split('-').reverse().join('-'),
      manual_data: useManualInfo ? 1 : 0,
    }
    api.patch(endpoint, data)
      .then(res => {
        NotificationManager.success('Woohoo! The vehicle has been updated');
        setVehicleEdit(false);
        if (refresh) {
          refresh()
        }
      })
      .catch(err => {
        NotificationManager.error('Oops! There was a problem updating the vehicle information.');
        console.log(err);
      });
  }

  const [lookupData, setLookupData] = useState()
  const [useManualInfo, setUseManualInfo] = useState()

  const handleVRMLookup = (vrm) => {

    if (!vrm){
      return false
    }

    setUseManualInfo(false);

    api
      .get(`${API_URL}/misc/vrm-lookup?vrm=${vrm}`)
      .then((res) => {
        if (get(res, 'data.Model', false) === false) {
          setLookupData(null);
          setUseManualInfo(true);
          NotificationManager.error('Oops! We could not find information for this registration. Please enter the details manually.');
        } else {
          setLookupData(res.data)
        }
      })
      .catch((err) => {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again.'
        )
      })

  }

  const maxMileageExceeded = () => vehicle.mileage && vehicle.mileage > coverLevel.max_mileage;
  const maxAgeExceeded = () => {
    const age = getAge(new Date(vehicle.reg_date));
    return age > coverLevel.max_age;
  }
  const maxRetailPriceExceeded = () => vehicle.retail_price > coverLevel.max_price;

  return (
    <div className="row">
      {!vehicleEdit &&
      <div className="col-12 pr-5">
        <div className="row">
          <div className="col d-flex align-items-center">
            <h6 className="mt-4 mb-5 text-uppercase">Vehicle details</h6>
          </div>
          <div className="col d-flex align-items-center justify-content-end">
            {allowEdit &&
            <>
              <button
                className="btn btn-light btn-sm"
                onClick={() => setVehicleEdit(true)}
                style={{ width: 34, height: 34 }}
              >
                <i className="wf-icon-edit mr-0" />
              </button>
            </>
            }
          </div>
        </div>

        <div className="row d-flex flex-start">
          <div className="col-4">
            <span className="label">Vehicle reg.</span>
            <p className="font-md">{echo(vehicle.vrm)}</p>
          </div>
          <div className="col-4">
            <span className="label">VIN number</span>
            <p className="font-md">{echo(vehicle.vin)}</p>
          </div>
          <div className="col-4">
            <span className="label">Type</span>
            <p className="font-md">{echo(vehicle.type)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Make</span>
            <p className="font-md">{echo(vehicle.make)}</p>
          </div>
          <div className="col-4">
            <span className="label">Model</span>
            <p className="font-md">{echo(vehicle.model)}</p>
          </div>
          <div className="col-4">
            <span className="label">Series</span>
            <p className="font-md">{echo(vehicle.series)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Drive type</span>
            <p className="font-md">{echo(vehicle.drive_type)}</p>
          </div>
          <div className="col-4">
            <span className="label">Engine size</span>
            <p className="font-md">{echo(vehicle.engine_size)}</p>
          </div>
          <div className="col-4">
            <span className="label">Transmission</span>
            <p className="font-md">{echo(vehicle.transmission)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Fuel Type</span>
            <p className="font-md">{vehicle.fuel_type ? vehicle.fuel_type : '-'}</p>
          </div>
          <div className="col-4">
            <span className="label">Manufactured</span>
            <p className={`font-md ${maxAgeExceeded() ? "font-weight-bold text-red" : ""}`}>{vehicle.reg_date ? formatDate(vehicle.reg_date) : '-'}</p>
          </div>
          <div className="col-4">
            <span className="label">Vehicle sale price</span>
            <p className={`font-md ${maxRetailPriceExceeded() ? "font-weight-bold text-red" : ""}`}>{money.format(vehicle.retail_price)}</p>
          </div>
        </div>

        <h6 className="my-4 text-uppercase">Mileage history</h6>

        <table className="table table-bordered">
          <thead className="bg-light">
            <tr>
              <th width="200px">Date recorded</th>
              <th>Accurate mileage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{vehicle.mileage_date ? formatDate(vehicle.mileage_date) : '-'}</td>
              <td className={`${maxMileageExceeded() ? "font-weight-bold text-red" : ""}`}>{vehicle.mileage ? prettyNumber(vehicle.mileage) : '-'} miles</td>
            </tr>
          </tbody>
        </table>
      </div>
    }

    <div className="col-12 pl-0 pr-4">
      <SlideToggle isVisible={vehicleEdit}>
        <VehicleDetailsEdit
          vehicle={vehicle}
          onCancel={() => {
            setVehicleEdit(false)
          }}
          initialValues={{...vehicle, reg_date: moment(vehicle.reg_date).toDate()}}
          onSubmit={handleVehicleEditSubmit}
          handleVRMLookup={handleVRMLookup}
          lookupData={lookupData}
          manualInfo={[useManualInfo, setUseManualInfo]}
        />
      </SlideToggle>
    </div>

      <div className="pt-1 col">
        <hr className="mt-4" style={{ height: 20 }} />
      </div>

    </div>
  )
}

VehicleDetails.propTypes = {
  vehicle: PropTypes.object.isRequired,
  allowEdit: PropTypes.bool,
}

export default VehicleDetails
