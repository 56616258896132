import React, {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import { connect } from 'react-redux'
import CreateForm from '../../../../Admin/SpecialVehicles/forms/create'
import { convertValues } from '../../../../Admin/SpecialVehicles/forms/helpers'
import { createVehicle } from "./helpers"
import { history } from '../../../../../../utils/history'
import { NotificationManager } from 'react-notifications'
import { reset } from 'redux-form'
import { get } from 'lodash'
import { confirmAlert } from 'react-confirm-alert'
import api from "../../../../../../utils/api";

const DealerSpecialVehiclesCreate = ({ dispatch }) => {

  const [dealer, setDealer] = useState(null);
  const params = useParams()
  useEffect(() => {
    api
      .get(`/external-sales/dealers/${params.id}`)
      .then((res) => {
        setDealer(res.data)
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing'
          )
        }
      })
  }, [params.id])

  const onSubmit = (values) => {
    createVehicle(dealer.id, convertValues(values, 'Dealer'))
      .then(() => {
        dispatch(reset('special-vehicles/create'))
        history.push(`/external-sales/dealers/${dealer.id}/edit`)
        NotificationManager.success('Woohoo! Your vehicle was saved!')
      })
      .catch((err) => {
        if (
          get(err.response, 'data.errors', false) &&
          Object.keys(get(err.response, 'data.errors', false)).filter((key) =>
            key.startsWith('models.')
          )
        ) {
          let errorString = err.response.data.errors[
          get(err.response, 'data.errors', false) &&
          Object.keys(
            get(err.response, 'data.errors', false)
          ).filter((key) => key.startsWith('models.'))[0]
            ].join(' ')
          confirmAlert({
            title: '🧐 Uh-oh!',
            message: errorString,
            customUI: ({ title, message, onClose }) => {
              return (
                <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                    <span
                      className="btn-close-modal fa fa-close"
                      onClick={onClose}
                    />
                    <h3>{title}</h3>
                    <p>{message}</p>

                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={onClose}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              )
            },
          })
        } else {
          NotificationManager.error(
            'Oops! Something went wrong saving your vehicle. Please try again.'
          )
        }
      })
  }

  return (<>
    {dealer && <div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={`/external-sales/dealers/`}>
              Dealers
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/external-sales/dealers/${dealer.id}/edit`} className="open active">
              {dealer.name}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">Add a special vehicle</span>
          </li>
        </ol>

        <div className="container-fluid">
          <CreateForm
            onSubmit={onSubmit}
            target={'Dealer'}
            onCancelRedirectTo={'/external-sales/dealers/' + dealer.id + '/special-vehicles/dealer'}
            dealer={dealer}
          />
        </div>
      </div>}
    </>
  )
};

DealerSpecialVehiclesCreate.propTypes = {}

export default connect((state) => ({}))(DealerSpecialVehiclesCreate)
