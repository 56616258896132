import React, { Component } from 'react'
import LoginForm from './LoginForm'
import * as S from '../Login.style'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getFormValues, isValid } from 'redux-form'
import { resetForm } from '../../../../actions/cover-levels.actions'
import { login } from '../../../../actions/authentication.action'

class LoginPage extends Component {

  handleSubmit = values => this.props.login({...values})

  render() {
    return (
      <S.LoginPageWrapper>
        <div className="page-column">
          <LoginForm onSubmit={this.handleSubmit}/>

          <div className="meta-links">
            <div>
              Don’t have an account yet? <Link to="/policyholder-portal/register">Register</Link>
            </div>
            <div>
              Are you a car dealer? <Link to="/">Dealer login</Link>
            </div>
            <div className="font-xs tos">
              By signing in, you agree to the{' '}
              <Link to="#">Terms of Service</Link>{' '}
              and <Link to="#">Privacy Policy</Link>
            </div>
          </div>
        </div>

        <div className="page-column bg-primary">
          <S.HaveQuestions>
            <h1 className="text-white">Have a question?</h1>
            <p className="mb-4">
              You can sign in to your account to access and update your policy information, to make a claim or to notify us of any changes.
            </p>
            <p className="mb-4">
              If you have any questions please get in touch and speak to one of our advisors.
            </p>
            <h3 className="heading-call-us">
              Give us a call
              <Link
                to="tel:+441733830278"
                className="heading-font text-warning"
              >
                01733 830278
              </Link>
            </h3>
          </S.HaveQuestions>
        </div>
      </S.LoginPageWrapper>
    )
  }
}

export default connect(
  state => ({
    valid: isValid('auth/login')(state),
    values: getFormValues('auth/login')(state),
  }),
  {resetForm, login},
)(LoginPage)
