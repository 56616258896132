import {
  ADD_PRICE_BAND_TO_TABLE,
  FETCH_PRICE_BANDS_FAILURE,
  FETCH_PRICE_BANDS_REQUEST,
  FETCH_PRICE_BANDS_SUCCESS,
  FETCH_SINGLE_PRICE_BAND_FAILURE,
  FETCH_SINGLE_PRICE_BAND_REQUEST,
  FETCH_SINGLE_PRICE_BAND_SUCCESS,
  SEARCH_PRICE_BANDS,
} from '../../../constants'

const initialState = {
  isPending : true,
  data      : null,
  error     : null,
  priceBands: null,
  searching : false,
}

export function priceBands(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRICE_BANDS_REQUEST:
      return {
        ...state,
        isPending: true,
      }
    case FETCH_PRICE_BANDS_SUCCESS:
      return {
        ...state,
        isPending: false,
        data     : action.payload.priceBands,
      }
    case FETCH_PRICE_BANDS_FAILURE:
      return {
        ...state,
        isPending: false,
        data     : null,
        errors   : { ...action.payload.errors },
      }
    case SEARCH_PRICE_BANDS:
      return {
        ...state,
        data: action.payload.priceBands,
      }
    case FETCH_SINGLE_PRICE_BAND_REQUEST:
      return {
        isPending: true,
      }

    // eslint-disable-next-line no-undef
    case FETCH_SINGLE_PRICE_BAND_SUCCESS:
      return {
        isPending: false,
        priceBand: action.payload.priceBand,
      }

    case FETCH_SINGLE_PRICE_BAND_FAILURE:
      return {
        isPending: false,
        priceBand: null,
        error    : action.payload.error,
      }
    case ADD_PRICE_BAND_TO_TABLE:
      return {
        ...state,
        data: {
          ...state.data,
          data: [
            ...state.data.data,
            action.payload.priceBand
          ],
        },
        error: action.payload.error
      }
    default:
      return state
  }
}
