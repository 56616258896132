import React, { Fragment } from 'react'
import PageHeader from '../../../ui/PageHeader'
import { Table } from '../../../ui/Table'
import { Link } from 'react-router-dom'
import { sortCaret } from '../../../../utils/misc'
import { uniqueId } from 'lodash'
import api from "../../../../utils/api";
import {NotificationManager} from "react-notifications";
import moment from "moment";
import { hasPermission } from '../../../../utils/auth';

class Policies extends React.Component {
  state = {
    filterGroups: [
      {
        name        : 'status',
        allowOnlyOne: true,
        filters     : [
          {id: uniqueId(), name: 'Online Purchase in Progress', value: 'Online Purchase in Progress'},
          {id: uniqueId(), name: 'Draft', value: 'Draft'},
          {id: uniqueId(), name: 'Awaiting Approval', value: 'Awaiting Approval'},
          {id: uniqueId(), name: 'Rejected', value: 'Rejected'},
          {id: uniqueId(), name: 'Approved', value: 'Approved'},
          {id: uniqueId(), name: 'Cancelled', value: 'Cancelled'},
          {id: uniqueId(), name: 'Transferred', value: 'Transferred'},
          {id: uniqueId(), name: 'Expired', value: 'Expired'},
        ],
      },
    ],
    query       : '',
    page        : '',
    order       : 'ref',
    desc        : true,
    limit       : 10,
    status      : '',
    policies    : null,
    searching: false,
  }

  fetchData = url => {
    api.get(`/claims/policies${url ? url : ''}`)
        .then(res => {
          this.setState({ policies: res.data, searching: false })
        })
        .catch(err => {
          if (err) {
            NotificationManager.error('Oops! Something went wrong. Please try again after refreshing.')
          }
        })
  }
  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    let url = `?page=${page}&limit=${sizePerPage}&query=${this.state.query}`
    if (sortField){
      url = `${url}&order=${sortField}&desc=${sortOrder === 'desc' ? true : false}`
    }
    this.fetchData(url)
  }

  handleSearch = (query, filters) => {
    let status = ''

    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]
      if (filter.group_name === 'status') {
        status = filter.name
      }
    }

    this.setState(
      {
        query,
        status,
        page: 1
      },
      this.buildUrl,
    )
  }

  /**
   * Builds URL and to fetch policies
   */
  buildUrl = () => {
    const { query, page, order, desc, limit, status } = this.state

    let url = `?query=${query}&page=${page}&limit=${limit}`

    if (order) {
      url += `&order=${order}&desc=${desc}`
    }
    if (status !== '') {
      url += `&status=${status}`
    }

    this.setState({ searching: true })
    this.fetchData(url)
  }

  componentDidMount() {
    this.buildUrl()
  }


  render() {

    const options = {
      showOptions: false,
      columns    : [
        {
          dataField  : 'ref',
          text       : 'Policy ID',
          headerStyle: {
            width: 150,
          },
          formatter  : (cell, row) => <Link to={`/claims/policies/${row.id}/policy`}>{row.ref}</Link>,
          sort       : true,
          sortCaret  : () => sortCaret('desc'),
        },

        {
          dataField: 'purchased_from',
          text     : 'Purchased from',
          formatter: (cell, row) => {
            if (row.dealer) {
              return <Link to={`/claims/dealers/${row.dealer.id}/timeline`}>{row.dealer.name}</Link>
            }
            return row.purchased_from
          }
        },
        {
          dataField: 'cover_level.name',
          text     : 'Cover level',
        },
        {
          dataField: 'policyholder_name',
          text     : 'Policyholder name',
          formatter: (cell, row) => <Link to={`/claims/policyholders/${row.policyholder.id}/timeline`}>{row.policyholder.title} {row.policyholder.first_name} {row.policyholder.last_name}</Link>,
        },
        {
          dataField: 'vehicle.vrm',
          text     : 'Vehicle reg',
        },
        {
          dataField: 'start_date',
          text     : 'Start date',
          sort     : true,
          sortCaret,
          formatter: (cell, row) => moment(row.start_date).format('DD/MM/YYYY')
        },
        {
          dataField: 'duration',
          text     : 'Duration',
        },
        {
          dataField: 'status',
          text     : 'Status',
          sort     : true,
          sortCaret,
        },
      ],
    }

    if (hasPermission('claims.policies.update')) {
      options.columns.push(
        {
          dataField  : '',
          text       : 'Actions',
          headerStyle: {
            width: 80,
          },
          formatter  : (cell, row) => (
            <Link
              data-tip="Edit policy"
              data-place="top"
              to={`/claims/policies/${row.id}/policy`}
              className="table-action-btn table-action-btn--edit"
              size="sm"
            >
              <i className="wf-icon-edit"/>
            </Link>
          )
        },
      )
    }

    return (
      <Fragment>
        <br/>
        <div className="container-fluid animated fadeIn">
          <PageHeader
            title="Policies"
            description={
              'A list of all Warranty First policies. You can view all contact history between Warranty First and a customer by viewing their timeline.'
            }
          />

          {this.state.policies && (
            <Table
              resource={this.state.policies}
              selected={[]}
              searching={this.state.searching}
              showOptions={options.showOptions}
              omnibox={{ groups: this.state.filterGroups }}
              handleTableChange={this.handleTableChange}
              columns={options.columns}
              hasTableSearch={true}
              onSearch={this.handleSearch}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

export default Policies
