import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { change, Field, Form, reduxForm } from 'redux-form'
import renderField from '../../../../../../utils/renderField'
import { formatPostcode, postcodeValidator } from '../../../../../../utils/misc'
import { required } from 'redux-form-validators'

const DealerDetailsForm = (props) => {
  const { handleSubmit, onSubmit, valid, dirty, toggle, vatRegistered } = props
  const [currentVatRegistered, setCurrentVatRegistered] = useState(vatRegistered)

  const handleFormatPostcode = (event, value) => {
    event.preventDefault()

    const formatted = formatPostcode(value)
    props.dispatch(
      change('dealer/update-dealer-details', 'postcode', formatted, true)
    )
  }

  const handleVatRegisteredChange = (event) => {
    const vatRegistered = event.target.checked
    setCurrentVatRegistered(vatRegistered)
    if (!vatRegistered) {
      props.dispatch(
        change('dealer/update-dealer-details', 'vat_number', null, true)
      )
    }
  }

  return (
    <div className="my-3 px-1">
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Dealership name"
              component={renderField}
              name={`name`}
              className="form-control"
              placeholder={'e.g. 123 Cars'}
              validate={[
                required({
                  message: 'You must enter a dealership name.',
                }),
              ]}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Address"
              component={renderField}
              name={`address_1`}
              className="form-control"
              validate={[
                required({
                  message: 'You must enter the first line of address.',
                }),
              ]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label=""
              component={renderField}
              name={`address_2`}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label=""
              component={renderField}
              name={`address_3`}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="City"
              component={renderField}
              name={`city`}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="County"
              component={renderField}
              name={`county`}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Postcode"
              component={renderField}
              name={`postcode`}
              className="form-control"
              onBlur={handleFormatPostcode}
              validate={[
                required({ message: 'You must enter a postcode.' }),
                postcodeValidator(),
              ]}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              type="checkbox"
              id={'vatRegistered'}
              label="This dealer is VAT registered"
              component={renderField}
              name={`vat_registered`}
              onChange={handleVatRegisteredChange}
            />
          </div>
        </div>

        {currentVatRegistered && (
          <div className="row">
            <div className="col">
              <Field
                type="text"
                label="VAT number"
                component={renderField}
                name={`vat_number`}
                className="form-control"
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col mt-3">
            <div className="form-group">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!valid || !dirty}
              >
                Save changes
              </button>
              <button
                type="button"
                className="btn btn-outline btn-form-cancel"
                onClick={() => toggle(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

DealerDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'dealer/update-dealer-details',
})(DealerDetailsForm)
