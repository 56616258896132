import { combineReducers } from 'redux'
import { authentication } from './authentication.reducer'
import { dealerCoverLevels } from './dealer-cover-levels.reducer'
import { userGroups } from './user-groups.reducer'
import { users } from './users.reducer'
import { territories } from './territories.reducer'
import { reducer as form } from 'redux-form'
import { publicCoverLevels } from './public-cover-levels.reducer'
import { directSalesCoverLevels } from './direct-sales-cover-levels.reducer'
import { pipelines } from './pipelines.reducer'
import { directSales } from './direct-sales'
import priceBands from './admin/price-bands'
import { claims } from "./claims"
import { externalSales } from './external-sales'

const rootReducer = combineReducers({
  authentication,
  users,
  userGroups,
  publicCoverLevels,
  directSalesCoverLevels,
  dealerCoverLevels,
  territories,
  form,
  pipelines,
  priceBands,
  directSales,
  claims,
  externalSales,
})

export default rootReducer
