import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PageHeader from '../../../ui/PageHeader'
import { Table } from '../../../ui/Table'
import { Link } from 'react-router-dom'
import { sortCaret } from '../../../../utils/misc'
import { fetchPolicyHolders, searchPolicyHolders } from '../../../../actions/direct-sales/policy-holders.actions'
import {uniq, uniqueId} from "lodash";

class PolicyHoldersPage extends React.Component {
  state = {
    filterGroups: [
      {
        name: 'status',
        allowOnlyOne: true,
        filters: [
          { id: uniqueId(), name: 'Draft', value: 'Draft' },
          { id: uniqueId(), name: 'Awaiting Approval', value: 'Awaiting Approval' },
          { id: uniqueId(), name: 'Rejected', value: 'Rejected' },
          { id: uniqueId(), name: 'Approved', value: 'Approved' },
          { id: uniqueId(), name: 'Cancelled', value: 'Cancelled' },
          { id: uniqueId(), name: 'Transferred', value: 'Transferred' },
          { id: uniqueId(), name: 'Expired', value: 'Expired' },
        ],
      },
    ],
    query: '',
    page: '',
    desc: '',
    limit: 10,
    status: '',
  }

  componentDidMount() {
    const {filterGroups, ...params} = this.state

    const url = new URLSearchParams(params).toString()
    this.props.fetchPolicyHolders('?' + url)
  }

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    let url = `?page=${page}&desc=${
      sortOrder === 'desc' ? true : false
      }&limit=${sizePerPage}&query=${this.state.query}`
    if (sortField) url = `${url}&order=${sortField}`

    this.props.fetchPolicyHolders(url)
  }

  handleSearch = (query) => {
    this.setState(
      {
        query,
      },
      this.buildUrl
    )
  }

  /**
   * Builds URL and to fetch policyholders
   */
  buildUrl = () => {
    const { query, page, desc, limit, status } = this.state

    let url = `?query=${query}&page=${page}&desc=${desc}&limit=${limit}`

    if (status !== '') url = `${url}&status=${status}`

    this.props.searchPolicyHolders({ searching: true })
    this.props.fetchPolicyHolders(url)
  }

  render() {
    const { policyHolders, isPending, searching } = this.props

    const options = {
      showOptions: false,
      columns    : [
        {
          dataField: 'name',
          text: 'Policyholder name',
          formatter: (cell, row) => <Link to={`/direct-sales/policyholders/${row.id}/timeline`}>{row.title} {row.first_name} {row.last_name}</Link>,
          sort: true,
          sortCaret,
        },

        {
          dataField: 'ref',
          text     : 'Associated policy ID/s',
          formatter: (cell, row) => (
            row.policies.map((policy, index) => [
              index > 0 && ', ',
              <Link
                key={index}
                to={`/direct-sales/policies/${policy.id}/policy`}
              >
                {policy.ref}
              </Link>,
            ])
          ),
        },

        {
          dataField: 'vehicle.vrm',
          text     : 'Vehicle reg',
          formatter: (cell, row) => {
            let regs = row.policies.map((policy) => {
              return policy.vehicle.vrm
            })
            let uniqRegs = uniq(regs)

            return uniqRegs.map((policy, index) => [index > 0 && ', ', policy])
          },
        },
      ],
    }

    return (
      <Fragment>
        <br/>
        <div className="container-fluid animated fadeIn">
          <PageHeader
            title="Policyholders"
            description={
              'A list of all Active Warranty First policyholders. You can view all contact history between Warranty First and a customer by clicking on their name and viewing their timeline.'
            }
          />

          {policyHolders && (
            <Table
              resource={policyHolders}
              selected={[]}
              loading={isPending}
              showOptions={options.showOptions}
              handleTableChange={this.handleTableChange}
              columns={options.columns}
              hasTableSearch={true}
              onSearch={this.handleSearch}
              searching={searching}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

export default connect(
  state => ({
    isPending    : state.directSales.policyHolders.isPending,
    searching    : state.directSales.policyHolders.searching,
    policyHolders: state.directSales.policyHolders.data,
  }),
  { fetchPolicyHolders, searchPolicyHolders },
)(PolicyHoldersPage)
