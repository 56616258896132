import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form, reduxForm } from 'redux-form'
import renderField from '../../../../../../utils/renderField'
import CustomSelect from '../../../../../ui/CustomSelect'
import { email, required } from 'redux-form-validators'

const InvoicingForm = (props) => {
  const { handleSubmit, onSubmit, valid, cancel, dirty } = props

  return (
    <div className="my-3 px-1">
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row">
          <div className="col">
            <Field
              type="checkbox"
              id={'showRegistration'}
              label="Show registration on invoice"
              component={renderField}
              name={`show_vrms_on_invoice`}
              style={{ marginBottom: 0 }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              type="checkbox"
              id={'excludeDebtorLedger'}
              label="Exclude from debtor ledger"
              component={renderField}
              name={`exclude_from_debtor_ledger`}
              style={{ marginBottom: 0 }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              label="Invoice cycle"
              component={CustomSelect}
              name={`invoice_cycle`}
              id={'invoiceCycle'}
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.value}
              validate={[
                required({ message: 'You must select an invoice cycle.' }),
              ]}
              options={[
                {
                  value: 'Weekly',
                },
                {
                  value: 'Monthly',
                },
              ]}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Invoice email address"
              component={renderField}
              name={`invoice_email`}
              className="form-control"
              validate={[
                required({ msg: 'You must enter an email address.' }),
                email({
                  message: "That email address doesn't look quite right.",
                }),
              ]}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!valid || !dirty}
              >
                Save changes
              </button>
              <button
                type="button"
                className="btn btn-outline btn-form-cancel"
                onClick={() => cancel(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

InvoicingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'dealer/invoicing',
})(InvoicingForm)
