import React from 'react'
import PropTypes from 'prop-types'
import { ucFirst } from '../../utils/misc'

function FilterOptions(props) {
  return (
    <div className="option px-3 py-2" onClick={props.onClick}>
      {ucFirst(props.group.name)}
      <i className="arrow icon-arrow-right font-weight-bold" />
      <div className="option-filters">
        {' '}
        {/* eslint-disable-next-line*/}
        {props.filtersOpen &&
          props.group.filters.filter(props.filterQuery).length === 0 && (
            <span className="d-block px-3 py-2 filter-text">
              Sorry, there are no results for {props.localQuery}…
            </span>
          )}
        {props.group && props.group.filters.map(props.searchFilters)}
      </div>
    </div>
  )
}


FilterOptions.propTypes = {
  onClick: PropTypes.func,
  group: PropTypes.any,
  filtersOpen: PropTypes.bool,
  filterQuery: PropTypes.func,
  localQuery: PropTypes.string,
  searchFilters: PropTypes.func.isRequired
}

export default FilterOptions
