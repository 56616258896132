import React, {useState, useEffect} from 'react'
import {Link, useParams} from "react-router-dom";
import api from "../../../utils/api";
import {NotificationManager} from 'react-notifications'
import DSPolicyDetails from "../DirectSales/Policies/Show/PolicyDetails";
import ESPolicyDetails from "../ExternalSales/Policies/Show/PolicyDetails";
import TransferPolicyForm from "./TransferPolicyForm";
import {get} from 'lodash'
import {history} from "../../../utils/history";

const TransferPolicy = ({target}) => {
  const params = useParams()
  const [policy, setPolicy] = useState()
  useEffect(() => {
    if (params.id) {
      api.get(`/${target}/policies/${params.id}`)
        .then(res => setPolicy(res.data))
        .catch(() => NotificationManager.error('Oops! Something went wrong retrieving the policy to transfer'))
    }
  }, [params.id])

  const handleTransferFormSubmit = (values) => {
    let data = {
      title: values.title.name,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      tel1: values.tel_mobile,
      tel2: values.tel_home,
      address_1: get(values, 'address_1', get(values, 'address.address_1')),
      address_2: get(values, 'address_2', get(values, 'address.address_2')),
      address_3: get(values, 'address_2', get(values, 'address.address_3')),
      city: get(values, 'city', get(values, 'address.city')),
      county: get(values, 'county', get(values, 'address.county')),
      postcode: get(values, 'postcode', get(values, 'address.postcode')),
    }
    api.post(`${target}/policies/${policy.id}/transfer`, data)
      .then(res => {
        NotificationManager.success('Woohoo! This policy was transferred successfully.')
        history.push(`/${target}/policies/${res.data.id}/policy`)
      })
      .catch(() => {
        NotificationManager.error('Oops! There was a problem transferring this policy. Please try again.')
      })
  }

  return <>
    {policy && <div>
    <ol className="breadcrumb">
      <li className="breadcrumb-item">
        <Link to={`${target}/policies`} className="active">
          Policies
        </Link>
      </li>
      <li className="breadcrumb-item">
        <Link to={`/${target}/policies/${policy.id}/policy`} className="open active">
          {policy.ref}
        </Link>
      </li>
      <li className="breadcrumb-item">
        <span className="active">Transfer Policy</span>
      </li>
    </ol>
      <div className="container">
        <header className="py-4 d-flex align-items-center justify-content-between border-bottom">
          <div>
            <div className="d-flex">
              <h3 style={{ fontSize: 36 }}>
                Transfer Policy {' '}
                <span className="font-xl mt-2- fw-400 text-gray-700">
                    {policy.ref}
                  </span>
              </h3>
            </div>
          </div>
        </header>
        <div className="row">
          <div className="col-8 border-right mb-5">
            <TransferPolicyForm onSubmit={handleTransferFormSubmit} />
          </div>
          <div className="col-4">
            {target === 'direct-sales' ? <DSPolicyDetails disableEdit={true} policy={policy} /> : <ESPolicyDetails disableEdit={true} policy={policy} />}
          </div>
        </div>
      </div>
  </div>}
  </>
}

export default TransferPolicy