import React, { Fragment } from 'react'
import PrivateRoute from '../components/PrivateRoute'
import ProfilePage from '../components/pages/Profile'

function AdminRoutes() {
  return (
    <Fragment>
      {/**
       * Account Details
       */}
      <PrivateRoute exact path={`/account/profile`} component={ProfilePage} />
    </Fragment>
  )
}

export default AdminRoutes