import React from 'react'
import PrivateRoute from "../components/PrivateRoute";

import AccountDetails from "../components/pages/PolicyholderPortal/AccountDetails";
import Policies from "../components/pages/PolicyholderPortal/Policies";
import Policy from '../components/pages/PolicyholderPortal/Policies/Show';
import Claims from "../components/pages/PolicyholderPortal/Claims";
import Claim from '../components/pages/PolicyholderPortal/Claims/Show';
import CreateClaim from '../components/pages/PolicyholderPortal/Claims/Create';
import Billing from '../components/pages/PolicyholderPortal/Billing';
import DirectDebit from '../components/pages/PolicyholderPortal/Billing/DirectDebit';

const PolicyholderPortalRoutes = () => {
  return <>
    <PrivateRoute path={`/policyholder-portal/account-details`} exact component={AccountDetails} />
    <PrivateRoute path={`/policyholder-portal/policies`} exact component={Policies} />
    <PrivateRoute exact path={`/policyholder-portal/policies/:id/policy`} component={Policy}/>
    <PrivateRoute path={`/policyholder-portal/claims`} exact component={Claims} />
    <PrivateRoute exact path={`/policyholder-portal/claims/:id/claim`} component={Claim}/>
    <PrivateRoute path={`/policyholder-portal/claims/create`} exact component={CreateClaim} />
    <PrivateRoute path={`/policyholder-portal/billing`} exact component={Billing} />
    <PrivateRoute path={`/policyholder-portal/billing/direct-debit`} exact component={DirectDebit} />
  </>
}

export default PolicyholderPortalRoutes
