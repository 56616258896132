import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import * as PropTypes from 'prop-types'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import api from '../../../utils/api'
import moment from 'moment'
import AddAccountManager from './AddAccountManager'

const AssignTerritoryAccountManager = props => {
  const { territory } = props
  const [addModal, setAddModal] = useState(false)

  /**
   * Fetch account managers to populate the dropdown
   */
  const getAllAccountManagers = query =>
    new Promise((resolve, reject) => {
      api
        .get(`admin/territories/account-managers?query=${query ? query : ''}&limit=999999`)
        .then(res => {
          resolve(res.data.data)
        })
        .catch(() => reject([]))
    })

  const handleSubmit = values => {
    return new Promise((resolve, reject) => {
      api
        .post(`admin/territories/account-managers`, { ...values })
        .then(res => {
          let start_date =
            res.data.start_date &&
            moment(res.data.start_date).format('DD/MM/YYYY')
          let end_date =
            res.data.end_date && moment(res.data.end_date).format('DD/MM/YYYY')
          let current_end_date =
            values.current_end_date &&
            moment(values.current_end_date).format('DD/MM/YYYY')

          props.addAccountManager({
            ...res.data,
            start_date,
            end_date,
            current_end_date,
          })

          props.fetchTerritory(territory.id)

          resolve(res.data)
        })
        .catch(err => {
          reject(err.response)
        })
    })
  }

  return (
    <>
      <div className="row">
        <div className="col" style={{ marginTop: '2.1rem' }}>
          <hr style={{ marginBottom: '3.1rem' }} />
          <h6 className="title-xs">Territory Account Managers</h6>

          <Link
            to={'#'}
            className="font-md title-xs"
            style={{
              marginBottom: '35px',
              display: 'block',
              fontWeight: 400,
              marginTop: '1rem',
            }}
            onClick={() => setAddModal(true)}
          >
            Assign new Account Manager
          </Link>

          {addModal && (
            <div className="react-confirm-alert-overlay">
              <div className="react-confirm-alert">
                <div
                  className="react-confirm-alert-body"
                  style={{ width: '40rem' }}
                >
                  <span
                    className="btn-close-modal fa fa-close"
                    onClick={() => setAddModal(false)}
                  />

                  <h3 className="mb-0">Assign new Account Manager</h3>
                  <h4
                    className="text-black-50 font-weight-normal text-capitalize mt-1 mb-4"
                    style={{ fontSize: '1em' }}
                  >
                    {territory.name}
                  </h4>

                  <AddAccountManager
                    territory={territory}
                    getAccountManagers={getAllAccountManagers}
                    handleSubmit={handleSubmit}
                    onClose={() => setAddModal(false)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

AssignTerritoryAccountManager.propTypes = {
  territory: PropTypes.object.isRequired,
  addAccountManager: PropTypes.func.isRequired,
}

export default AssignTerritoryAccountManager
