import React from 'react';
import { Form, Field, reduxForm, reset, change } from 'redux-form'
import { required, email } from 'redux-form-validators';
import renderField from '../../../../../utils/renderField';
import { formatPostcode, validPhoneNumber, validPostcode } from '../../../../../utils/misc'

const EditProspectDetails = ({ handleSubmit, valid, onCancel, dispatch, dirty }) => {

  const handleFormatPostcode = (event, value) => {
    event.preventDefault();

    const formatted = formatPostcode(value);
    dispatch(change('edit-prospect-details', 'postcode', formatted, true));
    dispatch(change('edit-prospect-details', 'postcode', formatted, true));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col">
          <Field
            type="text"
            label="Address"
            component={renderField}
            name={`address_1`}
            className="form-control"
            validate={[
              required({ message: 'You must enter the first line of address_'})
            ]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Field
            type="text"
            label=""
            component={renderField}
            name={`address_2`}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Field
            type="text"
            label=""
            component={renderField}
            name={`address_3`}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Field
            type="text"
            label="City"
            component={renderField}
            name={`city`}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Field
            type="text"
            label="County"
            component={renderField}
            name={`county`}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Field
            type="text"
            label="Postcode"
            component={renderField}
            name={`postcode`}
            className="form-control"
            onBlur={handleFormatPostcode}
            validate={[
              required({ message: 'You must enter a postcode.'})
            ]}
          />
        </div>
      </div>

      <Field
        type="email"
        label="Email"
        name="email"
        component={renderField}
        className="form-control"
        validate={[
          required({ message: 'You must enter an email address_' }),
          email({
            message: "That email doesn't look quite right.",
          }),
        ]}
      />

      <div className="row">
        <div className="col-md-6">
          <Field
            type="text"
            label="Telephone (home)"
            component={renderField}
            name={`tel1`}
            className="form-control"
          />
        </div>
        <div className="col-md-6">
          <Field
            type="text"
            label="Telephone (mobile)"
            component={renderField}
            name={`tel2`}
            className="form-control"
          />
        </div>
      </div>

      <div className="d-flex">
        <button
          className="btn btn-secondary"
          type="submit"
          name="submit"
          disabled={!valid || !dirty}
        >
          Save changes
        </button>

        <button
          type="button"
          className="btn btn-transparent text-secondary"
          onClick={() => onCancel() && dispatch(reset('edit-prospect-details'))}
        >
          Cancel
        </button>
      </div>
    </Form>
  );
};

const validate = (fields) => {
  let errors = {};

  if (!fields.tel1 && !fields.tel2)
    errors.tel1 = 'You must enter at least one telephone number.';

  if (fields.tel1 && !validPhoneNumber(fields.tel1))
    errors.tel1 = "That phone number doesn't look quite right.";

  if (fields.tel2 && !validPhoneNumber(fields.tel2))
    errors.tel2 = "That phone number doesn't look quite right.";

  if (fields.postcode && !validPostcode(fields.postcode)) {
    errors.postcode = 'That postcode doesn\'t look quite right.'
  }

  return errors;
};

export default reduxForm({
  form: 'edit-prospect-details',
  validate,
})(EditProspectDetails);
