import React, { useState } from 'react';
import { Field, change } from 'redux-form';
import renderField from '../utils/renderField';
import { Link } from 'react-router-dom';
import { API_URL } from '../utils/constants';
import api from '../utils/api';
import { NotificationManager } from 'react-notifications';
import { formatPostcode, postcodeValidator } from '../utils/misc';
import { useDispatch } from 'react-redux';
import { required } from 'redux-form-validators';
import CustomSelect from '../components/ui/CustomSelect';
import SlideToggle from '../components/ui/SlideToggle';
import PropTypes from 'prop-types'

export function usePostcodeLookupWithRedux(form, allowNullPostcode, requiredFields = []) {

  const [addressForm, setAddressForm] = useState(false);
  const [addressFieldVisible, setAddressFieldVisible] = useState(false);

  const [addresses, setAddresses] = useState();
  const dispatch = useDispatch();

  const forceManualAddress = () => {
    setAddressForm(true)
  }

  const handleLookup = (postcode) => {
    dispatch(change(form, 'address', null, false));
    api
      .get(`${API_URL}/misc/postcode-lookup?postcode=${postcode}&expand=1`)
      .then((res) => {
        let data = res.data.addresses.map((row) => {
          // if there is no second line of address and no third line of address
          // get the locality as that will be enough given the fact that they will have 1 line of address any way
          let address_2 = row.line_2.length === 0 ? row.locality : row.line_2;
          let address_3 = row.line_3.length === 0 ? row.locality : '';

          return {
            formatted_address: row.formatted_address
              .filter((r) => r)
              .join(', '),
            address_1: row.line_1,
            address_2,
            address_3: address_2 === address_3 ? '' : address_3,
            city: row.town_or_city,
            county: row.county,
            postcode,
          };
        });

        setAddresses(data);
        setAddressFieldVisible(true);
      })
      .catch(() => {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again.'
        );
      });
  };

  const handleFormatPostcode = (event, value) => {
    event.preventDefault();

    const formatted = formatPostcode(value);
    dispatch(change(form, 'postcode', formatted, true));
    dispatch(change(form, 'address.postcode', formatted, true));
  };

  const validatePostcode = () => {
    if (allowNullPostcode) {
      return []
    } else {
      return [
        required({ message: 'You must enter a postcode.' }),
        postcodeValidator(),
      ]
    }
  }

  const markup = (
    <>
      {!addressForm && (
        <div className="row">
          <div className="col mb-2">
            <Field
              type="text"
              label={requiredFields.includes('postcode') ? "Postcode lookup *" : "Postcode lookup"}
              component={renderField}
              name={`postcode`}
              validate={validatePostcode()}
              className="form-control"
              button="Lookup address"
              handleSubmit={handleLookup}
              onBlur={handleFormatPostcode}
            />
            {/* <div className="invalid-feedback d-block js--press-lookup" style={{
              margin: '-12px 0 12px 0'
            }}>You must lookup the address and select one.</div> */}
            <div className="d-flex font-md">
              <span>Or&nbsp;</span>
              <Link
                to="#"
                className="mb-2 d-block"
                onClick={(e) => {
                  e.preventDefault();
                  setAddressFieldVisible(false);
                  setAddressForm(true);
                }}
              >
                enter address manually
              </Link>
            </div>
          </div>
        </div>
      )}

      <SlideToggle isVisible={addressFieldVisible}>
        <div className="row">
          <div className="col">
            <Field
              label={`Please select the correct address${requiredFields.includes('address_1' ? ' *' : '')}`}
              component={CustomSelect}
              name={`address`}
              id={'addresses'}
              options={addresses}
              validate={[required({ message: 'You must select an address.'})]}
              getOptionValue={(option) => option}
              getOptionLabel={(option) => option.formatted_address}
            />
          </div>
        </div>
      </SlideToggle>

      <SlideToggle isVisible={addressForm}>
        <>
          <div className="row">
            <div className="col">
              <Field
                type="text"
                label={requiredFields.includes('address_1') ? "Address *" : "Address"}
                component={renderField}
                name={`address_1`}
                className="form-control"
                validate={[
                  required({ message: 'You must enter the first line of address.'})
                ]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Field
                type="text"
                label=""
                component={renderField}
                name={`address_2`}
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Field
                type="text"
                label=""
                component={renderField}
                name={`address_3`}
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Field
                type="text"
                label="City *"
                component={renderField}
                name={`city`}
                className="form-control"
                validate={[
                  required({ message: 'You must enter city.'})
                ]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Field
                type="text"
                label="County"
                component={renderField}
                name={`county`}
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Field
                type="text"
                label={ requiredFields.includes('postcode') ? "Postcode *" : "Postcode"}
                component={renderField}
                name={`postcode`}
                className="form-control"
                onBlur={handleFormatPostcode}
                validate={validatePostcode()}
              />
            </div>
          </div>
        </>
      </SlideToggle>
    </>
  );
  return {
    markup,
    forceManualAddress
  };
}

usePostcodeLookupWithRedux.propTypes = {
  allowNullPostcode: PropTypes.bool,
}
usePostcodeLookupWithRedux.defaultProps = {
  allowNullPostcode: false,
}
