import React, { Component, Fragment } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import PageHeader from '../../ui/PageHeader'
import {
  deleteDealerCoverLevel,
  fetchDealerCoverLevels,
  searchCoverLevels,
} from '../../../actions/cover-levels.actions'
import { connect } from 'react-redux'
import { sortCaret } from '../../../utils/misc'
import Table from '../../ui/Table'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import AddIcon from '../../../images/icons/icn-plus.svg'
import ReactTooltip from 'react-tooltip'

const claimLimitsFormatter = (cell, row) => {
  return (
    <Fragment>
      {row.claim_limits.map((item, i) => {
        let limit = `£${item}, `

        if (i === row.claim_limits.length - 1) limit = `£${item}`

        return (
          <Link
            to={`/admin/cover-levels/${row.id}/edit`}
            style={{textDecoration : 'none', color : '#2d2c31'}}
            size="sm"
            key={i}
          >
            {limit}
          </Link>
        )
      })}
    </Fragment>
  )
}


class DealerCoverLevels extends Component {

  componentDidMount() {
    this.props.fetchDealerCoverLevels()
  }

  handleDelete = row => {
    confirmAlert({
      title : '🧐 Are you sure?',
      customUI : ({title, onClose}) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose}/>
              <h3>{title}</h3>
              <p>Delete the cover level <strong>{row.name}</strong>? It'll be lost forever.</p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  this.props.deleteDealerCoverLevel(row.id).then(() => {
                    this.props.fetchDealerCoverLevels(false);
                  })
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  handleTableChange = (type, {sortField, sortOrder, page, sizePerPage}) => {
    let url = `&page=${page}&desc=${
      sortOrder === 'desc' ? true : false
      }&limit=${sizePerPage}`
    if (sortField) url = `${url}&order=${sortField}`

    this.props.fetchDealerCoverLevels(url)
  }

  handleSearch = val => {
    this.props.searchCoverLevels(val, 'Dealer')
  }

  render() {
    const {isPending, dealerCoverLevels, searching} = this.props

    const options = {
      showOptions : false,
      columns : [
        {
          dataField : 'name',
          text : 'Name',
          sort : true,
          sortCaret,
          headerStyle : {
            width : '30%',
          },
        },
        {
          dataField : 'claim_limits',
          text : 'Claim limits',
          formatter : claimLimitsFormatter,
        },
        {
          dataField : 'vehicle_types',
          text : 'Vehicle Types',
          formatter : (cell, row) => row.vehicle_types.join(', ')
        },
        {
          dataField : 'id',
          text : 'Actions',
          headerStyle : {
            width : 100,
          },
          formatter : (cell, row) => (
            <div className="d-flex">
              <Link
                data-tip="Edit cover level"
                data-place="top"
                to={`/admin/dealer-cover-levels/${row.id}/edit`}
                className="table-action-btn"
                primary="true"
                size="sm"
              >
                <span className="wf-icon-edit"/>
              </Link>
              <Link
                data-tip="Delete cover level"
                data-place="top"
                to={`#`}
                className="table-action-btn table-action-btn--delete"
                size="sm"
                onClick={() => this.handleDelete(row)}
              >
                <span className="wf-icon-delete"/>
              </Link>
              <ReactTooltip effect="solid"/>
            </div>
          ),
        },
      ],
    }


    return (
      <Fragment>
        <br/>
        <div className="container-fluid animated fadeIn">
          <PageHeader
            title="Dealer cover levels"
            description="Set up, view and edit cover levels for all dealerships around the UK. Dealer price bands will be based on the cover levels you set up here. You can view an audit trail by clicking ‘show all activity’ at the bottom of the page."
            buttonLink={'/admin/dealer-cover-levels/create'}
            buttonText="Add a cover level"
            svgIcon={AddIcon}
          />

          <br/>
          {dealerCoverLevels && (
            <Table
              resource={dealerCoverLevels}
              selected={[]}
              loading={isPending}
              showOptions={options.showOptions}
              handleTableChange={this.handleTableChange}
              columns={options.columns}
              dispatch={this.props.dispatch}
              hasTableSearch
              onSearch={this.handleSearch}
              searching={searching}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDealerCoverLevels,
      dispatch,
      deleteDealerCoverLevel,
      searchCoverLevels,
    },
    dispatch,
  )

export default connect(
  state => ({
    isPending : state.dealerCoverLevels.isPending,
    dealerCoverLevels : state.dealerCoverLevels.data,
    searching : state.dealerCoverLevels.searching,
  }),
  mapDispatchToProps,
)(DealerCoverLevels)
