import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { echo, echoMoney } from '../../utils/misc'
import AdditionalInformationForm from './forms/AdditionalInformationForm'
import SlideToggle from './SlideToggle'
import { useDispatch } from 'react-redux'
import { reset } from 'redux-form'

const AdditionalInformation = ({ dealer, formName, onSubmit }) => {
  const [edit, setEdit] = useState(false)
  const dispatch = useDispatch()

  /**
   * Toggle the form and reset after user clicks cancel
   */
  const onCancel = () => {
    setEdit(false)
    if (formName) {
      dispatch(reset(formName))
    }
  }

  const handleSubmit = values => {
    return onSubmit(values).then(() => {
      setEdit(false)
    })
  }

  return (
    dealer && (
      <div className="border-bottom pb-4 mb-2">
        <header
          className="mb-4 d-flex align-items-center justify-content-between"
          style={{ marginTop: 40 }}
        >
          <h6 className="my-0 text-uppercase">Additional information</h6>
          <button
            onClick={() => setEdit(!edit)}
            className="btn-gray"
            data-tip="Edit additional information"
            data-place="top"
          >
            <i className="wf-icon-edit d-block" />
          </button>
        </header>

        {!edit && (
          <div className="row">
            <div className="col-4">
              <span className="label">Previous warranty provider</span>
              <p className="font-md">{echo(dealer.existing_warranty_provider)}</p>
            </div>

            <div className="col-4">
              <span className="label">Policies per month</span>
              <p className="font-md">{echo(dealer.policies_per_month)}</p>
            </div>

            <div className="col-4">
              <span className="label">Potential monthly revenue</span>
              <p className="font-md">{echoMoney(dealer.potential_revenue)}</p>
            </div>

            <div className="col-4">
              <span className="label">Number of cars in stock</span>
              <p className="font-md">{echo(dealer.cars_in_stock)}</p>
            </div>

            <div className="col-4">
              <span className="label">Lowest stock price</span>
              <p className="font-md">{echoMoney(dealer.min_stock_price)}</p>
            </div>

            <div className="col-4">
              <span className="label">Highest stock price stock</span>
              <p className="font-md">{echoMoney(dealer.max_stock_price)}</p>
            </div>

            <div className="col-12">
              <span className="label">Other</span>
              <p className="font-md">{echo(dealer.comments)}</p>
            </div>
          </div>
        )}

        <SlideToggle isVisible={edit}>
          <AdditionalInformationForm
            initialValues={{ ...dealer }}
            onSubmit={handleSubmit}
            cancel={onCancel}
          />
        </SlideToggle>
      </div>
    )
  )
}

AdditionalInformation.defaultProps = {
  onSubmit: () => {},
  formName: null
}

AdditionalInformation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  dealer: PropTypes.object.isRequired,
  formName: PropTypes.string
}

export default AdditionalInformation
