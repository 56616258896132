import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { uniqueId } from 'lodash'
import { NotificationManager } from 'react-notifications'
import api from '../../utils/api'
import Table from './Table'
import { sortCaret, specialVehicleTypeColor } from '../../utils/misc'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import useModal from '../../hooks/useModal'
import DeleteModal from '../modals/SingleDeleteModal'

const SpecialVehiclesTable = (props) => {
  const [state, setState] = useState({
    filters: [],
    filterGroups: [],
    query: '',
    page: 1,
    desc: false,
    limit: 10,
    order: '',
    account_manager: '',
    selectedRows: [],
    assignee_id: null,
  })
  const [vehicles, setVehicles] = useState(false)

  const { endpoint, staticData, filters, target } = props

  let url = `?target=${target}&query=${state.query}&page=${state.page}&desc=${state.desc}&limit=${state.limit}`

  let pipelines = state.filters.filter((f) => f.group_name === 'Pipeline lane')
  if (pipelines.length > 0) url = `${url}&lane_id=${pipelines[0].id}`

  const fetchVehicles = () => {
    if (endpoint) {
      api
        .get(`${endpoint}${url ? url : ''}`)
        .then((res) => {
          res.data.data = res.data.data.map((vehicle) => {
            return {
              model: vehicle.models.map((model) => model.model).join(', '),
              ...vehicle,
            }
          })
          setVehicles(res.data)
          setState((prevState) => ({
            ...prevState,
          }))
        })
        .catch((err) => {
          if (err) {
            NotificationManager.error(
              'Oops! Something went wrong. Please try again.'
            )
          }
        })
    }
  }

  /**
   * Fetch special vehicles
   */
  useEffect(() => {
    fetchVehicles()
  }, [url])

  const handleSearch = (query, filters) => {
    setState((pre) => ({ ...pre, query, filters }))
  }

  const handleTableChange = (
    type,
    { sortField, sortOrder, page, sizePerPage }
  ) => {
    setState((prevState) => ({
      ...prevState,
      page,
      desc: sortOrder === 'desc' ? true : false || false,
      limit: sizePerPage,
      order: sortField || '',
    }))
    let url = `?query=${state.query}&page=${page}&desc=${state.desc}&limit=${sizePerPage}`

    setState((pre) => ({ ...pre, url }))
  }

  const {
    isShowing: isShowingDeleteModal,
    toggle: toggleDeleteModal,
  } = useModal()

  const [row, setRow] = useState(null)

  const toggleDelete = (row) => {
    setRow(row)
    toggleDeleteModal()
    return false
  }

  const columns = [
    {
      dataField: 'make',
      text: 'Vehicle make',
      sort: true,
      sortCaret,
    },
    {
      dataField: 'model',
      text: 'Model',
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      sortCaret,
      formatter: (cell, row) => {
        let bgColor = specialVehicleTypeColor(row.type)

        return (
          <span
            key={uniqueId}
            style={{ backgroundColor: bgColor, marginRight: 2 }}
            className="badge badge-pill badge-secondary"
          >
            {row.type}
          </span>
        )
      },
    },
    {
      dataField: 'id',
      text: 'Action',
      headerStyle: {
        width: 90,
      },
      formatter: (cell, row) => {
        return (
          <div className={'d-flex align-items-center'}>
            <Link
              data-tip="Edit vehicle"
              data-place="top"
              to={`/admin/special-vehicles/${row.id}/edit`}
              size="sm"
              className="table-action-btn"
            >
              <i className="wf-icon-edit" />
            </Link>

            <Link
              data-tip="Delete vehicle"
              data-place="top"
              to={`#`}
              size="sm"
              className="table-action-btn"
              onClick={(e) => {
                e.preventDefault()
                toggleDelete(row)
              }}
            >
              <i className="wf-icon-delete" />
            </Link>
            <ReactTooltip effect="solid" />
          </div>
        )
      },
    },
  ]

  return (
    <>
      {vehicles && (
        <Table
          resource={vehicles}
          showOptions={false}
          handleTableChange={handleTableChange}
          filters={filters}
          columns={columns}
          hasTableSearch={true}
          searching={false}
          onSearch={handleSearch}
          omnibox={{
            groups: state.filterGroups,
          }}
        />
      )}

      {!vehicles && staticData && (
        <Table
          resource={{ data: staticData, total: 10, from: 1 }}
          showOptions={false}
          handleTableChange={handleTableChange}
          columns={columns}
          hasTableSearch={true}
          searching={false}
          onSearch={handleSearch}
          omnibox={{
            groups: filters,
          }}
        />
      )}
      {row && (
        <DeleteModal
          toggle={toggleDeleteModal}
          endPoint={'/admin/special-vehicles'}
          item={row.id}
          isShowing={isShowingDeleteModal}
          style={{ maxWidth: 435 }}
          onCancel={toggleDeleteModal}
          onDone={fetchVehicles}
        >
          <>
            Delete{' '}
            <strong>
              {row.make} - {row.model}
            </strong>{' '}
            from your vehicles? You can undo this later.
          </>
        </DeleteModal>
      )}
    </>
  )
}

SpecialVehiclesTable.propTypes = {
  deleteEndpoint: PropTypes.string,
  editEndpoint: PropTypes.string,
  endpoint: PropTypes.string,
  staticData: PropTypes.array,
  filters: PropTypes.array,
  isShowingDeleteModal: PropTypes.bool,
  toggleDeleteModal: PropTypes.func,
  target: PropTypes.string.isRequired,
}

SpecialVehiclesTable.defaultProps = {
  staticData: null,
}

export default SpecialVehiclesTable
