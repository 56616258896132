import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import UserEditForm from './UserEditForm'
import { isValid, change, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import {
  fetchUser,
  updateUserInfo,
  deleteUser,
  restoreSingleUser,
} from '../../../actions/users.actions'
import { resetForm } from '../../../actions/cover-levels.actions'
import api from '../../../utils/api'
import { hasPermission, isSuperAdmin, hasGroup } from '../../../utils/auth'
import { confirmAlert } from 'react-confirm-alert'

class UserEditPage extends Component {
  state = {
    groups: [],
    dealerships: [{
      id: 9999999,
      name: 'None',
      value: 'none',
    }],
    disableGroupSelection: true,
    dealerGroup: [],
  }

  async componentDidMount() {
    const cid = this.props.match.params.id
    if (!isNaN(Number(cid))) await this.props.fetchUser(cid)
    else this.props.history.push('/404')

    await this.loadGroups()
    await this.loadDealerships()
  }

  cancel = e => {
    e.preventDefault()
    this.props.resetForm('users/edit')
    this.props.history.push('/admin/users')
  }

  loadGroups = async () => {
    const response = await api.get('admin/users/groups?limit=1000')
    let groups = response.data.data.map(group => {
      return {
        color: group.color,
        value: group.id,
        name: group.name,
      }
    })

    // External Sales Managers should be able to assign users to a Dealer user group or the External Sales group
    // Account Managers (which are also part of external sales) should only should be able to assign users to a Dealer user group
    if (hasGroup('Management')) {
      this.setState({ groups: groups })
    } else if (!isSuperAdmin() && hasPermission('external_sales.dealers.view')) { // is an External Sales member of staff
      if (hasPermission('external_sales.dealers.view_all')) { // is an External Sales Manager
        this.setState({ groups: groups.filter(g => g.name.startsWith('Dealer') || g.name === 'External Sales') })
      } else { // is an Account Manager
        this.setState({ groups: groups.filter(g => g.name.startsWith('Dealer')) })
      }
    } else {
      this.setState({ groups: groups })
    }
  }

  loadDealerships = async () => {
    const response = await api.get('/misc/dealers?limit=999999&is_prospect=false')
    let dealerships = response.data.data

    this.setState(prevState => ({
      ...prevState,
      dealerships: [...prevState.dealerships, ...dealerships],
    }))
  }

  restoreUser = id => {
    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({title, onClose}) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose}/>
              <h3>{title}</h3>
              <p>
                Restore{' '}
                <strong>
                  {this.props.first_name} {this.props.last_name}
                </strong>
                ? They'll be able to login to the system again.
              </p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  this.props.restoreSingleUser(id)
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  removeDealerGroup = (yes) => {
    if (yes)
      this.setState({
        groups: this.state.groups.filter(g => g.name !== 'Dealer'),
      })
    else
      this.loadGroups()
  }

  render() {
    return (
      <Fragment>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin/users" className="open active">
              Users
            </Link>
          </li>
          <li className="breadcrumb-item">Add user</li>
        </ol>

        <div className="container-fluid animated fadeIn">
          <div className="mw-37-5rem mx-auto">
            {this.props.user && (
              <UserEditForm
                change={this.props.change}
                dealerships={this.state.dealerships}
                dealer={this.props.dealer}
                name={`${this.props.first_name} ${this.props.last_name}`}
                valid={this.props.valid}
                deleted={!!this.props.deleted_at}
                isActive={this.props.is_active || false}
                groups={this.state.groups}
                cancel={this.cancel}
                deleteUser={this.props.deleteUser}
                restoreUser={this.restoreUser}
                removeDealerGroup={this.removeDealerGroup}
                onSubmit={values => this.props.updateUserInfo(values)}
                dealerGroup={this.state.dealerGroup}
              />
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

const selector = formValueSelector('users/edit')

const mapStateToProps = state => ({
  dealer_id: selector(state, 'dealer_id'),
  dealer_name: selector(state, 'dealer_name'),
  is_active: selector(state, 'is_active'),
  deleted_at: selector(state, 'deleted_at'),
  user_id: selector(state, 'id'),
  dealer: {
    id: selector(state, 'dealer_id.id'),
    name: selector(state, 'dealer_name'),
  },
  first_name: selector(state, 'first_name'),
  last_name: selector(state, 'last_name'),
  user: state.users.singleUser,
  isPending: state.users.isPending,
  valid: isValid('users/edit')(state),
})

export default connect(
  mapStateToProps,
  {
    fetchUser,
    resetForm,
    change,
    updateUserInfo,
    deleteUser,
    restoreSingleUser,
  },
)(UserEditPage)
