import moment from 'moment'
import { uniqueId } from 'lodash'

export const filterGroups = [
  {
    name: 'Expires within',
    allowOnlyOne: true,
    filters: [
      { id: uniqueId(), name: 'Expired', value: '-1' },
      { id: uniqueId(), name: '1 day', value: moment().add(1, 'days').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '2 days', value: moment().add(2, 'days').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '3 days', value: moment().add(3, 'days').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '4 days', value: moment().add(4, 'days').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '5 days', value: moment().add(5, 'days').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '6 days', value: moment().add(6, 'days').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '1 week', value: moment().add(1, 'weeks').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '2 weeks', value: moment().add(2, 'weeks').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '3 weeks', value: moment().add(3, 'weeks').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '4 weeks', value: moment().add(4, 'weeks').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '5 weeks', value: moment().add(5, 'weeks').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '6 weeks', value: moment().add(6, 'weeks').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '7 weeks', value: moment().add(7, 'weeks').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '8 weeks', value: moment().add(8, 'weeks').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '9 weeks', value: moment().add(9, 'weeks').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '10 weeks', value: moment().add(10, 'weeks').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '11 weeks', value: moment().add(11, 'weeks').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '12 weeks', value: moment().add(12, 'weeks').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '6 months', value: moment().add(6, 'months').format('YYYY-MM-DD') },
      { id: uniqueId(), name: '1 year', value: moment().add(1, 'years').format('YYYY-MM-DD') },
    ],
  },
  {
    name: 'Deleted',
    allowOnlyOne: true,
    singleValueFilter: true,
    filters: [
      {
        id: 1,
        name: 'Deleted',
        value: true
      },
    ],
  },
]
