import React, { Component, Fragment } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import PageHeader from '../../../ui/PageHeader'
import Table from '../../../ui/Table'
import { Link } from 'react-router-dom'
import Avatar from '../../../ui/Avatar'
import { sortCaret } from '../../../../utils/misc'
import api from '../../../../utils/api'
import { NotificationManager } from 'react-notifications'
import { uniqueId } from 'lodash'
import { API_URL } from '../../../../utils/constants'

class DealersPage extends Component {
  state = {
    filterGroups: [
      {
        name: 'status',
        allowOnlyOne: true,
        filters: [
          { id: uniqueId(), name: 'Active', value: 'Active' },
          { id: uniqueId(), name: 'In review', value: 'In review' },
          { id: uniqueId(), name: 'Rejected', value: 'Rejected' }
        ]
      }
    ],
    query: '',
    page: 1,
    desc: false,
    limit: 10,
    order: '',
    account_manager: null,
    dealers: null,
    searching: true,
    status: '',
    current_account_manager: null
  }

  fetchData = (url) => {
    api
      .get(`/claims/dealers${url ? url : ''}`)
      .then((res) => {
        this.setState({ dealers: res.data, searching: false })
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing.'
          )
        }
      })
  }

  getAccountManagers = () =>
    new Promise((resolve, reject) => {
      api.get(`${API_URL}/misc/account-managers`).then((res) => {
        let ams = res.data

        ams = ams.map((am, index) => {
          am.name = (
            <div
              className="d-flex align-items-center"
              style={{ marginBottom: '-2px' }}
              key={index}
            >
              <Avatar user={am} size="xsmall"/>
              <span className="font-md ml-1" style={{ marginTop: '-2px' }}>
                {am.first_name} {am.last_name}
              </span>
            </div>
          )
          return am
        })

        this.setState((prevState) => ({
          ...prevState,
          filterGroups: [
            ...this.state.filterGroups,
            { name: 'account manager', filters: ams }
          ]
        }))
      })
    })

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    let url = `?page=${page}&desc=${
      sortOrder === 'desc' ? true : false
    }&limit=${sizePerPage}&query=${this.state.query}`
    if (sortField) url = `${url}&order=${sortField}`

    if (this.state.status) {
      url = `${url}&status=${this.state.status}`
    }

    if (this.state.current_account_manager) {
      url = `${url}&current_account_manager=${this.state.current_account_manager}`
    }

    this.fetchData(url)
  }

  handleSearch = (query, filters) => {
    let status = ''

    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]

      if (filter.group_name === 'status') status = filter.name
    }

    let ams = filters.filter((f) => f.group_name === 'account manager')
    if (ams.length === 1) {
      let am_fullname = ams.map((am) => `${am.first_name} ${am.last_name}`)[0]
      this.setState((prevState) => ({
        ...prevState,
        current_account_manager: am_fullname
      }))
    } else {
      this.setState((prevState) => ({
        ...prevState,
        current_account_manager: ''
      }))
    }

    this.setState(
      {
        query,
        status
      },
      this.buildUrl
    )
  }

  /**
   * Builds URL and to fetch garages
   */
  buildUrl = () => {
    const {
      query,
      page,
      desc,
      limit,
      status,
      current_account_manager
    } = this.state

    let url = `?query=${query}&page=${page}&desc=${desc}&limit=${limit}`

    if (current_account_manager) {
      url = `${url}&current_account_manager=${current_account_manager}`
    }

    if (status !== '') url = `${url}&status=${status}`

    this.setState({ searching: true })
    this.fetchData(url)
  }

  componentDidMount() {
    this.fetchData()

    this.getAccountManagers()
  }

  render() {
    const options = {
      showOptions: false,
      columns: [
        {
          dataField: 'id',
          text: 'Dealership ID',
          headerStyle: {
            width: '135px'
          },
          formatter: (cell, row) => row.id.toString().padStart(6, '0'),
          sort: true,
          sortCaret
        },
        {
          dataField: 'name',
          text: 'Dealership name',
          headerStyle: {
            width: '300px'
          },
          formatter: (cell, row) => (
            <Link key={row.id} to={`/claims/dealers/${row.id}/timeline`}>
              {row.name}
            </Link>
          ),
          sort: true,
          sortCaret
        },
        {
          dataField: 'postcode',
          text: 'Postcode',
          sort: true,
          sortCaret,
          headerStyle: {
            width: '135px'
          }
        },
        {
          dataField: 'territory.name',
          text: 'Territory'
        },
        {
          dataField: 'territory.account_manager',
          text: 'AM',
          headerStyle: {
            width: 70
          },
          formatter: (cell, row) => {
            return (
              row.territory &&
              row.territory.current_account_manager && (
                <div key={row.id} className="d-flex align-items-center">
                  <Avatar
                    user={{
                      first_name: row.territory.current_account_manager.split(
                        ' '
                      )[0],
                      last_name: row.territory.current_account_manager.split(
                        ' '
                      )[1]
                    }}
                  />
                  <span className="ml-2">
                    {row.territory.current_account_manager.first_name}{' '}
                    {row.territory.current_account_manager.last_name}
                  </span>
                </div>
              )
            )
          }
        },
        {
          dataField: 'status',
          text: 'Status',
          sort: true,
          sortCaret
        }
      ]
    }

    return (
      <Fragment>
        <br/>
        <div className="container-fluid animated fadeIn">
          <PageHeader
            title="Dealers"
            description="View all dealerships below."
          />
          <br/>
          {this.state.dealers && (
            <Table
              resource={this.state.dealers}
              showOptions={options.showOptions}
              omnibox={{ groups: this.state.filterGroups }}
              columns={options.columns}
              searching={this.state.searching}
              hasTableSearch={true}
              handleTableChange={this.handleTableChange}
              onSearch={this.handleSearch}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

export default DealersPage
