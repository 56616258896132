import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form, formValueSelector, reduxForm } from 'redux-form'
import renderField from '../../../../utils/renderField'
import { connect } from 'react-redux'
import { money } from '../../../../utils/misc'

const CustomisePriceForm = ({
  onSubmit,
  handleSubmit,
  payNow,
  valid,
  onCancel,
  customPrice,
  noOfPayments,
}) => {
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col">
          <Field
            type="text"
            label="Total policy price"
            component={renderField}
            name="customPrice"
            className="form-control"
            fieldIcon="wf-icon-gbp"
          />
        </div>
      </div>

      {/* if it's monthly plan */}
      {!payNow && (
        <div
          className={`d-flex align-items-center justify-content-between mb-1 ${
            payNow ? 'mt-4' : ''
          }`}
        >
          <span className="label">
            {noOfPayments} payments @ {money.format(customPrice / noOfPayments)}
          </span>
          <span className="label" />
        </div>
      )}
      <div className={`d-flex align-items-center justify-content-between`}>
        <span className="label">Net</span>
        <span className="label">{money.format(customPrice / 1.2)}</span>
      </div>

      <div className="d-flex align-items-center justify-content-between">
        <span className="label">VAT</span>
        <span className="label">{money.format((customPrice / 1.2) * 0.2)}</span>
      </div>

      <div
        className="d-flex align-items-center justify-content-between mb-4"
        style={{ marginTop: 50 }}
      >
        <span className="label fw-800" style={{ fontSize: 20 }}>
          Total
        </span>
        <span className="label fw-800" style={{ fontSize: 20 }}>
          {money.format(customPrice)}
        </span>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group mb-0">
            <button
              className="btn btn-secondary"
              type="submit"
              disabled={!valid}
            >
              Save quote
            </button>
            <button
              type="button"
              className="btn btn-outline btn-form-cancel"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Form>
  )
}

CustomisePriceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  payNow: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

const selector = formValueSelector('customisePriceForm')

export default connect((state) => ({
  customPrice: selector(state, 'customPrice'),
}))(
  reduxForm({
    form: 'customisePriceForm',
  })(CustomisePriceForm)
)
