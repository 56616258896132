import React from 'react'
import PropTypes from 'prop-types'
import { echo } from '../../../../../utils/misc'

const VehicleDetails = ({ vehicle }) => {

  return (
    <div className="row">
      <div className="col-12 pr-5">
        <div className="row">
          <div className="col">
            <h6 className="mb-4 text-uppercase">Vehicle details</h6>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-4">
            <span className="label">Vehicle reg.</span>
            <p className="font-md">{echo(vehicle.vrm)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Make</span>
            <p className="font-md">{echo(vehicle.make)}</p>
          </div>
          <div className="col-4">
            <span className="label">Model</span>
            <p className="font-md">{echo(vehicle.model)}</p>
          </div>
          <div className="col-4">
            <span className="label">Series</span>
            <p className="font-md">{echo(vehicle.series)}</p>
          </div>
        </div>

      </div>

    </div>
  )
}

VehicleDetails.propTypes = {
  vehicle: PropTypes.object.isRequired,
}

export default VehicleDetails
