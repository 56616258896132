import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../../../../utils/api'
import { useParams } from 'react-router-dom'
import { history } from '../../../../../utils/history'
import PolicyholderDetails from './PolicyholderDetails'
import PolicyDetails from './PolicyDetails'
import PolicyStatusForm from './PolicyStatusForm'
import { NotificationManager } from 'react-notifications'
import moment from "moment";
import VehicleDetails from '../../../DealerPortal/Policies/Show/VehicleDetails';
import AdminComments from './AdminComments';
import { hasPermission } from '../../../../../utils/auth';
import { connect } from 'react-redux';

const Policy = props => {
  const { loggedInPerson } = props
  const params = useParams()
  const [policy, setPolicy] = useState(null)
  const [sendingPolicyDocumentUpdateEmail, setSendingPolicyDocumentUpdateEmail] = useState(false)
  const [policyLapsedDisabled, setPolicyLapsedDisabled] = useState(false)
  const [forceRerender, setForceRerender] = useState(0)

  const fetchPolicy = () => {
    api
      .get('/direct-sales/policies/' + params.id)
      .then((res) => {
        setPolicy(res.data)
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 404) {
          history.push('/404')
        }
      })
  }

  useEffect(() => {
    fetchPolicy();
  }, [])

  const handlePolicyStatusSubmit = (values) => {
    api
      .patch('/direct-sales/policies/' + params.id + '/status',
      {
        status: values.status.value,
        sub_status: values.status.value === 'Cancelled' ? values.sub_status.value : ''
      })
      .then(async (res) => {
        if (values.status.value === 'Cancelled') {
          if (
            values.status.value !== policy.status &&
            !values.sub_status.value
          ) {
            const message = {
              message: `Policy cancelled by ${loggedInPerson.first_name} ${loggedInPerson.last_name}`,
              message_type: 'Note',
              person_id: loggedInPerson.person_id,
            };
            await createPolicyMessage(message);
          } else if (
            ['Approved', 'Cancelled'].includes(policy.status) &&
            values.sub_status.value !== policy.sub_status
          ) {
            if (values.sub_status.value === 'Customer Request') {
              await sendEmail({ type: 'policy-cancellation-customer-request' });
              const message = {
                message: `Cancelled due to customer request. Email sent on the ${moment().format('Do')} of ${moment().format('MMM')} ${moment().format('YYYY')} at ${moment().format('HH:mm')}`,
                message_type: 'Email',
                person_id: loggedInPerson.person_id,
              };
              await createPolicyholderMessage(message);
              await createPolicyMessage(message);
            }
          }
        } else if (values.status.value === 'Approved' && values.status.value !== policy.status) {
          // send new policy email by trigger
          // await sendEmail({ type: 'new-policy' });
          // const message = {
          //   message: `New policy documents were emailed on the ${moment().format('Do')} of ${moment().format('MMM')} ${moment().format('YYYY')} at ${moment().format('HH:mm')}`,
          //   message_type: 'Email',
          //   person_id: loggedInPerson.person_id,
          // };
          // await createPolicyholderMessage(message);
          // await createPolicyMessage(message);
        }

        NotificationManager.success('Woohoo! You updated the policy.')
        history.push('/direct-sales/policies')
      })
      .catch((err) => {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again after refreshing the page'
        )
      })
  }

  const renewPolicy = () => {
    history.push(`/direct-sales/get-quote?policy=${policy.id}`)
  }

  const transferPolicy = () => {
    history.push(`/direct-sales/policies/${policy.id}/transfer`)
  }

  const isPolicyActive = () => {
    return policy && (policy.status === 'Approved') &&
      moment(policy.start_date).isSameOrBefore(moment()) &&
      moment(policy.end_date).isAfter(moment());
  }

  const isPolicyApproved = () => {
    return policy && (policy.status === 'Approved')
  }
  
  const sendPolicyDocumentUpdateEmail = async () => {
    setSendingPolicyDocumentUpdateEmail(true);

    try {
      await api.post(`/direct-sales/policies/${policy.id}/email`, { type: 'policy-document-update' });
      const message = {
        message: `The updated policy document was emailed on the ${moment().format('Do')} of ${moment().format('MMM')} ${moment().format('YYYY')} at ${moment().format('HH:mm')}`,
        message_type: 'Email',
        person_id: loggedInPerson.person_id,
      };
      await createPolicyholderMessage(message);
      await createPolicyMessage(message);
      setForceRerender(forceRerender + 1);
      NotificationManager.success('Woohoo! You sent policy update document email.')
    } catch (err) {
      NotificationManager.error(
        'Oops! Something went wrong. Please try again after refreshing the page'
      )
    }

    setSendingPolicyDocumentUpdateEmail(false);
  }

  const sendEmail = async ({ type }) => {
    try {
      await api.post(`/direct-sales/policies/${policy.id}/email`, { type });
    } catch (err) {}
  }

  const createPolicyholderMessage = async (message) => {
    try {
      await api.post(`/direct-sales/policyholders/${policy.policyholder.id}/timeline/message`, message);
    } catch (err) {}
  }
  
  const createPolicyMessage = async (message) => {
    try {
      await api.post(`/direct-sales/policies/${policy.id}/timeline/message`, message);
    } catch (err) {}
  }

  return (
    policy && <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/direct-sales/policies" className="open active">
            Policies
          </Link>
        </li>
        <li className="breadcrumb-item">
          <span className="active">View policy</span>
        </li>
      </ol>

      <div className="container">
        <header className="py-4 d-flex align-items-center justify-content-between border-bottom">
          <div>
            <div className="d-flex">
              <h3 style={{ fontSize: 36 }}>
                Policy,{' '}
                <span className="font-xl mt-2- fw-400 text-gray-700">
                  {policy.ref}
                </span>
                <span className="badge badge-primary badge-pill font-sm py-2 px-3 ml-3">Public</span>
              </h3>
            </div>
          </div>

          <div className="d-flex" style={{ gap: 20 }}>
            {isPolicyApproved() && (
              <button
                className="btn btn-outline-info btn-md"
                disabled={sendingPolicyDocumentUpdateEmail}
                onClick={sendPolicyDocumentUpdateEmail}
              >
                Policy document update email
              </button>
            )}
            
            {hasPermission('direct_sales.policies.update') &&
              <div>
                <button className="btn btn-success btn-md mr-4" onClick={renewPolicy}>Renew policy</button>
                {isPolicyActive() &&
                  <button className="btn btn-outline-info btn-md" onClick={transferPolicy}>Transfer Policy</button>
                }
              </div>
            }
          </div>
        </header>

        <div className="row">
          <div className="col-8 border-right pt-4">
            <VehicleDetails
              vehicle={policy.vehicle}
              allowEdit={hasPermission('direct_sales.policies.update')}
              endpoint={`/direct-sales/policies/${policy.id}/vehicle`}
              refresh={fetchPolicy}
              coverLevel={policy.cover_level}
            />
            <PolicyholderDetails policyholder={policy.policyholder} />
            <div className="pt-1">
              <hr className="mt-4" style={{ height: '20px' }}/>
            </div>
          </div>
          <div className="col-4">
            <PolicyDetails policy={policy} refreshPolicy={fetchPolicy} />
          </div>
          <div className="col-4">
            <PolicyStatusForm
              onSubmit={handlePolicyStatusSubmit}
              initialValues={{
                status: {
                  value: policy.status,
                },
                sub_status: {
                  value: policy.sub_status,
                },
              }}
              statusText={policy.status}
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col pr-5">
            <hr />
            <AdminComments key={forceRerender} />
          </div>
        </div>

      </div>
    </div>
  )
}

Policy.propTypes = {}

export default connect(
  (state) => ({
    loggedInPerson: state.authentication.user,
  }),
  null
)(Policy);
