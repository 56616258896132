import React, { useEffect } from 'react'
import PageHeader from '../ui/PageHeader'
import { connect } from 'react-redux'
import {history} from "../../utils/history";

function Dashboard({user}) {


  useEffect(() => {
    if (!user) return;
    if (user.is_super_admin) {
      history.push('/dashboard/claims');
    }
    const permissions = user.permissions.filter((item) => {
      return item.includes('dashboard')
    })
    if (permissions.length > 0) {
      switch(permissions[0]) {
        case 'dashboards.external_sales.view':
          history.push('/dashboard/external-sales')
          break;
        case 'dashboards.direct_sales.view':
          history.push('/dashboard/direct-sales')
          break;
        case 'dashboards.claims.view':
          history.push('/dashboard/claims')
          break;
        default:
          history.push('/404');
      }
    }
  });

  return (
    <div className="container-fluid animated fadeIn">
      <br/>
      <PageHeader
        title="Dashboard"
        description={`We’re designing you an amazing Dashboard. Coming soon!`}
      />
    </div>
  )
}

export default connect((state) => ({
  user: state.authentication.user,
}))(Dashboard)
