import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

const Avatar = props => {
  const { user, size, placeForTip, tooltip, onClick, dataTip } = props

  const hasAvatar = {
    backgroundImage: `url(${user && user.avatar})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPosition: 'center',
  }

  return user ? (
    <div
      data-tip={dataTip || `${user.first_name || ''} ${user.last_name || ''}`}
      data-place={placeForTip}
      className={`user-avatar user-avatar--${size}`}
      onClick={onClick}
      style={hasAvatar}
    >
      {!user.avatar && (
        <div className="user-avatar__letters">
          {user.first_name ? user.first_name.charAt(0) : ''} {user.last_name ? user.last_name.charAt(0) : ''}
        </div>
      )}

      {tooltip && <ReactTooltip effect="solid" />}
    </div>
  ) : (
    <div className={`user-avatar user-avatar--${size}`} />
  )
}

Avatar.propTypes = {
  user: PropTypes.object,
  size: PropTypes.string,
  placeForTip: PropTypes.string, // top, bottom, left, right
  onClick: PropTypes.func,
  tooltip: PropTypes.bool,
  dataTip: PropTypes.string,
}

Avatar.defaultProps = {
  size: 'small',
  placeForTip: 'top',
  tooltip: false,
}

export default Avatar
