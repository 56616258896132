import { specialVehicleTypeColor } from './misc'

export const specialVehicleTypes = [
  {
    name: 'Type',
    allowOnlyOne: true,
    filters: [
      {
        id: 1,
        name: 'Prestige',
        value: 'Prestige',
        color: specialVehicleTypeColor('Prestige')
      },
      {
        id: 2,
        name: 'Specialist',
        value: 'Specialist',
        color: specialVehicleTypeColor('Specialist')
      },
      {
        id: 3,
        name: 'Bespoke',
        value: 'Bespoke',
        color: specialVehicleTypeColor('Bespoke')
      },
      {
        id: 4,
        name: 'Commercial',
        value: 'Commercial',
        color: specialVehicleTypeColor('Commercial')
      },
    ],
  },
]