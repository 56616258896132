import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import api from '../../../utils/api'
import _ from 'lodash'
import UserGroupForm from './UserGroupsForm'
import { NotificationManager } from 'react-notifications'
import { SubmissionError } from 'redux-form'
import { history } from '../../../utils/history'

const UserGroupsCreate = props => {
  const [allPermissions, setAllPermsissions] = React.useState([])
  const [permissions, setPermissions] = React.useState([])

  React.useEffect(() => {
    api.get('admin/users/groups/permissions').then(res => {
      setAllPermsissions(res.data)
    })
  }, [])

  /**
   * Select permission
   *
   */
  const select = permission => {
    if (_.includes(permissions, permission))
      setPermissions([...permissions.filter(p => p !== permission)])
    else setPermissions(_.union([...permissions, permission]))
  }

  const selectAll = list => {
    const perms = Object.keys(list)

    if (Array.isArray(perms)) {
      setPermissions([...perms])
    }

    return false
  }

  const deselect = perms => {
    const permsArray = Object.keys(perms)
    let copy = _.clone(permissions)

    permsArray.forEach(p => _.remove(copy, value => p === value))
    setPermissions([...copy])
  }

  const onSubmit = values => {
    api
      .post('admin/users/groups', values)
      .then(res => {
        NotificationManager.success('Woohoo! You created a user group.')
        history.push('/admin/user-groups')
      })
      .catch(err => {
        if (err && err.response && err.response.status === 422) {
          const errors = err.response.data.errors
          throw new SubmissionError({
            ...errors,
            _error: err.response.data.message,
          })
        }
      })
  }

  return (
    <Fragment>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/admin/user-groups" className="open active">
            User groups
          </Link>
        </li>
        <li className="breadcrumb-item">
          <span className="active">Add a group</span>
        </li>
      </ul>

      <div className="container-fluid animated fadeIn">
        <div className="mw-37-5rem mx-auto">
          <h2 className="mb-4">Add a group</h2>

          <UserGroupForm
            permissions={permissions}
            handleChange={select}
            allPermissions={allPermissions}
            onSubmit={onSubmit}
            selectAll={selectAll}
            deselect={deselect}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default UserGroupsCreate
