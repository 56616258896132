import React from 'react'
import moment from 'moment'
import { reset } from 'redux-form'
import { has, uniqBy, set } from 'lodash'
import { addValidator } from 'redux-form-validators'

export const lastSeen = (date) => {
  if (!moment(date).isValid()) {
    return 'Never'
  }
  let result = moment.duration(moment(date).diff()).humanize(true)
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const sortCaret = (order, column) => {
  if (!order)
    return (
      <span className="carets">
        <span className="fa fa-caret-up" />
        <span className="fa fa-caret-down" />
      </span>
    )
  else if (order === 'asc')
    return (
      <span className="carets">
        <span
          className="fa fa-caret-up"
          style={{ color: 'rgba(45,44,49,0.5)' }}
        />
        <span className="fa fa-caret-down" />
      </span>
    )
  else if (order === 'desc')
    return (
      <span className="carets">
        <span className="fa fa-caret-up" />
        <span
          className="fa fa-caret-down"
          style={{ color: 'rgba(45,44,49,0.5)' }}
        />
      </span>
    )
  return null
}

let touched, valid, validated

export const selectInputStyles = {
  input: (provided, state) => {
    return {
      ...provided,
      paddingTop: 8,
      paddingBottom: 8,
    }
  },
  control: (provided, state) => {
    if (touched) {
      if (valid)
        return {
          ...provided,
          borderColor: '#70B725',
        }
      if (validated) {
        return {
          ...provided,
          borderColor: '#70B725',
        }
      } else {
        return {
          ...provided,
          borderColor: '#e10808',
        }
      }
    }
    return {
      ...provided,
    }
  },
}

// Checks the color passed through and returns true if the color is light
export const isLight = (color) => {
  if (color === null || typeof color === 'undefined') return true
  var r, g, b, hsp

  if (color.match(/^rgb/)) {
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

    r = color >> 16 // eslint-disable-next-line
    g = (color >> 8) & 255
    b = color & 255
  }

  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  if (hsp > 127.5) {
    return true
  } else {
    return false
  }
}

// Capitalises the first letter of a string
export const ucFirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const hasLowerCase = (str) => {
  return /[a-z]/.test(str)
}

export const hasUpperCase = (str) => {
  return /[A-Z]/.test(str)
}

export const hasNumber = (str) => {
  return /[0-9]/.test(str)
}

export const isPositiveFloat = (str) => {
  return /^[0-9]{1,12}([.,][0-9]{1,12})?$/.test(str)
}

export const hasSpecialCharacters = (str) => {
  return /\W|_/g.test(str)
}

export const isNumber = (str) => {
  return /^[0-9]*$/.test(str)
}

export const isPhoneNumber = (str) => {
  // eslint-disable-next-line no-useless-escape
  let pattern = new RegExp(
    /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|#)\d{3,4})?$/
  )

  return !pattern.test(str) ? 'Please enter a valid UK phone number' : undefined
}

export const resetForm = (formName) => (dispatch) => dispatch(reset(formName))

export const colorPickerSwatches = [
  {
    value: '#111E60',
  },
  {
    value: '#25399D',
  },
  {
    value: '#005BDF',
  },
  {
    value: '#1573FF',
  },
  {
    value: '#5E0878',
  },
  {
    value: '#752CA5',
  },

  {
    value: '#6540B8',
  },
  {
    value: '#64003E',
  },
  {
    value: '#871B5F',
  },
  {
    value: '#C31F84',
  },
  {
    value: '#C4000E',
  },
  {
    value: '#E72231',
  },

  {
    value: '#E82368',
  },
  {
    value: '#F7377A',
  },
  {
    value: '#FB518D',
  },
  {
    value: '#FB5606',
  },
  {
    value: '#FB9308',
  },
  {
    value: '#F7C401',
  },

  {
    value: '#0B5C6F',
  },
  {
    value: '#178794',
  },
  {
    value: '#1FA7B1',
  },
  {
    value: '#28C6CC',
  },
  {
    value: '#3E2222',
  },
  {
    value: '#724242',
  },
]

export const echo = (field, placeholder = '-') => {
  if (!field || field === 'undefined') {
    return placeholder
  }
  return field
}

export const echoMoney = (field, placeholder = '-') => {
  if (!field || field === 'undefined') {
    return placeholder
  }
  return money.format(field)
}

/**
 * Checks if value is a valid vehicle registration number (UK number)
 * @param value
 * @returns {boolean}
 */
export const isVrm = (value) =>
  /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/.test(
    value
  )

/**
 * Transform object into a field=value&field_2=value_2
 * @param params
 * @returns {string}
 */
export const queryString = (params) =>
  Object.keys(params)
    .map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    })
    .join('&')

/*
 * Formats money like £2,135.99 when called like -> money.format(amount)
 * @type {Intl.NumberFormat}
 */
export const money = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
})

/**
 * Format integers into pretty numbers.
 *
 * @param {Number} uglyNumber
 */
export const prettyNumber = (uglyNumber) =>
  new Intl.NumberFormat().format(uglyNumber)

export const specialVehicleTypeColor = (type) => {
  let table = {
    Prestige: '#111E60',
    Specialist: '#C6308C',
    Bespoke: '#FB9407',
    Commercial: '#7230C6',
  }

  return has(table, type) ? table[type] : ''
}

export const formatPostcode = (postcode) => {
  if (postcode.length > 4) {
    let parts = postcode
      .toUpperCase()
      .match(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/)
    if (parts && parts.length > 2) {
      return parts[1] + ' ' + parts[2]
    }
  }
  return postcode
}

export const validPostcode = (postcode) => {
  postcode = postcode.replace(/\s/g, '')
  let regex = /^(GIR ?0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/i

  return regex.test(postcode)
}

/**
 * Formats accepted
 1) "0116 2746 190"
 2) "0116 2746190"
 3) "079155 24272"
 4) "+44 78904 12345"
 5) "1234567890"
 6) "(0800) 123 456"
 7) "0116 2746190"
 *
 * @param {string} value
 */
export const validPhoneNumber = (value) => {
  let regex = /^\s*((?:[+](?:\s?\d)(?:[-\s]?\d)|0)?(?:\s?\d)(?:[-\s]?\d){9}|[(](?:\s?\d)(?:[-\s]?\d)+\s*[)](?:[-\s]?\d)+)\s*$/
  return regex.test(value)
}

export const validInteger = (value) => {
  let regex = /^[0-9]\d*$/
  return regex.test(value)
}

export const validPrice = (value) => {
  let regex = /^[1-9]\d{0,7}(?:\.\d{1,4})?$/
  return regex.test(value)
}

export const formatDate = (date) => date ? moment(date).format('DD/MM/YYYY') : '-'

export const formatDateYMD = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const getAge = (date) => {
  const today = new Date()
  let years = today.getFullYear() - date.getFullYear();
  if (today.getMonth() > date.getMonth() || (today.getMonth() <= date.getMonth() && today.getDate() >= date.getDate())) {
    years++;
  }
  return years;
}

/**
 * Helper that displays icon tick based on given boolean
 * @param field
 * @returns {*}
 */
export const checked = (field) =>
  field ? (
    <span className="wf-icon-circle-tick d-inline-block mr-2 mb-1" />
  ) : (
    <span className="wf-icon-red-cross d-inline-block mr-2 mb-1" />
  )

export const postcodeValidator = addValidator({
  defaultMessage: "That postcode doesn't look quite right.",
  validator: function (options, value, allValues) {
    return validPostcode(value)
  },
})

export const formatExcess = (type, excess) => {
  if (type === 'Absolute')
    return <div className="font-md">{excess != null ? `£${excess}` : '-'}</div>
  if (type === 'Percentage')
    return <div className="font-md">{excess != null ? `${excess}%` : '-'}</div>
}

/**
 * Formats prices to match api requirements for price band store
 * @param values
 * @returns {[]}
 */
export const formatPrices = (values) => {

  let returnPrices = [];

  var special, price;

  // "prices": {
  //   "_4": - Cover Level ID
  //   {
  //     "_250": { - Claim Limit
  //       "_3": { - Duration (months)
  //         "price": "333" - Price if non special
  //       },
  //       "_4": {
  //         "_4 months (drive 8)": "444" - Price if special
  //       }
  //     }
  //   }
  // }

  for(let [coverLevelId, claimLimits] of Object.entries(values)) {
    for(let [claimLimit, durations] of Object.entries(claimLimits)) {
      for(let [duration, prices] of Object.entries(durations)) {
        if(prices.hasOwnProperty('price')) {
          special = null
          price = prices.price
        } else {
          special = Object.getOwnPropertyNames(prices)[0];
          price = prices[special]
        }
        returnPrices.push({
          cover_level_id: coverLevelId.replace('_', ''),
          claim_limit: claimLimit.replace('_', ''),
          duration: (duration.replace('_', '') === '1')
            ? duration.replace('_', '') + ' month'
            : duration.replace('_', '') + ' months',
          price: price,
          special_duration: special === null ? null : special.replace('_', '')
        })
      }
    }
  }
  return returnPrices
}

export const getColumn = (tableHeadings, target, monthValue) => {
  const th = tableHeadings.filter(th => th.name === target)

  if (th.length === 1) {
    return th[0].columns.filter(c => c.value === parseInt(monthValue))[0]
  }

  return null
}

// returns an array of cover levels based on what cover_level_ids where present in the `prices` array
// it also adds the claim_limits for each cover level
export const formatCoverLevels = (prices, coverLevelsList) =>
  uniqBy(prices, 'cover_level.id').map((item) => {
    return {
      ...item.cover_level,
      claim_limits: coverLevelsList.filter(
        (c) => c.id === item.cover_level.id
      )[0].claim_limits,
    }
  })

export const formatFields = (prices) => {
  // "prices": {
  //   "_4": - Cover Level ID
  //   {
  //     "_250": { - Claim Limit
  //       "_3": { - Duration (months)
  //         "price": "333" - Price if non special
  //       },
  //       "_4": {
  //         "_4 months (drive 8)": "444" - Price if special
  //       }
  //     }
  //   }
  // }
  let formattedFields = {}
  for (let price of prices) {
    price.duration = price.duration.replace(' months', '')
    price.duration = price.duration.replace(' month', '')

    if (price.special_duration) {
      set(formattedFields, `_${price.cover_level.id}._${price.claim_limit}._${price.duration}._${price.special_duration}`, price.price)
    } else {
      set(formattedFields, `_${price.cover_level.id}._${price.claim_limit}._${price.duration}.price`, price.price)
    }
  }
  return formattedFields
}

export const formatTableHeadings = (prices, defaultHeadings, coverLevelId = 0) => {
  let formattedHeadings = [...defaultHeadings]
  for (let price of prices) {
    if (coverLevelId === 0 || price.cover_level.id === coverLevelId) {
      if (price.special_duration) {
        formattedHeadings.push({
          title: price.special_duration,
          value: price.duration,
          hiddenFor: [],
          special: price.special_duration,
          cover_level: price.cover_level
        })
      } else {
        formattedHeadings.push({
          title: price.duration === '1' ? `${price.duration} month` : `${price.duration} months`,
          value: price.duration,
          hiddenFor: [],
          special: false,
          cover_level: price.cover_level
        })
      }
    }
  }

  formattedHeadings = uniqBy(formattedHeadings, function(heading) {
    return heading.title
  })

  return formattedHeadings.sort((a, b) => {
    if (parseInt(a.value) < parseInt(b.value)) return -1;
    if (parseInt(a.value) > parseInt(b.value)) return 1;
    return 0;
  })

  // {
  //   title: '18 months',
  //     value: 18,
  //   hiddenFor: [],
  //   special: false,
  //   cover_level: null,
  // },

}

export const jsDateToIso = (date) => {
  return moment(date).format('YYYY-MM-DD');
}

export const vehicleTypes = [
  'Car',
  'Bike',
  'Van',
  'Motorhome',
  'Other',
]
