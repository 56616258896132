import React from 'react';
import { Field, isValid } from 'redux-form';
import renderField from '../../../../../../utils/renderField';
import { history } from '../../../../../../utils/history';
import SlideToggle from '../../../../../ui/SlideToggle';
import { numericality, required } from 'redux-form-validators';

export function SpecialistVehicle(vehicle_type, props) {
  return (
    <SlideToggle isVisible={vehicle_type === 'Specialist'}>
      <div className="row">
        <div className="col-md-3 my-5">
          <Field
            label="Increase all prices by"
            type="text"
            component={renderField}
            name={`increase_prices_by`}
            append={{
              direction: 'right',
              text: '%',
            }}
            validate={[
              required({
                message: 'You must enter the price increase.'
              }),
              numericality({
                allowBlank: false,
                '>=': 0
              }),
            ]}
          />
        </div>
      </div>

      <div style={{ marginBottom: '4rem' }}>
        <button
          type="submit"
          name="submit"
          className={`btn btn-secondary`}
          disabled={!isValid}
        >
          Save
        </button>
        <button
          type="submit"
          className="btn btn-transparent text-secondary"
          onClick={() => history.push(props.onCancelRedirectTo)}
        >
          Cancel
        </button>
      </div>
    </SlideToggle>
  );
}
