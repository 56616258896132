import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  SET_TOKEN_CLAIMS,
  LOGOUT,
  ACTIVATE_2FA,
  INACTIVATE_2FA,
  SET_2FA_ERROR,
} from '../constants'
import { history } from '../utils/history'
import { accessTokenClaims, hasPermission } from '../utils/auth'
import api from '../utils/api'
import { SubmissionError } from 'redux-form';
import { NotificationManager } from 'react-notifications'

/**
 * Authentication is pending
 */
export const authPendingAction = () => ({
  type: LOGIN_REQUEST,
  isPending: true,
})

/**
 * Login failed action creator
 *
 * @param {message} message
 */
const authFailed = data => ({
  type: LOGIN_FAILURE,
  payload: data,
})

export const authSuccess = data => ({
  type: LOGIN_SUCCESS,
  payload: data,
})


export const activate2FA = data => ({
  type: ACTIVATE_2FA,
})

const inactive2FA = data => ({
  type: INACTIVATE_2FA,
})

export const set2FAError = errors => ({
  type: SET_2FA_ERROR,
  payload: {
    errors
  }
})

/**
 * Login action
 *
 * @param values
 */
export const 

login = values => dispatch => {
  dispatch(authPendingAction())

  return api
    .post(`/auth/login`, { ...values })
    .then(res => {
      localStorage.setItem('wf-token', JSON.stringify(res.data.access_token))
      
      let claims = accessTokenClaims(res.data.access_token)
      const { is_two_factor_email_active: needTwoFactorAuth } = claims
      
      // store two factor authentication state
      localStorage.setItem('need-2FA', needTwoFactorAuth)

      // direct the user to their homepage (which depends on what permissions they have)
      const from = history.location.state ? history.location.state.from : ''
      
      if (needTwoFactorAuth) {
        dispatch(activate2FA())
        history.push('/auth/two-factor')
      } else if (from) {
        history.push(from)
      } else if (hasPermission('dealer_portal.dashboard')) {
        history.push('/dealer-portal/dashboard')
      } else if (hasPermission('dealer_portal.*')) {
        history.push('/dealer-portal/policies')
      } else if (hasPermission('policyholder_portal.*')) {
        history.push('/policyholder-portal/policies')
      } else if (hasPermission('dashboards.claims.view')) {
        history.push('/dashboard/claims')
      } else if (hasPermission('dashboards.direct_sales.view')) {
        history.push('/dashboard/direct-sales')
      } else if (hasPermission('dashboards.external_sales.view')) {
        history.push('/dashboard/external-sales')
      } else if (hasPermission('direct_sales.policies.*')) {
        history.push('/direct-sales/policies')
      } else if (hasPermission('external_sales.policies.*')) {
        history.push('/external-sales/policies')
      } else { // default homepage
        history.push('/dashboard')
      }

      return dispatch(authSuccess({ ...res.data, user: { ...claims } }))
    })
    .catch(err => {
      const errors = err.response.data && err.response.data.errors
      dispatch(authFailed({ ...err.response }))
      throw new SubmissionError({
        ...errors,
        _error: err.response.data,
      })
    })
}

/**
 * Store all the claims from JWT
 * @param {data} data
 */
export const setTokenClaims = data => dispatch =>
  dispatch({
    type: SET_TOKEN_CLAIMS,
    payload: data,
  })

/**
 * Logout user & delete token
 */
export const logout = () => dispatch => {
  const from = window.location.pathname
  dispatch({
    type: LOGOUT,
  })
  // if the user is a policyholder we want to return them to the Policyholder Login page#
  // instead of the Dealer Login page
  const loginURL = hasPermission('policyholder_portal.*') ? '/policyholder-portal' : '/'
  localStorage.removeItem('wf-token')
  history.push(loginURL, { from })
}

export const forgotPassword = email => dispatch => {
  return api
    .post(`/auth/forgot`, email)
    .then(res => {
      // console.log('res', res.data)
    })
    .catch(err => {
      // console.log('err response', err.response)
    })
}

export const sendTwoFactorCode = () => dispatch => {

  return api
    .get(`/auth/two-factor`)
    .then(res => {
      NotificationManager.success('New code has been sent to your email address.')
    })
    .catch(err => {      
      NotificationManager.success('Failed to send code to your email address. Please try again.')
    })
}

export const verifyTwoFactorCode = code => dispatch => {
  return api
    .post(`/auth/two-factor`, { code })
    .then(res => {

      // if 2FA success, inactivate 'need-2FA' flag
      localStorage.setItem('need-2FA', false)
      dispatch(inactive2FA())
    
      const from = history.location.state ? history.location.state.from : ''
      // direct the user to their homepage (which depends on what permissions they have)
      if (from) {
        history.push(from)
      } else if (hasPermission('dealer_portal.dashboard')) {
        history.push('/dealer-portal/dashboard')
      } else if (hasPermission('dealer_portal.*')) {
        history.push('/dealer-portal/policies')
      } else if (hasPermission('policyholder_portal.*')) {
        history.push('/policyholder-portal/policies')
      } else if (hasPermission('dashboards.claims.view')) {
        history.push('/dashboard/claims')
      } else if (hasPermission('dashboards.direct_sales.view')) {
        history.push('/dashboard/direct-sales')
      } else if (hasPermission('dashboards.external_sales.view')) {
        history.push('/dashboard/external-sales')
      } else if (hasPermission('direct_sales.policies.*')) {
        history.push('/direct-sales/policies')
      } else if (hasPermission('external_sales.policies.*')) {
        history.push('/external-sales/policies')
      } else { // default homepage
        history.push('/dashboard')
      }

    })
    .catch(err => {
      dispatch(set2FAError( 'The code is incorrect or has expired. Please try again.'))
      // throw new SubmissionError({
      //   ...errors,
      //   _error: err.response.data,
      // })
    })
}