import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../../../../utils/api'
import { useParams } from 'react-router-dom'
import { history } from '../../../../../utils/history'
import PolicyholderDetails from './PolicyholderDetails'
import VehicleDetails from './VehicleDetails'
import PolicyDetails from './PolicyDetails'
import { confirmAlert } from 'react-confirm-alert'
import { NotificationManager } from 'react-notifications'
import { hasPermission } from '../../../../../utils/auth';

const Policy = (props) => {
  const params = useParams()
  const [policy, setPolicy] = useState(null)

  const fetchPolicy = () => {
    api
      .get('/external-sales/policies/' + params.id)
      .then((res) => {
        setPolicy(res.data)
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 404) {
          history.push('/404')
        }
      })
  }

  useEffect(() => {
    fetchPolicy();
  }, [])

  const onSendForApproval = () => {
    confirmAlert({
      title: '🧐 Are you sure?',
      message: `The policy will be sent for approval. This action cannot be undone.`,
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>{message}</p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  sendPolicyForApproval()
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const sendPolicyForApproval = () => {
    api
      .patch('/external-sales/policies/' + params.id, {
        dealer_id: policy.dealer.id,
        cover_level_id: policy.cover_level.id,
        duration: policy.duration,
        claim_limit: policy.claim_limit,
        labour_rate: policy.labour_rate,
        start_date: policy.start_date,
        excess: policy.excess,
        excess_type: policy.excess_type,
        net: policy.net,
        vat: policy.vat,

        ...policy.vehicle,
        ...policy.policyholder,

        action: 'send_for_approval',
      })
      .then((res) => {
        NotificationManager.success('Woohoo! You sent a policy for approval.')
        fetchPolicy()
      })
      .catch((err) => {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again after refreshing the page'
        )
      })
  }

  return (
    policy && (
      <div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dealer-portal/policies" className="open active">
              Policies
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">View policy</span>
          </li>
        </ol>

        <div className="container">
          <header className="py-4 d-flex align-items-center justify-content-between border-bottom">
            <div>
              <div className="d-flex">
                <h3 style={{ fontSize: 36 }}>
                  Policy,{' '}
                  <span className="font-xl mt-2- fw-400 text-gray-700">
                    {policy.ref}
                  </span>
                {policy.status === 'Rejected' &&
                  <span className="badge badge-blacklisted badge-pill font-sm py-2 px-3 ml-3">
                    {policy.status}
                  </span>
                }
                {policy.status !== 'Rejected' &&
                  <span className="badge badge-primary badge-pill font-sm py-2 px-3 ml-3">
                    {policy.status}
                  </span>
                }
                </h3>
              </div>
            </div>
            <div>
              {policy.status === 'Draft' &&
                <button
                  className="btn btn-blue btn-md"
                  style={{ marginRight: 20 }}
                  onClick={onSendForApproval}
                >
                  Send for approval
                </button>
              }
            </div>
          </header>

          <div className="row">
            <div className="col-8 border-right mt-3 mb-5">
              <VehicleDetails
                vehicle={policy.vehicle}
                allowEdit={hasPermission('dealer_portal.policies') && policy.status === 'Draft'}
                endpoint={`/external-sales/policies/${policy.id}/vehicle`}
                refresh={fetchPolicy}
                coverLevel={policy.cover_level}
              />
              <PolicyholderDetails
                policyholder={policy.policyholder}
                allowEdit={hasPermission('dealer_portal.policies') && policy.status === 'Draft'}
              />
            </div>
            <div className="col-4">
              <PolicyDetails policy={policy} refreshPolicy={fetchPolicy} allowEdit={hasPermission('dealer_portal.policies')} />
            </div>
          </div>
        </div>
      </div>
    )
  )
}

Policy.propTypes = {}

export default Policy
