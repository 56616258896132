import React from 'react'
import {connect} from "react-redux"
import {reduxForm, Form, Field} from "redux-form"
import {usePostcodeLookupWithRedux} from "../../../hooks/usePostcodeLookupWithRedux";
import CustomSelect from "../../ui/CustomSelect";
import renderField from "../../../utils/renderField";
import validator from "validator";


const TransferPolicyForm = ({handleSubmit, invalid}) => {
  const { markup } = usePostcodeLookupWithRedux('transfer-policy')
  return <>
    <Form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <h6 className="mt-4 mb-4 text-uppercase">New Policyholder details</h6>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              label="Company name (optional)"
              component={renderField}
              name={`company_name`}
              id={'company_name'}
              type="text"
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Field
              label="Title"
              component={CustomSelect}
              getOptionValue={option => option.value}
              getOptionLabel={option => option.name}
              name={`title`}
              id={'title'}
              options={[
                {
                  name : 'Mr',
                  value: 1,
                },
                {
                  name : 'Mrs',
                  value: 2,
                },
                {
                  name : 'Miss',
                  value: 3,
                },
                {
                  name : 'Ms',
                  value: 4,
                },
                {
                  name : 'Doctor',
                  value: 5,
                },
                {
                  name : 'Reverend',
                  value: 5,
                },
              ]}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              label="First name"
              component={renderField}
              name={`first_name`}
              id={'firstName'}
              type="text"
              className="form-control"
            />
          </div>
          <div className="col">
            <Field
              label="Last name"
              component={renderField}
              name={`last_name`}
              id={'lastName'}
              type="text"
              className="form-control"
            />
          </div>
        </div>

        {markup}

        <div className="row">
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Telephone (mobile)"
              component={renderField}
              name={`tel_mobile`}
              className="form-control"
            />
          </div>
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Telephone (home)"
              component={renderField}
              name={`tel_home`}
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              type="email"
              label="Email"
              component={renderField}
              name={`email`}
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={invalid}
              >
                Transfer
              </button>
            </div>
          </div>
        </div>
    </Form>
  </>
}

const validate = values => {
  let errors = {};
  if (!values.tel_mobile && !values.tel_home) {
    errors.tel_mobile =
      'Please provide either a mobile or home telephone number.'
  }
  if (!values.email) errors.email = 'Please enter an email address'
  if (values.email && !validator.isEmail(values.email))
    errors.email = `That email address doesn't look quite right.`
  if (!values.first_name)
    errors.first_name = "Please enter the first name"
  if (!values.last_name) errors.last_name = "Please enter the last name"
  if (!values.title) errors.title = 'Please select title'
  if (!values.address) {
    if (!values.address_1) errors.address_1 = 'Please enter an address'
    if (!values.city) errors.city = 'Please enter a city'
    if (!values.county) errors.county = 'Please enter a county'
  }
  return errors;
}

export default connect()(
  reduxForm({
    form            : 'transfer-policy',
    destroyOnUnmount: true,
    validate,
  })(TransferPolicyForm),
)
