import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import {Link} from "react-router-dom";
import {prettyNumber} from "../../../../utils/misc";
import { Field, formValueSelector, change, untouch} from "redux-form";
import renderField from "../../../../utils/renderField";
import Modal from "../../../modals/Modal";
import {numericality} from "redux-form-validators";
import useModal from "../../../../hooks/useModal";
import {NotificationManager} from "react-notifications";

const CoverLevelTable = ({coverLevel, cols, specialDuration, clearSpecialDuration, dispatch, reduxForm, ...props}) => {

  const {isShowing: durationModal, toggle: toggleDurationModal} = useModal()

  const [durations, setDurations] = useState([]);

  useEffect(() => {
    setDurations([...cols]) //Copy in the cols so its immutable
  }, [])

  useEffect(() => {
    if (!specialDuration) return
    if (typeof specialDuration[coverLevel.name] == 'undefined') return
    if (!specialDuration[coverLevel.name]) return
    for(let i = 0; i < durations.length; i++) {
      if (specialDuration[coverLevel.name].value === durations[i].value) {
        NotificationManager.error('This duration already exists on ' + coverLevel.name)
        return
      }
    }
    addDuration(specialDuration[coverLevel.name])
    clearSpecialDuration(coverLevel.name)
  }, [specialDuration])

  const addDuration = (durationObj) => {
    let tempDurations = durations
    tempDurations.push(durationObj)
    tempDurations.sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    })
    setDurations([...tempDurations])
  }

  const removeDuration = (months) => {
    let tempDurations = durations
    tempDurations = [...tempDurations].filter(duration => {
      return duration.value !== months;
    })
    setDurations(tempDurations)
  }

  const handleDurationModalSubmit = () => {
    let tempDurations = durations
    for(let i = 0; i < tempDurations.length; i++) {
      if (parseInt(props.newDuration) === tempDurations[i].value) {
        NotificationManager.error('This duration already exists on ' + coverLevel.name)
        return;
      }
    }
    addDuration({
      'title': props.newDuration + ' months',
      'value': parseInt(props.newDuration),
      'special': false,
      hiddenFor: [],
    });
    dispatch(change(reduxForm, 'pb_duration', ''))
    dispatch(untouch(reduxForm, 'pb_duration'))
    toggleDurationModal()
  }

  const handleRemoveColumn = (col, idx) => {
    removeDuration(col.value)
  }

return (
  <>
    <Modal
      hide={toggleDurationModal}
      isShowing={durationModal}
      title="Add duration"
      subtitle="e.g. 48 months"
      onCancel={toggleDurationModal}
      onSubmit={() => {handleDurationModalSubmit()}}
      textAction="Add duration"
      textCancel="Cancel"
    >
      <div className="row">
        <div className="mt-2 col">
          <Field
            name="pb_duration"
            type="text"
            label="Duration"
            className="w-50 form-control"
            component={renderField}
            validate={[
              numericality({
                msg: 'You must enter a number.',
              }),
            ]}
            append={{
              direction: 'right',
              text: 'months',
            }}
          />
        </div>
      </div>
    </Modal>


    <div
      className="px-4 mt-5"
      style={{ overflow: 'scroll' }}
    >
      <div className="col">
        <div className="label mb-2">
          {coverLevel.name.toUpperCase()} PRICES
        </div>
        <table
          className="table table-striped table-bordered price-band-table"
          style={{
            marginBottom: '56px',
            marginRight: '30px',
          }}
        >
          <thead>
          <tr>
            <th>Claim limit</th>
            {durations.map((col, idx) => (
              <th key={idx} className={`${
                col.special
                  ? 'price-band-table__th-special'
                  : ''
              }`}>
                <div className={'d-flex justify-content-between'}>
                  <span>{col.title}</span>
                  {col.special && (
                    <span>
                      <i className={'fa fa-close text-black-50'} style={{cursor: 'pointer'}} onClick={() => {handleRemoveColumn(col, idx)}} />
                    </span>
                  )}
                </div>
              </th>
            ))}
            <th>
              <Link
                to={'#'}
                onClick={() => {
                  toggleDurationModal()
                }}>
                Add duration
              </Link>
            </th>
          </tr>
          </thead>
          <tbody>
          {coverLevel.claim_limits.map((claim_limit, idx) => (
            <tr key={idx}>
              <td>£{prettyNumber(claim_limit)}</td>
              {durations.map((col, idx) => (
                <td key={idx}>
                  <Field
                    component={renderField}
                    name={col.special ?
                      `prices._${coverLevel.id}._${claim_limit}._${col.value}._${col.title}`
                      :
                      `prices._${coverLevel.id}._${claim_limit}._${col.value}.price`
                    }
                    showSuccessValidation={false}
                    className="form-control input-group-price"
                    type="text"
                    fieldIcon="wf-icon-gbp"
                  />
                </td>
              ))}
              <td />
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  </>
)
}


export default connect((state, ownProps) => {
  const selector = formValueSelector(ownProps.reduxForm)
  return {
    newDuration: selector(state, 'pb_duration')
  }
})(CoverLevelTable)