import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import api from '../../../../utils/api';
import { echo, formatDate } from '../../../../utils/misc';
import SystemUsers from './SystemUsers';

const AccountDetails = () => {

  const [dealer, setDealer] = useState(null)

  const fetchDealer = () => {
    api
      .get(`/dealer-portal/account-details`)
      .then((res) => {
        setDealer(res.data)
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            'Oops! Something went wrong. Please try again after refreshing'
          )
        }
      })
  }

  useEffect(() => {
    fetchDealer()
  }, [])

  const handleSuspendUser = (id) => {
    api
    .patch(`/dealer-portal/suspend-user/${id}`)
    .then((res) => {
      fetchDealer()
      NotificationManager.success('Woohoo! You suspended a user.');
    })
    .catch((err) => {
      console.error(err);
      NotificationManager.error('Oops! Something went wrong. Please try again after refreshing.');
    })
  }

  return (

    <div className="container" style={{ maxWidth: '600px' }}>

      <header className="py-4 d-flex align-items-center justify-content-between">
        <div>
          <div className="d-flex">
            <h3 style={{ fontSize: 36 }}>
              Account details
            </h3>
          </div>
        </div>
      </header>

      {dealer && <div className="row">
        <div className="col-12">

          <div className="row d-flex flex-start">
          <div className="col-12">
              <span className="label">Dealership ID</span>
              <p className="font-md">{echo(dealer.id.toString().padStart(6, '0'))}</p>
            </div>
            <div className="col-12">
              <span className="label">Dealership name</span>
              <p className="font-md">{echo(dealer.name)}</p>
            </div>
            <div className="col-12">
              <span className="label">Address</span>
              <p className="font-md">{[
                dealer.address_1,
                dealer.address_2,
                dealer.address_3,
                dealer.city,
                dealer.country,
                dealer.postcode,
              ].filter(Boolean).join(', ')}
              </p>
            </div>
            <div className="col-12">
              <span className="label">Sign-up date</span>
              <p className="font-md">{dealer.active_start_date ? formatDate(dealer.active_start_date) : '-'}</p>
            </div>
            <div className="col-12">
              <span className="label">{dealer.allow_staff_view_pricing ? 'Pricing visible to all users' : 'Pricing visible to management only' }</span>
            </div>
          </div>

          <div className="pt-1">
            <hr className="mt-4" style={{ height: 20 }} />
          </div>

          <h6 className="mt-4 mb-4 text-uppercase">Contact details</h6>

          <div className="row d-flex flex-start">
            <div className="col-6">
              <span className="label">Telephone (office)</span>
              <p className="font-md">{echo(dealer.tel_office)}</p>
            </div>
            <div className="col-6">
              <span className="label">Telephone (mobile)</span>
              <p className="font-md">{echo(dealer.tel_mobile)}</p>
            </div>
            <div className="col-12">
              <span className="label">Email address</span>
              <p className="font-md">{echo(dealer.email)}</p>
            </div>
            <div className="col-12">
              <span className="label">Website</span>
              <p className="font-md">{echo(dealer.website)}</p>
            </div>
          </div>

          <div className="pt-1">
            <hr className="mt-4" style={{ height: 20 }} />
          </div>

          <h6 className="mt-4 mb-4 text-uppercase">System users</h6>

          <SystemUsers
            dealer={dealer}
            onSuspendUser={handleSuspendUser}
          />

          <div className="pt-1">
            <hr className="mt-4" style={{ height: 20 }} />
          </div>

          <h6 className="mt-4 mb-4 text-uppercase">Your Warranty First Account Manager</h6>

          <div className="row d-flex flex-start mb-5">
            <div className="col-12">
              <span className="label">{echo(dealer.territory && dealer.territory.current_account_manager ? dealer.territory.current_account_manager : null)}</span>
              <p className="font-md">{echo(dealer.territory && dealer.territory.current_account_manager  ? dealer.territory.current_account_manager_profile.email : null)}</p>
            </div>
          </div>

        </div>
      </div>}
    </div>
  )

}

export default AccountDetails
