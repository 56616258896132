import React from 'react'
import PropTypes from 'prop-types'
import {Field, reduxForm, change, untouch, formValueSelector} from 'redux-form'
import { connect, useDispatch } from 'react-redux'
import renderField from '../../../../../utils/renderField'
import { numericality, required } from 'redux-form-validators'
import {isVrm} from "../../../../../utils/misc";
import {Link} from "react-router-dom";
import renderDatePicker from "../../../../ui/renderDatePicker";
import CustomSelect from '../../../../ui/CustomSelect';

const StepVehicle = ({
  handleLookup,
  handleSubmit,
  lookupData,
  setLookupData,
  onSubmit,
  valid,
  vrm,
  manualInfo,
  setManualInfo,
  vehicleInfo
}) => {
  const dispatch = useDispatch()

  const handleResetForm = () => {
    let fields = {
      vrm: '',
      current_mileage: '',
      vehicle_sale_price: '',
    }
    Object.keys(fields).forEach(key => {
      dispatch(change('create-policy-vehicle', key, fields[key]))
      dispatch(untouch('create-policy-vehicle', key))
    })
    setLookupData(null)
    setManualInfo(false)
  }

  const vehicleLookupAlert = () => {
    return (vrm && isVrm(vrm)) && !lookupData
  }

  return (
    <div className="mw-37-5rem mx-auto">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <h6 className="mt-4 mb-4 text-uppercase">Vehicle Details</h6>
          </div>
        </div>

        {!manualInfo && <>
          <div className="row">
            <div className="col">
              <Field
                type="text"
                label="Vehicle reg. *"
                component={renderField}
                name={`vrm`}
                className={`form-control ${vehicleLookupAlert() ? 'is-invalid' : ''}`}
                button="Lookup"
                handleSubmit={handleLookup}
                normalize={value => value && value.toLocaleUpperCase()}
              />
            </div>
            {vehicleLookupAlert() &&
            <div className="invalid-feedback" style={{display: "block", marginBottom: '1.3rem'}}>Please lookup information for {vrm}</div>
            }
          </div>
          <div className="row mb-4">
            <div className="col">
              <Link
                to="#"
                className="text-blue"
                onClick={() => {
                  setManualInfo(true)
                }}
              >
                Enter Details Manually
              </Link>
            </div>
          </div>
        </>}

        {!manualInfo && lookupData && (
          <div className="row">
            <div className="col">
              <div className={`${lookupData ? 'd-block' : 'd-none'}`}>
                <div className="mt-1 vehicle-details-lookup">
                  <table className="table table-striped table-bordered">
                    <tbody className="smaller">
                      <tr>
                        <td className="fw-600">VIN</td>
                        <td>{lookupData.VIN}</td>
                      </tr>
                      <tr>
                        <td className="fw-600">Type</td>
                        <td>{lookupData.Type}</td>
                      </tr>
                      <tr>
                        <td className="fw-600">Make</td>
                        <td>{lookupData.Make}</td>
                      </tr>
                      <tr>
                        <td className="fw-600">Model</td>
                        <td>{lookupData.Model}</td>
                      </tr>
                      <tr>
                        <td className="fw-600">Series</td>
                        <td>{lookupData.Series}</td>
                      </tr>
                      <tr>
                        <td className="fw-600">Drive Type</td>
                        <td>{lookupData.DriveType}</td>
                      </tr>
                      <tr>
                        <td className="fw-600">Engine Size (cc)</td>
                        <td>{lookupData.EngineSize}</td>
                      </tr>
                      <tr>
                        <td className="fw-600">Fuel Type</td>
                        <td>{lookupData.FuelType}</td>
                      </tr>
                      <tr>
                        <td className="fw-600">Transmission</td>
                        <td>{lookupData.Transmission}</td>
                      </tr>
                      <tr>
                        <td className="fw-600">Manufactured</td>
                        <td>{lookupData.RegDate}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {manualInfo && <>
          <div className="col">
            <div className="row">
              <div className="col-sm-12">
                <Field
                  type="text"
                  label="Vehicle Registration *"
                  component={renderField}
                  name={`vrm`}
                  className="form-control"
                  normalize={value => value && value.replace(/\s/g, '').toLocaleUpperCase()}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  type="text"
                  label="VIN"
                  component={renderField}
                  name={`vehicle.VIN`}
                  className="form-control"
                  normalize={value => value && value.replace(/\s/g, '').toLocaleUpperCase()}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  className="select-z-index-fix2"
                  label="Type *"
                  component={CustomSelect}
                  placeholder="Select"
                  name={`vehicle.Type`}
                  getOptionLabel={(option) => option.value}
                  getOptionValue={(option) => option.value}
                  options={[
                    { value: 'Car' },
                    { value: 'Bike' },
                    { value: 'Van' },
                    { value: 'Motorhome' },
                    { value: 'Other' },
                  ]}
                  validate={[required({ message: 'Please select the vehicle type.' })]}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  type="text"
                  label="Make *"
                  component={renderField}
                  name={`vehicle.Make`}
                  className="form-control"
                  validate={[required({ message: 'Please enter the vehicle make.' })]}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  type="text"
                  label="Model *"
                  component={renderField}
                  name={`vehicle.Model`}
                  className="form-control"
                  validate={[required({ message: 'Please enter the vehicle model.' })]}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  type="text"
                  label="Drive Type"
                  component={renderField}
                  name={`vehicle.DriveType`}
                  className="form-control"
                />
              </div>
              <div className="col-sm-6">
                <Field
                  type="text"
                  label="Fuel *"
                  component={renderField}
                  name={`vehicle.FuelType`}
                  className="form-control"
                  validate={[required({ message: 'Please enter the vehicle fuel.' })]}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  type="text"
                  label="Transmission"
                  component={renderField}
                  name={`vehicle.Transmission`}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <Field
                  type="text"
                  label="Engine Size (cc) *"
                  component={renderField}
                  name={`vehicle.EngineSize`}
                  className="form-control"
                  validate={[
                    required({
                      msg: 'Please enter the vehicle engine size.'
                    }),
                    numericality({
                      int: true,
                      msg: 'Field must contain only numbers.',
                    })
                  ]}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  type="text"
                  id="manufactured"
                  label="Registered *"
                  component={renderDatePicker}
                  inputValueFormat="DD-MM-YYYY"
                  placeholderText="DD/MM/YYYY"
                  dateFormat="dd/MM/yyyy"
                  fixedHeight
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  yearDropdownItemNumber={3}
                  name={`vehicle.Manufactured`}
                  className="form-control"
                  validate={[required({ message: 'Please select the vehicle registered date.' })]}
                />
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <Link
                to="#"
                className="text-blue"
                onClick={() => {
                  setManualInfo(false)
                }}
              >
                Enter Vehicle Registration
              </Link>
            </div>
          </div>
        </>}

        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Current mileage *"
              component={renderField}
              name={`current_mileage`}
              className="form-control"
              append={{
                direction: 'right',
                text: 'miles',
              }}
              validate={[
                required({
                  msg: 'Please enter the vehicle current mileage.'
                }),
                numericality({
                  int: true,
                  msg: 'Field must contain only numbers.',
                })
              ]}
            />
          </div>
          <div className="col">
            <Field
              type="text"
              label="Vehicle sale price *"
              component={renderField}
              name={`vehicle_sale_price`}
              className="form-control"
              append={{
                direction: 'left',
                text: <i className="wf-icon-gbp" />,
              }}
              validate={[
                required({
                  msg: 'Please enter the vehicle sale price.'
                }),
                numericality({
                  int: true,
                  msg: 'Field must contain only numbers.',
                })
              ]}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p>* Mandatory fields</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!valid || !vehicleInfo}
              >
                Next step
              </button>
              <button
                type="button"
                className="btn btn-outline btn-form-cancel"
                onClick={handleResetForm}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

StepVehicle.propTypes = {
  handleLookup: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  lookupData: PropTypes.object,
  setLookupData: PropTypes.func,
  valid: PropTypes.bool.isRequired,
}

const validate = values => {
  let errors = {}

  if (!values.vrm) errors.vrm = 'Please enter the vehicle registration number.'
  if (!values.mileage) errors.mileage = 'Accurate mileage is required.'
  if (!values.sale_price) errors.sale_price = 'Vehicle sale price is required.'

  return errors
}

const selector = formValueSelector('create-policy-vehicle')

export default connect((state) => ({
  vrm: selector(state, 'vrm'),
}))(
  reduxForm({
    form: 'create-policy-vehicle',
    destroyOnUnmount: false,
    validate,
  })(StepVehicle),
)
