import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FieldArray, Form, reduxForm } from 'redux-form'
import api from '../../../../utils/api'
import { NotificationManager } from 'react-notifications'
import renderExcess from '../../../ui/fields/renderExcess'
import renderLabourRates from '../../../ui/fields/renderLabourRates'
import renderSundries from '../../../ui/fields/renderSundries'
import { isEmpty, get } from 'lodash'

const PricingRuleForm = ({ handleSubmit, onSubmit, coverType, url, array: { push }, valid, hasSundries, children, error }) => {
  const [coverLevels, setCoverLevels] = useState(null)
  const [durations, setDurations] = useState(null)

  useEffect(() => {
    push('rules', {})
    push('labour_rates', {})
    push('sundries', {})
  }, [])
  useEffect(() => {

    api.get(`/admin/cover-levels?limit=50&target=${coverType}`)
      .then(res => {
        let coverLevels = res.data.data.map(({ id, name }) => {
          return { id, name }
        })
        setCoverLevels(coverLevels)
      })
      .catch(err => {
        if (err.response) {
          NotificationManager.error('Oops! Something went wrong. Please try again')
        }
      })

    api.get(`/admin/price-rules/${url}/sundry-duration-options`)
      .then(res => {
        setDurations(res.data)
      })
      .catch(err => {
        if (err.response) {
          NotificationManager.error('Oops! Something went wrong. Please try again')
        }
      })

  }, [])

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <table className="table table-striped table-bordered pricing-rule-table mt-4 mb-5">
          <thead>
          <tr>
            <th width="160px">Excess</th>
            <th width="325px">Policy cost impact</th>
            <th>Applies to cover level(s)</th>
            <th width="100px">Default*</th>
            <th>Active**</th>
          </tr>
          </thead>
          <tbody>
          <FieldArray name={'rules'} component={renderExcess} coverLevels={coverLevels}/>
          </tbody>
        </table>

        <table className="table table-striped table-bordered pricing-rule-table">
          <thead>
          <tr>
            <th width="160px">Labour rate</th>
            <th width="325px">Policy cost impact</th>
            <th>Applies to cover level(s)</th>
            <th width="100px">Default*</th>
            <th>Active**</th>
          </tr>
          </thead>
          <tbody>
          <FieldArray name={'labour_rates'} component={renderLabourRates} coverLevels={coverLevels}/>
          </tbody>
        </table>


        <div className="my-5">
          {children}
        </div>


        {hasSundries && <div className="" style={{ marginTop: 65 }}>
          <h6 className="title-xs text-uppercase fw-600">Sundry pricing</h6>
          <p style={{ maxWidth: 535 }}>
            Sundry price rules can be set up for dealers or direct sales. If a dealer or user selects one of the sundries when adding a new
            policy, prices will be adjusted accordingly.
          </p>

          <table className="table table-striped table-bordered pricing-rule-table" style={{ marginTop: 40 }}>
            <thead>
            <tr>
              <th width="160px">Sundry</th>
              <th width="325px">Policy cost impact</th>
              <th>Applies to cover level(s)</th>
              <th>Applies to duration(s)</th>
              <th>Active**</th>
            </tr>
            </thead>
            <tbody>
            <FieldArray name={'sundries'} component={renderSundries} coverLevels={coverLevels} durations={durations}/>
            </tbody>
          </table>
        </div>}

        <button type="submit" disabled={!valid} name="submit" className={`btn btn-secondary mb-4`}>
          Save prices
        </button>
      </Form>

    </div>
  )
}

PricingRuleForm.propTypes = {
  hasSundries: PropTypes.bool,
}

PricingRuleForm.defaultProps = {
  hasSundries: false,
}

const validate = values => {
  const checkRule = (rule, type) => {
    let errors = {};

    if ((type !== 'Sundry') && rule.title < 0) {
      errors.title = 'The value cannot less than 0'
    }

    if (type === 'Excess' && rule.title >= 100) {
      errors.title = 'The percentage must be less than 100'
    }

    if (get(rule, 'effect.value') === undefined) {
      errors.effect = 'You must choose an effect (+ or -)'
    }

    if (get(rule, 'cost_type.value') === undefined) {
      errors.cost_type = 'You must choose a cost type (% or £)'
    } else if (get(rule, 'cost_type.value') === '%' && rule.value >= 100) {
      errors.value = 'The percentage must be less than 100'
    }

    if (!get(rule, 'value')) {
      errors.value = 'Please enter a value'
    }

    if (get(rule, 'value') < 0) {
      errors.value = 'Please enter a positive number'
    }



    if (get(rule, 'cover_levels', []).filter(Boolean).length === 0) {
      errors.active = 'You must select a cover level.'
    }

    return errors;
  }



  let errors = {}

  if (values.rules && values.rules.length === 1) {
    if (isEmpty(values.rules[0])) {
      errors.rules = true
    }
  }

  errors = {...errors, labour_rates: {...get(values, 'labour_rates', []).map(rule => (checkRule(rule, 'Labour Rate')))}}
  errors = {...errors, rules: {...get(values, 'rules', []).map(rule => (checkRule(rule, 'Excess')))}}
  errors = {...errors, sundries: {...get(values, 'sundries', []).map(rule => (checkRule(rule, 'Sundry')))}}

  return errors
}

export default reduxForm({
  form: 'pricing-rules',
  enableReinitialize: true,
  validate,
})(PricingRuleForm)
