import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, formValueSelector, touch } from 'redux-form'
import CustomSelect from '../../../../ui/CustomSelect'
import renderField from '../../../../../utils/renderField'
import { get } from 'lodash'
import { confirmAlert } from 'react-confirm-alert'

const RuleRow = ({
  availableRules,
  idx,
  setRules,
  users,
  handleDelete,
  rule,
  dispatch,
}) => {
  useEffect(() => {
    if (get(setRules, `${idx}.id`, 0) === 0) {
      dispatch(touch('individualCommissionForm', `rules.${idx}.*`))
    }
  }, [])

  const getValueBoxType = (setRules) => {
    let typeName = get(setRules, `${idx}.type.name`, null)
    if (typeName) {
      if (typeName[0] === '%') {
        return 'text'
      } else if (typeName[0] === '£') {
        return 'text'
      } else {
        return 'hidden'
      }
    }
    return 'hidden'
  }

  const getPrependAppendType = (setRules) => {
    let typeName = get(setRules, `${idx}.type.name`, null)
    if (typeName) {
      if (typeName[0] === '%') {
        return {
          direction: 'right',
          text: '%',
        }
      } else if (typeName[0] === '£') {
        return {
          direction: 'left',
          text: <i className="wf-icon-gbp" />,
        }
      } else {
        return {}
      }
    }
    return {}
  }

  return (
    <tr>
      <td>
        <span className="if">If</span>
        <span className="field">
          <Field
            component={CustomSelect}
            showSuccessValidation={false}
            name={`rules.${rule.rfid}.type`}
            id={`${idx}Type`}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.name}
            options={availableRules}
            small={true}
            size={'sm'}
          />
        </span>
      </td>
      <td>
        <Field
          type={getValueBoxType(setRules)}
          label=""
          component={renderField}
          append={getPrependAppendType(setRules)}
          name={`rules.${rule.rfid}.value`}
          className="form-control form-control-sm"
          showSuccessValidation={false}
        />
      </td>
      <td>
        <Field
          type="text"
          label=""
          component={renderField}
          append={{
            direction: 'right',
            text: '%',
          }}
          name={`rules.${rule.rfid}.commission`}
          className="form-control form-control-sm"
          showSuccessValidation={false}
        />
      </td>
      <td>
        <Field
          component={CustomSelect}
          name={`rules.${rule.rfid}.assignee`}
          id={`${idx}Type`}
          options={users}
          showSuccessValidation={false}
          size={'sm'}
        />
      </td>
      <td>
        <span
          className="wf-icon-delete"
          style={{ display: 'inline-block', cursor: 'pointer' }}
          data-tip="Delete users"
          data-place="top"
          data-tooltip="Tooltip on top"
          onClick={() =>
            confirmAlert({
              title: '🧐 Are you sure?',
              message: `Delete this commission rule?`,
              customUI: ({ title, message, onClose }) => {
                return (
                  <div className="react-confirm-alert">
                    <div className="react-confirm-alert-body">
                      <span
                        className="btn-close-modal fa fa-close"
                        onClick={onClose}
                      />
                      <h3>{title}</h3>
                      <p>{message}</p>

                      <button
                        className="btn btn-secondary"
                        onClick={() => {
                          onClose()
                          handleDelete(rule.id, rule.rfid)
                        }}
                      >
                        Yes, I'm sure
                      </button>

                      <button
                        type="button"
                        className="btn btn-transparent text-secondary"
                        onClick={onClose}
                      >
                        No, cancel
                      </button>
                    </div>
                  </div>
                )
              },
            })
          }
        />
        <Field
          type="hidden"
          component={renderField}
          name={`rules.${rule.rfid}.id`}
        />
      </td>
    </tr>
  )
}

let selector = formValueSelector('individualCommissionForm')
export default connect((state) => {
  return {
    setRules: selector(state, 'rules'),
  }
})(RuleRow)
