import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { AppHeader } from '@coreui/react'
import ResetPasswordForm from './ResetPasswordForm'
import { connect } from 'react-redux'
import { getFormValues, isValid } from 'redux-form'
import api from '../../../../utils/api'
import { history } from '../../../../utils/history'
import { hasPermission } from '../../../../utils/auth';

class ResetPasswordPage extends Component {

  constructor(props) {
    super(props);
    this.state = { formError: '' }
  }

  handleSubmit = values => {
    const search = this.props.location.search
    const email = new URLSearchParams(search).get('email')

    //check the token first
    api.get(`/auth/set-password/${this.props.match.params.token}?email=${email}&new=false`)
      .then(res => {
        // token was ok so set the user's password
        api.post(`/auth/set-password`, {
          token: this.props.match.params.token,
          email: email,
          password: values.password
        }).then(res => {
          // store the access token in local storage (i.e. log the user in)
          localStorage.setItem('wf-token', JSON.stringify(res.data.access_token))

          // redirect the user to their default page
          if (hasPermission('dealer_portal.*')) {
            history.push('/dealer-portal/policies')
          } else if (hasPermission('policyholder_portal.*')) {
            history.push('/policyholder-portal/policies')
          } else {
            history.push('/dashboard')
          }

          window.location.reload(false); // so that React reconstructs the user object from the access token
        })
        .catch(err => {
          this.setState({formError: 'An unexpected error occured. Please try again.'})
        })
      })
      .catch(err => {
        this.setState({formError: 'This link has expired.'})
      })
  }

  render() {
    return (
      <div>
        <AppHeader fixed>
          <Link to={'/'} className="navbar-brand"/>
        </AppHeader>

        <div className="vh-100 container-fluid">
          <div className="row h-100 justify-content-center">
            <div className="h-100 d-flex align-items-center justify-content-center col">
              <div className="mt-5 mw-30rem col-lg-10 col-xl-8">

                <ResetPasswordForm onSubmit={this.handleSubmit} submitError={this.state.formError} />

                <p className="small text-center">By signing in, you agree to the <a
                  href="https://driver.warrantyfirst.co.uk/websitetermsandconditions.php">Terms of
                  Service</a> and <a href="https://driver.warrantyfirst.co.uk/privacypolicy.php">Privacy Policy</a>
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    valid: isValid('auth/set-password')(state),
    values: getFormValues('set-password')(state),
  }),
  {},
)(ResetPasswordPage)
