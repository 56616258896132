import { CLAIMS_SITE_URL } from './constants';

export default [
  {
    menuTitle: 'Admin',
    permission: 'admin.*',
    displayOn: '/admin',
    routes: [
      {
        name: 'Manage users',
        url: '/admin/users',
        image: 'icn-manage-users',
        permission: 'admin.users.*',
        children: [
          {
            name: 'Users',
            url: '/admin/users',
            // image: 'icn-user',
            permission: 'admin.users.*',
          },
          {
            name: 'Groups',
            url: '/admin/user-groups',
            // image: 'icn-group',
            permission: 'admin.users.groups.*',
          },
        ],
      },
      {
        name: 'Cover levels',
        url: '#',
        image: 'icn-cover-levels',
        permission: 'admin.cover_levels.*',
        children: [
          {
            name: 'Public',
            url: '/admin/public-cover-levels',
            permission: 'admin.cover_levels.*',
          },
          {
            name: 'Dealer',
            url: '/admin/dealer-cover-levels',
            permission: 'admin.cover_levels.*',
          },
          {
            name: 'Direct sales',
            url: '/admin/direct-sales-cover-levels',
            permission: 'admin.cover_levels.*',
          },
        ],
      },
      {
        name: 'Price bands',
        url: '#',
        image: 'icn-price-bands',
        permission: 'admin.price_bands.*',
        children: [
          {
            name: 'Public',
            url: '/admin/public-price-bands',
          },
          {
            name: 'Dealer',
            url: '/admin/dealer-price-bands',
          },
          {
            name: 'Direct sales',
            url: '/admin/direct-sales-price-bands',
          },
        ],
      },
      {
        name: 'Pricing rules',
        url: '#',
        image: 'icn-price-rules',
        permission: 'admin.price_rules.*',
        children: [
          {
            name: 'Public',
            url: '/admin/pricing-rules/public',
          },
          {
            name: 'Dealer',
            url: '/admin/pricing-rules/dealer',
          },
          {
            name: 'Direct sales',
            url: '/admin/pricing-rules/direct-sales',
          },
        ],
      },
      {
        name: 'Special vehicles',
        url: '#',
        image: 'icn-special-vehicle',
        permission: 'admin.special_vehicles.*',
        children: [
          {
            name: 'Public',
            url: '/admin/special-vehicles/public',
          },
          {
            name: 'Dealer',
            url: '/admin/special-vehicles/dealer',
          },
          {
            name: 'Direct sales',
            url: '/admin/special-vehicles/direct-sales',
          },
        ],
      },
      {
        name: 'Team Budgets',
        url: '/admin/budgets/team/',
        image: 'icn-team-budgets',
        permission: 'admin.targets.*',
      },
      {
        name: 'External sales',
        url: '#',
        image: 'icn-external-sales',
        permission: 'admin.territories.*',
        children: [
          {
            name: 'Budgets',
            url: '/admin/budgets/external-sales',
            permission: 'admin.targets.*'
          },
          {
            name: 'Territories',
            url: '/admin/territories',
            permission: 'admin.territories.*',
          },
          {
            name: 'Commission',
            url: '/admin/commission/external-sales',
            permission: 'admin.commission.*',
          },
        ],
      },
      {
        name: 'Direct sales',
        url: '#',
        image: 'icn-direct-sales',
        permission: 'admin.targets.*',
        children: [
          {
            name: 'Budgets',
            url: '/admin/budgets/direct-sales',
            permission: 'admin.targets.*'
          },
          {
            name: 'Commission',
            url: '/admin/commission/direct-sales',
            permission: 'admin.commission.*',
          },
        ],
      },
    ],
  },

  /**
   * Direct sales
   */
  {
    menuTitle: 'Direct sales',
    permission: 'direct_sales.*',
    displayOn: '/direct-sales',
    routes: [
      {
        name: 'Renewals',
        url: '/direct-sales/renewals',
        permission: 'direct_sales.renewals.*',
        image: 'icn-renewals',
      },
      {
        name: 'Prospects',
        url: '/direct-sales/prospects',
        permission: 'direct_sales.prospects.*',
        image: 'icn-prospects',
      },
      {
        name: 'Pipeline',
        url: '/direct-sales/pipeline',
        permission: 'direct_sales.pipeline.*',
        image: 'icn-pipeline',
      },
      {
        name: 'Policies',
        url: '/direct-sales/policies',
        permission: 'direct_sales.policies.*',
        image: 'icn-policies',
      },
      {
        name: 'Policyholders',
        url: '/direct-sales/policyholders',
        permission: 'direct_sales.policyholders.*',
        image: 'icn-policyholders',
      },

      /**
       * These are just as guide as to what icons to use for the menu
       */
      // {
      //   name: 'Dealers',
      //   url: '/direct-sales/dealers',
      //   permission: 'direct_sales.dealers.list',
      //   icon: 'icon-flag',
      // },
      // {
      //   name: 'Reports',
      //   url: '/direct-sales/reports',
      //   permission: 'direct_sales.reports.list',
      //   icon: 'icon-chart',
      // },
    ],
  },

  /**
   * External sales
   */
  {
    menuTitle: 'External sales',
    permission: 'external_sales.*',
    displayOn: '/external-sales',
    routes: [
      {
        name: 'Dealers',
        url: '/external-sales/dealers',
        permission: 'external_sales.dealers.*',
        image: 'icn-dealers',
      },
      {
        name: 'Prospects',
        url: '/external-sales/prospects',
        permission: 'external_sales.prospects.*',
        image: 'icn-prospects',
      },
      {
        name: 'Pipeline',
        url: '/external-sales/pipeline',
        permission: 'external_sales.pipeline.*',
        image: 'icn-pipeline',
      },
      {
        name: 'Territories',
        url: '/external-sales/territories',
        permission: 'external_sales.territories.*',
        image: 'icn-territories',
      },
      {
        name: 'Policies',
        url: '/external-sales/policies',
        permission: 'external_sales.policies.*',
        image: 'icn-policies',
      },
      {
        name: 'Policyholders',
        url: '/external-sales/policyholders',
        permission: 'external_sales.policyholders.*',
        image: 'icn-policyholders',
      },
    ],
  },

  /**
   * Claims
   */
  {
    menuTitle: 'Claims',
    permission: 'claims.*',
    displayOn: '/claims',
    routes: [
      {
        name: 'Claims',
        url: '/claims/claims',
        permission: 'claims.claims.*',
        image: 'icn-claims',
      },
      {
        name: 'Policies',
        url: '/claims/policies',
        permission: 'claims.policies.*',
        image: 'icn-policies',
      },
      {
        name: 'Policyholders',
        url: '/claims/policyholders',
        permission: 'claims.policyholders.*',
        image: 'icn-policyholders',
      },
      {
        name: 'Garages',
        url: '/claims/garages',
        permission: 'claims.garages.*',
        image: 'icn-garages',
      },
      {
        name: 'Dealers',
        url: '/claims/dealers',
        permission: 'claims.dealers.*',
        image: 'icn-dealers',
      },
    ],
  },

  /**
   * Account
   */
  {
    menuTitle: 'Account',
    permission: 'account.*',
    displayOn: '/account',
    routes: [
      {
        name: 'Your profile',
        url: '/account/profile',
        icon: 'icon-user',
        permission: 'account.profile.*',
      },
      // {
      //   name: 'Awards',
      //   url: '/account/awards',
      //   icon: 'icon-prize',
      //   permission: 'account.awards.*',
      // },
    ],
  },

  /**
   * Dashboard
   */
  {
    menuTitle: 'Dashboard',
    permission: 'dashboards.*',
    displayOn: '/dashboard',
    url: '/dashboard',
    routes: [
      {
        name: 'Claims',
        url: '/dashboard/claims',
        permission: 'dashboards.claims.*',
        image: 'icn-claims',
      },
      {
        name: 'Direct Sales',
        url: '/dashboard/direct-sales',
        permission: 'dashboards.direct_sales.*',
        image: 'icn-direct-sales',
      },
      {
        name: 'External Sales',
        url: '/dashboard/external-sales',
        permission: 'dashboards.external_sales.*',
        image: 'icn-external-sales',
      },
    ],
  },

  /**
   * Dealer Portal
   */
  {
    menuTitle: 'Dealer Portal',
    permission: 'dealer_portal.*',
    displayOn: '/dealer-portal',
    url: '/dealer-portal',
    routes: [
      {
        name: 'Dashboard',
        url: '/dealer-portal/dashboard',
        permission: 'dealer_portal.dashboard',
        image: 'icn-dashboard',
      },
      {
        name: 'Policies',
        url: '/dealer-portal/policies',
        permission: 'dealer_portal.policies',
        image: 'icn-policies',
      },
      {
        name: 'Account Details',
        url: '/dealer-portal/account-details',
        permission: 'dealer_portal.account_details',
        image: 'icn-account-details',
      },
      {
        name: 'Billing',
        url: '/dealer-portal/billing',
        permission: 'dealer_portal.billing',
        image: 'icn-billing',
      },
      {
        name: 'Start a Claim',
        url: CLAIMS_SITE_URL,
        isExternal: true,
        permission: 'dealer_portal.*',
        image: 'icn-start-a-claim',
      },
    ],
  },

  /**
   * Policyholder Portal
   */
  {
    menuTitle: 'Policyholder Portal',
    permission: 'policyholder_portal.*',
    displayOn: '/policyholder-portal',
    url: '/policyholder-portal',
    routes: [
      {
        name: 'Your account',
        url: '/policyholder-portal/account-details',
        image: 'icn-account-details',
        permission: 'policyholder_portal.account_details',
      },
      {
        name: 'Your policies',
        url: '/policyholder-portal/policies',
        image: 'icn-policies',
        permission: 'policyholder_portal.policies',
      },
      {
        name: 'Your claims',
        url: '/policyholder-portal/claims',
        image: 'icn-claims',
        permission: 'policyholder_portal.claims',
      },
      {
        name: 'Billing',
        url: '/policyholder-portal/billing',
        image: 'icn-billing',
        permission: 'policyholder_portal.billing',
      },
    ],
  },
]
