import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { find } from 'lodash'
import { formatDateYMD, money } from '../../../../utils/misc'
import { NotificationManager } from 'react-notifications'
import api from '../../../../utils/api'
import { API_URL } from '../../../../utils/constants'
import { Link, useHistory } from 'react-router-dom'
import Modal from '../../../modals/Modal'
import useModal from '../../../../hooks/useModal'
import CustomisePriceForm from './CustomisePriceForm'

const PolicyCard = (props) => {
  let history = useHistory()

  const { prices, claimLimit, planLength, payNow, rewritePrice } = props

  const [savedQuote, setSavedQuote] = useState(null)
  const {
    isShowing: isShowingCustomPriceModal,
    toggle: toggleCustomPriceModal,
  } = useModal()

  if (!claimLimit) return <></>

  let price = parseFloat(find(prices, ['claim_limit', claimLimit.value]).price)

  const customPrice = parseFloat(
    find(prices, ['claim_limit', claimLimit.value]).custom_price
  )

  const monthly_amt = parseFloat(
    find(prices, ['claim_limit', claimLimit.value]).monthly_amt
  )

  const monthly_qty = parseFloat(
    find(prices, ['claim_limit', claimLimit.value]).monthly_qty
  )

  // price divided by number of months
  const openCustomisePriceModal = (e) => {
    e.preventDefault()
    toggleCustomPriceModal()
  }

  const handleCustomPriceSubmit = (values) => {
    rewritePrice(planLength, claimLimit.value, values.customPrice)
    toggleCustomPriceModal()
  }

  /**
   * Save quote to API
   */
  const saveQuote = () => {
    let fields = props.getQuoteValues({
      duration: planLength + ' months',
      payNow,
    })

    fields.pay_monthly = !payNow

    // remove the conversation to UTC for the policy start date
    fields.start_date = formatDateYMD(fields.start_date)

    api
      .post(`${API_URL}/direct-sales/prospects/quotes`, fields)
      .then((response) => {
        NotificationManager.success('Woohoo! Your quote has been saved.')
        setSavedQuote(response.data)
        history.push(`/direct-sales/prospects/${response.data.id}/quote`)
      })
      .catch((err) => {
        if (err.response.status === 422) {
          let errors = []
          for (let field in err.response.data.errors) {
            if (err.response.data.errors.hasOwnProperty(field)) {
              errors.push(err.response.data.errors[field][0])
            }
          }
          NotificationManager.error(errors.join(' '))
        } else {
          NotificationManager.error(
            'Oops! Your quote was not saved. Please try again after refreshing the page.'
          )
        }
      })
  }

  return (
    <div className="product-card-mini p-4 shadow-dark">
      <div className="row align-items-center mb-3">
        <div className="col">
          <h5 className="fw-600 font-md">{planLength} MONTH PLAN</h5>
        </div>
        <div className="col text-right">
          {!savedQuote && (
            <button
              onClick={saveQuote}
              className="btn btn-primary px-3 font-sm"
            >
              Save Quote
            </button>
          )}
        </div>
      </div>

      {!payNow && (
        <div className="row">
          <div className="col monthly-summary">
            <p className="mb-0">{monthly_qty} payments @</p>
          </div>
        </div>
      )}

      <div className="d-flex align-items-baseline justify-content-between mt-3">
        {payNow ? (
          <span className="annual price">
            {money.format(customPrice ? customPrice : price)}
          </span>
        ) : (
          <span className="monthly price">
            {money.format(
              customPrice ? customPrice / monthly_qty : monthly_amt
            )}
          </span>
        )}
      </div>
      <Link
        className="font-sm d-block"
        style={{ marginTop: 10 }}
        to={'#'}
        onClick={openCustomisePriceModal}
      >
        Customise price
      </Link>
      <Modal
        isShowing={isShowingCustomPriceModal}
        onCancel={toggleCustomPriceModal}
        textCancel={null}
        title={'Customise price'}
        subtitle={`${props.coverLevel} ${planLength} month plan`}
        style={{ width: 434 }}
      >
        <CustomisePriceForm
          payNow={payNow}
          noOfPayments={monthly_qty}
          initialValues={{
            customPrice: customPrice
              ? parseFloat(customPrice).toFixed(2)
              : parseFloat(price).toFixed(2),
          }}
          onSubmit={handleCustomPriceSubmit}
          onCancel={toggleCustomPriceModal}
        />
      </Modal>
    </div>
  )
}

PolicyCard.propTypes = {
  prices: PropTypes.array.isRequired,
  payNow: PropTypes.bool.isRequired,
}

export default PolicyCard
