import React, { Fragment, Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import TerritoryEditForm from './TerritoryEditForm'
import {
  addAccountManager,
  fetchSingleTerritory,
  updateTerritory,
} from '../../../actions/territories.actions'
import { confirmAlert } from 'react-confirm-alert'
import { resetForm } from '../../../actions/cover-levels.actions'
import Table from '../../ui/Table'
import { sortCaret } from '../../../utils/misc'
import ReactTooltip from 'react-tooltip'
import api from '../../../utils/api'
import { NotificationManager } from 'react-notifications'
import AddPostcodesForm from './AddPostcodesForm'
import AssignTerritoryAccountManager from './AssignTerritoryAccountManager'
import TerritoryAccountManagersTable from './TerritoryAccountManagersTable'
import { bindActionCreators } from 'redux'

class TerritoryEditPage extends Component {
  state = {
    postcodes: null,
    searchingPostcodes: false,
    columns: [
      {
        dataField: 'postcode',
        text: 'Postcodes',
        sort: true,
        sortCaret,
      },
      {
        dataField: 'id',
        text: 'Actions',
        headerStyle: {
          width: 80,
        },
        formatter: (cell, row) => (
          <Fragment>
            <Link
              data-tip="Delete postcode"
              data-place="top"
              to={`#`}
              className="table-action-btn table-action-btn--delete"
              size="sm"
              onClick={() => this.showModalDeletePostcode(row)}
            >
              <span className="icon-trash" />
            </Link>
            <ReactTooltip effect="solid" />
          </Fragment>
        ),
      },
    ],
    selected: [],
  }

  cancel = e => {
    e.preventDefault()
    this.props.resetForm('territory/edit')
    this.props.history.push('/admin/territories')
    this.setState({
      selected: [],
    })
  }

  fetchPostcodes = (tid, params) => {
    api
      .get(`/admin/territories/${tid}/postcodes${params ? params : ''}`)
      .then(res => {
        this.setState({ postcodes: res.data })
      })
  }

  /**
   * Delete postcode
   * @param row
   */
  deletePostcode = row => {
    api
      .delete(`admin/territories/${row.territory_id}/postcodes/${row.postcode}`)
      .then(res => {
        this.setState(state => ({
          ...state,
          postcodes: {
            ...state.postcodes,
            data: this.state.postcodes.data.filter(
              item => item.postcode !== row.postcode,
            ),
          },
          selected: [],
        }))
        NotificationManager.success('Woohoo! You deleted a postcode.')
      })
      .catch(err => {
        NotificationManager.error('Oops! Something went wrong.')
      })
  }

  componentDidMount() {
    const tid = this.props.match.params.id

    if (!isNaN(Number(tid))) {
      this.props.fetchSingleTerritory(tid)
      this.fetchPostcodes(tid)
    } else {
      this.props.history.push('/404')
    }
  }

  /**
   * Delete territory by id
   * @param id
   */
  deleteTerritory = id => {
    api
      .delete(`admin/territories/${id}`)
      .then(res => {
        NotificationManager.success('Woohoo! You deleted a territory.')
        // eslint-disable-next-line no-restricted-globals
        this.props.history.push('/admin/territories')
        this.setState({
          selected: [],
        })
      })
      .catch(err => {
        NotificationManager.error(
          'Ooops! Something went wrong. Please try again.',
        )
      })
  }

  /**
   * MODAL - Delete territory
   * @param territory
   */
  showModalDeleteTerritory = territory => {
    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>
                Delete the territory <strong>{territory.name}</strong>? It'll be
                lost forever.
              </p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  this.deleteTerritory(territory.id)
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={() => {
                  onClose()
                  this.setState({ selected: [] })
                }}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  /**
   * MODAL - Delete postcode
   * @param row
   */
  showModalDeletePostcode = row => {
    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>
                Delete the postcode <strong>{row.postcode}</strong>? It'll be
                lost forever.
              </p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  this.deletePostcode(row)
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  /**
   * MODAL - Add postcode
   */
  addPostcodesModal = () => {
    return confirmAlert({
      title: 'Add postcodes',
      customUI: ({ title, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div
              className="react-confirm-alert-body"
              style={{ width: '40rem' }}
            >
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3 className="mb-0">{title}</h3>
              <h4
                className="text-black-50 font-weight-normal text-capitalize mb-4"
                style={{ fontSize: '1.12rem' }}
              >
                {this.props.territory.name}
              </h4>
              <AddPostcodesForm
                fetchPostcodes={this.fetchPostcodes}
                territory={this.props.territory}
                onClose={onClose}
              />
            </div>
          </div>
        )
      },
    })
  }

  handleCheckboxChange = (e, rowIndex, row) => {
    const postcode = this.state.postcodes.data[rowIndex].postcode

    if (row.checked) {
      this.setState(prevState => ({
        selected: [...prevState.selected, postcode],
      }))
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== postcode),
      }))
    }
  }

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    let url = `?page=${page}&desc=${
      sortOrder === 'desc' ? true : false
    }&limit=${sizePerPage}`
    if (sortField) url = `${url}&order=${sortField}`

    this.fetchPostcodes(this.props.match.params.id, url)
  }

  /**
   * Search postcodes
   * @param query
   */
  handleSearch = query => {
    this.setState({ searchingPostcodes: true })
    api
      .get(
        `admin/territories/${
          this.props.match.params.id
        }/postcodes?query=${query}`,
      )
      .then(res => {
        this.setState({ postcodes: res.data, searchingPostcodes: false })
      })
      .catch(err => {
        this.setState({ searchingPostcodes: false })
      })
  }

  handleRowSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.postcode)
    if (isSelect) {
      this.setState(() => ({
        selected: ids,
      }))
    } else {
      this.setState(() => ({
        selected: [],
      }))
    }
  }

  handleRowSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row.postcode],
      }))
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== row.postcode),
      }))
    }
  }

  /**
   * Delete multiple postcodes
   */
  deleteMultiplePostcodes = () => {
    api
      .patch(`admin/territories/${this.props.match.params.id}/postcodes`, {
        postcodes: this.state.selected.toString(),
      })
      .then(res => {
        this.fetchPostcodes(this.props.match.params.id)
        this.setState({ selected: [] })
        NotificationManager.success(`Woohoo! You deleted multiple postcodes.`)
      })
      .catch(err => {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again.',
        )
      })
  }

  /**
   * MODAL - Multi delete postcodes
   */
  showMultiDeleteModal = () => {
    const selected = this.state.selected
    const multiPostcodes = selected.length > 1 && (
      <p>
        Delete the <strong>{selected.length}</strong> postcodes? They'll be lost
        forever.
      </p>
    )
    const postcode = selected.length === 1 && (
      <p>
        Delete the{' '}
        <strong>
          {this.state.selected[0]} postcode? It'll be lost forever.
        </strong>
      </p>
    )

    return confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>

              {postcode}
              {multiPostcodes}
              <button
                className="btn btn-secondary"
                onClick={() => {
                  this.deleteMultiplePostcodes()
                  onClose()
                }}
              >
                Yes, I'm sure
              </button>

              <button
                type="button"
                className="btn btn-transparent text-secondary"
                onClick={() => {
                  onClose()
                  this.setState(() => ({
                    selected: [],
                  }))
                }}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  render() {
    const { isPending, territory } = this.props
    const { columns, postcodes, searchingPostcodes } = this.state

    const selectRow = {
      mode: 'checkbox',
      // clickToSelect: true,
      selected: this.state.selected,
      onSelect: this.handleRowSelect,
      onSelectAll: this.handleRowSelectAll,
      selectionRenderer: ({ mode, rowIndex, ...rest }) => {
        return (
          <div className="text-center lh-1 position-relative align-checkbox">
            <div className="custom-control custom-checkbox custom-control-inline">
              <input
                className="custom-control-input"
                type={mode}
                rowindex={rowIndex}
                onChange={e => this.handleCheckboxChange(e, rowIndex, rest)}
                style={{ width: 10, height: 10 }}
                id={`checkbox-${rowIndex}`}
                {...rest}
              />
              <label
                htmlFor={`checkbox-${rowIndex}`}
                className="custom-control-label"
              />
            </div>
          </div>
        )
      },

      selectionHeaderRenderer: ({ mode, checked, indeterminate, ...rest }) => {
        return (
          <div className="text-center lh-1 position-relative align-checkbox">
            <div className="custom-control custom-checkbox custom-control-inline">
              <input
                className="custom-control-input"
                type={mode}
                checked={checked}
                onChange={this.handleCheckboxChange}
                {...rest}
              />
              <label className="custom-control-label" />
            </div>
          </div>
        )
      },
    }

    return isPending ? (
      <div>Loading</div>
    ) : (
      <Fragment>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin/territories" className="open active">
              Territories
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">Edit territory</span>
          </li>
        </ol>

        <div className="container-fluid animated fadeIn">
          <div className="mw-37-5rem mx-auto">
            <TerritoryEditForm
              onSubmit={this.props.updateTerritory}
              handleDelete={() =>
                this.showModalDeleteTerritory(this.props.territory)
              }
              valid={this.props.valid}
              isPending={this.props.isPending}
              cancel={this.cancel}
            />

            {territory && (
              <>
                <AssignTerritoryAccountManager
                  territory={territory}
                  addAccountManager={this.props.addAccountManager}
                  fetchTerritory={this.props.fetchSingleTerritory}
                />
                <TerritoryAccountManagersTable
                  territory={territory}
                  fetchTerritory={this.props.fetchSingleTerritory}
                />
              </>
            )}

            <div className="row">
              <div className="col" style={{ marginTop: '2.1rem' }}>
                <hr style={{ marginBottom: '3.1rem' }} />
                <h6 className="title-xs">Territory Postcodes</h6>

                <Link
                  to={'#'}
                  className="title-xs"
                  style={{
                    marginBottom: '35px',
                    display: 'block',
                    fontWeight: 400,
                    marginTop: '1rem',
                  }}
                  onClick={this.addPostcodesModal}
                >
                  Add postcodes
                </Link>
                {postcodes && (
                  <Table
                    handleTableChange={this.handleTableChange}
                    searching={searchingPostcodes}
                    resource={postcodes}
                    columns={columns}
                    keyField={'postcode'}
                    selectRow={selectRow}
                    onSearch={this.handleSearch}
                    hasTableSearch={true}
                    tableActions={[
                      {
                        iconClass: 'icon-trash font-lg text-danger',
                        callback: this.showMultiDeleteModal,
                        tooltip: {
                          text: 'Delete multiple claim limits',
                          place: 'top',
                        },
                      },
                    ]}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isPending: state.territories.isPending,
  territory: state.territories.singleTerritory,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateTerritory,
      fetchSingleTerritory,
      addAccountManager,
      resetForm,
      dispatch,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TerritoryEditPage)
