import React from 'react'
import { Link } from 'react-router-dom'
import { Col } from 'reactstrap'
import { LoginFormWrapper } from './Login.style'
import { Field, Form, reduxForm } from 'redux-form'
import renderField from '../../../utils/renderField'
import * as validator from 'validator'

const validate = values => {
  let errors = {}

  if (!values.email) errors.email = 'You must enter your email address.'
  if (!values.password) errors.password = 'You must enter your password.'

  if (values.email && !validator.isEmail(values.email))
    errors.email = 'That email address doesn\'t look quite right.'
  return errors
}

let LoginForm = props => {
  const {handleSubmit, error, onSubmit} = props

  const formErrors = error && error.errors && error.errors.form
  return (
    <LoginFormWrapper>
      <h1 className="h2">Dealer Login</h1>
      <h4 className="text-uppercase">Sign In to your account</h4>
      {formErrors && <div className="alert alert-danger">{formErrors[0]}</div>}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Field
          label="Email address"
          type="email"
          name="email"
          component={renderField}
          className="form-control"
          placeholder="Your email address"
        />
        <Field
          label="Password"
          type="password"
          name="password"
          className="form-control"
          placeholder="Your password"
          component={renderField}
        />
        <div className="row align-items-center" style={{ marginTop: '1.875rem' }}>
          <Col xs="6">
            <button disabled={!props.valid} className="btn btn-secondary">
              Login
            </button>
          </Col>
          <Col xs="6" className="text-right">
            <Link to="/auth/forgot" className="link-forgot-password">Forgot password?</Link>
          </Col>
        </div>
      </Form>
    </LoginFormWrapper>
  )
}

export default reduxForm({
  form: 'auth/login',
  validate,
})(LoginForm)
