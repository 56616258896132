import React, { Fragment, useState } from 'react'

const renderField = ({
  id,
  input,
  fieldIcon,
  className,
  style,
  placeholder,
  label,
  type,
  button,
  fieldInfo,
  append,
  canToggleShow,
  noValidation,
  handleSubmit,
  showSuccessValidation,
  showErrorValidation,
  onBlur,
  size,
  meta: { touched, error, valid, dirty },
  disabled,
  ...rest
}) => {
  const [show, setShow] = useState(false)
  var validated = ''

  if (touched && error && 'error') {
    validated = 'is-invalid'
  }

  if (touched && dirty && valid && showSuccessValidation) {
    validated = 'is-valid'
  }

  const fieldIconMarkup = fieldIcon && (
    <div className="input-group">
      <span className="input-group-prepend">
        <span className="input-group-text">
          <i className={fieldIcon}/>
        </span>
      </span>

      <input
        {...input}
        className={`${className} ${noValidation ? '' : validated} bg-white`}
        placeholder={placeholder}
        type={type}
      />

      {touched && error && showErrorValidation && error.length > 0 && <div className="invalid-feedback">{error}</div>}
    </div>
  )

  const appendFieldMarkup = append && (
    <>
      <div className="input-group">
        {append.direction === 'left' && (
          <span className="input-group-prepend">
          <span className="input-group-text font-sm" style={{ height: size === 'sm' ? 36 : 'auto'}}>{append.text}</span>
        </span>
        )}

        <input
          {...input}
          className={`${className} ${noValidation ? '' : validated} form-control ${size === 'sm' ? 'form-control-sm' : ''}`}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
        />

        {append.direction === 'right' && (
          <span className="input-group-append">
          <span className={`input-group-text font-sm ${size === 'sm' ? 'form-control-sm' : ''}`}>{append.text}</span>
        </span>
        )}
      </div>
      {touched && error && showErrorValidation && <div className="invalid-feedback d-block">{error}</div>}
    </>
  )

  return (
    <div className="b-form-group form-group" style={style}>
      {type !== 'radio' && type !== 'checkbox' && label && (
        <label className="col-form-label pt-0">{label}</label>
      )}

      {type === 'radio' && (
        <div className="custom-control custom-radio custom-control-inline">
          {(validated = '')}
          <input
            {...input}
            id={id}
            className={`${className} ${
              noValidation ? '' : validated
              } custom-control-input`}
            placeholder={placeholder}
            onChange={() => input.onChange(input.value)}
            onBlur={() => input.onBlur(input.value)}
            type={type}
          />

          <label
            className="custom-control-label"
            style={{ fontWeight: 400 }}
            htmlFor={id}
          >
            {label === '' ? '\u00A0' : label}
          </label>

          {touched && error && <div className="invalid-feedback">{error}</div>}
        </div>
      )}

      {type === 'checkbox' && (
        <div className="position-relative align-checkbox">
          <div className="custom-control custom-checkbox custom-control-inline">
            {(validated = '')}

            <input
              {...input}
              id={id}
              className={`custom-control-input`}
              placeholder={placeholder}
              type={type}
            />

            <label
              className={`custom-control-label font-weight-normal ${className} `}
              htmlFor={id}
            >
              {label === '' ? '\u00A0' : label}
            </label>

            {touched && error && (
              <div className="invalid-feedback">{error}</div>
            )}
          </div>
        </div>
      )}

      {(type === 'text' || type === 'email') &&
      (!button ? (
        <Fragment>
          {(!fieldIcon && !append) ?

           (<Fragment>
               <input
                 {...input}
                 className={`${className} ${noValidation ? '' : validated}`}
                 placeholder={placeholder}
                 type={type}
                 disabled={disabled}
               />
               {touched && error && (
                 <div className="invalid-feedback">{error}</div>
               )}
             </Fragment>
           ) : (
             <>
               {fieldIcon && fieldIconMarkup}
               {append && appendFieldMarkup}
             </>
           )}
        </Fragment>
      ) : (
         <Fragment>
           <div className="input-group mb-3">
             <input
               {...input}
               className={`${className} ${noValidation ? '' : validated}`}
               placeholder={placeholder}
             />
             <div className="input-group-append" style={{ zIndex: handleSubmit ? '1' : '-1'}}>
               <button
                 className="btn btn-primary"
                 type="button"
                 onClick={() => touched && valid && handleSubmit(input.value)}
               >
                 {button}
               </button>
             </div>
             {touched && error && (
               <div className="invalid-feedback">{error}</div>
             )}
           </div>
         </Fragment>
       ))}

      {type === 'password' && (
        <Fragment>
          {canToggleShow && show ? (
            <div className="input-show-container">
              <input
                {...input}
                className={`${className} ${noValidation ? '' : validated}`}
                placeholder={placeholder}
                type="text"
              />
              <span
                className={`show-btn ${input.value === '' ? 'disabled' : ''}`}
                onClick={() => setShow(false)}
              >
                Hide
              </span>
            </div>
          ) : (
             <div className="input-show-container">
               <input
                 {...input}
                 className={`${className} ${noValidation ? '' : validated}`}
                 placeholder={placeholder}
                 type="password"
               />
               {canToggleShow && (
                 <span
                   className={`show-btn ${input.value === '' ? 'disabled' : ''}`}
                   onClick={() => setShow(true)}
                 >
                  Show
                </span>
               )}
             </div>
           )}
          {touched && error && (
            <div className="invalid-feedback d-block">{error}</div>
          )}
        </Fragment>
      )}

      {type === 'textarea' && (
        <Fragment>
          <textarea
            {...input}
            className={`${className} ${noValidation ? '' : validated}`}
            placeholder={placeholder}
            {...rest}
          />
          {touched && error && <div className="invalid-feedback">{error}</div>}
        </Fragment>
      )}

      {type === 'file' && (
        <Fragment>
          <input
            className={`d-none`}
            placeholder={placeholder}
            onChange={input.onChange}
            type={type}
            id={id}
          />
          <label
            htmlFor={id}
            className={`d-flex align-items-center mt-3 mb-1 ${className}`}
          >
            <span
              className="btn btn-light"
              style={{ backgroundColor: '#EAE8E7', cursor: 'pointer  ' }}
            >
              Choose file
            </span>
            <span className="ml-3 font-weight-normal font-xs">
              No file chosen
            </span>
          </label>
          {fieldInfo && (
            <div
              className="help-block font-sm"
              style={{ marginTop: '0.65rem' }}
            >
              {fieldInfo}
            </div>
          )}
        </Fragment>
      )}
      {type === 'date' && (
        <Fragment>
          {(!fieldIcon && !append) ?

           (<Fragment>
               <input
                 {...input}
                 className={`${className} ${noValidation ? '' : validated}`}
                 placeholder={placeholder}
                 type={type}
                 {...rest}
               />
               {touched && error && (
                 <div className="invalid-feedback">{error}</div>
               )}
             </Fragment>
           ) : (
             <>
               {fieldIcon && fieldIconMarkup}
               {append && appendFieldMarkup}
             </>
           )}
        </Fragment>
        )}

        {type === 'hidden' && (
          <Fragment>
            <input
              type={type}
              {...input}
              {...rest}
            />
          </Fragment>
        )}
    </div>
  )
}

renderField.defaultProps = {
  showSuccessValidation: true,
  showErrorValidation: true,
}

export default renderField
