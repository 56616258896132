import React, { Component } from 'react'
import PageHeader from '../../../../../ui/PageHeader'
import { Link } from 'react-router-dom'
import api from '../../../../../../utils/api'
import { NotificationManager } from 'react-notifications'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { change, formValueSelector } from 'redux-form'
import EditForm from '../../forms/edit'
import { fetchPriceBand } from '../../../../../../actions/admin/price-bands/price-bands.action'
import { tableHeadings as defaultHeadings } from '../../forms/edit/table-headings'
import { clone, uniqBy } from 'lodash'
import {
  formatCoverLevels,
  formatFields,
  formatPrices,
} from '../../../../../../utils/misc'

class Edit extends Component {
  state = {
    coverLevelOptions: null,
    formattedCoverLevels: null,

    // we need to format field names so values can be read by the table
    formattedFields: null,
    prices: [],
    tableHeadings: defaultHeadings
  }

  async componentDidMount() {
    await this.fetchCoverLevels('Public')

    this.props.fetchPriceBand(this.props.match.params.id).then((res) => {
      const formattedCoverLevels = formatCoverLevels(
        res.prices,
        this.state.coverLevelOptions
      )
      const formattedFields = formatFields(res.prices)

      this.setState((state) => ({
        ...state,
        formattedCoverLevels,
        formattedFields,
        prices: res.prices
      }))
    })

    const priceBand = this.props.location.priceBand

    if (typeof priceBand !== 'undefined') {
      this.props.change('price-band/edit', 'name', priceBand.name)

      let prices = clone(priceBand.prices)

      let data = uniqBy(
        prices.map((p) => {
          const { cover_level, ...rest } = p
          return {
            ...rest,
            cover_level_name: p.cover_level.name,
            cover_level_id: p.cover_level.id,
          }
        }),
        'cover_level_name'
      )

      let items = {}

      for (let t of data) {
        items[t.cover_level_name] = {}
        for (let priceRow of prices) {
          if (priceRow.cover_level.id === t.cover_level_id) {
            let duration = priceRow.duration.split(' ')[0]
            items[t.cover_level_name][`${priceRow.claim_limit}_${duration}`] =
              priceRow.price
          }
        }
      }

      this.props.dispatch(change('price-band/edit', 'prices', items))
    }
  }

  fetchCoverLevels = async (target) => {
    const res = await api.get('/admin/cover-levels?limit=50&target=' + target)
    if (res.status === 200) {
      const coverLevels = res.data.data

      this.props.dispatch(change('price-band/edit', 'coverLevels', coverLevels))

      this.setState((pre) => ({
        ...pre,
        coverLevelOptions: coverLevels,
      }))
    } else {
      NotificationManager.error(
        'Oops! Something went wrong. Please try again after refreshing.'
      )
    }
  }

  onSubmit = (values) => {
    api
      .patch(`/admin/price-bands/${this.props.match.params.id}`, {
        name: values.name,
        target: 'Public',
        rank: 1,
        prices: formatPrices(values.prices, this.props.tableHeadings),
      })
      .then(() => {
        NotificationManager.success('Woohoo! Your changes were saved.')
      })
      .catch((err) => {
        if (err && err.response.status === 422) {
          const errors = err.response.data.errors
          if (errors.hasOwnProperty('prices')) {
            NotificationManager.error(errors.prices[0])
          }
        }
      })
  }

  render() {
    const { coverLevelOptions } = this.state
    const priceBand = this.props.location.priceBand

    return (
      <>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin/public-price-bands" className="open active">
              Public price bands
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">Edit price band</span>
          </li>
        </ol>

        <div className="container-fluid animated fadeIn">
          <PageHeader
            title="Edit price band"
            description={
              "Please start by giving your price band a name and including at least one cover level. You'll then be able to customise duration and prices."
            }
          />
        </div>

        {coverLevelOptions &&
          this.state.formattedCoverLevels &&
          this.state.formattedFields &&
          this.props.priceBand && (
            <EditForm
              onSubmit={this.onSubmit}
              initialValues={{
                ...this.props.priceBand,
                coverLevels: this.state.formattedCoverLevels,
                prices: this.state.formattedFields,
              }}
              coverLevelOptions={coverLevelOptions}
              priceBand={priceBand}
              onCancelRedirectTo={'/admin/public-price-bands'}
              deleteEndpoint={`/admin/price-bands/${this.props.match.params.id}`}
              onDeleteRedirectTo={'/admin/public-price-bands'}
              tableHeadings={this.state.tableHeadings}
              prices={this.state.prices}
            />
          )}
      </>
    )
  }
}

Edit.propTypes = {}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      change,
      fetchPriceBand,
      dispatch,
    },
    dispatch
  )


const selector = formValueSelector('price-band/edit')

export default connect((state) => {
  return {
    priceBand: state.priceBands.priceBand,
    tableHeadings: selector(state, 'tableHeadings')
  }
}, mapDispatchToProps)(Edit)
