import React from 'react'
import PrivateRoute from "../components/PrivateRoute";

import Dashboard from '../components/pages/DealerPortal/Dashboard';
import AccountDetails from "../components/pages/DealerPortal/AccountDetails";
import Policies from "../components/pages/DealerPortal/Policies";
import Billing from "../components/pages/DealerPortal/Billing";
import DirectDebit from "../components/pages/DealerPortal/Billing/DirectDebit";
import Policy from '../components/pages/DealerPortal/Policies/Show';
import CreatePolicy from "../components/pages/DealerPortal/Policies/Create";

const DealerPortalRoutes = () => {
  return <>
    <PrivateRoute path={`/dealer-portal/dashboard`} exact component={Dashboard} />
    <PrivateRoute path={`/dealer-portal/account-details`} exact component={AccountDetails} />
    <PrivateRoute path={`/dealer-portal/policies`} exact component={Policies} />
    <PrivateRoute path={`/dealer-portal/policies/create`} exact component={CreatePolicy} />
    <PrivateRoute exact path={`/dealer-portal/policies/:id/policy`} component={Policy}/>
    <PrivateRoute path={`/dealer-portal/billing`} exact component={Billing} />
    <PrivateRoute path={`/dealer-portal/billing/direct-debit`} exact component={DirectDebit} />
  </>
}

export default DealerPortalRoutes