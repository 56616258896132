import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { echo } from '../../../../utils/misc'
import SlideToggle from '../../../ui/SlideToggle';
import ContactDetailsEdit from './ContactDetailsEdit';
import api from "../../../../utils/api";
import {API_URL} from "../../../../utils/constants";
import {get} from "lodash";
import {NotificationManager} from "react-notifications";
import moment from "moment";

const ContactDetails = ({ policyholder, allowEdit, endpoint, refresh }) => {

  const [policyholderEdit, setPolicyholderEdit] = useState(false);

  const handlePolicyholderEditSubmit = (values) => {

    let data = {
      address_1: get(values, 'address_1', get(values, 'address.address_1')),
      address_2: get(values, 'address_2', get(values, 'address.address_2')),
      address_3: get(values, 'address_3', get(values, 'address.address_3')),
      city: get(values, 'city', get(values, 'address.city')),
      county: get(values, 'county', get(values, 'address.county')),
      postcode: get(values, 'postcode', get(values, 'address.postcode')),
      tel1: values.tel1,
      tel2: values.tel2,
      email: values.email,
    }
    api.patch(`/policyholder-portal/account-details/contact-details`, data)
      .then(res => {
        NotificationManager.success('Woohoo! Your contact details have been updated');
        setPolicyholderEdit(false);

        // odd scroll behaviour fix.
        window.scroll(0, 0)

        if (refresh) {
          refresh()
        }
      })
      .catch(err => {
        NotificationManager.error('Oops! There was a problem updating your contact details.');
        console.log(err);
      });
  }

  const [lookupData, setLookupData] = useState()
  const [useManualInfo, setUseManualInfo] = useState()

  const handlePostcodeLookup = (postcode) => {

    if (!postcode) {
      return false
    }

    setUseManualInfo(false);

    api
      .get(`${API_URL}/misc/postcode-lookup?postcode=${postcode}`)
      .then((res) => {
        if (get(res, 'data.Model', false) === false) {
          setLookupData(null);
          setUseManualInfo(true);
          NotificationManager.error('Oops! We could not find information for this postcode. Please enter the details manually.');
        } else {
          setLookupData(res.data)
        }
      })
      .catch((err) => {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again.'
        )
      })

  }

  return (
    <div className="row">
      {!policyholderEdit &&
      <div className="col-12">
        <div className="row">
          <div className="col d-flex align-items-center mb-3">
            <h6 className="text-uppercase">Contact details</h6>
          </div>
          <div className="col d-flex align-items-center mb-3 justify-content-end">
            {allowEdit &&
            <>
              <button
                className="btn btn-light btn-sm"
                onClick={() => setPolicyholderEdit(true)}
                style={{ width: 34, height: 34 }}
              >
                <i className="wf-icon-edit mr-0" />
              </button>
            </>
            }
          </div>
        </div>

        <div className="row d-flex flex-start">
          <div className="col-6">
            <span className="label">Telephone (home)</span>
            <p className="font-md">{echo(policyholder.tel1)}</p>
          </div>
          <div className="col-6">
            <span className="label">Telephone (mobile)</span>
            <p className="font-md">{echo(policyholder.tel2)}</p>
          </div>
          <div className="col-12">
            <span className="label">Email address</span>
            <p className="font-md">{echo(policyholder.email)}</p>
          </div>
          <div className="col-12">
            <span className="label">Address</span>
            <p className="font-md">{[
              policyholder.address_1,
              policyholder.address_2,
              policyholder.address_3,
              policyholder.city,
              policyholder.country,
              policyholder.postcode,
            ].filter(Boolean).join(', ')}
            </p>
          </div>
        </div>

      </div>
    }

    <div className="col-12">
      <SlideToggle isVisible={policyholderEdit}>
        <ContactDetailsEdit
          policyholder={policyholder}
          onCancel={() => {
            setPolicyholderEdit(false)
          }}
          initialValues={{...policyholder, reg_date: moment(policyholder.reg_date).toDate()}}
          onSubmit={handlePolicyholderEditSubmit}
          handlePostcodeLookup={handlePostcodeLookup}
          lookupData={lookupData}
          manualInfo={[useManualInfo, setUseManualInfo]}
        />
      </SlideToggle>
    </div>

      <div className="pt-1 col">
        <hr className="mt-4" style={{ height: 20 }} />
      </div>

    </div>
  )
}

ContactDetails.propTypes = {
  policyholder: PropTypes.object.isRequired,
  allowEdit: PropTypes.bool,
}

export default ContactDetails
