import React from 'react'
import Avatar from '../Avatar'
import moment from 'moment'
import PropTypes from 'prop-types'
import { has } from 'lodash'
import Ellipsis from '../Ellipsis'
import ReactTooltip from "react-tooltip";

const TimelineCard = ({createdBy, message, isOwner, handleDelete, editNote, editMeeting, onEdit, onPin}) => {
  const [editing, setEditing] = React.useState(false)
  const [editingErrors, setEditingErrors] = React.useState()

  const handleEdit = message => {
    let editFunction = message.message_type === 'Meeting' ? editMeeting : editNote;
    editFunction(message)
      .then(res => {
        setEditing(false)
      })
      .catch(err => {
        if (err && err.response.status === 422) {
          setEditingErrors(err.response.data.errors)
        }
      })
  }

  return (
    <div className={`timeline-card d-flex ${message.pinned && 'timeline-card--pinned'} position-relative`}>
      {message.pinned && <div className="wf-icon-x position-absolute unpin p-2" onClick={() => onPin(message.id)}/>}
      <div className="timeline-card-content flex-grow-1">
        {message.pinned &&
        <div className="d-flex justify-content-between">
          <div
            className="timeline-card-pinned d-inline-flex p-1 px-2 align-items-center d-inline-block text-gray-800">
            <i className="wf-icon-pin mr-2"/>
            <span className="font-sm">Pinned by {message.pinned_by.first_name} {message.pinned_by.last_name}</span>
          </div>
        </div>
        }

        <div className="timeline-card-user d-flex font-lg position-relative">
          {createdBy ? (
              <>
                <Avatar size="small" user={createdBy}/>
                <span className="ml-2">{createdBy.first_name} {createdBy.last_name}</span>
              </>
          ) : (
            <span>System generated</span>

          )}
          <span className="ml-2">- {moment.utc(message.created_at).local().format('DD MMM YYYY - HH:mm')}</span>
        </div>
        {' '}
        {/* .timeline-card-user */}
        <div className="font-md mb-2 d-flex align-items-center">
          {message.message_type === 'Email' && <div className="wf-icon-email-line mr-1"/>}
          {message.message_type === 'Call' && <div className="wf-icon-call mr-1"/>}
          {message.message_type === 'SMS' && <div className="wf-icon-text-outline mr-1"/>}
          {message.message_type === 'Note' && <div className="wf-icon-pencil pull-left mr-1"/>}
          {message.message_type === 'Meeting' && <div className="wf-icon-meeting mr-1"/>}
          {message.message_type}
        </div>
        {editing ? <>
          <div className="form-group mt-1">
              <textarea
                onChange={e => onEdit(e.currentTarget.value)}
                name="note"
                cols="30"
                rows="5"
                className="form-control"
                value={message.message}
              >
              </textarea>
            {editingErrors &&
            <div className="d-block invalid-feedback">{has(editingErrors, 'message') && editingErrors.message[0]}</div>}
          </div>

          <button
            className="btn btn-secondary"
            disabled={message.message.length < 2}
            onClick={() => handleEdit(message)}
          >
            Save changes
          </button>
          <button
            className="btn btn-transparent text-secondary"
            onClick={() => setEditing(false)}
          >
            No, cancel
          </button>
        </> : <Ellipsis text={message.message}/>}
        <div className="timeline-card-actions d-flex justify-content-between">
          <div>
            {/*<button*/}
            {/*  className="btn btn-transparent d-flex align-items-center font-weight-regular font-sm text-blue btn-sm btn-slack pl-0">*/}
            {/*  <i className="wf-icon-slack d-inline-block mr-"/> Forward to*/}
            {/*  Slack*/}
            {/*</button>*/}
          </div>

          <div>
            {!message.pinned && <button
              className="btn btn-transparent btn-action btn-sm font-lg font-weight-bold mr-2"
              data-tip="Pin to the top"
              data-place="top"
              onClick={() => onPin(message.id)}
            >
              <i className="wf-icon-pin d-block"/>
            </button>}

            {isOwner && message.message_type === 'Note' &&
            <button
              className="btn-gray mr-2"
              data-tip="Edit note"
              data-place="top"
              onClick={() => setEditing(true)}
            >
              <i className="wf-icon-edit d-block"/>
            </button>}

            {isOwner && message.message_type === 'Meeting' &&
            <button
              className="btn-gray mr-2"
              data-tip="Edit meeting"
              data-place="top"
              onClick={() => setEditing(true)}
            >
              <i className="wf-icon-edit d-block"/>
            </button>}

            {(message.message_type === 'Note' || message.message_type === 'Meeting') && <button
              className="btn-gray"
              data-tip="Delete note"
              data-place="top"
              onClick={handleDelete}
            >
              <i className="wf-icon-delete d-block"/>
            </button>}
          </div>
          <ReactTooltip effect="solid" />
        </div>
      </div>
    </div>
  )
}

TimelineCard.propTypes = {
  createdBy : PropTypes.object.isRequired,
  message : PropTypes.object.isRequired,
  isOwner : PropTypes.bool.isRequired,
  handleDelete : PropTypes.func.isRequired,
  editNote : PropTypes.func.isRequired,
  editMeeting : PropTypes.func.isRequired,
  onPin : PropTypes.func.isRequired,
}


export default TimelineCard