import React from 'react';
import { Field } from 'redux-form';
import CustomSelect from '../../../../../ui/CustomSelect';
import { prettyNumber } from '../../../../../../utils/misc';
import renderField from '../../../../../../utils/renderField';
import { history } from '../../../../../../utils/history';
import SlideToggle from '../../../../../ui/SlideToggle';
import { numericality } from 'redux-form-validators';

export function PrestigeVehicle(
  vehicle_type,
  coverLevelsOptions,
  coverLevels,
  toggleModal,
  state,
  handleRemoveColumn,
  noCoverLevelsSelected,
  valid,
  props
) {
  return (
    <SlideToggle isVisible={vehicle_type === 'Prestige'}>
      <div className="row">
        <div className="col-md-6 my-5">
          {coverLevelsOptions && (
            <Field
              name={`coverLevels.${vehicle_type}`}
              isMulti
              component={CustomSelect}
              label="Included cover level(s)"
              options={coverLevelsOptions || []}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
            />
          )}
        </div>
      </div>

      {coverLevels && coverLevels[vehicle_type] && coverLevels[vehicle_type] && (
          <>
            <div className="row">
              <div className="col-8">
                <ul className="list-info mb-4" style={{ maxWidth: '570px' }}>
                  <li>Prices exclude VAT.</li>
                  <li>
                    A price of £0 or a blank cell will prevent the warranty / duration / claim limit from being chosen when setting up a policy.
                  </li>
                </ul>

                {/*<div className="d-flex mb-3 align-items-center">*/}
                {/*  <button*/}
                {/*    type="button"*/}
                {/*    className="btn btn-warning btn-sm font-weight-bold mr-3"*/}
                {/*    onClick={() => toggleModal('special')}*/}
                {/*  >*/}
                {/*    Add special duration*/}
                {/*  </button>*/}
                {/*  <p className="mb-0">e.g.Pay for 24 months, drive for 36.</p>*/}
                {/*</div>*/}
              </div>
            </div>
          </>
        )}

        {coverLevels && coverLevels[vehicle_type] && coverLevels[vehicle_type].map((row, i) => (
          <div className="row" key={i}>
            {state.tables && state.tables[vehicle_type] &&
              state.tables[vehicle_type].map((table, index) => {
                return (
                  table.name === row.name && (
                    <div
                      className="mt-2"
                      style={{ overflow: 'scroll' }}
                      key={index}
                    >
                      <div className="col">
                        <div className="label mb-4">
                          {row.name.toUpperCase()} PRICES
                        </div>

                        <table
                          className="table table-striped table-bordered special-vehicles-table"
                          style={{
                            marginBottom: '56px',
                            marginRight: '30px',
                          }}
                        >
                          <thead>
                            <tr>
                              <th>Claim limit</th>
                              {table.columns
                                .sort((x, y) => x.value - y.value)
                                .map((th, i) => (
                                  <th
                                    key={i}
                                    className={`${
                                      th.special
                                        ? 'special-vehicles-table__th-special'
                                        : ''
                                    }`}
                                  >
                                    <div className="d-flex justify-content-between">
                                      <span>{th.title}</span>
                                      {th.special && (
                                        <span>
                                          <i
                                            className="fa fa-close text-black-50"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                              handleRemoveColumn(
                                                table.name,
                                                th,
                                                i
                                              )
                                            }
                                          />
                                        </span>
                                      )}
                                    </div>
                                  </th>
                                ))}
                              <th>
                                <span
                                  className="text-blue cursor-pointer"
                                  onClick={() => toggleModal(false, table.name)}
                                >
                                  Add duration
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {row.claim_limits.map((claim_limit, index) => {
                              let cols =
                                row.name === table.name && table.columns;
                              return (
                                <tr key={index}>
                                  <td>£{prettyNumber(claim_limit)}</td>
                                  {cols.map((th, idx) => {
                                    return (
                                      <td key={idx}>
                                        <Field
                                          component={renderField}
                                          name={`price_band.${vehicle_type}.${row.name}.${claim_limit}_${th.value}`}
                                          showSuccessValidation={false}
                                          showErrorValidation={false}
                                          validate={[
                                            numericality({
                                              allowBlank: true,
                                              '>=': 0
                                            }),
                                          ]}
                                          className="form-control input-group-price"
                                          type="text"
                                          fieldIcon="wf-icon-gbp"
                                        />
                                      </td>
                                    );
                                  })}
                                  <td />
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        ))}

      {coverLevels && coverLevels[vehicle_type] && (
        <div style={{ marginBottom: '4rem' }}>
          <button
            type="submit"
            name="submit"
            className={`btn btn-secondary`}
            disabled={noCoverLevelsSelected || !valid}
          >
            Save
          </button>
          <button
            type="submit"
            className="btn btn-transparent text-secondary"
            onClick={() => history.push(props.onCancelRedirectTo)}
          >
            Cancel
          </button>
        </div>
      )}
    </SlideToggle>
  );
}
