import React from 'react';
import PropTypes from 'prop-types';
import Avatar from './Avatar';

const AddedBy = ({ user }) => {
  return (
    <>
      <h6 className="mb-0 text-uppercase">Added by</h6>
      <div className="my-3 d-flex">
        <Avatar user={user} />
        <span className="ml-2">{user.first_name} {user.last_name}</span>
      </div>
    </>
  );
};

AddedBy.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
};

export default AddedBy;
