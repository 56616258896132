import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { echo } from '../../../../../utils/misc'
import PolicyholderDetailsEdit from './PolicyholderDetailsEdit'
import SlideToggle from '../../../../ui/SlideToggle';

const PolicyholderDetails = ({ policyholder, allowEdit }) => {

  const [policyholderEdit, setPolicyholderEdit] = useState(false);

  const handlePolicyholderEditSubmit = (values) => {
    // let data = {
    //   dealer_id: dealer.id,
    //   cover_level_id: values.cover_level.id,
    //   duration: values.duration.name,
    //   claim_limit: values.claim_limit.value,
    //   labour_rate: values.labour_rate.title,
    //   start_date: moment(values.start_date).format('YYYY-MM-DD'),
    //   excess: values.excess.title,
    //   excess_type: 'Percentage',
    //   sundries: values.sundries.map(s => s.title),
    //   net: values.policy_price / 1.2,
    //   vat: (values.policy_price / 1.2) * 0.2,

    //   ...vehicle,
    //   ...policyholder.,

    //   action: 'save_for_later',
    // };
    // api.patch(`/external-sales/policies/${id}`, data)
    //   .then(res => {
    //     NotificationManager.success('Woohoo! The policy has been updated');
    //     setPolicyEdit(false);
    //     refreshPolicy();
    //   })
    //   .catch(err => {
    //     NotificationManager.error('Oops! There was a problem updating the policy information.');
    //     console.log(err);
    //   });
  }

  return (
    <div className="row">
      {!policyholderEdit &&
      <div className="col-12 pr-5">
        <div className="row mb-4">
          <div className="col d-flex align-items-center">
            <h6 className="text-uppercase">Policyholder details</h6>
          </div>
          {/* the ability to edit the policyholder details hasn't been fully developed yet so disable this feature for now */ }
          {/* <div className="col d-flex align-items-center justify-content-end">
          {hasPermission('dealer_portal.policies') && allowEdit &&
            <button
              className="btn btn-light btn-sm"
              onClick={() => setPolicyholderEdit(true)}
              style={{ width: 34, height: 34 }}
            >
              <i className="wf-icon-edit mr-0" />
            </button>
          }
          </div> */}
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Driver</span>
            <p className="font-md">{echo(policyholder.first_name)} {echo(policyholder.last_name, '')}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <span className="label">Address</span>
            <p className="font-md"> {[
              policyholder.address_1,
              policyholder.address_2,
              policyholder.address_3,
              policyholder.city,
              policyholder.country,
              policyholder.postcode,
            ]
              .filter(Boolean)
              .join(', ')}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <span className="label">Telephone (mobile)</span>
            <p className="font-md">{echo(policyholder.tel1)}</p>
          </div>
          <div className="col-4">
            <span className="label">Telephone (home)</span>
            <p className="font-md">{echo(policyholder.tel2)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <span className="label">Email</span>
            <p className="font-md">{echo(policyholder.email)}</p>
          </div>
        </div>
      </div>
      }

      <SlideToggle isVisible={policyholderEdit}>
        <PolicyholderDetailsEdit
          policyholder={policyholder}
          onCancel={() => {
            setPolicyholderEdit(false)
          }}
          initialValues={{
            title: {name: policyholder.title, id: policyholder.title},
            first_name: policyholder.first_name,
            last_name: policyholder.last_name,
            address_1: policyholder.address_1,
            address_2: policyholder.address_2,
            address_3: policyholder.address_3,
            city: policyholder.city,
            county: policyholder.county,
            postcode: policyholder.postcode,
            tel1: policyholder.tel1,
            tel2: policyholder.tel2,
            email: policyholder.email,
          }}
          onSubmit={handlePolicyholderEditSubmit}
        />
      </SlideToggle>

    </div>
  )
}

PolicyholderDetails.propTypes = {
  policyholder: PropTypes.object.isRequired,
  allowEdit: PropTypes.bool,
}

export default PolicyholderDetails
