import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../../../../utils/api'
import { useParams } from 'react-router-dom'
import { history } from '../../../../../utils/history'
import PolicyholderDetails from './PolicyholderDetails'
import VehicleDetails from '../../../DealerPortal/Policies/Show/VehicleDetails';
import PolicyDetails from './PolicyDetails'
import DealerDetails from './DealerDetails'
import { confirmAlert } from 'react-confirm-alert'
import { NotificationManager } from 'react-notifications'
import moment from "moment";
import AdminComments from './AdminComments';
import { hasPermission } from '../../../../../utils/auth';
import { connect } from 'react-redux';

const Policy = props => {
  const { loggedInPerson } = props
  const params = useParams()
  const [policy, setPolicy] = useState(null)
  const [sendingPolicyDocumentUpdateEmail, setSendingPolicyDocumentUpdateEmail] = useState(false)
  const [forceRerender, setForceRerender] = useState(0)

  const fetchPolicy = () => {
    api
      .get('/claims/policies/' + params.id)
      .then((res) => {
        setPolicy(res.data)
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 404) {
          history.push('/404')
        }
      })
  }

  useEffect(() => {
    fetchPolicy();
  }, [])

  const cancelPolicy = () => {
    api
      .patch('/claims/policies/' + params.id + '/cancel')
      .then((res) => {
        NotificationManager.success('Woohoo! You cancelled a policy.')
        let updated = { ...policy, status: res.data.status }
        setPolicy(updated)
      })
      .catch((err) => {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again after refreshing the page'
        )
      })
  }

  const onCancel = () => {
    confirmAlert({
      title: '🧐 Are you sure?',
      message: `The policy will be cancelled forever. This action cannot be undone.`,
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose} />
              <h3>{title}</h3>
              <p>{message}</p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  cancelPolicy()
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const purchasedFrom = policy && (policy.purchased_from === 'Dealer' || policy.purchased_from === 'Public') && policy.purchased_from

  const sendPolicyDocumentUpdateEmail = async () => {
    setSendingPolicyDocumentUpdateEmail(true);

    try {
      await api.post(`/direct-sales/policies/${policy.id}/email`, { type: 'policy-document-update' });
      const message = {
        message: `The updated policy document was emailed on the ${moment().format('Do')} of ${moment().format('MMM')} ${moment().format('YYYY')} at ${moment().format('HH:mm')}`,
        message_type: 'Email',
        person_id: loggedInPerson.person_id,
      };
      await createPolicyholderMessage(message);
      await createPolicyMessage(message);
      setForceRerender(forceRerender + 1);
      NotificationManager.success('Woohoo! You sent policy update document email.')
    } catch (err) {
      NotificationManager.error(
        'Oops! Something went wrong. Please try again after refreshing the page'
      )
    }

    setSendingPolicyDocumentUpdateEmail(false);
  }

  const isPolicyActive = () => {
    return policy && (policy.status === 'Approved') &&
      moment(policy.start_date).isSameOrBefore(moment()) &&
      moment(policy.end_date).isAfter(moment());
  }

  const isPolicyApproved = () => {
    return policy && (policy.status === 'Approved')
  }

  const createPolicyholderMessage = async (message) => {
    try {
      await api.post(`/direct-sales/policyholders/${policy.policyholder.id}/timeline/message`, message);
    } catch (err) {}
  }

  const createPolicyMessage = async (message) => {
    try {
      await api.post(`/direct-sales/policies/${policy.id}/timeline/message`, message);
    } catch (err) {}
  }

  return (
    policy && (
      <div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/claims/policies" className="open active">
              Policies
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">View policy</span>
          </li>
        </ol>

        <div className="container">
          <header className="py-4 d-flex align-items-center justify-content-between border-bottom">
            <div>
              <div className="d-flex">
                <h3 style={{ fontSize: 36 }}>
                  Policy,{' '}
                  <span className="font-xl mt-2- fw-400 text-gray-700">
                    {policy.ref}
                  </span>
                  <span className="badge badge-primary badge-pill font-sm py-2 px-3 ml-3">
                    {policy.status === 'Cancelled' ? 'Cancelled' : purchasedFrom}
                  </span>
                </h3>
              </div>
            </div>

            <div className="d-flex" style={{ gap: 20 }}>
              {isPolicyApproved() && (
                <button
                  className="btn btn-outline-info btn-md"
                  disabled={sendingPolicyDocumentUpdateEmail}
                  onClick={sendPolicyDocumentUpdateEmail}
                >
                  Policy document update email
                </button>
              )}
              
              {hasPermission('claims.policies.update') && policy.status !== 'Cancelled' && moment(policy.end_date).isAfter(moment()) && (
                <button
                  className="btn btn-outline-danger btn-md"
                  style={{ marginRight: 20 }}
                  onClick={onCancel}
                >
                  Cancel
                </button>
              )}
            </div>
          </header>

          <div className="row">
            <div className="col-8 border-right mb-5">
              <DealerDetails dealer={policy.dealer} />
              <VehicleDetails
                vehicle={policy.vehicle}
                allowEdit={hasPermission('claims.policies.update')}
                endpoint={`/claims/policies/${policy.id}/vehicle`}
                refresh={fetchPolicy}
                coverLevel={policy.cover_level}
              />
              <PolicyholderDetails policyholder={policy.policyholder} />
              <div className="pt-1">
                <hr className="mt-4 mb-2" style={{ height: '20px' }} />
              </div>
              <AdminComments key={forceRerender} />
            </div>
            <div className="col-4">
              <PolicyDetails policy={policy} />
            </div>
          </div>
        </div>
      </div>
    )
  )
}

Policy.propTypes = {}

export default connect(
  (state) => ({
    loggedInPerson: state.authentication.user,
  }),
  null
)(Policy);

