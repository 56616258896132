import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form, reduxForm } from 'redux-form'
import CustomSelect from '../../../../../ui/CustomSelect'
import renderField from '../../../../../../utils/renderField'

const PricingForm = (props) => {
  const {
    handleSubmit,
    onSubmit,
    valid,
    onCancel,
    priceBands,
  } = props

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <div className="row">
        <div className="col">
          <Field
            name="base_price_band_id"
            component={CustomSelect}
            label="Choose price band"
            options={priceBands}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Field
            type="checkbox"
            id={'disableSpecialVehiclePricing'}
            label="Turn off special vehicle pricing"
            component={renderField}
            name={`disable_special_vehicle_pricing`}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Field
            type="checkbox"
            id={'allowStaffViewPricing'}
            label="Allow dealer staff to view pricing"
            component={renderField}
            name={`allow_staff_view_pricing`}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group mb-0">
            <button
              className="btn btn-secondary"
              type="submit"
              disabled={!valid}
            >
              Save changes
            </button>
            <button
              type="button"
              className="btn btn-outline btn-form-cancel"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Form>
  )
}

PricingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  priceBands: PropTypes.array.isRequired,
}

export default reduxForm({
  form: 'dealer/pricing-form',
})(PricingForm)
