import React, { Fragment } from 'react'
import PageHeader from '../../../ui/PageHeader'
import { Table } from '../../../ui/Table'
import { Link } from 'react-router-dom'
import {NotificationManager} from "react-notifications";
import { uniqueId, get } from 'lodash'
import { sortCaret } from '../../../../utils/misc'
import api from '../../../../utils/api';
import { hasPermission } from '../../../../utils/auth';
import moment from "moment";

class Policies extends React.Component {
  state = {
    filterGroups: [
      {
        name        : 'status',
        allowOnlyOne: true,
        filters     : [
          {id: uniqueId(), name:'Draft', value: 'Draft'},
          {id: uniqueId(), name:'Awaiting Approval', value: 'Awaiting Approval'},
          {id: uniqueId(), name:'Approved', value: 'Approved'},
          {id: uniqueId(), name:'Rejected', value: 'Rejected'},
          {id: uniqueId(), name:'Cancelled', value: 'Cancelled'},
        ],
      },
    ],
    query       : '',
    page        : '',
    desc        : '',
    sortField   : null,
    limit       :  10,
    status      : '',
    policies    : null,
    searching: false,
    selected    : [],
    policyHighlights: [],
  }

  fetchData = url => {
    api.get(`/external-sales/policies${url ? url : ''}`)
        .then(res => {
          const policies = res.data.data.map(policy => {
            policy.highlight = {
              success: get(this.state, 'policyHighlights.ok', []).includes(policy.id),
              error: get(this.state, 'policyHighlights.error', []).includes(policy.id),
            }
            return policy;
          })
          this.setState({policies: {...res.data, policies}, searching: false })

        })
        .catch(err => {
          if (err) {
            console.log(err)
            NotificationManager.error('Oops! Something went wrong. Please try again after refreshing.')
          }
        })
  }

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    this.setState({page, desc: (sortOrder === 'desc'), limit: sizePerPage, sortField}, this.buildUrl);
  }

  handleSearch = (query, filters) => {
    let status = ''

    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]

      if (filter.group_name === 'status') status = filter.name
    }
    this.setState(
        {
          query,
          status,
        },
        this.buildUrl,
    )
  }

  /**
   * Builds URL and to fetch policies
   */
  buildUrl = () => {
    const { query, page, desc, limit, status } = this.state

    let url = `?query=${query}&page=${page}&desc=${desc}&limit=${limit}`

    if (status !== '') url = `${url}&status=${status}`

    this.setState({ searching: true })
    this.fetchData(url)
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {

    const options = {
      showOptions: false,
      columns    : [
        {
          dataField  : 'created_at',
          text       : 'Date added',
          headerStyle: {
            width: 150,
          },
          formatter  : (cell, row) => moment(row.created_at).format('DD/MM/YYYY'),
          sort       : true,
          sortCaret,
        },
        {
          dataField  : 'ref',
          text       : 'Policy ID',
          headerStyle: {
            width: 150,
          },
          formatter  : (cell, row) => <Link to={`/dealer-portal/policies/${row.id}/policy`}>{row.ref}</Link>,
          sort       : true,
          sortCaret,
        },
        {
          dataField: 'cover_level.name',
          text     : 'Cover level',
        },
        {
          dataField: 'policyholder_name',
          text     : 'Policyholder name',
          formatter: (cell, row) => `${row.policyholder.title} ${row.policyholder.first_name} ${row.policyholder.last_name}`,
        },
        {
          dataField: 'vehicle.vrm',
          text     : 'Vehicle reg',
        },
        {
          dataField: 'duration',
          text     : 'Duration',
        },
        {
          dataField: 'status',
          text     : 'Status',
          sort     : true,
          sortCaret,
        },
        {
          dataField  : '',
          text       : 'Actions',
          headerStyle: {
            width: 80,
          },
          formatter  : (cell, row) => (
            <Link
                data-tip={row.status === 'Draft' || hasPermission('dealer_portal.policies.set_sold_by') ? 'Edit policy' : 'View policy'}
                data-place="top"
                to={`/dealer-portal/policies/${row.id}/policy`}
                className="table-action-btn table-action-btn--edit"
                size="sm"
            >
              <i className={row.status === 'Draft' || hasPermission('dealer_portal.policies.set_sold_by') ? 'wf-icon-edit' : 'icon-magnifier'}/>
            </Link>
          ),
        },
      ],
    }

    const tableActions = []

    return (
        <Fragment>
          <br/>
          <div className="container-fluid animated fadeIn">
            <PageHeader
                title="Policies"
                description={
                  'A list of all your created policies with an overview of their status, i.e., if they have been approved or rejected by Warranty First.'
                }
                buttonLink={'/dealer-portal/policies/create'}
                buttonText="Add a policy"
                buttonIcon="d-block wf-icon-add"
            />
            <div className="es-policy-table">
            {this.state.policies && (
                <Table
                    resource={this.state.policies}
                    selected={this.state.selected}
                    searching={this.state.searching}
                    showOptions={options.showOptions}
                    omnibox={{ groups: this.state.filterGroups }}
                    handleTableChange={this.handleTableChange}
                    columns={options.columns}
                    hasTableSearch={true}
                    onSearch={this.handleSearch}
                    searchPlaceholder={'Search for a policy...'}
                    tableActions={tableActions}
                    minSelection={0}
                    rowClasses={(row, idx) => {
                      if (get(row, 'highlight.success', false)) return 'policy-approval-success';
                      if (get(row, 'highlight.error', false)) return 'policy-approval-error';
                      return '';
                    }}
                />
            )}
            </div>
          </div>
        </Fragment>
    )
  }
}

export default Policies
