import React, { Component, Fragment } from 'react'
import CreateCoverLevelForm from './CreateCoverLevelForm'
import { connect } from 'react-redux'
import { isValid, getFormValues, getFormSubmitErrors } from 'redux-form'
import {
  addDealerCoverLevel,
  resetForm,
} from '../../../actions/cover-levels.actions'
import { history } from '../../../utils/history';
import { Link } from 'react-router-dom'

class CreateCoverLevelPage extends Component {
  cancel = e => {
    e.preventDefault()
    this.props.resetForm('dealer-cover-levels/create')
    history.push('/admin/dealer-cover-levels')
  }

  render() {
    return (
      <Fragment>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin/dealer-cover-levels" className="open active">
              Dealer cover levels
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">Add a cover level</span>
          </li>
        </ol>

        <div className="container-fluid animated fadeIn">
          <div className="mw-37-5rem mx-auto">
            <CreateCoverLevelForm
              onSubmit={this.props.addDealerCoverLevel}
              valid={this.props.valid}
              cancel={this.cancel}
              submitErrors={this.props.submitErrors || {}}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default connect(
  state => ({
    valid: isValid('dealer-cover-levels/create')(state),
    values: getFormValues('dealer-cover-levels/create')(state),
    submitErrors: getFormSubmitErrors('dealer-cover-levels/create')(state),
  }),
  { addDealerCoverLevel, resetForm },
)(CreateCoverLevelPage)
