import React from 'react'
import PrivateRoute from "../components/PrivateRoute";

import Index from "../components/pages/Reports/ReportIndex";


const ReportRoutes = () => {
  return <>
    <PrivateRoute path={`/reports/`} exact component={Index} />
  </>
}

export default ReportRoutes