import React from 'react'
import { Form, reduxForm } from 'redux-form'
import { Field } from 'redux-form'
import renderDatePicker from '../../../ui/renderDatePicker'

const DatePickers = ({ handleSubmit }) => {
  return (
    <Form
      onSubmit={handleSubmit}
      className={'form-inline d-flex align-items-end pull-right'}
      >
      <span className="date-label">from</span>
      <Field
        type="date"
        name="date_from"
        label=""
        className="form-control form-control-sm from-date-field"
        inputValueFormat="YYYY-MM-DD"
        placeholderText="DD/MM/YYYY"
        dateFormat="dd/MM/yyyy"
        fixedHeight
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        yearDropdownItemNumber={3}
        id="fromDate"
        component={renderDatePicker}
        icon={false}
      />
      <span className="date-label">to</span>
      <Field
        type="date"
        name="date_to"
        label=""
        className="form-control form-control-sm to-date-field"
        inputValueFormat="YYYY-MM-DD"
        placeholderText="DD/MM/YYYY"
        dateFormat="dd/MM/yyyy"
        fixedHeight
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        yearDropdownItemNumber={3}
        id="toDate"
        component={renderDatePicker}
        icon={false}
      />
      <button type="submit" className={'btn btn-secondary btn-sm font-sm'}>
        Apply
      </button>
    </Form>
  )
}

const validate = (values) => {
  let errors = {}
  if (!values.date_from) {
    errors.date_from = 'From Date is required'
  }
  if (!values.date_to) {
    errors.date_to = 'To Date is required'
  }

  return errors
}

export default reduxForm({
  form: 'rangeForm',
  validate,
})(DatePickers)
