import React from 'react'
import PropTypes from 'prop-types'
import { Draggable } from 'react-beautiful-dnd'
import Avatar from './Avatar'
import { history } from '../../utils/history'
import { has, get } from 'lodash'

export const PipelineCardDirectSales = ({ card }) => {
    let contact;
    if (card && has(card, 'dealer.primary_contact')) {
        contact = get(card, 'dealer.primary_contact.first_name', '') + ' ' + get(card, 'dealer.primary_contact.last_name', '')
    }
  return (
    card && (
      <Draggable draggableId={card.id} index={card.rank}>
        {provided => (
          <div
            className="card"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={() => history.push(`/external-sales/dealers/${card.dealer.id}/timeline`)}
          >
            <header className="d-flex justify-content-between">
                <div className="header__content">
                <h3>{get(card, 'dealer.name')}</h3>
                  <div className="font-sm gray-700 mt-1">{get(card, 'dealer.territory.name', '\u00A0')}</div>
              </div>
              <div className="header__actions">
                <span className="icon-note"/>
              </div>
            </header>

              <div className="card__content d-flex mt-2">
              <div className="d-flex flex-grow-1 align-items-center justify-content-between">
                  <span className="font-sm gray-700">{contact}</span>
                  {card.assignee && <Avatar className="float-right" user={card.assignee} tooltip />}
              </div>
            </div>

          </div>
        )}
      </Draggable>
    )
  )
}

PipelineCardDirectSales.propTypes = {
  card: PropTypes.object.isRequired
}

export default PipelineCardDirectSales