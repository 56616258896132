import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const Ellipsis = ({text}) => {

  const [read, setRead] = React.useState(false)

  return (
    text && <>
      {
        text.length > 140 ?
          <>
            <div className="font-md">{read ? text : `${text.substring(0, 140)}...`}</div>
            {!read && <Link to="#" className="text-blue font-sm d-block mt-2" onClick={(e) => {
              e.preventDefault();
              setRead(true)
            }}>Read more...</Link>}
            {read && <Link to="#" className="text-blue font-sm d-block mt-2" onClick={(e) => {
              e.preventDefault();
              setRead(false)
            }}>Read less</Link>}
          </>
          :
          <div className="font-md">{text}</div>
      }
    </>
  )
}

Ellipsis.propTypes = {
  text : PropTypes.string.isRequired,
}

export default Ellipsis
