import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { AppHeader } from '@coreui/react'
import CodeForm from './CodeForm'
import { connect } from 'react-redux'
import { getFormValues, isValid } from 'redux-form'
import api from '../../../utils/api'

class ForgotPasswordPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formError: '',
      linkSent: false,
    }
  }

  handleSubmit = values => {
    api.post(`/auth/forgot`, {
      email: values.email,
    }).then(res => {
      this.setState({
        formError: null,
        linkSent: true,
      });
    })
    .catch(err => {
      this.setState({
        formError: 'An unexpected error occured. Please try again.',
        linkSent: false
      })
    })
  }

  render() {
    return (
      <div>
        <AppHeader fixed>
          <Link to={'/'} className="navbar-brand"/>
        </AppHeader>

        <div className="vh-100 container-fluid">
          <div className="row h-100 justify-content-center">
            <div className="h-100 d-flex align-items-center justify-content-center col">
              <div className="mt-5 mw-37-5rem col-lg-10 col-xl-8">

                <CodeForm onSubmit={this.handleSubmit} submitError={this.state.formError} linkSent={this.state.linkSent} />

              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    valid: isValid('auth/forgot')(state),
    values: getFormValues('forgot')(state),
  }),
  {},
)(ForgotPasswordPage)
