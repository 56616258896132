import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreateDealerForm from './CreateDealerForm'
import { Link } from 'react-router-dom'
import api from '../../../../../utils/api'
import moment from 'moment'
import { history } from '../../../../../utils/history'
import { NotificationManager } from 'react-notifications'
import { has } from 'lodash'
import { formValueSelector } from 'redux-form'

class CreateDealer extends Component {
  onSubmit = values => {
    values.base_price_band_id = values.price_band.id;
    delete values.price_band

    values = {...values, ...values.address}
    delete values.address

    if (has(values, 'garage_id.id')) {
      values.garage_id = values.garage_id.id
    }

    values.active_start_date = moment(values.active_start_date).format('YYYY-MM-DD')

    if (has(values, 'active_end_date')) {
        values.active_end_date = moment(values.active_start_date).format('YYYY-MM-DD')
    }

    values.invoice_cycle = values.invoice_cycle.name

    api.post('/external-sales/dealers', values)
      .then(res => {
        history.push('/external-sales/dealers')
        NotificationManager.success('Woohoo! You added a new dealer.')
      })
      .catch(err => {
        if (err) {
          console.log(err)
        }
      })
  }

  fetchGarages = query =>
    new Promise((resolve, reject) => {
      api
        .get(`misc/garages?query=${query ? query : ''}`)
        .then(res => {
          resolve(res.data.data)
        })
        .catch(err => {
          if (err) {
            reject([])
          }
        })
    })


  fetchPricebands = query =>
    new Promise((resolve, reject) => {
      api
        .get(`misc/price-bands?target=Dealer&query=${query ? query : ''}`)
        .then(res => {
          resolve(res.data.data)
        })
        .catch(err => {
          if (err) {
            reject([])
          }
        })
    })

  cancel = e => {
    e.preventDefault()
    this.props.history.goBack()
  }

  render() {
    return (
      <>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/external-sales/dealers" className="open active">
              Dealers
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="active">Add dealer</span>
          </li>
        </ol>

        <CreateDealerForm onSubmit={this.onSubmit} cancel={this.cancel} fetchGarages={this.fetchGarages} fetchPricebands={this.fetchPricebands} addressFieldVisible={this.props.addressFieldVisible} postcode={this.props.postcode}/>
      </>
    )

  }
}

CreateDealer.propTypes = {}

const selector = formValueSelector('dealers/create')

export default connect(state  => {
  const addressFieldVisible = selector(state, 'addressFieldVisible') || false
  const postcode = selector(state, 'postcode') || ''

  return {
    addressFieldVisible,
    postcode
  }
}, {})(CreateDealer)