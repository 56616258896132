import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {getFormValues, reduxForm, reset} from 'redux-form';
import StepDealer from './StepDealer'
import StepVehicle from './StepVehicle'
import api from '../../../../../utils/api'
import { API_URL } from '../../../../../utils/constants'
import { NotificationManager } from 'react-notifications'
import StepPolicy from './StepPolicy'
import StepPolicyholder from './StepPolicyholder'
import { history } from '../../../../../utils/history'
import moment from "moment";
import { get } from 'lodash'

const CreatePolicy = ({dealer, vehicle, policy, policyholder, dispatch, ...props}) => {
  const [step, setStep] = useState(1)
  const [steps, setSteps] = useState(formSteps)
  const [lookupData, setLookupData] = useState()
  const [useManualInfo, setUseManualInfo] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState(null)
  const [lastStepVisited, setLastStepVisited] = useState(1)
  const [price, setPrice] = useState(null)

  const nextStep = () => {
    setStep(step + 1)
  }

  /**
   * Perform vehicle registration number lookup
   *
   * @param {String} vrm
   */
  const handleLookup = vrm => {
    if (!vrm) return false

    api
      .get(`${API_URL}/misc/vrm-lookup?vrm=${vrm}`)
      .then(res => {
        if (get(res, 'data.Model', false) === false) {
          setLookupData(null);
          setUseManualInfo(true);
          NotificationManager.error('Oops! We could not find information for this registration. Please enter the details manually.');
        } else {
          setLookupData(res.data)
        }
      })
      .catch(err => {
        // if (err && err.response.status  422) {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again.',
        )
        // }
      })
  }

  useEffect(() => {
    //If Manual Data entry, set vehicle info, else use lookupdata
    if (useManualInfo) {
      let vehicleInfo = get(vehicle, 'vehicle', {});
      if (vehicleInfo.Manufactured) {
        vehicleInfo.RegDate = moment(vehicleInfo.Manufactured).format('DD-MM-YYYY');
      }
      setVehicleInfo(vehicleInfo);
    } else {
      setVehicleInfo(lookupData);
    }
  }, [lookupData, useManualInfo, vehicle])

  useEffect(() => {
    setSteps(steps => [
      ...steps.map(item => {
        if (item.id === step) {
          item.current = true
        } else {
          item.current = false
        }
        return item
      }),
    ])
  }, [step])

  /**
   * Only allow the user to navigate
   * to the last step he visited.
   *
   * @param {Number} step
   */
  const handleGoBackTo = step => {
    if (lastStepVisited >= step.id) {
      setStep(step.id)
    }
  }

  const getDataForApi = (action) => {
    let data = {
      dealer_id: dealer.dealer.id,
      cover_level_id: policy.cover_level.id,
      duration: policy.duration.name, //This one requires the month suffix, so use the human name attribute
      claim_limit: policy.claim_limit.value,
      labour_rate: get(policy, 'labour_rate.title', null),
      start_date: moment(policy.policy_start_date).format('YYYY-MM-DD'),
      excess: get(policy, 'excess.title', null),
      excess_type: 'Percentage',
      sundries: get(policy, 'sundries', []).map(sundry => sundry.title),
      net: get(policy, 'net', 0),
      vat: get(policy, 'vat', 0),

      vrm: vehicle.vrm,
      vin: vehicleInfo.VIN,
      type: vehicleInfo.Type.value || vehicleInfo.Type,
      make: vehicleInfo.Make,
      model: vehicleInfo.Model,
      drive_type: vehicleInfo.DriveType,
      engine_size: vehicleInfo.EngineSize,
      transmission: vehicleInfo.Transmission,
      fuel_type: vehicleInfo.FuelType,
      reg_date: vehicleInfo.RegDate.split('-').reverse().join('-'),
      manual_data: useManualInfo,
      mileage: vehicle.current_mileage,
      retail_price: vehicle.vehicle_sale_price,

      company_name: policyholder.ph_company_name,
      title: policyholder.title.name,
      first_name: policyholder.ph_first_name,
      last_name: policyholder.ph_last_name,
      address_1: get(policyholder, 'address_1', get(policyholder, 'address.address_1', undefined)),
      address_2: get(policyholder, 'address_2', get(policyholder, 'address.address_2', undefined)),
      address_3: get(policyholder, 'address_3', get(policyholder, 'address.address_3', undefined)),
      city: get(policyholder, 'city', get(policyholder, 'address.city', undefined)),
      county: get(policyholder, 'county', get(policyholder, 'address.county', undefined)),
      postcode: get(policyholder, 'postcode', get(policyholder, 'address.postcode', undefined)),
      tel1: policyholder.ph_tel_mobile,
      tel2: policyholder.ph_tel_home,
      email: policyholder.ph_email,
      sold_by_id: policyholder.sold_by ? policyholder.sold_by.id : null,
      action,
    };

    //If the data has been looked up via postcode, insert these values here.
    data = {...data, ...policyholder.address}

    return data;
  }

  const handleSubmit = () => {
    api.post('/external-sales/policies/', getDataForApi('send_for_approval'))
      .then((res) => {
        NotificationManager.success('Woohoo! You created a policy.')
        //Reset Each Form
        //When you reset these forms, all the props disappear, so you must set defaults in the form thats live when you submit.
        //Destroy on unmount is set to false to ensure we keep the data in the other steps.
        dispatch(reset('create-policy-policy'))
        dispatch(reset('create-policy-dealer'))
        dispatch(reset('create-policy-policyholder'))
        dispatch(reset('create-policy-vehicle'))
        history.push('/external-sales/policies')
      })
      .catch(() => {
        NotificationManager.error('Oops! There was a problem saving this policy.')
      })
  }

  useEffect(() => {
    if (
      vehicleInfo && get(vehicle, 'current_mileage', false) && get(vehicle, 'vehicle_sale_price', false) && //Vehicle Info is set
      dealer && dealer.dealer && //Dealer Info is set
      get(policy, 'cover_level.id', false) && get(policy, 'duration.name', false) && get(policy, 'claim_limit.value', false) //Policy Info is set
    ) {
      api.get(`/external-sales/policies/create/${dealer.dealer.id}/price`, {
        params: {
          legacy: false,
          type: vehicleInfo.Type.value || vehicleInfo.Type,
          make: vehicleInfo.Make,
          model: vehicleInfo.Model,
          drive_type: vehicleInfo.DriveType,
          engine_size: vehicleInfo.EngineSize,
          reg_date: vehicleInfo.RegDate.split('-').reverse().join('-'),
          manual_data: useManualInfo,
          mileage: vehicle.current_mileage,
          sale_price: vehicle.vehicle_sale_price,
          cover_level_id: policy.cover_level.id,
          duration: policy.duration.name,
          claim_limit: policy.claim_limit.value,
          excess: get(policy, 'excess.title', null),
          labour_rate: get(policy, 'labour_rate.title', null),
          sundries: get(policy, 'sundries', []).map(sundry => sundry.title),
        }
      })
        .then(res => {
          setPrice(res.data.price)
        })
    } else {
      setPrice(null);
    }
  }, [dealer, vehicle, vehicleInfo, policy])

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/external-sales/policies" className="open active">
            Policies
          </Link>
        </li>
        <li className="breadcrumb-item">
          <span className="active">Add policy</span>
        </li>
      </ol>

      <h2 className="text-center mt-5 mb-5">Add a policy</h2>

      {steps && (
        <div className="px-5 bg-grey text-text-center d-flex justify-content-center py-3 form-steps">
          {steps.map((step, i) => (
            <h5
              onClick={() => handleGoBackTo(step)}
              className={`form-step fw-800 text-capitalize
        ${step.current ? 'text-primary current' : ''}
        `}
              key={i}
            >
              {step.id}. {step.title}
            </h5>
          ))}
        </div>
      )}
      <div className="container-fluid mt-5">
        {step === 1 && (
          <StepDealer
            onSubmit={() => {
              nextStep()
              setLastStepVisited(2)
            }}
          />
        )}
        {step === 2 && (
          <StepVehicle
            handleLookup={handleLookup}
            lookupData={lookupData}
            setLookupData={setLookupData}
            manualInfo={useManualInfo}
            setManualInfo={setUseManualInfo}
            onSubmit={() => {
              nextStep()
              setLastStepVisited(3)
            }}
            vehicleInfo={vehicleInfo}
          />
        )}
        {step === 3 &&
          <StepPolicy
            onSubmit={() => {
              nextStep()
              setLastStepVisited(4)
            }}
            vehicleData={vehicleInfo}
            price={price}
          />}
        {step === 4 && (
          <StepPolicyholder
            price={price}
            soldByUsers={dealer && dealer.dealer ? dealer.dealer.contacts : []}
            onSubmit={handleSubmit}
            goStep={setStep} />
        )}
      </div>
    </div>
  )
}

const formSteps = [
  {
    id: 1,
    title: 'Dealer',
    current: true,
  },
  {
    id: 2,
    title: 'Vehicle',
    current: false,
  },
  {
    id: 3,
    title: 'Policy',
    current: false,
  },

  {
    id: 4,
    title: 'Policyholder',
    current: false,
  },
]

CreatePolicy.propTypes = {}

export default connect((state) => ({
  dealer: getFormValues('create-policy-dealer')(state),
  vehicle: getFormValues('create-policy-vehicle')(state),
  policy: getFormValues('create-policy-policy')(state),
  policyholder: getFormValues('create-policy-policyholder')(state)
}))(
  reduxForm({
    form: 'create-policy',
    destroyOnUnmount: false,
  })(CreatePolicy),
)
