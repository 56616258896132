import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { history } from '../../../../../utils/history';
import CustomAsyncSelect from "../../../../ui/CustomAsyncSelect";
import api from "../../../../../utils/api";
import { NotificationManager } from 'react-notifications';
import pDebounce from "../../../../../utils/pDebounce";

const StepDealer = props => {
  const { handleSubmit, onSubmit, valid, dealer } = props

  const fetchDealers = async (query) => {
    try {
      const res = await api.get(`/misc/dealers`, {params: {
          query, limit: 100,
          is_prospect: false,
          active_only: true,
        }});
      return res.data.data;
    } catch {
      NotificationManager.error('Oops! Something went wrong retrieving dealer information.')
    }
  }

  const loadDealers = pDebounce(q => (fetchDealers(q)), 1000)

  return (
    <div className="mw-37-5rem mx-auto pb-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h6 className="mt-4 mb-5 text-uppercase">Dealer details</h6>

        <Field
          component={CustomAsyncSelect}
          label="Which dealer is this policy for? *"
          name="dealer"
          placeholder="Search dealers..."
          isClearable={false}
          loadOptions={loadDealers}
          defaultOptions={false}
          getOptionLabel={(d) => (`${d.name} - ${d.address_1}, ${d.city}, ${d.postcode}`)}
          getOptionValue={(d) => (d.id)}
        />


        {dealer && (
          <>
            <div className="row">
              <div className="col">
                <span className="label">Address</span>
                <p>{dealer.name}<br />
                  {[
                    dealer.address_1,
                    dealer.address_2,
                    dealer.address_3,
                    dealer.city,
                    dealer.country,
                    dealer.postcode,
                  ]
                    .filter(Boolean)
                    .join(', ')}
                </p>

                <span className="label">Telephone (office)</span>
                <p>{dealer.tel_office}</p>

                <span className="label">Email address</span>
                <p>{dealer.email}</p>
              </div>
            </div>

          </>
        )}

        <div className="row">
          <div className="col">
            <p>* Mandatory fields</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!valid || !dealer}
              >
                Next step
              </button>
              <button
                type="button"
                className="btn btn-outline btn-form-cancel"
                onClick={() => history.push('/external-sales/policies')}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

StepDealer.propTypes = {}

const selector = formValueSelector('create-policy-dealer')

export default connect(
  state => ({
    dealer: selector(state, 'dealer'),
  }),
  null,
)(
  reduxForm({
    form: 'create-policy-dealer',
    destroyOnUnmount: false,
  })(StepDealer),
)
