import React from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { history } from '../../../../../utils/history'
import { connect } from 'react-redux'
import CustomAsyncSelect from '../../../../ui/CustomAsyncSelect'
import api from '../../../../../utils/api'
import {
  echo,
  formatDate,
  formatExcess,
  money,
  prettyNumber,
} from '../../../../../utils/misc'
import { Link } from 'react-router-dom'
import moment from 'moment'
import pDebounce from "../../../../../utils/pDebounce"

const StepOne = ({
  handleSubmit,
  onSubmit,
  valid,
  policy,
  policy_id,
  setPolicy,
}) => {
  /**
   * Fetch all policies
   *
   * @param query
   * @returns {Promise<unknown>}
   */
  const fetchPolicies = async (query) => {
    try {
      const res = await api.get(
        `/claims/policies?status=Approved&query=${query ? query : ''}&limit=1000`
      )
      return res.data.data
    } catch (error) {
      return []
    }
  }

  const loadOptions = pDebounce((val) => {
    return fetchPolicies(val)
  }, 1000)

  /**
   * Fetch policy data when user selects policy from dropdown
   *
   * @param policy
   * @returns {boolean}
   */
  const selectPolicy = (policy) => {
    if (!policy) {
      setPolicy(null)
      return false
    }

    api
      .get(`/claims/policies/${policy.id}`)
      .then((res) => {
        setPolicy(res.data)
      })
      .catch((err) => {
        if (err) {
          setPolicy(null)
        }
      })
  }

  return (
    <div className="mw-37-5rem mx-auto pb-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h6 className="mt-4 mb-5 text-uppercase">Policy details</h6>

        <Field
          component={CustomAsyncSelect}
          label="Select a policy (ID or registration)"
          name="policy_id"
          placeholder={'Search by policy ID or registration'}
          isClearable
          cacheOptions
          loadOptions={loadOptions}
          onChange={(option) => {
            if (!option) {
              setPolicy(null)
            } else {
              selectPolicy(option)
            }
          }}
          defaultOptions={false}
          value={policy_id}
          getOptionLabel={(option) =>
            `${option.ref} - ${option.vehicle.vrm} - ${option.policyholder.first_name} ${option.policyholder.last_name}`
          }
          getOptionValue={(option) => option.id}
        />

        {policy && (
          <>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <span className="label">Email</span>
                  <div className="font-md">
                    {echo(policy.policyholder.email)}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="form-group mb-0">
                  <span className="label">Telephone (home)</span>
                  <div className="font-md">{echo(policy.policyholder.tel1)}</div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group mb-0">
                  <span className="label">Telephone (mobile)</span>
                  <div className="font-md">{echo(policy.policyholder.tel2)}</div>
                </div>
              </div>
            </div>

            <hr style={{ margin: '30px 0' }} />

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <span className="label">Cover level</span>
                  {policy.cover_level && (
                    <div className="font-md">
                      {echo(policy.cover_level.name)}, {echo(policy.duration)}
                    </div>
                  )}
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <span className="label">Start date</span>
                  <div className="font-md">{formatDate(policy.start_date)}</div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <span className="label">End date</span>
                  <div className="font-md">{formatDate(policy.end_date)}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <span className="label">Claim limit</span>
                  <div className="font-md">
                    {money.format(policy.claim_limit)}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <span className="label">Labour rate</span>
                  <div className="font-md">
                    {money.format(policy.labour_rate)}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <span className="label">Excess</span>
                  <div className="font-md">
                    {formatExcess(policy.excess_type, policy.excess)}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <span className="label">Vehicle reg.</span>
                  <div className="font-md">{echo(policy.vehicle.vrm)}</div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <span className="label">Type</span>
                  <div className="font-md">{echo(policy.vehicle.type)}</div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <span className="label">Make and model</span>
                  <div className="font-md">
                    {echo(policy.vehicle.make)} {echo(policy.vehicle.model)}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <span className="label">Starting mileage</span>
                  <div className="font-md">
                    {policy.vehicle.mileage
                      ? prettyNumber(policy.vehicle.mileage)
                      : '-'}{' '}
                    miles
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <span className="label">Purchased from</span>
                  <div className="font-md">{policy.purchased_from}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <span className="label">Invoice</span>
                  <div className="font-md">{echo(policy.invoice_status)}</div>
                </div>
              </div>
            </div>

            <hr style={{ margin: '20px 0 30px 0' }} />

            <div className="row">
              <div className="col">
                <h6 className="text-uppercase d-inline-block mt-2 mb-4">
                  Claims history
                </h6>

                <table className="table table-striped table-bordered d-table mt-3">
                  <thead>
                    <tr>
                      <th>Ref.</th>
                      <th>Fault date</th>
                      <th>Gross</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {policy.claims &&
                      policy.claims.length > 0 ?
                      policy.claims.map((claim, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <Link to={`/claims/claims/${claim.id}`}>
                                {claim.ref}
                              </Link>
                            </td>
                            <td>
                              {moment(claim.fault_date).format('DD-MM-YYYY')}
                            </td>
                            <td>{money.format(claim.gross)}</td>
                            <td>{claim.status}</td>
                          </tr>
                        )
                      }): <tr>
                        <td colSpan={4} className="text-center">No claims history.</td>
                      </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!valid || !policy}
              >
                Next step
              </button>

              <button
                type="button"
                className="btn btn-outline btn-form-cancel"
                onClick={() => history.push('/external-sales/policies')}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

StepOne.propTypes = {}

const selector = formValueSelector('claims/create')

export default connect(
  (state) => ({
    policy_id: selector(state, 'policy_id'),
  }),
  null
)(
  reduxForm({
    form: 'claims/create',
    destroyOnUnmount: false,
  })(StepOne)
)
