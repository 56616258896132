import React, { Fragment } from 'react'
import {NotificationManager} from "react-notifications";
import api from '../../../../../utils/api';
import PageHeader from '../../../../ui/PageHeader';
import { Mandate } from '../../../DirectDebit/Mandate';
import { NewInstruction } from '../../../DirectDebit/NewInstruction';

class DirectDebit extends React.Component {

  state = {
    wfSettings: null,
    mandate: null, // Existing mandate for the policyholder
  }

  fetchWFSettings = () => {
    api
      .get(`/misc/wf-settings`)
      .then((res) => {
        this.setState({wfSettings: {...res.data}})
      })
      .catch((err) => {
        NotificationManager.error('Oops! Something went wrong. Please try again after refreshing')
      })
  }

  fetchMandate = () => {
    api
      .get(`/policyholder-portal/billing/direct-debit/mandate`)
      .then((res) => {
        this.setState({mandate: {...res.data}})
      })
      .catch((err) => {
        NotificationManager.error('Oops! Something went wrong. Please try again after refreshing')
      })
  }

  componentDidMount = () => {
    this.fetchWFSettings();
    this.fetchMandate()
  }

  /**
    * Inserts a space before each word in a camel case string
    * i.e. converts 'NewInstruction' to 'New Instruction'
    *
    * @param {string} status
    * @returns string
    */
  echoStatus = (status) => {
    if (status) {
        return status.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
    }
    return '';
  }

  render() {

    return (
      <Fragment>
        <br/>
        <div className="container-fluid animated fadeIn">
          {this.state.mandate && this.state.mandate.sort_code ?
          <>
            <PageHeader
              title="Direct Debit"
              description="You have setup a direct debit mandate with us. Please see details below"
            />
            <Mandate
              wfSettings={this.state.wfSettings}
              mandate={this.state.mandate}
            />
          </>
          :
          <>
            <PageHeader
              title="Direct Debit"
              description="You can setup a direct debit mandate with us here"
            />
            <NewInstruction
              createURL={`/policyholder-portal/billing/direct-debit/mandate`}
              wfSettings={this.state.wfSettings}
            />
          </>
          }
        </div>
      </Fragment>
    )
  }
}

export default DirectDebit
