import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import moment from 'moment'
import renderField from '../../../../../utils/renderField'
import { required } from "redux-form-validators";

const EditMOTDateForm = ({
  handleSubmit,
  onSubmit,
  onCancel,
  valid,
  ...rest
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col">
          <Field
            type="date"
            name="mot_date"
            style={{ width: '100%', marginBottom: 0 }}
            label="Date of last MOT"
            max={moment().format('DD-MM-YYYY')}
            required
            className="form-control"
            id="motDate"
            component={renderField}
            showSuccessValidation={true}
            validate={[
              required({
                message: 'You must enter a date.',
              }),
            ]}
          />
        </div>
        <div className="col">
          <Field
            type="text"
            name="mot_mileage"
            label="Mileage"
            component={renderField}
            className="form-control"
            showSuccessValidation={true}
            // validate={[
            //   numericality({
            //     int: true,
            //     msg: 'Field must contain only numbers.',
            //   }),
            // ]}
            append={{
              direction: 'right',
              text: 'miles',
            }}
          />
        </div>
      </div>

      <div className="row">
       <div className="col-md-12 mt-4">
          <div className="form-group">
            <button
              className="btn btn-secondary"
              type="submit"
              disabled={!valid}
            >
              Save changes
            </button>
            <button
              type="button"
              className="btn btn-outline btn-form-cancel"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

const validate = (values) => {
  let errors = {};
  if (moment(values.mot_date).isAfter(moment())) {
    errors.mot_date = 'You cannot set a future MOT date';
  }
  return errors;
}

EditMOTDateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: 'claims/edit-mot-date',
  validate
})(EditMOTDateForm)
