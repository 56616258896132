import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Avatar from '../../../../ui/Avatar'
import { echo, formatDate, formatExcess, money } from '../../../../../utils/misc'
import {API_URL} from "../../../../../utils/constants";
import {hasPermission} from "../../../../../utils/auth";
import SlideToggle from "../../../../ui/SlideToggle";
import PolicyEditForm from "./PolicyEditForm";
import moment from "moment";
import api from '../../../../../utils/api'
import {NotificationManager} from 'react-notifications'

export const PolicyDetails = ({ policy, isQuote, refreshPolicy, disableEdit }) => {
  const { cover_level, sold_by, created_by } = policy

  const [policyEdit, setPolicyEdit] = useState(false);
  const [sendingPolicyRecovery, setSendingPolicyRecovery] = useState(false)

  const downloadUrl = () => {
    return API_URL + `/downloads/pdf/external-sales/policies/${policy.id}/certificate?token=` + JSON.parse(localStorage.getItem('wf-token'))
  }

  const downloadSummaryUrl = () => {
    return API_URL + `/downloads/pdf/external-sales/policies/${policy.id}/summary?token=` + JSON.parse(localStorage.getItem('wf-token'))
  }

  const handlePolicyEditSubmit = (values) => {
    let data = {
      dealer_id: policy.dealer.id,
      company_name: values.company_name,
      cover_level_id: values.cover_level.id,
      duration: values.duration.name,
      claim_limit: values.claim_limit.value,
      labour_rate: values.labour_rate.title,
      start_date: (values.start_date instanceof Date) ? moment(values.start_date).format('YYYY-MM-DD') : values.start_date.split('-').reverse().join('-'),
      excess: values.excess.title,
      excess_type: 'Percentage',
      sundries: values.sundries.map(s => s.title),
      net: (values.policy_price - (values.policy_price * 0.2)),
      vat: (values.policy_price * 0.2),
      sold_by_id: values.sold_by ? values.sold_by.id : null,

      ...policy.vehicle,
      ...policy.policyholder,

      action: 'update',
    };
    api.patch(`/external-sales/policies/${policy.id}`, data)
      .then(res => {
        NotificationManager.success('Woohoo! The policy has been updated');
        setPolicyEdit(false);
        refreshPolicy();
      })
      .catch(err => {
        NotificationManager.error('Oops! There was a problem updating the policy information.');
        console.log(err);
      });
  }

  const hasRecovery = () => {
    return policy && 
      policy.status === 'Approved' &&
      Array.isArray(policy.extras) && 
      policy.extras.some(extra => extra.title.includes('Recovery'));
  }

  const sendRecovery = async () => {
    setSendingPolicyRecovery(true);

    try {
      await api.post(`/external-sales/policies/${policy.id}/recovery`);
      NotificationManager.success('Woohoo! You resent policy recovery API.')
    } catch (err) {
      NotificationManager.error(
        'Oops! Something went wrong. Please try again after refreshing the page'
      )
    }

    setSendingPolicyRecovery(false);
  }

  return (
    policy && <div className="px-5">
      {!policyEdit && <>
      <div className="row">
        <div className="col d-flex align-items-center">
          <h6 className="mt-5 mb-4 text-uppercase">
            {isQuote ? 'Quote' : 'Policy'} details
          </h6>
        </div>
        <div className="col d-flex align-items-center justify-content-end">
          {hasPermission('external_sales.policies.update') && !isQuote && !disableEdit &&
          <><button
            className="btn btn-light btn-sm"
            onClick={() => setPolicyEdit(true)}
            style={{ width: 34, height: 34 }}
          >
            <i className="wf-icon-edit mr-0" />
          </button></>
          }
        </div>
      </div>

      <div className="row">
        <div className="col">
          <span className="label">Company</span>
          <p className="font-md">{echo(policy.company_name)}</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <span className="label">Cover level</span>
          {cover_level && <p className="font-md">{echo(cover_level.name)}, {echo(policy.duration)}</p>}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <span className="label">Start date</span>
          {policy.status === 'Awaiting Approval' && moment(policy.start_date) < moment().subtract(7, 'day') ? (
            <p className="font-md font-weight-bold text-red">{formatDate(policy.start_date)}</p>
          ) : (
            <p className="font-md">{formatDate(policy.start_date)}</p>
          )}
        </div>
        <div className="col">
          <span className="label">End date</span>
          <p className="font-md">{formatDate(policy.end_date)}</p>
        </div>
      </div>

      {!isQuote &&
      <div className="row">
        <div className="col">
          <span className="label">Transferred in</span>
          <p className="font-md">{formatDate(policy.transfer_in_date)}</p>
        </div>
        <div className="col">
          <span className="label">Transferred out</span>
          <p className="font-md">{formatDate(policy.transfer_out_date)}</p>
        </div>
      </div>
      }

      <div className="row">
        <div className="col">
          <span className="label">Claim limit</span>
          <p className="font-md">{money.format(policy.claim_limit)}</p>
        </div>
        <div className="col">
          <span className="label">Labour rate</span>
          <p className="font-md">{money.format(policy.labour_rate)}</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <span className="label">Excess</span>
          {formatExcess(policy.excess_type, policy.excess)}
        </div>
        <div className="col">
          <span className="label">Invoice status</span>
          <p className="font-md">{echo(policy.invoice_status)}</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <span className="label">Invoice generated date</span>
          <p className="font-md">
            {policy.invoice_generated_at ? formatDate(policy.invoice_generated_at) : '-'}
          </p>
        </div>
        <div className="col">
          <span className="label">Invoice paid date</span>
          <p className="font-md">
            {policy.invoice_latest_paid_at ? formatDate(policy.invoice_latest_paid_at) : '-'}
          </p>
        </div>
      </div>

      <div className="d-flex justify-content-between mt-3 pb-4 border-bottom">
        <span className="font-xl fw-800 text-gray-800">Price (inc. Tax)</span>
        <span className="font-xl fw-800 text-gray-800">{money.format(policy.net + policy.vat)}</span>
      </div>

      {(policy.dealer_net || policy.dealer_vat) && <div className="d-flex justify-content-between mt-3 mb-5 pb-4 border-bottom">
        <span className="font-xl fw-800 text-gray-800">Dealer Price<br></br>(exc. Tax)</span>
        <span className="font-xl fw-800 text-gray-800">{money.format(policy.dealer_net_override || policy.dealer_net)}</span>
      </div>
      }

      { sold_by && hasPermission('dealer_portal.policies.set_sold_by') && <div>
        <h6 className="mt-3 text-uppercase">{isQuote ? 'Quote' : 'Policy'} sold by</h6>
        <div className="d-flex py-3 mb-3">
          <>
            <Avatar user={sold_by}/>
            <span className="ml-2">{echo(sold_by.first_name)} {echo(sold_by.last_name, '')}</span>
          </>
        </div>
      </div> }

      { created_by && <div>
        <h6 className="mt-3 text-uppercase">{isQuote ? 'Quote' : 'Policy'} created by</h6>
        <div className="d-flex py-3 mb-3">
          <>
            <Avatar user={created_by}/>
            <span className="ml-2">{echo(created_by.first_name)} {echo(created_by.last_name, '')}</span>
          </>
        </div>
      </div> }

      { sold_by && <div>
        <h6 className="mt-3 text-uppercase">{isQuote ? 'Quote' : 'Policy'} sold by</h6>
        <div className="d-flex py-3 mb-3">
          <>
            <Avatar user={sold_by}/>
            <span className="ml-2">{echo(sold_by.first_name)} {echo(sold_by.last_name, '')}</span>
          </>
        </div>
      </div> }

      <h6 className="mt-3 text-uppercase">source</h6>
      <div className="mb-3">
        <span>{policy.purchased_from}</span>
      </div>

      {policy.extras && policy.extras.length > 0 && <>
        <h6 className="mt-4 text-uppercase">Policy Extras</h6>
        <div>
          {policy.extras.map((extra, idx) => (
              <p key={idx}>{extra.title}</p>
          ))}
        </div>
        {hasPermission('external_sales.policies.update') && hasRecovery() && (
          <button
            className="btn btn-outline-info btn-sm font-sm"
            disabled={sendingPolicyRecovery}
            onClick={sendRecovery}
          >
            Resend Recovery API
          </button>
        )}
      </>}

      <h6 className="mt-5 mb-4 text-uppercase">
        Policy documents
      </h6>
      {(['Approved', 'Cancelled', 'Expired'].indexOf(policy.status) > -1) &&
      <div
        className="ph-claim d-flex justify-content-between align-items-center cursor-pointer"
        onClick={() => {window.open(downloadUrl())}}
      >
        <div className="ph-claim-meta">
          <div className="ph-document-date font-sm font-weight-medium">
            {formatDate(policy.created_at)}
          </div>
          <div className="ph-document-ref font-md">
            Warranty Certificate
          </div>
        </div>

        <div className="ph-documents-links">
          <i className="wf-icon-download d-inline-block text-blue font-weight-bold"/>
        </div>
      </div>
      }
        {((policy.status === 'Awaiting Approval')) &&
        <div
          className="ph-claim d-flex justify-content-between align-items-center cursor-pointer"
          onClick={() => {window.open(downloadSummaryUrl())}}
        >
          <div className="ph-claim-meta">
            <div className="ph-document-date font-sm font-weight-medium">
              {formatDate(policy.created_at)}
            </div>
            <div className="ph-document-ref font-md">
              Policy Summary
            </div>
          </div>

          <div className="ph-documents-links">
            <i className="wf-icon-download d-inline-block text-blue font-weight-bold"/>
          </div>
        </div>
        }
      </>}
      <SlideToggle isVisible={policyEdit}>
        <PolicyEditForm
          policy={policy}
          onCancel={() => {
            setPolicyEdit(false)
          }}
          initialValues={{
            company_name: policy.company_name,
            cover_level: {name: cover_level.name, id: cover_level.id},
            start_date: moment(policy.start_date).format('DD-MM-YYYY'),
            duration: {name: policy.duration, value: policy.duration.split(' ')[0]},
            claim_limit: {name: policy.claim_limit, value: policy.claim_limit},
            labour_rate: {title: policy.labour_rate},
            excess: {title: policy.excess},
            sundries: policy.extras,
            policy_price: (policy.net + policy.vat).toFixed(2),
            pay_monthly: (policy.pay_monthly) ? {name: 'Pay Monthly', value: true} : {name: 'One-time payment', value: false},
            sold_by: policy.sold_by,
          }}
          onSubmit={handlePolicyEditSubmit}
        />
      </SlideToggle>
    </div>
  )
}

PolicyDetails.defaultProps = {
  isQuote: false
}

PolicyDetails.propTypes = {
  policy: PropTypes.object.isRequired,
}

export default PolicyDetails