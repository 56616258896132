import React, { Component } from 'react'
import {
  change,
  Field,
  Form,
  formValueSelector,
  isValid,
  reduxForm,
  untouch,
} from 'redux-form'
import renderField from '../../../../../../utils/renderField'
import CustomSelect from '../../../../../ui/CustomSelect'
import Modal from '../../../../../modals/Modal'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { set } from 'lodash'
import { history } from '../../../../../../utils/history'
import {
  formatTableHeadings,
  isNumber,
  isPositiveFloat,
} from '../../../../../../utils/misc'
import { numericality } from 'redux-form-validators'
import { NotificationManager } from 'react-notifications'
import api from '../../../../../../utils/api'
import CoverLevelTable from "../../CoverLevelTable";

class EditForm extends Component {
  state = {
    specialDurationModal: false,
    durationModal: false,
    deleteModal: false,
    coverLevelName: null,
  }

  toggleModal = (modal, coverLevelName) => {
    const { dispatch, change, untouch } = this.props

    if (modal === 'special') {
      this.setState((pre) => ({
        ...pre,
        specialDurationModal: !pre.specialDurationModal,
      }))

      // reset form fields
      dispatch(change('price-band/edit', 'pb_duration', ''))
      dispatch(change('price-band/edit', 'pb_drive', ''))
      dispatch(change('price-band/edit', 'target_cover_level', ''))

      dispatch(untouch('price-band/edit', 'pb_duration'))
      dispatch(untouch('price-band/edit', 'pb_drive'))
      dispatch(untouch('price-band/edit', 'target_cover_level'))
    } else if (modal === 'delete') {
      this.setState((pre) => ({
        ...pre,
        deleteModal: !pre.deleteModal,
      }))
    } else {
      this.setState((pre) => ({
        ...pre,
        durationModal: !pre.durationModal,
        coverLevelName,
      }))
    }
  }

  clearSpecialDuration = () => {
    this.setState(prevState => {
      return {...prevState, specialDuration: null}
    })
  }

  handleSpecialDurationSubmit = () => {
    const { pb_duration, pb_drive, target_cover_level } = this.props
    if (
      !isNumber(pb_duration) ||
      !isNumber(pb_drive) ||
      typeof target_cover_level === 'undefined'
    ) {
      return false
    }
    let title = `${pb_duration} months (drive ${pb_drive})`
    let specialDuration = {};
    specialDuration[target_cover_level.name] =   {
      title    : title,
      value    : parseInt(pb_duration),
      hiddenFor: [],
      special  : true,
    };
    this.setState(prevState => {
      return {...prevState, specialDuration}
    })
    this.toggleModal('special')
    const { dispatch, change, untouch } = this.props
    // reset form fields
    dispatch(change('pb_duration', ''))
    dispatch(change('pb_drive', ''))
    dispatch(change('target_cover_level', ''))
    dispatch(untouch('price-band/edit', 'pb_duration'))
    dispatch(untouch('price-band/edit', 'pb_drive'))
    dispatch(untouch('price-band/edit', 'target_cover_level'))
  }

  handleDelete = () => {
    api
      .delete(this.props.deleteEndpoint)
      .then((res) => {
        history.push(this.props.onDeleteRedirectTo)
        NotificationManager.success('Woohoo! You deleted a price band.')
      })
      .catch((err) => {
        if (err && err.response.status === 404) {
          NotificationManager.error('Oops! Price band could not be found.')
        }
      })
  }

  render() {
    const {
      handleSubmit,
      onSubmit,
      coverLevelOptions,
      coverLevels,
      isValid,
      pristine,
      prices,
      tableHeadings
    } = this.props

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>

        <Modal
          hide={() => this.toggleModal('special')}
          isShowing={this.state.specialDurationModal}
          style={{ width: '435px' }}
          title="Special pricing rule"
          subtitle="e.g. Pay for 24 months, drive for 36"
          onCancel={() => this.toggleModal('special')}
          onSubmit={this.handleSpecialDurationSubmit}
          textAction="Save price rule"
          textCancel="Cancel"
        >
          <div className="mt-4 d-flex">
            <Field
              name="pb_duration"
              type="text"
              label="Pay for (duration)"
              className="w-50 form-control"
              component={renderField}
              validate={[
                numericality({
                  msg: 'You must enter a number.',
                  allowBlank: false,
                }),
              ]}
              append={{
                direction: 'right',
                text: 'months',
              }}
            />
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <Field
              name="pb_drive"
              type="text"
              label="Drive for (duration)"
              className="w-50 form-control"
              component={renderField}
              validate={[
                numericality({
                  msg: 'You must enter a number.',
                  allowBlank: false,
                }),
              ]}
              append={{
                direction: 'right',
                text: 'months',
              }}
            />
          </div>

          <div>
            {coverLevels && (
              <Field
                name="target_cover_level"
                component={CustomSelect}
                label="Choose cover level"
                options={coverLevels}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
              />
            )}
          </div>
        </Modal>

        <Modal
          hide={() => this.toggleModal('delete')}
          isShowing={this.state.deleteModal}
          title="Are you sure?"
          onCancel={() => this.toggleModal('delete')}
          onSubmit={this.handleDelete}
          textAction="Yes, I'm sure"
          textCancel="No, cancel"
        >
          {this.props.name && (
            <p>
              Delete the price band <strong>{this.props.name}</strong>? It'll be
              lost forever.
            </p>
          )}
        </Modal>
        <div className="container-fluid">
          <div className="row">
            <div className="col-8">
              <div className="row">
                <div className="col">
                  <Field
                    type="text"
                    label="Price band name"
                    component={renderField}
                    name={`name`}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  {coverLevelOptions && (
                    <Field
                      name="coverLevels"
                      isMulti
                      component={CustomSelect}
                      label="Included cover level(s)"
                      options={coverLevelOptions}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {coverLevels && coverLevels.length > 0 && (
            <>
              <hr />
              <div className="row">
                <ul
                  className="list-info mb-4 ml-3"
                  style={{ maxWidth: '570px' }}
                >
                  <li>Prices exclude VAT.</li>
                  <li>
                    A price of £0 or a blank cell will prevent the warranty /
                    duration / claim limit from being chosen when setting up a
                    policy.
                  </li>
                </ul>
              </div>
              {/*<div className="row">*/}
              {/*  <div className="col">*/}
              {/*    <button*/}
              {/*      type="button"*/}
              {/*      className="btn btn-warning btn-sm font-weight-bold mr-4"*/}
              {/*      onClick={() => this.toggleModal('special')}*/}
              {/*    >*/}
              {/*      Add special duration*/}
              {/*    </button>*/}
              {/*    <span>e.g. Pay for 24 months, drive for 36.</span>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </>
          )}
        </div>

        {coverLevels &&
          coverLevels.map((coverLevel, i) => (
            <div className="row" key={i}>
              <CoverLevelTable
                clearSpecialDuration={this.clearSpecialDuration}
                specialDuration={this.state.specialDuration || null}
                key={i}
                reduxForm={'price-band/edit'}
                coverLevel={coverLevel}
                cols={formatTableHeadings(prices, tableHeadings, coverLevel.id)} />
            </div>
          ))}

        {coverLevels && coverLevels.length > 0 && (
          <div
            className="px-4 d-flex justify-content-between align-items-center"
            style={{ marginBottom: '4rem' }}
          >
            <div>
              <button
                type="submit"
                name="submit"
                className={`btn btn-secondary`}
                disabled={!isValid || pristine}
              >
                Save price band
              </button>
              <button
                type="submit"
                className="btn btn-transparent text-secondary"
                onClick={() => history.push(this.props.onCancelRedirectTo)}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={() =>
                  this.setState((prevState) => ({
                    ...prevState,
                    deleteModal: true,
                  }))
                }
                className="btn-delete-resource text-center"
                data-tip="Delete price band"
                data-place="top"
              >
                <div className="wf-icon-delete mx-auto" />
              </button>
            </div>
          </div>
        )}
      </Form>
    )
  }
}

EditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancelRedirectTo: PropTypes.string.isRequired,
  onDeleteRedirectTo: PropTypes.string.isRequired,
}

const selector = formValueSelector('price-band/edit')

const validate = (values) => {
  let errors = {}

  if (!values.name) errors.name = 'Price band name is required.'

  if (values.prices) {
    errors.prices = {}

    for(let [coverLevelId, claimLimits] of Object.entries(values.prices)) {
      for(let [claimLimit, durations] of Object.entries(claimLimits)) {
        for(let [duration, prices] of Object.entries(durations)) {
          if(prices.hasOwnProperty('price')) {
            if (!isPositiveFloat(prices.price)) {
              set(errors.prices, `${coverLevelId}.${claimLimit}.${duration}.price`, 'Must be a positive price')
            }
          } else {
            let key = Object.getOwnPropertyNames(prices)[0];
            if (!isPositiveFloat(prices[key])) {
              set(errors.prices, `${coverLevelId}.${claimLimit}.${duration}.${key}`, 'Must be a positive price')
            }
          }
        }
      }
    }
  }
  return errors
}

export default connect(
  (state) => ({
    coverLevels: selector(state, 'coverLevels'),
    name: selector(state, 'name'),
    pb_duration: selector(state, 'pb_duration'),
    pb_drive: selector(state, 'pb_drive'),
    target_cover_level: selector(state, 'target_cover_level'),
    isValid: isValid('price-band/edit')(state),
  }),
  { change, untouch }
)(
  reduxForm({
    form: 'price-band/edit',
    validate,
  })(EditForm)
)
