import React from 'react'

const coveredComponentsDynamicMarkup = <>
  <div className="mb-5">
    <h1><i className="wf-icon-dynamic-cover d-inline-block mr-1"></i> Dynamic</h1>
    <p>Our value plan provides cover for you vehicle at a great price.</p>
  </div>

  <hr/>

  <div className="my-5">
    <h2 className="my-4">What's included?</h2>
    <p>You can make multiple claims, up to the current
      value of your vehicle (based on Glass’s Guide industry prices) whilst your vehicle is covered by
      us.</p>
  </div>

  <h5><i className="wf-icon-circle-tick d-inline-block mt-2 mr-1"/> Engine</h5>
  <p className="small">Rocker assembly, inlet, exhaust valves, valve guides, valve springs, cylinder
    head, cylinder head gasket, stretched head bolts, push rods, camshaft followers, timing gears,
    timing chains, tensioner, oil pump, drive gears, pistons, piston rings, cylinder bores, gudgeon
    pins, connecting rods, big end bearings, main bearing, crankshaft, ring gear,distributor drive,
    timing belts (subject to correct replacement schedule having taken place), engine casings (if
    damaged by an internal component.</p>

  <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Gearbox</h5>
  <p className="small">Gears, shafts, synchromesh hubs, baulk rings, gear selector forks, bearings,
    speedometer, drive, factory fitted overdrive units, solenoids, transfer box components, gearbox
    casings (if damaged by an internal component).</p>

  <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Automatic gearbox</h5>
  <p className="small">Shafts, bushes, clutches, brake bands, bearings, oil pump, governers,
    servos, torque convertor, drive plate, valve block, modulator valve, speedometer drive,
    casings (if damaged by an internal component).</p>

  <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Drive train</h5>
  <p className="small">Crown wheel, pinion gear, planet gears, planet carriers bearings, all
    other internal components, half shafts, drive shafts, prop shafts, universal joints, support
    bearings, constant velocity joints, casings (if damaged by an internal component).</p>

  <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Electrical</h5>
  <p className="small">Starter motor, alternator, front wiper motor, rear wiper motor,
    indicator switch, indicator relay.</p>

  <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Clutch</h5>
  <p className="small">Clutch cover diaphragm, centre plate torque springs, clutch release
    bearing, clutch fork, clutch master cylinder, slave cylinder.</p>

  <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Steering</h5>
  <p className="small">Rack and pinion assembly, hydraulic PAS steering pump, PAS steering
    motor, steering box, steering idler.</p>

  <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Brakes</h5>
  <p className="small">Brake master cylinder, brake callipers (excluding seizure).</p>

  <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Cooling system</h5>
  <p className="small">Water pump (non cam belt driven), thermostat, thermostat
    housing.</p>

  <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Wear and tear cover</h5>
  <p className="small">The following are covered for wear and tear only up to
    110,000 miles and 10 years, whichever is sooner (inspections may be required):
    Wheel bearings, Coil Springs &amp; Shock Absorbers.</p>
</>

export default coveredComponentsDynamicMarkup