import React, { Fragment } from 'react'
import { reduxForm, Field, Form, getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import renderField from '../../../utils/renderField'
import { fetchUser } from '../../../actions/users.actions'
import CustomSelect from '../../ui/CustomSelect'
import validator from 'validator'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import { NotificationManager } from 'react-notifications'
import api from '../../../utils/api'
import PropTypes from 'prop-types'
import CustomAsyncSelect from "../../ui/CustomAsyncSelect";
import { get } from 'lodash';

const UserEditForm = ({formValues, ...props}) => {

  const resendInvitation = () => {
    api
      .get(
        `/admin/users/users/${props.initialValues.id}/resend-invitation-email`,
      )
      .then(() =>
        NotificationManager.success(
          'Woohoo! Your email invitation was re-sent.',
        ),
      )
      .catch(() =>
        NotificationManager.warning(
          'Oops! Your email invitation could not be sent. Please try again.',
        ),
      )
  }

  const sendResetPasswordEmail = () => {
    api.post(`/auth/forgot`, {
      email: props.initialValues.email,
    }).then(() =>
      NotificationManager.success(
        'Woohoo! Your reset password email was sent.',
      ),
    ).catch((err) => {
      console.error(err);
        // NotificationManager.warning(err);
        NotificationManager.warning(
          'Oops! Your reset password email could not be sent. Please try again.',
        );
      })
  }

  const handleDelete = () => {
    confirmAlert({
      title: '🧐 Are you sure?',
      customUI: ({title, onClose}) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <span className="btn-close-modal fa fa-close" onClick={onClose}/>
              <h3>{title}</h3>
              <p>
                Delete <strong>{props.name}</strong> from the system? The user
                won't be able to login whilst deleted. You can undo this later.
              </p>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                  props.deleteUser(props.initialValues.id, '/admin/users')
                }}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-transparent text-secondary"
                onClick={onClose}
              >
                No, cancel
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const loadDealerOptions = async (query) => {
    let res = await api.get(`/misc/dealers?query=${query}&limit=999999&is_prospect=false`);
    return res.data.data;
  }

  const loadUserOptions = async (query) => {
    // we need the ID of the Direct Sales user group
    const directSalesGroup = props.groups.find((e) => e.name === 'Direct Sales');
    // now load all users that are in the Direct Sales user group
    const res = await api.get(`/admin/users/users?groups[]=${directSalesGroup.value}&query=${query}&limit=999999`);
    return res.data.data;
  }

  const directSalesGroupIsChosen = () => {
    const groups = get(formValues, 'groups', []);
    const directSalesGroup = groups.find((e) => e.name === 'Direct Sales');
    return directSalesGroup !== undefined;
  }

  const {handleSubmit, onSubmit, cancel, initialValues, deleted} = props

  return (
    initialValues && (
      <Form onSubmit={handleSubmit(onSubmit)} style={{marginBottom: '5rem'}}>
        <h2 className="mb-4">Edit user</h2>
        {!deleted && !props.initialValues.is_registered && (
          <div className="alert alert-primary" style={{marginBottom: 40}}>
            <i className="icon-info"/> This user has not logged in yet.{' '}
            <Link to="#" onClick={() => resendInvitation()}>
              Resend invitation email
            </Link>{' '}
          </div>
        )}

        <fieldset disabled={deleted}>
          <div className="row">
            <div className="col">
              <Field
                type="text"
                label="First name"
                component={renderField}
                name="first_name"
                className="form-control"
              />
            </div>

            <div className="col">
              <Field
                type="text"
                label="Last name"
                component={renderField}
                name="last_name"
                className="form-control"
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Field
                type="email"
                label="Email address"
                component={renderField}
                name="email"
                className="form-control"
              />
            </div>
          </div>

          {props.initialValues.is_registered && <div className="row">
            <div
              className="col"
              style={{marginTop: '1.25rem', marginBottom: '2.5rem'}}
            >
              <h6 className="title-xs">Reset user password</h6>
              <p>We'll email the user a link to reset their password</p>

              <button
                type="button"
                onClick={() => sendResetPasswordEmail()}
                className="btn btn-warning btn-sm font-sm"
              >
                Reset password
              </button>
            </div>
          </div>}

          <div className="row">
            <div className="col">
              <div className="form-group">
                {props.dealerships && <Field
                  label="Assign to dealership (optional)"
                  component={CustomAsyncSelect}
                  placeholder={props.dealer.name}
                  name="dealer_id"
                  isDisabled={deleted}
                  loadOptions={loadDealerOptions}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.name}
                  options={props.dealerships}
                  className={`select-z-index-fix`}
                />}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="form-group">
                <Field
                  name="groups"
                  isMulti
                  component={CustomSelect}
                  isDisabled={deleted}
                  label="Groups"
                  options={props.groups}
                  getOptionValue={option => option.value}
                  getOptionLabel={option => option.name}
                />
              </div>
            </div>
          </div>

          {initialValues.is_two_factor_email_active && (
            <div className="row">
              <div className="col">
                <Field
                  type="text"
                  placeholder="Separate multiple emails with a comma (no space)"
                  label="Copy 2FA email code"
                  component={renderField}
                  name="copy_two_factor_emails"
                  className="form-control"
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="status">Status</label>
                {!deleted ? (
                  <Fragment>
                    <div className="d-flex">
                      <Field
                        type="radio"
                        label="Active"
                        value={true}
                        id="statusActive"
                        onChange={val => props.change('is_active', val)}
                        onBlur={val => props.change('is_active', val)}
                        name="is_active"
                        component={renderField}
                      />

                      <Field
                        type="radio"
                        label="Suspended"
                        value={false}
                        id="statusSuspended"
                        name="is_active"
                        onChange={val => props.change('is_active', val)}
                        onBlur={val => props.change('is_active', val)}
                        component={renderField}
                      />
                    </div>
                    {!props.isActive && (
                      <Field
                        type="text"
                        placeholder="e.g Awaiting payment"
                        label="Why do you want to suspend this user?"
                        component={renderField}
                        name="suspended_reason"
                        className="form-control"
                      />
                    )}
                    {!props.isActive && directSalesGroupIsChosen() && (
                      <Field
                        label="Move sales to another user (optional)"
                        component={CustomAsyncSelect}
                        name="move_sales_to"
                        isDisabled={deleted}
                        loadOptions={loadUserOptions}
                        getOptionValue={option => option.person_id}
                        getOptionLabel={option => `${option.first_name} ${option.last_name} (${option.email})`}
                        className={`select-z-index-fix`}
                      />
                    )}
                  </Fragment>
                ) : (
                  <p>Deleted</p>
                )}
              </div>
            </div>
          </div>

        </fieldset>

        <div className="row mt-4">
          <div className="col">
            {!deleted ? (
              <button disabled={!props.valid} className="btn btn-secondary">
                Save changes
              </button>
            ) : (
              <button
                type="button"
                onClick={() => props.restoreUser(props.initialValues.id)}
                className="btn btn-secondary"
              >
                Restore user
              </button>
            )}
            <button
              className="btn btn-outline"
              style={{color: '#70b725'}}
              onClick={cancel}
            >
              Cancel
            </button>
          </div>
          {!deleted && (
            <div className="col d-flex justify-content-end btn-delete">
              <button
                type="button"
                className="btn-delete-resource"
                onClick={() => handleDelete()}
              >
                <span className="icon-trash"/>
              </button>
            </div>
          )}
        </div>
      </Form>
    )
  )
}

const validate = (values, props) => {

  let errors = {}

  if (!values.first_name) errors.first_name = 'First name is required.'
  if (values.first_name && values.first_name.length < 2)
    errors.first_name = 'Must be 2 characters or more.'
  if (values.first_name && values.first_name.length > 50)
    errors.first_name = 'Must be 50 characters or less.'
  if (!values.last_name) errors.last_name = 'Last name is required.'
  if (values.last_name && values.last_name.length < 2)
    errors.last_name = 'Must be 2 characters or more.'
  if (values.last_name && values.last_name.length > 50)
    errors.last_name = 'Must be 50 characters or less.'
  if (!values.email) errors.email = 'Please give this user an email address.'
  if (!values.groups || values.groups.length === 0)
    errors.groups = 'Please assign one or more groups to this user.'
  if (values.email && !validator.isEmail(values.email))
    errors.email = `That email address doesn't look quite right.`

  if (!values.is_active && !values.suspended_reason)
    errors.suspended_reason = 'Please give a reason.'

  if (values.is_two_factor_email_active && values.copy_two_factor_emails && values.copy_two_factor_emails.split(',').some(email => !validator.isEmail(email)))
    errors.copy_two_factor_emails = `Copy 2FA email code doesn't look quite right.`
 
  return errors
}

UserEditForm.propTypes = {
  change: PropTypes.func.isRequired,
  dealerships: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  deleted: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
  cancel: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  restoreUser: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dealerGroup: PropTypes.array.isRequired,
}

export default connect(
  state => ({
    initialValues: state.users.singleUser,
    formValues: getFormValues('users/edit')(state)
  }),
  {fetchUser},
)(
  reduxForm({
    form: 'users/edit',
    enableReinitialize: true,
    validate,
  })(UserEditForm),
)
