import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, Form, reduxForm } from 'redux-form'
import renderField from '../../../../../utils/renderField'
import renderDatePicker from '../../../../ui/renderDatePicker'
import {usePostcodeLookupWithRedux} from "../../../../../hooks/usePostcodeLookupWithRedux";
import { history } from '../../../../../utils/history'
import CustomAsyncSelect from "../../../../ui/CustomAsyncSelect";
import api from '../../../../../utils/api'

const GarageForm = props => {
  const { handleSubmit, onSubmit } = props
  const { markup } = usePostcodeLookupWithRedux('garages/create')

  const loadDealerOptions = async (query) => {
    let res = await api.get(`/misc/dealers?query=${query}&limit=10&active_only=true`);
    return res.data.data;
  }

  const [currentVatRegistered, setCurrentVatRegistered] = useState(false)

  const handleVatRegisteredChange = (event) => {
    const vatRegistered = event.target.checked
    setCurrentVatRegistered(vatRegistered)
  }

  return (
    <div className="mb-5">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mw-37-5rem mx-auto">

          <div className="row">
            <div className="col">
              <h3 className="my-4">Add a garage</h3>
            </div>
          </div>
          <div className="row">
            <div className="col mt-3">
              <Field
                type="text"
                label="Garage name"
                component={renderField}
                name="name"
                className="form-control"
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              {markup}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Field
                name="signup_date"
                label="Sign-up date"
                className="form-control"
                inputValueFormat="DD-MM-YYYY"
                placeholderText="DD/MM/YYYY"
                dateFormat="dd/MM/yyyy"
                fixedHeight
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={3}
                id="signupDate"
                component={renderDatePicker}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Field
                label="This garage is an authorised repairer"
                name="authorised_repairer"
                type="checkbox"
                id="authorisedRepairer"
                component={renderField}
              />
              <Field
                label="This garage is VAT registered"
                name="vat_registered"
                type="checkbox"
                id="vatRegistered"
                component={renderField}
                onChange={handleVatRegisteredChange}
              />
              {currentVatRegistered && (
                <Field
                  type="text"
                  label="VAT number"
                  component={renderField}
                  name={`vat_number`}
                  className="form-control"
                />
              )}
            </div>
          </div>

          <hr/>

          <div className="row">
            <div className="col-md-12 mb-4">
              <h6 className="mt-4 text-uppercase">Contact details</h6>
              <p>Please include at least one contact telephone number</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 b-form-group">
              <Field
                type="text"
                label="Telephone (office)"
                component={renderField}
                name={`tel_office`}
                className="form-control"
              />
            </div>
            <div className="col-md-6 b-form-group">
              <Field
                type="text"
                label="Telephone (mobile)"
                component={renderField}
                name={`tel_mobile`}
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 b-form-group">
              <Field
                type="email"
                label="Email address"
                component={renderField}
                name={`email`}
                className="form-control"
                placeholder="e.g. 123cars@gmail.com"
              />
            </div>
            <div className="col-md-6 b-form-group">
              <Field
                type="text"
                label="Website (optional)"
                component={renderField}
                name={`website`}
                className="form-control"
              />
            </div>
          </div>

          <hr/>

          <div className="row">
            <div className="col-md-12 mb-4">
              <h6 className="mt-4 text-uppercase">Additional information</h6>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <Field
                type="text"
                label="Minimum Labour Rate"
                component={renderField}
                name={`minimum_labour_rate`}
                className="form-control"
              />
            </div>
            <div className="col-6">
              <Field
                type="text"
                label="Sell Out Labour Rate"
                component={renderField}
                name={`sell_out_labour_rate`}
                className="form-control"
              />
            </div>
            <div className="col-8">
              <Field
                component={CustomAsyncSelect}
                label="Link to dealers (optional)"
                name="dealer"
                placeholder={'Find a dealer'}
                isClearable
                cacheOptions
                loadOptions={loadDealerOptions}
                defaultOptions={false}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                isMulti={true}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Field
                name="comments"
                type="textarea"
                label="Comments"
                className="form-control"
                component={renderField}
                rows={4}
                placeholder=""
              />
            </div>
          </div>

          <div className="row">
            <div className="col mt-3">
              <button disabled={!props.valid} className="btn btn-secondary">
                Add garage
              </button>
              <button onClick={() => {
                history.push('/claims/garages')
              }} className="btn btn-outline" style={{ color: '#70b725' }}>
                Cancel
              </button>
            </div>
          </div>

        </div>
      </Form>
    </div>
  )
}

GarageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

const validate = (v) => {
  let errors = {};
  if (!v.name) errors.name = 'Please provide a garage name.'
  if ((!v.tel_office) && (!v.tel_mobile)) {
    errors.tel_office = 'Please provide at least 1 phone number.'
  }
  return errors;
}

export default reduxForm({
  form: 'garages/create', validate
})(GarageForm)