
const validate = fields => {
  let errors = {}
  if (!fields.name)
    errors.name = 'Please give this territory a name.'
  if (fields.name && fields.name.length < 2)
    errors.name = 'Must be 2 characters or more.'
  if (fields.name && fields.name.length > 50)
    errors.name = 'Must be 50 characters or less.'
  if (fields.postcodes && fields.postcodes.length &&  fields.postcodes.length < 3)
    errors.postcodes = 'Please add a valid postcode for this territory.'
  if (!fields.start_date)
    errors.start_date = 'Please give this account manager a start date.'

  return errors
}

export default validate
