import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import api from '../../../../../utils/api'

const ClaimsCreate = ({ array: { push } }) => {
  const [step, setStep] = useState(1)
  const [steps, setSteps] = useState(formSteps)
  const [policy, setPolicy] = useState(null)
  const [lastStepVisited, setLastStepVisited] = useState(1)

  const nextStep = () => {
    setStep(step + 1)
  }

  useEffect(() => {
    push('claim_items', {})
  }, [])


  useEffect(() => {
    setSteps(steps => [
      ...steps.map(item => {
        if (item.id === step) {
          item.current = true
        } else {
          item.current = false
        }
        return item
      }),
    ])
  }, [step])

  /**
   * Only allow the user to navigate
   * to the last step he visited.
   *
   * @param {Number} step
   */
  const handleGoBackTo = step => {
    if (lastStepVisited >= step.id) {
      setStep(step.id)
    }
  }

  const handleSubmit = values => {
    NotificationManager.success('Woohoo! You created a claim.')
    // dispatch(reset('create-policy'))
    // history.push('/external-sales/policies')
  }

  /**
   * Fetch all garages
   * @param query
   * @returns {Promise<unknown>}
   */
  const fetchGarages = query =>
    new Promise((resolve, reject) => {
      api
        .get(`misc/garages?query=${query ? query : ''}&limit=9999999&status=Active`)
        .then(res => {
          resolve(res.data.data)
        })
        .catch(err => {
          if (err) {
            reject([])
          }
        })
    })

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/claims/claims" className="open active">
            All claims
          </Link>
        </li>
        <li className="breadcrumb-item">
          <span className="active">Start a new claim</span>
        </li>
      </ol>

      <h2 className="text-center mt-5 mb-5">Start a new claim</h2>

      {steps && (
        <div className="px-5 bg-grey text-text-center d-flex justify-content-center py-3 form-steps">
          {steps.map((step, i) => (
            <h5
              onClick={() => handleGoBackTo(step)}
              className={`form-step fw-800 text-capitalize
        ${step.current ? 'text-primary current' : ''}
        `}
              key={i}
            >
              {step.id}. {step.title}
            </h5>
          ))}
        </div>
      )}
      <div className="container-fluid mt-5">
        {step === 1 && (
          <StepOne setPolicy={setPolicy} policy={policy} onSubmit={() => {
            nextStep()
            setLastStepVisited(2)
          }}/>
        )}
        {step === 2 && (
          <StepTwo policy={policy} onSubmit={() => {
            nextStep()
            setLastStepVisited(3)
          }}/>
        )}
        {step === 3 && <StepThree setStep={setStep} policy={policy} fetchGarages={fetchGarages} onSubmit={handleSubmit}/>}
      </div>
    </div>
  )
}


const formSteps = [
  {
    id             : 1,
    title          : 'Policy',
    current        : true,
    stepLinkIsGreen: false,
  },
  {
    id             : 2,
    title          : 'Fault',
    current        : false,
    stepLinkIsGreen: false,
  },
  {
    id             : 3,
    title          : 'Claim',
    current        : false,
    stepLinkIsGreen: false,
  },
]

ClaimsCreate.propTypes = {}

export default connect()(
  reduxForm({
    form            : 'claims/create',
  })(ClaimsCreate),
)
