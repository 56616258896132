import {
  FETCH_PH_REQUEST,
  FETCH_PH_SUCCESS,
  FETCH_PH_FAILURE,
  SEARCH_PH,
  FETCH_SINGLE_PH_REQUEST, FETCH_SINGLE_PH_SUCCESS, FETCH_SINGLE_PH_FAILURE, UPDATE_PH_CONTACT_DETAILS_SUCCESS
} from '../../constants';

const initialState = {
  isPending: true,
  data: null,
  error: null,
  policyHolder: null,
  selectedRows: [],
  searching: false,
}

export function policyHolders(state = initialState, action) {
  switch (action.type) {
    case FETCH_PH_REQUEST:
      return {
        ...state,
        isPending: true,
      }
    case FETCH_PH_SUCCESS:
      return {
        ...state,
        isPending: false,
        data: action.payload.policyHolders,
      }
    case FETCH_PH_FAILURE:
      return {
        ...state,
        isPending: false,
        data: null,
        errors: { ...action.payload.errors },
      }
    case SEARCH_PH:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_SINGLE_PH_REQUEST:
      return {
        isPending: true
      }

    case FETCH_SINGLE_PH_SUCCESS:
      return {
        isPending: false,
        policyHolder: action.payload.policyHolder
      }

    case FETCH_SINGLE_PH_FAILURE:
      return {
        isPending: false,
        policyHolder: null,
        error: action.payload.error
      }
    case UPDATE_PH_CONTACT_DETAILS_SUCCESS:
      return {
        isPending: false,
        policyHolder: {...state.policyHolder, ...action.payload.policyHolder}
      }
    default:
      return state
  }
}
