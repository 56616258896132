import React from 'react'
import Select from 'react-select'

const CustomSelect = props => {
  const { prependIcon, appendIcon, managed, size, showSuccessValidation } = props

  let validated = false
  const {
    meta: { touched, error, valid },
  } = props

  if (touched && (error && 'error')) {
    validated = false
  }

  if (touched && valid && showSuccessValidation) {
    validated = true
  }

  const selectStyles = {
    input              : (provided, state) => {

      return {
        ...provided,
        paddingTop     : size === 'sm' ? 0 : 8,
        paddingBottom  : size === 'sm' ? 0 : 8,
        flex           : 1,
        backgroundColor: '#fff',
      }
    },
    control            : (provided, state) => {
      if (touched) {
        if (valid)
          return {
            ...provided,
            borderColor: '#70B725',
          }
        if (validated) {
          return {
            ...provided,
            borderColor: '#70B725',
          }
        } else {
          return {
            ...provided,
            borderColor: '#e10808',
          }
        }
      }
      let prependInputGroupStyles = {}
      if (prependIcon) {
        prependInputGroupStyles = {
          borderRadius: '0 .25rem .25rem 0',
        }
      }
      let appendInputGroupStyles = {};
      if (appendIcon) {
        appendInputGroupStyles = {
          borderRadius: '.25rem 0 0 .25rem',
        }
      }

      return {
        ...provided,
        backgroundColor: state.isDisabled ? 'rgba(45,44,49,0.05)' : '#fff',
        borderColor    : state.isDisabled ? 'rgba(45,44,49,0.2)' : '#d3d2d7',
        borderRadius   : '.25rem',
        height         : size === 'sm' ? '30' : 'auto',
        ...prependInputGroupStyles,
        ...appendInputGroupStyles
      }
    },
    placeholder        : (provided, state) => ({
      ...provided,
      color: state.disabled && 'rgba(45,44,49,0.4)',
    }),
    singleValue        : (provided, state) => {
      return {
        ...provided,
        padding: 0,
        // fontSize: 15,
      }
    },
    multiValue         : styles => ({
      ...styles,
      border      : '1px solid #d3d2d7',
      padding     : '0.31rem',
      borderRadius: '0.25rem',
    }),
    multiValueLabel    : styles => ({
      ...styles,
      fontSize: '1em',
      color   : 'rgba(45,44,49,0.7)',
      padding : 0,
    }),
    multiValueRemove   : (styles, { data }) => ({
      ...styles,
      paddingTop: '2px',
      ':hover'  : {
        backgroundColor: 'none',
        cursor         : 'pointer',
        fontSize       : '1em',
      },
    }),
    indicatorSeparator : (provided, state) => {
      return ({
        ...provided,
        display: state.isMulti ? 'block' : 'none',
      })
    },
    indicatorsContainer: (provided, state) => {
      return {
        ...provided,
        padding: 0,
      }
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 999,
      }
    }
  }

  const selectBox = (
    <Select
      {...props}
      label={props.label}
      value={props.input.value}
      onChange={value => props.input.onChange(value)}
      onBlur={() => props.input.onBlur(props.input.value)}
      blurInputOnSelect={false} /* bug fix - dropdowns show no options on mobile phones */
      isSearchable={!!props.isSearchable} /* false: hide virtual keyboard on mobiles */
      options={props.options}
      placeholder={props.placeholder}
      styles={selectStyles}
      className="react-select-container"
      classNamePrefix="react-select"
      menuPortalTarget={document.querySelector('body')}
    />
  )

  return (
    <div className="b-form-group form-group select-z-index-fix">
      {props.label && <label htmlFor="col-form-label pt-0">{props.label}</label>}

      {prependIcon ? (
        <>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text font-sm">{prependIcon}</span>
            </div>
            {selectBox}
          </div>
        </>
      ) : <></>}

      {appendIcon ? (
        <>
          <div className="input-group">
            {selectBox}
            <div className="input-group-append">
              <span className="input-group-text font-sm">{appendIcon}</span>
            </div>
          </div>
        </>
      ) : <></>}

      {(!prependIcon && !appendIcon) ? (
        <>
          {selectBox}
        </>
      ) : <></>}

      {managed && touched && error && (
        <div
          style={{
            marginTop: '0.25rem',
            fontSize : '90%',
            color    : '#e10808',
          }}
        >
          {error}
        </div>
      )}
    </div>
  )
}

CustomSelect.defaultProps = {
  managed: true, // if CustomSelect is needed without redux-form
  placeholder          : 'Select',
  prependIcon          : false,
  showSuccessValidation: true,
}

export default CustomSelect
