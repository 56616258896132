import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import {NotificationManager} from "react-notifications";
import api from '../../../../utils/api';
import { echoMoney } from '../../../../utils/misc'
import PageHeader from '../../../ui/PageHeader';
import moment from 'moment';

class Billing extends React.Component {

  state = {
    summary: null,
    policyCharges: null,
    mandate: null,
  }

  fetchAccountSummary = () => {
    api
      .get(`/policyholder-portal/billing/summary`)
      .then((res) => {
        this.setState({summary: {...res.data}})
      })
      .catch((err) => {
        NotificationManager.error('Oops! Something went wrong. Please try again after refreshing')
      })
  }

  fetchPolicyCharges = () => {
    api
      .get(`/policyholder-portal/billing/payment-plan`)
      .then((res) => {
        this.setState({policyCharges: {...res.data}})
      })
      .catch((err) => {
        NotificationManager.error('Oops! Something went wrong. Please try again after refreshing')
      })
  }

  fetchMandate = () => {
    api
      .get(`/policyholder-portal/billing/direct-debit/mandate`)
      .then((res) => {
        this.setState({mandate: {...res.data}})
      })
      .catch((err) => {
        NotificationManager.error('Oops! Something went wrong. Please try again after refreshing')
      })
  }

  componentDidMount = () => {
    this.fetchAccountSummary()
    this.fetchPolicyCharges()
    this.fetchMandate()
  }

  getOutstanding = (row) => {
    let outstanding = 0.00
    if (parseFloat(row.invoice.ar_entries[0].outstanding) > 0) {
      if (row.policy.pay_monthly)  {
        if (row.ar_entries[0] === undefined) { // no sales receipt for the policy charge
          outstanding = parseFloat(row.net) + parseFloat(row.vat)
        }
      } else {
        outstanding = parseFloat(row.invoice.ar_entries[0].outstanding)
      }
    }
    return outstanding
  }

  amountOverdue = () => {
    const today = moment().startOf('day');
    let overdue = 0.00;
    if (this.state.policyCharges && this.state.policyCharges.data) {
      this.state.policyCharges.data.forEach(row => {
        const outstanding = this.getOutstanding(row)
        if (outstanding > 0.00 && moment(row.date_due) < today) {
          overdue += outstanding
        }
      });
    }
    return overdue;
  }

  renderOutstanding = (row) => {
    const outstanding = this.getOutstanding(row)
    return outstanding > 0.00 ? echoMoney(outstanding) : '-'
  }

  renderDaysOverdue = (row) => {
    let outstanding = this.getOutstanding(row)
    if (row.date_due && outstanding > 0.00) {
      const today = moment().startOf('day');
      const dateDue = moment(row.date_due);
      const days = Math.round(moment.duration(today - dateDue).asDays());
      return days > 0 ? days : '-';
    }
    return '-';
  }

  render() {

    return (
      <Fragment>
        <br/>
        <div className="container-fluid animated fadeIn mb-4">
          <PageHeader
            title="Billing"
            description={'A summary of your account is displayed here.'}
          />

          {!(this.state.policyCharges && this.state.policyCharges.data) &&
          <p className="mt-4 mb-5" style={{fontStyle: 'italic'}}>You have no invoices yet</p>
          }

          {this.state.policyCharges && this.state.policyCharges.data &&
          <>
          <table className="table table-striped table-bordered d-table mt-3 mb-5">
            <thead>
              <tr>
                <th>Invoice No.</th>
                <th>Invoice Date</th>
                <th>Policy No.</th>
                <th>Amount</th>
                <th>Outstanding</th>
                <th>Due Date</th>
                <th>Days Overdue</th>
              </tr>
            </thead>
            <tbody>
            {this.state.policyCharges.data && this.state.policyCharges.data.length > 0 && this.state.policyCharges.data.map((row, i) => {
              const prevRow = i === 0 ? null : this.state.policyCharges.data[i-1]
              return (
                <tr key={i}>
                  <td>{prevRow === null || prevRow.invoice.id !== row.invoice.id ? row.invoice.id : ''}</td>
                  <td>{prevRow === null || prevRow.invoice.id !== row.invoice.id ? moment(row.invoice.date).format('DD/MM/YYYY') : ''}</td>
                  <td>{prevRow === null || prevRow.policy.id !== row.policy.id ? row.policy.ref : ''}</td>
                  <td>{echoMoney(row.net + row.vat)}</td>
                  <td>{this.renderOutstanding(row)}</td>
                  <td>{row.date_due ? moment(row.date_due).format('DD/MM/YYYY') : '-'}</td>
                  <td>{this.renderDaysOverdue(row)}</td>
                </tr>
              )})
            }
            </tbody>
          </table>
          </>
          }

          {this.state.summary &&
          <div className="row">

            <div className="col billing-summary">
              <div className="row">
                <div className="col-3">Account balance:</div>
                <div className="col-2 text-right">{echoMoney(this.state.summary.balance - (this.state.summary.uncredited + this.state.summary.unallocated))}</div>
                <div className="col-2">&nbsp;</div>
                <div className="col-5">&nbsp;</div>
              </div>
              <div className="row amount-coming-due">
                <div className="col-3">Amount coming due:</div>
                <div className="col-2 text-right">{echoMoney(this.state.summary.balance)}</div>
                <div className="col-2">&nbsp;</div>
                <div className="col-5">&nbsp;</div>
              </div>
              <div className="row amount-overdue">
                <div className="col-3">Amount overdue:</div>
                <div className="col-2 text-right">{echoMoney(this.amountOverdue())}</div>
                <div className="col-2">&nbsp;</div>
                <div className="col-5">&nbsp;</div>
              </div>
              <div className="row">
                <div className="col-5 mt-5 text-right">You {(!this.state.mandate || !this.state.mandate.sort_code) && 'do not' } have a direct debit mandate setup with us.</div>
              </div>
              <div className="row">
                <div className="col-5">&nbsp;</div>
              </div>
              <div className="row">
                <div className="col-5 text-right">
                  <Link to="/policyholder-portal/billing/direct-debit">
                    <button className="btn btn-primary">
                    {this.state.mandate && this.state.mandate.sort_code ? 'View' : 'Setup'} direct debit mandate
                    </button>
                  </Link>
                </div>
              </div>
            </div>

          </div>
          }

        </div>

      </Fragment>
    )
  }
}

export default Billing
