import React from 'react'
import { Form, reduxForm, Field } from 'redux-form'
import renderField from '../../../utils/renderField'
import { hasUpperCase, hasNumber, hasSpecialCharacters } from '../../../utils/misc'
import propTypes from 'prop-types'

function ChangePasswordForm(props) {
  const { onSubmit, handleSubmit, toggleModal, errors } = props

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>

      {errors && <div className="alert alert-danger">{errors}</div>}

      <Field
        type="password"
        className="form-control"
        name={`password`}
        label="New password"
        component={renderField}
        canToggleShow={true}
      />

      <Field
        type="password"
        className="form-control"
        name={`password_confirmation`}
        label="Please confirm your new password"
        component={renderField}
        canToggleShow={true}
      />

      <button
        className="btn btn-secondary mt-4"
        type="submit"
        disabled={!props.valid}
      >
        Update password
      </button>
      <button
        className="btn btn-transparent text-secondary mt-4"
        onClick={toggleModal}
      >
        Cancel
      </button>
    </Form>
  )
}

ChangePasswordForm.propTypes = {
  toggleModal: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
  handleSubmit: propTypes.func.isRequired,
  valid: propTypes.bool,
}

const validate = fields => {
  const errors = {}

  if (!fields.password) errors.password = 'This field is required.'
  if (fields.password && fields.password.length < 6)
    errors.password = 'Must be 6 characters or more.'
  if (!hasUpperCase(fields.password))
    errors.password = 'Must contain at least one uppercase character'
  if (!hasNumber(fields.password))
    errors.password = 'Must contain at least one number'

  if (!fields.password_confirmation)
    errors.password_confirmation = 'This field is required.'
  if (
    fields.password_confirmation &&
    fields.password &&
    fields.password_confirmation !== fields.password
  )
    errors.password_confirmation = 'Must match new password.'
  
  if(fields.password && !hasSpecialCharacters(fields.password))
    errors.password = 'Password needs at least 1 special character.'

  return errors
}

// eslint-disable-next-line
ChangePasswordForm = reduxForm({
  form: 'profile/updatePassword',
  validate,
})(ChangePasswordForm)

export default ChangePasswordForm
