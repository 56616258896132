import React from 'react'
import { LoginFormWrapper } from '../../Login/Login.style'
import { Field, Form, reduxForm } from 'redux-form'
import * as validator from 'validator'
import renderField from '../../../../utils/renderField';
import { isVrm, validPostcode } from '../../../../utils/misc';

const RegisterForm = props => {
  const {handleSubmit, onSubmit, submitError, linkSent} = props

  return (
    <LoginFormWrapper>
      <h1 className="h2">Register today</h1>
      <h4 className="text-uppercase">To access your policy details</h4>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <Field
              label="Vehicle reg."
              type="text"
              name="vrm"
              component={renderField}
              className="form-control"
              placeholder="e.g. AB12CDE"
              normalize={value => value && value.replace(/\s/g, '').toLocaleUpperCase()}
            />
          </div>
          <div className="col-12">
            <Field
              label="Email address"
              type="email"
              name="email"
              component={renderField}
              className="form-control"
              placeholder="Your email address"
            />
          </div>
        </div>
        {submitError &&
        <div className="alert alert-danger">{submitError}</div>
        }
        {!linkSent &&
        <button type="submit" disabled={!props.valid} className="btn mt-4 px-4 btn-secondary">Register</button>
        }
        {linkSent &&
        <p className="text-green">An email has been sent to the specified email address, with a 'set password' link in it.</p>
        }
      </Form>
    </LoginFormWrapper>
  )
}

const validate = (fields) => {
  let errors = {}

  if (!fields.first_name) {
    errors.first_name = 'Please enter your first name.'
  }

  if (!fields.last_name) {
    errors.last_name = 'Please enter your last name.'
  }

  if (!fields.postcode) {
    errors.postcode = 'Please enter your postcode.'
  } else if (!validPostcode(fields.postcode)) {
    errors.postcode = 'That postcode doesn\'t look quite right.'
  }

  if (!fields.vrm) {
    errors.vrm = 'Please enter your vehicle registration.'
  } else if (!isVrm(fields.vrm)) {
    errors.vrm = 'That vehicle registration doesn\'t look quite right.'
  }

  if (!fields.email) {
    errors.email = 'Please enter your email address.'
  } else if (!validator.isEmail(fields.email)) {
    errors.email = 'That email address doesn\'t look quite right.'
  }

  return errors
}

RegisterForm.defaultProps = {
  linkSent: false,
}

export default reduxForm({
  form: 'policyholder-portal/register',
  validate,
})(RegisterForm)
