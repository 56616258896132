import React, { Fragment } from 'react'
import PageHeader from '../../../../ui/PageHeader'
import { Table } from '../../../../ui/Table'
import { Link } from 'react-router-dom'
import { sortCaret } from '../../../../../utils/misc'
import api from '../../../../../utils/api'
import NotificationManager from 'react-notifications'
import moment from 'moment'
import Avatar from '../../../../ui/Avatar';
import { uniqueId } from 'lodash';
import { hasPermission } from '../../../../../utils/auth';

class Policies extends React.Component {
  state = {
    filterGroups : [
      {
        name : 'status',
        allowOnlyOne : true,
        filters : [
          {id: uniqueId(), name: 'Awaiting Approval', value: 'Awaiting Approval'},
          {id: uniqueId(), name: 'Rejected', value: 'Rejected'},
          {id: uniqueId(), name: 'Approved', value: 'Approved'},
          {id: uniqueId(), name: 'Cancelled', value: 'Cancelled'},
          {id: uniqueId(), name: 'Transferred', value: 'Transferred'},
          {id: uniqueId(), name: 'Expired', value: 'Expired'},
        ],
      },
    ],
    query: '',
    page: '',
    desc: false,
    limit: 10,
    order: '',
    filters: [],
    policies : null,
    searching : false,
  }

  fetchPolicies = url => {
    api.get(`/direct-sales/policies${url ? url : ''}`)
      .then(res => {
        this.setState({policies : res.data, searching : false})
      })
      .catch(err => {
        if (err) {
          NotificationManager.error('Oops! Something went wrong. Please try again after refreshing.')
        }
      })
  }

  componentDidMount() {
    this.fetchPolicies()

    this.fetchAssignees().then(res => {
      this.setState({
        filterGroups: [
          {
            name: 'sold by',
            filters: res.map((user, index) => ({
              id: index,
              name: (
                <div
                  className="d-flex align-items-center"
                  style={{ marginBottom: '-2px' }}
                >
                  <Avatar user={user} size="xsmall"/>
                  <span className="font-md ml-1" style={{ marginTop: '-2px' }}>
                    {user.first_name} {user.last_name}
                  </span>
                </div>
              ),
              value: user.id,
            })),
            allowOnlyOne: true,
          },
          ...this.state.filterGroups,
        ],
      })
    })

  }

  fetchAssignees = (val) => {
    return new Promise((resolve, reject) => {
      api
        .get('/direct-sales/prospects/assignees?limit=999&query=' + (val || ''))
        .then(res => {
          resolve(res.data.data)
        })
        .catch(err => {
          if (err) {
            reject([])
          }
        })
    })
  }

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    this.setState(
      {
        ...this.state,
        page: page || 1,
        desc: (sortOrder === 'desc') || false,
        limit: sizePerPage || 10,
        order: sortField || '',
      },
      this.buildUrlAndFetchPolicies,
    );
  };

  handleSearch = (query, filters) => {
    this.setState(
      {
        query,
        filters,
        page: 1
      },
      this.buildUrlAndFetchPolicies,
    )
  }

  /**
   * Builds URL and to fetch policyholders
   */
  buildUrlAndFetchPolicies = () => {

    const {query, page, desc, limit, order, filters } = this.state

    let status = filters.filter(f => f.group_name === 'status')
    let assignee = filters.filter(f => f.group_name === 'sold by')

    let url = `?query=${query}&page=${page}&limit=${limit}`
    if (order !== ''){
      url += `&order=${order}&desc=${desc}`
    }
    if (status.length === 1){
      url += `&status=${status[0].value}`
    }
    if (assignee.length === 1) {
      url += `&sold_by_id=${assignee[0].value}`
    }

    this.fetchPolicies(url)
  }

  render() {
    const {policies, searching} = this.state

    let cols = [
      {
        dataField : 'ref',
        text : 'Policy ID',
        headerStyle : {
          width : 150,
        },
        formatter : (cell, row) => <Link to={'/direct-sales/policies/' + row.id + '/policy'}>{row.ref}</Link>,
        sort : true,
        sortCaret,
      },
      {
        dataField : 'policyholder_name',
        text : 'Policyholder name',
        formatter : (cell, row) => {
          if (hasPermission('direct_sales.policies.policyholders.*')) {
            return <Link to={`/direct-sales/policyholders/${row.policyholder.id}/timeline`}>{row.policyholder.title} {row.policyholder.first_name} {row.policyholder.last_name}</Link>
          }
          return `${row.policyholder.title} ${row.policyholder.first_name} ${row.policyholder.last_name}`
        }
      },
      {
        dataField : 'purchased_from',
        text : 'Purchased from',
        formatter : (cell, row) => {
          if (row.cover_level.dealer) {
            return row.cover_level.dealer.name
          }
          return row.purchased_from
        },
      },
      {
        dataField : 'sold_by',
        text : 'Sold by',
        headerStyle: {
          width: 100,
        },
        formatter  : (cell, row) => row.sold_by &&
          <Avatar
            user={row.sold_by}
            tooltip
            dataTip={`${row.sold_by.first_name} ${row.sold_by.last_name}`}
          />
      },
      {
        dataField : 'cover_level.name',
        text : 'Cover level',
      },
      {
        dataField : 'vehicle.vrm',
        text : 'Vehicle reg',
      },

      {
        dataField : 'start_date',
        text : 'Start date',
        formatter : (cell, row) => `${moment(row.start_date).format('DD/MM/YYYY')}`,
        sort : true,
        sortCaret,
      },
      {
        dataField: 'duration',
        text     : 'Duration',
      },

      {
        dataField : 'status',
        text : 'Status',
        sort : true,
        sortCaret,
      },
    ]

    if (hasPermission('direct_sales.policies.update')) {
      cols.push({
        dataField : '',
        text : 'Actions',
        headerStyle : {
          width : 100,
        },
        formatter : (cell, row) => (
          <Link
            data-tip="Edit policy"
            data-place="top"
            to={'/direct-sales/policies/' + row.id + '/policy'}
            className="table-action-btn table-action-btn--edit"
            size="sm"
          >
            <i className="wf-icon-edit"/>
          </Link>
        ),
      })
    }

    const options = {
      showOptions : false,
      columns : cols,
    }

    return (
      <Fragment>
        <br/>
        <div className="container-fluid animated fadeIn">
          <PageHeader
            title="Policies"
            description={
              'A list of all Warranty First policies. You can view all contact history between Warranty First and a customer by clicking on their name and viewing their timeline.'
            }
          />

          {policies && (
            <Table
              resource={policies}
              selected={[]}
              loading={!!policies}
              showOptions={options.showOptions}
              omnibox={{groups : this.state.filterGroups}}
              handleTableChange={this.handleTableChange}
              searching={searching}
              columns={options.columns}
              hasTableSearch={true}
              onSearch={this.handleSearch}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

export default Policies