import React, { useEffect, useState } from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import api from '../../../../../utils/api'
import {
  echo,
  formatDate,
  formatExcess,
  money,
  prettyNumber,
} from '../../../../../utils/misc'
import { Link } from 'react-router-dom'
import moment from 'moment'
import CustomSelect from '../../../../ui/CustomSelect';
import { required } from 'redux-form-validators';

const Step1 = ({
  onCancel,
  handleSubmit,
  onSubmit,
  valid,
  policy,
  policy_id,
  setPolicy,
}) => {

  const [policies, setPolicies] = useState([])

  /**
   * Fetch all of the policyholder's policies
   *
   * @param query
   * @returns {Promise<unknown>}
   */
  const fetchPolicies = () => {
    api
      .get(`/policyholder-portal/policies?limit=1000`)
      .then(res => {
        setPolicies(res.data.data)
      })
  }

  useEffect(() => {
    fetchPolicies()
  }, [])


  /**
   * Fetch policy data when user selects policy from dropdown
   *
   * @param policy
   * @returns {boolean}
   */
  const selectPolicy = (policy) => {
    if (!policy) {
      setPolicy(null)
      return false
    }

    api
      .get(`/policyholder-portal/policies/${policy.id}`)
      .then((res) => {
        setPolicy(res.data)
      })
      .catch((err) => {
        if (err) {
          setPolicy(null)
        }
      })
  }

  const policyIsNotActive = () => {
    const today = moment().startOf('day')
    if (policy && (policy.status !== 'Approved' || today < moment(policy.start_date) || today > moment(policy.end_date))) {
      return true
    }
    return false
  }

  return (
    <div className="mw-37-5rem mx-auto pb-5" id="create-claim-step-1">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h6 className="mt-4 mb-5 text-uppercase">Policy details</h6>

        <Field
          component={CustomSelect}
          label="Select a policy"
          name={`policy_id`}
          options={policies}
          isClearable={true}
          onChange={(option) => {
            if (!option) {
              setPolicy(null)
            } else {
              selectPolicy(option)
            }
          }}
          getOptionLabel={(option) => `${option.ref} - ${option.vehicle.vrm}`}
          getOptionValue={(option) => option.id}
          showSuccessValidation={false}
          validate={[required({ msg: 'You must select a policy' })]}
        />

        {policy && (
          <>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <span className="label">Cover level</span>
                  {policy.cover_level && (
                    <div className="font-md">
                      {echo(policy.cover_level.name)}, {echo(policy.duration)}
                    </div>
                  )}
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <span className="label">Start date</span>
                  <div className="font-md">{formatDate(policy.start_date)}</div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <span className="label">End date</span>
                  <div className="font-md">{formatDate(policy.end_date)}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <span className="label">Claim limit</span>
                  <div className="font-md">
                    {money.format(policy.claim_limit)}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <span className="label">Labour rate</span>
                  <div className="font-md">
                    {money.format(policy.labour_rate)}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <span className="label">Excess</span>
                  <div className="font-md">
                    {formatExcess(policy.excess_type, policy.excess)}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <span className="label">Vehicle reg.</span>
                  <div className="font-md">{echo(policy.vehicle.vrm)}</div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <span className="label">Type</span>
                  <div className="font-md">{echo(policy.vehicle.type)}</div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <span className="label">Make and model</span>
                  <div className="font-md">
                    {echo(policy.vehicle.make)} {echo(policy.vehicle.model)}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <span className="label">Starting mileage</span>
                  <div className="font-md">
                    {policy.vehicle.mileage
                      ? prettyNumber(policy.vehicle.mileage)
                      : '-'}{' '}
                    miles
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <span className="label">Purchased from</span>
                  <div className="font-md">{policy.purchased_from}</div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <span className="label">Policy status</span>
                  <div className="font-md">
                    {echo(policy.status)}
                  </div>
                </div>
              </div>
            </div>

            {policyIsNotActive() &&
            <div className="row">
              <div className="col-12">
                <div className="alert alert-danger">
                  This policy is not active
                </div>
              </div>
            </div>
}

            <hr style={{ margin: '20px 0 30px 0' }} />

            <div className="row">
              <div className="col">
                <h6 className="text-uppercase d-inline-block mt-2 mb-4">
                  Claims history
                </h6>

                <table className="table table-striped table-bordered d-table mt-3">
                  <thead>
                    <tr>
                      <th>Ref.</th>
                      <th>Fault date</th>
                      <th>Gross</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {policy.claims &&
                      policy.claims.length > 0 ?
                      policy.claims.map((claim, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <Link to={`/claims/claims/${claim.id}`}>
                                {claim.ref}
                              </Link>
                            </td>
                            <td>
                              {moment(claim.fault_date).format('DD-MM-YYYY')}
                            </td>
                            <td>{money.format(claim.gross)}</td>
                            <td>{claim.status}</td>
                          </tr>
                        )
                      }): <tr>
                        <td colSpan={4} className="text-center">No claims history.</td>
                      </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!valid || !policy || policyIsNotActive()}
              >
                Next step
              </button>

              <button
                type="button"
                className="btn btn-outline btn-form-cancel"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

      </form>
    </div>
  )
}

Step1.propTypes = {}

const selector = formValueSelector('policyholder-portal/claims/create')

export default connect(
  (state) => ({
    policy_id: selector(state, 'policy_id'),
  }),
  null
)(
  reduxForm({
    form: 'policyholder-portal/claims/create',
    destroyOnUnmount: false,
  })(Step1)
)
