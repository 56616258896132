import validator from 'validator'

const validate = fields => {
  let errors = {}
  if (!fields.first_name)
    errors.first_name = 'Please enter your first name.'
  if (fields.first_name && fields.first_name.length < 2)
    errors.first_name = 'Must be 2 characters or more.'
  if (fields.first_name && fields.first_name.length > 50)
    errors.first_name = 'Must be 50 characters or less.'

  if (!fields.last_name) errors.last_name = 'Please enter your last name.'
  if (fields.last_name && fields.last_name.length < 2)
    errors.last_name = 'Must be 2 characters or more.'
  if (fields.last_name && fields.last_name.length > 50)
    errors.last_name = 'Must be 50 characters or less.'

  if (!fields.email) errors.email = 'Please enter your email address.'
  if (fields.email && !validator.isEmail(fields.email))
    errors.email = `That email address doesn't look quite right.`

  if (fields.is_two_factor_email_active && fields.copy_two_factor_emails && fields.copy_two_factor_emails.split(',').some(email => !validator.isEmail(email)))
    errors.copy_two_factor_emails = `Copy 2FA email code doesn't look quite right.`

  return errors
}

export default validate
