import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { echo } from '../../../../utils/misc';
import * as PropTypes from 'prop-types';
import React from 'react';

function DealerDetailsSection(props) {
  let dealerEmail = props.dealer && props.dealer.email;
  if (dealerEmail && dealerEmail.length > 30) {
    dealerEmail = dealerEmail.substr(0, 30) + '...';
  }
  return (
    props.dealer && (
      <div className="dealer-details bg-white shadow-dark">
        <div className="container-fluid no-gutters p-0">
          <div className="row">
            <div className="col-4">
              <span className="label">Address</span>
              <p className="font-md">
                {[
                  props.dealer.address_1,
                  props.dealer.address_2,
                  props.dealer.address_3,
                  props.dealer.city,
                  props.dealer.country,
                  props.dealer.postcode,
                ]
                  .filter(Boolean)
                  .join(', ')
                }
              </p>
            </div>
            <div className="col-4">
              <span className="label">Sign-up date</span>
              <p className="font-md">
                {moment(props.dealer.created_at).format('DD/MM/YYYY')}
              </p>
            </div>
            <div className="col-4">
              <span className="label">Primary contact</span>
              {props.dealer.primary_contact ? (
                <div className="d-flex mt-1">
                  <span className="font-md">
                    {props.dealer.primary_contact.first_name}{' '}
                    {props.dealer.primary_contact.last_name}
                  </span>
                </div>
              ) : (
                <p>-</p>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <span className="label">Email</span>
              <p
                className="font-md text-wrap"
                data-tip={props.dealer.email}
                data-place="top"
              >
                {echo(dealerEmail)}
              </p>
              <ReactTooltip effect="solid" />
            </div>
            <div className="col-4">
              <span className="label">System access end date</span>
              <p className="font-md">
                {props.dealer.active_end_date
                  ? moment(props.dealer.active_end_date).format('DD/MM/YYYY')
                  : '-'}
              </p>
            </div>
            <div className="col-4">
              <span className="label">Primary contact's telephone</span>
              {props.dealer.primary_contact ? (
                <p className="font-md text-wrap ">
                  {echo(props.dealer.primary_contact.tel1)}
                </p>
              ) : (
                <p>-</p>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <span className="label">Telephone (office)</span>
              <p className="font-md">{echo(props.dealer.tel_office)}</p>
            </div>
            <div className="col-4">
              <span className="label">Price band</span>
              <p className="font-md">Band 1 low with custom price</p>
            </div>
            <div className="col-4">
              <span className="label">Dealer Status</span>
              <p className="font-md">{echo(props.dealer.status)}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <span className="label">Telephone (mobile)</span>
              <p className="font-md">{echo(props.dealer.tel_mobile)}</p>
            </div>
            <div className="col-4">
              <span className="label">VAT registered</span>
              <p className="font-md">{echo(props.dealer.vat_registered ? 'Yes' : 'No')}</p>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

DealerDetailsSection.propTypes = {
  dealer: PropTypes.object.isRequired,
};

export default DealerDetailsSection;
