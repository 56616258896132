
import { NotificationManager } from 'react-notifications'
import {
  ADD_PRICE_BAND_TO_TABLE,
  FETCH_PRICE_BANDS_FAILURE,
  FETCH_PRICE_BANDS_REQUEST,
  FETCH_PRICE_BANDS_SUCCESS,
  FETCH_SINGLE_PRICE_BAND_FAILURE,
  FETCH_SINGLE_PRICE_BAND_REQUEST,
  FETCH_SINGLE_PRICE_BAND_SUCCESS,
  SEARCH_PRICE_BANDS,
} from '../../../constants'
import api from '../../../utils/api'

export const fetchPriceBandsRequest = () => ({
  type: FETCH_PRICE_BANDS_REQUEST,
})

export const fetchPriceBandsSuccess = priceBands => ({
  type   : FETCH_PRICE_BANDS_SUCCESS,
  payload: { priceBands },
})

export const fetchPriceBandsFailure = errors => ({
  type   : FETCH_PRICE_BANDS_FAILURE,
  payload: { errors },
})

/**
 * Fetch policyholders
 * @param url
 * @returns {Function}
 */
export const fetchPriceBands = (target, params)=> dispatch => {
  dispatch(fetchPriceBandsRequest())
  api
    .get(`/admin/price-bands?target=${target}${params ? params : ''}`)
    .then(res => {
      return dispatch(fetchPriceBandsSuccess(res.data))
    })
    .catch(err => {
      if (err && err.response) {
        dispatch(fetchPriceBandsFailure(err.response.data))
        NotificationManager.error(
          'Oops! Something went wrong. Please try again after refreshing.',
        )
      }
    })
}

export const searchPriceBands = data => ({
  type   : SEARCH_PRICE_BANDS,
  payload: {
    priceBands: data,
  },
})

export const fetchPriceBandRequest = () => ({
  type: FETCH_SINGLE_PRICE_BAND_REQUEST,
})

export const fetchPriceBandFailure = error => ({
  type   : FETCH_SINGLE_PRICE_BAND_FAILURE,
  payload: { error },
})

export const fetchPriceBandSuccess = priceBand => ({
  type   : FETCH_SINGLE_PRICE_BAND_SUCCESS,
  payload: { priceBand },
})


/**
 * Fetch single policyholder
 * @param id
 * @returns {Function}
 */
export const fetchPriceBand = id => dispatch => {
  dispatch(fetchPriceBandRequest())

  return api
    .get(`/admin/price-bands/${id}`)
    .then(res => {
      dispatch(fetchPriceBandSuccess(res.data))

      return res.data
    })
    .catch(err => {
      if (err && err.response) {
        dispatch(fetchPriceBandFailure(err.response.data))
        NotificationManager.error(
          'Oops! Something went wrong. Please try again after refreshing.',
        )
      }
    })
}

export const addPriceBandToTable = priceBand => ({
  type: ADD_PRICE_BAND_TO_TABLE,
  payload: priceBand
})