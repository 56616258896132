import React from 'react'

const coveredComponentsPremierMarkup = <>
  <div className="mb-5">
    <h1><i className="wf-icon-premier-cover d-inline-block mr-1"></i> Premier</h1>
    <p>Even more peace of mind and more major parts covered with our most
      popular warranty plan.</p>
  </div>

  <hr/>

  <div className="my-5">
    <h2 className="my-4">What's included?</h2>
    <p>You can make multiple claims, up to the current value of your vehicle (based on Glass’s Guide
      industry prices) whilst your vehicle is covered by us.</p>
  </div>

  <div>
    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Air conditioning</h5>
    <p className="small">Compressor &amp; drive clutch, condenser, evaporator, expansion valve,
      interior fan control switch, pressure &amp; temperature sensors and receiver/drier.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Braking system</h5>
    <p className="small">Anti-lock braking system (ABS) pump &amp; control unit &amp; sensors,
      calipers (not seized), drum brake self-adjusting mechanisms, handbrake control
      cables &amp; linkages, master &amp; wheel cylinders, pedal assembly, pressure
      reducing &amp; proportioning valves, and vacuum pump &amp; servo.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Casings</h5>
    <p className="small">Casings are covered provided they have been damaged as a direct
      result of the covered failure of an insured part.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Clutch</h5>
    <p className="small">Clutch cover diaphragm, centre plate torque springs, clutch release
      bearing, clutch fork, clutch master cylinder, slave cylinder, centre plate oil
      contaminations.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Cooling system</h5>
    <p className="small">Engine cooling water &amp; oil radiators, heater radiator,
      thermostat &amp; housing, temperature gauge, viscous cooling fan couplings and water
      pump (non cam belt driven).</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Drive Train</h5>
    <p className="small">Crown wheel, pinion gear, planet gears, planet carriers
      bearings, all other internal components, half shafts, drive shafts, prop shafts,
      universal joints, support bearings, constant velocity joints, casings (if damaged
      by an internal component).</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Wheel bearings</h5>
    <p className="small">Failure on front &amp; rear wheel bearings.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Electrics</h5>
    <p className="small">Alternator, central locking solenoids and motors, electric
      door mirror motors, electric window motors, electric sunroof motors, engine
      cooling fan motors, front &amp; rear screen wiper motors, horn, indicator
      flasher relay, interior fan motor, starter motor and relays.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Electronic control units
      (ECUs &amp; computers</h5>
    <p className="small">The air conditioning, anti-lock braking system, automatic
      gearbox, central door locking system, fuel injection, ignition, engine
      management, alarm/immobiliser, steering and suspension electronic control
      units (computers), that were originally fitted to your vehicle when it was
      first manufactured are covered (upgraded or revised software and firmware,
      including software and firmware patches and updates, relating directly or
      indirectly to any components of your vehicle and any retro-fitted
      ECUs &amp; computers are excluded from cover).</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Engine</h5>
    <p className="small">All internally lubricated parts are covered including:
      Camshafts &amp; bearings, camshaft followers &amp; rockers (including
      hydraulic lash adjusters), connecting rods &amp; bearings,
      crankshaft &amp; bearings, cylinder block, cylinder
      bores &amp; liners &amp; seals, cylinder heads, internal
      bushings &amp; bearings, oil pump &amp; drive, pistons &amp; rings, timing
      gears &amp; chain &amp; tensioner and valves &amp; springs &amp; guides
      (burnt or pitted valves and valve seats are excluded from cover).
      Crankshaft pulley and cylinder head gasket.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Camshaft drive belts</h5>
    <p className="small">Camshaft drive belts and tensioners (subject to
      documented proof that the last due change of camshaft drive belt has
      taken place as specified by the manufacturer's recommended servicing
      schedule).</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Steering</h5>
    <p className="small">Column shaft &amp; tilt mechanism &amp; couplings,
      hydraulic pumps &amp; reservoir &amp; pressure pipes,
      steering &amp; idler boxes, rack and pinion assembly (including
      hydraulic rams and electric motors) and track rods &amp; track rod
      ends.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Transmission</h5>
    <p className="small">(Including front &amp; centre &amp; rear
      differentials, front &amp; rear live axles and drive shafts).
      Constant velocity joints &amp; boots, crown wheels &amp; pinions,
      differential gears &amp; thrust washers, drive flanges, external
      drive shafts, internal bushes &amp; bearings &amp; shafts, internal
      parts of differential locking mechanisms, propeller
      shafts &amp; propeller shaft couplings &amp; centre bearings and
      universal joints.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Turbocharger</h5>
    <p className="small">Turbocharger units</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Flywheels &amp; drive plates</h5>
    <p className="small">Flexible drive plate for the automatic
      transmission's torque converter, solid &amp; dual-mass flywheels
      (flywheels damaged by clutch friction plates are excluded from
      cover) and starter ring gear.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Fuel system</h5>
    <p className="small">Air flow &amp; air pressure &amp; air
      temperature sensors and meters, fuel cut-off valves, fuel
      gauge &amp; sender unit, fuel pressure
      regulators &amp; accumulators, fuel pumps (including
      pre-pumps, low &amp; high pressure pumps &amp; injection
      pumps), injectors (max 2 per policy including
      petrol &amp; diesel &amp; single-point injectors), oxygen
      sensors, throttle body assembly and throttle position
      sensors.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Gearboxes</h5>
    <p className="small">(Including automated manual gearboxes,
      automatic gearboxes, four wheel drive transfer gearboxes,
      hybrid transaxles, manual gearboxes and transaxles.
      Automatic transmission brake
      bands &amp; clutches &amp; modulator valves, drive chains,
      gear lever, gears, hydraulic gear-shift
      governors &amp; internal servos &amp; valve blocks, internal
      bearings &amp; bushes, oil pumps, selector forks, shafts
      (all, including extension shafts), synchromesh hubs and
      rings and torque converter.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Ignition system</h5>
    <p className="small">Camshaft sensors, crankshaft sensors
      and ignition coils.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Oil seals &amp; gaskets</h5>
    <p className="small">The engine crankshaft rear oil seal
      and the main gearbox input shaft oil seal are both
      covered and other oil seals and gaskets that would
      necessitate the removal of the engine, a gearbox or a
      final drive unit to carry out repairs are all covered
      subject to any oil leak from them being sufficiently
      advanced that oil can be seen to be dripping (slight
      leaks causing only oil misting or staining are excluded
      from cover).</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Suspension</h5>
    <p className="small">Bushes, front and rear coil
      springs, shock absorbers, upper and lower wishbones,
      ball joints, swivel joints, McPherson struts,
      suspension arms, anti-roll bar, anti-roll bar links,
      self-levelling pump and regulator valves and
      displacer.</p>

    <h5><i className="wf-icon-circle-tick d-inline-block mr-1"/> Wear and tear cover</h5>
    <p className="small">The following are covered for
      wear and tear only up to 110,000 miles and 10 years,
      whichever is sooner (inspections may be required):
      Piston rings, Cylinder bores, Valve guides, Timing
      chain &amp; Tensioner, Manual gearbox bearing, Coil
      springs, Clutch release bearing, Fuel pump, Antilock
      brake system, EGR valve, Air flow meter, Power
      steering pump, Wheel bearings and Shock
      absorbers.</p>
  </div>
</>

export default coveredComponentsPremierMarkup