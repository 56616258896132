import React from 'react'
import { Form, reduxForm } from 'redux-form'
import { Field } from 'redux-form'
import CustomSelect from '../../../../../ui/CustomSelect'
import { required } from 'redux-form-validators'
import moment from 'moment'

const YearSelector = ({ handleSubmit, handleChange }) => {
  const baseYear = 2018
  const toYear = moment().add('2', 'year').year()

  const yearOptions = []
  for (let i = baseYear; i <= toYear; i++) {
    yearOptions.push({
      year: i,
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
        <div className={'col-2'} style={{marginLeft: '-15px'}}>
          <Field
            label="Select a year"
            component={CustomSelect}
            name="year"
            options={yearOptions}
            getOptionLabel={(option) => option.year}
            getOptionValue={(option) => option.year}
            validate={[
              required({
                message: 'You must choose a year',
              }),
            ]}
            onChange={handleChange}
          />
        </div>
    </Form>
  )
}

export default reduxForm({
  form: 'yearForm',
  enableReinitialize: true,
})(YearSelector)
