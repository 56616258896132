import React from 'react'
import PropTypes from 'prop-types'

function OmniboxOptions(props) {
  return <div className="option-box">
    {(!props.filtersOpen) &&
    <span className="d-flex align-items-center px-3 py-2 search-text">
      <i className="d-inline-block wf-icon-search mr-2" />
        {!props.hasSearchQuery && 'Type and '}press enter to search
      </span>
    }

    {props.omnibox && props.filtersOpen && (props.omnibox.groups.filter(group => group.filters.filter(
      f => (new RegExp(props.localQuery, 'gi').test(f.name)) ? f : false).length !== 0).length === 0) &&
    <span className="d-block px-3 py-2 filter-text">Sorry, there are no results for {props.localQuery}…</span>
    }

    {props.omnibox && !props.hasSearchQuery &&
    <>
      <div className="text-uppercase px-3 py-2 bg-grey">
        <strong>Filter by</strong>
      </div>
      <div className="options">
        {props.omnibox.groups.map(props.loopFilters)}
      </div>
    </>
    }
  </div>
}

// line 19
// style={(props.filtersOpen && (props.omnibox.groups.filter(group => group.filters.filter(
//   f => (new RegExp(props.localQuery, 'gi').test(f.name)) ? f : false).length !== 0).length === 0)) ? {display: 'none'} : {}}

OmniboxOptions.propTypes = {
  hasSearchQuery: PropTypes.bool,
  omnibox: PropTypes.any,
  f: PropTypes.func,
}

export default OmniboxOptions