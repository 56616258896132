import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import useModal from '../../../../hooks/useModal'
import InfoModal from '../../../modals/InfoModal'
import coveredComponentsPremier from '../../../modals/CoveredComponentsPremier'
import coveredComponentsDynamic from '../../../modals/CoveredComponentsDynamic'
import coveredComponentsExclusive from '../../../modals/CoveredComponentsExclusive'
import { change, Field, formValueSelector, reduxForm } from 'redux-form'
import CustomSelect from '../../../ui/CustomSelect'
import { connect, useDispatch } from 'react-redux'
import Switch from 'react-switch'
import { money, prettyNumber, ucFirst } from '../../../../utils/misc'
import PolicyCard from './PolicyCard'
import { find } from 'lodash';

const StepQuote = (props) => {
  const {
    isShowing: isShowingClaimLimitExplanation,
    toggle: toggleClaimLimitExplanation,
  } = useModal()
  const {
    isShowing: isShowingLabourRateExplanation,
    toggle: toggleLabourRateExplanation,
  } = useModal()
  const {
    isShowing: isShowingCoveredComponents,
    toggle: toggleCoveredComponents,
  } = useModal()

  const [markup, setMarkup] = React.useState()
  const [claimOptions, setClaimOptions] = React.useState([])
  const [savings, setSavings] = useState([])
  const dispatch = useDispatch()

  const { quoteData, payNow, setPayNow } = props

  React.useEffect(() => {
    if (props.coverLevel.name === 'Premier') {
      setMarkup(coveredComponentsPremier)
    }
    if (props.coverLevel.name === 'Dynamic') {
      setMarkup(coveredComponentsDynamic)
    }
    if (props.coverLevel.name === 'Exclusive') {
      setMarkup(coveredComponentsExclusive)
    }
  }, [props.coverLevel])

  useEffect(() => {
    let claims =
      quoteData &&
      quoteData['ClaimLimits'].map((limit) => ({
        name: prettyNumber(limit),
        value: limit,
      }))

    setClaimOptions(claims)

    dispatch(change('get-quote', 'quote_claim_limit', claims[0]))
  }, [])

  useEffect(() => {
    // from `wf-public-website`
    if (props.quote_claim_limit) {
      let price12 =
        quoteData &&
        quoteData['Prices'] &&
        quoteData['Prices']['12 months'] &&
        quoteData['Prices']['12 months']
          .map((item) => {
            if (item.claim_limit === props.quote_claim_limit.value) {
              return (parseFloat(item.price))
            }
            return null
          })
          .filter((i) => i !== undefined)[0]

      let price24 =
        quoteData &&
        quoteData['Prices'] &&
        quoteData['Prices']['24 months'] &&
        quoteData['Prices']['24 months']
          .map((item) => {
            if (item.claim_limit === props.quote_claim_limit.value) {
              return (parseFloat(item.price))
            }
            return null
          })
          .filter((i) => i !== undefined)[0]

      let price36 =
        quoteData &&
        quoteData['Prices'] &&
        quoteData['Prices']['36 months'] &&
        quoteData['Prices']['36 months']
          .map((item) => {
            if (item.claim_limit === props.quote_claim_limit.value) {
              return (parseFloat(item.price))
            }
            return null
          })
          .filter((i) => i !== undefined)[0]

      let save24 = parseFloat(price12) * 2 - parseFloat(price24)
      let save36 = parseFloat(price12) * 3 - parseFloat(price36)

      setSavings([
        {
          planLength: 2, // years
          saving: `Save ${money.format(save24)} *`,
        },
        {
          planLength: 3, // years
          saving: `Save ${money.format(save36)} *`,
        },
      ])
    }
  }, [props])

  return (
    payNow !== undefined && (
      <>
        <InfoModal
          hide={toggleClaimLimitExplanation}
          isShowing={isShowingClaimLimitExplanation}
          style={{ width: '650px', top: '15%' }}
        >
          <h3 className="mb-3">What’s a claim limit?</h3>
          <p className="mb-3">
            We will pay up to your chosen claim limit, per claim you make. This
            means that each claim you make will be limited to your chosen claim
            limit.
          </p>
          <p>
            You can claim multiple times, for each new issue, up to the current
            value of your vehicle (based on Glass’s Guide industry prices).
          </p>
        </InfoModal>

        <InfoModal
          hide={toggleLabourRateExplanation}
          isShowing={isShowingLabourRateExplanation}
          style={{ width: '650px', top: '15%' }}
        >
          <h3 className="mb-3">What’s a labour rate?</h3>
          <p className="mb-3">
            We will pay up to your chosen labour rate for each hour of work
            carried out on your car at a VAT registered garage.
          </p>
          <p>
            Feel free to increase or decrease your labour rate, as you see fit.
            Please note, this will have a bearing on your warranty's premium.
          </p>
        </InfoModal>

        <div className="mw-50rem mx-auto quote-options">
          <div className="position-relative text-center mb-4">
            <h2>
              Your quote -{' '}
              <i
                className={`wf-icon-${props.coverLevel.name}-cover d-inline-block`}
                style={{ verticalAlign: 'middle' }}
              />{' '}
              {ucFirst(props.coverLevel.name)}
            </h2>
            {props.savedQuote && (
              <label className="label font-lg d-block fw-600">
                Quote number: {props.savedQuote.ref}
              </label>
            )}
          </div>

          {/**
         Quote
         */}
          <div className="mt-2 mw-37-5rem mb-5 mx-auto">
            <hr className="mt-5 mb-5" />
            <label className="fw-600 d-block mb-4 label font-lg">
              Customise quote
            </label>

            <div className="mb-3">
              <Field
                label="Amount we will pay for labour (per hour)"
                component={CustomSelect}
                prependIcon={<i className="wf-icon-gbp" />}
                className="prepended-select mb-0"
                name={`labour_rate`}
                id={'labourRate'}
                options={props.labourRateOptions}
                getOptionLabel={(o) => o.title}
                getOptionValue={(o) => o.title}
                disabled={!!props.savedQuote}
              />
              <Link
                to="#"
                onClick={() => toggleLabourRateExplanation()}
                className="font-md d-block"
                style={{ marginTop: '-15px' }}
              >
                What does this mean?
              </Link>
            </div>

            <div className="mb-3">
              <Field
                label="Excess"
                component={CustomSelect}
                appendIcon={<i className="wf-icon-percent" />}
                className="prepended-select mb-0"
                name={`excess`}
                id={'excess'}
                options={props.excessOptions}
                getOptionLabel={(o) => o.title}
                getOptionValue={(o) => o.title}
                disabled={!!props.savedQuote}
              />
            </div>

            <div className="mb-3">
              <Field
                label="Maximum amount we will pay out per claim"
                component={CustomSelect}
                name={`quote_claim_limit`}
                value={[{ name: 500, value: 500 }]}
                id={'claim_limit'}
                options={claimOptions}
                prependIcon={<i className="wf-icon-gbp" />}
                className="prepended-select mb-0"
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.value}
                disabled={!!props.savedQuote}
              />
              <Link
                to="#"
                onClick={() => toggleClaimLimitExplanation()}
                className="font-md d-block"
                style={{ marginTop: '-15px' }}
              >
                What does this mean?
              </Link>
            </div>

            <div className="mb-3">
              <Field
                label="Extras"
                component={CustomSelect}
                isMulti
                className="mb-0"
                name={`sundries`}
                id={'sundries'}
                options={props.sundryOptions}
                getOptionLabel={(o) => o.title}
                getOptionValue={(o) => o.title}
                disabled={!!props.savedQuote}
              />
            </div>

          </div>
        </div>

        <div className="bg-grey py-5">
          <div
            className="d-flex mx-auto align-items-center justify-content-between text-uppercase mb-4 payment-toggle-wrapper"
            style={{ maxWidth: '285px' }}
          >
            <span className={`fw-600 ${!payNow && 'text-blue'}`}>
              Pay monthly
            </span>
            <div className="custom-switch pl-0">
              <Switch
                onChange={() => {
                  setPayNow(!payNow)
                  props.fetchQuoteOptions(props.coverLevel.id, !payNow)
                }}
                checked={payNow}
                checkedIcon={false}
                uncheckedIcon={false}
                offColor="#1673FF"
                onColor="#1673FF"
                id="paymentType"
                activeBoxShadow={false}
                handleDiameter={24}
                width={64}
                height={32}
              />
            </div>
            <span className={`fw-600 ${payNow && 'text-blue'}`}>Pay now</span>
          </div>

          {/**
         Claim limit
         */}
          <div className="container mt-5">
            <div className="row">
              {quoteData && props.quote_claim_limit &&
                Object.keys(quoteData['Prices']).map((plan, i) => {
                  let planLength = parseInt(plan.split(' ')[0])
                  let prices = quoteData['Prices'][plan]

                  // don't output a policy card if there is no price available for the chosen claim limit
                  if (!find(prices, ['claim_limit', props.quote_claim_limit.value])) {
                    return null;
                  }

                  // don't output a policy card if an sundry items (extras) have been chosen that is not available for the policy card's duration
                  if (props.sundries) {
                    if (props.sundries.filter(s => s.durations == null || !s.durations[plan]).length > 0) {
                      return null;
                    }
                  }

                  return (
                    <div
                      className="col-4"
                      key={i}
                      style={{
                        paddingBottom: '20px',
                      }}
                    >
                      <PolicyCard
                        planLength={planLength}
                        prices={prices}
                        claimLimit={props.quote_claim_limit}
                        labourRate={props.labour_rate}
                        savings={savings}
                        payNow={payNow}
                        getQuoteValues={props.getQuoteValues}
                        plan={plan}
                        rewritePrice={props.updateCustomPrice}
                        coverLevel={ucFirst(props.coverLevel.name)}
                      />
                    </div>
                  )
                })}
            </div>
          </div>

          <p
            className="font-sm text-center mt-5 mb-4"
            style={{ color: 'rgba(45,44,49,0.7)' }}
          >
            * Based on a 12 month plan.
          </p>

          {/*<button*/}
          {/*  onClick={() => props.prevStep()}*/}
          {/*  className="btn btn-md btn-primary mx-auto d-block mb-3 px-2"*/}
          {/*  style={{ paddingTop: '6px', paddingBottom: '6px' }}*/}
          {/*>*/}
          {/*  Choose a different plan*/}
          {/*</button>*/}
        </div>
      </>
    )
  )
}

StepQuote.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  coverLevel: PropTypes.object.isRequired,
  payNow: PropTypes.bool.isRequired,
  setPayNow: PropTypes.func.isRequired,
  fetchQuoteOptions: PropTypes.func.isRequired
}

const selector = formValueSelector('get-quote')

export default connect(
  (state) => ({
    quote_claim_limit: selector(state, 'quote_claim_limit'),
    excess: selector(state, 'excess'),
    labour_rate: selector(state, 'labour_rate'),
    sundries: selector(state, 'sundries'),
  }),
  null
)(
  reduxForm({
    form: 'get-quote',
    destroyOnUnmount: false,
  })(StepQuote)
)
