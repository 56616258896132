import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, Form, reduxForm, formValueSelector } from 'redux-form'
import CustomSelect from '../../../../../ui/CustomSelect'
import api from '../../../../../../utils/api'
import CustomAsyncSelect from '../../../../../ui/CustomAsyncSelect'
import { hasPermission } from '../../../../../../utils/auth'
import { echo, echoMoney, formatDate } from '../../../../../../utils/misc'
import { connect } from 'react-redux'
import renderField from '../../../../../../utils/renderField';
import moment from 'moment';
import { CLAIM_PAYEE_TYPES } from '../../../../../../constants'

const ClaimStatusForm = ({
  handleSubmit,
  onSubmit,
  valid,
  payout,       // gross, not net
  repairQuote,  // gross, not net
  payee,
  garage,
  dealer,
  policyholder,
  onGarageInfoClick,
  onDealerInfoClick,
  statusText,
  expiryDate,
  extensionDate,
}) => {

  const [currentPayee, setPayee] = useState(payee)

  /**
   * Fetch all garages
   * @param query
   * @returns {Promise<unknown>}
   */
  const fetchGarages = (query) =>
    new Promise((resolve, reject) => {
      api
        .get(`misc/garages?query=${query ? query : ''}&status=Active&limit=9999999`)
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          if (err) {
            reject([])
          }
        })
    })

  const fetchDealers = async (query) => {
    try {
      const res = await api.get('/claims/dealers', { params: {
          query, 
          limit: 100,
          status: 'Active,Rejected'
        }});
      return res.data.data;
    } catch {
      return []
    }
  }

  const policyholderName = () => {
    const holder = policyholder || {}
    const title = holder.title || ''
    const firstName = holder.first_name || ''
    const lastName = holder.last_name || ''

    return `${title} ${firstName} ${lastName}`
  }
  
  const shortfall = () => {
    if (repairQuote > 0 && payout > 0) {
      return echoMoney(repairQuote - payout)
    }
    return '-'
  }

  const handlePayeeChange = (option) => {
    setPayee(option.value)
    // change form data?
  }

  if (hasPermission('claims.claims.update')) return (
    <Form onSubmit={handleSubmit(onSubmit)}>

      <Field
        type="text"
        label="Repair quote"
        component={renderField}
        name={`repair_quote`}
        className="form-control text-right"
        append={{
          direction: 'left',
          text     : <i className="wf-icon-gbp"/>,
        }}
      />

      <div className="d-flex align-items-center justify-content-between mt-2 mb-4">
        <span className="label fw-800" style={{ fontSize: 20 }}>
        Shortfall
        </span>
        <span className="label fw-800" style={{ fontSize: 20 }}>
          {shortfall()}
        </span>
      </div>

      <hr className="mt-1 mb-4"/>
      <Field
        component={CustomSelect}
        label="Payee"
        name={`payee`}
        options={Object.values(CLAIM_PAYEE_TYPES).map((e) => { return { value:e } })}
        getOptionValue={(o) => o.value}
        getOptionLabel={(o) => o.value}
        onChange={handlePayeeChange}
      />

      {/* payee is repairing garage */}
      {(currentPayee === CLAIM_PAYEE_TYPES.REPAIRING_GARAGE && garage) &&
        <span
          className="garage-info fa fa-info-circle"
          onClick={onGarageInfoClick(garage)}
        />
      }
      {currentPayee === CLAIM_PAYEE_TYPES.REPAIRING_GARAGE && (
        <Field
          component={CustomAsyncSelect}
          name={`garage_id`}
          label={`Repairing garage`}
          placeholder={'Search garage'}
          isClearable
          loadOptions={fetchGarages}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          onChange={(option) => {
            garage = option
          }}
        />
      )}

      {(currentPayee === CLAIM_PAYEE_TYPES.REPAIRING_GARAGE && garage) &&
        <p className='small mb-4'>
          <i
            className={`wf-icon-${garage.vat_registered ? 'circle-tick' : 'red-cross'} d-inline-block text-green`}
            style={{ marginBottom: 3 }}
          />{' '}
          This garage is {garage.vat_registered ? '' : 'not'} VAT registered.
        </p>
      }

      {/* payee is dealer */}
      {(currentPayee === CLAIM_PAYEE_TYPES.DEALER && dealer) &&
        <span
          className="garage-info fa fa-info-circle"
          onClick={onDealerInfoClick(dealer)}
        />
      }
      {currentPayee === CLAIM_PAYEE_TYPES.DEALER && (
        <Field
          component={CustomAsyncSelect}
          name={`dealer_id`}
          label={`Dealer`}
          placeholder={'Search dealer'}
          isClearable
          loadOptions={fetchDealers}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          onChange={(option) => {
            dealer = option
          }}
        />
      )}

      {(currentPayee === CLAIM_PAYEE_TYPES.DEALER && dealer) &&
        <p className='small mb-4'>
          <i
            className={`wf-icon-${dealer.vat_registered ? 'circle-tick' : 'red-cross'} d-inline-block text-green`}
            style={{ marginBottom: 3 }}
          />{' '}
          This dealer is {dealer.vat_registered ? '' : 'not'} VAT registered.
        </p>
      }
  
      {/* payee is policyholder */}
      {(currentPayee === CLAIM_PAYEE_TYPES.POLICYHOLDER) &&
        <div className='row'>
          <div className='col'>
            <span className='label'>Policyholder</span>
            <p>{policyholderName()}</p>
          </div>
        </div>
      }
      <hr className="mt-1 mb-4"/>

      <Field
        component={CustomSelect}
        label="Claim status"
        name={`status`}
        options={[
          { value: 'Awaiting approval' },
          { value: 'Awaiting information' },
          { value: 'Assessor appointed' },
          { value: 'Diagnosis requested' },
          { value: 'Awaiting underwriter decision' },
          { value: 'Reviewed by underwriters' },
          { value: 'Awaiting liability acceptance' },
          { value: 'Approved' },
          { value: 'Payment docs not received' },
          { value: 'Awaiting Liability Payment' },
          { value: 'Liability Paid' },
          { value: 'Approved not paid' },
          { value: 'Approved paid' },
          { value: 'No comms within 14 days' },
          { value: 'Parts not covered' },
          { value: 'Inherent Fault' },
          { value: 'Rejected' },
          { value: 'Cancelled' },
          { value: 'Closed expired MOT' },
          { value: 'Closed expired service' },
        ]}
        getOptionValue={(o) => o.value}
        getOptionLabel={(o) => o.value}
      />

      <div className="d-flex align-items-center justify-content-between pt-3 mb-4">
        <span className="label">
          Expiry date
        </span>
        {moment(expiryDate) < moment() ? (
          <span className="label font-weight-bold text-red">
            {formatDate(expiryDate)}
          </span>
        ) : (
          <span className="label">
            {formatDate(expiryDate)}
          </span>
        )}
      </div>

      {moment(expiryDate) < moment() &&
      <Field
        type="date"
        name={`extension_date`}
        style={{ width: '100%', marginBottom: 0 }}
        label="Extension date"
        className="form-control"
        id="extensionDate"
        component={renderField}
      />
      }

      <div className="row">
        <div className="col-md-12 mt-3">
          <div className="form-group">
            <button
              className="btn btn-secondary"
              type="submit"
              disabled={!valid}
            >
              Save changes
            </button>
            <button
              type="button"
              className="btn btn-outline btn-form-cancel"
              onClick={() => {}}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Form>
  )

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mt-2 mb-4">
        <span className="label fw-800" style={{ fontSize: 20 }}>
        Repair quote
        </span>
        <span className="label fw-800" style={{ fontSize: 20 }}>
          {echoMoney(repairQuote)}
        </span>
      </div>

      <div className="d-flex align-items-center justify-content-between mt-2 mb-4">
        <span className="label fw-800" style={{ fontSize: 20 }}>
        Shortfall
        </span>
        <span className="label fw-800" style={{ fontSize: 20 }}>
          {shortfall()}
        </span>
      </div>

      <div>
        <span className="label">Repairing garage</span>
        {garage ? (
          <div>
            <p>{garage.name}</p>
            <p className="small mb-4">
              <i
                className={`wf-icon-${garage.vat_registered ? 'circle-tick' : 'red-cross'} d-inline-block text-green`}
                style={{ marginBottom: 3 }}
              />{' '}
              This garage is {garage.vat_registered ? '' : 'not'} VAT registered.
            </p>
          </div>
        ) : (
          <p>-</p>
        )}
      </div>

      <div>
        <span className="label">Claim status</span>
        <p>{echo(statusText)}</p>
      </div>

      <div className="d-flex align-items-center justify-content-between pt-3 mb-4">
        <span className="label">
          Expiry date
        </span>
        {moment(expiryDate) < moment() ? (
          <span className="label font-weight-bold text-red">
            {formatDate(expiryDate)}
          </span>
        ) : (
          <span className="label">
            {formatDate(expiryDate)}
          </span>
        )}
      </div>

      {moment(expiryDate) < moment() &&
      <div className="d-flex align-items-center justify-content-between mt-2 mb-4">
        <span className="label">
          Extension date
        </span>
        <span className="label">
          {formatDate(extensionDate)}
        </span>
      </div>}

    </>
  )
}

ClaimStatusForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

const selector = formValueSelector('claim-status')

export default connect((state) => ({
  repairQuote: selector(state, 'repair_quote'),
  garage: selector(state, 'garage_id'),
  dealer: selector(state, 'dealer_id'),
}))(
  reduxForm({
    form: 'claim-status',
    enableReinitialize: true,
  })(ClaimStatusForm)
)
