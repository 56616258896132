import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { change, Field, Form, reduxForm } from 'redux-form'
import renderField from '../../../../../utils/renderField'
import { Link } from 'react-router-dom'
import { email, url, required } from 'redux-form-validators'
import { validInteger, validPhoneNumber } from '../../../../../utils/misc'
import { usePostcodeLookupWithRedux } from '../../../../../hooks/usePostcodeLookupWithRedux'
import { useDispatch } from 'react-redux'
import { has } from 'lodash'

const CreateProspectForm = (props) => {
  const { handleSubmit, onSubmit, cancel, valid, dirty } = props

  const [primaryContactForm, setPrimaryContactForm] = useState(false)
  const [currentVatRegistered, setCurrentVatRegistered] = useState(false)
  const dispatch = useDispatch()

  const { markup } = usePostcodeLookupWithRedux('prospects/create')

  const handleVatRegisteredChange = (event) => {
    const vatRegistered = event.target.checked
    setCurrentVatRegistered(vatRegistered)
    if (!vatRegistered) {
      props.dispatch(
        change('dealer/create', 'vat_number', null, true)
      )
    }
  }

  return (
    <div className="mw-37-5rem mx-auto mt-5 mb-5">
      <h1 className="page-title mt-1 mb-4">Add a prospect</h1>

      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row">
          <div className="col">
            <Field
              type="text"
              label="Dealership name"
              component={renderField}
              name={`name`}
              className="form-control"
              placeholder={'e.g. 123 Cars'}
            />
          </div>
        </div>

        {markup}

        <hr />
        <div className="row">
          <div className="col-md-12 mb-4">
            <h6 className="mt-4 text-uppercase">Contact details</h6>
            <p>Please include at least one contact telephone number</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Telephone (office)"
              component={renderField}
              name={`tel_office`}
              className="form-control"
            />
          </div>
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Telephone (mobile)"
              component={renderField}
              name={`tel_mobile`}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 b-form-group">
            <Field
              type="email"
              label="Email address"
              component={renderField}
              name={`email`}
              validate={[
                required({ msg: 'You must enter an email address.' }),
                email({
                  message: "That email address doesn't quite look right.",
                }),
              ]}
              className="form-control"
              placeholder="e.g. 123cars@gmail.com"
            />
          </div>
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Website (optional)"
              component={renderField}
              name={`website`}
              validate={[
                url({
                  message: "That URL doesn't quite look right.",
                  allowBlank: true,
                  protocolIdentifier: false,
                }),
              ]}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 pb-4">
            {!primaryContactForm && (
              <Link
                to="#"
                className="font-sm"
                onClick={() => {
                  dispatch(
                    change('prospects/create', '_hasPrimaryContact', true)
                  )
                  setPrimaryContactForm(!primaryContactForm)
                }}
              >
                Add a primary contact
              </Link>
            )}
            {primaryContactForm && (
              <>
                <div className="mb-4 mt-3">
                  <h6 className="mt-4">Primary contact (optional)</h6>
                  <p>
                    Add the contact details of a specific person at the
                    dealership.
                  </p>
                </div>

                <div className="row">
                  <div className="col-md-6 mt-2">
                    <Field
                      type="text"
                      label="Contact's first name"
                      component={renderField}
                      name={`primary_contact_first_name`}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mt-2">
                    <Field
                      type="text"
                      label="Contact's last name"
                      component={renderField}
                      name={`primary_contact_last_name`}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col mt-2">
                    <Field
                      type="email"
                      label="Contact's email address"
                      component={renderField}
                      name={`primary_contact_email`}
                      validate={[
                        required({ msg: 'You must enter an email address.' }),
                        email({
                          message:
                            "That email address doesn't look quite right.",
                        }),
                      ]}
                      className="form-control"
                      placeholder="e.g. 123cars@email.com"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Field
                      type="text"
                      label="Telephone (office) "
                      component={renderField}
                      name={`primary_contact_tel_office`}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      type="text"
                      label="Telephone (mobile)"
                      component={renderField}
                      name={`primary_contact_tel_mobile`}
                      className="form-control"
                    />
                  </div>
                </div>
              </>
            )}
            <hr style={{ marginTop: '2.25rem' }} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mb-4">
            <h6 className="text-uppercase">Is this dealer is VAT registered?</h6>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              type="checkbox"
              id={'vatRegistered'}
              label="This dealer is VAT registered"
              component={renderField}
              name={`vat_registered`}
              onChange={handleVatRegisteredChange}
            />
          </div>
        </div>

        {currentVatRegistered && (
          <div className="row">
            <div className="col">
              <Field
                type="text"
                label="VAT number"
                component={renderField}
                name={`vat_number`}
                className="form-control"
              />
            </div>
          </div>
        )}

        <hr/>

        <div className="row">
          <div className="col-md-12 mb-4">
            <h6 className="text-uppercase">
              Additional information (optional)
            </h6>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Previous warranty provider"
              component={renderField}
              name={`existing_warranty_provider`}
              className="form-control"
            />
          </div>
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Policies per month"
              component={renderField}
              name={`policies_per_month`}
              className="form-control"
            />
          </div>
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Potential montly revenue"
              component={renderField}
              name={`potential_revenue`}
              className="form-control"
            />
          </div>
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Number of cars in stock"
              component={renderField}
              name={`cars_in_stock`}
              className="form-control"
            />
          </div>
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Lowest stock price"
              component={renderField}
              fieldIcon="wf-icon-gbp"
              name={`min_stock_price`}
              className="form-control"
            />
          </div>
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Highest stock price"
              component={renderField}
              fieldIcon="wf-icon-gbp"
              name={`max_stock_price`}
              className="form-control"
            />
          </div>
          <div className="col-md-12 b-form-group">
            <Field
              type="textarea"
              label="Other"
              component={renderField}
              fieldIcon="wf-icon-gbp"
              name={`comments`}
              className="form-control"
              placeholder="Reg company number, Directors name(s) & Reg address"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <button className="btn btn-secondary" disabled={!valid || !dirty}>
                Add prospect
              </button>
              <button
                className="btn btn-outline btn-form-cancel"
                onClick={cancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

CreateProspectForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

const validate = (fields, form) => {
  let errors = {}

  if (!fields.name) errors.name = 'You must enter a dealership name.'

  if (!fields.tel_office && !fields.tel_mobile)
    errors.tel_office = 'You must enter at least one telephone number.'

  if (fields.tel_office && !validPhoneNumber(fields.tel_office))
    errors.tel_office = "That phone number doesn't look quite right."

  if (fields.tel_mobile && !validPhoneNumber(fields.tel_mobile))
    errors.tel_mobile = "That phone number doesn't look quite right."

  if (fields._hasPrimaryContact) {
    if (
      fields.primary_contact_tel_office &&
      !validPhoneNumber(fields.primary_contact_tel_office)
    )
      errors.primary_contact_tel_office =
        "That phone number doesn't look quite right."

    if (
      fields.primary_contact_tel_mobile &&
      !validPhoneNumber(fields.primary_contact_tel_mobile)
    )
      errors.primary_contact_tel_mobile =
        "That phone number doesn't look quite right."
  }

  if (fields.policies_per_month && !validInteger(fields.policies_per_month))
    errors.policies_per_month = 'You must enter a positive number.'

  if (fields.potential_revenue && !validInteger(fields.potential_revenue))
    errors.potential_revenue = 'You must enter a positive number.'

  if (fields.cars_in_stock && !validInteger(fields.cars_in_stock))
    errors.cars_in_stock = 'You must enter a positive number.'

  if (fields.min_stock_price && !validInteger(fields.min_stock_price))
    errors.min_stock_price = 'You must enter a positive number.'

  if (fields.max_stock_price && !validInteger(fields.max_stock_price))
    errors.max_stock_price = 'You must enter a positive number.'

  const pressLookupErr = document.querySelector('.js--press-lookup')

  if (fields.postcode && !has(fields, 'address.address_1')) {
    if (pressLookupErr) {
      pressLookupErr.classList.add('d-block')
    }
  } else if (pressLookupErr) {
    pressLookupErr.classList.remove('d-block')
  }

  return errors
}

export default reduxForm({
  form: 'prospects/create',
  initialValues: {
    _hasPrimaryContact: false,
  },
  validate,
})(CreateProspectForm)
